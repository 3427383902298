import React, { useEffect, useState } from 'react';
import { Box, useMediaQuery } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

import {
    GridCol,
    Variant,
    Article,
    Typography,
    ColorDSD,
    InputType,
    Input,
    InputPrefix
} from '../../../../styles';

export interface ITelephoneInfoSignature {
    cellRef: any;
    telephonePreRempli: string;
    shouldCellPhoneInputErrorDisplay: boolean;
    handleCellPhoneChange: (cellPhone: string) => void;
}

export const TelephoneInfoSignature: React.FC<ITelephoneInfoSignature> = ({
    cellRef,
    telephonePreRempli,
    shouldCellPhoneInputErrorDisplay,
    handleCellPhoneChange
}) => {
    const [t] = useTranslation('InformationsSignature');
    const isMobile = useMediaQuery('(max-width:820px)');
    const [cellPhone, setCellPhone] = useState(telephonePreRempli);
    const [isErrorDisplayed, setIsErrorDisplayed] = useState(false);

    const onCellPhoneChange = (value) => {
        setIsErrorDisplayed(false);

        setCellPhone(value);
        handleCellPhoneChange(value);
    };

    useEffect(() => {
        if (shouldCellPhoneInputErrorDisplay) {
            setIsErrorDisplayed(shouldCellPhoneInputErrorDisplay);
        }
    }, [shouldCellPhoneInputErrorDisplay]);

    return (
        <GridCol col="12" sm="12" md="12">
            <Box display="flex" flexWrap="nowrap">
                <Typography
                    variant={Variant.p}
                    article={Article.a3}
                    color={ColorDSD.lightSystem3}
                    hideMarginBottom
                >
                    {t('authentificationModeCellphoneInput')}
                </Typography>
            </Box>
            <Box maxWidth={isMobile ? '100%' : '300px'}>
                <Input
                    dataTestId={'input_telephone_cellulaire_field'}
                    inputRef={cellRef}
                    isError={isErrorDisplayed}
                    errorLabel={t('authentificationModeCellphoneInputError')}
                    hint={t('authentificationModeCellphoneInputHint')}
                    key="InputMui_InfoSignature_Cellphone_01"
                    value={cellPhone}
                    inputPrefix={InputPrefix.CELLULAR}
                    type={InputType.TEL}
                    onFocusOut={onCellPhoneChange}
                    onChange={onCellPhoneChange}
                />
            </Box>
        </GridCol>
    );
};
