import React from 'react';

export type SpacingRange = 1 | 2 | 3 | 4 | 5 | 6;

interface ISpacerProps {
    all?: SpacingRange;
    lg?: SpacingRange;
    xs?: SpacingRange;
    md?: SpacingRange;
    sm?: SpacingRange;
    xl?: SpacingRange;
}

export const Spacer: React.FC<ISpacerProps> = ({ all, lg, xs, md, sm, xl }) => (
    // @ts-ignore
    <dsd-vspacer all={all} lg={lg} md={md} sm={sm} xl={xl} xs={xs} />
);
