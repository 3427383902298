export default {
    en: {
        sommaire: 'Back to AccèsD home',
        titreRhn: 'Mortgage renewal',
        titre: 'You’ve submitted your renewal application',
        titreSI: 'Your mortgage has been renewed',
        titreSIGuy: 'It’s your co-borrower’s turn',
        saved: 'Application saved',
        alerteSIUnique: 'Your new rate is now in effect.',
        alerteSIGuy: 'You’ve signed all the required documents.',
        alerteSIMartine:
            'Your new rate will take effect on {{date, MMMM\xa0D}}, {{date, YYYY}}.',
        descriptionProchaineEtapeGuy:
            'We’ve sent your co-borrower an email asking them to confirm your choices.',
        rappelTitreSI: 'Important reminder',
        rappelTexteSI:
            'Your mortgage payment has likely changed. Remember to adjust your automatic transfers between accounts, if necessary.',
        DescriptionTitreGuy: 'Deadline to confirm and sign',
        dateRappelPlusGrandDateLimite:
            '{{martineName}} must confirm your choices and sign the required documents by <strong>{{date, MMMM\xa0D}}, {{date, YYYY}}</strong>. If these steps aren’t completed by the deadline, you’ll have to start the process over again, and the rates available at that time may be different.',
        dateRappelPlusGrandDateLimiteEgaleDateAujourdhui:
            '{{martineName}} must confirm your choices and sign the required documents <strong>today</strong>. If these steps aren’t completed by the deadline, you’ll have to start the process over again, and the rates available at that time may be different.',
        dateLimiteEgaleDateRappel:
            '{{martineName}} must confirm your choices and sign the required documents by <strong>{{date, MMMM\xa0D}}, {{date, YYYY}}</strong>. If these steps aren’t completed by the deadline, your mortgage will be renewed with a 1-year open variable-rate term.',
        dateLimiteEgaleDateRappelDateAujourdhui:
            '{{martineName}} must confirm your choices and sign the required documents <strong>today</strong>. If these steps aren’t completed by 11:59 PM, your mortgage will be renewed with a 1-year open variable-rate term.',
        DescriptionTitreOntario: 'If you change your mind',
        DescriptionOntario:
            'You have up to 21\xa0days after signing your contract to cancel your renewal. Contact your branch for more information. If you cancel, your previous mortgage terms will be reinstated.',
        informationSignatureContrat:
            'You’ll receive the contract to be signed when your co-borrower has completed the renewal.',
        sousTitreDate:
            "It’s now your co-borrower's turn to accept the offer by {{date, MMMM\xa0D}}, {{date, YYYY}}",
        sousTitreToday:
            "It’s now your co-borrower's turn to accept the offer by today",
        warningText:
            'If the offer isn’t accepted by then, you’ll need to restart the process and the rates may be different.',
        choixTitre2Steps: 'Next steps',
        choixTitre1Step: 'Next step to finalize your renewal',
        choixEtape1: 'Your co-borrower must accept the offer',
        choixEtape2: 'Sign your contract',
        alertBasTerminee:
            'You’ll receive an authentication email within 3 business days. As soon as you receive it, you can electronically sign the contract.',
        alertOntarioTitle: 'An extra precaution',
        alertOntario:
            'Want to cancel your renewal? Contact your advisor within 21 days of receiving your contract. Should you decide to cancel, your current mortgage terms and conditions will remain in effect until the end of the term.',
        tipTitle: 'Important reminder',
        tip: 'Once you’ve signed the contract, consider changing your automatic transfer amounts on AccèsD or with other financial institutions.'
    },
    fr: {
        sommaire: 'Retour à l’accueil',
        titreRhn: 'Renouvellement hypothécaire',
        titre: 'Vous avez bien transmis votre demande de renouvellement',
        titreSI: 'Votre prêt est renouvelé',
        titreSIGuy: 'C’est maintenant au tour de votre coemprunteur',
        saved: 'Demande enregistrée',
        alerteSIUnique: 'Votre nouveau taux est applicable dès maintenant.',
        alerteSIGuy: 'Vous avez signé tous les documents requis.',
        alerteSIMartine:
            'Votre nouveau taux est applicable dès le {{date, D}}{{suffixe}}\xa0{{date, MMMM\xa0YYYY}}.',
        descriptionProchaineEtapeGuy:
            'Nous avons envoyé un courriel à votre coemprunteur pour l’inviter à confirmer vos choix. ',
        rappelTitreSI: 'Rappel important',
        rappelTexteSI:
            'Le montant de votre paiement hypothécaire a sans doute changé. Pensez à ajuster vos virements automatiques entre comptes si nécessaire.',
        DescriptionTitreGuy: 'Date limite pour confirmer et signer',
        dateRappelPlusGrandDateLimite:
            '{{martineName}} doit confirmer vos choix et signer les documents requis au plus tard le <strong>{{date, D}}{{suffixe}}\xa0{{date, MMMM\xa0YYYY}}</strong>. Si ce délai n’est pas respecté, vous devrez recommencer le processus. Les taux disponibles à ce moment pourraient être différents.',
        dateRappelPlusGrandDateLimiteEgaleDateAujourdhui:
            '{{martineName}} doit confirmer vos choix et signer les documents requis <strong>aujourd’hui</strong>. Si ce délai n’est pas respecté, vous devrez recommencer le processus. Les taux disponibles à ce moment pourraient être différents.',
        dateLimiteEgaleDateRappel:
            '{{martineName}} doit confirmer vos choix et signer les documents requis au plus tard le <strong>{{date, D}}{{suffixe}}\xa0{{date, MMMM\xa0YYYY}}</strong>. Si ce délai n’est pas respecté, votre prêt sera renouvelé avec un terme de 1 an ouvert à taux variable.',
        dateLimiteEgaleDateRappelDateAujourdhui:
            '{{martineName}} doit confirmer vos choix et signer les documents requis <strong>aujourd’hui</strong>. Si ces étapes ne sont pas complétées d’ici 23 h 59, votre prêt sera renouvelé avec un terme de 1 an ouvert à taux variable.',
        DescriptionTitreOntario: 'Si vous changez d’avis',
        DescriptionOntario:
            'Vous avez 21\xa0jours après la signature du contrat pour annuler votre renouvellement. Contactez votre caisse pour le faire. En cas d’annulation, les conditions précédentes de votre prêt seront rétablies.',
        informationSignatureContrat:
            'Vous recevrez le contrat à signer quand votre coemprunteur aura confirmé vos choix de renouvellement.',
        sousTitreDate:
            'C’est au tour de votre coemprunteur de confirmer votre offre au plus tard le {{date, D}}{{suffixe}}\xa0{{date, MMMM\xa0YYYY}}',
        sousTitreToday:
            "C’est au tour de votre coemprunteur de confirmer votre offre au plus tard aujourd'hui",
        warningText:
            'Si nous ne recevons pas votre confirmation dans ce délai, vous devrez recommencer le processus. Il pourrait y avoir une variation des taux d’intérêts des offres.',
        choixTitre2Steps: 'Prochaines étapes',
        choixTitre1Step: 'Prochaine étape pour finaliser votre renouvellement',
        choixEtape1: 'Votre coemprunteur doit confirmer vos choix',
        choixEtape2: 'Signer votre contrat',
        alertBasTerminee:
            'Vous recevrez un courriel d’authentification d’ici 3 jours ouvrables. Apposez votre signature électronique sur le contrat dès sa réception.',
        alertOntarioTitle: 'Une précaution de plus',
        alertOntario:
            "Vous souhaitez annuler votre renouvellement? Contactez votre conseiller dans les 21 jours suivant la réception du contrat. Notez qu'en cas d'annulation, les conditions actuelles de votre prêt seront maintenues jusqu'à la fin du terme.",
        tipTitle: 'Rappel Important',
        tip: 'Une fois votre contrat signé, pensez à modifier le montant des virements automatiques de vos paiements dans AccèsD ou auprès de l’institution financière où se trouve votre compte.'
    }
};
