import React from 'react';
import { useTranslation } from 'react-i18next';

import { Typography, Variant, Article, Modal } from '../../styles';

export interface IModalSaveAndLeaveSigneeProps {
    isOpen?: boolean;
    onPrimaryButtonClick?: () => void;
    onSecondaryButtonClick?: () => void;
}

export const ModalSaveAndLeaveSignee: React.FC<
    IModalSaveAndLeaveSigneeProps
> = ({ isOpen, onPrimaryButtonClick, onSecondaryButtonClick }) => {
    const [t] = useTranslation(['ModalSaveAndLeaveSignee']);

    return (
        <>
            {isOpen && (
                <Modal
                    show={true}
                    title={t('modalSaveAndLeaveSigneeTitle')}
                    buttonPrimaryLabel={t(
                        'modalSaveAndLeaveSigneePrimaryButton'
                    )}
                    buttonSecondaryLabel={t(
                        'modalSaveAndLeaveSigneeSecondaryButton'
                    )}
                    onClickPrimaryButton={onPrimaryButtonClick}
                    onClickSecondaryButton={onSecondaryButtonClick}
                >
                    <Typography variant={Variant.p} article={Article.a3}>
                        {t('modalSaveAndLeaveSigneeDescription')}
                    </Typography>
                </Modal>
            )}
        </>
    );
};
