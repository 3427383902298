import { CodeAssurance, END_POINT, IResumeOffre } from './useObtenirResumeApi';
import {
    FrequenceDureeTerme,
    FrequenceRemboursement,
    SousTypeTaux,
    TypeOffreTarification,
    TypeTaux
} from '../useOffreApi/useOffreApi';
import {
    IndicateurFumeur,
    Sexe
} from '../../contexte/useRenouvellementApi/useRenouvellementApi';
import {
    QUERY_PARAM_USER_MODE,
    URLToURLSearchParams,
    getDateStr
} from '../../../../utils';
import { mockInstance, withDelay } from '../../../../api/axiosInstance.mock';

import { TypeAssurance } from '../usePretApi/usePretApi';
import { offresCompletes as offresMockData } from '../useOffreApi/useOffreApi.mock';
import { dataCoEmprunteur1 as sessionMockData } from '../../contexte/useSessionApi/useSessionApi.mock';
import { useSelector } from 'react-redux';

const actualDateStr = getDateStr(new Date());
const nextWeekDateStr = getDateStr(
    new Date(new Date().setDate(new Date().getDate() + 7))
);
const dateCibleRenouvellement = getDateStr(
    new Date(new Date().setDate(new Date().getDate() + 8))
);

// Offre 5 Ans Revisable Ferme (5Dans1) / Fin de terme prêt
export const data: IResumeOffre = {
    isAdmissibleSignatureIntegree: false,
    offreChoisie: {
        idOffre: 130068,
        dateLimite: nextWeekDateStr,
        dateCalcul: actualDateStr,
        amortissement: {
            annee: 20,
            mois: 10
        },
        typeTaux: TypeTaux.REVISABLE,
        sousTypeTaux: SousTypeTaux.FERME,
        frequenceRemboursement: FrequenceRemboursement.MENSUELLE,
        frequenceDureeTerme: FrequenceDureeTerme.ANNEE,
        valeurTauxPreferentiel: 1.78,
        valeurTauxAfficher: 4.29,
        valeurTauxPropose: 2.05,
        valeurTauxEffectif: null,
        valeurTauxPlafond: null,
        valeurDureeTerme: 5.0,
        montantRemboursement: 756.11,
        tauxAdditionnelAssurance: 0.887,
        typeOffreTarification: null,
        dateAcceptationOffre: null,
        dateLimiteApprobation: nextWeekDateStr,
        dateCibleRenouvellement: dateCibleRenouvellement,
        tauxRabaisSubsequent: -0.4,
        dateProchainPaiementChangementFrequence: nextWeekDateStr,
        dateDebutEffetChangementFrequence: actualDateStr,
        delaiApprobation: 10,
        montantRemboursementSansAssurance: 468.11,
        solde: 307072.01,
        indicateurFinDeTerme: true
    },
    assurance: {
        typeAssurance: TypeAssurance.AP,
        indicateurReductionTauxNonFumeur: true,
        codeAssurance: CodeAssurance.VIE_INVALIDITE,
        listeAssure: [
            {
                prenom: 'UNIQUE',
                nom: 'Mocked',
                age: 34,
                codeSexe: Sexe.MASCULIN,
                indicateurFumeur: IndicateurFumeur.OUI,
                pctCouvertureAssuranceVie: 100.0,
                pctCouvertureAssuranceInvalidite: 100.0,
                indicateurEligibiliteAssuranceVie: true,
                indicateurEligibiliteAssuranceInvalidite: true
            }
        ]
    }
};

// Offre 5 Ans Revisable Ferme (5Dans1) / Pas fin de terme prêt
export const dataResumeOffreRevisableFermePasFinDeTerme: IResumeOffre = {
    ...data,
    offreChoisie: {
        ...data.offreChoisie,
        indicateurFinDeTerme: false,
        soldeFinDeTerme: {
            soldeFinTerme: 300.0,
            soldeFinTermeSansAssurance: 250.0
        }
    }
};

// Offre 5 Ans Variable Protege / Pas d'assurance
export const dataResumeOffreVariableProtege: IResumeOffre = {
    ...data,
    offreChoisie: {
        ...data.offreChoisie,
        typeTaux: TypeTaux.VARIABLE,
        sousTypeTaux: SousTypeTaux.PROTEGE,
        valeurTauxEffectif: 1.78,
        valeurTauxPlafond: 5.05,
        tauxRabaisSubsequent: null,
        indicateurFinDeTerme: false
    },
    assurance: {
        typeAssurance: TypeAssurance.NONE,
        indicateurReductionTauxNonFumeur: false,
        codeAssurance: CodeAssurance.AUCUNE
    }
};

const endPoint = END_POINT.replace(
    /{idDemande}/g,
    String(sessionMockData?.contextePret?.idDemande)
);

export const useObtenirResumeApiMock = (): any => {
    const query = URLToURLSearchParams();
    const userMockParam = query.get(QUERY_PARAM_USER_MODE);

    // Store: obtenir l'offre choisie pour rendre les mock dynamique
    const montantSelectionne = useSelector(
        (state: any) => state.renouvellement.montantSelectionne
    );

    let dataToReturn;

    if (montantSelectionne) {
        const offreMockSelected = offresMockData.listeOffre.find(
            ({ montantRemboursement }) =>
                montantRemboursement === montantSelectionne
        );

        dataToReturn = {
            ...data,
            offreChoisie: {
                ...offreMockSelected,
                dateCibleRenouvellement: dateCibleRenouvellement,
                solde: 307072.01,
                amortissement: data?.offreChoisie?.amortissement,
                dateDebutEffetChangementFrequence: actualDateStr,
                dateCalcul: actualDateStr,
                dateLimite: nextWeekDateStr,
                dateLimiteApprobation: nextWeekDateStr
            }
        };
    } else {
        dataToReturn = data;
    }

    if (userMockParam) {
        if (
            userMockParam === 'coemprunteur1OntarioSignatureIntegree' ||
            userMockParam === 'coemprunteur2OntarioSignatureIntegree' ||
            userMockParam === 'emprunteuruniqueontarioSignatureIntegree'
        ) {
            if (dataToReturn && dataToReturn.assurance) {
                dataToReturn.assurance.codeAssurance = null;
            }
        }

        // Ajout des infos pour le mock si SignatureIntegree
        if (
            userMockParam.toUpperCase().includes('SIGNATUREINTEGREE') ||
            userMockParam.toUpperCase().includes('ADMISSIBLEASSURANCE')
        ) {
            dataToReturn.dateProchainPaiement = '20231207';
            dataToReturn.isAdmissibleSignatureIntegree = true;
        }

        //Scenario ajout d'assurance SI sans modification de l'offre choisie
        if (
            userMockParam ===
            'emprunteurUniqueAjoutAssuranceSignatureIntegreeParcours2'
        ) {
            dataToReturn.assurance.codeAssurance =
                CodeAssurance.SOUMISSION_ASSURANCE;
            dataToReturn.offreChoisie.typeOffreTarification =
                TypeOffreTarification.SIMULEE;
            dataToReturn.offreChoisie.dateProchainPaiementChangementFrequence =
                null;
        }
    }

    mockInstance.onGet(endPoint).reply(withDelay([200, dataToReturn], 200));
};
