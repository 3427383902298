import { Box, useMediaQuery } from '@material-ui/core';
import React from 'react';
import {
    ColorHex,
    ContainerMui,
    Typography,
    Variant,
    Police
} from '../../../styles';

interface ColorfulContainerProps {
    title: string;
    color: ColorHex;
    titleColor: ColorHex;
    children: React.ReactNode;
    noSpacer?: boolean;
}

export const ColorfulContainer: React.FC<ColorfulContainerProps> = ({
    title,
    color,
    titleColor,
    children,
    noSpacer
}) => {
    const isMobile = useMediaQuery('(max-width:640px)');
    const isTablet = useMediaQuery('(max-width:1200px)');

    return (
        <ContainerMui noSpacer hidePadding minWidth="100%">
            <ContainerMui
                border={2}
                borderColor={color}
                backgroundColor={color}
                borderRadius="0.5rem 0.5rem 0 0"
                margin="0"
                padding={
                    isTablet ? '1rem 1rem 1rem 1.4rem' : '1rem 1rem 1rem 1.2rem'
                }
                noSpacer
            >
                <Typography
                    variant={Variant.h2}
                    police={Police.bold}
                    color={titleColor}
                    hideMarginBottom
                >
                    {title}
                </Typography>
            </ContainerMui>
            <ContainerMui
                border={2}
                borderRadius="0 0 0.5rem 0.5rem"
                borderColor={color}
                noSpacer={noSpacer}
                padding={
                    isMobile
                        ? '1rem 1.8rem 1rem 1.4rem'
                        : '1.3rem 1.8rem 1.3rem 1.5rem'
                }
            >
                <Box ml="0.5rem">{children}</Box>
            </ContainerMui>
        </ContainerMui>
    );
};
