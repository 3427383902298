// Configuration pour le SPA pour savoir qu'elle url de base utilise pour appeler le backend
// Cela permet de se passer de l'ancien cookie config pour cette partie
// -----------------------------------------
// --------------- PROD ------------------
// -----------------------------------------

import { isSimulateurOriginalSource } from '../utils';

// PROD - ACCESWEB
export const ACCESWEB_PROD = 'https://accweb.mouv.desjardins.com';
export const ACCESWEB_NONPROD =
    'https://accweb-01-20494.mouvdev.desjardins.com';

// PROD - PISE AZURE EXTERNE
export const apiURLProdPise =
    'https://api-azr.desjardins.com/api/distribution-libreservice/financement/habitation/post-achat/service-renouvellement/v1/pise/api';
// -----------------------------------------
// --------------- NON PROD ------------------
// -----------------------------------------
// NonPROD - Localhost HTTP
export const apiURLNonProdLocalUnsecured =
    'http://localhost.dev.desjardins.com:8083/pise/api';
// NonPROD - PISE AZURE EXTERNE
export const apiURLNonProdPise =
    'https://api-azr-dev-d.desjardins.com/api/distribution-libreservice/financement/habitation/post-achat/service-renouvellement/v1/pise/api';

// NonPROD - Localhost HTTPS
// NonPROD - Space DEV
export const apiURLNonProdDev =
    'https://renouvellement-web-dev-parcours-habitation.apps.cfzcea.dev.desjardins.com/pise/api';
// NonPROD - Space QA
export const apiURLNonProdQA =
    'https://renouvellement-web-qa-parcours-habitation.apps.cfzcea.dev.desjardins.com/pise/api';
// NonPROD - Space HA
export const apiURLNonProdHA =
    'https://renouvellement-web-ha-parcours-habitation.apps.cfzcea.dev.desjardins.com/pise/api';
// NonPROD - Space PREPROD
export const apiURLNonProdPreProd =
    'https://renouvellement-web-preprod-parcours-habitation.apps.cfzcea.dev.desjardins.com/pise/api';
// NonPROD - Space AZURE
export const apiURLNonProdAzure =
    'https://renouvellement-web-azure-parcours-habitation.apps.cfzcea.dev.desjardins.com/pise/api';
// NonPROD - MASTER - Space HA
export const apiURLNonProdMaster =
    'https://renouvellement-web-master-parcours-habitation.apps.cfzcea.dev.desjardins.com/pise/api';
// NonPROD - VS + MASTER - Space HA
export const apiURLNonProdVS =
    'https://renouvellement-hypothecaire.accesd.mouvdev.desjardins.com/pise/api';

// Type d'environnement
export enum EnvType {
    LOCAL = 'LOCAL',
    DEV = 'DEV',
    QA = 'QA',
    HA = 'HA',
    PREPROD = 'PREPROD',
    AZURE = 'AZURE',
    HA_MASTER = 'HA_MASTER',
    HA_VS = 'HA_VS',
    PROD = 'PROD'
}

export type EnvConfig = {
    type: EnvType;
    usePise: boolean;
    isProd: boolean;
    baseApiUrl: string;
    accesWebUrl: string;
    // Not required in prod. Default in NonProd: HA
    envSoa?: string;
};

export function getBaseLocationUrl(): string {
    return location.protocol + '//' + location.host;
}

export function getEnvConfig(): EnvConfig {
    const url = window.location.href;
    const envConfig: EnvConfig = {
        type: EnvType.PROD,
        usePise: true,
        isProd: true,
        accesWebUrl: ACCESWEB_PROD,
        baseApiUrl: apiURLProdPise
    };

    // PROD ENV
    if (
        url.includes('.accesd.mouv.desjardins.com') ||
        url.includes('.apps.cfzcec.desjardins.com') ||
        url.includes('.apps.cfzccc.desjardins.com')
    ) {
        return envConfig;
    }

    // NON PROD ENVs
    const usePise = !isSimulateurOriginalSource();
    envConfig.accesWebUrl = ACCESWEB_NONPROD;
    envConfig.usePise = usePise;
    envConfig.isProd = false;
    const baseUrlNonProdDev = usePise ? apiURLNonProdPise : apiURLNonProdDev;

    // Localhost
    if (url.includes('localhost.dev.desjardins.com')) {
        // Localhost - Localhost URL -> Localhost config
        // Example: https://localhost.dev.desjardins.com:3000 (pointe vers API DEV)
        // Example: http://localhost.dev.desjardins.com:3000 (pointe vers API sur mon poste)
        envConfig.type = EnvType.LOCAL;
        envConfig.baseApiUrl = url.startsWith('https:')
            ? baseUrlNonProdDev
            : apiURLNonProdLocalUnsecured;
        envConfig.envSoa = 'dev';
        return envConfig;
    }

    // Pise first - Same for all NonProd
    if (usePise) {
        envConfig.baseApiUrl = apiURLNonProdPise;
    }

    if (url.includes('renouvellement-web-master-')) {
        // NonProd - Direct - MASTER - Space HA
        // Example: https://renouvellement-web-master-parcours-habitation.apps.cfzcea.dev.desjardins.com
        envConfig.type = EnvType.HA_MASTER;
        if (!usePise) {
            envConfig.baseApiUrl = apiURLNonProdMaster;
        }
    } else if (url.includes('.accesd.mouvdev.desjardins.com')) {
        // NonProd - VS - MASTER - Space HA
        // Example: https://renouvellement-hypothecaire.accesd.mouvdev.desjardins.com
        envConfig.type = EnvType.HA_VS;
        if (!usePise) {
            envConfig.baseApiUrl = apiURLNonProdVS;
        }
    } else {
        if (url.includes('renouvellement-web-dev-')) {
            // NonProd - Space DEV
            // Example: https://renouvellement-web-dev-parcours-habitation.cfzcea.mouvdev.desjardins.com
            envConfig.type = EnvType.DEV;
            envConfig.envSoa = 'dev';
            if (!usePise) {
                envConfig.baseApiUrl = apiURLNonProdDev;
            }
        } else if (url.includes('renouvellement-web-qa-')) {
            // NonProd - Space QA
            // Example: https://renouvellement-web-qa-parcours-habitation.cfzcea.mouvdev.desjardins.com
            envConfig.type = EnvType.QA;
            envConfig.envSoa = 'qa';
            if (!usePise) {
                envConfig.baseApiUrl = apiURLNonProdQA;
            }
        } else if (url.includes('renouvellement-web-ha-')) {
            // NonProd - Space HA
            // Example: https://renouvellement-web-ha-parcours-habitation.cfzcea.mouvdev.desjardins.com
            envConfig.type = EnvType.HA;
            if (!usePise) {
                envConfig.baseApiUrl = apiURLNonProdHA;
            }
        } else if (url.includes('renouvellement-web-azure-')) {
            // NonProd - Space AZURE
            // Example: https://renouvellement-web-azure-parcours-habitation.cfzcea.mouvdev.desjardins.com
            envConfig.type = EnvType.AZURE;
            envConfig.envSoa = 'azure';
            if (!usePise) {
                envConfig.baseApiUrl = apiURLNonProdAzure;
            }
        } else {
            // NonProd - Space PREPROD
            // Example: https://renouvellement-web-preprod-parcours-habitation.cfzcea.mouvdev.desjardins.com
            envConfig.type = EnvType.PREPROD;
            envConfig.envSoa = 'preprod';
            if (!usePise) {
                envConfig.baseApiUrl = apiURLNonProdPreProd;
            }
        }
    }
    return envConfig;
}

// Fastest way to know if we are in NonProd
export const isEnvNonProd = (): boolean => {
    const url = window.location.href;
    return (
        url.includes('.mouvdev.desjardins.com') ||
        url.includes('.dev.desjardins.com')
    );
};
