export default {
    en: {
        sommaire: 'Back to AccèsD home',
        title: 'Mortgage renewal',
        annuleeConseillerText:
            'Your mortgage renewal has been completed with an advisor.'
    },
    fr: {
        sommaire: 'Retour à l’accueil',
        title: 'Renouvellement hypothécaire',
        annuleeConseillerText:
            'Votre renouvellement hypothécaire a été complété avec un conseiller.'
    }
};
