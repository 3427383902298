export default {
    en: {
        frequence: 'Frequency',
        tooltip:
            'With accelerated payments, you’ll repay the equivalent of one additional monthly payment per year, which will reduce your amortization period and the total amount you pay in interest.'
    },
    fr: {
        frequence: 'Fréquence',
        tooltip:
            "Le paiement accéléré permet de rembourser l'équivalent d'un paiement mensuel de plus par année, ce qui réduit votre période d'amortissement et le montant total de vos intérêts."
    }
};
