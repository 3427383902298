export default {
    en: {
        back: 'Back',
        cancel: 'Save and leave',
        next: 'Continue',
        title: 'Choose your payment amount',
        ajusterPaiementTitre: 'How to pay less interest',
        ajusterPaiementTexte:
            "The minimum payment is required, but to pay less interest, you can choose a higher amount and accelerate your payments. It's the quickest way to repay your mortgage.",
        typePretTitre: 'Type of mortgage',

        HEBDOMADAIRE: 'Weekly',
        AUX_DEUX_SEMAINES: 'Every 2 weeks',
        MENSUELLE: 'Monthly',
        HEBDOMADAIRE_ACCELEREE: 'Accelerated weekly',
        AUX_DEUX_SEMAINES_ACCELEREE: 'Accelerated, every 2 weeks',
        infoAlertTitle:
            'You can decide to go back to your minimum mortgage payment',
        infoAlertMessage:
            'You can change your mind after signing your contract and go back to the minimum payment.',
        modalCalculerTitle: 'Calculate payment',
        modalCalculerAcceptButton: 'Continue',
        modalCalculerStayButton: 'Stay on page',
        modalCalculerContent:
            'If you don’t click the calculate button, your payment won’t be saved. Do you want to continue?',
        loadingText: 'We’re loading the next step.'
    },
    fr: {
        back: 'Précédent',
        cancel: 'Enregistrer et quitter',
        next: 'Continuer',
        title: 'Choisir vos paiements',
        ajusterPaiementTitre: 'Comment payer moins d’intérêt',
        ajusterPaiementTexte:
            'Le paiement minimum est obligatoire, mais pour payer moins d’intérêt, vous pouvez choisir un montant plus élevé et accélérer la fréquence de vos paiements. C’est la façon la plus rapide de rembourser votre prêt hypothécaire.',
        typePretTitre: 'Type de prêt',

        HEBDOMADAIRE: 'Hebdomadaire',
        AUX_DEUX_SEMAINES: 'Aux 2 semaines',
        MENSUELLE: 'Mensuel',
        HEBDOMADAIRE_ACCELEREE: 'Hebdomadaire accéléré',
        AUX_DEUX_SEMAINES_ACCELEREE: 'Aux 2 semaines accéléré',
        infoAlertTitle: 'C’est possible de revenir au paiement minimum',
        infoAlertMessage:
            'Vous pouvez revenir au paiement minimum si vous changez d’avis après la signature de votre contrat.',
        modalCalculerTitle: 'Calculer le paiement',
        modalCalculerAcceptButton: 'Continuer',
        modalCalculerStayButton: 'Rester sur la page',
        modalCalculerContent:
            'Si vous ne cliquez pas sur le bouton <b>Calculer</b>, votre paiement ne sera pas sauvegardé. Voulez-vous continuer?',
        loadingText: 'Nous préparons la prochaine étape.'
    }
};
