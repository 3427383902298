import React from 'react';

import { Trans, useTranslation } from 'react-i18next';
import { Langues, getLangue } from '../../utils';

interface DateTextProps {
    date: Date;
    translationKey: string;
    children: React.ReactNode;
}

export const DateText: React.FC<DateTextProps> = ({
    date,
    translationKey,
    children
}) => {
    const [t] = useTranslation('DateText');

    const suffixe =
        date.getDate() === 1 && getLangue() === Langues.FR
            ? t('suffixe1er')
            : '';

    return (
        <Trans i18nKey={translationKey}>
            {children}
            {{ suffixe: suffixe }}
        </Trans>
    );
};
