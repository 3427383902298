import React from 'react';
import { useTranslation } from 'react-i18next';

import { LinkAction, LinkActionType, LinkActionTheme } from '../../styles';
import { NavigationPlacement } from '../../components/navigationPlacement/NavigationPlacement';
import { goQuitterParcours } from '../../hooks';
import { getRetourUrl } from '../../utils';

export const Navigation: React.FC<unknown> = () => {
    const [t] = useTranslation('AnnuleeConseiller');

    const handleQuit = () => {
        goQuitterParcours();
    };

    const retourUrl = getRetourUrl();

    return (
        <NavigationPlacement
            renderReturnButton={
                <LinkAction
                    href={retourUrl}
                    type={LinkActionType.return}
                    theme={LinkActionTheme.green}
                    dataTestId="navigation-quit-button"
                    onClick={handleQuit}
                >
                    {t('sommaire')}
                </LinkAction>
            }
        ></NavigationPlacement>
    );
};
