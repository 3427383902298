export default {
    en: {
        offerselected_title:
            '{{firstname}} has selected a mortgage renewal offer',
        dateRappelPlusGrandDateLimiteEgaleDateAujourdhui:
            'You have until <strong>the end of the day</strong> to accept this offer. If the offer isn’t accepted by then, you’ll need to restart the process and the available interest rates may be different.',
        dateRappelPlusGrandDateLimite:
            'You have until <strong>{{date, MMMM\xa0D}}, {{date, YYYY}}</strong> to accept this offer. If the offer isn’t accepted by then, you’ll need to restart the process and the available interest rates may be different.',
        dateLimiteEgaleDateRappel:
            'You have until <strong>{{date, MMMM\xa0D}}, {{date, YYYY}}</strong> to accept this offer. If these steps aren’t completed by the deadline, your mortgage will be renewed with a 1-year open variable-rate term.',
        dateLimiteEgaleDateRappelDateAujourdhui:
            'You have until <strong>the end of the day</strong> to accept this offer. If these steps aren’t completed by 11:59 PM, your mortgage will be renewed with a 1-year open variable-rate term.'
    },
    fr: {
        offerselected_title:
            '{{firstname}} a choisi une offre de renouvellement',
        dateRappelPlusGrandDateLimiteEgaleDateAujourdhui:
            'Vous avez jusqu’à la <strong>fin de la journée</strong> pour confirmer ce choix. Passé ce délai, vous devrez recommencer le processus. Les taux d’intérêt offerts pourraient être différents.',
        dateRappelPlusGrandDateLimite:
            'Vous avez jusqu’au <strong>{{date, D}}{{suffixe}}\xa0{{date, MMMM\xa0YYYY}}</strong> pour confirmer ce choix. Passé ce délai, vous devrez recommencer le processus. Les taux d’intérêt offerts pourraient être différents.',
        dateLimiteEgaleDateRappel:
            'Vous avez jusqu’au <strong>{{date, D}}{{suffixe}}\xa0{{date, MMMM\xa0YYYY}}</strong> pour confirmer ce choix. Si ce délai n’est pas respecté, votre prêt sera renouvelé avec un terme de 1 an ouvert à taux variable.',
        dateLimiteEgaleDateRappelDateAujourdhui:
            'Vous avez jusqu’à la <strong>fin de la journée</strong> pour confirmer ce choix. Si ces étapes ne sont pas terminées d’ici 23 h 59, votre prêt sera renouvelé avec un terme de 1 an ouvert à taux variable.'
    }
};
