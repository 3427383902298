export default {
    en: {
        buttonClose_AVIS_DATE_DEPASSEE: 'Got it',
        buttonClose_AVIS_ASSURANCE_NON_DISPONIBLE: 'Got it',
        buttonClose_AVIS_OFFRE_MODIFIEE: 'Got it',
        buttonClose_AVIS_OFFRE_ACCEPTEE: 'Continue',
        buttonClose_AVIS_CONFLIT_CONVENTION: 'Edit',
        buttonClose_AVIS_COURRIEL_INVALIDE: 'Got it',
        buttonClose_AVIS_PRESTATION_INVALIDITE: 'View offers',
        buttonClose_AVIS_SIGNATURE_EN_COURS: 'Got it',
        titre_AVIS_DATE_DEPASSEE: '	Renew your mortgage',
        titre_AVIS_ASSURANCE_NON_DISPONIBLE:
            'Adding loan insurance online is currently unavailable',
        titre_AVIS_OFFRE_MODIFIEE: 'The selected offer is no longer available',
        titre_AVIS_OFFRE_ACCEPTEE: 'Your co-borrower selected renewal options',
        titre_AVIS_CONFLIT_CONVENTION: 'Edit your information',
        titre_AVIS_COURRIEL_INVALIDE: 'The email address format is invalid',
        titre_AVIS_PRESTATION_INVALIDITE:
            'Select an offer with regular payments',
        titre_AVIS_SIGNATURE_EN_COURS:
            'You’re unable to continue at the moment',
        AVIS_DATE_DEPASSEE:
            'For a limited time, you can still renew your mortgage. If we don’t hear from you, your mortgage will be renewed as specified in the Mortgage renewal notice you received.',
        AVIS_ASSURANCE_NON_DISPONIBLE:
            'You can continue your mortgage renewal without adding insurance. To insure your mortgage, finish your renewal, then contact your caisse.',
        AVIS_OFFRE_MODIFIEE:
            'Our mortgage offers have changed and the offer you previously selected is no longer available. You need to select a new offer.',
        AVIS_OFFRE_ACCEPTEE:
            'Your co-borrower has selected renewal options that include a new term, rate and payment. Look over these choices and see if they work for you.' +
            '<br/><br/>By selecting <strong>Continue</strong>, you’ll start the confirmation process.',
        AVIS_PRESTATION_INVALIDITE:
            'Since one of the insured people receives disability benefits, you must choose an offer with regular payments. You won’t be able to make payment changes.',
        AVIS_CONFLIT_CONVENTION:
            "Enter an email address and phone number different from your co-borrower's information. You can also choose to answer security questions.",
        AVIS_COURRIEL_INVALIDE:
            'Make sure the domain name is correct and enter a valid email address.',
        AVIS_SIGNATURE_EN_COURS:
            'Your co-borrower has already started signing the mortgage renewal documents.<br/><br/>Once {{guyName}} is finished, you’ll receive an email asking you to confirm their choices and sign the documents.<br/><br/><strong>If you don’t receive the email</strong> in the next 2\xa0hours, you can come back and continue the mortgage renewal application with the options you selected.'
    },
    fr: {
        buttonClose_AVIS_DATE_DEPASSEE: 'J’ai compris',
        buttonClose_AVIS_ASSURANCE_NON_DISPONIBLE: 'J’ai compris',
        buttonClose_AVIS_OFFRE_MODIFIEE: 'J’ai compris',
        buttonClose_AVIS_OFFRE_ACCEPTEE: 'Continuer',
        buttonClose_AVIS_CONFLIT_CONVENTION: 'Modifier',
        buttonClose_AVIS_COURRIEL_INVALIDE: 'J’ai compris',
        buttonClose_AVIS_PRESTATION_INVALIDITE: 'Consulter les offres',
        buttonClose_AVIS_SIGNATURE_EN_COURS: 'J’ai compris',
        titre_AVIS_DATE_DEPASSEE: 'Renouveler votre prêt hypothécaire',
        titre_AVIS_ASSURANCE_NON_DISPONIBLE:
            'L’ajout d’assurance prêt n’est pas disponible en ligne',
        titre_AVIS_OFFRE_MODIFIEE: 'L’offre sélectionnée n’est plus disponible',
        titre_AVIS_OFFRE_ACCEPTEE: 'Votre coemprunteur a choisi une option',
        titre_AVIS_CONFLIT_CONVENTION: 'Modifiez vos informations',
        titre_AVIS_COURRIEL_INVALIDE:
            'Le format de l’adresse courriel n’est pas valide',
        titre_AVIS_PRESTATION_INVALIDITE:
            'Choisissez une offre avec le paiement régulier',
        titre_AVIS_SIGNATURE_EN_COURS:
            'Vous ne pouvez pas continuer pour le moment',
        AVIS_DATE_DEPASSEE:
            'Pour une durée limitée, vous pouvez encore renouveler votre prêt. Si nous n’avons aucune nouvelle de votre part, votre prêt sera renouvelé conformément à la lettre d’avis de renouvellement que vous avez reçue.',
        AVIS_ASSURANCE_NON_DISPONIBLE:
            'Vous pouvez continuer le renouvellement hypothécaire sans ajouter la protection. Pour protéger votre prêt, terminez le renouvellement et communiquez ensuite avec votre caisse.',
        AVIS_OFFRE_MODIFIEE:
            'Les offres hypothécaires ont changé et l’offre que vous avez choisie n’est plus disponible. Vous devez choisir une nouvelle offre.',
        AVIS_OFFRE_ACCEPTEE:
            'Votre coemprunteur a choisi une option de renouvellement avec terme, taux et paiement. Vous devez consulter ses choix et confirmer s’ils vous conviennent.' +
            '<br/><br/>En choisissant <strong>Continuer</strong>, vous commencerez le processus d’approbation.',
        AVIS_PRESTATION_INVALIDITE:
            'L’un des assurés reçoit des prestations d’invalidité. Vous devez choisir une offre avec le paiement régulier, sans possibilité de le modifier.',
        AVIS_CONFLIT_CONVENTION:
            'Entrez une adresse courriel et un numéro de téléphone différents des informations de votre coemprunteur. Vous pouvez aussi choisir de répondre par des questions de sécurité.',
        AVIS_COURRIEL_INVALIDE:
            'Vérifiez le nom de domaine et entrez une adresse de courriel valide.',
        AVIS_SIGNATURE_EN_COURS:
            'Votre coemprunteur a déjà commencé à signer les documents du renouvellement hypothécaire.<br/><br/>Quand {{guyName}} aura terminé, vous recevrez un courriel qui vous invite à confirmer ses choix et à signer les documents à votre tour.<br/><br/><strong>Si vous ne recevez pas ce courriel</strong> dans les 2\xa0prochaines heures, vous pouvez revenir pour signer la demande de renouvellement avec vos choix.'
    }
};
