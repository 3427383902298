import { Box, makeStyles, Tooltip, ClickAwayListener } from '@material-ui/core';
import React, { ReactNode } from 'react';
import { ColorDSD, ColorHex } from '../';

import { Icon } from '../';
import { useTranslation } from 'react-i18next';

type Placement =
    | 'top'
    | 'top-end'
    | 'top-start'
    | 'right'
    | 'right-end'
    | 'right-start'
    | 'bottom'
    | 'bottom-end'
    | 'bottom-start'
    | 'left'
    | 'left-end'
    | 'left-start';

type IconSize = 'sm' | 'md' | 'lg' | 'xl';

interface IIconTooltipProps {
    dataCy?: string;
    dataTestId?: string;
    placement?: Placement;
    label?: string | ReactNode;
    iconName?: string;
    iconSize?: IconSize;
    srOnlySuffix?: string;
    isSmallerTooltip?: boolean;
    fill?: ColorDSD;
    fillCustom?: ColorHex;
    onActive?: () => void;
}

const useStyles = makeStyles(() => ({
    smallerTooltip: {
        maxWidth: 150
    }
}));

export const IconTooltip: React.FC<IIconTooltipProps> = ({
    iconSize = 'sm',
    label,
    iconName,
    srOnlySuffix,
    placement,
    isSmallerTooltip = false,
    fill,
    fillCustom,
    onActive
}) => {
    const classes = useStyles();
    const [isOpen, setIsOpen] = React.useState(false);
    const [t] = useTranslation(['IconTooltip']);

    const handleTooltipClose = () => {
        setIsOpen(false);
    };

    const handleTooltipOpen = () => {
        if (typeof onActive === typeof Function) {
            onActive();
        }
        setIsOpen(true);
    };

    return (
        <ClickAwayListener onClickAway={handleTooltipClose}>
            <Tooltip
                open={isOpen}
                title={label}
                arrow={true}
                classes={{
                    tooltip: isSmallerTooltip && classes.smallerTooltip
                }}
                PopperProps={{
                    disablePortal: true
                }}
                onClose={handleTooltipClose}
                onMouseEnter={handleTooltipOpen}
                onMouseLeave={handleTooltipClose}
                enterTouchDelay={0}
                placement={placement}
                disableFocusListener
                disableTouchListener
            >
                <Box
                    width={iconSize === 'sm' ? '16px' : '24px'}
                    height={iconSize === 'sm' ? '16px' : '24px'}
                >
                    <Icon
                        size={iconSize}
                        onClick={onActive}
                        iconName={iconName}
                        fill={fill}
                        fillCustom={fillCustom}
                        srOnlySuffix={t('textHorsEcran') + srOnlySuffix}
                    />
                </Box>
            </Tooltip>
        </ClickAwayListener>
    );
};
