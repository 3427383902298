import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import {
    useRoutes,
    useAssuranceApi,
    Routes,
    IResumeOffre,
    IAssureResume,
    hasSoumissionAssurance
} from '../../hooks';
import {
    hideLoading,
    sendEvent,
    EVENT_CATEGORIES,
    EVENT_ACTIONS,
    EVENT_LABELS
} from '../../features';
import { ResumeAssurance } from './ResumeAssurance';

export interface IResumeAssuranceConnectedProps {
    idDemande: number;
    resumeOffre: IResumeOffre;
    isAssuranceAdmissible: boolean;
    isEditButtonDisplayed: boolean;
    isNoAssuranceDisplayed: boolean;
    isModalProtectionVisible?: boolean; // dans le cas martine choixCoEmprunteur
    assures: Array<IAssureResume>;
}

export const ResumeAssuranceConnected: React.FC<
    IResumeAssuranceConnectedProps
> = ({
    idDemande,
    resumeOffre,
    isEditButtonDisplayed,
    isAssuranceAdmissible,
    isNoAssuranceDisplayed,
    isModalProtectionVisible,
    assures
}) => {
    const dispatch = useDispatch();
    const { goToRoute } = useRoutes();

    // Apis
    const assuranceApiMutation = useAssuranceApi();

    const handleEditAssurance = () => {
        dispatch(
            sendEvent({
                category: EVENT_CATEGORIES.RHN,
                action: EVENT_ACTIONS.CLIC,
                label: EVENT_LABELS.MODIFIER + 'assurance prêt'
            })
        );

        if (hasSoumissionAssurance(resumeOffre?.assurance?.codeAssurance)) {
            assuranceApiMutation.mutateAsync(idDemande);
        } else {
            goToRoute(Routes.EVEIL_ASSURANCE);
        }
    };

    // Retour de l'appel basculer vers assurance -> redirectUrl
    useEffect(() => {
        if (assuranceApiMutation.isSuccess) {
            if (assuranceApiMutation.data?.redirectUrl) {
                const assuranceRedirectUrl = String(
                    assuranceApiMutation.data?.redirectUrl
                ).concat('?action=modifier');

                window.location.href = assuranceRedirectUrl;
            } else {
                dispatch(hideLoading());
            }
        }
    }, [assuranceApiMutation.isSuccess]);

    return (
        <ResumeAssurance
            resumeOffre={resumeOffre}
            assures={assures}
            isAssuranceAdmissible={isAssuranceAdmissible}
            isEditButtonDisplayed={isEditButtonDisplayed}
            isNoAssuranceDisplayed={isNoAssuranceDisplayed}
            isModalProtectionVisible={isModalProtectionVisible}
            onEditAssurance={handleEditAssurance}
        />
    );
};
