import { Box, useMediaQuery } from '@material-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';

import {
    ContainerMui,
    Variant,
    Typography,
    Article,
    IconTooltip,
    Police,
    ColorHex,
    BorderRadiusMui
} from '../../../../styles';

interface IInfoEconomieProps {
    value: number;
    onTooltipActive: () => void;
}

export const InfoEconomie: React.FC<IInfoEconomieProps> = ({
    value,
    onTooltipActive
}) => {
    const [t] = useTranslation('InfoEconomie');
    const isMobile = useMediaQuery('(max-width:820px)');

    const getValue = () => (value > 0 ? value : 0);

    return (
        <>
            <ContainerMui
                backgroundColor={ColorHex.lightConfirmation1}
                borderRadius={BorderRadiusMui.sm}
            >
                <Box display="flex" flexWrap="nowrap">
                    <Typography
                        variant={Variant.p}
                        article={isMobile ? Article.a4 : Article.a3}
                    >
                        {t('economie')}
                    </Typography>
                    <Box paddingTop="4px" paddingLeft="0.5rem" height="40px">
                        <IconTooltip
                            iconName="alertes_contour_information"
                            label={t('tooltipEconomie')}
                            onActive={onTooltipActive}
                            isSmallerTooltip={isMobile}
                        ></IconTooltip>
                    </Box>
                </Box>

                <Box display="flex" justifyContent="center" flexWrap="nowrap">
                    <Box paddingRight={isMobile ? '0.5rem' : '1rem'}>
                        <Typography
                            variant={isMobile ? Variant.h6 : Variant.h3}
                            police={Police.bold}
                        >
                            {t('Number:currency', {
                                value: getValue()
                            })}
                        </Typography>
                    </Box>
                </Box>
            </ContainerMui>
        </>
    );
};
