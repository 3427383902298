import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { OpinionService } from '@oel/js-opinion';

import { getFormattedDate, Langues } from '../../utils';
import { Headings } from '../../styles';
import { Navigation as NavigationInformationsSignature } from './InformationsSignature.navigation';
import { InformationsSignature } from './InformationsSignature';
import {
    deleteCookie,
    DOMAIN_DESJARDINS_COOKIE,
    isMobileAppFromCookies,
    TNR_COOKIE,
    TNR_MOMENTVERITE_VALUES
} from '../../utils';
import {
    useConfirmerConventionApi,
    useConfirmerOffreApi,
    useEnteteApi,
    useListeConventionsApi,
    useObtenirResumeApi,
    useQuestionsApi,
    useRenouvellementApi,
    useRoutes,
    useSessionApi,
    useAssuranceApi,
    ParcoursEmprunteur,
    getEmprunteurTypeConnection,
    hasSoumissionAssurance,
    hasAlreadyAssurance,
    useToggleApi,
    ModeAuthentification
} from '../../hooks';
import {
    hideLoading,
    setMontantSelectionnee,
    sendEvent,
    EVENT_CATEGORIES,
    EVENT_ACTIONS,
    EVENT_LABELS,
    showLoading,
    setCodeAvis
} from '../../features';
import { HeadingProvider } from '../../context';
import {
    getMessageErreur,
    IConventionState
} from './InformationsSignature.utils';

const headingMapping = {
    h2: Headings.h3,
    h3: Headings.h5
};

export const InformationsSignatureConnected: React.FC<unknown> = () => {
    const dispatch = useDispatch();
    const [t, i18n] = useTranslation('InformationsSignature');
    const { getRoute, goToRoute } = useRoutes();

    // Store
    const isLoading = useSelector((state: any) => state.loading.show);
    const montantSelectionne = useSelector(
        (state: any) => state.renouvellement.montantSelectionne
    );

    // Apis
    const { data: toggle } = useToggleApi(true);
    const { data: session } = useSessionApi();
    const { data: renouvellement } = useRenouvellementApi(!!session);

    const idDemande = session?.contextePret?.idDemande;
    const { data: resumeChoix, isFetching: isObtenirResumeFetching } =
        useObtenirResumeApi(
            idDemande,
            'InformationsSignature',
            !!renouvellement
        );
    const { data: questions, isFetching: isQuestionsFetching } =
        useQuestionsApi(idDemande);
    const { data: listeConventions, isFetching: isListeConventionsFetching } =
        useListeConventionsApi(
            idDemande,
            renouvellement?.emprunteurConnecte?.id,
            !!renouvellement
        );
    const { data: entete, isFetching: isEnteteFetching } =
        useEnteteApi(idDemande);

    // Apis mutation
    const assuranceApiMutation = useAssuranceApi();
    const confirmerConventionMutation = useConfirmerConventionApi();
    const confirmerOffreMutation = useConfirmerOffreApi();

    // State
    const [convention, setConvention] = useState<IConventionState>(null);
    const [questionsList, setQuestionsList] = useState([]);
    const [shouldEmailInputErrorDisplay, setShouldEmailInputErrorDisplay] =
        useState(false);
    const [shouldCellPhoneInputErrorDisplay, setShouldCellPhoneErrorDisplay] =
        useState(false);
    const [shouldQuestion1ErrorDisplay, setShouldQuestion1ErrorDisplay] =
        useState(false);
    const [shouldQuestion2ErrorDisplay, setShouldQuestion2ErrorDisplay] =
        useState(false);
    const [shouldAnswer1ErrorDisplay, setShouldAnswer1ErrorDisplay] =
        useState(false);
    const [shouldAnswer2ErrorDisplay, setShouldAnswer2ErrorDisplay] =
        useState(false);
    const [shouldCheckBoxErrorDisplay, setShouldCheckBoxErrorDisplay] =
        useState(false);
    const [isQuebec, setIsQuebec] = useState(true);
    const [isCheckBoxChecked, setIsCheckBoxChecked] = useState(false);
    const [isAdmissibleSI, setIsAdmissibleSI] = useState(false);

    // Prepare questions
    const defineQuestions = (langueContrat: string) => {
        const formattedList = [];

        questions?.forEach((item) => {
            formattedList.push({
                value: item?.idQuestionUnifiee,
                label: t(
                    'questionId_' +
                        item?.idQuestionUnifiee +
                        '_' +
                        langueContrat
                )
            });
        });

        setQuestionsList(formattedList);
    };

    useEffect(() => {
        if (session) {
            const isMartine =
                session?.typeParcoursEmprunteur ===
                ParcoursEmprunteur.COEMPRUNTEUR_MARTINE;

            const etape: EVENT_ACTIONS = isMartine
                ? EVENT_ACTIONS.ETAPE_9
                : EVENT_ACTIONS.ETAPE_5;

            dispatch(
                sendEvent({
                    category: EVENT_CATEGORIES.FORMULAIRE,
                    action: etape,
                    label: EVENT_LABELS.FRM
                })
            );
        }
    }, [session]);

    const handleClickPrevMartineWithAssurance = () => {
        dispatch(showLoading());
        assuranceApiMutation.mutateAsync(idDemande);
    };

    // Retour de l'appel basculer vers assurance -> redirect
    useEffect(() => {
        if (assuranceApiMutation.isSuccess) {
            // Si on recoit un redirectUrl -> Alors on suit l'url ( Assurance )
            if (assuranceApiMutation.data?.redirectUrl) {
                window.location.href = assuranceApiMutation.data?.redirectUrl;
            } else {
                dispatch(hideLoading());
            }
        }
    }, [assuranceApiMutation.isSuccess]);

    //init convention avec les donnees BE
    useEffect(() => {
        if (session && listeConventions && questions && resumeChoix && entete) {
            // Convention de l'emprunteur actuellement connecte
            const conventionRetrieved = listeConventions?.userConventions;
            // Conventions de l'autre co-emprunteur si existant
            const conventionOtherUserRetrieved =
                listeConventions?.userNotConnectedConventions;
            // Si on est martine -> on prend la langue contrat de Guy, sinon notre langue contrat
            const langueConventionUser = session?.isMartine
                ? conventionOtherUserRetrieved?.langue
                : conventionRetrieved?.langue;
            // Langue du contrat selon calcul -> langue choisie sinon langue application
            const langueInitiale: string | Langues =
                langueConventionUser || i18n.language || Langues.FR;

            defineQuestions(langueInitiale);

            // Take cellphone and email from listeConvention or Renouvellement if exists
            let cellphoneRetrieved = conventionRetrieved?.cellulaire;
            let courrielRetrieved = conventionRetrieved?.courriel;
            if (!cellphoneRetrieved) {
                cellphoneRetrieved =
                    renouvellement?.emprunteurConnecte?.cellulaire;
            }
            if (!courrielRetrieved) {
                courrielRetrieved =
                    renouvellement?.emprunteurConnecte?.courriel;
            }

            setIsQuebec(entete?.numeroInstitution === '815');

            // Save TNR COOKIE -> NOT COMPLETE
            OpinionService.enregistrerEvenementTnr(
                TNR_MOMENTVERITE_VALUES.NON_COMPLET,
                isMobileAppFromCookies()
            );

            // set admissibilite SI
            setIsAdmissibleSI(resumeChoix.isAdmissibleSignatureIntegree);

            // Save actual convention
            setConvention({
                cellulaire: !isAdmissibleSI ? cellphoneRetrieved : null,
                question1Id:
                    conventionRetrieved?.question1Id && !isAdmissibleSI
                        ? conventionRetrieved?.question1Id.toString()
                        : '1',
                question2Id:
                    conventionRetrieved?.question2Id && !isAdmissibleSI
                        ? conventionRetrieved?.question2Id.toString()
                        : '2',
                reponse1:
                    conventionRetrieved?.reponse1 && !isAdmissibleSI
                        ? conventionRetrieved?.reponse1
                        : null,
                reponse2:
                    conventionRetrieved?.reponse2 && !isAdmissibleSI
                        ? conventionRetrieved?.reponse2
                        : null,
                modeAuthentification: !isAdmissibleSI
                    ? conventionRetrieved?.modeAuthentification
                        ? conventionRetrieved?.modeAuthentification
                        : ModeAuthentification.CODE_SMS
                    : null,
                langue: langueInitiale,
                courriel: courrielRetrieved
            });

            dispatch(hideLoading());
        }
    }, [session, listeConventions, questions, resumeChoix, entete]);

    useEffect(() => {
        const loadingFlag =
            isObtenirResumeFetching ||
            isQuestionsFetching ||
            isListeConventionsFetching ||
            isEnteteFetching;

        // Ne pas utiliser pour desactiver le spinner
        // La partie du haut s'en occupe apres avoir fait des verifications
        if (loadingFlag === true) dispatch(showLoading());
    }, [
        isObtenirResumeFetching,
        isQuestionsFetching,
        isListeConventionsFetching,
        isEnteteFetching
    ]);

    useEffect(() => {
        // On sauvegarde le taux actuel si on rebascule ici
        if (resumeChoix && !montantSelectionne) {
            dispatch(
                setMontantSelectionnee(
                    resumeChoix?.offreChoisie.montantRemboursement
                )
            );
        }
    }, [resumeChoix]);

    const handleConventionChanged = (prop, value) => {
        // reset errors
        setShouldCellPhoneErrorDisplay(false);
        setShouldEmailInputErrorDisplay(false);
        setShouldQuestion1ErrorDisplay(false);
        setShouldQuestion2ErrorDisplay(false);
        setShouldAnswer1ErrorDisplay(false);
        setShouldAnswer2ErrorDisplay(false);

        // update convention store
        setConvention((convention) => ({ ...convention, [prop]: value }));

        if (prop === 'langue') {
            defineQuestions(value);
        }
    };

    const handleCheckBoxClick = (isChecked: boolean) => {
        setShouldCheckBoxErrorDisplay(false);
        setIsCheckBoxChecked(isChecked);
    };

    const handleClickNext = (
        emailInputErrorDisplay: boolean,
        cellPhoneInputErrorDisplay: boolean,
        question1ErrorDisplay: boolean,
        question2ErrorDisplay: boolean,
        answer1ErrorDisplay: boolean,
        answer2ErrorDisplay: boolean
    ): boolean => {
        let checkBoxErrorDisplay = !isCheckBoxChecked;

        if (isAdmissibleSI) {
            cellPhoneInputErrorDisplay = false;
            question1ErrorDisplay = false;
            question2ErrorDisplay = false;
            answer1ErrorDisplay = false;
            answer2ErrorDisplay = false;
            checkBoxErrorDisplay = false;
        }

        setShouldEmailInputErrorDisplay(emailInputErrorDisplay);
        setShouldCellPhoneErrorDisplay(cellPhoneInputErrorDisplay);
        setShouldQuestion1ErrorDisplay(question1ErrorDisplay);
        setShouldQuestion2ErrorDisplay(question2ErrorDisplay);
        setShouldAnswer1ErrorDisplay(answer1ErrorDisplay);
        setShouldAnswer2ErrorDisplay(answer2ErrorDisplay);
        setShouldCheckBoxErrorDisplay(checkBoxErrorDisplay);

        const isValidForm =
            !emailInputErrorDisplay &&
            !cellPhoneInputErrorDisplay &&
            !question1ErrorDisplay &&
            !question2ErrorDisplay &&
            !answer1ErrorDisplay &&
            !answer2ErrorDisplay &&
            !checkBoxErrorDisplay;

        if (!isValidForm) {
            dispatch(
                sendEvent({
                    category: EVENT_CATEGORIES.FORMULAIRE,
                    action: EVENT_ACTIONS.ERREUR_UTILISATEUR,
                    label: EVENT_LABELS.FRM,
                    messageErreur: getMessageErreur(
                        emailInputErrorDisplay,
                        cellPhoneInputErrorDisplay,
                        question1ErrorDisplay,
                        question2ErrorDisplay,
                        answer1ErrorDisplay,
                        answer2ErrorDisplay,
                        checkBoxErrorDisplay
                    )
                })
            );
            window.scrollTo(0, 0);
            return false;
        }
        return true;
    };

    const handleClickNextModal = () => {
        dispatch(showLoading());

        const shouldSaveQuestion: boolean =
            convention?.modeAuthentification === ModeAuthentification.QUESTIONS;

        // Envoyer seulement la convention choisit
        const conventionToSend: IConventionState = {
            cellulaire: !shouldSaveQuestion ? convention?.cellulaire : null,
            question1Id: shouldSaveQuestion ? convention?.question1Id : null,
            question2Id: shouldSaveQuestion ? convention?.question2Id : null,
            reponse1: shouldSaveQuestion ? convention?.reponse1 : null,
            reponse2: shouldSaveQuestion ? convention?.reponse2 : null,
            langue: convention?.langue,
            modeAuthentification: convention?.modeAuthentification,
            courriel: convention?.courriel
        };

        confirmerConventionMutation.mutateAsync({
            idDemande: idDemande,
            data: {
                ...conventionToSend,
                idOffre: resumeChoix?.offreChoisie.idOffre,
                statutDemande: session?.isMartine ? 'EN_ATTENTE' : 'INITIALE'
            }
        });
    };

    useEffect(() => {
        if (confirmerConventionMutation.isSuccess) {
            if (confirmerConventionMutation.data?.codeAvis) {
                setIsCheckBoxChecked(false);

                // Save code avis in store
                dispatch(
                    setCodeAvis(confirmerConventionMutation.data?.codeAvis)
                );

                dispatch(hideLoading());
            } else {
                // backend a besoin de finir convention avant de confirmer offre
                confirmerOffreMutation.mutateAsync({
                    idDemande: idDemande,
                    idOffre: resumeChoix?.offreChoisie.idOffre,
                    isMartine: session?.isMartine,
                    montantRemboursement: montantSelectionne,
                    isOntario: !isQuebec
                });
            }
        }
    }, [confirmerConventionMutation.isSuccess]);

    useEffect(() => {
        if (confirmerOffreMutation.isSuccess) {
            if (confirmerOffreMutation.data?.codeAvis) {
                setIsCheckBoxChecked(false);

                // Save code avis in store
                dispatch(setCodeAvis(confirmerOffreMutation.data?.codeAvis));
                dispatch(hideLoading());
            } else {
                // Save TNR COOKIE -> COMPLETE
                deleteCookie(TNR_COOKIE, DOMAIN_DESJARDINS_COOKIE, '/');

                OpinionService.enregistrerEvenementTnr(
                    TNR_MOMENTVERITE_VALUES.COMPLET,
                    isMobileAppFromCookies()
                );

                // Route suivante
                // et pas besoin de cacher le spinner global car la prochaine page va s'en occuper
                if (isAdmissibleSI) {
                    goToRoute(getRoute().nextRouteConditional1);
                } else {
                    goToRoute(getRoute().nextRoute);
                }
            }
        }
    }, [confirmerOffreMutation.isSuccess]);

    return (
        !isLoading && (
            <HeadingProvider value={headingMapping}>
                <InformationsSignature
                    convention={convention}
                    questions={questionsList}
                    emprunteurMartine={getEmprunteurTypeConnection(
                        false,
                        renouvellement?.emprunteurs
                    )}
                    isOntario={!isQuebec}
                    isAssuranceAjoute={
                        hasSoumissionAssurance(
                            resumeChoix?.assurance?.codeAssurance
                        ) ||
                        hasAlreadyAssurance(
                            resumeChoix?.assurance?.codeAssurance
                        )
                    }
                    dateCibleRenouvellement={
                        new Date(
                            getFormattedDate(
                                resumeChoix?.offreChoisie
                                    ?.dateCibleRenouvellement,
                                '/'
                            )
                        )
                    }
                    parcoursEmprunteur={session?.typeParcoursEmprunteur}
                    shouldEmailInputErrorDisplay={shouldEmailInputErrorDisplay}
                    shouldCellPhoneInputErrorDisplay={
                        shouldCellPhoneInputErrorDisplay
                    }
                    shouldQuestion1ErrorDisplay={shouldQuestion1ErrorDisplay}
                    shouldQuestion2ErrorDisplay={shouldQuestion2ErrorDisplay}
                    shouldAnswer1ErrorDisplay={shouldAnswer1ErrorDisplay}
                    shouldAnswer2ErrorDisplay={shouldAnswer2ErrorDisplay}
                    shouldCheckBoxErrorDisplay={shouldCheckBoxErrorDisplay}
                    isAdmissibleSI={isAdmissibleSI}
                    toggle={toggle}
                    onConventionChanged={handleConventionChanged}
                    onCheckBoxClick={handleCheckBoxClick}
                />
                <NavigationInformationsSignature
                    session={session}
                    isAdmissibleSI={isAdmissibleSI}
                    renouvellement={renouvellement}
                    convention={convention}
                    questions={questionsList}
                    onClickNext={handleClickNext}
                    onClickNextModal={handleClickNextModal}
                    onClickPrevMartineWithAssurance={
                        handleClickPrevMartineWithAssurance
                    }
                />
            </HeadingProvider>
        )
    );
};
