import React from 'react';
import { useTranslation } from 'react-i18next';

import { Typography, Variant, ColorDSD, Lightbox } from '../../../../styles';

interface ICoutAssuranceModalProps {
    isOpen: boolean;
    onClose: () => void;
}

export const CoutAssuranceModal: React.FC<ICoutAssuranceModalProps> = ({
    isOpen,
    onClose
}) => {
    // Translation
    const [t] = useTranslation(['CoutAssuranceModal']);

    const renderTitreSection = (i18nKey) => (
        <Typography variant={Variant.h6} color={ColorDSD.primary}>
            {t(i18nKey)}
        </Typography>
    );

    const renderTextSection = (i18nKey) => (
        <Typography variant={Variant.p}>{t(i18nKey)}</Typography>
    );

    return (
        <>
            {isOpen && (
                <Lightbox
                    show={true}
                    title={t('titre')}
                    buttonPrimaryLabel={t('buttonClose')}
                    onClickPrimaryButton={onClose}
                    onClose={onClose}
                >
                    {renderTitreSection('labelCoutProtectionActuelle')}
                    {renderTextSection('textCoutProtectionActuelle')}

                    {renderTitreSection('labelCasInvalidite')}
                    {renderTextSection('textCasInvalidite')}

                    {renderTitreSection('labelPrimeAssurance')}
                    {renderTextSection('textPrimeAssurance')}
                </Lightbox>
            )}
        </>
    );
};
