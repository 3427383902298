import { AxiosResponse } from 'axios';
import { useMutation } from 'react-query';

import { STATUT_EMPRUNTEUR_END_POINT } from '../../apiEndPoints';
import {
    axiosInstance,
    getDynamicHeaders,
    IMutationResult,
    reactQueryOptions
} from '../../../../api';

interface IStatutEmprunteur {
    idDemande: number;
    statut: string;
}

const putStatutEmprunteur = async (
    data: IStatutEmprunteur
): Promise<AxiosResponse<any>> => {
    const endPoint = STATUT_EMPRUNTEUR_END_POINT.replace(
        /{idDemande}/g,
        String(data.idDemande)
    );

    const content = { statut: data.statut };

    const { data: response } = await axiosInstance.put(
        endPoint,
        content,
        getDynamicHeaders()
    );

    return response;
};

export const useStatutEmprunteurApi = (): IMutationResult => {
    return useMutation(putStatutEmprunteur, {
        ...reactQueryOptions()
    });
};
