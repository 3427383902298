import React, { useState } from 'react';
import { useMediaQuery } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

import {
    GridCol,
    Variant,
    Typography,
    Police,
    RadioGroupMui,
    RadioMui,
    ISelectOptions,
    ContainerMui
} from '../../../../styles';

import { TelephoneInfoSignature } from '../telephoneInfoSignature/TelephoneInfoSignature';
import { ModeAuthentification } from '../../../../hooks';
import { QuestionInfoSignature } from '../questionInfoSignature/QuestionInfoSignature';
import { IConventionState } from '../../InformationsSignature.utils';

interface IAuthentificationInfoSignature {
    convention: IConventionState;
    questions: ISelectOptions;
    shouldQuestion1ErrorDisplay: boolean;
    shouldQuestion2ErrorDisplay: boolean;
    shouldAnswer1ErrorDisplay: boolean;
    shouldAnswer2ErrorDisplay: boolean;
    shouldCellPhoneInputErrorDisplay: boolean;
    question1Ref: any;
    question2Ref: any;
    cellRef: any;
    onConventionChanged: (prop, value) => void;
}

export const AuthentificationInfoSignature: React.FC<
    IAuthentificationInfoSignature
> = ({
    convention,
    questions,
    shouldQuestion1ErrorDisplay,
    shouldQuestion2ErrorDisplay,
    shouldAnswer1ErrorDisplay,
    shouldAnswer2ErrorDisplay,
    shouldCellPhoneInputErrorDisplay,
    question1Ref,
    question2Ref,
    cellRef,
    onConventionChanged
}) => {
    const [t] = useTranslation('InformationsSignature');
    const isMobile = useMediaQuery('(max-width:820px)');

    // State
    const [selectedQuestionsInUse, setSelectedQuestionsInUse] = useState({
        question1: Number(convention?.question1Id),
        question2: Number(convention?.question2Id)
    });

    // Autentification mode
    const renderAuthenticationOption = (
        labelKey: string,
        mode: ModeAuthentification
    ) => {
        return (
            <RadioMui
                dataTestId={
                    'radio_button_' +
                    (mode === ModeAuthentification.CODE_SMS
                        ? 'telephone_cellulaire'
                        : 'questions_securite')
                }
                label={t(labelKey)}
                value={mode}
                isChecked={convention?.modeAuthentification === mode}
                onChange={() => {
                    onConventionChanged('modeAuthentification', mode);
                }}
            />
        );
    };

    const renderConventionQuestionsInputs = () => {
        return (
            <GridCol col="12" sm="12" md="12">
                {/* Question 1 */}
                <QuestionInfoSignature
                    id={1}
                    paddingBottom="2rem"
                    questionRef={question1Ref}
                    selectedQuestionInUse={selectedQuestionsInUse.question1}
                    conventionId={convention?.question1Id}
                    conventionQuestionReponse={convention?.reponse1}
                    questions={questions}
                    shouldAnswerErrorDisplay={shouldAnswer1ErrorDisplay}
                    shouldQuestionErrorDisplay={shouldQuestion1ErrorDisplay}
                    onQuestionChange={(questionId: string) => {
                        setSelectedQuestionsInUse((prev) => ({
                            ...prev,
                            question1: Number(questionId)
                        }));
                        onConventionChanged('question1Id', questionId);
                    }}
                    onQuestionAnswerChange={(questionAnswer: string) => {
                        onConventionChanged('reponse1', questionAnswer);
                    }}
                />

                {/* Question 2 */}
                <QuestionInfoSignature
                    id={2}
                    paddingBottom={isMobile ? '0' : '1rem'}
                    questionRef={question2Ref}
                    selectedQuestionInUse={selectedQuestionsInUse.question2}
                    conventionId={convention?.question2Id}
                    conventionQuestionReponse={convention?.reponse2}
                    questions={questions}
                    shouldAnswerErrorDisplay={shouldAnswer2ErrorDisplay}
                    shouldQuestionErrorDisplay={shouldQuestion2ErrorDisplay}
                    onQuestionChange={(questionId: string) => {
                        setSelectedQuestionsInUse((prev) => ({
                            ...prev,
                            question2: Number(questionId)
                        }));
                        onConventionChanged('question2Id', questionId);
                    }}
                    onQuestionAnswerChange={(questionAnswer: string) => {
                        onConventionChanged('reponse2', questionAnswer);
                    }}
                />
            </GridCol>
        );
    };

    return (
        <ContainerMui
            padding={
                isMobile
                    ? '0.33rem 1rem 0.33rem 1rem'
                    : '0.5rem 1.5rem 0.5rem 1.5rem'
            }
            spacerDistance={6}
            minWidth="100%"
            minHeight="fit-content"
        >
            {/* Authentication mode */}
            <GridCol col="12" md="8">
                <Typography variant={Variant.h3} police={Police.bold}>
                    {t('authentificationTitre')}
                </Typography>
                <Typography variant={Variant.p}>
                    {t('authentificationModeLabel')}
                </Typography>
                <RadioGroupMui
                    id={'infos_signature_radiogroup_mode'}
                    value="mode"
                >
                    {renderAuthenticationOption(
                        'authentificationModeRadioSMS',
                        ModeAuthentification.CODE_SMS
                    )}
                    {renderAuthenticationOption(
                        'authentificationModeRadioQuestions',
                        ModeAuthentification.QUESTIONS
                    )}
                </RadioGroupMui>
            </GridCol>
            {/* Authentication selection - SMS */}
            <GridCol col="12" sm="12" md="12">
                {convention?.modeAuthentification ===
                ModeAuthentification.CODE_SMS ? (
                    <TelephoneInfoSignature
                        cellRef={cellRef}
                        telephonePreRempli={convention?.cellulaire}
                        shouldCellPhoneInputErrorDisplay={
                            shouldCellPhoneInputErrorDisplay
                        }
                        handleCellPhoneChange={(cellphone: string) => {
                            onConventionChanged('cellulaire', cellphone);
                        }}
                    />
                ) : (
                    renderConventionQuestionsInputs()
                )}
            </GridCol>
        </ContainerMui>
    );
};
