import React, { useEffect } from 'react';
import { StatutEmprunteur } from './StatutEmprunteur';

import { setEtapeActuelle } from '../../features';
import { useDispatch, useSelector } from 'react-redux';
import {
    getPageStep,
    PersonneConnectee,
    useSessionApi,
    useStatutEmprunteurApi
} from '../../hooks';

export interface IStatutEmprunteurConnectedProps {
    skipStepValidation?: boolean;
    skipUpdate?: boolean;
}

export const StatutEmprunteurConnected: React.FC<
    IStatutEmprunteurConnectedProps
> = ({ skipStepValidation, skipUpdate }) => {
    // Hooks
    const dispatch = useDispatch();

    // APIs
    const { data: session } = useSessionApi();
    const statutEmprunteurMutation = useStatutEmprunteurApi();
    const idDemande = session?.contextePret?.idDemande;

    // Store
    const etapeActuelle = useSelector(
        (state: any) => state.renouvellement.etapeActuelle
    );

    const checkStepAndUpdateStatus = () => {
        // Obtenir l'index de la page actuelle
        const etape = getPageStep();
        if (etape) {
            // Si on revient en arriere -> on update le statut
            if (
                !skipUpdate &&
                (skipStepValidation || etapeActuelle > etape?.etape)
            ) {
                // Call update statut only for members
                if (session?.personneConnectee === PersonneConnectee.MEMBRE) {
                    statutEmprunteurMutation.mutateAsync({
                        idDemande: idDemande,
                        statut: etape?.statut
                    });
                }
            }
            // Mise a jour de l'etape actuelle
            dispatch(setEtapeActuelle(etape?.etape));
        }
    };

    useEffect(() => {
        checkStepAndUpdateStatus();
    }, []);

    return <StatutEmprunteur />;
};
