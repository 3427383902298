import { CybermetrieService } from '@oel/js-cybermetrie';
import { createSlice, createAsyncThunk, current } from '@reduxjs/toolkit';

import { ParcoursEmprunteur, PersonneConnectee } from '../../hooks';

const GOOGLE_ANALYTICS_EXTRA_ATTRIBUT_PARCOURS_CO_EMPRUNTEUR = 'coemprunteur';
const GOOGLE_ANALYTICS_EXTRA_ATTRIBUT_PARCOURS_EMPRUNTEUR_UNIQUE =
    'emprunteurUnique';

const sortByTime = (a: any, b: any) => a.time - b.time;

interface ICybermetrieState {
    isEnabled: boolean;
    isInitialized: boolean;
    idDemande: number | null;
    extraAttribut2: string | null;
    choixTerme: string | null;
    messageErreur: string | null;
    langue: string | null;
    platform: string | null;
    orientation: string | null;
    browser: string | null;
    parcoursEmprunteur: string | null;
    pendingEvents: Array<any>;
}

const initialState: ICybermetrieState = {
    isEnabled: false,
    isInitialized: false,
    idDemande: null,
    extraAttribut2: null,
    choixTerme: null,
    messageErreur: null,
    langue: null,
    platform: null,
    orientation: null,
    browser: null,
    parcoursEmprunteur: null,
    pendingEvents: []
};

export enum EVENT_CATEGORIES {
    RHN = 'Renouvellement hypothecaire',
    FORMULAIRE = 'Formulaire',
    TIROIRS = 'Tiroirs'
}

export enum EVENT_ACTIONS {
    ENTREE = 'entree',
    CLIC = 'Clic',
    DEBUTE = 'debute',
    ETAPE_2 = 'etape-2',
    ETAPE_3 = 'etape-3',
    ETAPE_3_A = 'etape-3a',
    ETAPE_4 = 'etape-4',
    ETAPE_5 = 'etape-5',
    ETAPE_5_1 = 'etape-5.1',
    ETAPE_5_2 = 'etape-5.2',
    ETAPE_6 = 'etape-6',
    ETAPE_7 = 'etape-7',
    ETAPE_8 = 'etape-8',
    ETAPE_9 = 'etape-9',
    COMPLETE = 'complete',
    OPEN = 'ouverture',
    ERREUR_UTILISATEUR = 'erreur utilisateur',
    ERREUR_TECHNIQUE = 'erreur technique',
    AFFICHAGE = 'Affichage'
}

export enum EVENT_LABELS {
    FRM = 'frm - renouvellement hypothecaire',
    VOIR_VOTRE_PRET_ACTUEL = 'rhn - voir votre prêt actuel',
    VOIR_MA_PROTECTION = 'rhn - voir votre protection actuelle',
    CLICK2CALL_SPECIALISTE = 'rhn - parler a nos specialistes - ', // + page d'origine
    CLICK2CALL_CONSEILLER = 'rhn - parler a un conseiller - ', // + page d'orgine
    SELECTEUR_TAUX = 'rhn - selecteur de taux',
    FILTRE_TAUX = 'rhn - filtre',
    MODALE_PRET_ACTUEL = 'modale pret actuel',
    MODALE_VOIR_PROTECTION = 'modale voir protection',
    TIROIRS = 'rhn - ', // + titre du tiroire
    REFUSER_COEMPRUNTEUR = 'rhn - refuser coemprunteur',
    ACCEPTER_COEMPRUNTEUR = 'rhn - accepter coemprunteur',
    INFOBULLE = 'rhn - infobulle - ', // + valeure dynamique
    VOIR_DETAIL_PRET = 'rhn - voir detail du prêt', // titre type offre dans extraAttribut2
    COMPRENDRE_TAUX = 'rhn - comprendre les types de taux',
    RECOMMENCER_CALCUL = 'rhn - recommencer calcul',
    MODIFICATION_DE_PAIEMENT = 'rhn - modification du paiement',
    MODIFICATION_DE_FREQUENCE = 'rhn - modification de la frequence',
    CODE_AVIS_OFFRE_MODIFIEE = 'modale - changement doffre',
    COMPRENDRE_COUT_ASSURANCE = "rhn - comprendre le cout de l'assurance",
    ENREGISTRER_ET_QUITTER = 'rhn - enregistrer et quitter - ', // + page d'origine
    PRECEDENT = 'rhn - precedent - ', // + page d'origine
    MODIFIER = 'rhn - modifier - ', // + valeur dynamique
    CONTINUER_SANS_ASSURANCE = 'rhn - continuer sans assurance',
    OBTENIR_ASSURANCE = 'rhn - obtenir une offre assurance'
}

export const setIsAppCybermetrie = (isMobile: boolean): void => {
    const metaOelIsApp: HTMLMetaElement = document.querySelector(
        'meta[name="oel-is-app"]'
    );
    if (metaOelIsApp !== null) {
        metaOelIsApp.setAttribute('content', String(isMobile));
    }
};

export const initGAServices = createAsyncThunk(
    'cybermetrie/initGAServices',
    async (_: void, thunkAPI: any) => {
        const { isEnabled, isInitialized } = thunkAPI.getState().cybermetrie;

        if (isEnabled && isInitialized) {
            return;
        }

        CybermetrieService.initialiser();
        return {
            initialized: true
        };
    }
);

export const cybermetrieSlice = createSlice({
    name: 'cybermetrie',
    initialState: initialState,
    reducers: {
        initCybermetrie: (state, action) => {
            const {
                personneConnectee,
                idDemande,
                extraAttribut2,
                choixTerme,
                messageErreur,
                langue,
                platform,
                orientation,
                browser,
                parcoursEmprunteur
            } = action.payload;

            const isMembre = personneConnectee === PersonneConnectee.MEMBRE;

            return {
                ...state,
                isEnabled: isMembre,
                idDemande: isMembre ? idDemande : null,
                extraAttribut2: isMembre ? extraAttribut2 : null,
                choixTerme: isMembre ? choixTerme : null,
                messageErreur: isMembre ? messageErreur : null,
                langue: isMembre ? langue : null,
                platform: isMembre ? platform : null,
                orientation: isMembre ? orientation : null,
                browser: isMembre ? browser : null,
                parcoursEmprunteur: isMembre ? parcoursEmprunteur : null
            };
        },
        sendEvent: (state, action) => {
            if (!state.isEnabled) {
                return;
            }

            if (!state.isInitialized) {
                const stackedEvent = {
                    ...action.payload,
                    time: Date.now()
                };

                return {
                    ...state,
                    pendingEvents: [...state.pendingEvents, stackedEvent]
                };
            }

            const {
                category,
                action: eventAction,
                label,
                eventValue,
                choixTerme,
                extraAttribut2,
                messageErreur
            } = action.payload;

            const isParcoursEmprunteurUnique =
                state.parcoursEmprunteur === ParcoursEmprunteur.UNIQUE;
            const extraAttributParcoursLabel = isParcoursEmprunteurUnique
                ? GOOGLE_ANALYTICS_EXTRA_ATTRIBUT_PARCOURS_EMPRUNTEUR_UNIQUE
                : GOOGLE_ANALYTICS_EXTRA_ATTRIBUT_PARCOURS_CO_EMPRUNTEUR;

            const dimensionsPersonnalisees = new Map<string, string>([
                ['choixTerme', choixTerme],
                ['messageErreur', messageErreur],
                ['extraAttribut', `${extraAttributParcoursLabel}`],
                ['extraAttribut2', extraAttribut2],
                ['transactionId', state.idDemande]
            ]);

            CybermetrieService.envoyerEvenement(
                {
                    event: 'interractionsPages',
                    action: eventAction,
                    category,
                    label,
                    value: eventValue // le param s'appel value sauf qu'il va apparaitre sous le nom eventValue
                },
                dimensionsPersonnalisees
            );

            return state;
        },
        reset: () => {
            return initialState;
        }
    },
    extraReducers: (builder) => {
        builder.addCase(initGAServices.fulfilled, (state, action) => {
            state.isInitialized = action?.payload?.initialized || false;

            const { pendingEvents } = current(state);
            const sortedPendingEvents = pendingEvents.slice().sort(sortByTime);
            sortedPendingEvents.forEach((event) => {
                cybermetrieSlice.caseReducers.sendEvent(state, {
                    type: 'cybermetrie/sendEvent',
                    payload: event
                });
            });

            state.pendingEvents = [];
        });
    }
});

export const { initCybermetrie, sendEvent, reset } = cybermetrieSlice.actions;

export default cybermetrieSlice.reducer;
