import React from 'react';
import { MenuItem, TextField } from '@material-ui/core';

interface IOptions {
    value: string;
    label: string;
    isDisabled?: boolean;
    isSelected?: boolean;
}

export type ISelectOptions = IOptions[];

interface ISelectProps {
    id?: string;
    dataTestId?: string;
    value?: string;
    onChange: (e: any) => void;
    onFocus?: () => void;
    options: ISelectOptions;
    error?: boolean;
    helperText?: string;
    selectRef?: any;
}

export const Select: React.FC<ISelectProps> = ({
    id,
    dataTestId = 'select',
    value,
    onChange,
    onFocus,
    options,
    error,
    helperText,
    selectRef
}) => {
    return (
        <TextField
            ref={selectRef}
            data-testid={dataTestId}
            size="small"
            variant="outlined"
            id={id}
            error={error}
            helperText={helperText}
            select
            value={value}
            onFocus={onFocus}
            onChange={onChange}
            fullWidth
            SelectProps={{
                MenuProps: {
                    anchorOrigin: {
                        vertical: 'bottom',
                        horizontal: 'left'
                    },
                    getContentAnchorEl: null
                }
            }}
        >
            {options.map((option) => (
                <MenuItem
                    key={option.value}
                    data-testid={'select-option-' + option.value}
                    value={option.value}
                    disabled={option.isDisabled}
                >
                    {option.label}
                </MenuItem>
            ))}
        </TextField>
    );
};
