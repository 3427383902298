import React, { useEffect } from 'react';
import { InteretRetard } from './InteretRetard';
import { useSelector, useDispatch } from 'react-redux';
import { setLoading } from '../../features';
import { HeadingProvider } from '../../context';
import { usePretApi, useRenouvellementApi, useSessionApi } from '../../hooks';
import { Navigation as NavigationInteretRetard } from './InteretRetard.navigation';
import { Box } from '@material-ui/core';
import { Headings } from '../../styles';

const headingMapping = {
    h1: Headings.h3,
    h2: Headings.h5
};

export const InteretRetardConnected: React.FC<unknown> = () => {
    // Store
    const dispatch = useDispatch();
    const isLoading = useSelector((state: any) => state.loading.show);

    // API
    const { data: session, isFetching: isSessionFetching } = useSessionApi();
    const { data: renouvellement, isFetching: isRenouvellementFetching } =
        useRenouvellementApi(!!session);
    const { data: pret, isFetching: isPretFetching } = usePretApi(
        session?.contextePret?.idDemande,
        !!renouvellement
    );

    useEffect(() => {
        const loadingFlag =
            isSessionFetching || isRenouvellementFetching || isPretFetching;

        dispatch(setLoading(loadingFlag));
    }, [isSessionFetching, isRenouvellementFetching, isPretFetching]);

    return (
        !isLoading && (
            <HeadingProvider value={headingMapping}>
                <InteretRetard
                    montantTotalInteretRetard={
                        renouvellement?.montantTotalInteretRetard
                    }
                    date={session?.dateSysteme}
                    typePret={pret?.typePret}
                />
                <NavigationInteretRetard />
                <Box mb="6rem" />
            </HeadingProvider>
        )
    );
};
