export default {
    en: {
        titre: 'Talk to our specialists',
        demanderOnVousAppel: 'Have us call you',
        demanderOnVousAppelDescription:
            'We can call you as soon as possible or when it’s convenient for you.',
        demanderOnVousAppelLink: 'Schedule a call',
        appelerMaintenant: 'Call us now',
        appelerMaintenantDescription:
            'We’ll take your call as soon as possible.',
        numeroTelephone: '1-844-626-2476',
        numeroTelephoneAriaLabel:
            'This link will launch your default phone software.',
        click2call_aria: 'This link will open in a new window.'
    },
    fr: {
        titre: 'Parlez à nos spécialistes',
        demanderOnVousAppel: 'Demandez qu’on vous appelle',
        demanderOnVousAppelDescription:
            'Nous pouvons vous appeler dès que possible ou au moment de votre choix.',
        demanderOnVousAppelLink: 'Planifier un appel',
        appelerMaintenant: 'Appelez maintenant',
        appelerMaintenantDescription: 'Nous vous répondrons dès que possible.',
        numeroTelephone: '1 844 626-2476',
        numeroTelephoneAriaLabel:
            'Ce lien lancera votre logiciel de téléphonie par défaut.',
        click2call_aria: 'Ce lien s’ouvrira dans une nouvelle fenêtre.'
    }
};
