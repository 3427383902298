import { DateText, Police, Typography, Variant } from '../../../../styles';

import React from 'react';
import { useTranslation } from 'react-i18next';

interface StatusCoEmprunteurDeuxProps {
    guyFirstName: string;
    dateLimite: Date;
    delaiApprobation: number;
    dateRappel: Date;
    dateAujourdhui: Date;
}
export const StatusCoEmprunteurDeux: React.FC<StatusCoEmprunteurDeuxProps> = ({
    guyFirstName,
    dateLimite,
    dateRappel,
    dateAujourdhui
}) => {
    const [t] = useTranslation(['StatusCoEmprunteurDeux']);

    const getTextDateLimite = () => {
        // Date rappel moins un jour parce que dans le backend si on met la date cible renouvellement = date rappel et ensuite date limite = date cible renouvellement - 1.
        // Donc, ça donne la date limite = date rappel - 1 donc pour voir si il sont égal il faut faire moins 1 jour sur la date de rappel sinon ils peuveunt jamais être égal
        // sauf si c'est la à la date d'aujourd'hui.
        const dateRappelMoins1Jour = new Date(dateRappel);
        dateRappelMoins1Jour.setDate(dateRappelMoins1Jour.getDate() - 1);
        if (dateLimite.getTime() < dateRappelMoins1Jour.getTime()) {
            if (dateLimite.getTime() === dateAujourdhui.getTime()) {
                return 'dateRappelPlusGrandDateLimiteEgaleDateAujourdhui';
            } else {
                return 'dateRappelPlusGrandDateLimite';
            }
        } else {
            if (dateRappel.getTime() === dateAujourdhui.getTime()) {
                return 'dateLimiteEgaleDateRappelDateAujourdhui';
            } else {
                return 'dateLimiteEgaleDateRappel';
            }
        }
    };

    return (
        <React.Fragment>
            <Typography variant={Variant.h3} police={Police.bold}>
                {t('offerselected_title', {
                    firstname: guyFirstName
                })}
            </Typography>
            <p>
                <DateText
                    date={dateLimite}
                    translationKey={
                        'StatusCoEmprunteurDeux:' + getTextDateLimite()
                    }
                >
                    {{ date: dateLimite }}
                </DateText>
            </p>
        </React.Fragment>
    );
};
