export default {
    en: {
        titre: 'Confirm your renewal offer',
        instructionsTitre: 'It’s time to review your offer',
        click2call: 'talk to our specialists',
        click2call_aria: 'This link will open in a new window.',
        instructionsDesc1:
            'To take advantage of this renewal offer, please confirm your decision in a secure manner. ',
        instructionsDesc2_1: 'If this offer no longer meets your needs, ',
        instructionsDesc2_2: ' to discuss other options.',
        instructionsDescMartineDate:
            "You have until <1>{{date, D MMMM YYYY}}</1> to confirm your co-borrower's offer",
        instructionDescMartineAujourdhui:
            'You have until today to confirm your co-borrower’s offer',
        back: 'Back',
        cancel: 'Save and leave',
        renouveller: 'Confirm',
        refuser: 'Change renewal details',
        modaleTitre: 'Change renewal details?',
        modaleMessage:
            "If you change the renewal details, you’ll cancel all your co-borrower's choices—the rate and the payment amount and frequency. The available rates may be different.",
        modaleMessageSI:
            'Your co-borrower will need to sign a new contract with the options you select.',
        modaleAcceptButton: 'Change renewal details',
        modaleStayButton: 'Keep current details'
    },
    fr: {
        titre: 'Confirmer votre offre de renouvellement',
        instructionsTitre: 'C’est le moment de réviser vos choix',
        click2call: 'parler à nos spécialistes',
        click2call_aria: 'Ce lien s’ouvrira dans une nouvelle fenêtre.',
        instructionsDesc1:
            'Pour bénéficier de cette offre de renouvellement, confirmez vos choix de façon sécurisée. ',
        instructionsDesc2_1:
            'Si cette offre ne correspond plus à vos besoins, vous pouvez ',
        instructionsDesc2_2: ' pour examiner d’autres options.',
        instructionsDescMartineDate:
            "Vous avez jusqu’au {{date, D}}{{suffixe}}\xa0{{date, MMMM\xa0YYYY}} pour confirmer l'offre de votre coemprunteur.",
        instructionDescMartineAujourdhui:
            "Vous avez jusqu'à aujourd'hui pour confirmer l'offre de votre coemprunteur.",
        back: 'Précédent',
        cancel: 'Enregistrer et quitter',
        renouveller: 'Confirmer',
        refuser: 'Choisir une autre option',
        modaleTitre: 'Choisir une autre option?',
        modaleMessage:
            'Si vous choisissez une autre option, vous annulerez les choix de votre coemprunteur – taux, fréquence et montant des paiements. Les taux disponibles pourraient être différents.',
        modaleMessageSI:
            'Votre coemprunteur devra signer un nouveau contrat avec les options que vous choisissez.',
        modaleAcceptButton: 'Choisir une autre option',
        modaleStayButton: 'Conserver l’option actuelle'
    }
};
