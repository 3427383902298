import { useQuery, UseQueryResult } from 'react-query';

import {
    axiosInstance,
    getDynamicHeaders,
    reactCachedQueryOptions
} from '../../../../api';
import { DEMANDE_END_POINT } from '../../apiEndPoints';

export interface IQuestion {
    id: number;
    libelle: string;
    idQuestionUnifiee: number;
}

export const END_POINT = DEMANDE_END_POINT + '/conventions/questions';

const fetch = async (idDemande: number) => {
    const endPoint = END_POINT.replace(/{idDemande}/g, String(idDemande));

    const { data } = await axiosInstance.get(endPoint, getDynamicHeaders());

    return data;
};

export function useQuestionsApi(
    idDemande: number = undefined
): UseQueryResult<Array<IQuestion>, Error> {
    return useQuery<Array<IQuestion>, Error>(
        ['question', idDemande],
        () => fetch(idDemande),
        {
            ...reactCachedQueryOptions(),
            enabled: !!idDemande
        }
    );
}
