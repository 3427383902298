import React from 'react';

import { ColorDSD, ColorHex } from '../';

export interface IHr {
    color?: ColorDSD;
    backgroundColor?: ColorHex;
    height?: string;
}

export const Hr: React.FC<IHr> = ({ color, backgroundColor, height }) => (
    <hr
        className={color}
        style={{
            height: height,
            backgroundColor: backgroundColor
        }}
    ></hr>
);
