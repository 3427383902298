import React from 'react';

type TagIconColorLight =
    | 'light-system-t'
    | 'light-information-4'
    | 'light-confirmation-4'
    | 'light-warning-4'
    | 'light-error-4';

type TagIconColorDark =
    | 'dark-system-t'
    | 'dark-information-3'
    | 'dark-confirmation-3'
    | 'dark-warning-3'
    | 'dark-error-3';

export enum ThemeTag {
    CONFIRMATION = 'confirmation',
    INFORMATION = 'information',
    WARNING = 'warning',
    ERROR = 'error',
    PROMOTION = 'promo',
    NEW = 'new',
    YELLOW = 'yellow',
    PRIMARY = 'primary'
}

interface ITagProps {
    label: string;
    theme?: ThemeTag;
    iconName?: string;
    iconColor?: TagIconColorLight | TagIconColorDark;
    srOnlyIcon?: string;
    size?: string;
    isDark?: boolean;
}

export const Tag: React.FC<ITagProps> = ({
    theme,
    iconName,
    srOnlyIcon,
    iconColor,
    size,
    isDark,
    label
}) => (
    // @ts-ignore
    <dsd-tag
        theme={theme}
        size={size}
        dark={isDark}
        icon-name={iconName}
        icon-color={iconColor}
        sr-only-icon={srOnlyIcon}
    >
        {label}
        {/* @ts-ignore */}
    </dsd-tag>
);
