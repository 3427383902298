import React from 'react';
import { Box, useMediaQuery } from '@material-ui/core';
import {
    TypeAssurance,
    TypeOffre,
    TypeOffreTarification,
    isVariableProtege
} from '../../../../hooks';
import { getLangue } from '../../../../utils';
import { GridCol } from '../../../../styles';
import { Card } from '../card/Card';

interface CardListProps {
    isCoemprunteur: boolean;
    typeAssurance: TypeAssurance;
    cardList: any[];
    onOfferSelected: (
        idDemande: number,
        idOffre: number,
        isOfferVariableProtege: boolean,
        tauxPropose: number
    ) => void;
}

const listOffreTitreDeuxLigneEn: string[] = [
    TypeOffre.CINQ_ANS_VARIABLE_REDUIT,
    TypeOffre.CINQ_ANS_REVISABLE_FERME,
    TypeOffre.CINQ_ANS_VARIABLE_PROTEGE
];

const listOffreTitreDeuxLigneEcranMediumFr: string[] = [
    TypeOffre.CINQ_ANS_REVISABLE_FERME
];

const listOffreTitreDeuxLigneEcranSmallFr: string[] = [
    TypeOffre.CINQ_ANS_REVISABLE_FERME,
    TypeOffre.CINQ_ANS_VARIABLE_PROTEGE
];

export const CardList: React.FC<CardListProps> = ({
    isCoemprunteur,
    typeAssurance,
    cardList,
    onOfferSelected
}) => {
    const isEcranMedium = useMediaQuery('(max-width:1440px)'); // utilise pour carte offre
    const isEcranSmall = useMediaQuery('(max-width:1200px)'); // utilise pour carte offre
    const isTablet = useMediaQuery('(max-width:991px)');

    // Si la card est dans les derniere on enleve le spacer
    const getIsBottomCard = (listCards: any[], currentCard: any): boolean => {
        const cardPosition = listCards.findIndex(
            (c) => c.typeOffre === currentCard.typeOffre
        );
        const lastCardPosition = listCards.length - 1;
        const isEvenNumberOfCards: boolean =
            !isTablet && listCards.length % 2 === 0;

        if (isEvenNumberOfCards) {
            if (
                cardPosition === lastCardPosition ||
                cardPosition === lastCardPosition - 1
            ) {
                return true;
            }
        } else {
            if (cardPosition === lastCardPosition) {
                return true;
            }
        }

        return false;
    };

    // fonction pour determiner l'espacement a mettre pour aligner interet
    const getIsVoisinPromo = (listCards: any[], currentCard: any): boolean => {
        return (
            getCardVoisin(listCards, currentCard)?.typeOffreTarification ===
            TypeOffreTarification.PROMOTION
        );
    };

    // fonction pour determiner l'espacement a mettre pour aligner interet
    const getIsVoisinTitreSurDeuxLigne = (
        listCards: any[],
        currentCard: any
    ): boolean => {
        const voisin = getCardVoisin(listCards, currentCard);
        return getIsTitreSurDeuxLigne(voisin?.typeOffre);
    };

    const getListTitreSurDeuxLigne = (): string[] => {
        const langue = getLangue();
        let listOffreTitreDeuxLigne: string[] = [];

        if (isEcranMedium) {
            if (langue === 'fr') {
                if (isEcranSmall) {
                    listOffreTitreDeuxLigne =
                        listOffreTitreDeuxLigneEcranSmallFr;
                } else {
                    listOffreTitreDeuxLigne =
                        listOffreTitreDeuxLigneEcranMediumFr;
                }
            } else {
                listOffreTitreDeuxLigne = listOffreTitreDeuxLigneEn;
            }
        }

        return listOffreTitreDeuxLigne;
    };

    // fonction pour retourner si le titre est sur deux ligne
    const getIsTitreSurDeuxLigne = (typeOffre: string): boolean => {
        const listOffreDeuxLigne: string[] = getListTitreSurDeuxLigne();

        let isTitreSurDeuxLigne = false;
        listOffreDeuxLigne.forEach((typeOffreSurDeuxLigne) => {
            if (!isTitreSurDeuxLigne && typeOffre === typeOffreSurDeuxLigne) {
                isTitreSurDeuxLigne = true;
            }
        });

        return isTitreSurDeuxLigne;
    };

    // fonction pour trouver la carte voisin quand non mobile
    const getCardVoisin = (listCards: any[], currentCard: any): any => {
        const cardPosition = listCards.findIndex(
            (c) => c.typeOffre === currentCard.typeOffre
        );
        const cardPositionIsEven = cardPosition % 2 === 0;
        if (cardPositionIsEven) {
            return listCards[cardPosition + 1];
        } else {
            return listCards[cardPosition - 1];
        }
    };

    return (
        <Box display="flex" flexWrap="wrap" margin="0 -12px">
            {cardList &&
                cardList.map((offre, key) => {
                    const isOfferVariableProtege = isVariableProtege(offre);
                    return (
                        <GridCol
                            col="12"
                            sm="12"
                            md="6"
                            key={key}
                            dataTestId={offre.idOffre}
                        >
                            <Card
                                offre={offre}
                                isCoemprunteur={isCoemprunteur}
                                typeAssurance={typeAssurance}
                                isBottomCard={getIsBottomCard(cardList, offre)}
                                isTitreSurDeuxLigne={getIsTitreSurDeuxLigne(
                                    offre?.typeOffre
                                )}
                                isVoisinPromo={getIsVoisinPromo(
                                    cardList,
                                    offre
                                )}
                                isVoisinTitreSurDeuxLigne={getIsVoisinTitreSurDeuxLigne(
                                    cardList,
                                    offre
                                )}
                                onSelectOffer={() => {
                                    onOfferSelected(
                                        offre.idDemande,
                                        offre.idOffre,
                                        isOfferVariableProtege,
                                        offre.interestRate
                                    );
                                }}
                            />
                        </GridCol>
                    );
                })}
        </Box>
    );
};
