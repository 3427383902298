import React from 'react';

import { ColorHex, ColorDSD } from '../';

interface IIconProps {
    fill?: ColorDSD;
    fillCustom?: ColorHex;
    iconName?: string;
    size?: string;
    srOnlySuffix?: string;
    onClick?: () => void;
}

export const Icon: React.FC<IIconProps> = ({
    fill,
    fillCustom,
    iconName,
    srOnlySuffix,
    size,
    onClick
}) => (
    // @ts-ignore
    <dsd-icon
        fill={fill}
        fill-custom={fillCustom}
        size={size}
        icon-name={iconName}
        sr-only-suffix={srOnlySuffix}
        onClick={onClick}
    />
);
