import React from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles, createStyles } from '@material-ui/core/styles';

import {
    Typography,
    Variant,
    Grid,
    GridRow,
    GridCol,
    Article,
    Spacer,
    Link,
    ContainerMui,
    EnumLinkType
} from '../../styles';

interface ILegalProps {
    isQuebec: boolean;
}

const useStyles = makeStyles(() =>
    createStyles({
        indentLevelOne: {
            marginLeft: '1em'
        },
        indentLevelTwo: {
            marginLeft: '2em'
        }
    })
);

export const Legal: React.FC<ILegalProps> = ({ isQuebec }) => {
    const [t, i18n] = useTranslation(['Legal']);
    const classes = useStyles();

    const renderLegalHeader = () => (
        <>
            <Typography
                variant={Variant.h1}
                dataTestId="titre_conditions_legal"
            >
                {t('legal_general_title')}
            </Typography>
        </>
    );

    const renderLegalTexteSommaire = () => (
        <>
            <Typography variant={Variant.p} article={Article.a4}>
                {isQuebec ? (
                    <>
                        {t('legal_general_partie_1')}
                        <strong>{t('legal_general_partie_bold')}</strong>
                        {t('legal_general_partie_2')}
                    </>
                ) : (
                    t('legal_ontario_general')
                )}
            </Typography>
        </>
    );

    const renderLegalInteretTitle = () => (
        <>
            <Spacer></Spacer>
            <Typography variant={Variant.h5}>
                {t('legal_interet_title')}
            </Typography>
        </>
    );

    const renderLegalInteretTauxFixe = () => (
        <>
            <Typography variant={Variant.h6}>
                {t('legal_interet_tauxfixe_title')}
            </Typography>
            <Typography variant={Variant.p}>
                {isQuebec ? (
                    <>
                        {t('legal_interet_tauxfixe_desc_partie_1')}
                        <strong>
                            {t('legal_interet_tauxfixe_desc_partie_bold')}
                        </strong>
                        {t('legal_interet_tauxfixe_desc_partie_2')}
                    </>
                ) : (
                    <>
                        {t('legal_ontario_interet_tauxfixe_desc_partie_1')}
                        <strong>
                            {t(
                                'legal_ontario_interet_tauxfixe_desc_partie_bold'
                            )}
                        </strong>
                        {t('legal_ontario_interet_tauxfixe_desc_partie_2')}
                    </>
                )}
            </Typography>
            <Typography variant={Variant.p}>
                <strong>
                    {t('legal_interet_tauxfixe_taux_interet_partie_bold')}
                </strong>
                {isQuebec
                    ? t('legal_interet_tauxfixe_taux_interet_partie_texte')
                    : t(
                          'legal_ontario_interet_tauxfixe_taux_interet_partie_texte'
                      )}
            </Typography>
        </>
    );

    const renderLegalInteretTauxVariable = () => (
        <>
            <Typography variant={Variant.h6}>
                {t('legal_interet_tauxvariable_title')}
            </Typography>
            <Typography variant={Variant.p}>
                {isQuebec ? (
                    <>
                        {t('legal_interet_tauxvariable_paragraph_1_partie_1')}
                        <strong>
                            {t(
                                'legal_interet_tauxvariable_paragraph_1_partie_bold'
                            )}
                        </strong>
                        {t('legal_interet_tauxvariable_paragraph_1_partie_2')}
                    </>
                ) : (
                    <>
                        {t(
                            'legal_ontario_interet_tauxvariable_paragraph_1_partie_1'
                        )}
                        <strong>
                            {t(
                                'legal_ontario_interet_tauxvariable_paragraph_1_partie_bold'
                            )}
                        </strong>
                        {t(
                            'legal_ontario_interet_tauxvariable_paragraph_1_partie_2'
                        )}
                    </>
                )}
            </Typography>
            <Typography variant={Variant.p}>
                {isQuebec
                    ? t('legal_interet_tauxvariable_paragraph_2')
                    : t('legal_ontario_interet_tauxvariable_paragraph_2')}
            </Typography>
            <Typography variant={Variant.p}>
                {t('legal_interet_tauxvariable_paragraph_3')}
            </Typography>
            <Typography variant={Variant.p}>
                <strong>
                    {t('legal_interet_tauxvariable_paragraph_4_bold')}
                </strong>
                {t('legal_interet_tauxvariable_paragraph_4_texte')}
            </Typography>
        </>
    );

    const renderLegalRemboursement = () => (
        <>
            <Spacer></Spacer>
            <Typography variant={Variant.h5}>
                {t('legal_remboursement_title')}
            </Typography>
            <Typography variant={Variant.p}>
                {isQuebec
                    ? t('legal_remboursement_desc')
                    : t('legal_ontario_remboursement_desc')}
            </Typography>
        </>
    );

    const renderLegalPretNonPremierRang = () => (
        <>
            <Spacer></Spacer>
            <Typography variant={Variant.h5}>
                {t('legal_pretnonpremierrang_remboursement_title')}
            </Typography>
            <Typography variant={Variant.p}>
                {t('legal_pretnonpremierrang_remboursement_desc')}
            </Typography>
        </>
    );

    const renderLegalAugmentationPaiementsPeriodiques = () => (
        <>
            <Spacer></Spacer>
            <Typography variant={Variant.h5}>
                {t('legal_augmentation_paiements_periodiques_title')}
            </Typography>
            <Typography variant={Variant.p}>
                {isQuebec
                    ? t(
                          'legal_augmentation_paiements_periodiques_desc_partie_1'
                      )
                    : t(
                          'legal_ontario_augmentation_paiements_periodiques_desc_partie_1'
                      )}
                <Link
                    type={EnumLinkType.EXTERNAL}
                    href={t('legal_lien_gerer_pret')}
                    ariaLabel={t('click2call_aria')}
                >
                    <strong>{t('legal_lien_gerer_pret')}</strong>
                </Link>
                {t('legal_augmentation_paiements_periodiques_desc_partie_2')}
            </Typography>
        </>
    );

    const renderLegalRemboursementParAnticipation = () => (
        <>
            <Spacer></Spacer>
            <Typography variant={Variant.h5}>
                {t('legal_remboursement_anticipation_title')}
            </Typography>
            <Typography variant={Variant.h6}>
                {t('legal_remboursement_anticipation_subtitle')}
            </Typography>
            <Typography variant={Variant.p}>
                {t('legal_remboursement_anticipation_desc_partie_1')}
                <Link
                    type={EnumLinkType.EXTERNAL}
                    href={t('legal_lien_gerer_pret')}
                    ariaLabel={t('click2call_aria')}
                >
                    <strong>{t('legal_lien_gerer_pret')}</strong>
                </Link>
                {t('legal_remboursement_anticipation_desc_partie_2')}
            </Typography>
        </>
    );

    const renderLegalRemboursementsPartiels = (isFirstBloc: boolean) => (
        <>
            <Spacer></Spacer>
            <Typography variant={Variant.h5}>
                {t('legal_remboursements_partiels_title')}
            </Typography>
            <Typography variant={Variant.p}>
                {t('legal_remboursements_partiels_desc_partie_1')}
                <strong>
                    {t('legal_remboursements_partiels_desc_partie_bold')}
                </strong>
                {isFirstBloc
                    ? t('legal_remboursements_partiels_desc_partie_2_firstbloc')
                    : t(
                          'legal_remboursements_partiels_desc_partie_2_secondbloc'
                      )}
            </Typography>
            <Typography variant={Variant.p}>
                {isQuebec
                    ? t('legal_remboursements_partiels_desc_paragraphe_2')
                    : t(
                          'legal_ontario_remboursements_partiels_desc_paragraphe_2'
                      )}
            </Typography>
        </>
    );

    const renderLegalRemboursementTotal = () => (
        <>
            <Spacer></Spacer>
            <Typography variant={Variant.h5}>
                {t('legal_remboursement_total_title')}
            </Typography>
            <Typography variant={Variant.p}>
                {t('legal_remboursement_total_desc_partie_1')}
                <strong>
                    {t('legal_remboursement_total_desc_partie_bold')}
                </strong>
                {t('legal_remboursement_total_desc_partie_2')}
            </Typography>
            <Typography variant={Variant.p} className={classes.indentLevelOne}>
                {t('legal_remboursement_total_desc_section_a_partie_1')}
                <strong>
                    {t(
                        'legal_remboursement_total_desc_section_a_partie_bold_1'
                    )}
                </strong>
                {t('legal_remboursement_total_desc_section_a_partie_2')}
                <strong>
                    {t(
                        'legal_remboursement_total_desc_section_a_partie_bold_2'
                    )}
                </strong>
            </Typography>
            <Typography variant={Variant.p} className={classes.indentLevelOne}>
                {t('legal_remboursement_total_desc_section_b_partie_1')}
                <strong>
                    {t('legal_remboursement_total_desc_section_b_partie_bold')}
                </strong>
                {t('legal_remboursement_total_desc_section_b_partie_2')}
            </Typography>
            <Typography variant={Variant.p} className={classes.indentLevelTwo}>
                {t(
                    'legal_remboursement_total_desc_section_b_partie_2_section_1'
                )}
            </Typography>
            <Typography variant={Variant.p} className={classes.indentLevelTwo}>
                {isQuebec
                    ? t(
                          'legal_remboursement_total_desc_section_b_partie_2_section_2_partie_1'
                      )
                    : t(
                          'legal_ontario_remboursement_total_desc_section_b_partie_2_section_2_partie_1'
                      )}
                <strong>
                    {t(
                        'legal_remboursement_total_desc_section_b_partie_2_section_2_partie_1_bold'
                    )}
                </strong>
                {isQuebec
                    ? t(
                          'legal_remboursement_total_desc_section_b_partie_2_section_2_partie_2'
                      )
                    : t(
                          'legal_ontario_remboursement_total_desc_section_b_partie_2_section_2_partie_2'
                      )}
            </Typography>
            <Typography variant={Variant.h6}>
                {t('legal_remboursement_total_taux_variable_title')}
            </Typography>
            <Typography variant={Variant.p}>
                {t('legal_remboursement_total_taux_variable_desc_partie_1')}
                <Link
                    type={EnumLinkType.EXTERNAL}
                    href={t('legal_lien_gerer_pret')}
                    ariaLabel={t('click2call_aria')}
                >
                    <strong>{t('legal_lien_gerer_pret')}</strong>
                </Link>
                {t('legal_remboursement_total_taux_variable_desc_partie_2')}
            </Typography>
        </>
    );

    const renderLegalRemboursementTotalAlternative = () => (
        <>
            <Spacer></Spacer>
            <Typography variant={Variant.h5}>
                {t('legal_remboursement_total_title')}
            </Typography>
            <Typography variant={Variant.p}>
                {t(
                    'legal_remboursement_total_desc_partie_alternative_paragraphe_1'
                )}
            </Typography>
            <Typography variant={Variant.p}>
                {t(
                    'legal_remboursement_total_desc_partie_alternative_paragraphe_2'
                )}
            </Typography>
        </>
    );

    const renderLoiInteret = () => (
        <>
            <Spacer></Spacer>
            <Typography variant={Variant.h5}>
                {t('legal_loi_interet_title')}
            </Typography>
            <Typography variant={Variant.p}>
                {isQuebec
                    ? t('legal_loi_interet_desc')
                    : t('legal_ontario_loi_interet_desc')}
            </Typography>
        </>
    );

    const renderImputationPaiements = () => (
        <>
            <Spacer></Spacer>
            <Typography variant={Variant.h5}>
                {t('legal_imputation_paiements_title')}
            </Typography>
            <Typography variant={Variant.p}>
                {t('legal_imputation_paiements_desc')}
            </Typography>
        </>
    );

    const renderAvisDivulgation = () => (
        <>
            <Spacer></Spacer>
            <Typography variant={Variant.h5}>
                {t('legal_avis_divulgation_title')}
            </Typography>
            <Typography variant={Variant.p}>
                {t('legal_avis_divulgation_desc')}
            </Typography>
        </>
    );

    const renderConsentement = () => (
        <>
            <Spacer></Spacer>
            <Typography variant={Variant.h5}>
                {t('legal_consentement_title')}
            </Typography>
            <Typography variant={Variant.p} className={classes.indentLevelOne}>
                {isQuebec
                    ? t('legal_consentement_element_1_paragraphe_1')
                    : t('legal_ontario_consentement_element_1_paragraphe_1')}
            </Typography>
            <Typography variant={Variant.p} className={classes.indentLevelOne}>
                {t('legal_consentement_element_1_paragraphe_2')}
            </Typography>
            <Typography variant={Variant.p} className={classes.indentLevelTwo}>
                <ul>
                    <li>{t('legal_consentement_element_1_element_liste_1')}</li>
                    <li>{t('legal_consentement_element_1_element_liste_2')}</li>
                    <li>{t('legal_consentement_element_1_element_liste_3')}</li>
                    <li>{t('legal_consentement_element_1_element_liste_4')}</li>
                </ul>
            </Typography>
            <Typography variant={Variant.p} className={classes.indentLevelOne}>
                {t('legal_consentement_element_1_paragraphe_3')}
            </Typography>
            <Typography variant={Variant.p} className={classes.indentLevelOne}>
                {t('legal_consentement_element_1_paragraphe_4')}
            </Typography>
            <Typography variant={Variant.p} className={classes.indentLevelOne}>
                {t('legal_consentement_element_2_paragraphe_1')}
            </Typography>
            <Typography variant={Variant.h6} className={classes.indentLevelOne}>
                <strong>
                    {t(
                        'legal_consentement_element_2_conditions_autres_financements_title'
                    )}
                </strong>
            </Typography>
            <Typography variant={Variant.p} className={classes.indentLevelOne}>
                {t('legal_consentement_element_2_conditions_autres_prets_desc')}
            </Typography>
            <Typography variant={Variant.h6} className={classes.indentLevelOne}>
                <strong>
                    {t(
                        'legal_consentement_element_2_pret_assure_titrise_title'
                    )}
                </strong>
            </Typography>
            <Typography variant={Variant.p} className={classes.indentLevelOne}>
                {t(
                    'legal_consentement_element_2_pret_assure_titrise_paragraphe_1'
                )}
            </Typography>
            <Typography variant={Variant.p} className={classes.indentLevelOne}>
                {t(
                    'legal_consentement_element_2_pret_assure_titrise_paragraphe_2'
                )}
            </Typography>
            <Typography variant={Variant.p} className={classes.indentLevelOne}>
                {t('legal_consentement_element_3_paragraphe_1')}
            </Typography>
            <Typography variant={Variant.p} className={classes.indentLevelTwo}>
                <ul>
                    <li>
                        {isQuebec
                            ? t('legal_consentement_element_3_element_liste_1')
                            : t(
                                  'legal_ontario_consentement_element_3_element_liste_1'
                              )}
                    </li>
                    <li>
                        {isQuebec
                            ? t('legal_consentement_element_3_element_liste_2')
                            : t(
                                  'legal_ontario_consentement_element_3_element_liste_2'
                              )}
                    </li>
                    <li>{t('legal_consentement_element_3_element_liste_3')}</li>
                    <li>{t('legal_consentement_element_3_element_liste_4')}</li>
                    <li>{t('legal_consentement_element_3_element_liste_5')}</li>
                    <li>{t('legal_consentement_element_3_element_liste_6')}</li>
                    <li>{t('legal_consentement_element_3_element_liste_7')}</li>
                    <li>{t('legal_consentement_element_3_element_liste_8')}</li>
                </ul>
            </Typography>
        </>
    );

    const renderFrais = () => (
        <>
            <Spacer></Spacer>
            <Typography variant={Variant.h5}>
                {t('legal_frais_title')}
            </Typography>
            <Typography variant={Variant.p}>
                {isQuebec
                    ? t('legal_frais_desc')
                    : t('legal_ontario_frais_desc')}
            </Typography>
        </>
    );

    const renderPreseance = () => (
        <>
            <Spacer></Spacer>
            <Typography variant={Variant.h5}>
                {t('legal_preseance_title')}
            </Typography>
            <Typography variant={Variant.p}>
                {isQuebec
                    ? t('legal_preseance_desc')
                    : t('legal_ontario_preseance_desc')}
            </Typography>
        </>
    );

    return (
        <>
            <Grid>
                {/* Header */}
                <GridRow>
                    <GridCol col="12" sm="12" md="12">
                        {renderLegalHeader()}
                    </GridCol>
                </GridRow>
                {/* Legal */}
                <ContainerMui padding="1.5rem">
                    <GridRow>
                        {/* Legal sommaire */}
                        <GridCol col="12" sm="12" md="12">
                            {renderLegalTexteSommaire()}
                        </GridCol>
                        {/* Legal interet */}
                        <GridCol col="12" sm="12" md="12">
                            {renderLegalInteretTitle()}
                            {renderLegalInteretTauxFixe()}
                            {renderLegalInteretTauxVariable()}
                        </GridCol>
                        {/* Legal remboursement */}
                        <GridCol col="12" sm="12" md="12">
                            {renderLegalRemboursement()}
                        </GridCol>
                        {/* Legal pret non premier rang - FR */}
                        <GridCol col="12" sm="12" md="12">
                            {isQuebec &&
                                i18n.language === 'fr' &&
                                renderLegalPretNonPremierRang()}
                        </GridCol>
                        {/* Legal augmentation paiements périodiques */}
                        <GridCol col="12" sm="12" md="12">
                            {renderLegalAugmentationPaiementsPeriodiques()}
                        </GridCol>
                        {/* Legal remboursement par anticipation */}
                        <GridCol col="12" sm="12" md="12">
                            {renderLegalRemboursementParAnticipation()}
                        </GridCol>
                        {/* Legal remboursements partiels */}
                        <GridCol col="12" sm="12" md="12">
                            {renderLegalRemboursementsPartiels(true)}
                        </GridCol>
                        {/* Legal remboursement total */}
                        <GridCol col="12" sm="12" md="12">
                            {renderLegalRemboursementTotal()}
                        </GridCol>
                        {/* Legal remboursements partiels */}
                        <GridCol col="12" sm="12" md="12">
                            {renderLegalRemboursementsPartiels(false)}
                        </GridCol>
                        {/* Legal remboursement total */}
                        <GridCol col="12" sm="12" md="12">
                            {renderLegalRemboursementTotalAlternative()}
                        </GridCol>
                        {/* Legal interet */}
                        <GridCol col="12" sm="12" md="12">
                            {renderLoiInteret()}
                            {renderImputationPaiements()}
                            {(isQuebec || i18n.language === 'en') &&
                                renderAvisDivulgation()}
                            {renderConsentement()}
                        </GridCol>
                        {/* Legal frais */}
                        <GridCol col="12" sm="12" md="12">
                            {renderFrais()}
                        </GridCol>
                        {/* Legal preseance */}
                        <GridCol col="12" sm="12" md="12">
                            {renderPreseance()}
                        </GridCol>
                        {/* Legal pret non premier rang - EN */}
                        <GridCol col="12" sm="12" md="12">
                            {isQuebec &&
                                i18n.language === 'en' &&
                                renderLegalPretNonPremierRang()}
                        </GridCol>
                    </GridRow>
                </ContainerMui>
            </Grid>
        </>
    );
};
