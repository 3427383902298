import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { Navigation } from './ErreurFonctionnelle.navigation';
import { HeadingProvider } from '../../context';
import { Headings } from '../../styles';
import {
    hideLoading,
    sendEvent,
    EVENT_CATEGORIES,
    EVENT_ACTIONS,
    EVENT_LABELS
} from './../../features';
import { ErreurFonctionnelleGenerique } from './ErreurFonctionnelleGenerique/ErreurFonctionnelleGenerique';
import { ErreurFonctionnelleTarif } from './ErreurFonctionnelleTarif/ErreurFonctionnelleTarif';

const headingMapping = {
    h1: Headings.h3
};

export enum CODE_ERREUR_FONCTIONNELLE_RHN {
    ERREUR_CALCUL_OFFRE = 'CALCUL_OFFRE'
}

interface IErreurFonctionnelleConnected {
    status?: number;
    isConseiller?: boolean;
    codeErreurRHN?: string;
    messageErreur?: string;
}

export const ErreurFonctionnelleConnected: React.FC<
    IErreurFonctionnelleConnected
> = ({ status, isConseiller = false, codeErreurRHN, messageErreur }) => {
    const dispatch = useDispatch();
    const [t] = useTranslation(['ErreurFonctionnelle']);

    useEffect(() => {
        dispatch(hideLoading());

        if (
            codeErreurRHN === CODE_ERREUR_FONCTIONNELLE_RHN.ERREUR_CALCUL_OFFRE
        ) {
            dispatch(
                sendEvent({
                    category: EVENT_CATEGORIES.FORMULAIRE,
                    action: EVENT_ACTIONS.ERREUR_TECHNIQUE,
                    label: EVENT_LABELS.FRM,
                    messageErreur: codeErreurRHN + ' | ' + messageErreur
                })
            );
        } else {
            dispatch(
                sendEvent({
                    category: EVENT_CATEGORIES.FORMULAIRE,
                    action: EVENT_ACTIONS.ERREUR_TECHNIQUE,
                    label: EVENT_LABELS.FRM,
                    messageErreur: status
                        ? status
                        : isConseiller
                        ? t('errorFonctionnelleTextConseiller')
                        : t('errorFonctionnelleText')
                })
            );
        }
    }, []);

    return (
        <HeadingProvider value={headingMapping}>
            {codeErreurRHN ===
            CODE_ERREUR_FONCTIONNELLE_RHN.ERREUR_CALCUL_OFFRE ? (
                <ErreurFonctionnelleTarif />
            ) : (
                <ErreurFonctionnelleGenerique isConseiller={isConseiller} />
            )}
            <Navigation />
        </HeadingProvider>
    );
};
