import React from 'react';
import ReactDOM from 'react-dom';
import { Root } from './App';
import './i18n';
import 'moment/locale/fr';
import '@dsd/library/dist/library/library.css';

import { applyPolyfills, defineCustomElements } from '@dsd/library/dist/loader';

// eslint-disable-next-line jest/require-hook
ReactDOM.render(<Root />, document.getElementById('root'));

// eslint-disable-next-line jest/require-hook
applyPolyfills().then(() => {
    defineCustomElements(window);
});
