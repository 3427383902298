import { useMutation } from 'react-query';
import { AxiosResponse } from 'axios';

import {
    axiosInstance,
    axiosInstanceNoError,
    getDynamicHeaders,
    IMutationResult,
    reactQueryOptions
} from '../../../../api';
import { SIGNATURE_STATUT_ENDPOINT } from '../../apiEndPoints';

export enum SignatureStatutEnum {
    NOUVEAU = 'NOUVEAU',
    SIGNEE = 'SIGNEE',
    TERMINEE = 'TERMINEE',
    REFUSEE = 'REFUSEE',
    ABANDONNEE = 'ABANDONNEE',
    SIGNATURE_PARTIELLE = 'SIGNATURE_PARTIELLE',
    SIGNATURE_DETECTEE = 'SIGNATURE_DETECTEE'
}

export type ISignatureStatut = {
    statut: SignatureStatutEnum;
};

export type ISignatureStatutParam = {
    idDemande: number;
    idContrat: string;
    isFetchingAPI: boolean;
};

const getSignatureUrl = (param: ISignatureStatutParam): string => {
    return (
        SIGNATURE_STATUT_ENDPOINT.replace(
            /{idDemande}/g,
            String(param?.idDemande)
        ).replace(/{idContrat}/g, param?.idContrat) +
        '?isFetchingAPI=' +
        param?.isFetchingAPI
    );
};

// Pooling call with no error Handler
export const getPoolingSignatureStatut = async (
    param: ISignatureStatutParam
): Promise<AxiosResponse<any>> => {
    return await axiosInstanceNoError.get(
        getSignatureUrl(param),
        getDynamicHeaders()
    );
};

// Real statut with axios error handler
const getSignatureStatut = async (
    param: ISignatureStatutParam
): Promise<AxiosResponse<any>> => {
    const { data: response } = await axiosInstance.get(
        getSignatureUrl(param),
        getDynamicHeaders()
    );

    return response;
};

// Real statut with axios mutation
export const useSignatureStatutApi = (): IMutationResult => {
    return useMutation(getSignatureStatut, {
        ...reactQueryOptions()
    });
};
