import React from 'react';
import { SaveAndLeaveModal } from './SaveAndLeaveModal';
import { useSessionApi } from '../../hooks';
import { useSelector } from 'react-redux';

export interface ISaveAndLeaveModalConnectedProps {
    isOpen?: boolean;
    signatureVariant?: boolean;
    onNext?: () => void;
    onCancel?: () => void;
}

export const SaveAndLeaveModalConnected: React.FC<
    ISaveAndLeaveModalConnectedProps
> = ({ isOpen, signatureVariant, onNext, onCancel }) => {
    // Utiliser pour ne pas appeler session si l'auth est pas terminer
    // car save and leave est appeler dans le header
    const idDemande = useSelector(
        (state: any) => state.renouvellement.idDemande
    );
    const { data: session } = useSessionApi(!!idDemande);

    return (
        <SaveAndLeaveModal
            isOpen={isOpen}
            signatureVariant={signatureVariant}
            isMartine={session?.isMartine}
            dateLimiteApprobationMartine={
                session?.approbationCoemprunteur?.dateLimite
            }
            onNext={onNext}
            onCancel={onCancel}
        />
    );
};
