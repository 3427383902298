import React from 'react';
import { Box, createStyles, makeStyles } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import {
    Grid,
    GridCol,
    GridRow,
    Police,
    Typography,
    Variant,
    LinkAction,
    LinkActionType,
    LinkActionTheme
} from '../../../../styles';
import { ContexteAppel, getParlerConseillerUrl } from '../../../../utils';

const useStyles = makeStyles(() =>
    createStyles({
        title: {
            borderBottom: 'solid 1px #666666',
            width: '98%'
        }
    })
);

interface IDeeplinkParlerConseillerProps {
    trackAnalytic: () => void;
}

export const DeeplinkParlerConseiller: React.FC<
    IDeeplinkParlerConseillerProps
> = ({ trackAnalytic }) => {
    const [t] = useTranslation(['DeeplinkParlerConseiller']);
    const classes = useStyles();

    return (
        <Box marginLeft="1rem" marginTop="1rem">
            <Grid>
                <GridCol col="12">
                    <GridRow>
                        <Typography
                            className={classes.title}
                            variant={Variant.h5}
                            police={Police.bold}
                            marginBottom={'1rem'}
                        >
                            {t('title')}
                        </Typography>
                    </GridRow>
                    <GridRow>
                        <Typography
                            variant={Variant.p}
                            police={Police.normal}
                            marginBottom={'1rem'}
                        >
                            {t('description')}
                        </Typography>
                    </GridRow>
                    <GridRow>
                        <LinkAction
                            href={getParlerConseillerUrl(
                                ContexteAppel.PAGE_INTERMEDIAIRE_DEEPLINK,
                                null
                            )}
                            type={LinkActionType.external}
                            theme={LinkActionTheme.green}
                            dataTestId="parler-conseiller-deeplink"
                            ariaLabel={t('click2call_aria')}
                            onClick={trackAnalytic}
                        >
                            {t('parlerConseiller')}
                        </LinkAction>
                    </GridRow>
                </GridCol>
            </Grid>
        </Box>
    );
};
