import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import validator from 'validator';

import { REGEX_ALPHANUMERIC } from '../../utils';
import { Button, ButtonVariant, ISelectOptions } from '../../styles';
import { NavigationPlacement, SaveAndLeaveModal } from '../../components';
import {
    useRoutes,
    getPageName,
    goQuitterParcours,
    ModeAuthentification,
    ISession,
    IRenouvellement,
    ParcoursEmprunteur
} from '../../hooks';
import {
    sendEvent,
    EVENT_CATEGORIES,
    EVENT_ACTIONS,
    EVENT_LABELS
} from '../../features';
import { ModalInfoSignature } from './components/modalInfoSignature/ModalInfoSignature';
import { IConventionState } from './InformationsSignature.utils';

export interface IInformationsSignatureNavigationProps {
    session: ISession;
    renouvellement: IRenouvellement;
    questions: ISelectOptions;
    convention: IConventionState;
    isAdmissibleSI: boolean;
    onClickNext: (
        shouldInputErrorDisplay: boolean,
        shouldCellPhoneInputErrorDisplay: boolean,
        shouldQuestion1ErrorDisplay: boolean,
        shouldQuestion2ErrorDisplay: boolean,
        shouldAnswer1ErrorDisplay: boolean,
        shouldAnswer2ErrorDisplay: boolean
    ) => boolean;
    onClickNextModal: () => void;
    onClickPrevMartineWithAssurance: () => void;
}

export const Navigation: React.FC<IInformationsSignatureNavigationProps> = ({
    session,
    renouvellement,
    questions,
    convention,
    isAdmissibleSI,
    onClickNext,
    onClickNextModal,
    onClickPrevMartineWithAssurance
}) => {
    const dispatch = useDispatch();
    const { getRoute, goToRoute } = useRoutes();
    const [t] = useTranslation('InformationsSignature');

    const isMartine: boolean =
        session?.typeParcoursEmprunteur ===
        ParcoursEmprunteur.COEMPRUNTEUR_MARTINE;

    // State
    const [isModalSaveAndLeaveOpen, setIsModalSaveAndLeaveOpen] =
        useState(false);
    const [isModalNextOpen, setIsModalNextOpen] = useState(false);

    // Send cyber & leave
    const leaveApp = () => {
        const pageCourante = getPageName();

        if (pageCourante) {
            dispatch(
                sendEvent({
                    category: EVENT_CATEGORIES.RHN,
                    action: EVENT_ACTIONS.CLIC,
                    label: EVENT_LABELS.ENREGISTRER_ET_QUITTER + pageCourante
                })
            );
        }

        goQuitterParcours();
    };

    const handleCancel = () => {
        // Open confirmation modal
        setIsModalSaveAndLeaveOpen(true);
    };

    const handleClicModalClose = (shouldStayHere: boolean) => {
        // Close confirmation modal
        setIsModalSaveAndLeaveOpen(false);

        if (!shouldStayHere) {
            // Send cyber & leave
            leaveApp();
        }
    };

    const handleBack = () => {
        dispatch(
            sendEvent({
                category: EVENT_CATEGORIES.RHN,
                action: EVENT_ACTIONS.CLIC,
                label: EVENT_LABELS.PRECEDENT + getPageName()
            })
        );

        if (isMartine && renouvellement?.emprunteurConnecte?.isAjoutAssurance) {
            // Martine avec ajout d'assurance et pas un conseiller
            onClickPrevMartineWithAssurance();
        } else {
            goToRoute(getRoute().prevRoute);
        }
    };

    // Validation courriel
    const isEmailValid = (): boolean => {
        return validator.isEmail(convention?.courriel) && rhnEmailValidation();
    };

    const rhnEmailValidation = (): boolean => {
        return isEmailLengthValid();
    };

    const isEmailLengthValid = (): boolean => {
        const emailBeforeArobase: string = convention?.courriel.substr(
            0,
            convention?.courriel.indexOf('@')
        );
        const emailAfterArobase: string = convention?.courriel.substr(
            emailBeforeArobase.length,
            convention?.courriel.length
        );

        return (
            convention?.courriel.length >= 12 &&
            convention?.courriel.length <= 300 &&
            emailBeforeArobase.length <= 64 &&
            emailAfterArobase.length <= 255
        );
    };

    // Validation telephone
    const validateCellphone = (): boolean => {
        return (
            convention?.cellulaire?.length === 10 && isCellPhoneOnlyNumbers()
        );
    };

    const isCellPhoneOnlyNumbers = (): boolean => {
        return /^\d+$/.test(convention?.cellulaire);
    };

    // Validation questions

    const isQuestionAnswerValid = (answer: string): boolean => {
        return (
            answer &&
            answer.length >= 4 &&
            answer.length <= 100 &&
            answer.match(REGEX_ALPHANUMERIC) !== null
        );
    };

    const isQuestionUnique = (): boolean => {
        return (
            Number(convention?.question1Id) !== Number(convention?.question2Id)
        );
    };

    const handleNext = () => {
        let isCellPhoneValid = true;
        let isAnswer1Valid = true;
        let isAnswer2Valid = true;
        let isQuestion1Valid = true;
        let isQuestion2Valid = true;
        // check les erreurs avec l'authentification base sur le mode authentification sélectionné
        if (
            convention?.modeAuthentification === ModeAuthentification.QUESTIONS
        ) {
            isAnswer1Valid = isQuestionAnswerValid(convention?.reponse1);
            isAnswer2Valid = isQuestionAnswerValid(convention?.reponse2);
            isQuestion1Valid = convention?.question1Id && isQuestionUnique();
            isQuestion2Valid = convention?.question2Id && isQuestionUnique();
        } else {
            isCellPhoneValid = validateCellphone();
        }

        if (
            onClickNext(
                !isEmailValid(),
                !isCellPhoneValid,
                !isQuestion1Valid,
                !isQuestion2Valid,
                !isAnswer1Valid,
                !isAnswer2Valid
            )
        ) {
            setIsModalNextOpen(true);
        }
    };

    return (
        <>
            <SaveAndLeaveModal
                isOpen={isModalSaveAndLeaveOpen}
                onNext={() => handleClicModalClose(false)}
                onCancel={() => handleClicModalClose(true)}
            />
            <ModalInfoSignature
                isOpen={isModalNextOpen}
                isAdmissibleSI={isAdmissibleSI}
                convention={convention}
                questions={questions}
                onClose={() => {
                    setIsModalNextOpen(false);
                }}
                handleNext={onClickNextModal}
            />
            <NavigationPlacement
                renderBackButton={
                    <Button
                        id={'QA_test_navigation_back_button'}
                        variant={ButtonVariant.secondary}
                        dataCy="qa-navigation-button-back"
                        dataTestId={'navigation-back-button'}
                        onClick={handleBack}
                        fullWidth="xs"
                    >
                        {t('back')}
                    </Button>
                }
                renderSaveButton={
                    <Button
                        id={'QA_test_navigation_quit_button'}
                        variant={ButtonVariant.secondary}
                        dataCy="qa-navigation-button-quit"
                        dataTestId={'navigation-quit-button'}
                        onClick={handleCancel}
                        fullWidth="xs"
                    >
                        {t('cancel')}
                    </Button>
                }
                renderNextButton={
                    <Button
                        id={'QA_test_navigation_next_button'}
                        variant={ButtonVariant.primary}
                        dataCy="qa-navigation-button-next"
                        dataTestId={'navigation-next-button'}
                        onClick={handleNext}
                        fullWidth="xs"
                    >
                        {t('next')}
                    </Button>
                }
            />
        </>
    );
};
