import { Box, useMediaQuery } from '@material-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';

import {
    ColorDSD,
    ContainerMui,
    ColorHex,
    Hr,
    Typography,
    Variant,
    Police,
    IconTooltip,
    Article,
    BorderRadiusMui
} from '../../../../styles';

interface IRepartitionDonutProps {
    amortissementRestant: { annee: string; mois: string };
    montantCapital: number;
    montantInteret: number;
    montantAssurance: number;
    onTooltipActive: (label: string) => void;
}

const TOTAL_HEIGHT = 416;

export const RepartitionDonut: React.FC<IRepartitionDonutProps> = ({
    amortissementRestant,
    montantCapital,
    montantInteret,
    montantAssurance,
    onTooltipActive
}) => {
    const [t] = useTranslation('RepartitionDonut');
    const isMobile = useMediaQuery('(max-width:480px)');
    const isEcranMedium = useMediaQuery('(max-width:1440px)');

    const RenderAmortissementRestant = () => {
        const formattedAnnee = t('Date:years', {
            value: amortissementRestant?.annee
        });
        const formattedMois = t('Date:months', {
            value: amortissementRestant?.mois
        });

        return (
            <>
                <Hr color={ColorDSD.hr1}></Hr>
                <Box
                    padding={isEcranMedium ? '1rem' : '0'}
                    marginTop={isEcranMedium ? '0' : '0.8rem'}
                    minHeight={isEcranMedium ? '4rem' : 'auto'}
                    height={isMobile ? 'auto' : isEcranMedium ? '4rem' : '2rem'}
                    display={isMobile ? 'grid' : 'flex'}
                    flexDirection="row"
                    flexWrap="nowrap"
                    justifyContent={isMobile ? 'flex-start' : 'center'}
                    alignItems="center"
                    gridTemplateColumns="auto auto auto"
                >
                    <Box height="1rem">
                        <Typography
                            variant={Variant.p}
                            article={isEcranMedium && Article.a5}
                            police={Police.bold}
                        >
                            {formattedAnnee}{' '}
                            {Number(amortissementRestant?.mois) > 0 &&
                                t('and') + ' ' + formattedMois}
                        </Typography>
                    </Box>
                    <Box height="1rem" margin="0 0.5rem 0 0.3rem">
                        <Typography
                            variant={Variant.p}
                            article={isEcranMedium && Article.a5}
                        >
                            {t('amortissementRestant')}
                        </Typography>
                    </Box>
                    <Box
                        height="1rem"
                        data-testid={'info_bulle_amortissement_restant'}
                    >
                        <IconTooltip
                            iconName="alertes_contour_information"
                            label={t('tooltipAmortissementRestant')}
                            onActive={() =>
                                onTooltipActive('Amortissement restant')
                            }
                            placement={isMobile ? 'left' : 'bottom'}
                            isSmallerTooltip={isMobile}
                        ></IconTooltip>
                    </Box>
                </Box>
            </>
        );
    };

    return (
        <ContainerMui
            dataTestId={'bloc_repartition_paiements'}
            borderRadius={BorderRadiusMui.sm}
            border={2}
            borderColor={ColorHex.lightSystem1}
            hidePadding
            minHeight={TOTAL_HEIGHT + 'px'}
        >
            <Box display="block" position="relative">
                <Box
                    display="flex"
                    flexDirection="row"
                    flexWrap="nowrap"
                    alignItems="center"
                    position="relative"
                    marginLeft="20px"
                    marginTop="15px"
                >
                    <Typography
                        variant={isMobile ? Variant.p : Variant.h5}
                        article={isEcranMedium ? Article.a3 : Article.a1}
                        hideMarginBottom
                    >
                        {t('chartTitle')}
                    </Typography>
                    <Box
                        display="flex"
                        paddingLeft="0.5rem"
                        data-testid={'info_bulle_repartition_paiements'}
                    >
                        <IconTooltip
                            iconName="alertes_contour_information"
                            label={t('tooltipTitle')}
                            onActive={() =>
                                onTooltipActive('Répartition des paiements')
                            }
                            isSmallerTooltip={isMobile}
                            placement={'left'}
                        ></IconTooltip>
                    </Box>
                </Box>
                <Box display="flex" justifyContent="center" alignItems="center">
                    <HighchartsReact
                        highcharts={Highcharts}
                        containerProps={{
                            style: {
                                height: '300px',
                                width: isMobile ? '252px' : '344px'
                            }
                        }}
                        options={{
                            chart: {
                                plotBackgroundColor: null,
                                plotBorderWidth: null,
                                plotShadow: false,
                                type: 'pie',
                                width: isMobile ? 252 : null
                            },
                            title: {
                                text: undefined
                            },
                            tooltip: {
                                formatter: function () {
                                    return (
                                        //@ts-ignore
                                        this.key +
                                        ': ' +
                                        '<b>' +
                                        t('Number:currency', {
                                            //@ts-ignore
                                            value: this.y
                                        }) +
                                        '</b>'
                                    );
                                }
                            },
                            credits: {
                                enabled: false
                            },
                            accessibility: {
                                point: {
                                    valueSuffix: '$'
                                }
                            },
                            legend: {
                                layout: 'horizontal',
                                align: 'left',
                                verticalAlign: 'top',
                                floating: true,
                                backgroundColor: '#FFFFFF',
                                navigation: {
                                    enabled: false
                                },
                                itemStyle: {
                                    fontSize: '14px',
                                    fontFamily: 'Averta',
                                    fontWeight: 500
                                },
                                y: 0,
                                x: 4
                            },
                            plotOptions: {
                                pie: {
                                    cursor: 'pointer',
                                    size: isMobile ? 180 : 200,
                                    dataLabels: {
                                        enabled: false
                                    },
                                    center: isEcranMedium
                                        ? ['50%', '55%']
                                        : ['50%', '60%'],
                                    showInLegend: true,
                                    point: {
                                        events: {
                                            legendItemClick: function () {
                                                return false;
                                            }
                                        }
                                    }
                                }
                            },
                            series: [
                                {
                                    name: t('seriesTitle'),
                                    data: [
                                        {
                                            name: t('seriesCapital'),
                                            y: montantCapital,
                                            color: '#008c53'
                                        },
                                        {
                                            name: t('seriesInteret'),
                                            y: montantInteret,
                                            color: '#8ec9a9'
                                        },
                                        montantAssurance !== 0
                                            ? {
                                                  name: t('seriesAssurance'),
                                                  y: montantAssurance,
                                                  color: '#cce7dc'
                                              }
                                            : undefined
                                    ]
                                }
                            ]
                        }}
                    />
                </Box>
                <RenderAmortissementRestant />
            </Box>
        </ContainerMui>
    );
};
