import React from 'react';
import { useTranslation } from 'react-i18next';

import {
    GridCol,
    Variant,
    Article,
    Typography,
    Police,
    RadioGroupMui,
    RadioMui,
    AlertType,
    Alert,
    ContainerMui
} from '../../../../styles';

import { Langues } from '../../../../utils';
import { ParcoursEmprunteur } from '../../../../hooks';
import { Box, useMediaQuery } from '@material-ui/core';
import { IConventionState } from '../../InformationsSignature.utils';

interface ILangueInfoSignature {
    convention: IConventionState;
    parcoursEmprunteur: string;
    loi14Actif: boolean;
    onLangueChange: (lang: Langues) => void;
}

export const LangueInfoSignature: React.FC<ILangueInfoSignature> = ({
    convention,
    parcoursEmprunteur,
    loi14Actif,
    onLangueChange
}) => {
    const [t] = useTranslation('InformationsSignature');
    const isMobile = useMediaQuery('(max-width:640px)');
    const isENAndLoi14 =
        convention?.langue === Langues.EN.toString() && loi14Actif === true;

    // Langue
    const renderLangOption = (labelKey: string, lang: Langues) => {
        return (
            <RadioMui
                dataTestId={
                    'radio_button_' +
                    (lang === Langues.FR ? 'francais' : 'anglais')
                }
                label={t(labelKey)}
                value={lang}
                isChecked={convention?.langue === lang}
                onChange={() => {
                    onLangueChange(lang);
                }}
            />
        );
    };

    // Langue
    const renderLangAlert = () => {
        if (isENAndLoi14) {
            return (
                // TODO remove box -1rem quand on update dsd
                <Box mb="-1rem">
                    <Alert
                        isFullWidth
                        type={AlertType.information}
                        dataTestId="bloc_texte_loi14"
                        className="dsd-mb-0"
                        titleLevel={4}
                        title={t('loi14TitreHorsEcran')}
                        dynamic
                    >
                        <Typography variant={Variant.p} article={Article.a3}>
                            {t('loi14AvisPart1')}
                        </Typography>
                        <Typography variant={Variant.p} article={Article.a3}>
                            {t('loi14AvisPart2')}
                        </Typography>
                    </Alert>
                </Box>
            );
        }
    };

    return (
        <ContainerMui
            padding={
                isMobile
                    ? '0.33rem 1rem 0.33rem 1rem'
                    : '0.5rem 1.5rem 0rem 1.5rem'
            }
            spacerDistance={6}
            minWidth="100%"
            minHeight="fit-content"
        >
            <GridCol col="12">
                <Typography variant={Variant.h3} police={Police.bold}>
                    {t('titreLangue')}
                </Typography>
                {parcoursEmprunteur === ParcoursEmprunteur.COEMPRUNTEUR_GUY && (
                    <Typography variant={Variant.p} article={Article.a3}>
                        {t('descriptionLangueGuy')}
                    </Typography>
                )}
                <Typography variant={Variant.p} article={Article.a3}>
                    {t(
                        parcoursEmprunteur ===
                            ParcoursEmprunteur.COEMPRUNTEUR_MARTINE
                            ? 'descriptionLangueMartine'
                            : 'descriptionLangue'
                    )}
                </Typography>
                {parcoursEmprunteur ===
                ParcoursEmprunteur.COEMPRUNTEUR_MARTINE ? (
                    <Typography
                        variant={Variant.p}
                        article={Article.a3}
                        police={Police.bold}
                        marginBottom="0.5rem"
                        hideMarginBottom={isENAndLoi14}
                    >
                        {t(convention?.langue)}
                    </Typography>
                ) : (
                    <RadioGroupMui
                        id={'infos_signature_radiogroup_langue'}
                        value="langue"
                    >
                        {renderLangOption('optionLangueFrancais', Langues.FR)}
                        {renderLangOption('optionLangueAnglais', Langues.EN)}
                    </RadioGroupMui>
                )}
                {renderLangAlert()}
            </GridCol>
        </ContainerMui>
    );
};
