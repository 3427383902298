import React, { useEffect, useRef } from 'react';

export enum InputType {
    DATE = 'date',
    EMAIL = 'email',
    NUMBER = 'number',
    PASSWORD = 'password',
    SEARCH = 'search',
    TEL = 'tel',
    TEXT = 'text'
}

export enum InputCustomType {
    CELLULAR = 'cellular',
    DAYDATE = 'daydate',
    LONGDATE = 'longdate',
    MONEY = 'money',
    PC = 'pc',
    PERCENTAGE = 'percentage',
    SEARCH = 'search',
    SHORTDATE = 'shortdate',
    SIN = 'sin',
    TAN = 'tan',
    TEL = 'tel'
}

export enum InputMode {
    DECIMAL = 'decimal',
    EMAIL = 'email',
    NUMERIC = 'numeric',
    SEARCH = 'search',
    TEL = 'tel',
    TEXT = 'text',
    URL = 'url',
    NONE = 'none'
}

export enum InputPrefix {
    CELLULAR = 'cellular',
    DATE = 'date',
    DOLLAR = '$',
    SEARCH = 'search',
    TEL = 'tel'
}

export enum InputSuffix {
    DOLLAR = '$',
    PERCENT = '%'
}

export enum AutoCompleteType {
    ON = 'on',
    OFF = 'off',
    NAME = 'name'
    // La liste continue mais elle est trop grosse pour tous les ajouter ici
    // donc mieux d'ajouter au fur et à mesure au besoin
}

type Decimal = 0 | 2 | 3;

type InputWidth = 1 | 2 | 3 | 4 | 5; // en rem

type MaxDecimalLength = 0 | 1 | 2 | 3;

interface IInputProps {
    // properties
    shouldAutoComplete?: AutoCompleteType;
    isDark?: boolean;
    decimals?: Decimal;
    isDisabled?: boolean;
    identifier?: string;
    inputMode?: InputMode;
    max?: number; // utilise pour type="number" ou custom-type="money"
    maxLength?: number;
    min?: number; // utilise pour type="number" ou custom-type="money"
    minLength?: number;
    name?: string;
    pattern?: string;
    isReadOnly?: boolean;
    isRequired?: boolean;
    shouldSpellCheck?: boolean;
    type?: InputType;
    value?: string;
    customType?: InputCustomType;
    describedBy?: string;
    hideRequiredLabelling?: boolean;
    inputPrefix?: InputPrefix;
    inputSuffix?: InputSuffix;
    inputWidth?: InputWidth;
    maxDecimalLength?: MaxDecimalLength;
    quietValidate?: boolean;
    isError?: boolean;
    // slots
    errorLabel?: string;
    hint?: string;
    label?: string;
    labelToolTip?: string;
    // events
    onChange?: (value: string) => void;
    onFocusOut?: (value: string) => void;
    onInputMaskChange?: (e: Event) => void;
    onInputMaskError?: (e: Event) => void;
    validate?: () => void;
    // extra de nous
    inputRef?: any;
    dataTestId?: string;
}

export const Input: React.FC<IInputProps> = ({
    // properties
    isDark,
    shouldAutoComplete,
    decimals,
    isDisabled = false,
    identifier,
    inputMode,
    max,
    maxLength,
    min,
    minLength,
    name = 'input-01',
    pattern,
    isReadOnly = false,
    isRequired = true,
    shouldSpellCheck = true,
    type,
    value,
    customType,
    describedBy,
    hideRequiredLabelling = false,
    inputPrefix,
    inputSuffix,
    inputWidth,
    maxDecimalLength = 2,
    quietValidate = false,
    isError,
    // slots
    errorLabel,
    hint,
    label,
    labelToolTip,
    // events
    onChange,
    onFocusOut,
    onInputMaskChange,
    onInputMaskError,
    validate,
    // extra de nous
    inputRef,
    dataTestId
}) => {
    const ref = useRef(null);

    const handleChange = (e: any) => {
        onChange(e.target.value);
    };

    const handleFocusOut = (e: any) => {
        onFocusOut(e.target.value);
    };

    useEffect(() => {
        ref.current.addEventListener('dsdInputChange', (e: any) => {
            if (onChange) handleChange(e);
        });

        ref.current.addEventListener('dsdInputFocusout', (e: any) => {
            if (onFocusOut) handleFocusOut(e);
        });

        ref.current.addEventListener('dsdInputMaskChange', (e: any) => {
            if (onInputMaskChange) onInputMaskChange(e);
        });

        ref.current.addEventListener('dsdInputMaskError', (e: any) => {
            if (onInputMaskError) onInputMaskError(e);
        });

        ref.current.addEventListener('validate', () => {
            if (validate) validate();
        });
    }, []);

    return (
        <div ref={inputRef} data-testid={dataTestId}>
            {/* @ts-ignore */}
            <dsd-input
                ref={ref}
                autocomplete={shouldAutoComplete}
                dark={isDark}
                decimals={decimals}
                disabled={isDisabled}
                identifier={identifier}
                input-mode={inputMode}
                max={max}
                maxlength={maxLength}
                min={min}
                minlength={minLength}
                name={name}
                pattern={pattern}
                readonly={isReadOnly}
                required={isRequired}
                spellcheck={shouldSpellCheck}
                type={type}
                value={value}
                custom-type={customType}
                described-by={describedBy}
                hide-required-labelling={hideRequiredLabelling}
                input-prefix={inputPrefix}
                input-suffix={inputSuffix}
                input-width={inputWidth}
                max-decimal-length={maxDecimalLength}
                quiet-validate={quietValidate}
                error={isError}
            >
                <span slot="label">{label}</span>
                <span slot="label-tooltip">{labelToolTip}</span>
                <span slot="hint">{hint}</span>
                <span slot="error">{errorLabel}</span>
                {/* @ts-ignore */}
            </dsd-input>
        </div>
    );
};
