import React, { useRef, useEffect } from 'react';

interface ICheckboxProps {
    label: string;
    identifier?: string;
    analytics?: string;
    value: string;
    name?: string;
    isDark?: boolean;
    isChecked?: boolean;
    isDisabled?: boolean;
    isRequired?: boolean;
    hasError?: boolean;
    dataCy?: string;
    dataTestId?: string;
    shouldHideRequiredLabelling?: boolean;
    onChange: (value: string) => void;
}

export const Radio: React.FC<ICheckboxProps> = ({
    label,
    identifier,
    analytics,
    value,
    name,
    isDark,
    isChecked,
    isDisabled,
    isRequired,
    shouldHideRequiredLabelling = true,
    dataTestId,
    dataCy,
    hasError,
    onChange
}) => {
    const elementRef = useRef(null);

    // evenement seulement triggered quand le radio est checked
    const handleRadioChange = () => {
        onChange(value);
    };

    useEffect(() => {
        elementRef.current.addEventListener(
            'dsdRadioChange',
            handleRadioChange
        );
    }, []);

    return (
        // @ts-ignore
        <dsd-radio
            ref={elementRef}
            analytics={analytics}
            dark={isDark}
            name={name}
            value={value}
            required={isRequired}
            identifier={identifier}
            disabled={isDisabled}
            checked={isChecked}
            error={hasError}
            data-cy={dataCy}
            hideRequiredLabelling={shouldHideRequiredLabelling}
            data-testid={dataTestId}
            hide-required-labelling
        >
            {label}
            {/* @ts-ignore */}
        </dsd-radio>
    );
};
