import React, { useEffect, useState } from 'react';
import { Box } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

import {
    InputMui,
    InputType,
    Variant,
    Article,
    Typography,
    ColorDSD
} from '../../../../../styles';

interface IPaiementSelectProps {
    paiement: { value: number; min: number; max: number };
    isDisabled: boolean;
    onChange: (value: string, shouldCalculAmortissement: boolean) => void;
}

export const PaiementInput: React.FC<IPaiementSelectProps> = ({
    paiement,
    isDisabled,
    onChange
}) => {
    const [t] = useTranslation('PaiementSelect');
    const [isInRange, setIsInRange] = useState(true);
    const [value, setValue] = useState(String(paiement?.value));

    const RenderBornes = () => (
        <Typography
            variant={Variant.p}
            article={Article.a5}
            color={ColorDSD.lightSystem3}
        >
            {t('minimum')}{' '}
            {t('Number:currency', {
                value: paiement.min
            })}
            {' - '}
            {t('maximum')}{' '}
            {t('Number:currency', {
                value: paiement.max
            })}
        </Typography>
    );

    const getIsInRange = (value) => {
        setIsInRange(true);

        if (isDisabled) {
            return true;
        }
        return Number(value) >= paiement.min && Number(value) <= paiement.max;
    };

    const handleChange = (e) => {
        // Save value in real time in state
        setValue(String(e.target.value));
    };

    const handleFocusOut = (e) => {
        const value = e.target.value;
        // Calcul if error must be displayed
        setIsInRange(getIsInRange(value));
        // Save value in store and state
        const fixed = Number(value).toFixed(2);
        setValue(String(fixed));
        onChange(String(fixed), false);
    };

    useEffect(() => {
        if (paiement?.value) {
            // If paiement changed -> reset error and value
            setValue(String(Number(paiement?.value).toFixed(2)));
        }
    }, [paiement]);

    return (
        <>
            <InputMui
                dataTestId={'input_paiement'}
                isError={!isInRange}
                helperText={!isInRange && t('erreurPaiementInput')}
                isDisable={isDisabled}
                adornment={'$'}
                key="InputMui_01"
                isFullWidth
                value={value}
                type={InputType.NUMBER}
                onBlur={handleFocusOut}
                onFocus={() => setIsInRange(true)}
                onChange={handleChange}
            />

            <Box>{!isDisabled && <RenderBornes />}</Box>
        </>
    );
};
