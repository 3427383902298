import React, { ReactNode } from 'react';

export enum TuileNavigationVariantEnum {
    STANDARD = 'standard',
    DESCRIPTION = 'standard-description',
    ICON = 'icon',
    ICONCENTERED = 'icon-centered',
    COMPLEX = 'complex',
    COMPLEXICON = 'complex-icon',
    IMAGE = 'image'
}

export enum TuileNavigationImagePositionEnum {
    RIGHT = 'right',
    LEFT = 'left',
    TOP = 'top'
}

export enum TuileNavigationTagThemeEnum {
    INFORMATION = 'information',
    CONFIRMATION = 'confirmation',
    WARNING = 'warning',
    ERROR = 'error',
    PROMO = 'promo',
    PRIMARY = 'primary'
}

interface ITuileNavigationProps {
    children: ReactNode;
    dark?: boolean;
    variant?: TuileNavigationVariantEnum;
    imagePosition?: TuileNavigationImagePositionEnum;
    fullWidth?: boolean;
    imageSrc?: string;
    imageAlt?: string;
    dataTestId?: string;
    srOnlySuffix?: string;
    onClick?: () => void;
}

export const TuileNavigation: React.FC<ITuileNavigationProps> = ({
    children,
    dark,
    variant,
    imagePosition,
    fullWidth,
    imageSrc,
    imageAlt,
    dataTestId,
    srOnlySuffix,
    onClick
}) => {
    return (
        // @ts-ignore
        <dsd-tile-navigation
            dark={dark}
            variant={variant}
            image-position={imagePosition}
            full-width={fullWidth}
            image-src={imageSrc}
            image-alt={imageAlt}
            onClick={onClick}
            data-testid={dataTestId}
            sr-only-suffix={srOnlySuffix}
        >
            {children}
            {/* @ts-ignore */}
        </dsd-tile-navigation>
    );
};
