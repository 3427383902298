export default {
    en: {
        moreoptions_title: 'More options for you',
        moreoptions_text_part1:
            'If you’re thinking of selling or renovating your home, you can ',
        moreoptions_text_link: 'talk to our specialists',
        moreoptions_text_part2: '.',
        click2call_aria:
            'This link redirects to another Desjardins site. Opens in a new tab.'
    },
    fr: {
        moreoptions_title: 'Encore plus d’options pour vous',
        moreoptions_text_part1:
            'Si vous songez à vendre ou à rénover, vous pouvez ',
        moreoptions_text_link: 'parler à nos spécialistes',
        moreoptions_text_part2: '.',
        click2call_aria:
            'Lien vers un autre site de Desjardins. S’ouvre dans un nouvel onglet.'
    }
};
