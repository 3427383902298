// Couleur pour components qui consomme les classes DSD
export enum ColorDSD {
    // DSD Colors
    lightWhite = 'dsd-c-light-white',
    primary = 'dsd-c-primary',
    lightWarning = 'dsd-c-light-warning-t',
    lightSystem1 = 'light-system-1', // rgb(230, 231, 232) - #e6e7e8
    lightSystem2 = 'light-system-2', // rgb(186, 187, 189) - #babbbd
    lightSystem3 = 'light-system-3', // rgb(118, 118, 118) - #767676
    lightConfirmation1 = 'dsd-c-light-confirmation-1', // rgb(217, 240, 227)
    lightConfirmation3 = 'dsd-c-light-confirmation-3', // #00874e
    lightGraphite1 = 'light-graphite-1', // rgb(240, 240, 240)
    lightGraphite2 = 'light-graphite-2', // #e6e6e6
    lightGraphite4 = 'light-graphite-4', // rgb(102, 102, 102)
    lightError4 = 'dsd-c-light-error-4',

    // hr
    hr1 = 'dsd-hr-1', // #e6e7e8 - light-system-1
    hr2 = 'dsd-hr-2', // #babbbd - light-system-2
    hr3 = 'dsd-hr-3' // #ffffff - light-white
}

// Couleur pour components qui consomme des HEX
export enum ColorHex {
    // HEX TODO:: Demander a DSD d'ajouter les classes de DSD dans Icons etc
    primary = '#00874e',
    darkPrimary = '#053E26',
    lightWhite = '#ffffff',
    lightConfirmation1 = '#d9f0e3',
    lightConfirmation2 = '#D0E6DF',
    lightSystem1 = '#e6e7e8',
    lightSystem2 = '#babbbd',
    lightSystem3 = '#767676',
    lightGraphite1 = '#f4f4f4',
    lightGraphite2 = '#e6e6e6',
    lightGraphite3 = '#aaaaaa',
    lightGraphite4 = '#666666',
    lightGraphite5 = '#6c6c6c',
    lightError4 = '#960e02',
    lightGrey1 = '#f0f0f0',
    lightGrey2 = '#f3f2f1',
    lightGrey3 = '#E9E8E7',
    yellow1 = '#F8EBC1',
    darkYellow1 = '#443507',
    lightGreen = '#D8E1E1'
}
