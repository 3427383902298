import React, { ReactNode, useEffect, useRef } from 'react';

export enum LightBoxType {
    STANDARD = 'STANDARD',
    NEW = 'NEW'
}

interface ILightboxProps {
    title: string;
    children: ReactNode;
    buttonPrimaryLabel: string;

    // optional
    dark?: boolean;
    show?: boolean;
    type?: LightBoxType;
    wide?: boolean;
    buttonSecondaryLabel?: string;
    fixedHeight?: boolean;
    iconAlt?: string;
    iconName?: string;
    imageAlt?: string;
    imageLg?: string;
    imageMd?: string;
    imageSm?: string;
    imageXs?: string;
    subtitle?: string;
    returnFocus?: string;
    stepDenominator?: number;
    stepNumerator?: number;
    dataTestId?: string;
    onClickPrimaryButton?: () => void;
    onClickSecondaryButton?: () => void;
    onOpen?: () => void;
    onClose?: () => void;
}

// -------------------------------------------------------------------------
//  NOTE IMPORTANTE
//  #1  Si plusieurs Modal/Lightbox sont rendered dans la même page ça peut causer
//      un crash de l'application. Voir pop-up aiguilleur et InfoTaux pour exemple
//      et fix.
//  #2  Pour une raison qui échappe même dsd les fonctions appelé ne fonctionne pas
//      avec le useState hook de react. Voir pup-up aiguilleur pour exemple et fix
// -------------------------------------------------------------------------
export const Lightbox: React.FC<ILightboxProps> = ({
    title,
    children,
    buttonPrimaryLabel,

    // optional
    dark,
    show,
    type,
    wide = true, // valeur par défaut dans dsd est true
    buttonSecondaryLabel,
    fixedHeight,
    iconAlt,
    iconName,
    imageAlt,
    imageLg,
    imageMd,
    imageSm,
    imageXs,
    subtitle,
    returnFocus,
    stepDenominator,
    stepNumerator,
    dataTestId,
    onClickSecondaryButton,
    onClickPrimaryButton,
    onOpen,
    onClose
}) => {
    const ref = useRef(null);

    useEffect(() => {
        ref.current.addEventListener('dsdLightboxHide', () => {
            if (onClose) onClose();
        });
        ref.current.addEventListener('dsdLightboxShow', () => {
            if (onOpen) onOpen();
        });
        ref.current.addEventListener('dsdLightboxPrimaryClick', () => {
            if (onClickPrimaryButton) onClickPrimaryButton();
        });
        ref.current.addEventListener('dsdLightboxSecondaryClick', () => {
            if (onClickSecondaryButton) onClickSecondaryButton();
        });
    }, []);

    return (
        // @ts-ignore
        <dsd-lightbox
            ref={ref}
            button-primary-label={buttonPrimaryLabel}
            button-secondary-label={buttonSecondaryLabel}
            dark={dark}
            show={show}
            type={type}
            wide={wide}
            fixed-height={fixedHeight}
            icon-alt={iconAlt}
            icon-name={iconName}
            image-alt={imageAlt}
            image-lg={imageLg}
            image-md={imageMd}
            image-sm={imageSm}
            image-xs={imageXs}
            return-focus={returnFocus}
            step-denominator={stepDenominator}
            step-numerator={stepNumerator}
            data-testId={dataTestId}
        >
            <span slot="title">{title}</span>
            <span slot="subtitle">{subtitle}</span>
            {children}
            {/* @ts-ignore */}
        </dsd-lightbox>
    );
};
