import Cookies from 'js-cookie';
import { ACCESWEB_NONPROD, ACCESWEB_PROD, isEnvNonProd } from '../config';

export const TNR_COOKIE = 'momentVerite';
export const DOMAIN_DESJARDINS_COOKIE = '.desjardins.com';
export const JWT_COOKIE_CONFIG_NAME = 'config';

const JWT_COOKIE_DESJ_NAME = 'DESJ_PREF';
const JWT_COOKIE_JWT_NAME = 'com.desjardins.parcours-habitation.jwt';
const DOMAIN_PROD = '.mouv.desjardins.com';
const DOMAIN_NONPROD_MOUV = '.mouvdev.desjardins.com';
const DOMAIN_NONPROD_PCF = '.apps.cfzcea.dev.desjardins.com';

export enum TNR_MOMENTVERITE_VALUES {
    NON_COMPLET = 'renouvellement_non_complete',
    COMPLET = 'renouvellement_complete'
}

export enum Langues {
    FR = 'fr',
    EN = 'en'
}

export const getCookieConfigAttributes = (): any => {
    const cookieConfig = Cookies.get(JWT_COOKIE_CONFIG_NAME);
    if (cookieConfig) {
        const decodedCookieConfig = decodeURI(cookieConfig);
        return JSON.parse(decodedCookieConfig);
    }
    return null;
};

export const setCookieConfig = (
    idDemande: string,
    langue: string,
    isApp: boolean,
    isNonProdEnv: boolean
): void => {
    const baseUrlRetour = isNonProdEnv ? ACCESWEB_NONPROD : ACCESWEB_PROD;
    const infoPret = '{"idDemande":' + idDemande + '}';

    const config = {
        infoPret: infoPret,
        langue: langue,
        urlRetour: baseUrlRetour + '/identifiantunique/sso/redirect',
        isApp: isApp
    };

    Cookies.withConverter({
        write: function (value) {
            return value;
        }
    }).set(JWT_COOKIE_CONFIG_NAME, encodeURIComponent(JSON.stringify(config)), {
        domain: DOMAIN_DESJARDINS_COOKIE
    });
};

export function isSimulateurOriginalSource(): boolean {
    const cookieConfigAttributes = getCookieConfigAttributes();
    return cookieConfigAttributes && cookieConfigAttributes?.pdo != null;
}

export function getPostAchatJWT(): string {
    return Cookies.get(JWT_COOKIE_JWT_NAME);
}

export function setPostAchatJWT(token: string, isProdEnv: boolean): void {
    if (isProdEnv) {
        Cookies.set(JWT_COOKIE_JWT_NAME, token, { domain: DOMAIN_PROD });
    } else {
        const url = window.location.href;
        Cookies.set(JWT_COOKIE_JWT_NAME, token, {
            domain: url.includes(DOMAIN_NONPROD_MOUV)
                ? DOMAIN_NONPROD_MOUV
                : DOMAIN_NONPROD_PCF
        });
    }
}

export function getConfigCookie(): string {
    return Cookies.get(JWT_COOKIE_CONFIG_NAME);
}

// Used only for error banner
// Otherwise retrieve idDemande from /session call
export function getPretIdDemande(): string {
    const cookieConfigAttributes = getCookieConfigAttributes();
    if (!cookieConfigAttributes) {
        return null;
    }
    // try to retrieve and parse infoPret information
    try {
        const infoPret = JSON.parse(cookieConfigAttributes?.infoPret);
        return infoPret?.idDemande;
    } catch (error) {
        return null;
    }
}
//eslint-disable-next-line
export function getLangueFromCookies() {
    const cookieConfigAttributes = getCookieConfigAttributes();
    if (cookieConfigAttributes) {
        return cookieConfigAttributes?.langue;
    }

    return null;
}

export function getDesjLangueFromCookie(): string {
    const cookie = Cookies.get(JWT_COOKIE_DESJ_NAME);
    if (cookie) {
        const decodedCookie = decodeURI(cookie);
        if (decodedCookie.includes('=fr')) {
            return Langues.FR;
        } else if (decodedCookie.includes('=en')) {
            return Langues.EN;
        }
    }
    return null;
}

export function isMobileAppFromCookies(): boolean {
    let isMobile = false;

    const cookieConfigAttributes = getCookieConfigAttributes();
    if (cookieConfigAttributes) {
        isMobile = cookieConfigAttributes?.isApp;
    }

    return isMobile;
}

export function isNextReturnFromAssurance(): boolean {
    let isNextReturnFromAssurance = false;

    const cookieConfigAttributes = getCookieConfigAttributes();
    if (cookieConfigAttributes) {
        isNextReturnFromAssurance =
            cookieConfigAttributes?.statutAssurance === 'NEXT';
    }

    return isNextReturnFromAssurance;
}

export function getRetourUrl(): string {
    const baseUrlRetour = isEnvNonProd() ? ACCESWEB_NONPROD : ACCESWEB_PROD;
    return baseUrlRetour + '/identifiantunique/sso/redirect';
}

export function deleteCookie(
    name: string,
    domaine: string,
    path: string
): void {
    Cookies.remove(name, { path: path, domain: domaine });
}
