import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import {
    useSessionApi,
    useDatesProchainPaiementApi,
    useSauvegarderModalitesOffreApi,
    useRoutes,
    infoOffreSelectionne,
    infoOffreSimulee,
    TypeOperationModalites,
    useRenouvellementApi,
    usePretApi,
    useObtenirResumeApi
} from '../../hooks';
import { IDateProchainPaiement } from '../../hooks/api/calculScenarioPaiement';
import { getFormattedDate } from '../../utils';
import { convertForBackend } from '../../utils/dateParser';
import { ModalitesDatePaiement } from './ModalitesDatePaiement';
import { Navigation as NavigationModalitesDatePaiement } from './ModalitesDatePaiement.navigation';
import {
    sendEvent,
    EVENT_CATEGORIES,
    EVENT_ACTIONS,
    EVENT_LABELS,
    hideLoading,
    setCodeAvis,
    showLoading,
    setMontantSelectionnee,
    setOffreChoisie
} from '../../features';
import { StatutEmprunteur } from '../../components';
import { HeadingProvider } from '../../context';
import { Headings } from '../../styles';

const headingMapping = {
    h2: Headings.h3
};

export const ModalitesDatePaiementConnected: React.FC<unknown> = () => {
    // Hooks
    const dispatch = useDispatch();
    const { getRoute, goToRoute } = useRoutes();
    const [borneDatesPaiement, setBorneDatesPaiement] =
        useState<IDateProchainPaiement>(null);
    const [isErreurVisible, setIsErreurVisible] = useState(false);
    const [isCalendarClicked, setIsCalendarClicked] = useState(false);
    const [dateChoisie, setDateChoisie] = useState(null);
    const [isCalendarInputEmpty, setIsCalendarInputEmpty] = useState(true);

    // Apis
    const { data: session } = useSessionApi();

    const idDemande = session?.contextePret?.idDemande;

    const { data: renouvellement } = useRenouvellementApi(!!session);
    const { data: pret } = usePretApi(idDemande, !!renouvellement);

    const { data: datesPaiement, isFetching: isDatesProchainPaiementFetching } =
        useDatesProchainPaiementApi(idDemande, !!renouvellement);

    const { data: resume, isFetching: isObtenirResumeFetching } =
        useObtenirResumeApi(idDemande, 'Modalites', !!renouvellement);

    // Apis Mutation
    const sauvegarderModalitesOffreApiMutation =
        useSauvegarderModalitesOffreApi();

    // Store
    const isLoading = useSelector((state: any) => state.loading.show);
    const offreModifiee = useSelector(
        (state: any) => state.modalites.offreModifiee
    );
    const tauxInteret: number = useSelector(
        (state: any) => state.modalites.tauxInteret
    );
    const offreChoisie = useSelector(
        (state: any) => state.modalites.offreChoisie
    );

    const handleDateChange = (date: string) => {
        date ? setIsCalendarInputEmpty(false) : setIsCalendarInputEmpty(true);

        setDateChoisie(date);
    };

    const onCalendarFocus = () => {
        setIsCalendarClicked(true);
    };

    const isDateEntreBorne = () => {
        const selectedDate = new Date(dateChoisie);
        const minDate = new Date(borneDatesPaiement?.dateMinimale);
        const maxDate = new Date(borneDatesPaiement?.dateMaximale);

        if (
            selectedDate.getTime() >= minDate.getTime() &&
            selectedDate.getTime() <= maxDate.getTime()
        ) {
            return true;
        } else {
            return false;
        }
    };

    const selectionnerOffre = (dateChoisie) => {
        const idOffre: number = offreChoisie?.idOffre;
        const infoOffreSimulee: infoOffreSimulee = {
            frequence: offreModifiee.frequenceRemboursement,
            amortissement: offreModifiee.amortissement,
            // si le membre n'a pas confirmer le calcul on prend juste le paiement de la borne minimal
            remboursement: offreModifiee?.isCalculer
                ? offreModifiee?.paiement
                : offreModifiee?.borneMinimale,
            typeOperation: TypeOperationModalites.PAIEMENT,
            tauxInitial: tauxInteret,
            dateProchainPaiement: convertForBackend(dateChoisie)
        };

        const infoOffreSelectionne: infoOffreSelectionne = {
            offreSimulee: infoOffreSimulee,
            statutDemande: session?.isMartine ? 'EN_ATTENTE' : 'INITIALE'
        };

        dispatch(showLoading());

        dispatch(
            setMontantSelectionnee(
                infoOffreSelectionne?.offreSimulee?.remboursement
            )
        );

        sauvegarderModalitesOffreApiMutation.mutateAsync({
            idDemande,
            idOffre,
            infoOffreSauvegarde: infoOffreSelectionne
        });
    };

    // Si on refresh ou on vient d'un redirect, fetch offres et init l'offre choisie
    useEffect(() => {
        if (resume?.offreChoisie) {
            dispatch(
                setOffreChoisie({
                    ...resume.offreChoisie
                })
            );
        }
    }, [resume]);

    useEffect(() => {
        // borneDatesPaiement n'a pas ete initialise' dans le store

        if (datesPaiement && resume) {
            const dateProchainPaiement: IDateProchainPaiement =
                datesPaiement.find(
                    (date: IDateProchainPaiement) =>
                        date.frequence === offreModifiee?.frequenceRemboursement
                );

            const datesPaiementTransformed = {
                dateDernierPaiement: getFormattedDate(
                    dateProchainPaiement?.dateDernierPaiement,
                    '/' // le "/" est pour la conversion en objet de DATE
                ),
                dateMinimale: getFormattedDate(
                    dateProchainPaiement?.dateMinimale,
                    '-'
                ),
                dateMaximale: getFormattedDate(
                    dateProchainPaiement?.dateMaximale,
                    '-'
                ),
                frequence: dateProchainPaiement?.frequence
            };

            setBorneDatesPaiement(datesPaiementTransformed);

            dispatch(hideLoading());
        }
    }, [datesPaiement, resume, offreModifiee?.frequenceRemboursement]);

    const handleNext = () => {
        // On regarde si il y a des erreur dans la page (date). Si oui, on ne change pas de vue
        setIsCalendarClicked(false);

        if (dateChoisie && !isCalendarInputEmpty && isDateEntreBorne()) {
            setIsErreurVisible(false);
            selectionnerOffre(dateChoisie);
        } else {
            setIsErreurVisible(true);
            dispatch(
                sendEvent({
                    category: EVENT_CATEGORIES.FORMULAIRE,
                    action: EVENT_ACTIONS.ERREUR_UTILISATEUR,
                    label: EVENT_LABELS.FRM,
                    messageErreur:
                        'Erreur utilisateur page Modalites Date Paiement: Entrez une date valide'
                })
            );
            // Back to the top if error
            window.scrollTo(0, 0);
        }
    };

    useEffect(() => {
        if (sauvegarderModalitesOffreApiMutation.isSuccess) {
            if (sauvegarderModalitesOffreApiMutation.data?.codeAvis) {
                // Save code avis in store
                dispatch(
                    setCodeAvis(
                        sauvegarderModalitesOffreApiMutation.data?.codeAvis
                    )
                );
                dispatch(hideLoading());
            } else {
                goToRoute(getRoute().nextRoute);
            }
        }
    }, [sauvegarderModalitesOffreApiMutation.isSuccess]);

    useEffect(() => {
        const loadingFlag =
            isDatesProchainPaiementFetching || isObtenirResumeFetching;

        // Ne pas utiliser pour desactiver le spinner
        // La partie du haut s'en occupe apres avoir fait des verifications
        if (loadingFlag === true) dispatch(showLoading());
    }, [isDatesProchainPaiementFetching, isObtenirResumeFetching]);

    useEffect(() => {
        dispatch(
            sendEvent({
                category: EVENT_CATEGORIES.FORMULAIRE,
                action: EVENT_ACTIONS.ETAPE_3_A,
                label: EVENT_LABELS.FRM
            })
        );
    }, []);

    return (
        !isLoading && (
            <HeadingProvider value={headingMapping}>
                <StatutEmprunteur />
                <ModalitesDatePaiement
                    dateChoisie={dateChoisie}
                    ancienPaiement={pret?.montantRemboursement}
                    nouvelleFrequencePaiement={
                        offreModifiee?.frequenceRemboursement
                    }
                    isCalendarInputEmpty={isCalendarInputEmpty}
                    isErreurVisible={isErreurVisible}
                    isCalendarClicked={isCalendarClicked}
                    borneDatesPaiement={borneDatesPaiement}
                    onDateChange={handleDateChange}
                    onCalendarFocus={onCalendarFocus}
                />
                <NavigationModalitesDatePaiement onClickNext={handleNext} />
            </HeadingProvider>
        )
    );
};
