import { mockInstance, withDelay } from '../../../../api/axiosInstance.mock';

import { END_POINT } from './usePaiementApi';
import { dataCoEmprunteur1 as sessionMockData } from '../../contexte/useSessionApi/useSessionApi.mock';
import { useSelector } from 'react-redux';

/* eslint-disable */
export const data = {
    idOffre: 130068,
    libelle: '3 ans fixe fermé',
    tauxInteret: 1.8,
    frequences: [
        {
            frequenceRemboursement: 'HEBDOMADAIRE',
            borneMinimale: 372.84,
            borneMaximale: 745.68,
            amortissement: {
                annee: 19,
                mois: 11
            },
            economies: {
                montantTotal: 1914.9,
                economieAssurance: {
                    montant: 0,
                    duree: {
                        annee: 3,
                        mois: 0
                    }
                },
                economieInteret: {
                    montant: 0,
                    duree: {
                        annee: 3,
                        mois: 0
                    }
                },
                economieCapital: {
                    montant: -1215.82,
                    duree: {
                        annee: 3,
                        mois: 0
                    }
                }
            },
            paiementsEtPourcentagesOffreSelectionnee: {
                montantTotalInteret: 16380.06,
                pourcentageInteretSurMontantTotal: 0.28,
                montantTotalAssurancesVie: 500,
                pourcentageAssurancesVieSurMontantTotal: 0.0,
                montantTotalAssurancesInvalidite: 0,
                pourcentageAssurancesInvaliditeSurMontantTotal: 0.0,
                montantTotalCapital: 41782.98,
                pourcentageCapitalSurMontantTotal: 0.72
            },
            paiementsEtPourcentagesOffreActuelle: {
                montantTotalInteret: 19510.78,
                pourcentageInteretSurMontantTotal: 0.32,
                montantTotalAssurancesVie: 500,
                pourcentageAssurancesVieSurMontantTotal: 0.0,
                montantTotalAssurancesInvalidite: 0,
                pourcentageAssurancesInvaliditeSurMontantTotal: 0.0,
                montantTotalCapital: 40567.16,
                pourcentageCapitalSurMontantTotal: 0.68
            }
        },
        {
            frequenceRemboursement: 'AUX_DEUX_SEMAINES',
            borneMinimale: 746.0,
            borneMaximale: 1492.78,
            amortissement: {
                annee: 19,
                mois: 11
            },
            economies: {
                montantTotal: 1859.52,
                economieAssurance: {
                    montant: 0,
                    duree: {
                        annee: 3,
                        mois: 0
                    }
                },
                economieInteret: {
                    montant: 0,
                    duree: {
                        annee: 3,
                        mois: 0
                    }
                },
                economieCapital: {
                    montant: -1262.46,
                    duree: {
                        annee: 3,
                        mois: 0
                    }
                }
            },
            paiementsEtPourcentagesOffreSelectionnee: {
                montantTotalInteret: 16388.8,
                pourcentageInteretSurMontantTotal: 0.28,
                montantTotalAssurancesVie: 500,
                pourcentageAssurancesVieSurMontantTotal: 0.0,
                montantTotalAssurancesInvalidite: 0,
                pourcentageAssurancesInvaliditeSurMontantTotal: 0.0,
                montantTotalCapital: 41829.62,
                pourcentageCapitalSurMontantTotal: 0.72
            },
            paiementsEtPourcentagesOffreActuelle: {
                montantTotalInteret: 19510.78,
                pourcentageInteretSurMontantTotal: 0.32,
                montantTotalAssurancesVie: 500,
                pourcentageAssurancesVieSurMontantTotal: 0.0,
                montantTotalAssurancesInvalidite: 0,
                pourcentageAssurancesInvaliditeSurMontantTotal: 0.0,
                montantTotalCapital: 40567.16,
                pourcentageCapitalSurMontantTotal: 0.68
            }
        },
        {
            frequenceRemboursement: 'MENSUELLE',
            borneMinimale: 1624.3,
            borneMaximale: 3248.78,
            amortissement: {
                annee: 19,
                mois: 11
            },
            economies: {
                montantTotal: 1599.9,
                economieAssurance: {
                    montant: 0,
                    duree: {
                        annee: 3,
                        mois: 0
                    }
                },
                economieInteret: {
                    montant: 0,
                    duree: {
                        annee: 3,
                        mois: 0
                    }
                },
                economieCapital: {
                    montant: -1447.07,
                    duree: {
                        annee: 3,
                        mois: 0
                    }
                }
            },
            paiementsEtPourcentagesOffreSelectionnee: {
                montantTotalInteret: 16463.81,
                pourcentageInteretSurMontantTotal: 0.28,
                montantTotalAssurancesVie: 500,
                pourcentageAssurancesVieSurMontantTotal: 0.0,
                montantTotalAssurancesInvalidite: 0,
                pourcentageAssurancesInvaliditeSurMontantTotal: 0.0,
                montantTotalCapital: 42014.23,
                pourcentageCapitalSurMontantTotal: 0.72
            },
            paiementsEtPourcentagesOffreActuelle: {
                montantTotalInteret: 19510.78,
                pourcentageInteretSurMontantTotal: 0.32,
                montantTotalAssurancesVie: 500,
                pourcentageAssurancesVieSurMontantTotal: 0.0,
                montantTotalAssurancesInvalidite: 0,
                pourcentageAssurancesInvaliditeSurMontantTotal: 0.0,
                montantTotalCapital: 40567.16,
                pourcentageCapitalSurMontantTotal: 0.68
            }
        }
    ]
};

const endPoint = END_POINT.replace(
    /{idDemande}/g,
    String(sessionMockData?.contextePret?.idDemande)
);

export const usePaiementApiMock = (): void => {
    // Store: obtenir l'offre choisie pour rendre les mock dynamique
    const offreChoisie = useSelector(
        (state: any) => state.modalites.offreChoisie
    );

    let dataToReturn;

    if (offreChoisie?.idOffre) {
        dataToReturn = {
            ...data,
            idOffre: offreChoisie?.idOffre
        };
    } else {
        dataToReturn = data;
    }

    mockInstance.onGet(endPoint).reply(withDelay([200, dataToReturn], 1000));
};
