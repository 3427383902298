import React from 'react';
import { useTranslation } from 'react-i18next';
import {
    Box,
    createStyles,
    makeStyles,
    useMediaQuery
} from '@material-ui/core';

import { FrequenceRemboursement } from '../../hooks';
import {
    ContainerMui,
    Variant,
    Typography,
    Article,
    ColorHex,
    IconTooltip,
    ColorDSD,
    SpacingRange,
    DateText
} from '../../styles';

export interface IResumeMontantProps {
    montant: number;
    frequenceRemboursement: FrequenceRemboursement;
    hasAssurance: boolean;
    has5Dans1Note: boolean;
    dateCalcul: Date;
    isSIMode: boolean;
    dateProchainPaiement: Date;
    isWide?: boolean;
    spacerDistance?: SpacingRange;
    spacerDistanceMobile?: SpacingRange;
    onTooltipActive: () => void;
}

const useStyles = makeStyles(() =>
    createStyles({
        titrePaiement: {
            fontSize: '14px !important',
            fontWeight: 400
        },
        paiement: {
            fontSize: '20px !important',
            fontWeight: 700
        }
    })
);

export const ResumeMontant: React.FC<IResumeMontantProps> = ({
    montant,
    frequenceRemboursement,
    hasAssurance,
    has5Dans1Note,
    dateCalcul,
    isSIMode,
    dateProchainPaiement,
    isWide = false,
    spacerDistance = 5,
    spacerDistanceMobile = 5,
    onTooltipActive
}) => {
    const [t] = useTranslation(['ResumeMontant']);
    const classes = useStyles();
    const isMobile = useMediaQuery('(max-width:640px)');

    return (
        <ContainerMui
            backgroundColor={
                isSIMode
                    ? ColorHex.lightConfirmation2
                    : ColorHex.lightConfirmation1
            }
            borderRadius="0.5rem"
            spacerDistance={spacerDistance}
            spacerDistanceMobile={spacerDistanceMobile}
            dataTestId={'boite_verte_resume_paiement'}
            padding={isMobile ? '0.5rem 1rem 0rem 1rem' : '0.5rem 1rem 1rem'}
        >
            <Box
                display="flex"
                flexWrap="nowrap"
                alignItems="center"
                mb={isSIMode ? 0 : '1rem'}
            >
                <Typography
                    variant={Variant.h3}
                    article={Article.a4}
                    color={ColorHex.darkPrimary}
                    className={classes.titrePaiement}
                >
                    {t('resumeMontantTitle')}
                    {hasAssurance && t('resumeMontantTitleSuffixAssurance')}
                </Typography>
                {!isSIMode && (
                    <Box
                        paddingLeft="0.75rem"
                        mb={isWide ? '0.75rem ' : '1rem'} // Cet espacement est utilise pour eviter que le text "paiement avec assurance" overlap le montant
                        data-testid={'info_bulle_paiement'}
                    >
                        <IconTooltip
                            iconName="alertes_rempli_information"
                            iconSize="md"
                            fill={ColorDSD.lightGraphite4}
                            srOnlySuffix={t('resumeMontantTooltipAltText')}
                            label={t('resumeMontantTooltip')}
                            onActive={onTooltipActive}
                        />
                    </Box>
                )}
            </Box>
            <Box
                display="flex"
                justifyContent="center"
                flexWrap="nowrap"
                mt={isSIMode ? '-0.75rem' : '-1.75rem'}
                mb={isWide ? '0' : '1rem'} // Cet espacement est utilise pour eviter que le text "paiement avec assurance" overlap le montant
            >
                <Typography
                    variant={Variant.p}
                    color={ColorHex.darkPrimary}
                    className={classes.paiement}
                >
                    {t('Number:currency', {
                        value: montant
                    })}
                    {t(frequenceRemboursement)}
                    {has5Dans1Note && (
                        <>
                            {t('resumeMontant5Dans1Prefix')}
                            <sup>{t('resumeMontant5Dans1Sup')}</sup>
                            {t('resumeMontant5Dans1Suffix')}
                        </>
                    )}
                </Typography>
            </Box>
            {isSIMode ? (
                <Box
                    display="flex"
                    justifyContent="center"
                    flexWrap="nowrap"
                    mt={isWide ? '0rem' : '-1.25rem'}
                >
                    <Typography variant={Variant.p} article={Article.a3}>
                        <DateText
                            translationKey="ResumeMontant:resumeMontantSubTitleSI"
                            date={dateProchainPaiement}
                        >
                            {{ date: dateProchainPaiement }}
                        </DateText>
                    </Typography>
                </Box>
            ) : (
                <Box
                    display="flex"
                    justifyContent="center"
                    flexWrap="nowrap"
                    mt={isWide ? '0rem' : '-1rem'}
                >
                    <Typography variant={Variant.p} article={Article.a4}>
                        <DateText
                            translationKey="ResumeMontant:resumeMontantSubTitle"
                            date={dateCalcul}
                        >
                            {{ date: dateCalcul }}
                        </DateText>
                    </Typography>
                </Box>
            )}
        </ContainerMui>
    );
};
