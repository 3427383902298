import { END_POINT, IInfosSignature } from './useListeConventionsApi';
import { mockInstance, withDelay } from '../../../../api/axiosInstance.mock';

import { dataCoEmprunteur1 as sessionMockData } from '../../contexte/useSessionApi/useSessionApi.mock';

export const data: IInfosSignature = {
    listeConventions: [
        {
            cellulaire: '5140001234',
            courriel: 'testmock@mock.com',
            idEmprunteur: 28093,
            modeAuthentification: 'S',
            question1Id: 2,
            question1Libelle: null,
            question2Id: 9,
            question2Libelle: null,
            reponse1: 'Desjardins',
            reponse2: 'Bob',
            langue: 'fr'
        },
        {
            cellulaire: null,
            courriel: 'testmock2@mock.com',
            idEmprunteur: 28371,
            modeAuthentification: 'Q',
            question1Id: 2,
            question1Libelle: null,
            question2Id: 9,
            question2Libelle: null,
            reponse1: 'Desjardins',
            reponse2: 'Martine',
            langue: 'fr'
        }
    ]
};

const endPoint = END_POINT.replace(
    /{idDemande}/g,
    String(sessionMockData?.contextePret?.idDemande)
);

export const useListeConventionsApiMock = (): any =>
    mockInstance.onGet(endPoint).reply(withDelay([200, data], 200));
