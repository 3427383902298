import React, { useEffect, useState } from 'react';
import { AxiosError } from 'axios';

import { ErrorBanner } from './ErrorBanner';
import { getPretIdDemande } from '../../utils';
import { isEnvNonProd } from '../../config';

interface IErrorBannerConnectedProps {
    axiosError: AxiosError<any>;
}

export const ErrorBannerConnected: React.FC<IErrorBannerConnectedProps> = ({
    axiosError
}) => {
    const [isVisible, setIsVisible] = useState(false);
    const [errorDate, setErrorDate] = useState('');
    const [errorDetails, setErrorDetails] = useState({
        message: '',
        key: '',
        traceId: null,
        code: null
    });
    const isNonProdEnv = isEnvNonProd();

    const handleCloseErrorBanner = () => {
        setIsVisible(false);
    };

    useEffect(() => {
        if (isNonProdEnv) {
            if (axiosError) {
                // retrieve error & parse
                const errors = axiosError?.response?.data?.errors;
                if (errors && errors.length > 0 && errors[0]?.message) {
                    errorDetails.message = errors[0]?.message;
                    errorDetails.key = errors[0]?.code;
                    errorDetails.traceId = errors[0]?.meta?.traceId;
                    errorDetails.code = axiosError?.response?.status;
                    setErrorDetails(errorDetails);
                }
            }
            setErrorDate(new Date().toString().substr(4, 20));
            // Set visible if not prod && message found
            setIsVisible(true);
        }
    }, [isNonProdEnv, axiosError]);

    return (
        <>
            {isVisible && (
                <>
                    <ErrorBanner
                        idDemande={getPretIdDemande()}
                        errorDetails={errorDetails}
                        errorUrl={axiosError?.config?.url}
                        errorDate={errorDate}
                        onClose={handleCloseErrorBanner}
                    />
                </>
            )}
        </>
    );
};
