import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { Navigation } from './Erreur.navigation';
import { Erreur } from './Erreur';
import { HeadingProvider } from '../../context';
import { Headings } from '../../styles';
import {
    hideLoading,
    sendEvent,
    EVENT_CATEGORIES,
    EVENT_ACTIONS,
    EVENT_LABELS
} from './../../features';
interface ErreurProps {
    codeErreur: number;
}

export enum CODE_ERREUR_TECHNIQUE_RHN {
    ERREUR_TECHNIQUE = 'RSET9000'
}

const headingMapping = {
    h1: Headings.h3
};

export const ErreurConnected: React.FC<ErreurProps> = ({ codeErreur }) => {
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(hideLoading());

        dispatch(
            sendEvent({
                category: EVENT_CATEGORIES.FORMULAIRE,
                action: EVENT_ACTIONS.ERREUR_TECHNIQUE,
                label: EVENT_LABELS.FRM,
                messageErreur: codeErreur
            })
        );
    }, []);

    return (
        <HeadingProvider value={headingMapping}>
            <Erreur />
            <Navigation />
        </HeadingProvider>
    );
};
