import React from 'react';
import { useTranslation } from 'react-i18next';
import {
    Box,
    makeStyles,
    createStyles,
    useMediaQuery
} from '@material-ui/core';

import {
    GridRow,
    GridCol,
    Typography,
    Variant,
    Police,
    Button,
    ButtonVariant,
    ContainerMui,
    Spacer,
    ColorDSD,
    IconTooltip
} from '../../styles';

const useStyles = makeStyles(() =>
    createStyles({
        note: {
            fontSize: '0.875rem',
            fontWeight: 'normal'
        }
    })
);

export interface IResumeModalitesProps {
    isEditButtonDisplayed: boolean;
    shouldDisplayVariableProtegeNote: boolean;
    montant: number;
    amortissement: { annee: number; mois: number };
    frequence: string;
    isPretAvecAssurance: boolean;
    isPretAvecAjoutAssurance: boolean;
    isLastInValidationPage: boolean;
    onModifiedClick: () => void;
    onTooltipActive: () => void;
}

export const ResumeModalites: React.FC<IResumeModalitesProps> = ({
    isEditButtonDisplayed,
    shouldDisplayVariableProtegeNote,
    montant,
    amortissement,
    frequence,
    isPretAvecAssurance,
    isPretAvecAjoutAssurance,
    isLastInValidationPage,
    onModifiedClick,
    onTooltipActive
}) => {
    const [t] = useTranslation(['ResumeModalites']);
    const classes = useStyles();
    const isMobile = useMediaQuery('(max-width:640px)');

    const formattedAnnees = t('Date:years', {
        value: amortissement?.annee
    });

    const formattedMois = t('Date:months', {
        value: amortissement?.mois
    });

    return (
        <>
            <ContainerMui
                dataTestId="bloc_modalite_validation"
                noSpacer={isLastInValidationPage}
            >
                <Box
                    ml={isMobile ? 1 : 3}
                    mb={
                        isEditButtonDisplayed
                            ? isMobile
                                ? -2
                                : -1
                            : isMobile
                            ? -4
                            : -3
                    }
                    mt={isMobile ? -2 : -1}
                >
                    <GridCol col="12">
                        <GridRow>
                            <Typography
                                variant={Variant.h4}
                                police={Police.bold}
                                marginBottom="1rem"
                            >
                                {t('resumeModalitesTitle')}
                            </Typography>
                        </GridRow>
                        <GridRow>
                            <Typography
                                variant={Variant.p}
                                marginBottom="0.25rem"
                            >
                                {t('resumeModalitesTypeTitle')}
                            </Typography>
                        </GridRow>
                        <GridRow>
                            <Typography
                                variant={Variant.p}
                                police={Police.bold}
                                marginBottom="1rem"
                            >
                                {t(frequence)}
                            </Typography>
                        </GridRow>
                        <GridRow>
                            <Typography
                                variant={Variant.p}
                                marginBottom="0.25rem"
                            >
                                {t('resumeModalitesAmortissementTitle')}
                            </Typography>
                        </GridRow>
                        <GridRow>
                            <Typography
                                variant={Variant.p}
                                police={Police.bold}
                                marginBottom="1rem"
                            >
                                {formattedAnnees}
                                {amortissement?.mois > 0 &&
                                    t('resumeModalitesAmortissementJoin') +
                                        ' ' +
                                        formattedMois}
                            </Typography>
                        </GridRow>
                        <div data-testid="modalites_resume_paiement">
                            <GridRow>
                                <Box
                                    display="flex"
                                    flexWrap="nowrap"
                                    alignItems="center"
                                >
                                    <Typography
                                        variant={Variant.p}
                                        marginBottom="0.25rem"
                                    >
                                        {t('resumeModalitesInsuranceTitle')}
                                        {isPretAvecAssurance &&
                                            t(
                                                'resumeModalitesInsuranceSubTitle'
                                            )}
                                    </Typography>
                                    {isPretAvecAjoutAssurance && (
                                        <Box
                                            paddingLeft="0.75rem"
                                            data-testid={'info_bulle_paiement'}
                                        >
                                            <IconTooltip
                                                iconName="alertes_rempli_information"
                                                iconSize="md"
                                                fill={ColorDSD.lightGraphite4}
                                                srOnlySuffix={t(
                                                    'resumeModalitesTooltipAltText'
                                                )}
                                                label={t(
                                                    'resumeModalitesTooltipText'
                                                )}
                                                onActive={onTooltipActive}
                                            />
                                        </Box>
                                    )}
                                </Box>
                            </GridRow>
                            <GridRow>
                                <Typography variant={Variant.p}>
                                    <strong>
                                        {t('Number:currency', {
                                            value: montant
                                        })}
                                    </strong>
                                    {shouldDisplayVariableProtegeNote && (
                                        <>
                                            <span className={classes.note}>
                                                {t(
                                                    'resumeModalitesPaymentVariableProtegeNote'
                                                )}
                                            </span>
                                        </>
                                    )}
                                </Typography>
                            </GridRow>
                        </div>
                        {/* Ne pas afficher si MARTINE ( page validation ou choix 1er coemprunteur ) */}
                        {isEditButtonDisplayed && (
                            <GridRow>
                                <Button
                                    id={'QA_test_validation_date_modifier'}
                                    variant={ButtonVariant.compact}
                                    dataCy="qa-button-date-modifier"
                                    dataTestId={'bouton_modifier_modalites'}
                                    onClick={onModifiedClick}
                                    srOnlySuffix={
                                        t('resumeChangeButton') +
                                        ' ' +
                                        t('resumeModalitesTitle')
                                    }
                                >
                                    {t('resumeChangeButton')}
                                </Button>
                            </GridRow>
                        )}
                    </GridCol>
                </Box>
            </ContainerMui>
            <Spacer md={3} sm={1} xs={1} />
        </>
    );
};
