import React from 'react';

// Type de headers pour override DSD
enum Headings {
    h1 = 'dsd-t-1',
    h2 = 'dsd-t-2',
    h3 = 'dsd-t-3',
    h4 = 'dsd-t-4',
    h5 = 'dsd-t-5',
    h6 = 'dsd-t-6'
}

const defaultHeadingMapping = {
    h1: Headings.h1,
    h2: Headings.h2,
    h3: Headings.h3,
    h4: Headings.h4,
    h5: Headings.h5,
    h6: Headings.h6
};

export interface IHeaderMapping {
    h1?: string;
    h2?: string;
    h3?: string;
    h4?: string;
    h5?: string;
    h6?: string;
}

export interface IHeadingProviderProps {
    children: React.ReactNode;
    value?: IHeaderMapping;
}

export const HeadingContext = React.createContext(defaultHeadingMapping);

export const HeadingProvider: React.FC<IHeadingProviderProps> = ({
    children,
    value
}): any => {
    return (
        //@ts-ignore
        <HeadingContext.Provider value={value || defaultHeadingMapping}>
            {children}
        </HeadingContext.Provider>
    );
};
