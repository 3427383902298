import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { Modalites } from './Modalites';
import {
    usePretApi,
    useSessionApi,
    useObtenirResumeApi,
    hasAlreadyAssurance,
    infoOffreSelectionne,
    infoOffreSimulee,
    useSauvegarderModalitesOffreApi,
    TypeOperationModalites,
    useRoutes,
    useRenouvellementApi,
    TypeOffreTarification
} from '../../hooks';
import { Headings } from '../../styles';
import { Navigation as NavigationModalites } from './Modalites.navigation';
import { StatutEmprunteur } from '../../components';
import {
    setLoading,
    setMontantSelectionnee,
    setOffreChoisie,
    sendEvent,
    EVENT_CATEGORIES,
    EVENT_ACTIONS,
    EVENT_LABELS,
    showLoading,
    hideLoading,
    setCodeAvis,
    setOffreBase,
    setOffresList,
    setOffreModifiee,
    setTauxInteret
} from '../../features';
import {
    useFrequenceAccelereeApi,
    usePaiementApi
} from '../../hooks/api/calculScenarioPaiement';
import { HeadingProvider } from '../../context';
import { getFormattedFrequencesOptions } from './modalites.transformer';
import {
    hasOffreSimulee,
    hasSoumissionAssurance
} from '../../hooks/api/demande/useObtenirResumeApi/Resume.utils';

const headingMapping = {
    h2: Headings.h3
};

/*
Petit guide pour cette page compliqué: 

    -   offreBase => l'offre avec laquel tu rentre dans la page de modalites pour la 1ere fois dans ta session. L'information provient
        de l'offre choisie dans /resume. ex: offre selectionne dans la page offre ou l'offre simulee du parcours assurance

    -   offreChoisie => l'offre choisie retourné dans /resume, ex: page modalites, date paiement et validation. Donc l'offre que 
        tu obtient en sauvegardant l'offre en backend dans le parcours de modalites ou simplement l'offre sauvegardé en backend comme choisie

    -   offreModifie => l'objet d'offre utilise pour sauver le state de la page de modalites. Eventuellement lorsque le membre décide de 
        continuer ces informations sont transféré dans l'offre choisie en backend.
*/
export const ModalitesConnected: React.FC<unknown> = () => {
    const dispatch = useDispatch();
    const { getRoute, goToRoute } = useRoutes();

    // State
    const [coutAssuranceModalDisplayed, setCoutAssuranceModalDisplayed] =
        useState(false);
    const [showLoadingCalculations, setShowLoadingCalculations] =
        useState(true);

    // Store
    const isLoading = useSelector((state: any) => state.loading.show);
    const offreBase = useSelector((state: any) => state.modalites.offreBase);
    const montantSelectionne = useSelector(
        (state: any) => state.renouvellement.montantSelectionne
    );
    const tauxInteret: number = useSelector(
        (state: any) => state.modalites.tauxInteret
    );

    // Apis
    const { data: session } = useSessionApi();
    const { data: renouvellement } = useRenouvellementApi(!!session);

    const idDemande = session?.contextePret?.idDemande;
    const { data: pret, isFetching: isPretFetching } = usePretApi(
        idDemande,
        !!renouvellement
    );
    const { data: resumeChoix, isFetching: isObtenirResumeFetching } =
        useObtenirResumeApi(idDemande, 'Modalites', !!renouvellement);
    const { data: frequencesData, isFetching: isFrequenceFetching } =
        useFrequenceAccelereeApi(idDemande, !!renouvellement);
    const { data: paiementData, isFetching: isPaiementFetching } =
        usePaiementApi(idDemande, !!renouvellement);

    // Apis Mutation
    const sauvegarderModalitesOffreApiMutation =
        useSauvegarderModalitesOffreApi();

    // offreASauvegarder est l'offreModifie ou l'offreDeBase qui devient
    // eventuellement offre choisie en backend qui est retourne par /resume
    const handleClickNext = (offreASauvegarder: any) => {
        const infoOffreSelectionne: infoOffreSelectionne = {
            statutDemande: session?.isMartine ? 'EN_ATTENTE' : 'INITIALE'
        };

        dispatch(showLoading());

        // on compare avec borneMinimal parce que si changement de frequence cette fonction n'est pas appelé
        // donc la borneMinimal est toujours le paiement de l'offre original
        const isOffreModifiee: boolean =
            offreASauvegarder?.paiement !== offreASauvegarder?.borneMinimale;

        if (isOffreModifiee) {
            // preparer les infos pour l'offre simulee
            const infoOffreSimulee: infoOffreSimulee = {
                frequence: offreASauvegarder.frequenceRemboursement,
                amortissement: offreASauvegarder.amortissement,
                remboursement: offreASauvegarder.paiement,
                typeOperation: TypeOperationModalites.PAIEMENT,
                tauxInitial: tauxInteret,
                dateProchainPaiement: null
            };
            infoOffreSelectionne.offreSimulee = infoOffreSimulee;

            sauvegarderModalitesOffreApiMutation.mutateAsync({
                idDemande,
                idOffre: offreASauvegarder?.idOffre,
                infoOffreSauvegarde: infoOffreSelectionne
            });
            dispatch(
                setMontantSelectionnee(
                    infoOffreSelectionne?.offreSimulee?.remboursement
                )
            );
        } else {
            sauvegarderModalitesOffreApiMutation.mutateAsync({
                idDemande,
                idOffre: offreBase?.idOffre,
                infoOffreSauvegarde: infoOffreSelectionne
            });
        }
    };

    useEffect(() => {
        dispatch(
            sendEvent({
                category: EVENT_CATEGORIES.FORMULAIRE,
                action: EVENT_ACTIONS.ETAPE_3,
                label: EVENT_LABELS.FRM
            })
        );
    }, []);

    useEffect(() => {
        const fetchingCalculationsDone =
            isPaiementFetching === false && isFrequenceFetching === false;
        if (fetchingCalculationsDone) {
            setShowLoadingCalculations(false);
        }
    }, [isPaiementFetching, isFrequenceFetching]);

    useEffect(() => {
        const loadingFlag = isPretFetching || isObtenirResumeFetching;
        dispatch(setLoading(loadingFlag));
    }, [isPretFetching, isObtenirResumeFetching]);

    // Retour de la sauvegarde de l'offre
    useEffect(() => {
        if (sauvegarderModalitesOffreApiMutation.isSuccess) {
            if (sauvegarderModalitesOffreApiMutation.data?.codeAvis) {
                // Save code avis in store
                dispatch(
                    setCodeAvis(
                        sauvegarderModalitesOffreApiMutation.data?.codeAvis
                    )
                );
                dispatch(hideLoading());
            } else {
                // Route suivante
                // et pas besoin de cacher le spinner global car la prochaine page va s'en occuper
                goToRoute(getRoute().nextRoute);
            }
        }
    }, [sauvegarderModalitesOffreApiMutation.isSuccess]);

    // Si on refresh ou on vient d'un redirect, fetch offres et init l'offre choisie
    useEffect(() => {
        if (resumeChoix) {
            if (offreBase == null) {
                dispatch(
                    setOffreBase({
                        ...resumeChoix.offreChoisie
                    })
                );
            }
        }

        // On sauvegarde le taux actuel si on rebascule ici
        if (resumeChoix && !montantSelectionne) {
            dispatch(
                setMontantSelectionnee(
                    resumeChoix?.offreChoisie.montantRemboursement
                )
            );
        }

        if (pret?.listeAssures && resumeChoix?.assurance) {
            const isPretAssure = pret?.listeAssures.length > 0;
            const isAlreadyAssured = hasAlreadyAssurance(
                resumeChoix?.assurance?.codeAssurance
            );

            setCoutAssuranceModalDisplayed(isPretAssure && isAlreadyAssured);
        }

        if (
            paiementData &&
            frequencesData &&
            resumeChoix?.offreChoisie &&
            pret
        ) {
            //@ts-ignore
            const paiementEtFrequences = paiementData.concat(frequencesData);

            const formattedFrequencesOptions =
                getFormattedFrequencesOptions(paiementEtFrequences);

            const offreModifieeFormatted = formattedFrequencesOptions.find(
                ({ frequenceRemboursement }) =>
                    frequenceRemboursement ===
                    resumeChoix?.offreChoisie.frequenceRemboursement
            );

            if (
                resumeChoix.offreChoisie.typeOffreTarification ===
                TypeOffreTarification.SIMULEE
            ) {
                offreModifieeFormatted.paiement =
                    resumeChoix.offreChoisie.montantRemboursement;
            } else {
                offreModifieeFormatted.paiement =
                    offreBase?.montantRemboursement;
            }

            dispatch(setOffresList(formattedFrequencesOptions));

            dispatch(
                setOffreModifiee({
                    ...offreModifieeFormatted,
                    isCalculer: true
                })
            );

            dispatch(
                setOffreChoisie({
                    ...resumeChoix?.offreChoisie
                })
            );

            dispatch(setTauxInteret(paiementEtFrequences[0]?.tauxInteret));
        }
    }, [paiementData, frequencesData, resumeChoix, pret]);

    return (
        !isLoading && (
            <HeadingProvider value={headingMapping}>
                <StatutEmprunteur />
                <Modalites
                    paiementData={paiementData}
                    frequencesData={frequencesData}
                    typePret={resumeChoix?.titreOffre}
                    coutAssuranceModalDisplayed={coutAssuranceModalDisplayed}
                    showLoadingCalculations={showLoadingCalculations}
                />
                <NavigationModalites
                    pretAdmissibleAssurance={
                        renouvellement.pretAdmissibleAssurance === true
                    }
                    offreSimuleeModalite={
                        hasOffreSimulee(
                            resumeChoix?.offreChoisie?.typeOffreTarification
                        ) &&
                        !hasSoumissionAssurance(
                            resumeChoix?.assurance?.codeAssurance
                        )
                    }
                    onClickNext={handleClickNext}
                />
            </HeadingProvider>
        )
    );
};
