import React from 'react';
import { useDispatch } from 'react-redux';

import { ResumeMontant } from './ResumeMontant';
import { IResumeOffre, hasCurrentlyAssurance } from '../../hooks';
import {
    sendEvent,
    EVENT_CATEGORIES,
    EVENT_ACTIONS,
    EVENT_LABELS
} from '../../features';
import { SpacingRange } from '../../styles';
import { getFormattedDate } from '../../utils';

const CYBERMETRIE_TOOLTIP_LABEL = 'Paiement';
const CYBERMETRIE_TOOLTIP_LABEL_WITH_INSURANCE = 'Paiement avec assurance';

export interface IResumeMontantConnectedProps {
    resumeOffre: IResumeOffre;
    isSIMode?: boolean;
    isWide?: boolean;
    spacerDistance?: SpacingRange;
    spacerDistanceMobile?: SpacingRange;
}

export const ResumeMontantConnected: React.FC<IResumeMontantConnectedProps> = ({
    resumeOffre,
    isSIMode = false,
    isWide,
    spacerDistance = 5,
    spacerDistanceMobile = 5
}) => {
    const dispatch = useDispatch();

    const isPretAvecAssurance = hasCurrentlyAssurance(
        resumeOffre?.assurance?.codeAssurance
    );

    const handleTooltipActive = () => {
        const getLabel = () =>
            isPretAvecAssurance
                ? CYBERMETRIE_TOOLTIP_LABEL_WITH_INSURANCE
                : CYBERMETRIE_TOOLTIP_LABEL;

        dispatch(
            sendEvent({
                category: EVENT_CATEGORIES.RHN,
                action: EVENT_ACTIONS.CLIC,
                label: EVENT_LABELS.INFOBULLE + getLabel()
            })
        );
    };

    return (
        <ResumeMontant
            montant={resumeOffre?.offreChoisie.montantRemboursement}
            frequenceRemboursement={
                resumeOffre?.offreChoisie.frequenceRemboursement
            }
            hasAssurance={isPretAvecAssurance}
            has5Dans1Note={resumeOffre?.offreChoisie?.has5Dans1Note}
            onTooltipActive={handleTooltipActive}
            isWide={isWide}
            dateCalcul={
                new Date(
                    getFormattedDate(
                        resumeOffre?.offreChoisie?.dateCalcul || '',
                        '/'
                    )
                )
            }
            dateProchainPaiement={
                new Date(
                    getFormattedDate(
                        resumeOffre?.dateProchainPaiement || '',
                        '/'
                    )
                )
            }
            isSIMode={isSIMode}
            spacerDistance={spacerDistance}
            spacerDistanceMobile={spacerDistanceMobile}
        />
    );
};
