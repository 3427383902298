import i18n from 'i18next';
import moment from 'moment';
import { initReactI18next } from 'react-i18next';
import Home from './views/home/Home.localization.ts';
import Offres from './views/offres/Offres.localization.ts';
import Erreur from './views/erreur/Erreur.localization.ts';
import Legal from './views/legal/Legal.localization.ts';
import ErreurFonctionnelle from './views/erreurFonctionnelle/ErreurFonctionnelle.localization.ts';
import MoreOptions from './views/home/components/moreOptions/MoreOptions.localization.ts';
import StatusCoEmprunteurDeux from './views/home/components/statusCoEmprunteurDeux/StatusCoEmprunteurDeux.localization.ts';
import RenouvellementEtapes from './views/home/components/renouvellementEtapes/RenouvellementEtapes.localization.ts';
import PretActuelModal from './components/pretActuelModal/PretActuelModal.localization';
import NoteLegaleOffre from './components/noteLegaleOffre/NoteLegaleOffre.localization.ts';
import NoteLegaleEveilAssurance from './components/noteLegaleEveilAssurance/NoteLegaleEveilAssurance.localization.ts';
import SupportBar from './components/supportBar/SupportBar.localization.ts';
import Header from './components/header/Header.localization.ts';
import ProgressBar from './components/progressBar/ProgressBar.localization.ts';
import InfoAlert from './components/infoAlert/InfoAlert.localization';
import DetailsOffreModal from './components/detailsOffreModal/DetailsOffreModal.localization';
import Card from './views/offres/components/card/Card.localization';
import ProtectionModal from './components/protectionModal/ProtectionModal.localization';
import InfoTaux from './views/offres/components/infoTaux/InfoTaux.localization';
import Aiguilleur from './views/offres/components/aiguilleur/Aiguilleur.localization';
import Modalites from './views/modalites/Modalites.localization';
import FrequenceSelect from './views/modalites/components/frequenceSelect/FrequenceSelect.localization';
import DetailsPaiementBar from './views/modalites/components/detailsPaiementBar/DetailsPaiementBar.localization.tsx';
import InfoEconomie from './views/modalites/components/infoEconomie/InfoEconomie.localization';
import PaiementSelect from './views/modalites/components/paiementSelect/PaiementSelect.localization';
import RepartitionDonut from './views/modalites/components/repartitionDonut/RepartitionDonut.localization';
import CoutAssuranceModal from './views/modalites/components/coutAssuranceModal/CoutAssuranceModal.localization';
import ModalitesDatePaiement from './views/modalitesDatePaiement/ModalitesDatePaiement.localization';
import InformationsSignature from './views/informationsSignature/InformationsSignature.localization';
import AlertInputError from './components/alertInputError/AlertInputError.localization';
import NavigationModal from './components/navigationModal/NavigationModal.localization';
import SaveAndLeaveModal from './components/saveAndLeaveModal/SaveAndLeaveModal.localization';
import ModalSaveAndLeaveSignee from './components/modalSaveAndLeaveSignee/ModalSaveAndLeaveSignee.localization';
import Validation from './views/validation/Validation.localization';
import Confirmation from './views/confirmation/Confirmation.localization';
import AnnuleeConseiller from './views/annuleeConseiller/AnnuleeConseiller.localization';
import AnnuleeSysteme from './views/annuleeSysteme/AnnuleeSysteme.localization';
import ResumePret from './components/resumePret/ResumePret.localization';
import ResumeAssurance from './components/resumeAssurance/ResumeAssurance.localization';
import ResumeDate from './components/resumeDate/ResumeDate.localization';
import ResumeMontant from './components/resumeMontant/ResumeMontant.localization';
import ResumeModalites from './components/resumeModalites/ResumeModalites.localization';
import CodeAvis from './components/codeAvis/CodeAvis.localization';
import EveilAssurance from './views/eveilAssurance/EveilAssurance.localization';
import DetailsCoutAssuranceModal from './components/detailsCoutAssuranceModal/DetailsCoutAssuranceModal.localization';
import DeeplinkSansPret from './views/deeplink/component/deeplinkSansPret/DeeplinkSansPret.localization';
import DeeplinkAvecPret from './views/deeplink/component/deeplinkAvecPret/DeeplinkAvecPret.localization';
import Deeplink from './views/deeplink/Deeplink.localization';
import DeeplinkParlerConseiller from './views/deeplink/component/deeplinkParlerConseiller/DeeplinkParlerConseiller.localization';
import InteretRetard from './views/interetRetard/InteretRetard.localization';
import Signature from './views/signature/Signature.localization';
import AppelerCaisse from './components/appelerCaisse/AppelerCaisse.localization';
import DateText from './styles/dateText/DateText.localization';
import IconTooltip from './styles/iconTooltip/IconTooltip.localization';

// eslint-disable-next-line jest/require-hook
i18n.use(initReactI18next).init({
    ns: [],
    fallbackLng: 'fr',
    resources: {
        en: {
            Home: Home.en,
            Offres: Offres.en,
            Erreur: Erreur.en,
            ErreurFonctionnelle: ErreurFonctionnelle.en,
            Legal: Legal.en,
            Card: Card.en,
            AnnuleeConseiller: AnnuleeConseiller.en,
            AnnuleeSysteme: AnnuleeSysteme.en,
            Confirmation: Confirmation.en,
            Validation: Validation.en,
            DetailsOffreModal: DetailsOffreModal.en,
            MoreOptions: MoreOptions.en,
            StatusCoEmprunteurDeux: StatusCoEmprunteurDeux.en,
            SupportBar: SupportBar.en,
            Header: Header.en,
            ProgressBar: ProgressBar.en,
            RenouvellementEtapes: RenouvellementEtapes.en,
            NoteLegaleOffre: NoteLegaleOffre.en,
            NoteLegaleEveilAssurance: NoteLegaleEveilAssurance.en,
            PretActuelModal: PretActuelModal.en,
            ProtectionModal: ProtectionModal.en,
            InfoTaux: InfoTaux.en,
            Aiguilleur: Aiguilleur.en,
            Modalites: Modalites.en,
            ModalitesDatePaiement: ModalitesDatePaiement.en,
            InformationsSignature: InformationsSignature.en,
            FrequenceSelect: FrequenceSelect.en,
            InfoAlert: InfoAlert.en,
            InfoEconomie: InfoEconomie.en,
            PaiementSelect: PaiementSelect.en,
            RepartitionDonut: RepartitionDonut.en,
            InfoBar: DetailsPaiementBar.en,
            CoutAssuranceModal: CoutAssuranceModal.en,
            AlertInputError: AlertInputError.en,
            NavigationModal: NavigationModal.en,
            SaveAndLeaveModal: SaveAndLeaveModal.en,
            ModalSaveAndLeaveSignee: ModalSaveAndLeaveSignee.en,
            ResumePret: ResumePret.en,
            ResumeAssurance: ResumeAssurance.en,
            ResumeDate: ResumeDate.en,
            ResumeMontant: ResumeMontant.en,
            ResumeModalites: ResumeModalites.en,
            CodeAvis: CodeAvis.en,
            EveilAssurance: EveilAssurance.en,
            DeeplinkSansPret: DeeplinkSansPret.en,
            DeeplinkAvecPret: DeeplinkAvecPret.en,
            Deeplink: Deeplink.en,
            DeeplinkParlerConseiller: DeeplinkParlerConseiller.en,
            DetailsCoutAssuranceModal: DetailsCoutAssuranceModal.en,
            InteretRetard: InteretRetard.en,
            Signature: Signature.en,
            AppelerCaisse: AppelerCaisse.en,
            DateText: DateText.en,
            IconTooltip: IconTooltip.en,
            String: {
                canadianCellphone: '{{value, canadianCellphone}}'
            },
            Number: {
                currency: '{{value, currency, USD}}',
                percent: '{{value, percent}}',
                percentNoDecimal: '{{value, percentNoDecimal}}',
                percent3Decimal: '{{value, percent3Decimal}}'
            },
            Date: {
                days: '{{value, days}}',
                months: '{{value, months}}',
                monthsOffer: '{{value, monthsOffer}}',
                years: '{{value, years}}',
                yearsOffer: '{{value, yearsOffer}}'
            },
            General: {
                titrePagePrincipal: 'Mortgage renewal',
                appStarted: 'RHN started [ENGLISH]',
                loadingText: 'We’re loading the next step.'
            }
        },
        fr: {
            Home: Home.fr,
            Offres: Offres.fr,
            Erreur: Erreur.fr,
            ErreurFonctionnelle: ErreurFonctionnelle.fr,
            Legal: Legal.fr,
            Card: Card.fr,
            AnnuleeConseiller: AnnuleeConseiller.fr,
            AnnuleeSysteme: AnnuleeSysteme.fr,
            Confirmation: Confirmation.fr,
            Validation: Validation.fr,
            DetailsOffreModal: DetailsOffreModal.fr,
            MoreOptions: MoreOptions.fr,
            StatusCoEmprunteurDeux: StatusCoEmprunteurDeux.fr,
            SupportBar: SupportBar.fr,
            Header: Header.fr,
            ProgressBar: ProgressBar.fr,
            RenouvellementEtapes: RenouvellementEtapes.fr,
            NoteLegaleOffre: NoteLegaleOffre.fr,
            NoteLegaleEveilAssurance: NoteLegaleEveilAssurance.fr,
            PretActuelModal: PretActuelModal.fr,
            ProtectionModal: ProtectionModal.fr,
            InfoTaux: InfoTaux.fr,
            Aiguilleur: Aiguilleur.fr,
            Modalites: Modalites.fr,
            ModalitesDatePaiement: ModalitesDatePaiement.fr,
            InformationsSignature: InformationsSignature.fr,
            FrequenceSelect: FrequenceSelect.fr,
            InfoAlert: InfoAlert.fr,
            InfoEconomie: InfoEconomie.fr,
            PaiementSelect: PaiementSelect.fr,
            RepartitionDonut: RepartitionDonut.fr,
            InfoBar: DetailsPaiementBar.fr,
            CoutAssuranceModal: CoutAssuranceModal.fr,
            AlertInputError: AlertInputError.fr,
            NavigationModal: NavigationModal.fr,
            SaveAndLeaveModal: SaveAndLeaveModal.fr,
            ModalSaveAndLeaveSignee: ModalSaveAndLeaveSignee.fr,
            ResumePret: ResumePret.fr,
            ResumeAssurance: ResumeAssurance.fr,
            ResumeDate: ResumeDate.fr,
            ResumeMontant: ResumeMontant.fr,
            ResumeModalites: ResumeModalites.fr,
            CodeAvis: CodeAvis.fr,
            EveilAssurance: EveilAssurance.fr,
            DeeplinkSansPret: DeeplinkSansPret.fr,
            DeeplinkAvecPret: DeeplinkAvecPret.fr,
            Deeplink: Deeplink.fr,
            DeeplinkParlerConseiller: DeeplinkParlerConseiller.fr,
            DetailsCoutAssuranceModal: DetailsCoutAssuranceModal.fr,
            InteretRetard: InteretRetard.fr,
            Signature: Signature.fr,
            AppelerCaisse: AppelerCaisse.fr,
            DateText: DateText.fr,
            IconTooltip: IconTooltip.fr,
            String: {
                canadianCellphone: '{{value, canadianCellphone}}'
            },
            Number: {
                currency: '{{value, currency, CAD}}',
                percent: '{{value, percent}}',
                percentNoDecimal: '{{value, percentNoDecimal}}',
                percent3Decimal: '{{value, percent3Decimal}}'
            },
            Date: {
                full: '{{value, fullDate}}',
                fullSingleDigit: '{{value, fullDateSingleDigit}}',
                days: '{{value, days}}',
                months: '{{value, months}}',
                monthsOffer: '{{value, monthsOffer}}',
                years: '{{value, years}}',
                yearsOffer: '{{value, yearsOffer}}'
            },
            General: {
                titrePagePrincipal: 'Renouvellement hypothécaire',
                appStarted: 'RHN démarré [FRANÇAIS]',
                loadingText: 'Nous préparons la prochaine étape.'
            }
        }
    },
    defaultNS: 'common',
    returnObjects: true,
    keySeparator: false,
    interpolation: {
        escapeValue: false,
        format: (value, rawFormat, lng) => {
            const [format] = rawFormat.split(',').map((v) => v.trim());

            // Format date
            if (value instanceof Date) {
                return moment(value).format(format);
            }

            const getNumberWithSpaces = (x, lng) => {
                const parts = x.toString().split('.');

                lng === 'en'
                    ? (parts[0] = parts[0].replace(
                          /\B(?=(\d{3})+(?!\d))/g,
                          ','
                      ))
                    : (parts[0] = parts[0].replace(
                          /\B(?=(\d{3})+(?!\d))/g,
                          ' '
                      ));

                return parts.join('.').replace(' ', '\xa0');
            };

            switch (format) {
                case 'fullDate': {
                    const fullDate =
                        lng === 'fr'
                            ? moment(value).format('DD MMMM YYYY')
                            : moment(value).format('LL');

                    return `${fullDate}`;
                }
                case 'fullDateSingleDigit': {
                    const fullDate =
                        lng === 'fr'
                            ? moment(value).format('D MMMM YYYY')
                            : moment(value).format('LL');

                    return `${fullDate}`;
                }
                case 'days': {
                    const fValue = Number(value);
                    const getFromattedFr = () =>
                        fValue > 1 ? 'jours' : 'jour';
                    const getFormattedEn = () => (fValue > 1 ? 'days' : 'day');

                    const days =
                        lng === 'fr' ? getFromattedFr() : getFormattedEn();

                    return `${fValue} ${days}`;
                }
                case 'months': {
                    const fValue = Number(value);
                    const getFromattedFr = () => 'mois';
                    const getFormattedEn = () =>
                        fValue > 1 ? 'months' : 'month';

                    const month =
                        lng === 'fr' ? getFromattedFr() : getFormattedEn();

                    return `${fValue} ${month}`;
                }

                case 'monthsOffer': {
                    const fValue = Number(value);
                    const getFromattedFr = () => ' mois';
                    const getFormattedEn = () => '-month';

                    const month =
                        lng === 'fr' ? getFromattedFr() : getFormattedEn();

                    return `${fValue}${month}`;
                }

                case 'years': {
                    const fValue = Number(value);
                    const getFromattedFr = () => (fValue > 1 ? 'ans' : 'an');
                    const getFormattedEn = () =>
                        fValue > 1 ? 'years' : 'year';

                    const year =
                        lng === 'fr' ? getFromattedFr() : getFormattedEn();

                    return `${fValue} ${year}`;
                }

                case 'yearsOffer': {
                    const fValue = Number(value);
                    const getFromattedFr = () => (fValue > 1 ? ' ans' : ' an');
                    const getFormattedEn = () => '-year';

                    const year =
                        lng === 'fr' ? getFromattedFr() : getFormattedEn();

                    return `${fValue}${year}`;
                }

                case 'percent': {
                    value = parseFloat(value).toFixed(2);
                    Intl.NumberFormat(lng, {
                        style: 'decimal'
                    }).format(value);
                    value =
                        lng === 'fr'
                            ? value.replace('.', ',') + '\xa0%'
                            : value + '%';

                    return value;
                }

                case 'percentNoDecimal': {
                    value = parseFloat(value).toFixed(0);
                    Intl.NumberFormat(lng, {
                        style: 'decimal'
                    }).format(value);

                    value = lng === 'fr' ? value + '\xa0%' : value + '%';
                    return value;
                }

                case 'percent3Decimal': {
                    value = parseFloat(value).toFixed(3);
                    Intl.NumberFormat(lng, {
                        style: 'decimal'
                    }).format(value);
                    value =
                        lng === 'fr'
                            ? value.replace('.', ',') + '\xa0%'
                            : value + '%';

                    return value;
                }

                case 'canadianCellphone': {
                    if (value) {
                        let fvalue = value;
                        const firstSeparator = lng === 'fr' ? ' ' : '-';
                        if (fvalue.length == 10) {
                            return (
                                fvalue.substring(0, 3) +
                                firstSeparator +
                                fvalue.substring(3, 6) +
                                '-' +
                                fvalue.substring(6, 10)
                            );
                        }
                    }
                    return value;
                }

                case 'currency': {
                    value = parseFloat(value).toFixed(2);
                    Intl.NumberFormat(lng, {
                        style: 'decimal'
                    }).format(value);
                    if (value === '0.00') {
                        return lng === 'fr' ? '0.00\xa0$' : '$0.00';
                    } else {
                        value =
                            lng === 'fr'
                                ? value.replace('.', ',') + '\xa0$'
                                : '$' + value;
                    }

                    return getNumberWithSpaces(value, lng);
                }
            }
        }
    }
});

export default i18n;
