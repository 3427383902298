import React, { ReactNode } from 'react';

export enum IconPosition {
    end = 'end',
    start = 'start',
    standalone = 'standalone'
}

export enum ButtonVariant {
    secondary = 'secondary',
    compact = 'compact',
    primary = 'primary',
    tertiary = 'tertiary',
    text = 'text'
}

interface IButtonProps {
    analytics?: string;
    isDark?: boolean;
    isDisabled?: boolean;
    fullWidth?: string;
    iconName?: string;
    iconPosition?: IconPosition;
    srOnlySuffix?: string;
    theme?: string;
    type?: string;
    href?: string;
    id?: string;
    dataCy?: string;
    dataTestId?: string;
    variant?: ButtonVariant;
    children: ReactNode;
    onClick?: () => void;
}

export const Button: React.FC<IButtonProps> = ({
    analytics,
    isDark,
    isDisabled,
    fullWidth,
    iconName,
    iconPosition,
    srOnlySuffix,
    theme,
    type,
    href,
    id,
    dataCy,
    dataTestId,
    variant,
    children,
    onClick
}) => {
    return (
        //@ts-ignore
        <dsd-button
            onClick={onClick}
            analytics={analytics}
            href={href}
            isDark={isDark}
            id={id}
            type={type}
            data-cy={dataCy}
            data-testid={dataTestId}
            variant={variant}
            theme={theme}
            full-width={fullWidth}
            icon-name={iconName}
            icon-position={iconPosition}
            sr-only-suffix={srOnlySuffix}
            disabled={isDisabled}
        >
            {children}
            {/*@ts-ignore*/}
        </dsd-button>
    );
};
