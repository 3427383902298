import React from 'react';

export enum TipBannerRadiusSize {
    MD = 'md',
    SM = 'sm'
}

type TitleLevel = 2 | 3 | 4 | 5 | 6;

interface ITipBannerProps {
    analytics?: string;
    isDark?: boolean;
    forcedBorderRadius?: TipBannerRadiusSize;
    titleLevel?: TitleLevel;
    content: string;
    imageSrc?: string;
    imageAlt?: string;
    title: string;
}

export const TipBanner: React.FC<ITipBannerProps> = ({
    analytics,
    isDark = false,
    forcedBorderRadius = null,
    titleLevel = 2,
    content,
    imageSrc,
    imageAlt,
    title
}) => (
    // @ts-ignore
    <dsd-tip-banner
        analytics={analytics}
        dark={isDark}
        forced-border-radius={forcedBorderRadius}
        title-level={titleLevel}
    >
        <span slot="content">{content}</span>
        <span slot="title">{title}</span>
        <img src={imageSrc} alt={imageAlt} />
        {/* @ts-ignore */}
    </dsd-tip-banner>
);
