import axios from 'axios';
import { EnvType, getBaseLocationUrl, getEnvConfig } from '../config';
import { getConfigCookie, getPostAchatJWT } from '../utils';

const envConfig = getEnvConfig();

export const getDynamicHeaders = (): any => {
    return {
        headers: {
            Authorization: 'Bearer ' + getPostAchatJWT(),
            Config: encodeURI(getConfigCookie())
        }
    };
};

export const createAxiosConfig = (): any => {
    const axiosHeaders = {
        'Access-Control-Allow-Origin': getBaseLocationUrl(),
        'Content-Type': 'application/json',
        'x-desj-tenantId': 'auth0'
    };

    // On ajoute en NonProd et si existant le space target
    if (!envConfig.isProd && envConfig?.envSoa) {
        axiosHeaders['x-desj-envsoa'] = envConfig?.envSoa;
    }

    //Localhost: no credentials ==> no cookies ==> no cors
    //NonProd & Prod: Credentials requis pour le parcours Assurance
    return {
        baseURL: envConfig.baseApiUrl,
        timeout: 0,
        headers: axiosHeaders,
        withCredentials: EnvType.LOCAL === envConfig.type ? false : true
    };
};

export const axiosInstanceKeepAlive = axios.create({
    baseURL: envConfig.accesWebUrl,
    timeout: 0,
    headers: { 'content-type': 'application/x-www-form-urlencoded' },
    withCredentials: true
});

export const axiosInstance = axios.create(createAxiosConfig());

export const axiosInstanceNoError = axios.create(createAxiosConfig());

// eslint-disable-next-line jest/require-hook
axiosInstance.interceptors.request.use(
    function (request) {
        return request;
    },
    function (error) {
        return Promise.reject(error);
    }
);

// eslint-disable-next-line jest/require-hook
axiosInstance.interceptors.response.use(
    (response) => {
        return response;
    },
    (error) => {
        return Promise.reject(error);
    }
);
