import { Box } from '@material-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';

import {
    FootnoteGroup,
    Footnote,
    FootnoteGoupListType,
    Grid,
    GridCol
} from '../../styles';
interface INoteLegaleOffreProps {
    onShowNoteLegale: () => void;
}
export const NoteLegaleOffre: React.FC<INoteLegaleOffreProps> = ({
    onShowNoteLegale
}) => {
    const [t] = useTranslation(['NoteLegaleOffre']);

    return (
        <Box mt={1} marginBottom="-0.5rem" marginTop="-2rem">
            <Grid>
                <GridCol col="12">
                    <FootnoteGroup
                        footnoteGroupTitle={t('footnoteGroupTitle')}
                        titleLevel="2"
                        open={true}
                    >
                        <div slot={FootnoteGoupListType.LIST}>
                            <Footnote
                                id="footnote_pret_offert_note_1"
                                returnId="footnote_return_pret_offert_note_1"
                            >
                                {/* le 1. et 2. sont un hack en attendant qu'on puisse update dsd */}
                                {'1. ' + t('noteTauxPageOffre')}
                            </Footnote>
                            <Footnote id={'footnote_offres_notelegale'}>
                                {'2. '}
                                <a
                                    data-testid={'footnote_offres_note_legale'}
                                    onClick={onShowNoteLegale}
                                    style={{
                                        cursor: 'pointer',
                                        fontSize: '15px'
                                    }}
                                >
                                    {t('noteLegaleTexte')}
                                </a>
                            </Footnote>
                        </div>
                    </FootnoteGroup>
                </GridCol>
            </Grid>
        </Box>
    );
};
