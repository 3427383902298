import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { Button, ButtonVariant } from '../../styles';
import {
    NavigationPlacement,
    SaveAndLeaveModal,
    NavigationModal
} from '../../components';
import {
    useRoutes,
    getPageName,
    goQuitterParcours,
    Routes,
    ISession,
    ParcoursEmprunteur
} from '../../hooks';
import {
    sendEvent,
    EVENT_CATEGORIES,
    EVENT_ACTIONS,
    EVENT_LABELS
} from '../../features';
import { isFrequenceEqual } from '../../utils';

export interface IValidationNavigationProps {
    session: ISession;
    admissibleSI: boolean;
    isOffreVariableProtege: boolean;
    isAjoutAssurance: boolean;
    onClickNext: () => void;
    onMartineRefuse: () => void;
    onClickPrevWithAssurance: () => void;
}

export const Navigation: React.FC<IValidationNavigationProps> = ({
    session,
    admissibleSI,
    isOffreVariableProtege,
    isAjoutAssurance,
    onClickNext,
    onMartineRefuse,
    onClickPrevWithAssurance
}) => {
    const dispatch = useDispatch();
    const [t] = useTranslation(['Validation']);
    const { getRoute, goToRoute } = useRoutes();

    // State
    const [isModalOpened, setIsModalOpened] = useState(false);

    // Store
    const offreBase = useSelector((state: any) => state.modalites.offreBase);
    const offreChoisie = useSelector(
        (state: any) => state.modalites.offreChoisie
    );

    const isMartine: boolean =
        session?.typeParcoursEmprunteur ===
        ParcoursEmprunteur.COEMPRUNTEUR_MARTINE;

    // Send cyber & leave
    const leaveApp = () => {
        const pageCourante = getPageName();

        if (pageCourante) {
            dispatch(
                sendEvent({
                    category: EVENT_CATEGORIES.RHN,
                    action: EVENT_ACTIONS.CLIC,
                    label: EVENT_LABELS.ENREGISTRER_ET_QUITTER + pageCourante
                })
            );
        }

        goQuitterParcours();
    };

    const handleCancelOrRefuserOffre = () => {
        setIsModalOpened(true);
    };

    const handleClickModalClose = (shouldStayHere: boolean) => {
        setIsModalOpened(false);

        if (!shouldStayHere) {
            // Send cyber & leave
            leaveApp();
        }
    };

    const handleBack = () => {
        // Send cyber
        dispatch(
            sendEvent({
                category: EVENT_CATEGORIES.RHN,
                action: EVENT_ACTIONS.CLIC,
                label: EVENT_LABELS.PRECEDENT + getPageName()
            })
        );
        // Parcours pour Unique ou Guy
        // frequence modifier nous ammene sur la page ModaliteDatePaiement en route conditionnelle
        if (!isMartine) {
            // Si on a une offre Variable protege -> on retourne a la page offre
            if (isOffreVariableProtege) {
                goToRoute(Routes.OFFRES);
            } else {
                // Si ajout d'assurance -> bascule vers assurance
                // Sinon retour a la route d'avant
                if (isAjoutAssurance) {
                    onClickPrevWithAssurance();
                } else {
                    // si l'offre choisie a eu un changement de frequence par rapport à l'offre choisie
                    // dans la page d'offre (aka: l'offre de base) on revient sur la route conditional
                    const isFrequenceModifiee = !isFrequenceEqual(
                        offreChoisie?.frequenceRemboursement,
                        offreBase?.frequenceRemboursement
                    );

                    // si offreBase est null c'est que le memebre a rebascule directement dans la page
                    // de validation donc l'offre na pas été simulee donc la frequence n'avait pas été changé
                    goToRoute(
                        isFrequenceModifiee && offreBase
                            ? getRoute().prevRouteConditional
                            : getRoute().prevRoute
                    );
                }
            }
        } else {
            // Martine sans ajout d'assurance
            goToRoute(getRoute().prevRoute);
        }
    };

    const refuserOffre = () => {
        onMartineRefuse();
    };

    return (
        <>
            {isMartine ? (
                <NavigationModal
                    isOpen={isModalOpened}
                    modaleTitle={t('modaleTitre')}
                    modaleMessage={
                        admissibleSI ? t('modaleMessageSI') : t('modaleMessage')
                    }
                    modaleAcceptButton={t('modaleAcceptButton')}
                    modaleStayButton={t('modaleStayButton')}
                    onNext={() => {
                        setIsModalOpened(false);
                        refuserOffre();
                    }}
                    onCancel={() => setIsModalOpened(false)}
                />
            ) : (
                <SaveAndLeaveModal
                    isOpen={isModalOpened}
                    onNext={() => handleClickModalClose(false)}
                    onCancel={() => handleClickModalClose(true)}
                />
            )}

            <NavigationPlacement
                renderBackButton={
                    <Button
                        id={'QA_test_navigation_back_button'}
                        variant={ButtonVariant.secondary}
                        dataCy="qa-navigation-button-back"
                        dataTestId={'navigation-back-button'}
                        onClick={handleBack}
                        fullWidth="xs"
                    >
                        {t('back')}
                    </Button>
                }
                renderSaveButton={
                    isMartine ? (
                        <Button
                            id={'QA_test_navigation_reject_button'}
                            variant={ButtonVariant.secondary}
                            dataCy="qa-navigation-button-reject"
                            dataTestId={'navigation-reject-button'}
                            onClick={handleCancelOrRefuserOffre}
                            fullWidth="xs"
                        >
                            {t('refuser')}
                        </Button>
                    ) : (
                        <Button
                            id={'QA_test_navigation_quit_button'}
                            variant={ButtonVariant.secondary}
                            dataCy="qa-navigation-button-quit"
                            dataTestId={'navigation-quit-button'}
                            onClick={handleCancelOrRefuserOffre}
                            fullWidth="xs"
                        >
                            {t('cancel')}
                        </Button>
                    )
                }
                renderNextButton={
                    <Button
                        id={'QA_test_navigation_next_button'}
                        variant={ButtonVariant.primary}
                        dataCy="qa-navigation-button-next"
                        dataTestId={'navigation-next-button'}
                        onClick={onClickNext}
                        fullWidth="xs"
                    >
                        {t('renouveller')}
                    </Button>
                }
            />
        </>
    );
};
