import React, { ReactNode, useRef, useEffect } from 'react';

interface IRadioGroupProps {
    legend?: string;
    description?: string;
    column?: boolean;
    errorMessage?: string;
    isRequired?: boolean;
    isDark?: boolean;
    quietValidate?: boolean;
    dataTestId?: string;
    shouldHideRequiredLabelling?: boolean;
    name: string;
    children: ReactNode;
    validate?: () => void;
}

export const RadioGroup: React.FC<IRadioGroupProps> = ({
    legend,
    column = false,
    description,
    errorMessage,
    name,
    isRequired,
    quietValidate,
    shouldHideRequiredLabelling,
    isDark,
    children,
    validate
}) => {
    const ref = useRef(null);

    useEffect(() => {
        ref.current.addEventListener('validate', validate);
    }, []);

    return (
        // @ts-ignore
        <dsd-radio-group
            ref={ref}
            name={name}
            column={column}
            slot-description={description}
            error={errorMessage}
            dark={isDark}
            required={isRequired}
            hideRequiredLabelling={shouldHideRequiredLabelling}
            quiet-validate={quietValidate}
        >
            <span slot="legend">{legend}</span>
            {children}
            {/* @ts-ignore */}
        </dsd-radio-group>
    );
};
