import { AxiosResponse } from 'axios';
import { useMutation } from 'react-query';

import { SIGNATURE_ABANDONNE_ENDPOINT } from '../../apiEndPoints';
import {
    axiosInstance,
    reactQueryOptions,
    IMutationResult,
    getDynamicHeaders
} from '../../../../api';

interface ISignatureAbandonne {
    idDemande: number;
    idContrat: string;
}

const putSignatureAbandonne = async (
    param: ISignatureAbandonne
): Promise<AxiosResponse<any>> => {
    const endPoint = SIGNATURE_ABANDONNE_ENDPOINT.replace(
        /{idDemande}/g,
        String(param?.idDemande)
    ).replace(/{idCeremonie}/g, param?.idContrat);

    const { data: response } = await axiosInstance.put(
        endPoint,
        {},
        getDynamicHeaders()
    );

    return response;
};

export const useSignatureAbandonneeApi = (): IMutationResult => {
    return useMutation(putSignatureAbandonne, {
        ...reactQueryOptions()
    });
};
