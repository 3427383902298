import React from 'react';
import { useTranslation } from 'react-i18next';

import { Typography, Variant, Article, Modal } from '../../../../styles';

export interface IModalModifierVosChoixProps {
    isOpen?: boolean;
    onPrimaryButtonClick?: () => void;
    onSecondaryButtonClick?: () => void;
}

export const ModalModifierVosChoix: React.FC<IModalModifierVosChoixProps> = ({
    isOpen,
    onPrimaryButtonClick,
    onSecondaryButtonClick
}) => {
    const [t] = useTranslation(['Signature']);

    return (
        <>
            {isOpen && (
                <Modal
                    show={true}
                    title={t('modalModifierVosChoixTitle')}
                    buttonPrimaryLabel={t('modalModifierVosChoixPrimaryButton')}
                    buttonSecondaryLabel={t(
                        'modalModifierVosChoixSecondaryButton'
                    )}
                    onClickPrimaryButton={onPrimaryButtonClick}
                    onClickSecondaryButton={onSecondaryButtonClick}
                >
                    <Typography variant={Variant.p} article={Article.a3}>
                        {t('modalModifierVosChoixDescription')}
                    </Typography>
                </Modal>
            )}
        </>
    );
};
