export default {
    en: {
        boutonFermerModal: 'Close',

        // titre/soustitre
        titre: 'Learn about the types of rates',
        sousTitrePaiement: 'Payments',
        sousTitreTaux: 'Rate',
        sousTitreHistoriqueTaux: 'Rate history',
        sousTitrePourquoi: 'Why choose it',
        sousTitreConversion: 'Conversion during the term',
        sousTitreNotes: 'Notes',

        // Taux Fixe
        titreTauxFixe: 'Closed fixed rate',
        descriptionPaiementTauxFixe: 'Fixed throughout the term.',
        descriptionTauxTauxFixe:
            'Fixed throughout the term. Tied to the length of the term.',
        descriptionHistoriqueTauxFixe:
            'Always higher than variable rates, especially for terms of 2 years or more.',
        descriptionPourquoiTauxFixe_1:
            'No sudden changes in your budget. Guaranteed security.',
        descriptionPourquoiTauxFixe_2:
            'Protection from potential market rate hikes.',
        conversionTauxFixe:
            'Convertible to another type of mortgage with penalty.',

        // Taux variable
        titreTauxVariable: 'Closed variable rate',
        descriptionPaiementTauxVariable:
            'Adjusted up or down with prime rate fluctuations.',
        descriptionTauxTauxVariable:
            'Based on Desjardins prime rate plus or minus a guaranteed spread.',
        descriptionHistoriqueTauxVariable:
            'Lower than fixed-rate mortgage over the long term.',
        descriptionPourquoiTauxVariable_1: 'Lowest rate on the market.',
        descriptionPourquoiTauxVariable_2: 'More flexible budget.',
        descriptionPourquoiTauxVariable_3:
            'To benefit from potential market rate cuts.',
        conversionTauxVariable:
            'Convertible to a fixed-rate mortgage without penalty.',

        // Taux révisable
        titreTauxRevisable: 'Yearly Rate Resetter',
        descriptionPaiementTauxRevisable:
            'Fixed for 12 months, adjusted each year.',
        descriptionTauxTauxRevisable:
            'The rate will stay the same for 12 months and be revised each year.',
        descriptionHistoriqueTauxRevisable:
            'More cost-effective than a 5-year fixed-rate mortgage.',
        descriptionPourquoiTauxRevisable_1:
            'More secure than a variable-rate mortgage',
        descriptionPourquoiTauxRevisable_2:
            'Attractive rate in year 1 of the term',
        conversionTauxRevisable:
            'Convertible to a fixed-rate closed mortgage without penalty.',

        // note
        note_1: 'You can stabilize your payments by setting them higher. Additional amount is applied directly to your principal, acts as a buffer if the prime rate rises and helps you pay off your mortgage faster.',
        retour: 'back'
    },
    fr: {
        boutonFermerModal: 'Fermer',

        // titre/soustitre
        titre: 'Comprendre les types de taux',
        sousTitrePaiement: 'Paiement',
        sousTitreTaux: 'Taux',
        sousTitreHistoriqueTaux: 'Historique des taux',
        sousTitrePourquoi: 'Pourquoi le choisir',
        sousTitreConversion: 'Conversion en cours de terme',
        sousTitreNotes: 'Notes',

        // Taux Fixe
        titreTauxFixe: 'Prêt fermé à taux fixe',
        descriptionPaiementTauxFixe: 'Fixe pendant tout le terme.',
        descriptionTauxTauxFixe:
            'Fixe pendant tout le terme. Proportionnel à la durée du terme.',
        descriptionHistoriqueTauxFixe:
            'Toujours plus élevé que les taux variables, surtout pour les termes de 2 ans et plus.',
        descriptionPourquoiTauxFixe_1:
            'Aucune surprise dans le budget. Sécurité assurée.',
        descriptionPourquoiTauxFixe_2:
            'Protège de possibles hausses de taux du marché.',
        conversionTauxFixe:
            'Le prêt peut être converti en un autre type de prêt, avec frais d’indemnité à payer.',

        // Taux variable
        titreTauxVariable: 'Prêt fermé à taux variable',
        descriptionPaiementTauxVariable:
            'Ajusté à chaque variation du taux préférentiel.',
        descriptionTauxTauxVariable:
            'Basé sur le taux préférentiel Desjardins plus ou moins un écart garanti.',
        descriptionHistoriqueTauxVariable:
            'Plus avantageux que les prêts à taux fixe sur le long terme.',
        descriptionPourquoiTauxVariable_1: 'Plus bas taux sur le marché.',
        descriptionPourquoiTauxVariable_2: 'Budget plus flexible.',
        descriptionPourquoiTauxVariable_3:
            "Pour profiter d'éventuelles baisses de taux du marché.",
        conversionTauxVariable:
            'Le prêt peut être converti en prêt à taux fixe fermé, sans frais d’indemnité.',

        // Taux révisable
        titreTauxRevisable: 'Prêt taux révisable annuellement',
        descriptionPaiementTauxRevisable:
            'Fixe pendant 12 mois, ajusté chaque année.',
        descriptionTauxTauxRevisable:
            'Le taux restera le même pendant 12 mois et sera révisé chaque année.',
        descriptionHistoriqueTauxRevisable:
            'Historiquement, plus économique qu’un prêt à taux fixe 5 ans.',
        descriptionPourquoiTauxRevisable_1:
            'Plus sécuritaire qu’un prêt à taux variable.',
        descriptionPourquoiTauxRevisable_2:
            'Taux avantageux la première année du terme.',
        conversionTauxRevisable:
            'Le prêt peut être converti en prêt à taux fixe fermé, sans frais d’indemnité.',

        // note
        note_1: 'Vous pouvez stabiliser vos paiements en ajoutant un montant supplémentaire à vos versements. Ce montant s’applique directement à votre capital, absorbe, le cas échéant, les hausses éventuelles du taux préférentiel et accélère donc le paiement de votre prêt.',
        retour: 'retour'
    }
};
