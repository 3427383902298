export default {
    en: {
        resumeMontantTitle: 'Payment',
        resumeMontantTitleSuffixAssurance: ' with insurance',
        resumeMontantTooltip:
            'There may be a slight variation between the payment amount listed here and the one on your contract, due to certain circumstances.',
        resumeMontantSubTitle:
            'Amount based on your mortgage balance on {{date, MMMM\xa0D}}, {{date, YYYY}}',
        resumeMontantSubTitleSI: 'As of {{date, MMMM\xa0D}}, {{date, YYYY}}',
        resumeMontantTooltipAltText: 'Information',
        resumeMontant5Dans1Prefix: ' (year 1)',
        resumeMontant5Dans1Sup: '',
        resumeMontant5Dans1Suffix: '',
        // Frequences
        HEBDOMADAIRE: ' weekly',
        AUX_DEUX_SEMAINES: ' every 2 weeks',
        MENSUELLE: ' monthly',
        HEBDOMADAIRE_ACCELEREE: ' weekly',
        AUX_DEUX_SEMAINES_ACCELEREE: ' every 2 weeks'
    },
    fr: {
        resumeMontantTitle: 'Paiement',
        resumeMontantTitleSuffixAssurance: ' avec assurance',
        resumeMontantTooltip:
            'Il pourrait y avoir une légère variation entre le montant du paiement affiché et celui de votre contrat à signer. Certaines circonstances expliquent cette différence.',
        resumeMontantSubTitle:
            'Montant basé sur le solde de votre prêt au {{date, D}}{{suffixe}}\xa0{{date, MMMM\xa0YYYY}}',
        resumeMontantSubTitleSI:
            'À partir du {{date, D}}{{suffixe}}\xa0{{date, MMMM\xa0YYYY}}',
        resumeMontantTooltipAltText: 'Information',
        resumeMontant5Dans1Prefix: ' (1',
        resumeMontant5Dans1Sup: 're',
        resumeMontant5Dans1Suffix: ' année)',
        // Frequences
        HEBDOMADAIRE: ' par semaine',
        AUX_DEUX_SEMAINES: ' aux 2 semaines',
        MENSUELLE: ' par mois',
        HEBDOMADAIRE_ACCELEREE: ' par semaine',
        AUX_DEUX_SEMAINES_ACCELEREE: ' aux 2 semaines'
    }
};
