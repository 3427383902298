import React, { useState } from 'react';
import {
    Box,
    CardMedia,
    createStyles,
    makeStyles,
    useMediaQuery
} from '@material-ui/core';
import { Trans, useTranslation } from 'react-i18next';
import { ProgressBar } from '../../components';
import {
    ContainerMui,
    Grid,
    GridCol,
    GridRow,
    Police,
    Typography,
    Variant
} from '../../styles';
import { Accordions } from './components/accordions/Accordions';
import { EveilAssuranceDescription } from './components/eveilAssuranceDescription/EveilAssuranceDescription';
import { ExempleDeProtection } from './components/exempleDeProtection/ExempleDeProtection';
import { ParcoursAssurance } from '../../hooks';

interface EveilAssuranceProps {
    isAdmissibleAssuranceInvalidite: boolean;
    parcoursAssurance: ParcoursAssurance;
    onAccordionToggle?: (eventLabel: string, isOpen: boolean) => void;
}

const useStyles = makeStyles(() =>
    createStyles({
        imageMobileSmall: {
            borderRadius: '1rem 1rem 0 0',
            height: '100%',
            width: '100%',
            paddingBottom: '75%',
            backgroundSize: '130%',
            backgroundPosition: '80% 0%',
            position: 'relative',
            display: 'flex',
            justifyContent: 'center'
        },
        imageMobileLarge: {
            borderRadius: '1rem 1rem 0 0',
            height: '100%',
            width: '100%',
            paddingBottom: '30%',
            backgroundSize: '100%',
            backgroundPosition: '0% 0%',
            position: 'relative',
            display: 'flex',
            justifyContent: 'center'
        },
        imageDesktop: {
            height: '100%',
            width: '100%',
            position: 'relative',
            backgroundSize: '150%',
            backgroundPosition: '50% 0%',
            display: 'flex',
            justifyContent: 'center'
        },
        imageTablet: {
            height: '100%',
            width: '100%',
            position: 'relative',
            backgroundSize: '170%',
            backgroundPosition: '50% 0%',
            display: 'flex',
            justifyContent: 'center'
        },
        imageBorderNoBottomRoundedCorner: {
            borderRadius: '0 1rem 0rem 0'
        },
        imageBorderBottomRoundedCorner: {
            borderRadius: '0 1rem 1rem 0'
        }
    })
);

export const EveilAssurance: React.FC<EveilAssuranceProps> = ({
    isAdmissibleAssuranceInvalidite,
    parcoursAssurance,
    onAccordionToggle
}) => {
    const [t] = useTranslation(['EveilAssurance']);
    const classes = useStyles();
    const isMobileSmall = useMediaQuery('(max-width:450px)');
    const isMobile = useMediaQuery('(max-width:640px)');
    const isTablet = useMediaQuery('(max-width:990px)');
    const isEcranSmall = useMediaQuery('(max-width:1200px)');
    const isEcranLarge = useMediaQuery('(min-width:1441px)');
    const [isAccordionMargeAtoutOpen, setIsAccordionMargeAtoutOpen] =
        useState(false);
    const [
        isAccordionAssuranceHypothequeOpen,
        setIsAccordionAssuranceHypothequeOpen
    ] = useState(false);

    const getDescriptionPadding = () => {
        const paddingBottom =
            ParcoursAssurance.Parcours1 === parcoursAssurance
                ? '0.5rem'
                : '1.5rem';

        const paddingBottomMobile =
            ParcoursAssurance.Parcours1 === parcoursAssurance
                ? '0rem'
                : '0.75rem';

        if (isMobile) {
            return '1rem 1rem ' + paddingBottomMobile + ' 1rem';
        } else if (isTablet || isEcranSmall) {
            return '1.5rem 0 ' + paddingBottom + ' 1.5rem';
        } else {
            return '1.5rem 0 ' + paddingBottom + ' 1.5rem';
        }
    };

    return (
        <Grid>
            <ProgressBar
                subtitle={
                    ParcoursAssurance.Parcours1 === parcoursAssurance
                        ? t('titleParcours1')
                        : t('titleParcours2et3')
                }
            />
            <Box marginTop={isMobile ? '0' : '1rem'}>
                <ContainerMui hidePadding spacerDistance={6} margin="lg">
                    <GridRow>
                        {/* image pour mobile */}
                        <GridCol col="12" className="sm:dsd-d-none">
                            <CardMedia
                                className={[
                                    isMobileSmall
                                        ? classes.imageMobileSmall
                                        : classes.imageMobileLarge
                                ].join(' ')}
                                image={'/images/page_eveil_mobile_v3.jpg'}
                            />
                        </GridCol>
                    </GridRow>
                    <GridRow>
                        <GridCol col="12" md="6" sm="7">
                            <Box padding={getDescriptionPadding()}>
                                <EveilAssuranceDescription
                                    admissibleAssuranceInvalidite={
                                        isAdmissibleAssuranceInvalidite
                                    }
                                    parcoursAssurance={parcoursAssurance}
                                />
                                <ExempleDeProtection
                                    admissibleAssuranceInvalidite={
                                        isAdmissibleAssuranceInvalidite
                                    }
                                />
                                <Accordions
                                    parcoursAssurance={parcoursAssurance}
                                    isAccordionMargeAtoutOpen={
                                        isAccordionMargeAtoutOpen
                                    }
                                    isAccordionAssuranceHypothequeOpen={
                                        isAccordionAssuranceHypothequeOpen
                                    }
                                    admissibleAssuranceInvalidite={
                                        isAdmissibleAssuranceInvalidite
                                    }
                                    onAccordionAssuranceHypothequeOpenOrClose={(
                                        isOpen: boolean
                                    ) => {
                                        setIsAccordionAssuranceHypothequeOpen(
                                            isOpen
                                        );
                                        onAccordionToggle(
                                            'pourquoi assurer mon pret',
                                            isOpen
                                        );
                                    }}
                                    onAccordionMargeAtoutOpenOrClose={(
                                        isOpen: boolean
                                    ) => {
                                        setIsAccordionMargeAtoutOpen(isOpen);
                                        onAccordionToggle(
                                            'comment fonctionne mat',
                                            isOpen
                                        );
                                    }}
                                />
                            </Box>
                        </GridCol>
                        {/* image pour desktop et tablet  */}
                        <GridCol
                            className="dsd-d-none sm:dsd-d-block"
                            md="6"
                            sm="5"
                        >
                            <Box height="100%">
                                <CardMedia
                                    className={[
                                        isTablet
                                            ? classes.imageTablet
                                            : classes.imageDesktop,
                                        isAccordionMargeAtoutOpen ||
                                        isAccordionAssuranceHypothequeOpen
                                            ? isEcranLarge
                                                ? classes.imageBorderBottomRoundedCorner
                                                : classes.imageBorderNoBottomRoundedCorner
                                            : classes.imageBorderBottomRoundedCorner
                                    ].join(' ')}
                                    image="/images/page_eveil_desktop_v3.jpg"
                                />
                            </Box>
                        </GridCol>
                    </GridRow>
                </ContainerMui>
                <ContainerMui
                    spacerDistance={6}
                    padding={isMobile ? '1rem' : '1.5rem'}
                >
                    <Typography
                        variant={Variant.h3}
                        police={Police.bold}
                        marginBottom={'1rem'}
                    >
                        {t('serviceAccompagnementTitre')}
                    </Typography>
                    <ul style={{ margin: '0' }}>
                        <li
                            style={{ marginLeft: isMobile ? '0.5rem' : '1rem' }}
                        >
                            <Typography variant={Variant.p}>
                                <Trans i18nKey="EveilAssurance:serviceAccompagnementTitreBullet1" />
                            </Typography>
                        </li>
                        <li
                            style={{ marginLeft: isMobile ? '0.5rem' : '1rem' }}
                        >
                            <Typography variant={Variant.p}>
                                <Trans i18nKey="EveilAssurance:serviceAccompagnementTitreBullet2" />
                            </Typography>
                        </li>
                        <li
                            style={{
                                marginBottom: '0',
                                marginLeft: isMobile ? '0.5rem' : '1rem'
                            }}
                        >
                            <Typography variant={Variant.p} hideMarginBottom>
                                <Trans i18nKey="EveilAssurance:serviceAccompagnementTitreBullet3" />
                            </Typography>
                        </li>
                    </ul>
                </ContainerMui>
            </Box>
        </Grid>
    );
};
