import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, useMediaQuery } from '@material-ui/core';

import {
    Grid,
    GridRow,
    GridCol,
    ContainerMui,
    Spacer,
    Button,
    Typography,
    Variant,
    IconPosition,
    ButtonVariant,
    Accordion,
    Article,
    Select,
    Bulle,
    FootnoteRef
} from '../../styles';
import {
    Aiguilleur,
    answersQuestion1,
    answersQuestion2,
    InfoTaux
} from './components';
import {
    IPret,
    getTypeAssurance,
    getTypePret,
    FrequenceDureeTerme,
    PageInitiale,
    TypeOffre
} from '../../hooks';
import {
    ProgressBar,
    ProtectionModal,
    PretActuelModal,
    ClavardageContexte,
    setContexteClavardage
} from '../../components';
import { useDispatch, useSelector } from 'react-redux';
import { setInfoBulleShown } from '../../features';
import { CardList } from './components/cardList/CardList';

export const OFFFER_FILTER_LIST = [
    {
        label: 'TOUT',
        value: 'TOUT'
    },
    {
        label: 'FIXE',
        value: 'FIXE'
    },
    {
        label: 'VARIABLE',
        value: 'VARIABLE'
    },
    {
        label: 'VENTE',
        value: 'VENTE'
    }
];

export const OFFFER_SORT_LIST = [
    {
        label: 'TAUX',
        value: 'TAUX'
    },
    {
        label: 'TERME',
        value: 'TERME'
    },
    {
        label: 'PAIEMENT',
        value: 'PAIEMENT'
    }
];

const OFFER_FILTER_VENTE = [
    TypeOffre.UN_ANS_FIXE_FERME,
    TypeOffre.DEUX_ANS_FIXE_FERME,
    TypeOffre.CINQ_ANS_VARIABLE_REDUIT,
    TypeOffre.CINQ_ANS_VARIABLE_PROTEGE,
    TypeOffre.CINQ_ANS_REVISABLE_FERME
];

interface OffresProps {
    offresListe: Array<any>;
    pretActuel: IPret;
    isAdmissibleAjoutAssurance: boolean;
    isCoemprunteur: boolean;
    onOfferSelected: (
        idDemande: number,
        idOffre: number,
        isOfferVariableProtege: boolean,
        tauxPropose: number
    ) => void;
    onShowInfoTauxModal: () => void;
    onFilterOrSortClic: (value: string) => void;
    onAiguilleurConfirm: () => void;
    defaultFilter: string;
    defaultSort: string;
    pageInitiale: PageInitiale;
}

export const Offres: React.FC<OffresProps> = ({
    offresListe,
    pretActuel,
    isAdmissibleAjoutAssurance,
    isCoemprunteur,
    onOfferSelected,
    onShowInfoTauxModal,
    onFilterOrSortClic,
    onAiguilleurConfirm,
    defaultFilter,
    defaultSort,
    pageInitiale
}) => {
    // Hooks
    const dispatch = useDispatch();
    const [t] = useTranslation(['Offres']);
    const [tPretActuel] = useTranslation(['PretActuelModal']);
    const isEcranLarge = useMediaQuery('(min-width:1200px)');
    const isTablet = useMediaQuery('(max-width:991px)');
    const isMobile = useMediaQuery('(max-width:639px)');

    // Store
    const infoBulleShown = useSelector(
        (state: any) => state.renouvellement.infoBulleShown
    );

    // State
    const [offreFiltrer, setOffreFiltrer] = useState([]);
    const [isInfoTauxOpened, setIsInfoTauxOpened] = useState(false);
    const [isPretActuelOpened, setIsPretActuelOpened] = useState(!isMobile);
    const [isAiguilleurOpened, setIsAiguilleurOpened] = useState(false);
    const [showInfoBulle, setShowInfoBulle] = useState(false);
    const [filterState, setFilterState] = useState(defaultFilter);

    // Const
    const typeAssurance = getTypeAssurance(pretActuel);
    let filterValue = defaultFilter;
    let sortValue = defaultSort;

    const getFilterOptions = () => {
        const listePossible = OFFFER_FILTER_LIST;

        const listeFiltres = [];
        listePossible.forEach((option: any) => {
            // translate
            option.label = t(option.label);
            listeFiltres.push(option);
        });

        return listeFiltres;
    };

    const getSortOptions = () => {
        const listePossible = OFFFER_SORT_LIST;

        const listeSort = [];
        listePossible.forEach((option: any) => {
            // translate
            option.label = t(option.label);
            listeSort.push(option);
        });

        return listeSort;
    };

    const sortOffres = (offers: any[]) => {
        offers.sort(function (a, b) {
            // Si offre grisee
            const aDisabled = a?.isDisabled || !a?.paiement;
            const bDisabled = b?.isDisabled || !b?.paiement;
            // Si la duree est en mois ou annee
            let aDuree = a?.valeurDureeTerme;
            let bDuree = b?.valeurDureeTerme;
            // Si c'est un VP le taux n'est pas le meme
            let aRate = a?.interestRate;
            let bRate = b?.interestRate;
            switch (sortValue) {
                case OFFFER_SORT_LIST[2].value:
                    // Si offre grisee
                    if (aDisabled) {
                        return 1;
                    } else if (bDisabled) {
                        return -1;
                    }
                    // Sinon tri par paiement
                    return a?.paiement - b?.paiement;
                case OFFFER_SORT_LIST[1].value:
                    if (a?.frequenceDureeTerme === FrequenceDureeTerme.MOIS) {
                        aDuree = aDuree / 12;
                    }
                    if (b?.frequenceDureeTerme === FrequenceDureeTerme.MOIS) {
                        bDuree = bDuree / 12;
                    }
                    return aDuree - bDuree;
                default:
                    // Si offre grisee
                    if (aDisabled) {
                        return 1;
                    } else if (bDisabled) {
                        return -1;
                    }
                    // Sinon tri par taux
                    if (a?.showVariableProtegeInterestNote) {
                        aRate = a?.effectiveRate;
                    }
                    if (b?.showVariableProtegeInterestNote) {
                        bRate = b?.effectiveRate;
                    }
                    return aRate - bRate;
            }
        });
        return offers;
    };

    const filtrerAndSortOffres = () => {
        let listeOffresFiltre = [];

        if (filterValue === OFFFER_FILTER_LIST[0].value) {
            listeOffresFiltre = Object.assign([], offresListe);
            // Filtre special pour la vente
        } else if (filterValue === OFFFER_FILTER_LIST[3].value) {
            offresListe.forEach((offre: any) => {
                if (OFFER_FILTER_VENTE.includes(offre.typeOffre)) {
                    listeOffresFiltre.push(offre);
                }
            });
        } else {
            offresListe.forEach((offre: any) => {
                if (offre.typeTauxFilter === filterValue) {
                    listeOffresFiltre.push(offre);
                }
            });
        }

        setOffreFiltrer(sortOffres(listeOffresFiltre));
    };

    const handleShowAiguilleur = () => {
        setShowInfoBulle(false);
        setIsAiguilleurOpened(true);
    };

    const handleAiguilleurClose = () => {
        setIsAiguilleurOpened(false);
    };

    const handleAiguilleurConfirm = (answer1: string, answer2: string) => {
        // Logique pour deduire le filtre a appliquer
        if (answer1 === answersQuestion1[0]) {
            filterValue = OFFFER_FILTER_LIST[3].value;
        } else if (answer2 === answersQuestion2[0]) {
            filterValue = OFFFER_FILTER_LIST[1].value;
        } else if (answer2 === answersQuestion2[1]) {
            filterValue = OFFFER_FILTER_LIST[2].value;
        } else {
            filterValue = OFFFER_FILTER_LIST[0].value;
        }
        setFilterState(filterValue);
        // relancer le filtre
        filtrerAndSortOffres();
        // Fermer l'aiguilleur
        setIsAiguilleurOpened(false);
        onAiguilleurConfirm();
    };

    const handleShowInfoTaux = () => {
        setIsInfoTauxOpened(true);

        onShowInfoTauxModal();
    };

    const handleSelectFilter = (filterSelected: string) => {
        filterValue = filterSelected;
        setFilterState(filterSelected);
        onFilterOrSortClic(filterSelected);
        filtrerAndSortOffres();
    };

    const handleSelectSort = (sortSelected: string) => {
        sortValue = sortSelected;
        onFilterOrSortClic(sortSelected);
        filtrerAndSortOffres();
    };

    const renderFilterAndSortGroups = () => {
        return (
            <Box display={isMobile ? '' : 'flex'} mb={3}>
                <Box>
                    <Select
                        fullWidth={isMobile}
                        identifier="select_offer_filtre"
                        label={t('titreFiltre')}
                        value={filterState}
                        hasNoDefaultOption={true}
                        onChange={handleSelectFilter}
                        options={getFilterOptions()}
                    />
                </Box>
                <Box ml={isMobile ? 0 : 2} mt={isMobile ? 2 : 0}>
                    <Select
                        fullWidth={isMobile}
                        identifier="select_offer_sort"
                        label={t('titreSort')}
                        value={sortValue}
                        hasNoDefaultOption={true}
                        onChange={handleSelectSort}
                        options={getSortOptions()}
                    />
                </Box>
            </Box>
        );
    };

    const renderOffresHeader = () => (
        <>
            <Typography variant={Variant.h2}>{t('title')}</Typography>
            <Typography variant={Variant.p}>{t('description_1')}</Typography>
            <Typography variant={Variant.p}>{t('description_2')}</Typography>
            <Spacer all={2} />
        </>
    );

    const renderCardsTitle = () => {
        return (
            <Box
                pb={1}
                display="flex"
                flexWrap="nowrap"
                data-testid={'titre_pret_offert'}
            >
                <Typography variant={Variant.h4}>{t('subtitle')}</Typography>

                <Box paddingTop="7px" paddingLeft="0.3rem">
                    <FootnoteRef
                        target={'footnote_pret_offert_note_1'}
                        id={'footnote_return_pret_offert_note_1'}
                    >
                        <span aria-label={t('footnoteToolTip')}>
                            <span aria-hidden="true">1</span>
                        </span>
                    </FootnoteRef>
                </Box>
            </Box>
        );
    };

    const renderModals = () => {
        return (
            <>
                <InfoTaux
                    isOpen={isInfoTauxOpened}
                    onClose={() => setIsInfoTauxOpened(false)}
                />
                <Aiguilleur
                    isOpen={isAiguilleurOpened}
                    onClose={handleAiguilleurClose}
                    onSelectFilter={handleAiguilleurConfirm}
                />
            </>
        );
    };

    const renderInfoModalButtons = (showBulle: boolean) => {
        // Mise a jour de l'etape actuelle
        if (showBulle) {
            dispatch(setInfoBulleShown(true));
        }
        const showAiguilleur = pretActuel?.amortissement?.annee >= 5;
        return (
            <>
                <Box
                    display="flex"
                    flexDirection={isTablet ? 'column' : 'row'}
                    flexWrap={isTablet ? 'wrap' : 'nowrap'}
                    marginLeft={isEcranLarge ? '0.25rem' : '0rem'}
                >
                    {showAiguilleur && (
                        <Button
                            variant={ButtonVariant.tertiary}
                            iconName="navigations_contour_modale"
                            iconPosition={IconPosition.start}
                            onClick={handleShowAiguilleur}
                            id="bouton_aiguilleur"
                            dataTestId="bouton_aiguilleur"
                            srOnlySuffix={t('boutonAiguilleurSr')}
                        >
                            <Box textAlign="left">{t('boutonAiguilleur')}</Box>
                        </Button>
                    )}
                    <Box mt={isTablet ? 2 : 0}>
                        <Button
                            variant={ButtonVariant.tertiary}
                            iconName="navigations_contour_modale"
                            iconPosition={IconPosition.start}
                            onClick={handleShowInfoTaux}
                            dataTestId="bouton_info_taux"
                        >
                            <Box textAlign="left">{t('boutonInfoTaux')}</Box>
                        </Button>
                    </Box>
                </Box>
                {/* Bulle d'information */}
                <Bulle
                    dataTestId="bulle_taux_preferes"
                    title={t('bulleTitre')}
                    description={t('bulleDescription')}
                    targetId={'bouton_aiguilleur'}
                    show={showBulle}
                    placement={'top'}
                />
            </>
        );
    };

    const renderPretActuelBloc = () => {
        const pretWithAssurance =
            !isAdmissibleAjoutAssurance && pretActuel?.listeAssures.length > 0;
        return (
            <Accordion
                dataTestId="accordion1"
                open={isPretActuelOpened}
                onOpenOrClose={(isOpen: boolean) => {
                    setIsPretActuelOpened(isOpen);
                }}
            >
                <span slot="title">{t('titreSituationActuelle')}</span>
                <Box>
                    <Typography variant={Variant.h4}>
                        {pretActuel?.offerTitle
                            ? pretActuel?.offerTitle
                            : getTypePret(
                                  pretActuel?.dureeTerme,
                                  pretActuel?.typePret,
                                  tPretActuel
                              )}
                    </Typography>
                    <Spacer />
                    <Typography variant={Variant.h6}>
                        {t('offreActuelleTaux') + ' '}
                        <span
                            style={{
                                color: '#00874e',
                                fontSize: '20px',
                                marginLeft: '0.2rem'
                            }}
                        >
                            {t('Number:percent', {
                                value: pretActuel?.tauxInteret
                            })}
                        </span>
                    </Typography>
                    <Spacer />
                    <Typography variant={Variant.span} article={Article.a3}>
                        {t(
                            pretWithAssurance
                                ? 'offreActuellePaiementAssuTitre'
                                : 'offreActuellePaiementTitre'
                        )}
                    </Typography>
                    <Typography variant={Variant.h6}>
                        <span>
                            {t('Number:currency', {
                                value: pretActuel?.montantRemboursement
                            })}{' '}
                            {tPretActuel(pretActuel?.frequenceRemboursement)}
                        </span>
                    </Typography>
                    <Spacer />
                    <Box style={{ marginLeft: '-1.5rem' }}>
                        <PretActuelModal />

                        {/* Afficher le lien pour la modale seulement si des assures sont present */}
                        {pretWithAssurance && (
                            <>
                                <Spacer />
                                <ProtectionModal />
                            </>
                        )}
                    </Box>
                </Box>
            </Accordion>
        );
    };

    // Ouvre ou ferme l'accordeon si mobile ou non
    useEffect(() => {
        setIsPretActuelOpened(!isMobile);
    }, [isMobile]);

    useEffect(() => {
        if (pretActuel?.amortissement?.annee >= 5) {
            const show =
                infoBulleShown === false &&
                pageInitiale === PageInitiale.ACCUEIL;
            setShowInfoBulle(show);
        }
        filtrerAndSortOffres();
    }, [offresListe]);

    useEffect(() => {
        setContexteClavardage(ClavardageContexte.OFFRES);

        return () => {
            // Réinitialisation du contexte de clavardage
            setContexteClavardage(ClavardageContexte.DEFAULT);
        };
    }, []);

    return (
        <>
            <Grid>
                <ProgressBar />
                {/* Header */}
                <GridRow>
                    <GridCol
                        col="12"
                        sm="12"
                        md="8"
                        dataTestId={'description_page_offre'}
                    >
                        {renderOffresHeader()}
                    </GridCol>
                </GridRow>

                <GridRow>
                    {/* Pret actuel - mobile */}
                    {isTablet && (
                        <GridCol
                            col="12"
                            sm="12"
                            md="12"
                            dataTestId={'page_offre_bloc_pret_actuel'}
                        >
                            <Box mb={2}>{renderPretActuelBloc()}</Box>
                        </GridCol>
                    )}
                    <GridCol
                        col="12"
                        sm="12"
                        md="8"
                        dataTestId={'page_offre_bloc_offres'}
                    >
                        <ContainerMui
                            margin="lg"
                            dataTestId={'offre_container'}
                        >
                            {/* Titre*/}
                            <GridRow>
                                <GridCol col="12" sm="12" md="12">
                                    {renderCardsTitle()}
                                </GridCol>
                            </GridRow>

                            {/* Boutons pour les modals */}
                            <GridRow>
                                {renderInfoModalButtons(showInfoBulle)}
                            </GridRow>
                            <Spacer />

                            {/* Filtre et tri. Seulement si amort >= 5 ans */}
                            {pretActuel?.amortissement?.annee >= 5 && (
                                <GridRow>
                                    <GridCol col="12" sm="12" md="8">
                                        {renderFilterAndSortGroups()}
                                    </GridCol>
                                </GridRow>
                            )}

                            {/* Cards */}
                            <GridRow>
                                <GridCol
                                    col="12"
                                    sm="12"
                                    md="12"
                                    dataTestId={'tuiles_container'}
                                >
                                    <CardList
                                        isCoemprunteur={isCoemprunteur}
                                        typeAssurance={typeAssurance}
                                        cardList={offreFiltrer}
                                        onOfferSelected={onOfferSelected}
                                    />
                                </GridCol>
                            </GridRow>
                        </ContainerMui>
                    </GridCol>
                    {/* Pret actuel - desktop */}
                    {!isTablet && (
                        <GridCol
                            col="12"
                            sm="12"
                            md="4"
                            dataTestId={'page_offre_bloc_pret_actuel'}
                        >
                            {renderPretActuelBloc()}
                        </GridCol>
                    )}
                </GridRow>
            </Grid>
            {renderModals()}
        </>
    );
};
