export default {
    en: {
        resumeAssuranceTitleAP: 'Loan Insurance',
        resumeAssuranceTitleAPMat: 'Loan Insurance – Versatile Line of Credit',

        resumeAssuranceAdmissibleSansChoix: 'No insurance',

        resumeAssuranceVieTitle: 'Life insurance',
        resumeAssuranceVieTexte: ' of mortgage balance',
        resumeAssuranceVieDisabledTexte:
            'No longer eligible. Will be over 70 at renewal',

        resumeAssuranceInvaliditeTitle: 'Disability insurance',
        resumeAssuranceInvaliditeTexte: ' of regular payments',
        resumeAssuranceInvaliditeDisabledTexte:
            'No longer eligible. Will be over 65 at renewal',

        resumeChangeButton: 'Edit',
        comprendreAssuranceButton: 'Learn about insurance costs'
    },
    fr: {
        resumeAssuranceTitleAP: 'Assurance prêt',
        resumeAssuranceTitleAPMat: 'Assurance prêt – marge Atout',

        resumeAssuranceAdmissibleSansChoix: 'Aucune assurance',

        resumeAssuranceVieTitle: 'Assurance vie',
        resumeAssuranceVieTexte: ' du solde du prêt',
        resumeAssuranceVieDisabledTexte:
            'N’est plus admissible car aura dépassé 70 ans au renouvellement',

        resumeAssuranceInvaliditeTitle: 'Assurance invalidité',
        resumeAssuranceInvaliditeTexte: ' des paiements réguliers',
        resumeAssuranceInvaliditeDisabledTexte:
            'N’est plus admissible car aura dépassé 65 ans au renouvellement',

        resumeChangeButton: 'Modifier',
        comprendreAssuranceButton: 'Comprendre le coût de l’assurance'
    }
};
