import React, { ReactNode } from 'react';

interface IGridRowProps {
    children: ReactNode;
}

export const GridRow: React.FC<IGridRowProps> = ({ children }) => (
    //@ts-ignore
    <dsd-grid-row>{children}</dsd-grid-row>
);
