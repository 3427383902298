import React, { useContext } from 'react';

import { ColorDSD } from '../';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { ColorHex } from '../color/Color';
import { HeadingContext } from '../../context';

const useStyles = makeStyles(() =>
    createStyles({
        typographyLink: {
            cursor: 'pointer'
        }
    })
);

export enum Variant {
    h1 = 'h1', // 36px - 2.25rem
    h2 = 'h2', // 32px - 2rem
    h3 = 'h3', // 28px - 1.75rem
    h4 = 'h4', // 24px - 1.5rem
    h5 = 'h5', // 20px - 1.25rem
    h6 = 'h6', // 18px - 1.125rem
    p = 'p', // 20px - 1.25rem
    a = 'a', // 20px - 1.25rem
    span = 'span'
}

export enum Body {
    b1 = 'dsd-b1', // 20px
    b2 = 'dsd-b2', // 16px - 1rem;
    b3 = 'dsd-b3', // 14px
    b4 = 'dsd-b4' // 12px
}

export enum Article {
    a1 = 'dsd-a-1', // 20px - 1.25rem
    a2 = 'dsd-a-2', // 18px - 1.125rem
    a3 = 'dsd-a-3', // 16px - 1rem
    a4 = 'dsd-a-4', // 14px - 0.875rem
    a5 = 'dsd-a-5' // 12px - 0.75rem
}

export enum Police {
    light = 'dsd-fw-light',
    normal = 'dsd-fw-normal',
    semiBold = 'dsd-fw-semibold',
    bold = 'dsd-fw-bold',
    extraBold = 'dsd-fw-extrabold',
    italic = 'dsd-fs-italic'
}

export enum SousTitre {
    st1 = 'dsd-st-1',
    st2 = 'dsd-st-2',
    st3 = 'dsd-st-3'
}

export enum Headings {
    h1 = 'dsd-t-1',
    h2 = 'dsd-t-2',
    h3 = 'dsd-t-3',
    h4 = 'dsd-t-4',
    h5 = 'dsd-t-5',
    h6 = 'dsd-t-6'
}

export const defaultHeadingMapping = {
    h1: Headings.h1,
    h2: Headings.h2,
    h3: Headings.h3,
    h4: Headings.h4,
    h5: Headings.h5,
    h6: Headings.h6
};

enum Helpers {
    hideMarginBottom = 'dsd-mb-0'
}

export interface ITypography {
    variant: Variant;
    color?: ColorDSD | ColorHex;
    police?: Police;
    className?: string;
    children?: React.ReactNode;
    body?: Body;
    article?: Article;
    sousTitre?: SousTitre;
    hideMarginBottom?: boolean;
    paddingRight?: string;
    marginBottom?: string;
    tabIndex?: number;
    role?: string;
    dataTestId?: string;
    accessibilityText?: string;
    onKeyPress?: (key: string) => void;
    onClick?: () => void;
}

export const Typography: React.FC<ITypography> = ({
    variant,
    color,
    police,
    className,
    children,
    article,
    sousTitre,
    hideMarginBottom,
    marginBottom,
    paddingRight,
    tabIndex,
    role,
    dataTestId,
    onKeyPress,
    accessibilityText,
    onClick
}) => {
    const headingMappingContext = useContext(HeadingContext);
    const mappedVariant = {
        ...defaultHeadingMapping,
        ...headingMappingContext
    };

    const classes = useStyles();
    let aLinkClass = '';

    if (variant === Variant.a) {
        aLinkClass = classes.typographyLink;
    }
    const RenderVariant = variant;

    const classNames = [
        className || '',
        article || '',
        sousTitre || '',
        color || '',
        police || '',
        mappedVariant[variant], // overiding dsd classe
        aLinkClass,
        hideMarginBottom ? Helpers.hideMarginBottom : ''
    ]
        .filter((e) => !!e)
        .join(' ');

    return (
        // @ts-ignore
        <RenderVariant
            data-testid={dataTestId}
            tabIndex={tabIndex}
            onKeyPress={(e) => (onKeyPress ? onKeyPress(e.key) : null)}
            className={classNames}
            onClick={onClick}
            role={role}
            style={{
                marginBottom: marginBottom,
                color: color,
                paddingRight: paddingRight
            }}
            aria-label={accessibilityText}
        >
            {children}
        </RenderVariant>
    );
};
