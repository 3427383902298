export default {
    en: {
        resumeModalitesTitle: 'Payment terms',
        resumeModalitesTypeTitle: 'Frequency',
        resumeModalitesAmortissementTitle: 'Remaining amortization',
        resumeModalitesAmortissementJoin: ' and ',
        resumeModalitesInsuranceTitle: 'Payment',
        resumeModalitesInsuranceSubTitle: ' with insurance',
        resumeModalitesPaymentVariableProtegeNote:
            ' (calculated based on the maximum rate)',
        resumeChangeButton: 'Edit',
        resumeModalitesTooltipText:
            'As explained in the “Cost of insurance” section of the Loan Insurance\xa0–\xa0Versatile Line of Credit Booklet, the payment with insurance is not the only factor in your total insurance cost.',
        resumeModalitesTooltipAltText: 'the payment',
        // Frequences
        HEBDOMADAIRE: 'Weekly',
        AUX_DEUX_SEMAINES: 'Every 2 weeks',
        MENSUELLE: 'Monthly',
        HEBDOMADAIRE_ACCELEREE: 'Weekly',
        AUX_DEUX_SEMAINES_ACCELEREE: 'Every 2 weeks'
    },
    fr: {
        resumeModalitesTitle: 'Modalités de paiement',
        resumeModalitesTypeTitle: 'Fréquence',
        resumeModalitesAmortissementTitle: 'Amortissement restant',
        resumeModalitesAmortissementJoin: ' et ',
        resumeModalitesInsuranceTitle: 'Paiement',
        resumeModalitesInsuranceSubTitle: ' avec assurance',
        resumeModalitesPaymentVariableProtegeNote:
            ' (calculé en fonction du taux maximum)',
        resumeChangeButton: 'Modifier',
        resumeModalitesTooltipText:
            'Comme expliqué à la section «\xa0Coût de l’assurance\xa0» de la Brochure de l’Assurance prêt\xa0–\xa0marge Atout, le paiement avec assurance n’est pas le seul élément du coût total de votre assurance.',
        resumeModalitesTooltipAltText: 'le paiement',
        // Frequences
        HEBDOMADAIRE: 'Hebdomadaire',
        AUX_DEUX_SEMAINES: 'Aux 2 semaines',
        MENSUELLE: 'Mensuel',
        HEBDOMADAIRE_ACCELEREE: 'Hebdomadaire',
        AUX_DEUX_SEMAINES_ACCELEREE: 'Aux 2 semaines'
    }
};
