import {
    IMutationResult,
    axiosInstance,
    getDynamicHeaders,
    reactQueryOptions
} from '../../../../api';

import { AxiosResponse } from 'axios';
import { mockFeatureToggle } from '../../../../api/axiosInstance.mock';
import { useAmortissementApiMock } from './useAmortissementApi.mock';
import { useMutation } from 'react-query';

export type AmortissementParams = {
    idDemande: number;
    idOffre: number;
    montant: number;
    frequence: string;
};

const fetch = async ({
    idDemande,
    idOffre,
    montant,
    frequence
}: AmortissementParams): Promise<AxiosResponse<any>> => {
    const endPoint = `/calculScenarioPaiement/demandes/${idDemande}/offres/${idOffre}/scenarios/amortissement?remboursement=${montant}&frequence=${frequence}`;

    // Mock
    if (mockFeatureToggle) {
        useAmortissementApiMock(endPoint, idOffre);
    }

    const { data } = await axiosInstance.get(endPoint, getDynamicHeaders());

    return data;
};

export const useAmortissementApi = (): IMutationResult => {
    return useMutation(fetch, {
        ...reactQueryOptions()
    });
};
