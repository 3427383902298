export default {
    en: {
        compris: 'Compris',
        attention: 'Attention',
        cancel: 'Rester sur la page'
    },
    fr: {
        compris: 'Compris',
        attention: 'Attention',
        cancel: 'Rester sur la page'
    }
};
