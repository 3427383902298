export default {
    en: {
        sommaire: 'Back to AccèsD home',
        title: 'Mortgage renewal',
        annuleeSystemeText:
            'The mortgage renewal offer has been interrupted. Please talk to our specialists.'
    },
    fr: {
        sommaire: 'Retour à l’accueil',
        title: 'Renouvellement hypothécaire',
        annuleeSystemeText:
            "L'offre de renouvellement hypothécaire a été interrompue. Veuillez parlez à nos spécialistes."
    }
};
