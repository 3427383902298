import React, { useEffect } from 'react';
import { Box } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

import {
    Typography,
    Variant,
    Grid,
    GridCol,
    GridRow,
    Hr,
    ColorHex,
    Spacer,
    Police,
    ContainerMui
} from '../../styles';

import {
    InfoAlert,
    InfoEconomie,
    RepartitionDonut,
    PaiementSelect,
    FrequenceSelect,
    DetailsPaiementBar
} from './components';
import { ClavardageContexte, setContexteClavardage } from '../../components';
import { ProgressBar } from './../../components';
import { Backdrop, makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        position: 'absolute',
        color: '#2f2f2f'
    }
}));

interface IModalitesProps {
    paiementData: any;
    frequencesData: any;
    typePret: string;
    coutAssuranceModalDisplayed: boolean;
    showLoadingCalculations: boolean;
}

export const Modalites: React.FC<IModalitesProps> = ({
    paiementData,
    frequencesData,
    typePret,
    coutAssuranceModalDisplayed,
    showLoadingCalculations
}) => {
    const [t] = useTranslation('Modalites');

    const classes = useStyles();

    const RenderHeader = () => (
        <>
            <Box display="flex" justifyContent="space-between">
                <Typography variant={Variant.h5} police={Police.bold}>
                    {t('typePretTitre')}
                </Typography>

                <Typography variant={Variant.h5} police={Police.normal}>
                    {typePret}
                </Typography>
            </Box>
            <Hr backgroundColor={ColorHex.lightSystem2}></Hr>
            <Spacer />
        </>
    );

    useEffect(() => {
        setContexteClavardage(ClavardageContexte.MODALITES);

        return () => {
            // Réinitialisation du contexte de clavardage
            setContexteClavardage(ClavardageContexte.DEFAULT);
        };
    }, []);

    return (
        <>
            <Grid>
                <ProgressBar />
                <GridRow>
                    <GridCol col="12" sm="12" md="8">
                        <Typography
                            variant={Variant.h2}
                            dataTestId={'page_modalites_titre'}
                        >
                            {t('title')}
                        </Typography>
                    </GridCol>
                </GridRow>
            </Grid>
            <Grid>
                <ContainerMui margin="lg">
                    <GridRow>
                        <GridCol
                            col="12"
                            sm="12"
                            md="12"
                            lg="4"
                            dataTestId={'page_modalites_instructions'}
                        >
                            {/* Titre et info section gauche */}
                            <Typography
                                variant={Variant.h4}
                                police={Police.bold}
                            >
                                {t('ajusterPaiementTitre')}
                            </Typography>
                            <Typography variant={Variant.p}>
                                {t('ajusterPaiementTexte')}
                            </Typography>
                        </GridCol>

                        <GridCol col="12" sm="12" md="12" lg="8">
                            <GridRow>
                                <GridCol col="12" sm="12" md="12">
                                    {/* Header type pret */}
                                    <RenderHeader />
                                </GridCol>
                            </GridRow>

                            <Box position={'relative'}>
                                {/* Spinner sur les calculs section droite */}
                                <Backdrop
                                    className={classes.backdrop}
                                    open={showLoadingCalculations}
                                >
                                    <Box display="block" position="absolute">
                                        <Box
                                            display="flex"
                                            flexDirection="row"
                                            alignItems="center"
                                            justifyContent="center"
                                            margin="0 1.625rem"
                                            borderRadius="1rem"
                                            padding="1.225rem"
                                            paddingRight="2.375rem" // compensation for dsd-loading box
                                            bgcolor={ColorHex.lightWhite}
                                            style={{
                                                userSelect: 'none',
                                                gap: '0.625rem'
                                            }}
                                        >
                                            {/* @ts-ignore */}
                                            <dsd-loading size="xs" />
                                            <b>{t('loadingText')}</b>
                                        </Box>
                                    </Box>
                                </Backdrop>

                                {showLoadingCalculations ? (
                                    <Box height={'150px'}></Box>
                                ) : (
                                    <Box>
                                        <GridRow>
                                            <GridCol col="12" sm="12" md="6">
                                                <FrequenceSelect />
                                                <PaiementSelect />
                                            </GridCol>

                                            <GridCol col="12" sm="12" md="6">
                                                {/* Donut! */}
                                                <RepartitionDonut />
                                            </GridCol>
                                        </GridRow>

                                        <GridRow>
                                            <GridCol col="12" sm="12" md="12">
                                                {/* Economie */}
                                                <InfoEconomie />
                                                {/* DetailsPaiementBar */}
                                                <GridRow>
                                                    <GridCol
                                                        col="12"
                                                        sm="12"
                                                        md="12"
                                                    >
                                                        <Box margin="0 -12px 16px -12px">
                                                            <DetailsPaiementBar
                                                                paiementData={
                                                                    paiementData
                                                                }
                                                                frequencesData={
                                                                    frequencesData
                                                                }
                                                                shouldShowCoutAssuranceModal={
                                                                    coutAssuranceModalDisplayed
                                                                }
                                                            />
                                                        </Box>
                                                    </GridCol>
                                                </GridRow>
                                                {/* Information */}
                                                <InfoAlert
                                                    title={t('infoAlertTitle')}
                                                    message={t(
                                                        'infoAlertMessage'
                                                    )}
                                                />
                                            </GridCol>
                                        </GridRow>
                                    </Box>
                                )}
                            </Box>
                        </GridCol>
                    </GridRow>
                </ContainerMui>
            </Grid>
        </>
    );
};
