// URLs de la librairie de clavardage
export const urlLibProd =
    'https://static.desjardins.com/applications/particulier/oel-clavardage-toolkit/oel-clavardage-toolkit.js';
export const urlLibNonProd =
    'https://static.certif.desjardins.com/applications/particulier/oel-clavardage-toolkit/oel-clavardage-toolkit.js';

export enum ClavardageContexte {
    DEFAULT = 'RHN',
    ACCUEIL = "RHN - Page d'accueil avant consentement",
    OFFRES = 'RHN - Offres',
    CHOIX_COEMPRUNTEUR = 'RHN - Coemprunteur - Offre choisie',
    MODALITES = 'RHN - Modalités de paiement',
    INFORMATION_SIGNATURE = 'RHN - Identification',
    VALIDATION = 'RHN - Validation',
    CONFIRMATION = 'RHN - Demande transmise'
}

export function injecterClavardage(isProd: boolean): void {
    // Injection du script de clavardage dans le document si token dispo
    if (isJetonDefined()) {
        const scriptToolkitClavardageElement = document.createElement('script');
        scriptToolkitClavardageElement.type = 'module';
        scriptToolkitClavardageElement.src = isProd
            ? urlLibProd
            : urlLibNonProd;
        document.body.appendChild(scriptToolkitClavardageElement);
        scriptToolkitClavardageElement.onload = function () {
            window.injectOelClavardageTag();
        };
    }
}

export function hideClavardage(): void {
    const isMobile = window.screen.width < 641;
    const clavardageElement = document.getElementById('btnChat');

    if (clavardageElement && isMobile) {
        clavardageElement.style.display = 'none';
    }
}

export function showClavardage(): void {
    const clavardageElement = document.getElementById('btnChat');
    if (clavardageElement) {
        clavardageElement.style.display = 'block';
    }
}

//Permet de verifier que le jeton Auth0 est definie
export function isJetonDefined(): boolean {
    const metaC2tJetonAuth: HTMLMetaElement = document.querySelector(
        'meta[name="c2t_jetonAuth"]'
    );
    return (
        metaC2tJetonAuth !== null &&
        metaC2tJetonAuth?.getAttribute('content')?.length > 0
    );
}

export function setJetonClavardage(jeton: string): void {
    // Ajout du jeton dans la meta c2t_jetonAuth (utilisé par la widget de clavardage)
    const metaC2tJetonAuth: HTMLMetaElement = document.querySelector(
        'meta[name="c2t_jetonAuth"]'
    );
    if (metaC2tJetonAuth !== null) {
        metaC2tJetonAuth.setAttribute('content', jeton);
    }
}

export function setContexteClavardage(contexte: string): void {
    const metaC2tContexte: HTMLMetaElement = document.querySelector(
        'meta[name="c2t_contexte"]'
    );
    if (metaC2tContexte !== null) {
        metaC2tContexte.setAttribute('content', contexte);
    }
}
