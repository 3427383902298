import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box } from '@material-ui/core';

import { IAssuree, TypeFumeur } from '../../hooks';
import { ContexteAppel, getParlerConseillerUrl } from '../../utils';
import {
    IconTooltip,
    Icon,
    Tag,
    Typography,
    Variant,
    Article,
    Police,
    ColorHex,
    ColorDSD,
    Grid,
    GridCol,
    GridRow,
    ContainerMui,
    Link,
    Button,
    ButtonVariant,
    IconPosition,
    Lightbox,
    EnumLinkType,
    BorderRadiusMui
} from '../../styles';

interface ProtectionProps {
    idDemande: number;
    listeAssures: Array<IAssuree>;
    isRenewalSectionDisplayed: boolean;
    onTooltipActive: () => void;
    onOpenModal: () => void;
    onParlerConseiller: () => void;
}

const GAP_SIZE = '6px';

export const ProtectionModal: React.FC<ProtectionProps> = ({
    idDemande,
    listeAssures,
    isRenewalSectionDisplayed,
    onTooltipActive,
    onOpenModal,
    onParlerConseiller
}) => {
    const [isModalOpened, setIsModalOpened] = useState<boolean>(false);
    const [t] = useTranslation(['ProtectionModal']);

    // Quand la modale ouvre, on envoie des analytiques
    const handleOpenModal = () => {
        onOpenModal();
        setIsModalOpened((prevState) => !prevState);
    };

    const getFumeurLocalization = (indicateurFumeur) => {
        return indicateurFumeur === TypeFumeur.OUI ? 'fumeur' : 'nonFumeur';
    };

    const getTooltipFumeurLocalization = (indicateurFumeur) => {
        return indicateurFumeur === TypeFumeur.OUI
            ? 'tooltipFumeur'
            : 'tooltipNonFumeur';
    };

    const renderIcon = (iconName) => (
        <Box
            display={'flex'}
            width="32px"
            height="56px"
            padding="0.4rem 0.5rem 1rem 0;"
            style={{ float: 'left' }}
        >
            <Icon fillCustom={ColorHex.primary} iconName={iconName}></Icon>
        </Box>
    );

    const renderAssuranceVie = (user: IAssuree) => (
        <ContainerMui
            borderRadius={BorderRadiusMui.md}
            backgroundColor={ColorHex.lightGraphite1}
            minHeight="110px"
            dataTestId={'infoprotection_assurance_vie'}
        >
            {renderIcon('produits_contour_assurance_vie')}
            <Typography
                variant={Variant.p}
                article={Article.a3}
                police={Police.bold}
                hideMarginBottom
            >
                {t('assuranceVie')}
            </Typography>
            {user.indicateurEligibiliteAssuranceVie ? (
                <Box display={'flex'} flexWrap="wrap" style={{ gap: GAP_SIZE }}>
                    <Typography
                        variant={Variant.p}
                        article={Article.a3}
                        police={Police.bold}
                        hideMarginBottom
                    >
                        {t('Number:percentNoDecimal', {
                            value: user?.pctCouvertureAssuranceVie
                        })}
                    </Typography>
                    <Typography
                        variant={Variant.p}
                        article={Article.a3}
                        hideMarginBottom
                    >
                        {t('soldePret')}
                    </Typography>
                </Box>
            ) : (
                <Box display={'flex'} flexWrap="wrap" style={{ gap: GAP_SIZE }}>
                    <Typography
                        variant={Variant.p}
                        article={Article.a3}
                        hideMarginBottom
                    >
                        {t('assuranceVieIneligible')}
                    </Typography>
                </Box>
            )}
        </ContainerMui>
    );

    const renderAssuranceInvalidite = (user: IAssuree) => (
        <ContainerMui
            borderRadius={BorderRadiusMui.md}
            backgroundColor={ColorHex.lightGraphite1}
            minHeight="110px"
            dataTestId={'infoprotection_assurance_invalidite'}
        >
            {renderIcon('produits_contour_assurance_invalidite')}

            <Typography
                variant={Variant.p}
                article={Article.a3}
                police={Police.bold}
                hideMarginBottom
            >
                {t('assuranceInvalidite')}
            </Typography>

            {user.indicateurEligibiliteAssuranceInvalidite ? (
                <Box display={'flex'} flexWrap="wrap" style={{ gap: GAP_SIZE }}>
                    <Typography
                        variant={Variant.p}
                        article={Article.a3}
                        police={Police.bold}
                        hideMarginBottom
                    >
                        {t('Number:percentNoDecimal', {
                            value: user?.pctCouvertureAssuranceInvalidite
                        })}
                    </Typography>
                    <Typography
                        variant={Variant.p}
                        article={Article.a3}
                        hideMarginBottom
                    >
                        {t('paiementRegulier')}
                    </Typography>
                </Box>
            ) : (
                <Box display={'flex'} flexWrap="wrap" style={{ gap: GAP_SIZE }}>
                    <Typography
                        variant={Variant.p}
                        article={Article.a3}
                        dataTestId={'text_invalidite_assurance'}
                        hideMarginBottom
                    >
                        {t('assuranceInvaliditeIneligible')}
                    </Typography>
                </Box>
            )}
        </ContainerMui>
    );

    const renderPersonalInfos = (user: IAssuree) => {
        return (
            <ContainerMui
                borderRadius={BorderRadiusMui.sm}
                border={2}
                borderColor={ColorHex.lightSystem1}
            >
                <Box display={'flex'} flexWrap="wrap" style={{ gap: GAP_SIZE }}>
                    <Typography variant={Variant.span} article={Article.a3}>
                        <strong>
                            {user?.prenom.toUpperCase() +
                                ' ' +
                                user?.nom.toUpperCase() +
                                ','}
                        </strong>
                        {` ${user?.age}  ${t('years')}`}
                    </Typography>
                </Box>
                {!!user?.indicateurFumeur && (
                    <Box display="flex" height="30px">
                        <Tag
                            label={t(
                                getFumeurLocalization(user?.indicateurFumeur)
                            )}
                            srOnlyIcon={t(
                                getFumeurLocalization(user?.indicateurFumeur)
                            )}
                        />

                        <Box paddingLeft="0.5rem" paddingTop="3px">
                            <IconTooltip
                                iconName="alertes_contour_information"
                                label={t(
                                    getTooltipFumeurLocalization(
                                        user?.indicateurFumeur
                                    )
                                )}
                                onActive={onTooltipActive}
                            ></IconTooltip>
                        </Box>
                    </Box>
                )}
            </ContainerMui>
        );
    };

    const renderCardsInfo = () => {
        const isEven = (value: number) => Boolean(value % 2 == 0);

        const renderGridCol = (user: IAssuree) => (
            <GridCol col="12" sm="12" md="6" key={'key_' + user?.prenom}>
                {renderPersonalInfos(user)}
                {renderAssuranceVie(user)}
                {renderAssuranceInvalidite(user)}
            </GridCol>
        );

        return (
            <Box>
                <Grid>
                    {listeAssures &&
                        listeAssures.map((user, index) => {
                            const hasPrevious = Boolean(
                                listeAssures[index - 1]
                            );
                            const hasNext = Boolean(listeAssures[index + 1]);
                            const followingIsOnLeft =
                                hasPrevious && isEven(index);
                            const hasFollowingOnRight =
                                hasNext && isEven(index);

                            return (
                                <GridRow key={index}>
                                    {!hasPrevious && renderGridCol(user)}
                                    {followingIsOnLeft && renderGridCol(user)}
                                    {hasFollowingOnRight &&
                                        renderGridCol(listeAssures[index + 1])}
                                </GridRow>
                            );
                        })}
                </Grid>
            </Box>
        );
    };

    const renderSectionRenouvellementAutomatique = () => {
        return (
            <>
                <Typography variant={Variant.h6} color={ColorDSD.primary}>
                    {t('titreSection')}
                </Typography>
                <Typography
                    variant={Variant.p}
                    article={Article.a3}
                    police={Police.bold}
                    hideMarginBottom
                >
                    {t('admissible')}
                </Typography>
                <Typography variant={Variant.p}>
                    {t('admissible_text')}
                </Typography>
                <Typography variant={Variant.p}>
                    {t('insurance_documents_part1')}
                    <strong>{t('insurance_documents_part2_bold')}</strong>
                    {t('insurance_documents_part3')}
                    <strong>{t('insurance_documents_part4_bold')}</strong>
                    {t('insurance_documents_part5')}
                </Typography>
                <Typography variant={Variant.p}>
                    {t('modifierAssurance_part1')}
                    <Link
                        href={getParlerConseillerUrl(
                            ContexteAppel.PROTECTION_ACTUELLE,
                            idDemande
                        )}
                        dataTestId="parler_conseiller_link"
                        type={EnumLinkType.EXTERNAL}
                        onClick={onParlerConseiller}
                        ariaLabel={t('click2call_aria')}
                    >
                        {t('modifierAssuranceLink')}
                    </Link>
                    {t('modifierAssurance_part2')}
                </Typography>
            </>
        );
    };

    return (
        <>
            <Box ml="1rem">
                <Button
                    variant={ButtonVariant.tertiary}
                    iconName="navigations_contour_modale"
                    iconPosition={IconPosition.start}
                    onClick={handleOpenModal}
                    dataTestId="bouton_protection"
                >
                    {t('boutonsProtection')}
                </Button>
            </Box>
            {isModalOpened && (
                <Lightbox
                    show={true}
                    title={t('protection')}
                    buttonPrimaryLabel={t('buttonText')}
                    onClickPrimaryButton={() => setIsModalOpened(false)}
                    onClose={() => setIsModalOpened(false)}
                    dataTestId={'protection_lightbox'}
                >
                    {/* User info cards */}
                    {renderCardsInfo()}
                    {/* Section renouvellement automatique */}
                    {isRenewalSectionDisplayed &&
                        renderSectionRenouvellementAutomatique()}
                </Lightbox>
            )}
        </>
    );
};
