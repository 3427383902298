import React, { useEffect, useRef, useState } from 'react';
import { CustomHeader as Header } from './Header';

import { getRetourUrl } from '../../utils';

import { useDispatch, useSelector } from 'react-redux';
import {
    sendEvent,
    EVENT_CATEGORIES,
    EVENT_ACTIONS,
    EVENT_LABELS,
    setCheckSignature
} from '../../features';
import { getPageName, goQuitterParcours, useSessionApi } from '../../hooks';
import { isSignataireSignee } from '../../views/signature/Signature.utils';

export interface IHeaderConnectedProps {
    langue: string;
    isConfirmationRequired: boolean;
    isStatutCheckRequired: boolean;
}

export const HeaderConnected: React.FC<IHeaderConnectedProps> = ({
    langue,
    isConfirmationRequired,
    isStatutCheckRequired
}) => {
    const dispatch = useDispatch();
    // Redux
    const statutSignature = useSelector(
        (state: any) => state.renouvellement.statutSignature
    );

    // Utiliser pour ne pas appeler session si l'auth est pas terminer
    // car save and leave est appeler dans le header
    const idDemande = useSelector(
        (state: any) => state.renouvellement.idDemande
    );
    const { data: session } = useSessionApi(!!idDemande);

    // Ref
    const confirmationStateRef = useRef(isConfirmationRequired);
    const statutCheckRef = useRef(isStatutCheckRequired);
    const statutSignatureRef = useRef(statutSignature);
    // State
    const [isModalConfirmOpened, setIsModalConfirmOpened] = useState(false);

    // Add listener statut
    useEffect(() => {
        statutSignatureRef.current = statutSignature;
    }, [statutSignature]);

    // Update ref
    useEffect(() => {
        confirmationStateRef.current = isConfirmationRequired;
    }, [isConfirmationRequired]);

    // Update ref
    useEffect(() => {
        statutCheckRef.current = isStatutCheckRequired;
    }, [isStatutCheckRequired]);

    const handleClicModalClose = (shouldStayHere: boolean) => {
        // Close confirmation modal
        setIsModalConfirmOpened(false);
        if (!shouldStayHere) {
            // Send cyber & leave
            sendCyberAndLeave();
        }
    };

    const sendCyberAndLeave = () => {
        // Send cyber
        sendEventReturnAction();
        // Back to AccesD method
        goQuitterParcours();
    };

    const sendEventReturnAction = () => {
        const pageCourante = getPageName();
        // on n'envoit que si le bouton est "enregistrer et quitter"
        if (pageCourante && (isConfirmationRequired || isStatutCheckRequired)) {
            dispatch(
                sendEvent({
                    category: EVENT_CATEGORIES.RHN,
                    action: EVENT_ACTIONS.CLIC,
                    label: EVENT_LABELS.ENREGISTRER_ET_QUITTER + pageCourante
                })
            );
        }
    };

    const handleClicBoutonRetour = () => {
        // Si on est en mode check avec la signature
        if (statutCheckRef.current) {
            if (
                !isSignataireSignee(
                    session?.isMartine,
                    statutSignatureRef.current
                )
            ) {
                // Ask to check statut
                dispatch(setCheckSignature(true));
            } else {
                // Send cyber & leave
                sendCyberAndLeave();
            }
        } else {
            if (confirmationStateRef.current) {
                // Open confirmation modal for some page
                setIsModalConfirmOpened(true);
            } else {
                // Send cyber & leave
                sendCyberAndLeave();
            }
        }
    };

    return (
        <Header
            langue={langue}
            urlRetour={getRetourUrl()}
            shouldConfirmationBeDisplayed={isConfirmationRequired}
            isModalConfirmOpened={isModalConfirmOpened}
            handleClicBoutonRetour={handleClicBoutonRetour}
            handleClicModalClose={handleClicModalClose}
        />
    );
};
