import { mockInstance, withDelay } from '../../../../api/axiosInstance.mock';

import { END_POINT } from './useRefuserOffreApi';
import { dataCoEmprunteur1 as sessionMockData } from '../../contexte/useSessionApi/useSessionApi.mock';

const endPoint = END_POINT.replace(
    /{idDemande}/g,
    String(sessionMockData?.contextePret?.idDemande)
);

export const useRefuserOffreApiMock = (): any => {
    mockInstance.onPut(endPoint).reply(
        withDelay(
            [
                200,
                {
                    pageInitiale: 'ACCUEIL',
                    codeAvis: null
                }
            ],
            200
        )
    );
};
