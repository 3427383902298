import React from 'react';
import { useDispatch } from 'react-redux';

import { PretActuelModal } from './PretActuelModal';
import { useSessionApi, usePretApi, useRenouvellementApi } from '../../hooks';
import {
    sendEvent,
    EVENT_CATEGORIES,
    EVENT_ACTIONS,
    EVENT_LABELS
} from '../../features';

export const PretActuelModalConnected: React.FC<unknown> = () => {
    // hooks
    const dispatch = useDispatch();
    const { data: session } = useSessionApi();
    const { data: renouvellement } = useRenouvellementApi(!!session);

    const idDemande = session?.contextePret?.idDemande;
    const { data: pretActuel } = usePretApi(idDemande, !!renouvellement);

    // Envoie des analitiques quand la modale s'ouvre
    const handleOpenModalAnalytic = () => {
        dispatch(
            sendEvent({
                category: EVENT_CATEGORIES.RHN,
                action: EVENT_ACTIONS.CLIC,
                label: EVENT_LABELS.VOIR_VOTRE_PRET_ACTUEL
            })
        );
    };

    const handleParlerConseillerAnalytic = () => {
        dispatch(
            sendEvent({
                category: EVENT_CATEGORIES.RHN,
                action: EVENT_ACTIONS.CLIC,
                label:
                    EVENT_LABELS.CLICK2CALL_SPECIALISTE +
                    EVENT_LABELS.MODALE_PRET_ACTUEL
            })
        );
    };

    return (
        <PretActuelModal
            dateRappel={pretActuel?.dateRappel}
            solde={pretActuel?.solde}
            amortissementRestant={pretActuel?.amortissement}
            tauxInteret={pretActuel?.tauxInteret}
            paiement={pretActuel?.montantRemboursement}
            dureeTerme={pretActuel?.dureeTerme}
            idDemande={idDemande}
            typePret={pretActuel?.typePret}
            frequenceRemboursement={pretActuel?.frequenceRemboursement}
            onOpenModalAnalytic={handleOpenModalAnalytic}
            onParlerConseillerAnalytic={handleParlerConseillerAnalytic}
        ></PretActuelModal>
    );
};
