import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { StatutEmprunteur } from '../../components';
import { EveilAssurance } from './EveilAssurance';
import { Navigation as NavigationEveilAssurance } from './EveilAssurance.navigation';
import {
    useAssuranceApi,
    useRenouvellementApi,
    useResetOffreChoisieApi,
    useRoutes,
    useSessionApi
} from '../../hooks';
import { Headings } from '../../styles';
import { HeadingProvider } from '../../context';
import {
    showLoading,
    hideLoading,
    sendEvent,
    EVENT_CATEGORIES,
    EVENT_ACTIONS,
    EVENT_LABELS,
    setCodeAvis
} from './../../features';
import { Box, useMediaQuery } from '@material-ui/core';
import { getCybermetrieAttribut2 } from './EveilAssurance.utils';

const headingMapping = {
    h2: Headings.h3,
    h3: Headings.h4,
    h4: Headings.h5,
    h5: Headings.h6
};

export const EveilAssuranceConnected: React.FC<unknown> = () => {
    const { getRoute, goToRoute } = useRoutes();
    const dispatch = useDispatch();
    const isMobile = useMediaQuery('(max-width:640px)');

    // Apis
    const { data: session } = useSessionApi();
    const idDemande = session?.contextePret?.idDemande;
    const { data: renouvellement } = useRenouvellementApi(!!session);

    // Apis Mutation
    const assuranceApiMutation = useAssuranceApi();
    const resetOffreChoisieMutation = useResetOffreChoisieApi();

    // Store
    const isLoading = useSelector((state: any) => state.loading.show);

    // Appel a reset offre choisie
    const handleOnContinuerSansAssuranceClick = () => {
        dispatch(showLoading());

        dispatch(
            sendEvent({
                category: EVENT_CATEGORIES.RHN,
                action: EVENT_ACTIONS.CLIC,
                label: EVENT_LABELS.CONTINUER_SANS_ASSURANCE
            })
        );

        resetOffreChoisieMutation.mutateAsync(idDemande);
    };

    // Appel a basculer vers assurance
    const handleOnBasculerAssuranceClick = () => {
        dispatch(
            sendEvent({
                category: EVENT_CATEGORIES.RHN,
                action: EVENT_ACTIONS.CLIC,
                label: EVENT_LABELS.OBTENIR_ASSURANCE
            })
        );

        assuranceApiMutation.mutateAsync(idDemande);
    };

    const handleAccordionToggle = (titreTiroirs: string, isOpen: boolean) => {
        if (isOpen) {
            dispatch(
                sendEvent({
                    category: EVENT_CATEGORIES.TIROIRS,
                    action: EVENT_ACTIONS.OPEN,
                    label: EVENT_LABELS.TIROIRS + titreTiroirs
                })
            );
        }
    };

    // Retour de l'appel basculer vers assurance -> redirect
    useEffect(() => {
        if (assuranceApiMutation.isSuccess) {
            // Si on recoit un redirectUrl -> Alors on suit l'url ( Assurance ) et pas besoin de faire disparaitre le spinner
            // Sinon on arrete le spinner
            if (assuranceApiMutation.data?.redirectUrl) {
                window.location.href = assuranceApiMutation.data?.redirectUrl;
            } else {
                dispatch(hideLoading());
            }
        }
    }, [assuranceApiMutation.isSuccess]);

    // Retour de l'appel reset offre choisie -> next page
    useEffect(() => {
        if (resetOffreChoisieMutation.isSuccess) {
            if (resetOffreChoisieMutation.data?.codeAvis) {
                // Save code avis in store
                dispatch(setCodeAvis(resetOffreChoisieMutation.data?.codeAvis));
                dispatch(hideLoading());
            } else {
                // Route suivante
                // et pas besoin de cacher le spinner global car la prochaine page va s'en occuper
                goToRoute(getRoute().nextRoute);
            }
        }
    }, [resetOffreChoisieMutation.isSuccess]);

    useEffect(() => {
        dispatch(
            sendEvent({
                category: EVENT_CATEGORIES.FORMULAIRE,
                action: EVENT_ACTIONS.ETAPE_2,
                label: EVENT_LABELS.FRM,
                extraAttribut2: getCybermetrieAttribut2(
                    renouvellement?.parcoursAssurance
                )
            })
        );
        dispatch(hideLoading());
    }, []);

    return (
        !isLoading && (
            <HeadingProvider value={headingMapping}>
                <Box marginBottom={isMobile ? '1rem' : 'auto'}>
                    <StatutEmprunteur />
                    <EveilAssurance
                        isAdmissibleAssuranceInvalidite={
                            renouvellement?.emprunteurConnecte
                                ?.admissibleAssuranceInvalidite
                        }
                        onAccordionToggle={handleAccordionToggle}
                        parcoursAssurance={renouvellement?.parcoursAssurance}
                    />
                    <NavigationEveilAssurance
                        onContinuerSansAssuranceClick={
                            handleOnContinuerSansAssuranceClick
                        }
                        onBasculerAssuranceClick={
                            handleOnBasculerAssuranceClick
                        }
                    />
                </Box>
            </HeadingProvider>
        )
    );
};
