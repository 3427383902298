import { AxiosResponse } from 'axios';
import { useMutation } from 'react-query';

import { CONFIRMER_OFFRE_END_POINT } from '../../apiEndPoints';
import {
    axiosInstance,
    reactQueryOptions,
    IMutationResult,
    getDynamicHeaders
} from '../../../../api';
import { CodeAvisEnumModal } from '../../../../components';

export interface IConfirmerOffreResponse {
    codeAvis: CodeAvisEnumModal;
    redirectUrl: string;
}

export interface IConfirmerOffre {
    idDemande: number;
    isMartine: boolean;
    idOffre: number;
    montantRemboursement: number;
    isOntario: boolean;
}

const putConfirmerOffre = async (
    data: IConfirmerOffre
): Promise<AxiosResponse<IConfirmerOffreResponse>> => {
    const endPoint = CONFIRMER_OFFRE_END_POINT.replace(
        /{idDemande}/g,
        String(data.idDemande)
    ).replace(/{idOffre}/g, String(data.idOffre));

    const { data: response } = await axiosInstance.put(
        endPoint,
        {
            isEmprunteurConsentant: data?.isOntario ? 'true' : 'false',
            statutDemande: data?.isMartine === true ? 'EN_ATTENTE' : 'INITIALE',
            montantRemboursement: String(data.montantRemboursement)
        },
        getDynamicHeaders()
    );

    return response;
};

export const useConfirmerOffreApi = (): IMutationResult => {
    return useMutation(putConfirmerOffre, {
        ...reactQueryOptions()
    });
};
