import { EmprunteurStatut } from '../../api/contexte/useRenouvellementApi/useRenouvellementApi';
import { PageInitiale } from '../../api/contexte/useSessionApi/useSessionApi';
import { ContexteAppel } from './../../../utils/parlerConseiller';

export enum Routes {
    HOME = 'accueil',
    OFFRES = 'offres',
    MODALITES = 'modalites',
    MODALITES_DATE_PAIEMENT = 'modalitesDatePaiement',
    INFORMATIONS_SIGNATURE = 'informationsSignature',
    VALIDATION = 'validation',
    CONFIRMATION = 'confirmation',
    EVEIL_ASSURANCE = 'eveilAssurance',
    ANNULEE_CONSEILLER = 'annuleeConseiller',
    ANNULEE_SYSTEME = 'annuleeSysteme',
    ERREUR = 'erreur',
    LEGAL = 'legal',
    DEEPLINK = 'deeplink',
    INTERET_RETARD = 'interetRetard',
    SIGNATURE = 'signature'
}

// Annuaires selon la page de l'etape et du statut associe.
// Permet en marche arriere d'envoye un changement de statut
// Ne concerne pas les pages erreurs, confirmation ou annulee
export const etapeEtStatutDesPages = {
    [Routes.HOME]: { etape: 0, statut: EmprunteurStatut.ACCUEIL },
    [Routes.OFFRES]: { etape: 1, statut: EmprunteurStatut.OFFRE_AFFICHEE },
    [Routes.EVEIL_ASSURANCE]: {
        etape: 2,
        statut: EmprunteurStatut.EVEIL_ASSURANCE
    },
    [Routes.MODALITES]: { etape: 3, statut: EmprunteurStatut.MODALITES },
    [Routes.MODALITES_DATE_PAIEMENT]: {
        etape: 3,
        statut: EmprunteurStatut.MODALITES
    },
    [Routes.VALIDATION]: { etape: 4, statut: EmprunteurStatut.OFFRE_VALIDEE },
    [Routes.INFORMATIONS_SIGNATURE]: {
        etape: 5,
        statut: EmprunteurStatut.OFFRE_VALIDEE
    }
};

export const nomDePage = {
    [Routes.HOME]: ContexteAppel.PAGE_ACCUEIL,
    [Routes.OFFRES]: ContexteAppel.PAGE_OFFRE,
    [Routes.MODALITES]: 'Page des modalites',
    [Routes.MODALITES_DATE_PAIEMENT]: 'Page du choix date premier paiement',
    [Routes.VALIDATION]: ContexteAppel.PAGE_VALIDATION,
    [Routes.INFORMATIONS_SIGNATURE]: ContexteAppel.PAGE_INFO_SIGNATURE,
    [Routes.CONFIRMATION]: ContexteAppel.PAGE_CONFIRMATION,
    [Routes.EVEIL_ASSURANCE]: ContexteAppel.PAGE_EVEIL_ASSURANCE,
    [Routes.ANNULEE_CONSEILLER]: 'Page annulee conseiller',
    [Routes.ANNULEE_SYSTEME]: 'Page annulee systeme',
    [Routes.ERREUR]: ContexteAppel.PAGE_ERREUR,
    [Routes.LEGAL]: 'Page des notes legales',
    [Routes.SIGNATURE]: 'Page de signature integree'
};

export const routesRequiringStatutCheck = [Routes.SIGNATURE.toString()];

export const routesRequiringLeaveConfirmation = [
    Routes.OFFRES.toString(),
    Routes.MODALITES.toString(),
    Routes.MODALITES_DATE_PAIEMENT.toString(),
    Routes.VALIDATION.toString(),
    Routes.INFORMATIONS_SIGNATURE.toString(),
    Routes.LEGAL.toString(),
    Routes.EVEIL_ASSURANCE.toString(),
    Routes.SIGNATURE.toString()
];

export const routeConfigUniqueOuGuySansModalitesNiAssurance = [
    {
        route: Routes.HOME,
        pageInitiales: [PageInitiale.ACCUEIL],
        nextRoute: Routes.OFFRES,
        nextRouteConditional1: Routes.INTERET_RETARD
    },
    {
        route: Routes.INTERET_RETARD
    },
    {
        route: Routes.OFFRES,
        pageInitiales: [PageInitiale.OFFRE],
        prevRoute: Routes.HOME,
        nextRoute: Routes.VALIDATION
    },
    {
        route: Routes.LEGAL,
        prevRoute: Routes.OFFRES
    },
    {
        route: Routes.VALIDATION,
        pageInitiales: [PageInitiale.RESUME],
        nextRoute: Routes.INFORMATIONS_SIGNATURE,
        prevRoute: Routes.OFFRES
    },
    {
        route: Routes.INFORMATIONS_SIGNATURE,
        pageInitiales: [PageInitiale.FINALISATION],
        nextRoute: Routes.CONFIRMATION,
        prevRoute: Routes.VALIDATION,
        nextRouteConditional1: Routes.SIGNATURE
    },
    {
        route: Routes.SIGNATURE,
        nextRoute: Routes.CONFIRMATION,
        prevRoute: Routes.VALIDATION
    },
    {
        route: Routes.CONFIRMATION,
        pageInitiales: [PageInitiale.CONFIRMATION]
    },
    {
        route: Routes.ANNULEE_CONSEILLER,
        pageInitiales: [PageInitiale.ANNULEE_CONSEILLER]
    },
    {
        route: Routes.ANNULEE_SYSTEME,
        pageInitiales: [PageInitiale.ANNULEE_SYSTEME]
    },
    {
        route: Routes.ERREUR,
        pageInitiales: [PageInitiale.ERREUR]
    }
];

export const routeConfigUniqueOuGuy = [
    {
        route: Routes.HOME,
        pageInitiales: [PageInitiale.ACCUEIL],
        nextRoute: Routes.OFFRES,
        nextRouteConditional1: Routes.INTERET_RETARD
    },
    {
        route: Routes.INTERET_RETARD
    },
    {
        route: Routes.OFFRES,
        pageInitiales: [PageInitiale.OFFRE],
        prevRoute: Routes.HOME,
        nextRoute: Routes.MODALITES,
        nextRouteConditional1: Routes.EVEIL_ASSURANCE,
        nextRouteConditional2: Routes.VALIDATION
    },
    {
        route: Routes.LEGAL,
        prevRoute: Routes.OFFRES
    },
    {
        route: Routes.EVEIL_ASSURANCE,
        pageInitiales: [PageInitiale.EVEIL_ASSURANCE],
        prevRoute: Routes.OFFRES,
        nextRoute: Routes.MODALITES
    },
    {
        route: Routes.MODALITES,
        pageInitiales: [PageInitiale.MODALITES],
        prevRoute: Routes.OFFRES,
        prevRouteConditional: Routes.EVEIL_ASSURANCE,
        nextRoute: Routes.VALIDATION,
        nextRouteConditional1: Routes.MODALITES_DATE_PAIEMENT
    },
    {
        route: Routes.MODALITES_DATE_PAIEMENT,
        nextRoute: Routes.VALIDATION,
        prevRoute: Routes.MODALITES
    },
    {
        route: Routes.VALIDATION,
        pageInitiales: [PageInitiale.RESUME],
        nextRoute: Routes.INFORMATIONS_SIGNATURE,
        prevRoute: Routes.MODALITES,
        prevRouteConditional: Routes.MODALITES_DATE_PAIEMENT
    },
    {
        route: Routes.INFORMATIONS_SIGNATURE,
        pageInitiales: [PageInitiale.FINALISATION],
        nextRoute: Routes.CONFIRMATION,
        prevRoute: Routes.VALIDATION,
        nextRouteConditional1: Routes.SIGNATURE
    },
    {
        route: Routes.SIGNATURE,
        nextRoute: Routes.CONFIRMATION,
        prevRoute: Routes.VALIDATION
    },
    {
        route: Routes.CONFIRMATION,
        pageInitiales: [PageInitiale.CONFIRMATION]
    },
    {
        route: Routes.ANNULEE_CONSEILLER,
        pageInitiales: [PageInitiale.ANNULEE_CONSEILLER]
    },
    {
        route: Routes.ANNULEE_SYSTEME,
        pageInitiales: [PageInitiale.ANNULEE_SYSTEME]
    },
    {
        route: Routes.ERREUR,
        pageInitiales: [PageInitiale.ERREUR]
    }
];

export const routeConfigMartine = [
    {
        route: Routes.HOME,
        pageInitiales: [PageInitiale.ACCUEIL],
        nextRoute: Routes.VALIDATION
    },
    {
        route: Routes.INTERET_RETARD
    },
    {
        route: Routes.LEGAL,
        prevRoute: Routes.VALIDATION
    },
    {
        route: Routes.VALIDATION,
        pageInitiales: [PageInitiale.RESUME],
        nextRoute: Routes.INFORMATIONS_SIGNATURE,
        prevRoute: Routes.HOME,
        // Add mandatory route for Martine too
        prevRouteConditional: Routes.HOME
    },
    {
        route: Routes.INFORMATIONS_SIGNATURE,
        pageInitiales: [PageInitiale.FINALISATION],
        nextRoute: Routes.CONFIRMATION,
        prevRoute: Routes.VALIDATION,
        nextRouteConditional1: Routes.SIGNATURE
    },
    {
        route: Routes.SIGNATURE,
        nextRoute: Routes.CONFIRMATION,
        prevRoute: Routes.INFORMATIONS_SIGNATURE
    },
    {
        route: Routes.CONFIRMATION,
        pageInitiales: [PageInitiale.CONFIRMATION]
    },
    {
        route: Routes.ANNULEE_CONSEILLER,
        pageInitiales: [PageInitiale.ANNULEE_CONSEILLER]
    },
    {
        route: Routes.ANNULEE_SYSTEME,
        pageInitiales: [PageInitiale.ANNULEE_SYSTEME]
    },
    {
        route: Routes.ERREUR,
        pageInitiales: [PageInitiale.ERREUR]
    }
];
