import { SignatureStatutEnum } from '../../hooks';

const LIST_STATUT_GUY_SIGNEE = [
    SignatureStatutEnum.SIGNEE,
    SignatureStatutEnum.TERMINEE,
    SignatureStatutEnum.SIGNATURE_DETECTEE,
    SignatureStatutEnum.SIGNATURE_PARTIELLE
];

const LIST_STATUT_MARTINE_SIGNEE = [
    SignatureStatutEnum.SIGNEE,
    SignatureStatutEnum.TERMINEE
];

const isGuySignee = (statut: SignatureStatutEnum) => {
    return LIST_STATUT_GUY_SIGNEE.includes(statut);
};

const isMartineSignee = (statut: SignatureStatutEnum) => {
    return LIST_STATUT_MARTINE_SIGNEE.includes(statut);
};

export const isSignataireSignee = (
    isMartine: boolean,
    statut: SignatureStatutEnum
): boolean => (isMartine ? isMartineSignee(statut) : isGuySignee(statut));
