import React from 'react';

interface IFootnoteRefProps {
    id: string;
    target: string;
    dark?: boolean;
    children: React.ReactNode;
    ref?: React.RefObject<HTMLInputElement>;
}

export const FootnoteRef: React.FC<IFootnoteRefProps> = ({
    id,
    target,
    dark,
    children,
    ref
}) => (
    <>
        {/*@ts-ignore*/}
        <dsd-footnote-ref target={target} id={id} dark={dark} ref={ref}>
            {children}
            {/*@ts-ignore*/}
        </dsd-footnote-ref>
    </>
);
