import { isMockSelectedSimple, mockInstance, withDelay } from '../../../../api';
import {
    END_POINT,
    FrequenceDureeTerme,
    FrequenceRemboursement,
    IOffres,
    offre,
    SousTypeTaux,
    TypeOffreTarification,
    TypeTaux
} from './useOffreApi';
import { dataCoEmprunteur1 as sessionMockData } from '../../contexte/useSessionApi/useSessionApi.mock';

const offresDeBase: Array<offre> = [
    {
        idOffre: 130065,
        typeTaux: TypeTaux.FIXE,
        sousTypeTaux: SousTypeTaux.FERME,
        frequenceRemboursement:
            FrequenceRemboursement.AUX_DEUX_SEMAINES_ACCELEREE,
        frequenceDureeTerme: FrequenceDureeTerme.ANNEE,
        dateLimiteApprobation: '',
        valeurTauxPreferentiel: null,
        valeurTauxAfficher: 6.74,
        valeurTauxPropose: 6.69,
        valeurDureeTerme: 5.0,
        montantRemboursement: 674.48,
        tauxAdditionnelAssurance: 2.652,
        typeOffreTarification: null,
        dateAcceptationOffre: null,
        tauxRabaisSubsequent: null,
        dateProchainPaiementChangementFrequence: null,
        dateDebutEffetChangementFrequence: null,
        delaiApprobation: null,
        offreAcceptee: false,
        offreSelectionnee: false,
        offreInitiale: false
    },
    {
        idOffre: 130071,
        typeTaux: TypeTaux.REVISABLE,
        sousTypeTaux: SousTypeTaux.FERME,
        frequenceRemboursement: FrequenceRemboursement.MENSUELLE,
        frequenceDureeTerme: FrequenceDureeTerme.ANNEE,
        dateLimiteApprobation: '',
        valeurTauxPreferentiel: null,
        valeurTauxAfficher: 7.79,
        valeurTauxPropose: 7.29,
        valeurDureeTerme: 5.0,
        montantRemboursement: 560.2,
        tauxAdditionnelAssurance: 2.652,
        typeOffreTarification: null,
        dateAcceptationOffre: null,
        tauxRabaisSubsequent: -0.4,
        dateProchainPaiementChangementFrequence: null,
        dateDebutEffetChangementFrequence: null,
        delaiApprobation: null,
        offreAcceptee: false,
        offreSelectionnee: false,
        offreInitiale: true
    },
    {
        idOffre: 130078,
        typeTaux: TypeTaux.VARIABLE,
        sousTypeTaux: SousTypeTaux.PROTEGE,
        frequenceRemboursement: FrequenceRemboursement.MENSUELLE,
        frequenceDureeTerme: FrequenceDureeTerme.ANNEE,
        dateLimiteApprobation: '',
        valeurTauxPreferentiel: 7.2,
        valeurTauxAfficher: 7.54,
        valeurTauxPropose: 7.44,
        valeurTauxEffectif: 7.1,
        valeurTauxPlafond: 7.44,
        valeurDureeTerme: 5.0,
        montantRemboursement: 700.65,
        tauxAdditionnelAssurance: 2.652,
        typeOffreTarification: TypeOffreTarification.PROMOTION,
        dateAcceptationOffre: null,
        tauxRabaisSubsequent: null,
        dateProchainPaiementChangementFrequence: null,
        dateDebutEffetChangementFrequence: null,
        delaiApprobation: null,
        offreAcceptee: false,
        offreSelectionnee: false,
        offreInitiale: false
    },
    {
        idOffre: 130067,
        typeTaux: TypeTaux.FIXE,
        sousTypeTaux: SousTypeTaux.FERME,
        frequenceRemboursement: FrequenceRemboursement.MENSUELLE,
        frequenceDureeTerme: FrequenceDureeTerme.ANNEE,
        dateLimiteApprobation: '',
        valeurTauxPreferentiel: null,
        valeurTauxAfficher: 7.79,
        valeurTauxPropose: 7.24,
        valeurDureeTerme: 1.0,
        montantRemboursement: 688.33,
        tauxAdditionnelAssurance: 2.652,
        typeOffreTarification: null,
        dateAcceptationOffre: null,
        tauxRabaisSubsequent: null,
        dateProchainPaiementChangementFrequence: null,
        dateDebutEffetChangementFrequence: null,
        delaiApprobation: null,
        offreAcceptee: false,
        offreSelectionnee: true,
        offreInitiale: false
    },
    {
        idOffre: 130168,
        typeTaux: TypeTaux.FIXE,
        sousTypeTaux: SousTypeTaux.FERME,
        frequenceRemboursement: FrequenceRemboursement.HEBDOMADAIRE,
        frequenceDureeTerme: FrequenceDureeTerme.ANNEE,
        dateLimiteApprobation: '',
        valeurTauxPreferentiel: null,
        valeurTauxAfficher: 7.34,
        valeurTauxPropose: 6.84,
        valeurDureeTerme: 2.0,
        montantRemboursement: 633.2,
        tauxAdditionnelAssurance: 2.652,
        typeOffreTarification: TypeOffreTarification.NON_SELECTIONABLE,
        dateAcceptationOffre: null,
        tauxRabaisSubsequent: null,
        dateProchainPaiementChangementFrequence: null,
        dateDebutEffetChangementFrequence: null,
        delaiApprobation: null,
        offreAcceptee: false,
        offreSelectionnee: false,
        offreInitiale: false
    },
    {
        idOffre: 130068,
        typeTaux: TypeTaux.FIXE,
        sousTypeTaux: SousTypeTaux.FERME,
        frequenceRemboursement: FrequenceRemboursement.HEBDOMADAIRE,
        frequenceDureeTerme: FrequenceDureeTerme.ANNEE,
        dateLimiteApprobation: '',
        valeurTauxPreferentiel: null,
        valeurTauxAfficher: 6.79,
        valeurTauxPropose: 6.19,
        valeurDureeTerme: 3.0,
        montantRemboursement: 652.09,
        tauxAdditionnelAssurance: 2.652,
        typeOffreTarification: TypeOffreTarification.PROMOTION,
        dateAcceptationOffre: null,
        tauxRabaisSubsequent: null,
        dateProchainPaiementChangementFrequence: null,
        dateDebutEffetChangementFrequence: null,
        delaiApprobation: null,
        offreAcceptee: false,
        offreSelectionnee: false,
        offreInitiale: false
    },
    {
        idOffre: 130269,
        typeTaux: TypeTaux.FIXE,
        sousTypeTaux: SousTypeTaux.FERME,
        frequenceRemboursement: FrequenceRemboursement.HEBDOMADAIRE_ACCELEREE,
        frequenceDureeTerme: FrequenceDureeTerme.ANNEE,
        dateLimiteApprobation: '',
        valeurTauxPreferentiel: null,
        valeurTauxAfficher: 6.59,
        valeurTauxPropose: 6.09,
        valeurDureeTerme: 4.0,
        montantRemboursement: 668.05,
        tauxAdditionnelAssurance: 2.652,
        typeOffreTarification: null,
        dateAcceptationOffre: null,
        tauxRabaisSubsequent: null,
        dateProchainPaiementChangementFrequence: null,
        dateDebutEffetChangementFrequence: null,
        delaiApprobation: null,
        offreAcceptee: false,
        offreSelectionnee: false,
        offreInitiale: false
    },

    {
        idOffre: 130070,
        typeTaux: TypeTaux.VARIABLE,
        sousTypeTaux: SousTypeTaux.REDUIT,
        frequenceRemboursement: FrequenceRemboursement.AUX_DEUX_SEMAINES,
        frequenceDureeTerme: FrequenceDureeTerme.ANNEE,
        dateLimiteApprobation: '',
        valeurTauxPreferentiel: 7.2,
        valeurTauxAfficher: 7.54,
        valeurTauxPropose: 7.44,
        valeurDureeTerme: 5.0,
        montantRemboursement: 567.96,
        tauxAdditionnelAssurance: 2.652,
        typeOffreTarification: null,
        dateAcceptationOffre: null,
        tauxRabaisSubsequent: null,
        dateProchainPaiementChangementFrequence: null,
        dateDebutEffetChangementFrequence: null,
        delaiApprobation: null,
        offreAcceptee: false,
        offreSelectionnee: false,
        offreInitiale: true
    },
    {
        idOffre: 130069,
        typeTaux: TypeTaux.FIXE,
        sousTypeTaux: SousTypeTaux.FERME,
        frequenceRemboursement: FrequenceRemboursement.HEBDOMADAIRE_ACCELEREE,
        frequenceDureeTerme: FrequenceDureeTerme.ANNEE,
        dateLimiteApprobation: '',
        valeurTauxPreferentiel: null,
        valeurTauxAfficher: 7.54,
        valeurTauxPropose: 7.44,
        valeurDureeTerme: 7.0,
        montantRemboursement: 677.71,
        tauxAdditionnelAssurance: 2.652,
        typeOffreTarification: null,
        dateAcceptationOffre: null,
        tauxRabaisSubsequent: null,
        dateProchainPaiementChangementFrequence: null,
        dateDebutEffetChangementFrequence: null,
        delaiApprobation: null,
        offreAcceptee: false,
        offreSelectionnee: false,
        offreInitiale: false
    }
];

const offresGrisees: Array<offre> = [
    {
        idOffre: 130077,
        typeTaux: TypeTaux.VARIABLE,
        sousTypeTaux: SousTypeTaux.PROTEGE,
        frequenceRemboursement: FrequenceRemboursement.MENSUELLE,
        frequenceDureeTerme: FrequenceDureeTerme.ANNEE,
        dateLimiteApprobation: '',
        valeurTauxPreferentiel: null,
        valeurTauxAfficher: null,
        valeurTauxPropose: null,
        valeurDureeTerme: 5,
        valeurTauxEffectif: null,
        valeurTauxPlafond: null,
        montantRemboursement: null,
        tauxAdditionnelAssurance: null,
        typeOffreTarification: TypeOffreTarification.NON_SELECTIONABLE,
        dateAcceptationOffre: null,
        tauxRabaisSubsequent: null,
        dateProchainPaiementChangementFrequence: null,
        dateDebutEffetChangementFrequence: null,
        delaiApprobation: null,
        offreAcceptee: false,
        offreSelectionnee: false,
        offreInitiale: false
    },
    {
        idOffre: null,
        typeTaux: TypeTaux.REVISABLE,
        sousTypeTaux: SousTypeTaux.FERME,
        frequenceRemboursement: FrequenceRemboursement.MENSUELLE,
        frequenceDureeTerme: FrequenceDureeTerme.ANNEE,
        dateLimiteApprobation: '',
        valeurTauxPreferentiel: null,
        valeurTauxAfficher: null,
        valeurTauxPropose: null,
        valeurTauxEffectif: null,
        valeurTauxPlafond: null,
        valeurDureeTerme: 5,
        montantRemboursement: null,
        tauxAdditionnelAssurance: null,
        typeOffreTarification: TypeOffreTarification.NON_SELECTIONABLE,
        dateAcceptationOffre: null,
        tauxRabaisSubsequent: null,
        dateProchainPaiementChangementFrequence: null,
        dateDebutEffetChangementFrequence: null,
        delaiApprobation: null,
        offreAcceptee: false,
        offreSelectionnee: false,
        offreInitiale: false
    }
];

export const offresBasiques: IOffres = {
    dateCalcul: '20210129',
    dateLimite: '20180710',
    indicateurOffreAcceptee: false,
    listeOffre: offresDeBase,
    approbation: {
        delaiApprobation: 6,
        dateLimite: null
    }
};

export const offresCompletes: IOffres = {
    dateCalcul: '20210129',
    dateLimite: '20180710',
    indicateurOffreAcceptee: false,
    listeOffre: offresDeBase.concat(offresGrisees),
    approbation: {
        delaiApprobation: 6,
        dateLimite: null
    }
};

const endPoint = END_POINT.replace(
    /{idDemande}/g,
    String(sessionMockData?.contextePret?.idDemande)
);

// eslint-disable-next-line
const dataErrorTechnique: any = {
    details: [
        {
            message: "API SoumissionAssurance: Test d'erreur",
            numero: '9000',
            type: 'CRITICAL'
        }
    ]
};

// eslint-disable-next-line
const dataErrorFonctionnelle: any = {
    details: [
        {
            message: "API SoumissionAssurance: Test d'erreur",
            type: 'CRITICAL'
        }
    ]
};

export const useOffresApiMock = (): any => {
    mockInstance
        .onGet(endPoint)
        .reply(
            withDelay(
                [
                    200,
                    isMockSelectedSimple() ? offresBasiques : offresCompletes
                ],
                200
            )
        );
};
