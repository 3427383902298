import { AxiosResponse } from 'axios';
import { useMutation } from 'react-query';

import { PRESELECTIONNER_OFFRE_END_POINT } from '../../apiEndPoints';
import {
    axiosInstance,
    getDynamicHeaders,
    IMutationResult,
    reactQueryOptions
} from '../../../../api';

interface ISauvegarderOffre {
    idDemande: number;
    idOffre: number;
    isMartine: boolean;
    tauxPropose: number;
}

const putPreselectionnerOffre = async (
    data: ISauvegarderOffre
): Promise<AxiosResponse<any>> => {
    const endPoint = PRESELECTIONNER_OFFRE_END_POINT.replace(
        /{idDemande}/g,
        String(data.idDemande)
    ).replace(/{idOffre}/g, String(data.idOffre));

    const content = {
        statutDemande: data?.isMartine === true ? 'EN_ATTENTE' : 'INITIALE'
    };

    if (data?.tauxPropose) content['tauxPropose'] = data.tauxPropose;

    const { data: response } = await axiosInstance.put(
        endPoint,
        content,
        getDynamicHeaders()
    );

    return response;
};

export const usePreselectionnerOffreApi = (): IMutationResult => {
    return useMutation(putPreselectionnerOffre, {
        ...reactQueryOptions()
    });
};
