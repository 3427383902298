import React from 'react';
import { AccordionGroup } from '../../../../styles';
import { AccordionAssuranceHypotheque } from '../accordionAssuranceHypotheque/AccordionAssuranceHypotheque';
import { ParcoursAssurance } from '../../../../hooks';
import { AccordionMargeAtout } from '../accordionMargeAtout/AccordionMargeAtout';

interface AccordionsProps {
    parcoursAssurance: ParcoursAssurance;
    isAccordionMargeAtoutOpen: boolean;
    isAccordionAssuranceHypothequeOpen: boolean;
    admissibleAssuranceInvalidite: boolean;
    onAccordionAssuranceHypothequeOpenOrClose: (isOpen: boolean) => void;
    onAccordionMargeAtoutOpenOrClose: (isOpen: boolean) => void;
}

export const Accordions: React.FC<AccordionsProps> = ({
    parcoursAssurance,
    isAccordionMargeAtoutOpen,
    isAccordionAssuranceHypothequeOpen,
    admissibleAssuranceInvalidite,
    onAccordionAssuranceHypothequeOpenOrClose,
    onAccordionMargeAtoutOpenOrClose
}) => {
    const renderAccordionAssuranceHypotheque = () => {
        return (
            <AccordionAssuranceHypotheque
                isAccordionOpen={isAccordionAssuranceHypothequeOpen}
                admissibleAssuranceInvalidite={admissibleAssuranceInvalidite}
                onAccordionOpenOrClose={
                    onAccordionAssuranceHypothequeOpenOrClose
                }
            />
        );
    };

    const renderAccordionParcours1 = () => {
        return (
            <AccordionGroup>
                {renderAccordionAssuranceHypotheque()}
                <AccordionMargeAtout
                    isAccordionOpen={isAccordionMargeAtoutOpen}
                    onAccordionOpenOrClose={onAccordionMargeAtoutOpenOrClose}
                />
            </AccordionGroup>
        );
    };

    const renderAccordionParcours2et3 = () => {
        return renderAccordionAssuranceHypotheque();
    };

    return (
        <>
            {ParcoursAssurance.Parcours1 === parcoursAssurance
                ? renderAccordionParcours1()
                : renderAccordionParcours2et3()}
        </>
    );
};
