import React from 'react';
import { useTranslation } from 'react-i18next';
import {
    Box,
    createStyles,
    makeStyles,
    useMediaQuery
} from '@material-ui/core';

import {
    Grid,
    GridRow,
    GridCol,
    LinkAction,
    LinkActionType,
    LinkActionTheme,
    Typography,
    Variant,
    Police,
    Hr,
    ColorDSD
} from '../../styles';

const useStyles = makeStyles(() =>
    createStyles({
        numberLink: {
            textDecoration: 'underline'
        }
    })
);
export interface IParlerConseillerConnectedProps {
    supportUrl: string;
    isLastComponentOnPage?: boolean;
    onSupportLinkClick?: () => void;
}

export const SupportBar: React.FC<IParlerConseillerConnectedProps> = ({
    supportUrl,
    isLastComponentOnPage = true,
    onSupportLinkClick
}) => {
    const isTablet = useMediaQuery('(max-width:990px)');
    const [t] = useTranslation(['SupportBar']);
    const classes = useStyles();

    return (
        <Box
            mt={1}
            padding={isLastComponentOnPage ? '1rem 0 4rem 0' : '1rem 0 0 0'}
        >
            <Grid>
                <GridRow>
                    <GridCol col="12">
                        <Typography variant={Variant.h2} police={Police.bold}>
                            {t('titre')}
                        </Typography>
                        <Hr height="1px" />
                    </GridCol>
                </GridRow>
                <GridRow>
                    <Box
                        marginBottom={isTablet ? '2rem' : '0'}
                        marginLeft={isTablet ? '0.5rem' : '0.75rem'}
                        marginRight="2rem"
                        marginTop="-0.75rem"
                    >
                        <GridCol col="12" sm="6">
                            <Typography
                                variant={Variant.h3}
                                police={Police.bold}
                            >
                                {t('demanderOnVousAppel')}
                            </Typography>
                            <Typography variant={Variant.p}>
                                {t('demanderOnVousAppelDescription')}
                            </Typography>
                            <LinkAction
                                href={supportUrl}
                                type={LinkActionType.external}
                                theme={LinkActionTheme.green}
                                dataTestId="parler-a-un-conseiller-button"
                                onClick={onSupportLinkClick}
                                ariaLabel={t('click2call_aria')}
                            >
                                {t('demanderOnVousAppelLink')}
                            </LinkAction>
                        </GridCol>
                    </Box>
                    <Box
                        marginTop="-0.75rem"
                        marginLeft={isTablet ? '0.5rem' : '0.75rem'}
                    >
                        <GridCol col="12" sm="6">
                            <Typography
                                variant={Variant.h3}
                                police={Police.bold}
                            >
                                {t('appelerMaintenant')}
                            </Typography>
                            <Typography variant={Variant.p}>
                                {t('appelerMaintenantDescription')}
                            </Typography>
                            <a
                                id="numero_appelez_maintenant"
                                href={'tel:+' + t('numeroTelephone')}
                                aria-label={t('numeroTelephoneAriaLabel')}
                            >
                                <Typography
                                    variant={Variant.h4}
                                    police={Police.bold}
                                    color={ColorDSD.primary}
                                    className={classes.numberLink}
                                >
                                    {t('numeroTelephone')}
                                </Typography>
                            </a>
                        </GridCol>
                    </Box>
                </GridRow>
            </Grid>
        </Box>
    );
};
