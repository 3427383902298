import { configureStore } from '@reduxjs/toolkit';

import renouvellementReducer from './features/renouvellement/renouvellementSlice';
import modalitesReducer from './features/modalites/modalitesSlice';
import codeAvisReducer from './features/codeAvis/codeAvisSlice';
import cybermetrieReducer from './features/cybermetrie/cybermetrieSlice';
import loadingReducer from './features/loading/loadingSlice';

export default configureStore({
    reducer: {
        renouvellement: renouvellementReducer,
        modalites: modalitesReducer,
        codeAvis: codeAvisReducer,
        cybermetrie: cybermetrieReducer,
        loading: loadingReducer
    }
});
