import React, { useEffect, useState } from 'react';
import { DebugBanner } from './DebugBanner';

import { useEnteteApi, IRenouvellement } from '../../hooks';
import { ISession } from '../../hooks/api/contexte/useSessionApi/useSessionApi';
import { EnvConfig } from '../../config';

interface IDebugBannerConnectedProps {
    skipBasculeInfos: boolean;
    envConfig: EnvConfig;
    isAuth0Enabled: boolean;
    isAuthenticated: boolean;
    session: ISession;
    renouvellement: IRenouvellement;
}

export const DebugBannerConnected: React.FC<IDebugBannerConnectedProps> = ({
    skipBasculeInfos,
    envConfig,
    isAuth0Enabled,
    isAuthenticated,
    session,
    renouvellement
}) => {
    const idDemande = skipBasculeInfos
        ? null
        : session?.contextePret?.idDemande;
    const { data: entete } = useEnteteApi(idDemande);

    const [emprunteurName, setEmprunteurName] = useState('');
    const [isVisible, setIsVisible] = useState(skipBasculeInfos);

    const handleCloseDebugBanner = () => {
        setIsVisible(false);
    };

    const descArr =
        session?.contextePret?.descriptionPerso?.split('pdo ') || [];
    const pdo = descArr[descArr.length - 1];

    useEffect(() => {
        // Save in store idOffreChoisie for navigation
        if (session) {
            if (renouvellement?.emprunteurConnecte && entete) {
                setEmprunteurName(
                    renouvellement?.emprunteurConnecte.prenom +
                        ' ' +
                        renouvellement?.emprunteurConnecte.nom
                );
            }
            setIsVisible(true);
        }
    }, [renouvellement, entete, session]);

    return (
        <>
            {isVisible && (
                <>
                    <DebugBanner
                        description={null}
                        typeProduit={null}
                        folio={entete?.folio}
                        numCompte={entete?.numCompte}
                        numeroInstitution={entete?.numeroInstitution}
                        numeroTransit={entete?.numeroTransit}
                        emprunteur={emprunteurName}
                        idDemande={idDemande}
                        pdo={pdo}
                        onClose={handleCloseDebugBanner}
                        isAuth0Enabled={isAuth0Enabled}
                        isAuthenticated={isAuthenticated}
                        isAdmissible={renouvellement?.pretAdmissibleAssurance}
                        isAdmissibleInvalidite={
                            renouvellement?.emprunteurConnecte
                                ?.admissibleAssuranceInvalidite
                        }
                        envConfig={envConfig}
                    />
                </>
            )}
        </>
    );
};
