import { AxiosResponse } from 'axios';
import { QueryClient, UseMutationResult } from 'react-query';

export type IMutationResult = UseMutationResult<
    AxiosResponse<any> | any,
    unknown,
    any,
    unknown
>;

export const queryClient = new QueryClient({
    defaultOptions: {}
});

const reactQueryBaseOptions: any = {
    keepPreviousData: false, // Data will be kept when fetching new data because the query key changed
    retry: false, // Failed queries will not retry
    useErrorBoundary: true, // Errors thrown in the render phase
    refetchOnWindowFocus: false, // Query will not refetch on window focus
    staleTime: 0, // Time (ms) after data is considered stale
    cacheTime: 0 // Time (ms) that unused/inactive cache data remains in memory
};

export const reactQueryOptions: any = () => ({
    ...reactQueryBaseOptions
});

export const reactCachedQueryOptions: any = () => ({
    ...reactQueryBaseOptions,
    staleTime: Infinity, // The data will never be considered stale
    cacheTime: Infinity // Will disable garbage collection
});
