import React from 'react';

type BullePlacement =
    | 'none'
    | 'top'
    | 'top-end'
    | 'top-start'
    | 'right'
    | 'bottom'
    | 'bottom-end'
    | 'bottom-start'
    | 'left';

interface IBulleProps {
    title: string;
    description: string;
    targetId: string;
    placement?: BullePlacement;
    isDark?: boolean;
    show?: boolean;
    showDelayDuration?: number;
    dataTestId?: string;
}

export const Bulle: React.FC<IBulleProps> = ({
    title,
    description,
    targetId,
    placement = 'top',
    isDark = false,
    show = false,
    showDelayDuration = 0,
    dataTestId = ''
}) => {
    return (
        //@ts-ignore
        <dsd-bubble
            id={dataTestId}
            data-testid={dataTestId}
            target-id={targetId}
            placement={placement}
            dark={isDark}
            show={show}
            show-delay-duration={showDelayDuration}
        >
            <span slot="title">{title}</span>
            <span slot="description">{description}</span>
            {/* @ts-ignore */}
        </dsd-bubble>
    );
};
