import {
    EVENT_ACTIONS,
    EVENT_CATEGORIES,
    EVENT_LABELS,
    sendEvent,
    setLoading
} from '../../features';
import {
    ParcoursEmprunteur,
    getYearsOrMonths,
    useEnteteApi,
    useObtenirResumeApi,
    useRenouvellementApi,
    useSessionApi
} from '../../hooks';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Confirmation } from './Confirmation';
import { HeadingProvider } from '../../context';
import { Headings } from '../../styles';
import { Navigation as NavigationConfirmation } from './Confirmation.navigation';
import { getFormattedDate } from '../../utils/dateParser';

const headingMapping = {
    h2: Headings.h3
};

const NUM_INSTITUTION_ONTARIO = '829';

export const ConfirmationConnected: React.FC<unknown> = () => {
    const dispatch = useDispatch();

    // Store
    const isLoading = useSelector((state: any) => state.loading.show);
    const storedIdDemande = useSelector(
        (state: any) => state.renouvellement.idDemande
    );

    // Apis
    const { data: session } = useSessionApi(true, 'invalidate cache');
    const { data: renouvellement } = useRenouvellementApi(!!session);
    const { data: resume, isFetching: isObtenirResumeFetching } =
        useObtenirResumeApi(storedIdDemande, 'Confirmation', !!renouvellement);
    const { data: entete, isFetching: isEnteteFetching } =
        useEnteteApi(storedIdDemande);

    useEffect(() => {
        const loadingFlag = isObtenirResumeFetching || isEnteteFetching;

        dispatch(setLoading(loadingFlag));
    }, [isObtenirResumeFetching, isEnteteFetching]);

    useEffect(() => {
        if (session && resume) {
            // appele dans le cas de SI ou non
            if (
                session?.typeParcoursEmprunteur ===
                    ParcoursEmprunteur.COEMPRUNTEUR_MARTINE ||
                session?.typeParcoursEmprunteur === ParcoursEmprunteur.UNIQUE
            ) {
                dispatch(
                    sendEvent({
                        category: EVENT_CATEGORIES.FORMULAIRE,
                        action: EVENT_ACTIONS.COMPLETE,
                        label: EVENT_LABELS.FRM,
                        eventValue: resume?.offreChoisie?.solde,
                        choixTerme: getYearsOrMonths(
                            resume?.offreChoisie?.valeurDureeTerme,
                            resume?.offreChoisie?.frequenceDureeTerme
                        ),
                        extraAttribut2: String(
                            resume?.offreChoisie?.typeTaux +
                                ' ' +
                                resume?.offreChoisie?.sousTypeTaux
                        ).toLowerCase()
                    })
                );
            } else {
                dispatch(
                    sendEvent({
                        category: EVENT_CATEGORIES.FORMULAIRE,
                        action: EVENT_ACTIONS.ETAPE_6,
                        label: EVENT_LABELS.FRM
                    })
                );
            }
        }
    }, [session, resume]);

    return (
        !isLoading && (
            <HeadingProvider value={headingMapping}>
                {entete && renouvellement && resume && (
                    <Confirmation
                        isOntario={
                            entete?.numeroInstitution ===
                            NUM_INSTITUTION_ONTARIO
                        }
                        delaiApprobation={
                            session?.approbationCoemprunteur.delaiApprobation
                        }
                        dateRappel={
                            new Date(
                                getFormattedDate(
                                    session?.approbationCoemprunteur
                                        .dateRappel || '',
                                    '/'
                                )
                            )
                        }
                        dateLimite={
                            new Date(
                                getFormattedDate(
                                    session?.approbationCoemprunteur
                                        ?.dateLimite || '',
                                    '/'
                                )
                            )
                        }
                        dateAujourdhui={
                            new Date(
                                getFormattedDate(
                                    session?.dateSysteme || '',
                                    '/'
                                )
                            )
                        }
                        typeParcours={session?.typeParcoursEmprunteur}
                        emprunteurs={renouvellement?.emprunteurs}
                        resumeOffre={resume}
                        session={session}
                    />
                )}
                <NavigationConfirmation />
            </HeadingProvider>
        )
    );
};
