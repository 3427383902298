import React from 'react';
import { useTranslation } from 'react-i18next';
import { Box, makeStyles, createStyles } from '@material-ui/core';

import {
    GridRow,
    GridCol,
    Typography,
    Variant,
    Police,
    Button,
    ButtonVariant,
    IconTooltip,
    ColorDSD,
    ColorHex,
    ContainerMui,
    BorderRadiusMui
} from '../../styles';
import {
    IResumeOffre,
    TypeAssurance,
    hasAlreadyAssurance,
    hasSoumissionAssurance,
    isVariableProtege,
    isVariableReduit
} from '../../hooks';
import { PretActuelModal, DetailsOffreModalChoixCoEmprunteur } from '..';

const useStyles = makeStyles(() =>
    createStyles({
        note: {
            fontSize: '0.875rem',
            fontWeight: 'normal'
        }
    })
);

export interface IResumePretProps {
    resumeOffre: IResumeOffre;
    isEditButtonDisplayed?: boolean;
    isTauxAssuranceDisplayed: boolean;
    isSoldeSansAssuranceDisplayed: boolean;
    showButtonPretActualModal?: boolean;
    onModifiedClick: () => void;
    onTooltipActive: () => void;
}

export const ResumePretSimplifie: React.FC<IResumePretProps> = ({
    resumeOffre,
    isEditButtonDisplayed,
    isTauxAssuranceDisplayed,
    showButtonPretActualModal,
    isSoldeSansAssuranceDisplayed,
    onModifiedClick,
    onTooltipActive
}) => {
    const [t] = useTranslation(['ResumePret']);
    const classes = useStyles();

    const { titreOffre, assurance, offreChoisie } = resumeOffre;
    const { codeAssurance, typeAssurance } = assurance;
    const {
        valeurTauxPropose,
        valeurTauxEffectif,
        valeurTauxPlafond,
        valeurTauxPreferentiel,
        tauxRabaisSubsequent,
        tauxAdditionnelAssurance,
        montantRemboursementSansAssurance,
        has5Dans1Note
    } = offreChoisie;

    const hasAssurance = hasAlreadyAssurance(codeAssurance);
    const isSoumissionAssurance = hasSoumissionAssurance(codeAssurance);
    const isOfferVariableProtege = isVariableProtege(resumeOffre?.offreChoisie);
    const isOfferVariableReduit = isVariableReduit(resumeOffre?.offreChoisie);
    const shoudTooltipBeDisplayed =
        isOfferVariableReduit || isOfferVariableProtege;

    const renderInterestRate = () => {
        const interestRateValue =
            isOfferVariableProtege && valeurTauxEffectif
                ? valeurTauxEffectif
                : valeurTauxPropose;

        return t('Number:percent', {
            value: interestRateValue
        });
    };

    const renderInterestTooltip = () => {
        const interetPretTooltipKey = isOfferVariableProtege
            ? 'resumePretTypeVPTooltip'
            : 'resumePretTypeVRTooltip';

        return (
            <Box paddingTop="4px" paddingLeft="0.5rem">
                <IconTooltip
                    iconName="alertes_contour_information"
                    srOnlySuffix={t('resumePretTypeTooltipAltText')}
                    label={t(interetPretTooltipKey)}
                    onActive={onTooltipActive}
                ></IconTooltip>
            </Box>
        );
    };

    return (
        <>
            <GridRow>
                <GridCol col="12">
                    <Typography variant={Variant.p} police={Police.bold}>
                        {t('resumePretTitle')}
                    </Typography>
                    <ContainerMui
                        backgroundColor={ColorHex.lightGrey2}
                        borderRadius={BorderRadiusMui.sm}
                        dataTestId="nouveau_pret"
                        spacerDistance={6}
                        spacerDistanceMobile={6}
                    >
                        <Box ml="1rem" pt="0.5rem">
                            <GridRow>
                                <Typography
                                    variant={Variant.h5}
                                    hideMarginBottom
                                >
                                    {titreOffre}
                                </Typography>
                            </GridRow>
                            <Box mt={'0.75rem'}>
                                <GridRow>
                                    <Typography variant={Variant.h5}>
                                        {t('resumePretInterestTitle')}
                                    </Typography>
                                    {shoudTooltipBeDisplayed &&
                                        renderInterestTooltip()}
                                    <Box ml="0.75rem" mt="-0.1rem">
                                        <Typography
                                            variant={Variant.h4}
                                            color={ColorDSD.primary}
                                        >
                                            {renderInterestRate()}
                                        </Typography>
                                    </Box>

                                    {has5Dans1Note && (
                                        <Box mt="0.25rem" ml="0.5rem">
                                            <span
                                                className={classes.note}
                                                data-testid="text_taux_5dans1"
                                            >
                                                {t(
                                                    'resumePretInterestSubTextPart1'
                                                )}
                                                <sup>
                                                    {t(
                                                        'resumePretInterestSubTextPart2'
                                                    )}
                                                </sup>
                                                {t(
                                                    'resumePretInterestSubTextPart3'
                                                )}
                                                {t('Number:percent', {
                                                    value: Math.abs(
                                                        tauxRabaisSubsequent
                                                    )
                                                })}
                                                {t(
                                                    'resumePretInterestSubTextPart4'
                                                )}
                                            </span>
                                        </Box>
                                    )}
                                    {isOfferVariableProtege &&
                                        valeurTauxPlafond && (
                                            <Box mt="0.25rem" ml="0.5rem">
                                                <span
                                                    className={classes.note}
                                                    data-testid="text_taux_vp"
                                                >
                                                    {t(
                                                        'resumePretTypeVPTextPart1'
                                                    )}
                                                    {t('Number:percent', {
                                                        value: valeurTauxPlafond
                                                    })}
                                                    {t(
                                                        'resumePretTypeVPTextPart2'
                                                    )}
                                                </span>
                                            </Box>
                                        )}
                                    {isOfferVariableReduit &&
                                        valeurTauxPreferentiel && (
                                            <Box mt="0.25rem" ml="0.5rem">
                                                <span
                                                    className={classes.note}
                                                    data-testid="text_taux_reduit"
                                                >
                                                    {t(
                                                        'resumePretTypeVRTextPart1'
                                                    )}
                                                    {t('Number:percent', {
                                                        value: valeurTauxPreferentiel
                                                    })}
                                                    {t(
                                                        'resumePretTypeVRTextPart2'
                                                    )}
                                                </span>
                                            </Box>
                                        )}
                                </GridRow>
                            </Box>
                        </Box>
                    </ContainerMui>
                </GridCol>
                {hasAssurance && isTauxAssuranceDisplayed && (
                    <GridCol col="12" sm="12" md="4">
                        <Typography variant={Variant.p} marginBottom="0.5rem">
                            {t(
                                typeAssurance === TypeAssurance.AP
                                    ? 'resumePretInsuranceTitleAssurance'
                                    : 'resumePretInsuranceTitleAssuranceMargeAtout'
                            )}
                        </Typography>
                        <Box mb={3}>
                            <strong>
                                {t('Number:percent3Decimal', {
                                    value: tauxAdditionnelAssurance
                                })}
                            </strong>
                        </Box>
                    </GridCol>
                )}
                {isSoumissionAssurance && isSoldeSansAssuranceDisplayed && (
                    <GridCol col="12" sm="12" md="4">
                        <Typography variant={Variant.p} marginBottom="0.5rem">
                            {t('resumePretInsuranceTitlePaiementSansAssurance')}
                        </Typography>
                        <Box mb={3}>
                            <strong>
                                {t('Number:currency', {
                                    value: montantRemboursementSansAssurance
                                })}
                            </strong>
                        </Box>
                    </GridCol>
                )}
                {isEditButtonDisplayed && (
                    <GridCol col="12" sm="12" md="12">
                        <Box mb={2}>
                            <Button
                                id={'QA_test_validation_date_modifier'}
                                variant={ButtonVariant.compact}
                                dataCy="qa-button-date-modifier"
                                dataTestId={'bouton_modifier_offre'}
                                onClick={onModifiedClick}
                                srOnlySuffix={
                                    t('resumeChangeButton') +
                                    ' ' +
                                    t('resumePretTypeTitle')
                                }
                            >
                                {t('resumeChangeButton')}
                            </Button>
                        </Box>
                    </GridCol>
                )}
            </GridRow>
            <Box>
                {showButtonPretActualModal && (
                    <GridRow>
                        <GridCol col="12" sm="12" md="4">
                            <DetailsOffreModalChoixCoEmprunteur
                                resumeOffre={resumeOffre}
                            />
                        </GridCol>
                        <GridCol col="12" sm="12" md="4">
                            <PretActuelModal />
                        </GridCol>
                    </GridRow>
                )}
            </Box>
        </>
    );
};
