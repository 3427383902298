import { mockInstance, withDelay } from '../../../../api/axiosInstance.mock';

export const data = {
    idOffre: 130068,
    libelle: '5 ans variable réduit fermé',
    tauxInteret: 2.2,
    frequences: [
        {
            frequenceRemboursement: 'MENSUELLE',
            borneMinimale: 744.79,
            borneMaximale: 1489.58,
            amortissement: {
                annee: 8,
                mois: 0
            },
            economies: {
                montantTotal: Math.random() * 30,
                economieAssurance: {
                    montant: 0,
                    duree: {
                        annee: 5,
                        mois: 0
                    }
                },
                economieInteret: {
                    montant: 150.0,
                    duree: {
                        annee: 5,
                        mois: 0
                    }
                },
                economieCapital: {
                    montant: 0.0,
                    duree: {
                        annee: 5,
                        mois: 0
                    }
                }
            },
            paiementsEtPourcentagesOffreSelectionnee: {
                montantTotalInteret: 5104.7,
                pourcentageInteretSurMontantTotal: 0.11,
                montantTotalAssurancesVie: 0,
                pourcentageAssurancesVieSurMontantTotal: 0.0,
                montantTotalAssurancesInvalidite: 0,
                pourcentageAssurancesInvaliditeSurMontantTotal: 0.0,
                montantTotalCapital: 39582.7,
                pourcentageCapitalSurMontantTotal: 0.89
            },
            paiementsEtPourcentagesOffreActuelle: {
                montantTotalInteret: 5104.7,
                pourcentageInteretSurMontantTotal: 0.11,
                montantTotalAssurancesVie: 0,
                pourcentageAssurancesVieSurMontantTotal: 0.0,
                montantTotalAssurancesInvalidite: 0,
                pourcentageAssurancesInvaliditeSurMontantTotal: 0.0,
                montantTotalCapital: 39582.7,
                pourcentageCapitalSurMontantTotal: 0.89
            }
        }
    ]
};

export const useAmortissementApiMock = (
    endPoint: string,
    idOffre: number
): void => {
    const dataToReturn = {
        ...data,
        idOffre: idOffre
    };

    mockInstance.onGet(endPoint).reply(withDelay([200, dataToReturn], 200));
};
