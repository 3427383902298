import { ISignatureStatut, SignatureStatutEnum } from './useSignatureStatutApi';
import {
    mockInstance,
    mockInstanceNoError,
    withDelay
} from '../../../../api/axiosInstance.mock';

import { SIGNATURE_STATUT_ENDPOINT } from '../../apiEndPoints';
import { dataCoEmprunteur1 as sessionMockData } from '../../contexte/useSessionApi/useSessionApi.mock';
import { data as signatureMockData } from '../useSignatureApi/useSignatureApi.mock';

export const dataInterval: ISignatureStatut = {
    statut: SignatureStatutEnum.NOUVEAU
};

export const dataClickBouton: ISignatureStatut = {
    statut: SignatureStatutEnum.SIGNEE
};

const endPointIsFetchingApiFalse =
    SIGNATURE_STATUT_ENDPOINT.replace(
        /{idDemande}/g,
        String(sessionMockData?.contextePret?.idDemande)
    ).replace(/{idContrat}/g, signatureMockData?.idContrat) +
    '?isFetchingAPI=false';

const endPointIsFetchingApiTrue =
    SIGNATURE_STATUT_ENDPOINT.replace(
        /{idDemande}/g,
        String(sessionMockData?.contextePret?.idDemande)
    ).replace(/{idContrat}/g, signatureMockData?.idContrat) +
    '?isFetchingAPI=true';

export const useSignatureStatutApiMock = (): any => {
    //pooling 5 fois sur 6
    mockInstanceNoError
        .onGet(endPointIsFetchingApiFalse)
        .reply(withDelay([200, dataInterval], 200));

    //pooling 1 fois sur 6
    mockInstanceNoError
        .onGet(endPointIsFetchingApiTrue)
        .reply(withDelay([200, dataClickBouton], 1000));

    //appel click button
    mockInstance
        .onGet(endPointIsFetchingApiTrue)
        .reply(withDelay([200, dataClickBouton], 1000));
};
