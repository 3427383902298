import { mockInstance, withDelay } from '../../../../api/axiosInstance.mock';

import { CODE_AVIS_END_POINT } from '../../apiEndPoints';
import { dataCoEmprunteur1 as sessionMockData } from '../../contexte/useSessionApi/useSessionApi.mock';

const endPoint = CODE_AVIS_END_POINT.replace(
    /{idDemande}/g,
    String(sessionMockData?.contextePret?.idDemande)
);

export const useCodeAvisApiMock = (): any => {
    const url = new RegExp(`${endPoint}`);

    mockInstance.onGet(url).reply(
        withDelay(
            [
                200,
                {
                    codeAvis: null
                }
            ],
            200
        )
    );
};
