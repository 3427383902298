export default {
    en: {
        applicationTitle: 'Mortgage renewal',
        title: 'Select the mortgage to renew',
        tuilePretSurtitrePrefix: 'Mortgage ',
        tuilePretSurtitreSuffix: '-LN',
        tuilePretSurtitreLabel: 'Renewal deadline',
        tuilePretSurtitreDescription: 'Balance: ',
        tuilePretTagInProgress: 'In progress',
        tuilePretTagDone: 'Application received',
        tuilePretSRPrefix: 'Select mortgage ',
        tuilePretSRSuffix: ' to renew by '
    },
    fr: {
        applicationTitle: 'Renouvellement hypothécaire',
        title: 'Choisir le prêt à renouveler',
        tuilePretSurtitrePrefix: 'Prêt ',
        tuilePretSurtitreSuffix: '-PR',
        tuilePretSurtitreLabel: 'Date limite de renouvellement',
        tuilePretSurtitreDescription: 'Solde : ',
        tuilePretTagInProgress: 'En cours',
        tuilePretTagDone: 'Demande reçue',
        tuilePretSRPrefix: 'Choisir le prêt ',
        tuilePretSRSuffix: ' à renouveler avant le '
    }
};
