import { useQuery, UseQueryResult } from 'react-query';

import {
    axiosInstance,
    getDynamicHeaders,
    reactQueryOptions
} from '../../../../api';
import { DEMANDE_END_POINT } from '../../apiEndPoints';
import {
    IAssureResume,
    ISoldeFinTerme,
    FrequenceDureeTerme,
    FrequenceRemboursement,
    SousTypeTaux,
    TypeOffreTarification,
    TypeTaux,
    getOfferTitle,
    TypeOffre
} from '../../../index';
import {
    getTypeOffre,
    TypeOffreParams
} from '../useOffreApi/Offres.transformer';

export enum ModeAuthentification {
    CODE_SMS = 'S',
    QUESTIONS = 'Q'
}

export enum CodeAssurance {
    VIE = 'V',
    VIE_INVALIDITE = 'I',
    SOUMISSION_ASSURANCE = 'S',
    AUCUNE = 'A',
    RETRAIT = 'R'
}

export type Assurance = {
    typeAssurance: string;
    indicateurReductionTauxNonFumeur: boolean;
    codeAssurance: CodeAssurance;
    // Utilise lorsqu'un pret a deja de l'assurance
    listeAssure?: Array<IAssureResume>;
};

export enum CodeRemboursement {
    FERME = 'FERME',
    OUVERT = 'OUVERT'
}

export type PretRessource = {
    annee: number;
    mois: number;
    codeRemboursement: CodeRemboursement;
    description: string;
};

export type Amortissement = {
    annee: number;
    mois: number;
};

export type OffreChoisie = {
    idOffre: number;
    amortissement: Amortissement;
    dateAcceptationOffre: string;
    dateCalcul: string;
    dateDebutEffetChangementFrequence: string;
    dateLimite: string;
    dateLimiteApprobation: string;
    dateCibleRenouvellement: string;
    dateProchainPaiementChangementFrequence: string;
    delaiApprobation: number;
    frequenceRemboursement: FrequenceRemboursement;
    indicateurFinDeTerme: boolean;
    montantRemboursement: number;
    montantRemboursementSansAssurance: number;
    solde: number;
    sousTypeTaux: SousTypeTaux;
    tauxAdditionnelAssurance: number;
    tauxRabaisSubsequent: number;
    typeOffreTarification: TypeOffreTarification;
    typeTaux: TypeTaux;
    valeurDureeTerme: number;
    valeurTauxAfficher: number;
    valeurTauxPreferentiel: number;
    valeurTauxPropose: number;
    // optional properties
    frequenceDureeTerme?: FrequenceDureeTerme;
    soldeFinDeTerme?: ISoldeFinTerme;
    valeurTauxEffectif?: number;
    valeurTauxPlafond?: number;
    has5Dans1Note?: boolean;
};

export interface IResumeOffre {
    assurance: Assurance;
    // optional properties
    offreChoisie?: OffreChoisie;
    offreBase?: OffreChoisie;
    dateProchainPaiement?: string;
    isAdmissibleSignatureIntegree?: boolean;
    // Infos ajoute post-reception
    titreOffre?: string;
    typeOffre?: TypeOffre;
}

export const END_POINT = DEMANDE_END_POINT + '/resume';

const fetch = async (idDemande: number) => {
    const endPoint = END_POINT.replace(/{idDemande}/g, String(idDemande));

    const { data } = await axiosInstance.get(endPoint, getDynamicHeaders());

    data.titreOffre = getOfferTitle(
        data?.offreChoisie?.typeTaux,
        data?.offreChoisie?.sousTypeTaux,
        data?.offreChoisie?.valeurDureeTerme,
        data?.offreChoisie?.frequenceDureeTerme
    );

    const offreParams: TypeOffreParams = {
        sousTypeTaux: data?.offreChoisie?.sousTypeTaux,
        typeTaux: data?.offreChoisie?.typeTaux,
        valeurDureeTerme: data?.offreChoisie?.valeurDureeTerme,
        frequenceDureeTerme: data?.offreChoisie?.frequenceDureeTerme
    };

    data.typeOffre = getTypeOffre(offreParams);

    if (data?.offreChoisie) {
        data.offreChoisie.has5Dans1Note =
            TypeTaux.REVISABLE === data?.offreChoisie?.typeTaux;
    }

    return data;
};

export function useObtenirResumeApi(
    idDemande: number = undefined,
    page: string = Date.now().toString(),
    isEnabled = true
): UseQueryResult<IResumeOffre, Error> {
    return useQuery<IResumeOffre, Error>(
        ['resume', idDemande, page],
        () => fetch(idDemande),
        {
            ...reactQueryOptions(),
            enabled: !!isEnabled
        }
    );
}
