import React from 'react';
import { useTranslation } from 'react-i18next';

import {
    ContainerMui,
    ColorHex,
    IconTooltip,
    ColorDSD,
    Typography,
    Variant,
    Article,
    BorderRadiusMui
} from '../../../../styles';

import { PaiementButtonConnected } from './paiementButton/PaiementButton.connected';
import { PaiementInputConnected } from './paiementInput/PaiementInput.connected';
import { Box, useMediaQuery } from '@material-ui/core';

export interface IPaiementSelectProps {
    onTooltipActive: () => void;
}

export const PaiementSelect: React.FC<IPaiementSelectProps> = ({
    onTooltipActive
}) => {
    const [t] = useTranslation('PaiementSelect');
    const isEcranMedium = useMediaQuery('(max-width:1440px)');

    return (
        <ContainerMui
            height={isEcranMedium ? '250px' : '230px'}
            borderRadius={BorderRadiusMui.sm}
            border={2}
            borderColor={ColorHex.lightSystem1}
        >
            <Box height="150px">
                <Box display="flex" flexWrap="nowrap">
                    <Typography
                        variant={Variant.p}
                        article={Article.a3}
                        color={ColorDSD.lightSystem3}
                        hideMarginBottom
                    >
                        {t('paiement')}
                    </Typography>
                    <Box
                        paddingTop="4px"
                        paddingLeft="0.5rem"
                        height="40px"
                        data-testid={'info_bulle_paiement'}
                    >
                        <IconTooltip
                            iconName="alertes_contour_information"
                            label={
                                <>
                                    <div>{t('tooltip1')}</div>
                                    <br></br>
                                    <div>{t('tooltip2')}</div>
                                    <br></br>
                                    <div>{t('tooltip3')}</div>
                                    <br></br>
                                    <div>{t('tooltip4')}</div>
                                </>
                            }
                            onActive={onTooltipActive}
                        />
                    </Box>
                </Box>
                <PaiementInputConnected />
            </Box>
            <PaiementButtonConnected />
        </ContainerMui>
    );
};
