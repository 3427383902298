import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { Headings } from '../../styles';
import { OFFFER_FILTER_LIST, OFFFER_SORT_LIST, Offres } from './Offres';
import {
    showLoading,
    hideLoading,
    setLoading,
    resetModalites,
    setCodeAvis,
    setMontantSelectionnee,
    sendEvent,
    EVENT_CATEGORIES,
    EVENT_ACTIONS,
    EVENT_LABELS
} from '../../features';
import { Navigation as NavigationOffres } from './Offres.navigation';
import { useRoutes } from '../../hooks/navigation/useRoutes/useRoutes';
import {
    useOffresApi,
    useSessionApi,
    usePretApi,
    usePreselectionnerOffreApi,
    useRenouvellementApi
} from '../../hooks';
import { StatutEmprunteur } from '../../components';
import { HeadingProvider } from '../../context';
import { useTranslation } from 'react-i18next';

const headingMapping = {
    h2: Headings.h3
};

export const OffresConnected: React.FC<any> = () => {
    const { getRoute, goToRoute } = useRoutes();
    const dispatch = useDispatch();
    const [t] = useTranslation(['Offres']);

    // Apis
    const { data: session } = useSessionApi();
    const { data: renouvellement, isFetching: isRenouvellementFetching } =
        useRenouvellementApi(!!session);

    const idDemande = session?.contextePret?.idDemande;
    const { data: offres, isFetching: isOffresFetching } = useOffresApi(
        idDemande,
        !!renouvellement
    );
    const { data: pret, isFetching: isPretFetching } = usePretApi(
        idDemande,
        !!renouvellement
    );

    // Apis Mutation
    const preselectionnerMutation = usePreselectionnerOffreApi();

    // State
    const [isOfferVariableProtege, setIsOfferVariableProtege] = useState(false);

    // Store
    const isLoading = useSelector((state: any) => state.loading.show);

    // Handlers
    const handleOfferSelected = (
        idDemande: number,
        idOffre: number,
        isOfferVariableProtege: boolean,
        tauxPropose: number
    ) => {
        const id = idOffre;
        const offreChoisie = offres.listeOffre.find(
            ({ idOffre }) => idOffre === id
        );

        // on reset les offres (si il y a eu une offre modifiee)
        dispatch(resetModalites());
        dispatch(showLoading());
        // sauvegarde du taux
        dispatch(setMontantSelectionnee(offreChoisie?.montantRemboursement));

        setIsOfferVariableProtege(isOfferVariableProtege);

        // Preselectionner une offre pour aller a Eveil ou Modalites ou Info signature si offre VP ou prestation
        preselectionnerMutation.mutateAsync({
            idDemande,
            idOffre,
            isMartine: session?.isMartine,
            tauxPropose: tauxPropose
        });
    };

    const handleShowInfoTauxModal = () => {
        dispatch(
            sendEvent({
                category: EVENT_CATEGORIES.RHN,
                action: EVENT_ACTIONS.CLIC,
                label: EVENT_LABELS.COMPRENDRE_TAUX
            })
        );
    };

    const handleFilterClic = (selectedFilter: string) => {
        // Cybermetrie filtre
        dispatch(
            sendEvent({
                category: EVENT_CATEGORIES.RHN,
                action: EVENT_ACTIONS.CLIC,
                label: EVENT_LABELS.FILTRE_TAUX,
                extraAttribut2: t(selectedFilter)
            })
        );
    };

    const onAiguilleurConfirm = () => {
        // Cybermetrie confirmation
        dispatch(
            sendEvent({
                category: EVENT_CATEGORIES.RHN,
                action: EVENT_ACTIONS.CLIC,
                label: EVENT_LABELS.SELECTEUR_TAUX
            })
        );
    };

    // PRESELECT pour ceux n'ayant pas des prestations invalidites
    useEffect(() => {
        if (preselectionnerMutation.isSuccess) {
            if (preselectionnerMutation.data?.codeAvis) {
                // Save code avis in store
                dispatch(setCodeAvis(preselectionnerMutation.data?.codeAvis));
                dispatch(hideLoading());
            } else {
                // Default next route
                let nextRoute = getRoute().nextRoute;
                // Si j'ai choisi une offre Variable protege et que je ne suis pas martine -> Info signature
                if (isOfferVariableProtege && !session?.isMartine) {
                    nextRoute = getRoute().nextRouteConditional2;
                } else if (renouvellement?.pretAdmissibleAssurance) {
                    // Si admissible ajout assurance -> Eveil assurance
                    nextRoute = getRoute().nextRouteConditional1;
                }
                // Change route
                goToRoute(nextRoute);
            }
        }
    }, [preselectionnerMutation.isSuccess]);

    useEffect(() => {
        const loadingFlag =
            isRenouvellementFetching || isOffresFetching || isPretFetching;

        dispatch(setLoading(loadingFlag));
    }, [isRenouvellementFetching, isOffresFetching, isPretFetching]);

    useEffect(() => {
        dispatch(
            sendEvent({
                category: EVENT_CATEGORIES.FORMULAIRE,
                action: EVENT_ACTIONS.DEBUTE,
                label: EVENT_LABELS.FRM
            })
        );
    }, []);

    return (
        !isLoading && (
            <HeadingProvider value={headingMapping}>
                <StatutEmprunteur skipUpdate={true} />
                <Offres
                    offresListe={offres?.formattedCardList || []}
                    pretActuel={pret}
                    isAdmissibleAjoutAssurance={
                        renouvellement?.pretAdmissibleAssurance
                    }
                    isCoemprunteur={renouvellement?.emprunteurs?.length > 1}
                    onOfferSelected={handleOfferSelected}
                    onShowInfoTauxModal={handleShowInfoTauxModal}
                    onFilterOrSortClic={handleFilterClic}
                    onAiguilleurConfirm={onAiguilleurConfirm}
                    defaultFilter={OFFFER_FILTER_LIST[0].value}
                    defaultSort={OFFFER_SORT_LIST[0].value}
                    pageInitiale={renouvellement?.pageInitiale}
                />
                <NavigationOffres />
            </HeadingProvider>
        )
    );
};

export default OffresConnected;
