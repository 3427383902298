import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Close from '@material-ui/icons/Close';
import { Box, Button, Tooltip } from '@material-ui/core';
import { RenderMockButton } from '../../api';

interface IErrorBannerProps {
    idDemande?: string;
    errorDetails: any;
    errorDate: any;
    errorUrl: string;
    onClose: () => void;
}

const useStyles = makeStyles({
    table: {
        minWidth: 650
    }
});

// Permet de savoir qui contacter selon les APIs
const apisErrorDictionnary = {
    ['API CalculVersement:']: {
        team: 'CSM-F',
        contact: 'CSMF-Financement-Simulation-Versement@desjardins.com'
    },
    ['API Versement:']: {
        team: 'CSM-F',
        contact: 'CSMF-Financement-Simulation-Versement@desjardins.com'
    },
    ['API SoumissionAssurance:']: {
        team: 'TNAP - Assurance',
        contact: 'equipe.institutionnelle.dsads@desjardins.com'
    },
    ['API PretATerme:']: {
        team: 'CSM-F',
        contact: 'CSMF-Escouade-APICulture@desjardins.com'
    },
    ['API Assurance:']: {
        team: 'CSM-F',
        contact: 'csmf-assurance-financement@desjardins.com'
    }
};

export const ErrorBanner: React.FC<IErrorBannerProps> = ({
    idDemande,
    errorDetails,
    errorUrl,
    errorDate,
    onClose
}) => {
    const classes = useStyles();

    // Utile seulement en Non-Prod pour les tests d'acceptation des differentes equipes
    const handleCopyErrorDetails = () => {
        let errorData =
            "Details de l'erreur survenue le " +
            errorDate +
            ' pour la Demande: ' +
            idDemande;
        errorData += '\n\t- Url actuelle: ' + window.location.href;
        if (errorUrl) {
            errorData += '\n\t- Error API URL: ' + errorUrl;
        }
        if (errorDetails) {
            errorData += '\n\t- Error code: ' + errorDetails?.code;
            errorData += '\n\t- Error message: ' + errorDetails?.message;
            if (errorDetails?.key)
                errorData += '\n\t- Error Key: ' + errorDetails?.key;
            if (errorDetails?.traceId)
                errorData += '\n\t- ' + errorDetails?.traceId;
            errorData += '\n\n';
            for (const apiName in apisErrorDictionnary) {
                if (errorDetails?.message?.includes(apiName)) {
                    errorData +=
                        "Pour cette erreur, vous pouvez contacter l'equipe: " +
                        apisErrorDictionnary[apiName]?.team +
                        ' / Contact: ' +
                        apisErrorDictionnary[apiName]?.contact;
                    if (errorDetails?.traceId) {
                        errorData +=
                            ' en leur fournissant ' + errorDetails?.traceId;
                    }
                    break;
                }
            }
        }
        navigator.clipboard.writeText(errorData);
    };

    return (
        <>
            <TableContainer id={'QA_test_info_error_banner'} component={Paper}>
                <Box ml={2} mt={1} display="flex">
                    <Box mr={3}>
                        <Tooltip title="Utilisez ce bouton pour copier les details automatiquement et collez les dans Teams">
                            <Button
                                style={{
                                    color: 'white',
                                    backgroundColor: 'green'
                                }}
                                onClick={() => {
                                    handleCopyErrorDetails();
                                }}
                                data-testid="QA_test_error_banner_button_copy"
                            >
                                {"Copier les details de l'erreur"}
                            </Button>
                        </Tooltip>
                    </Box>
                    {RenderMockButton()}
                </Box>
                <Table className={classes.table} size="small">
                    <TableHead>
                        <TableRow>
                            <TableCell align="left">Id Demande</TableCell>
                            <TableCell align="left">Date</TableCell>
                            <TableCell align="left">Error message</TableCell>
                            <TableCell align="left">Error url</TableCell>
                            <TableCell align="left">Error key</TableCell>
                            <TableCell align="left">Trace Id</TableCell>
                            <TableCell align="left">HTTP code</TableCell>
                            <TableCell align="center" size="small">
                                Close
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <TableRow key={idDemande}>
                            <TableCell align="left" scope="row">
                                {idDemande}
                            </TableCell>
                            <TableCell align="left">{errorDate}</TableCell>
                            <TableCell align="left">
                                {errorDetails?.message}
                            </TableCell>
                            <TableCell align="left">{errorUrl}</TableCell>
                            <TableCell align="left">
                                {errorDetails?.key}
                            </TableCell>
                            <TableCell align="left">
                                {errorDetails?.traceId}
                            </TableCell>
                            <TableCell align="left">
                                {errorDetails?.code}
                            </TableCell>
                            <TableCell
                                id={'QA_test_close_debug_banner'}
                                align="center"
                                size="small"
                            >
                                <Close onClick={onClose}></Close>
                            </TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </TableContainer>
        </>
    );
};
