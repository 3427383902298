import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    setOffreModifiee,
    sendEvent,
    EVENT_CATEGORIES,
    EVENT_ACTIONS,
    EVENT_LABELS,
    setMontantSelectionnee,
    setOffreChoisie
} from '../../../../features';
import { DetailsPaiementBar } from './DetailsPaiementBar';
import { getFormattedFrequencesOptions } from '../../modalites.transformer';

interface IDetailsPaiementBarConnectedProps {
    shouldShowCoutAssuranceModal: boolean;
    paiementData: any;
    frequencesData: any;
}

export const DetailsPaiementBarConnected: React.FC<
    IDetailsPaiementBarConnectedProps
> = ({ shouldShowCoutAssuranceModal, paiementData, frequencesData }) => {
    const dispatch = useDispatch();

    // Store
    const offreBase = useSelector((state: any) => state.modalites.offreBase);

    const handleReset = () => {
        dispatch(
            sendEvent({
                category: EVENT_CATEGORIES.RHN,
                action: EVENT_ACTIONS.CLIC,
                label: EVENT_LABELS.RECOMMENCER_CALCUL
            })
        );

        const paiementEtFrequences = paiementData.concat(frequencesData);

        const formattedFrequencesOptions =
            getFormattedFrequencesOptions(paiementEtFrequences);

        const offreModifieeFormatted = formattedFrequencesOptions.find(
            ({ frequenceRemboursement }) =>
                frequenceRemboursement === offreBase.frequenceRemboursement
        );

        dispatch(
            setOffreModifiee({
                ...offreModifieeFormatted,
                isCalculer: true
            })
        );

        // si le membre a ajouter de l'assurance l'offre de base est simule donc il faut toujours prendre l'id de l'offre simule
        dispatch(
            setOffreChoisie({
                ...offreBase,
                idOffre: offreBase.idOffre
            })
        );

        dispatch(setMontantSelectionnee(offreBase?.montantRemboursement));
    };

    return (
        <DetailsPaiementBar
            isCoutAssuranceModalDisplayed={shouldShowCoutAssuranceModal}
            onReset={handleReset}
        />
    );
};
