import {
    Alert,
    AlertType,
    ColorHex,
    ContainerMui,
    EnumLinkType,
    Grid,
    GridCol,
    GridRow,
    Hr,
    Link,
    Police,
    Spacer,
    Tag,
    Typography,
    Variant
} from '../../styles';
import { Box, CardMedia } from '@material-ui/core';
import { ClavardageContexte, setContexteClavardage } from '../../components';
import { ContexteAppel, getParlerConseillerUrl } from '../../utils';
import {
    MoreOptions,
    RenouvellementEtapes,
    StatusCoEmprunteurDeux
} from './components';
import React, { useEffect } from 'react';
import { createStyles, makeStyles } from '@material-ui/core/styles';

import { ParcoursEmprunteur } from '../../hooks';
import { useTranslation } from 'react-i18next';

interface HomeProps {
    parcoursEmprunteur: ParcoursEmprunteur;
    isRenewalExpired: boolean;
    guyFirstName: string;
    dateLimite: Date;
    dateRappel: Date;
    dateAujourdhui: Date;
    delaisRenouvellement: number;
    idDemande: number;
}

const useStyles = makeStyles(() =>
    createStyles({
        imageMobile: {
            borderRadius: '1rem 1rem 0 0',
            height: '100%',
            paddingBottom: '75%',
            backgroundPosition: 'bottom right',
            position: 'relative',
            display: 'flex',
            justifyContent: 'center'
        },
        imageDesktop: {
            borderRadius: '0 1rem 1rem 0',
            height: '100%',
            position: 'relative',
            backgroundPosition: 'bottom right',
            display: 'flex',
            justifyContent: 'center'
        },
        chevron: {
            top: '1.875rem',
            position: 'absolute',
            width: '42.5%'
        },
        description: {
            padding: '1.5rem 1.5rem 0rem 1.5rem',
            '@media (min-width: 640px)': {
                padding: '1.5rem .5rem 0rem 1.5rem'
            },
            '@media (min-width: 1200px)': {
                padding: '1.5rem 0 0rem 1.5rem'
            }
        }
    })
);

export const Home: React.FC<HomeProps> = ({
    parcoursEmprunteur,
    isRenewalExpired,
    guyFirstName,
    dateLimite,
    dateRappel,
    dateAujourdhui,
    delaisRenouvellement,
    idDemande
}) => {
    const [t] = useTranslation(['Home']);
    const classes = useStyles();
    const isUniqueParcours = parcoursEmprunteur === ParcoursEmprunteur.UNIQUE;
    const Chevron = () => {
        return (
            <img
                alt=""
                src="/images/chevronup.svg"
                className={classes.chevron}
            />
        );
    };

    const RenderEmprunteurComponents = () => {
        switch (parcoursEmprunteur) {
            case ParcoursEmprunteur.COEMPRUNTEUR_GUY:
                return (
                    <React.Fragment>
                        <RenouvellementEtapes
                            isEmprunteurUnique={false}
                            delais={delaisRenouvellement}
                        />
                        <p>{t('renouvellement_actif_guy')}</p>
                        <Spacer all={2} />
                        <MoreOptions idDemande={idDemande} />
                    </React.Fragment>
                );
            case ParcoursEmprunteur.COEMPRUNTEUR_MARTINE:
                return (
                    <React.Fragment>
                        <StatusCoEmprunteurDeux
                            guyFirstName={guyFirstName}
                            dateLimite={dateLimite}
                            dateRappel={dateRappel}
                            delaiApprobation={delaisRenouvellement}
                            dateAujourdhui={dateAujourdhui}
                        />
                    </React.Fragment>
                );
            default:
                //unique
                return (
                    <React.Fragment>
                        <RenouvellementEtapes isEmprunteurUnique={true} />
                        <p>{t('renouvellement_actif_unique')}</p>
                        <MoreOptions idDemande={idDemande} />
                    </React.Fragment>
                );
        }
    };

    useEffect(() => {
        setContexteClavardage(ClavardageContexte.ACCUEIL);

        return () => {
            // Réinitialisation du contexte de clavardage
            setContexteClavardage(ClavardageContexte.DEFAULT);
        };
    }, []);

    return (
        <Grid>
            <ContainerMui hidePadding>
                <GridRow>
                    {/* image pour mobile */}
                    <GridCol col="12" className="sm:dsd-d-none">
                        <CardMedia
                            className={classes.imageMobile}
                            image={'/images/home.jpg'}
                        >
                            <Chevron />
                        </CardMedia>
                    </GridCol>

                    {/* content */}
                    <GridCol col="12" sm="8" md="6">
                        <div
                            id="QA_test_instructions"
                            data-testid="QA-test-instructions"
                            className={classes.description}
                        >
                            <Typography
                                variant={Variant.h1}
                                police={Police.bold}
                            >
                                {t('title')}
                            </Typography>
                            <Tag
                                iconName="contenus_contour_temps"
                                srOnlyIcon={t('estimated_time_alt')}
                                label={t('estimated_time')}
                            />
                            <Spacer />

                            {/* Alerte si période de renouvellement est expirée */}
                            {isRenewalExpired && (
                                <Box
                                    pt={'0.5rem'}
                                    data-testid="bande-avis-date-depassee"
                                >
                                    <Alert type={AlertType.warning}>
                                        {/* Texte hors ecran */}
                                        <span slot="title">
                                            {t('warning_text_click2call_hs')}
                                        </span>
                                        {/* Texte visible */}
                                        {t('warning_text')}
                                        <br />
                                        <br />
                                        {t('warning_text_click2call_prefix')}
                                        <Link
                                            dataTestId="parler-conseiller-accueil-retard"
                                            type={EnumLinkType.EXTERNAL}
                                            href={getParlerConseillerUrl(
                                                ContexteAppel.RAPPEL_DEPASSEE,
                                                idDemande
                                            )}
                                            ariaLabel={t(
                                                'warning_text_click2call_aria'
                                            )}
                                        >
                                            {t('warning_text_click2call_link')}
                                        </Link>
                                        {t('warning_text_click2call_suffix')}
                                    </Alert>
                                </Box>
                            )}
                            <RenderEmprunteurComponents />
                            <Hr
                                backgroundColor={ColorHex.lightGraphite2}
                                height="2px"
                            />
                            <Typography
                                variant={Variant.h3}
                                police={Police.bold}
                            >
                                {t('precision_title')}
                            </Typography>
                            <ul>
                                <li>{t('precision_text_1')}</li>
                                <li>{t('precision_text_2')}</li>
                            </ul>
                        </div>
                    </GridCol>

                    {/* image desktop */}
                    <GridCol
                        className="dsd-d-none sm:dsd-d-block"
                        sm="4"
                        md="6"
                    >
                        <CardMedia
                            className={classes.imageDesktop}
                            image="/images/home.jpg"
                        >
                            <Chevron />
                        </CardMedia>
                    </GridCol>
                </GridRow>
            </ContainerMui>
            <ContainerMui hidePadding>
                <div
                    className={classes.description}
                    data-testid="bloc_consentement_accueil"
                >
                    <Typography variant={Variant.h2} police={Police.bold}>
                        {t('consentement_title')}
                    </Typography>
                    <Typography variant={Variant.p}>
                        {t('consentement_notice_1')}
                        {isUniqueParcours
                            ? t('consentement_notice_1_bis_unique')
                            : t('consentement_notice_1_bis_coemprunteur')}
                    </Typography>
                    <Typography variant={Variant.p}>
                        {t('consentement_notice_2')}
                        <strong>{t('consentement_notice_2_strong')}</strong>
                        {isUniqueParcours
                            ? t('consentement_notice_2_bis_unique')
                            : parcoursEmprunteur ===
                              ParcoursEmprunteur.COEMPRUNTEUR_GUY
                            ? t('consentement_notice_2_bis_guy')
                            : t('consentement_notice_2_bis_martine')}
                    </Typography>
                    <Spacer all={3} />
                </div>
            </ContainerMui>
        </Grid>
    );
};
export default Home;
