import {
    getDesjLangueFromCookie,
    getLangueFromCookies,
    Langues,
    QUERY_PARAM_LANGUE,
    URLToURLSearchParams
} from '.';

//Determine la langue du parcours
// Voici l'ordre logique:
//    le query param sinon le cookie config sinon le cookie Desjardins sinon langue du navigateur / application ou francais
export function getLangue(): string {
    // From queryParam
    const query = URLToURLSearchParams();
    const param = query.get(QUERY_PARAM_LANGUE);
    if (param === Langues.FR) {
        return Langues.FR;
    } else if (param === Langues.EN) {
        return Langues.EN;
    }

    // From cookie config
    const langueFromConfig = getLangueFromCookies();
    if (langueFromConfig) return langueFromConfig;

    // From cookie DESJ_PREF (desjardins.com)
    const langueFromDesj = getDesjLangueFromCookie();
    if (langueFromDesj) return langueFromDesj;

    // From web
    const navigatorLanguage = navigator.language;
    if (navigatorLanguage) {
        return navigatorLanguage.substring(0, 2);
    }

    // Default: FR
    return Langues.FR;
}
