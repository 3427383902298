import React from 'react';
import { useDispatch } from 'react-redux';

import { MoreOptions } from './MoreOptions';

import {
    sendEvent,
    EVENT_CATEGORIES,
    EVENT_ACTIONS,
    EVENT_LABELS
} from '../../../../features';
import { getPageName } from '../../../../hooks';

export interface IMoreOptionsConnectedProps {
    idDemande: number;
}

export const MoreOptionsConnected: React.FC<IMoreOptionsConnectedProps> = ({
    idDemande
}) => {
    const dispatch = useDispatch();

    const handleClicLienParlerConseiller = () => {
        dispatch(
            sendEvent({
                category: EVENT_CATEGORIES.RHN,
                action: EVENT_ACTIONS.CLIC,
                label: EVENT_LABELS.CLICK2CALL_SPECIALISTE + getPageName()
            })
        );
    };

    return (
        <MoreOptions
            idDemande={idDemande}
            onNavigationParlerConseiller={handleClicLienParlerConseiller}
        ></MoreOptions>
    );
};
