export default {
    en: {
        titre: 'You have unpaid interest',
        description_taux_variable:
            'As a result of the rate hikes, your regular payments weren’t high enough to cover the interest on your variable-rate mortgage.',
        description:
            'Before you renew your mortgage, you’ll need to decide how you want to pay the interest.',
        interet_non_paye_box_titre: 'Unpaid interest as of ',
        interet_non_paye_box_description:
            'The unpaid interest will continue to accrue additional interest until it’s paid off.',
        option_titre: 'Two ways to pay',
        option_1: 'Pay off the entire amount at once',
        option_2: 'Add the amount to your mortgage balance when you renew',
        appeler_nous_titre: 'Call us',
        appeler_nous_description:
            'Meet with a caisse advisor to discuss your options and find the best solution for you.',
        return_button: 'Back to AccèsD home'
    },
    fr: {
        titre: 'Vous avez des intérêts non payés',
        description_taux_variable:
            'À cause de la hausse des taux, les paiements réguliers de votre prêt à taux variable n’ont pas été suffisants pour couvrir les intérêts.',
        description:
            'Avant de renouveler votre prêt hypothécaire, vous devez choisir une option de paiement des intérêts.',
        interet_non_paye_box_titre: 'Intérêts non payés le ',
        interet_non_paye_box_description:
            'Tant que ces intérêts ne sont pas payés, ils engendrent des intérêts à leur tour.',
        option_titre: 'Deux options de paiement',
        option_1: 'Payer le montant total en un versement',
        option_2:
            'Ajouter le montant au solde de votre prêt lors du renouvellement',
        appeler_nous_titre: 'Appelez-nous',
        appeler_nous_description:
            'Consultez votre spécialiste en caisse pour prendre une décision éclairée et régler la situation.',
        return_button: 'Retour à l’accueil'
    }
};
