import React from 'react';
import DOMPurify from 'dompurify';
import { useTranslation } from 'react-i18next';

import { Typography, Variant, Article, Modal } from '../../styles';

export interface INavigationModalProps {
    isOpen?: boolean;
    modaleTitle?: string;
    modaleMessage?: string;
    modaleAcceptButton?: string;
    modaleStayButton?: string;
    onNext?: () => void;
    onCancel?: () => void;
}

export const NavigationModal: React.FC<INavigationModalProps> = ({
    isOpen,
    modaleTitle,
    modaleMessage,
    modaleAcceptButton,
    modaleStayButton,
    onNext,
    onCancel
}) => {
    const [t] = useTranslation(['NavigationModal']);
    const usedTitle = modaleTitle || t('attention');
    const usedPrimaryLabel = modaleAcceptButton || t('compris');
    const usedSecondaryLabel = modaleStayButton || t('cancel');

    return (
        <>
            {isOpen && (
                <Modal
                    show={true}
                    title={usedTitle}
                    buttonPrimaryLabel={usedPrimaryLabel}
                    buttonSecondaryLabel={usedSecondaryLabel}
                    onClickPrimaryButton={onNext}
                    onClickSecondaryButton={onCancel}
                >
                    <Typography variant={Variant.p} article={Article.a3}>
                        <span
                            dangerouslySetInnerHTML={{
                                __html: DOMPurify.sanitize(modaleMessage)
                            }}
                        ></span>
                    </Typography>
                </Modal>
            )}
        </>
    );
};
