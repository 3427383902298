import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
    setLoading,
    setMontantSelectionnee,
    sendEvent,
    EVENT_CATEGORIES,
    EVENT_ACTIONS,
    EVENT_LABELS,
    showLoading,
    hideLoading,
    setCodeAvis,
    setOffreChoisie
} from '../../features';
import {
    useSessionApi,
    useObtenirResumeApi,
    useRenouvellementApi,
    useRoutes,
    isVariableProtege,
    getPageStep,
    useStatutEmprunteurApi,
    useRefuserOffreApi,
    useSelectionnerOffreCoemprunteurApi,
    getPageName,
    useCodeAvisApi,
    useSignatureAdmissibiliteApi,
    useSoldeFinTermeApi,
    mustFetchSoldeFinTerme,
    useActualiserOffreGuyApi,
    ParcoursEmprunteur,
    useAssuranceApi
} from '../../hooks';
import { StatutEmprunteur } from '../../components';
import { Headings } from '../../styles';
import { HeadingProvider } from '../../context';
import { Navigation } from './Validation.navigation';
import { Validation } from './Validation';

const headingMapping = {
    h2: Headings.h3
};

export const ValidationConnected: React.FC<unknown> = () => {
    const dispatch = useDispatch();
    const { getRoute, goToRoute } = useRoutes();

    // State
    const [shouldFetchSoldeFinTerme, setShouldFetchSoldeFinTerme] =
        useState(false);
    const [shouldFetchResume, setShouldFetchResume] = useState(false);

    // Store
    const isLoading = useSelector((state: any) => state.loading.show);
    const montantSelectionne = useSelector(
        (state: any) => state.renouvellement.montantSelectionne
    );

    // Apis
    const { data: session } = useSessionApi();
    const idDemande = session?.contextePret?.idDemande;
    const { data: renouvellement } = useRenouvellementApi(!!session);
    const { data: resumeChoix, isFetching: isObtenirResumeFetching } =
        useObtenirResumeApi(idDemande, 'Validation', shouldFetchResume);
    const { data: signatureAdmissibilite, isFetching: isSIAdmissibleFetching } =
        useSignatureAdmissibiliteApi(idDemande, !!renouvellement);
    const { data: soldeFinTerme, isFetching: isSoldeFinTermeFetching } =
        useSoldeFinTermeApi(
            idDemande,
            shouldFetchSoldeFinTerme && !!renouvellement
        );

    // Apis mutation
    const statutEmprunteurMutation = useStatutEmprunteurApi();
    const refuserOffreMutation = useRefuserOffreApi();
    const codeAvisMutation = useCodeAvisApi();
    const selectionnerOffreCoemprunteurMutation =
        useSelectionnerOffreCoemprunteurApi();
    const actualiserOffreGuyMutation = useActualiserOffreGuyApi();
    const assuranceApiMutation = useAssuranceApi();

    useEffect(() => {
        if (signatureAdmissibilite) {
            if (
                signatureAdmissibilite?.admissible &&
                session?.typeParcoursEmprunteur ===
                    ParcoursEmprunteur.COEMPRUNTEUR_GUY
            ) {
                actualiserOffreGuyMutation.mutateAsync(idDemande);
            } else {
                setShouldFetchResume(true);
            }
        }
    }, [signatureAdmissibilite]);

    useEffect(() => {
        if (actualiserOffreGuyMutation.isSuccess) {
            setShouldFetchResume(true);
        }
    }, [actualiserOffreGuyMutation.isSuccess]);

    useEffect(() => {
        if (resumeChoix) {
            setShouldFetchSoldeFinTerme(mustFetchSoldeFinTerme(resumeChoix));
            dispatch(setOffreChoisie(resumeChoix?.offreChoisie));
        }

        // On sauvegarde le taux actuel si on rebascule ici
        if (resumeChoix && !montantSelectionne) {
            dispatch(
                setMontantSelectionnee(
                    resumeChoix?.offreChoisie.montantRemboursement
                )
            );
        }
    }, [resumeChoix]);

    useEffect(() => {
        if (soldeFinTerme) {
            resumeChoix.offreChoisie = {
                ...resumeChoix.offreChoisie,
                soldeFinDeTerme: soldeFinTerme
            };
        }
    }, [soldeFinTerme]);

    useEffect(() => {
        const etapeCouranteParcours = session?.isMartine
            ? EVENT_ACTIONS.ETAPE_8
            : EVENT_ACTIONS.ETAPE_4;

        dispatch(
            sendEvent({
                category: EVENT_CATEGORIES.FORMULAIRE,
                action: etapeCouranteParcours,
                label: EVENT_LABELS.FRM
            })
        );
    }, [session]);

    const handleClickNext = () => {
        dispatch(showLoading());

        // selection offre pour martine
        if (session?.isMartine) {
            dispatch(
                sendEvent({
                    category: EVENT_CATEGORIES.RHN,
                    action: EVENT_ACTIONS.CLIC,
                    label: EVENT_LABELS.ACCEPTER_COEMPRUNTEUR
                })
            );

            selectionnerOffreCoemprunteurMutation.mutateAsync({
                idDemande,
                isMartine: true,
                idOffre: resumeChoix?.offreChoisie.idOffre
            });
        } else {
            //On verifie codeAvis
            codeAvisMutation.mutateAsync({
                idDemande
            });
        }
    };

    const handleClickPrevWithAssurance = () => {
        dispatch(showLoading());
        assuranceApiMutation.mutateAsync(idDemande);
    };

    const handleMartineRefuse = () => {
        dispatch(showLoading());

        dispatch(
            sendEvent({
                category: EVENT_CATEGORIES.RHN,
                action: EVENT_ACTIONS.CLIC,
                label: EVENT_LABELS.REFUSER_COEMPRUNTEUR
            })
        );

        // Reject offer
        refuserOffreMutation.mutateAsync(idDemande);
    };

    // Retour de l'appel basculer vers assurance -> redirect
    useEffect(() => {
        if (assuranceApiMutation.isSuccess) {
            // Si on recoit un redirectUrl -> Alors on suit l'url ( Assurance )
            if (assuranceApiMutation.data?.redirectUrl) {
                window.location.href = assuranceApiMutation.data?.redirectUrl;
            } else {
                dispatch(hideLoading());
            }
        }
    }, [assuranceApiMutation.isSuccess]);

    useEffect(() => {
        if (refuserOffreMutation.isSuccess) {
            window.location.reload();
        }
    }, [refuserOffreMutation.isSuccess]);

    // verifie si un codeAvis est present pour Guy/Unique avant de passer a la suite
    useEffect(() => {
        if (codeAvisMutation.isSuccess) {
            if (codeAvisMutation.data?.codeAvis) {
                // Save code avis in store
                dispatch(setCodeAvis(codeAvisMutation.data?.codeAvis));
                dispatch(hideLoading());
                // Si on recoit un redirectUrl -> Alors on suit l'url ( Assurance )
            } else {
                goToRoute(getRoute().nextRoute);
            }
        }
    }, [codeAvisMutation.isSuccess]);

    // quand martine fait next il y a plusieurs cas a gerer apres l'appel selectionner offre
    useEffect(() => {
        if (selectionnerOffreCoemprunteurMutation.isSuccess) {
            if (selectionnerOffreCoemprunteurMutation.data?.codeAvis) {
                // Save code avis in store
                dispatch(
                    setCodeAvis(
                        selectionnerOffreCoemprunteurMutation.data?.codeAvis
                    )
                );
                dispatch(hideLoading());
                // Si on recoit un redirectUrl -> Alors on suit l'url ( Assurance )
            } else if (
                selectionnerOffreCoemprunteurMutation.data?.redirectUrl
            ) {
                window.location.href =
                    selectionnerOffreCoemprunteurMutation.data?.redirectUrl;
            } else {
                goToRoute(getRoute().nextRoute);
            }
        }
    }, [selectionnerOffreCoemprunteurMutation.isSuccess]);

    // Scanner les hooks pour savoir si le chargement est fini
    useEffect(() => {
        const loadingFlag =
            isObtenirResumeFetching ||
            isSIAdmissibleFetching ||
            isSoldeFinTermeFetching ||
            actualiserOffreGuyMutation.isLoading;
        dispatch(setLoading(loadingFlag));
    }, [
        isObtenirResumeFetching,
        isSIAdmissibleFetching,
        isSoldeFinTermeFetching,
        actualiserOffreGuyMutation.isLoading
    ]);

    // backend a besoin qu'on update le statut directement si martine
    useEffect(() => {
        if (session?.isMartine) {
            const etape = getPageStep();

            statutEmprunteurMutation.mutateAsync({
                idDemande: idDemande,
                statut: etape?.statut
            });
        }
    }, []);

    const trackClick2CallAnalytic = () => {
        dispatch(
            sendEvent({
                category: EVENT_CATEGORIES.RHN,
                action: EVENT_ACTIONS.CLIC,
                label: EVENT_LABELS.CLICK2CALL_SPECIALISTE + getPageName()
            })
        );
    };

    return (
        !isLoading && (
            <HeadingProvider value={headingMapping}>
                <StatutEmprunteur />
                {renouvellement && resumeChoix && (
                    <Validation
                        idDemande={idDemande}
                        resumeOffre={resumeChoix}
                        session={session}
                        renouvellement={renouvellement}
                        trackClick2CallAnalytic={trackClick2CallAnalytic}
                    />
                )}
                {resumeChoix && (
                    <Navigation
                        session={session}
                        admissibleSI={signatureAdmissibilite?.admissible}
                        isOffreVariableProtege={isVariableProtege(
                            resumeChoix?.offreChoisie
                        )}
                        isAjoutAssurance={
                            renouvellement?.emprunteurConnecte?.isAjoutAssurance
                        }
                        onMartineRefuse={handleMartineRefuse}
                        onClickNext={handleClickNext}
                        onClickPrevWithAssurance={handleClickPrevWithAssurance}
                    />
                )}
            </HeadingProvider>
        )
    );
};
