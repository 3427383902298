import React from 'react';
import { useTranslation } from 'react-i18next';
import { Box, useMediaQuery } from '@material-ui/core';

import {
    IResumeOffre,
    TypeAssurance,
    hasAlreadyAssurance,
    hasSoumissionAssurance,
    IAssureResume
} from '../../hooks';
import {
    Button,
    GridRow,
    GridCol,
    Typography,
    Variant,
    Police,
    Spacer,
    ButtonVariant,
    ContainerMui
} from '../../styles';
import { ProtectionModal } from '..';

export interface IResumeAssuranceProps {
    resumeOffre: IResumeOffre;
    assures: Array<IAssureResume>;
    isAssuranceAdmissible: boolean;
    isEditButtonDisplayed: boolean;
    isNoAssuranceDisplayed: boolean;
    isModalProtectionVisible?: boolean; // ont affiche cette modale dans le parcours Martine
    onEditAssurance: (isSoumissionAssurance: boolean) => void;
}

export const ResumeAssurance: React.FC<IResumeAssuranceProps> = ({
    resumeOffre,
    assures = [],
    isAssuranceAdmissible,
    isEditButtonDisplayed,
    isNoAssuranceDisplayed,
    isModalProtectionVisible,
    onEditAssurance
}) => {
    const [t] = useTranslation(['ResumeAssurance']);
    const isMobile = useMediaQuery('(max-width:640px)');

    const renderAssuranceAssure = () => {
        return (
            <>
                {resumeOffre?.assurance?.listeAssure &&
                    resumeOffre?.assurance?.listeAssure?.map((assure, key) => {
                        return (
                            <GridCol col="12" key={key}>
                                <GridRow>
                                    <Typography
                                        variant={Variant.p}
                                        marginBottom="1rem"
                                    >
                                        {assure?.prenom.toUpperCase() +
                                            ' ' +
                                            assure?.nom.toUpperCase()}
                                    </Typography>
                                </GridRow>
                                <GridRow>
                                    <Typography
                                        variant={Variant.p}
                                        marginBottom="0.25rem"
                                    >
                                        {t('resumeAssuranceVieTitle')}
                                    </Typography>
                                </GridRow>
                                {assure?.indicateurEligibiliteAssuranceVie ? (
                                    <GridRow>
                                        <Typography
                                            variant={Variant.p}
                                            marginBottom="1rem"
                                        >
                                            <strong>
                                                {t('Number:percentNoDecimal', {
                                                    value: assure?.pctCouvertureAssuranceVie
                                                })}
                                            </strong>
                                            {t('resumeAssuranceVieTexte')}
                                        </Typography>
                                    </GridRow>
                                ) : (
                                    <GridRow>
                                        <Typography
                                            variant={Variant.p}
                                            marginBottom="1rem"
                                            dataTestId="text_fin_assurance_vie_resume"
                                        >
                                            {t(
                                                'resumeAssuranceVieDisabledTexte'
                                            )}
                                        </Typography>
                                    </GridRow>
                                )}
                                <GridRow>
                                    <Typography
                                        variant={Variant.p}
                                        marginBottom="0.25rem"
                                    >
                                        {t('resumeAssuranceInvaliditeTitle')}
                                    </Typography>
                                </GridRow>
                                {assure?.indicateurEligibiliteAssuranceInvalidite ? (
                                    <GridRow>
                                        <Typography
                                            variant={Variant.p}
                                            marginBottom="1rem"
                                        >
                                            <strong>
                                                {t('Number:percentNoDecimal', {
                                                    value: assure?.pctCouvertureAssuranceInvalidite
                                                })}
                                            </strong>
                                            {t(
                                                'resumeAssuranceInvaliditeTexte'
                                            )}
                                        </Typography>
                                    </GridRow>
                                ) : (
                                    <GridRow>
                                        <Typography
                                            variant={Variant.p}
                                            marginBottom="1rem"
                                            dataTestId="text_fin_assurance_invalidite_resume"
                                        >
                                            {t(
                                                'resumeAssuranceInvaliditeDisabledTexte'
                                            )}
                                        </Typography>
                                    </GridRow>
                                )}
                            </GridCol>
                        );
                    })}
                {isModalProtectionVisible && (
                    <GridRow>
                        <Box ml={-3}>
                            <ProtectionModal />
                        </Box>
                    </GridRow>
                )}
            </>
        );
    };

    const renderChoixAssurance = () => {
        const pctCouvertureAssuranceVie = assures[0]?.pctCouvertureAssuranceVie;
        const pctCouvertureAssuranceInvalidite =
            assures[0]?.pctCouvertureAssuranceInvalidite;

        return (
            <>
                {pctCouvertureAssuranceVie != null && (
                    <GridCol col="12">
                        <GridRow>
                            <Typography
                                variant={Variant.p}
                                marginBottom="0.25rem"
                            >
                                {t('resumeAssuranceVieTitle')}
                            </Typography>
                        </GridRow>
                        <GridRow>
                            <Typography
                                variant={Variant.p}
                                marginBottom="0.1rem"
                            >
                                <strong>
                                    {t('Number:percentNoDecimal', {
                                        value: pctCouvertureAssuranceVie
                                    })}
                                </strong>
                                {t('resumeAssuranceVieTexte')}
                            </Typography>
                        </GridRow>
                    </GridCol>
                )}
                {/* On n'affiche que si le pourcentage est au dessus de 0% */}
                {pctCouvertureAssuranceInvalidite != null &&
                    pctCouvertureAssuranceInvalidite > 0 && (
                        <GridCol col="12">
                            <GridRow>
                                <Typography
                                    variant={Variant.p}
                                    marginBottom="0.25rem"
                                >
                                    {t('resumeAssuranceInvaliditeTitle')}
                                </Typography>
                            </GridRow>
                            <GridRow>
                                <Typography
                                    variant={Variant.p}
                                    marginBottom="0.1rem"
                                >
                                    <strong>
                                        {t('Number:percentNoDecimal', {
                                            value: pctCouvertureAssuranceInvalidite
                                        })}
                                    </strong>
                                    {t('resumeAssuranceInvaliditeTexte')}
                                </Typography>
                            </GridRow>
                        </GridCol>
                    )}
            </>
        );
    };

    const renderDejaAssure = () => {
        return (
            <>
                <ContainerMui dataTestId="bloc_assurance_validation">
                    <Box
                        ml={isMobile ? 1 : 3}
                        mb={isMobile ? -2 : -1}
                        mt={isMobile ? -2 : -1}
                    >
                        <GridCol col="12">
                            <GridRow>
                                <Typography
                                    variant={Variant.h4}
                                    police={Police.bold}
                                    marginBottom="1rem"
                                >
                                    {t(
                                        resumeOffre?.assurance
                                            ?.typeAssurance === TypeAssurance.AP
                                            ? 'resumeAssuranceTitleAP'
                                            : 'resumeAssuranceTitleAPMat'
                                    )}
                                </Typography>
                            </GridRow>
                            {renderAssuranceAssure()}
                        </GridCol>
                    </Box>
                </ContainerMui>
                <Spacer md={3} sm={1} xs={1} />
            </>
        );
    };

    const renderEditAssuranceButton = (isSoumissionAssurance: boolean) => (
        <GridRow>
            <Button
                id={'QA_test_validation_assurance_modifier'}
                variant={ButtonVariant.compact}
                dataCy="qa-button-date-modifier"
                dataTestId={'bouton_modifier_assurance'}
                onClick={() => onEditAssurance(isSoumissionAssurance)}
                srOnlySuffix={
                    t('resumeChangeButton') + ' ' + t('resumeAssuranceTitleAP')
                }
            >
                {t('resumeChangeButton')}
            </Button>
        </GridRow>
    );

    const renderAdmissibleSansChoixAssurance = () => {
        return (
            <>
                <ContainerMui dataTestId="bloc_assurance_validation">
                    <Box
                        ml={isMobile ? 1 : 3}
                        mb={
                            isEditButtonDisplayed
                                ? isMobile
                                    ? -2
                                    : -1
                                : isMobile
                                ? -4
                                : -3
                        }
                        mt={isMobile ? -2 : -1}
                    >
                        <GridCol col="12">
                            <GridRow>
                                <Typography
                                    variant={Variant.h4}
                                    police={Police.bold}
                                >
                                    {t('resumeAssuranceTitleAPMat')}
                                </Typography>
                            </GridRow>
                            <GridRow>
                                <Typography variant={Variant.p}>
                                    {t('resumeAssuranceAdmissibleSansChoix')}
                                </Typography>
                            </GridRow>
                            {isEditButtonDisplayed &&
                                renderEditAssuranceButton(false)}
                        </GridCol>
                    </Box>
                </ContainerMui>
                <Spacer md={3} sm={1} xs={1} />
            </>
        );
    };

    const renderAdmissibleAvecChoixAssurance = () => {
        return (
            <>
                <ContainerMui dataTestId="bloc_assurance_validation">
                    <Box
                        ml={isMobile ? 1 : 3}
                        mb={
                            isEditButtonDisplayed
                                ? isMobile
                                    ? -2
                                    : -1
                                : isMobile
                                ? -4
                                : -3
                        }
                        mt={isMobile ? -2 : -1}
                    >
                        <GridCol col="12">
                            <GridRow>
                                <Typography
                                    variant={Variant.h4}
                                    police={Police.bold}
                                    marginBottom={'-0.15rem'}
                                >
                                    {t('resumeAssuranceTitleAPMat')}
                                </Typography>
                            </GridRow>
                            {renderChoixAssurance()}
                            {/* Bouton modifier assurance : Ne pas afficher si MARTINE ( page validation ) */}
                            {isEditButtonDisplayed &&
                                renderEditAssuranceButton(true)}
                        </GridCol>
                    </Box>
                </ContainerMui>
                <Spacer md={3} sm={1} xs={1} />
            </>
        );
    };

    return (
        <>
            {/* Deja de l'assurance -> On affiche leur statuts */}
            {!isAssuranceAdmissible &&
                hasAlreadyAssurance(resumeOffre?.assurance?.codeAssurance) &&
                renderDejaAssure()}
            {/* Admissible a l'ajout d'assurance et a choisi de l'assurance */}
            {isAssuranceAdmissible &&
                hasSoumissionAssurance(resumeOffre?.assurance?.codeAssurance) &&
                renderAdmissibleAvecChoixAssurance()}
            {/* Admissible a l'ajout d'assurance et n'a pas choisi de l'assurance */}
            {/* Visible que sur certaines pages (isNoAssuranceDisplayed) */}
            {isAssuranceAdmissible &&
                isNoAssuranceDisplayed &&
                !hasSoumissionAssurance(
                    resumeOffre?.assurance?.codeAssurance
                ) &&
                renderAdmissibleSansChoixAssurance()}
        </>
    );
};
