import React from 'react';
import i18n from 'i18next';

// eslint-disable-next-line
export const Footer = (props) => {
    const { language } = i18n;
    return (
        <>
            {i18n.language && (
                <oel-footer-transactionnel
                    data-testid="pied-de-page"
                    langue={props.langue || language}
                    base-rem="base-16"
                    type-container="dsd-standard"
                ></oel-footer-transactionnel>
            )}
        </>
    );
};
