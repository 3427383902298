import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { CodeAvis } from './CodeAvis';
import { useRenouvellementApi, useRoutes, useSessionApi } from '../../hooks';
import { Routes } from '../../hooks/navigation/useRoutes/routeConfig';
import {
    showLoading,
    hideLoading,
    resetCodeAvis,
    sendEvent,
    EVENT_CATEGORIES,
    EVENT_ACTIONS,
    EVENT_LABELS
} from '../../features';
import { isNextReturnFromAssurance } from '../../utils';
import { CodeAvisEnumModal } from './CodeAvis.utils';

export const CodeAvisConnected: React.FC<unknown> = () => {
    const dispatch = useDispatch();
    const { resetParcours, goToRoute, getRoute } = useRoutes();

    // Apis
    const { data: session, isFetching: isSessionFetching } = useSessionApi();
    const { data: renouvellement, isFetching: isRenouvellementFetching } =
        useRenouvellementApi(!!session);

    // State
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [codeAvis, setCodeAvis] = useState<CodeAvisEnumModal>(null);
    const [isModalViewed, setIsModalViewed] = useState(false);
    const [isAvisFromBascule, setIsAvisFromBascule] = useState(false);

    // Store
    const codeAvisStored = useSelector((state: any) => state.codeAvis.codeAvis);

    // Validation du code Avis
    const validateCodeAvis = (codeAvis) => {
        // Exception pour le code AVIS_DATE_DEPASSEE ou on ne l'affiche pas si
        // on revient de l'assurance en marche avant
        if (
            codeAvis === CodeAvisEnumModal.AVIS_DATE_DEPASSEE &&
            isNextReturnFromAssurance()
        ) {
            return;
        }
        // On verifie si pour ce code avis on doit afficher de quoi
        if (codeAvis && codeAvis in CodeAvisEnumModal) {
            setCodeAvis(codeAvis);
            setIsModalOpen(true);

            // envoyer un evenement a la cybermetrie quand la modal ouvre et offre modifie
            if (codeAvis === CodeAvisEnumModal.AVIS_OFFRE_MODIFIEE) {
                dispatch(
                    sendEvent({
                        category: EVENT_CATEGORIES.RHN,
                        action: EVENT_ACTIONS.AFFICHAGE,
                        label: EVENT_LABELS.CODE_AVIS_OFFRE_MODIFIEE
                    })
                );
            }
            // Back to top for conflict
            if (
                CodeAvisEnumModal.AVIS_CONFLIT_CONVENTION === codeAvis ||
                CodeAvisEnumModal.AVIS_COURRIEL_INVALIDE === codeAvis
            ) {
                sendEvent({
                    category: EVENT_CATEGORIES.FORMULAIRE,
                    action: EVENT_ACTIONS.ERREUR_UTILISATEUR,
                    label: EVENT_LABELS.FRM,
                    messageErreur:
                        'Erreur utilisateur page ' +
                        getRoute().route +
                        ' : ' +
                        codeAvis
                });
                window.scrollTo(0, 0);
            }
        } else {
            // Reset code avis for next one
            setIsAvisFromBascule(false);
        }
    };

    // Scan session call (date depassee)
    useEffect(() => {
        if (session?.codeAvis) {
            setIsAvisFromBascule(true);
            validateCodeAvis(session?.codeAvis);
        }
    }, [session]);

    // Scan renouvellement call
    useEffect(() => {
        if (renouvellement?.codeAvis) {
            setIsAvisFromBascule(true);
            validateCodeAvis(renouvellement?.codeAvis);
        }
    }, [renouvellement]);

    // Scan PUT calls via store
    useEffect(() => {
        if (codeAvisStored) {
            validateCodeAvis(codeAvisStored);
            dispatch(resetCodeAvis());
        }
    }, [codeAvisStored]);

    const handleClose = () => {
        // Close modal
        setIsModalOpen(false);

        // Conflit convention does not reload context
        if (
            codeAvis !== CodeAvisEnumModal.AVIS_CONFLIT_CONVENTION &&
            codeAvis !== CodeAvisEnumModal.AVIS_COURRIEL_INVALIDE &&
            codeAvis !== CodeAvisEnumModal.AVIS_SIGNATURE_EN_COURS
        ) {
            // Si l'avis vient pendant le parcours -> Besoin de recharger
            dispatch(showLoading());

            if (!isAvisFromBascule) {
                // reload
                window.location.reload();
            } else {
                // Reset code avis for next one
                setIsAvisFromBascule(false);
            }

            // enable refetch
            setIsModalViewed(true);
        }
    };

    // Wait refetch of data and after reset progress
    useEffect(() => {
        if (isModalViewed && !isSessionFetching && !isRenouvellementFetching) {
            // codeAvis will not be seing again
            setIsModalViewed(false);
            dispatch(hideLoading());

            // Edge case pour AVIS_PRESTATION_INVALIDITE/AVIS_OFFRE_MODIFIEE(sauf Martine): on redirige au offres
            if (
                codeAvis === CodeAvisEnumModal.AVIS_PRESTATION_INVALIDITE ||
                (codeAvis === CodeAvisEnumModal.AVIS_OFFRE_MODIFIEE &&
                    !session?.isMartine)
            ) {
                goToRoute(Routes.OFFRES);
            } else {
                resetParcours();
            }
        }
    }, [isModalViewed, isSessionFetching, isRenouvellementFetching]);

    return (
        <CodeAvis
            onClose={handleClose}
            isOpen={isModalOpen}
            codeAvis={codeAvis}
            guyName={
                renouvellement?.emprunteurNonConnecte?.prenom +
                ' ' +
                renouvellement?.emprunteurNonConnecte?.nom
            }
        />
    );
};
