import React from 'react';
import i18n from 'i18next';

// eslint-disable-next-line
export const Header = (props) => {
    const { language } = i18n;

    return (
        <>
            <oel-header-transactionnel
                data-testid="entete-desjardins"
                base-rem="base-16"
                type-container="dsd-standard"
                comportement-bouton-retour={props.comportementBoutonRetour}
                custom-max-width-container={props.customMaxWidthContainer}
                custom-padding-container={props.customPaddingContainer}
                domaine-virtuel={props.domaineVirtuel}
                est-authentifie={props.estAuthentifie}
                id-contenu-principal={props.idContenuPrincipal}
                langue={props.langue || language}
                taille-texte-normale={props.tailleTexteNormale}
                taille-texte-moyenne={props.tailleTexteMoyenne}
                taille-texte-large={props.tailleTexteLarge}
                url-retour={props.urlRetour}
                url-retour-non-authentifie={props.urlRetour}
                version-theme={props.versionTheme}
                libelle-bouton-retour={props.libelleBoutonRetour}
            ></oel-header-transactionnel>
        </>
    );
};
