export default {
    en: {
        // Texte de la modale
        modalContentTitle: 'Save and leave?',
        modalContentLeaveButton: 'Save and leave',
        modalContentStayButton: 'Continue renewal',
        modalContentStaySignButton: 'Continue signing',
        modalContentText:
            'The information you entered has been saved. If you leave, the selected rate won’t be guaranteed.',
        modalContentTextMartine:
            'The information you entered was saved. If you leave, you’ll need to <strong>come back by {{date, MMMM\xa0D}}, {{date, YYYY}}</strong> to confirm your renewal options.'
    },
    fr: {
        // Texte de la modale
        modalContentTitle: 'Enregistrer et quitter?',
        modalContentLeaveButton: 'Enregistrer et quitter',
        modalContentStayButton: 'Continuer le renouvellement',
        modalContentStaySignButton: 'Continuer la signature',
        modalContentText:
            'Les informations que vous avez entrées sont enregistrées. Si vous quittez, le taux choisi ne sera pas garanti.',
        modalContentTextMartine:
            'Les informations que vous avez entrées sont enregistrées. Si vous quittez, vous devez <strong>revenir avant le {{date, D}}{{suffixe}}\xa0{{date, MMMM\xa0YYYY}}</strong> pour confirmer l’option de renouvellement.'
    }
};
