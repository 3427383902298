import { mockInstance, withDelay } from '../../../../api/axiosInstance.mock';
import { QUERY_PARAM_USER_MODE, URLToURLSearchParams } from '../../../../utils';

import { BASCULER_VERS_ASSURANCE_END_POINT } from '../../apiEndPoints';
import { dataCoEmprunteur1 as sessionMockData } from '../../contexte/useSessionApi/useSessionApi.mock';

const endPoint = BASCULER_VERS_ASSURANCE_END_POINT.replace(
    /{idDemande}/g,
    String(sessionMockData?.contextePret?.idDemande)
);

// eslint-disable-next-line
const reponseSansRedirect = {
    redirectUrl: null
};

const defineMockData = (userMockParam: string): any => {
    if (!userMockParam) return reponseSansRedirect;

    const target = window.location.href.endsWith('/validation')
        ? '/eveilAssurance'
        : '/validation';
    const actualUrl =
        window.location.href.substring(
            0,
            window.location.href.lastIndexOf('/')
        ) + target;

    switch (userMockParam) {
        case 'emprunteurUniqueAjoutAssuranceSignatureIntegreeParcours2':
            return {
                redirectUrl: actualUrl
            };
        default:
            return reponseSansRedirect;
    }
};

export const useAssuranceApiMock = (): any => {
    // var
    const query = URLToURLSearchParams();
    const userMockParam = query.get(QUERY_PARAM_USER_MODE);

    mockInstance
        .onGet(endPoint)
        .reply(withDelay([200, defineMockData(userMockParam)], 200));
};
