import React from 'react';
import { useTranslation } from 'react-i18next';

import { Button, ButtonVariant } from '../../styles';
import { NavigationPlacement } from '../../components';
import { useRoutes } from '../../hooks';

export const Navigation: React.FC<unknown> = () => {
    const [t] = useTranslation('Legal');
    const { goToRoute, getRoute } = useRoutes();

    const handleCancel = () => {
        goToRoute(getRoute().prevRoute);
    };

    return (
        <>
            <NavigationPlacement
                renderSaveButton={
                    <Button
                        id={'QA_test_navigation_back_button'}
                        variant={ButtonVariant.primary}
                        dataCy="qa-navigation-button-back"
                        dataTestId={'navigation-back-button'}
                        onClick={handleCancel}
                        fullWidth="xs"
                    >
                        {t('quitter')}
                    </Button>
                }
            ></NavigationPlacement>
        </>
    );
};
