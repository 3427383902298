import { useQuery, UseQueryResult } from 'react-query';

import {
    axiosInstance,
    getDynamicHeaders,
    reactCachedQueryOptions
} from '../../../../api';

export interface IToggle {
    clavardageActif: boolean;
    texteLoi14: boolean;
}

export const END_POINT = '/contextes/toggle';

const fetch = async () => {
    const { data } = await axiosInstance.get(END_POINT, getDynamicHeaders());

    return data;
};

export function useToggleApi(
    isEnable: boolean
): UseQueryResult<IToggle, Error> {
    return useQuery<IToggle, Error>('toggle', () => fetch(), {
        ...reactCachedQueryOptions(),
        enabled: isEnable
    });
}
