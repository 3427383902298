import React from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import { Box } from '@material-ui/core';

import { TypeAssurance, TypeOffreTarification } from '../../../../hooks';
import {
    Button,
    Spacer,
    Typography,
    Variant,
    Police,
    Article,
    ButtonVariant,
    ContainerMui,
    ColorHex,
    ThemeTag,
    Tag,
    BorderRadiusMui
} from '../../../../styles';
import { AppelerCaisse, DetailsOffreModalOffre } from '../../../../components';
import {
    getCardMinHeight,
    getCardTitleMarginBottomNoPromo
} from './Card.utils';

interface CardProps {
    offre: any;
    isCoemprunteur: boolean;
    typeAssurance: TypeAssurance;
    isBottomCard: boolean;
    isTitreSurDeuxLigne: boolean;
    isVoisinPromo: boolean;
    isVoisinTitreSurDeuxLigne: boolean;
    onSelectOffer: () => void;
}

const useStyles = makeStyles(() =>
    createStyles({
        note: {
            fontSize: '0.875rem',
            fontWeight: 'normal'
        },
        variableProtegeNote: {
            fontSize: '0.875rem',
            fontWeight: 'normal'
        }
    })
);

// Bouton Choisir offre
const ButtonBase = ({ altText, onClick, idOffre }) => {
    const [t] = useTranslation(['Card']);

    return (
        <Button
            fullWidth="xs"
            onClick={onClick}
            variant={ButtonVariant.primary}
            srOnlySuffix={
                t('offerChooseSRPrefix') + altText + t('offerChooseSRSuffix')
            }
            dataTestId={idOffre + '_choisir_bouton'}
        >
            {t('offerChooseLinkLabel')}
        </Button>
    );
};

const DisabledOfferCard: React.FC<any> = () => {
    const [t] = useTranslation(['Card']);

    return (
        <Box>
            <Typography variant={Variant.p} article={Article.a3}>
                {t('offerDisabled')}
            </Typography>
            <Spacer />
            <AppelerCaisse />
        </Box>
    );
};

const CardOfferPayment = ({
    typeAssurance,
    isCoemprunteur,
    paiement,
    frequence,
    hasInterestNote,
    showVariableProtegePaymentNote
}) => {
    const [t] = useTranslation(['Card']);
    const classes = useStyles();

    function renderSubtitle() {
        const paiementSuffixe = isCoemprunteur ? 'Coemprunteur' : '';
        switch (typeAssurance) {
            case TypeAssurance.AP:
                return t('offerPaymentAp' + paiementSuffixe);
            case TypeAssurance.AP_MAT:
                return t('offerPaymentApMat' + paiementSuffixe);
            case TypeAssurance.NONE:
                return t('offerPayment' + paiementSuffixe);
            default:
                return t('offerPayment' + paiementSuffixe);
        }
    }

    return (
        <Box marginBottom="1.5rem">
            <Typography
                variant={Variant.span}
                article={Article.a3}
                marginBottom="1rem"
            >
                {renderSubtitle()}
            </Typography>
            <Typography variant={Variant.h6}>
                {t('Number:currency', {
                    value: paiement
                }) +
                    ' ' +
                    t(frequence)}
                {hasInterestNote && (
                    <span className={classes.note}>
                        {' ' + t('offerPaymentNotePrefix')}
                        <sup>{t('offerPaymentNoteSup')}</sup>
                        {t('offerPaymentNoteSuffix')}
                    </span>
                )}
                {showVariableProtegePaymentNote && (
                    <p className={classes.variableProtegeNote}>
                        {t('offerPaymentNoteVariableProtege')}
                    </p>
                )}
            </Typography>
        </Box>
    );
};

const CardOfferInterest = ({
    interestRate,
    primeRate,
    effectiveRate,
    ceilingRate,
    hasPrimeNote,
    hasInterestNote,
    showVariableProtegeInterestNote,
    interestRebate
}) => {
    const [t] = useTranslation(['Card']);
    const classes = useStyles();

    return (
        <Box marginBottom="1.5rem">
            <Typography variant={Variant.h6}>
                {t('offerInterest') + ' '}
                <span
                    style={{
                        color: '#00874e',
                        fontSize: '20px',
                        marginLeft: '0.2rem'
                    }}
                >
                    {t('Number:percent', {
                        value: showVariableProtegeInterestNote
                            ? effectiveRate
                            : interestRate
                    })}
                </span>
            </Typography>
            {hasInterestNote && (
                <p className={classes.note}>
                    {t('offerInterestNote_part1')}
                    <sup>{t('offerInterestNote_part2')}</sup>
                    {t('offerInterestNote_part3')}
                    {t('Number:percent', {
                        value: Math.abs(interestRebate)
                    })}
                    {t('offerInterestNote_part4')}
                </p>
            )}
            {hasPrimeNote && (
                <p className={classes.note}>
                    {t('offerPrimeNotePrefix')}
                    {t('Number:percent', {
                        value: primeRate
                    })}
                </p>
            )}
            {showVariableProtegeInterestNote && (
                <p className={classes.variableProtegeNote}>
                    {t('offerInterestNoteCeilingRate')}
                    {t('Number:percent', {
                        value: ceilingRate
                    })}
                    <br></br>
                    {t('offerInterestNotePrimeRate')}
                    {t('Number:percent', {
                        value: primeRate
                    })}
                </p>
            )}
        </Box>
    );
};

const CardFooter = ({
    valeurDureeTerme,
    frequenceDureeTerme,
    idOffre,
    isDisabled,
    typeOffre,
    tauxRabaisSubsequent,
    onClick,
    offerTitle
}) => {
    return (
        <>
            {!isDisabled && (
                <Box
                    style={{
                        position: 'absolute',
                        bottom: 0,
                        marginTop: 'auto',
                        padding: '0 1.5rem 1.5rem 1.5rem'
                    }}
                >
                    <Box
                        style={{
                            marginLeft: '-1.5rem'
                        }}
                    >
                        <DetailsOffreModalOffre
                            valeurDureeTerme={valeurDureeTerme}
                            frequenceDureeTerme={frequenceDureeTerme}
                            typeOffre={typeOffre}
                            tauxRabaisSubsequent={tauxRabaisSubsequent}
                            titreOffre={offerTitle}
                            idOffre={idOffre}
                        />
                    </Box>
                    <Spacer />
                    <ButtonBase
                        onClick={onClick}
                        altText={offerTitle}
                        idOffre={idOffre}
                    />
                </Box>
            )}
        </>
    );
};

const CardContent = ({
    offerTitle,
    isCoemprunteur,
    isDisabled,
    primeRate,
    effectiveRate,
    ceilingRate,
    hasPrimeNote,
    interestRate,
    interestRebate,
    hasInterestNote,
    typeAssurance,
    paiement,
    frequence,
    showVariableProtegeInterestNote,
    typeOffreTarification,
    isTitreSurDeuxLigne,
    isVoisinTitreSurDeuxLigne,
    isVoisinPromo
}) => {
    const [t] = useTranslation(['Card']);
    const isPromotion =
        typeOffreTarification === TypeOffreTarification.PROMOTION;

    const CardOfferTitle = () => {
        return (
            <Box
                marginBottom={
                    isPromotion
                        ? '0.75rem'
                        : getCardTitleMarginBottomNoPromo(
                              isTitreSurDeuxLigne,
                              isVoisinTitreSurDeuxLigne,
                              isVoisinPromo
                          )
                }
            >
                <Typography
                    variant={Variant.h4}
                    police={Police.bold}
                    marginBottom="0.75rem"
                >
                    {offerTitle}
                </Typography>
                {isPromotion && (
                    <Tag label={t('tagPromotion')} theme={ThemeTag.PROMOTION} />
                )}
            </Box>
        );
    };

    return (
        <>
            <Spacer xs={6} />
            <Box color="#2f2f2f" padding="0 1.5rem 0rem 1.5rem">
                <CardOfferTitle />
                {isDisabled ? (
                    <DisabledOfferCard />
                ) : (
                    <>
                        <CardOfferInterest
                            interestRate={interestRate}
                            primeRate={primeRate}
                            effectiveRate={effectiveRate}
                            ceilingRate={ceilingRate}
                            hasPrimeNote={hasPrimeNote}
                            hasInterestNote={hasInterestNote}
                            showVariableProtegeInterestNote={
                                showVariableProtegeInterestNote
                            }
                            interestRebate={interestRebate}
                        />
                        <CardOfferPayment
                            typeAssurance={typeAssurance}
                            isCoemprunteur={isCoemprunteur}
                            paiement={paiement}
                            frequence={frequence}
                            hasInterestNote={hasInterestNote}
                            showVariableProtegePaymentNote={
                                showVariableProtegeInterestNote
                            }
                        />
                    </>
                )}
            </Box>
        </>
    );
};

export const Card: React.FC<CardProps> = ({
    offre,
    isCoemprunteur,
    typeAssurance,
    isBottomCard,
    isTitreSurDeuxLigne,
    isVoisinPromo,
    isVoisinTitreSurDeuxLigne,
    onSelectOffer
}) => {
    const isDisabled = offre?.isDisabled || !offre?.paiement;

    return (
        <ContainerMui
            boxShadow={3}
            backgroundColor={
                isDisabled ? ColorHex.lightSystem1 : ColorHex.lightWhite
            }
            borderRadius={BorderRadiusMui.sm}
            padding="none"
            noSpacer={isBottomCard}
            spacerDistance={6}
            spacerDistanceMobile={6}
            style={{ position: 'relative', minHeight: getCardMinHeight() }}
        >
            <CardContent
                isDisabled={isDisabled}
                isCoemprunteur={isCoemprunteur}
                offerTitle={offre?.offerTitle}
                primeRate={offre?.primeRate}
                effectiveRate={offre?.effectiveRate}
                ceilingRate={offre?.ceilingRate}
                hasPrimeNote={offre?.hasPrimeNote}
                interestRate={offre?.interestRate}
                interestRebate={offre?.tauxRabaisSubsequent}
                hasInterestNote={offre?.hasInterestNote}
                typeAssurance={typeAssurance}
                paiement={offre?.paiement}
                frequence={offre?.frequence}
                showVariableProtegeInterestNote={
                    offre?.showVariableProtegeInterestNote
                }
                typeOffreTarification={offre?.typeOffreTarification}
                isTitreSurDeuxLigne={isTitreSurDeuxLigne}
                isVoisinPromo={isVoisinPromo}
                isVoisinTitreSurDeuxLigne={isVoisinTitreSurDeuxLigne}
            />
            <CardFooter
                idOffre={offre?.idOffre}
                valeurDureeTerme={offre?.valeurDureeTerme}
                frequenceDureeTerme={offre?.frequenceDureeTerme}
                isDisabled={offre?.isDisabled || !offre?.paiement}
                onClick={onSelectOffer}
                typeOffre={offre?.typeOffre}
                tauxRabaisSubsequent={offre?.tauxRabaisSubsequent}
                offerTitle={offre?.offerTitle}
            />
        </ContainerMui>
    );
};
