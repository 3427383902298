export default {
    en: {
        titre: 'Learn about insurance costs',
        buttonClose: 'Close',
        labelCoutProtectionActuelle: 'Cost of current coverage',
        textCoutProtectionActuelle:
            'The cost of insurance is an additional interest rate that’s applied to the current rate of your mortgage.',
        labelPrimeAssurance: 'Your premium decreases',
        textPrimeAssurance:
            'Throughout the term, as your mortgage balance gets smaller, your insurance premium goes down too.',
        labelCasInvalidite: 'In the event of disability',
        textCasInvalidite:
            "As long as you're disabled and under 70, disability insurance covers the payments you'd have to make to pay off the balance of your mortgage, based on the percentages you've selected."
    },
    fr: {
        titre: "Comprendre le coût de l'assurance",
        buttonClose: 'Fermer',
        labelCoutProtectionActuelle: 'Coût pour la protection actuelle',
        textCoutProtectionActuelle:
            "Le coût de l'assurance correspond à un taux d'intérêt additionnel ajouté au taux du prêt hypothécaire.",
        labelPrimeAssurance: 'Une prime d’assurance qui diminue',
        textPrimeAssurance:
            'Au cours du terme, plus le solde de votre prêt hypothécaire diminue, plus votre prime d’assurance diminue elle aussi.',
        labelCasInvalidite: 'En cas d’invalidité',
        textCasInvalidite:
            'L’assurance invalidité couvre, selon les pourcentages choisis, les paiements requis pour rembourser le solde de votre prêt hypothécaire. Ceci s’applique tant et aussi longtemps que vous êtes invalide et que vous avez moins de 70 ans.'
    }
};
