import React, { ReactNode } from 'react';

export enum LinkActionVariant {
    secondary = 'secondary',
    primary = 'primary'
}

export enum LinkActionType {
    external = 'external',
    return = 'return'
}

export enum LinkActionTheme {
    green = 'green',
    pale = 'pale',
    somber = 'somber'
}

interface ILinkActionProps {
    children: ReactNode;
    href?: string;
    variant?: LinkActionVariant;
    dataTestId?: string;
    type?: LinkActionType;
    theme?: LinkActionTheme;
    ariaLabel?: string;
    onClick?: (event: any) => void;
}

export const LinkAction: React.FC<ILinkActionProps> = ({
    children,
    href = '',
    variant,
    dataTestId,
    type,
    theme,
    ariaLabel,
    onClick
}) => {
    const handleLinkClick = (event: any) => {
        if (typeof onClick === typeof Function) {
            onClick(event);
        }
    };

    return (
        // @ts-ignore
        <dsd-link-action
            href={href}
            onClick={handleLinkClick}
            data-testid={dataTestId}
            variant={variant}
            theme={theme}
            type={type}
        >
            <a aria-label={ariaLabel} href={href}>
                {children}
            </a>
            {/* @ts-ignore */}
        </dsd-link-action>
    );
};
