import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { isFrequenceEqual } from '../../utils';
import { useRoutes, getPageName, goQuitterParcours } from '../../hooks';
import {
    sendEvent,
    EVENT_CATEGORIES,
    EVENT_ACTIONS,
    EVENT_LABELS
} from '../../features';
import {
    NavigationModal,
    NavigationPlacement,
    SaveAndLeaveModal
} from '../../components';
import { Button, ButtonVariant } from '../../styles';

export interface IModalitesNavigationProps {
    pretAdmissibleAssurance: boolean;
    offreSimuleeModalite: boolean;
    onClickNext: (offreASauvegarder: any) => void;
}

export const Navigation: React.FC<IModalitesNavigationProps> = ({
    pretAdmissibleAssurance,
    offreSimuleeModalite: offreSimulee,
    onClickNext
}) => {
    const dispatch = useDispatch();
    const [t] = useTranslation(['Modalites']);
    const { getRoute, goToRoute } = useRoutes();

    // State
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isModalOpened, setIsModalOpened] = useState(false);

    // Store
    const offreModifiee = useSelector(
        (state: any) => state.modalites.offreModifiee
    );
    const offreBase = useSelector((state: any) => state.modalites.offreBase);
    const offresList = useSelector((state: any) => state.modalites.offresList);

    // Send cyber & leave
    const leaveApp = () => {
        const pageCourante = getPageName();

        if (pageCourante) {
            dispatch(
                sendEvent({
                    category: EVENT_CATEGORIES.RHN,
                    action: EVENT_ACTIONS.CLIC,
                    label: EVENT_LABELS.ENREGISTRER_ET_QUITTER + pageCourante
                })
            );
        }

        goQuitterParcours();
    };

    const handleCancel = () => {
        // Open confirmation modal
        setIsModalOpened(true);
    };

    const handleClicModalClose = (shouldStayHere: boolean) => {
        // Close confirmation modal
        setIsModalOpened(false);

        if (!shouldStayHere) {
            // Send cyber & leave
            leaveApp();
        }
    };

    const handleBack = () => {
        dispatch(
            sendEvent({
                category: EVENT_CATEGORIES.RHN,
                action: EVENT_ACTIONS.CLIC,
                label: EVENT_LABELS.PRECEDENT + getPageName()
            })
        );

        // si admissible assurance + pas d'offre simulee actuellement -> aller à la page éveil sinon page offre
        goToRoute(
            pretAdmissibleAssurance && !offreSimulee
                ? getRoute().prevRouteConditional
                : getRoute().prevRoute
        );
    };

    const handleConfirm = () => {
        // si le membre continue sans attendre la fin du chargement des calculs
        if (offreModifiee === null) {
            onClickNext(null);
            return;
        }

        // selon les conditions si on a change ou non le paiement sans calculer, on affiche la modale
        const paiementAmount = Number(offreModifiee?.paiement);
        const isPaymentValid =
            offreModifiee?.isCalculer ||
            offreModifiee?.isAcceleree ||
            paiementAmount ===
                Number(offreModifiee?.paiementCalculerSauvegarder) ||
            paiementAmount === Number(offreModifiee?.borneMinimale);

        if (!isPaymentValid) {
            setIsModalOpen(true);

            return;
        }

        handleNext(offreModifiee);
    };

    const handleModalCloseNoSave = () => {
        // Reset le paiement par le dernier paiement calculer (sauvegarder) ou
        // par la valeur par defaut
        const offreDeBase = offresList.find(
            ({ frequenceRemboursement }) =>
                frequenceRemboursement === offreModifiee.frequenceRemboursement
        );
        setIsModalOpen(false);
        handleNext(offreDeBase);
    };

    const handleNext = (offreASauvegarder) => {
        if (
            !isFrequenceEqual(
                offreBase.frequenceRemboursement,
                offreASauvegarder.frequenceRemboursement
            )
        ) {
            goToRoute(getRoute().nextRouteConditional1);
        } else {
            onClickNext(offreASauvegarder);
        }
    };

    return (
        <>
            <SaveAndLeaveModal
                isOpen={isModalOpened}
                onNext={() => handleClicModalClose(false)}
                onCancel={() => handleClicModalClose(true)}
            />
            <NavigationModal
                isOpen={isModalOpen}
                modaleTitle={t('modalCalculerTitle')}
                modaleMessage={t('modalCalculerContent')}
                modaleAcceptButton={t('modalCalculerAcceptButton')}
                modaleStayButton={t('modalCalculerStayButton')}
                onNext={handleModalCloseNoSave}
                onCancel={() => setIsModalOpen(false)}
            />
            <NavigationPlacement
                renderBackButton={
                    <Button
                        id={'QA_test_navigation_back_button'}
                        variant={ButtonVariant.secondary}
                        dataCy="qa-navigation-button-back"
                        dataTestId={'navigation-back-button'}
                        onClick={handleBack}
                        fullWidth="xs"
                    >
                        {t('back')}
                    </Button>
                }
                renderSaveButton={
                    <Button
                        id={'QA_test_navigation_quit_button'}
                        variant={ButtonVariant.secondary}
                        dataCy="qa-navigation-button-quit"
                        dataTestId={'navigation-quit-button'}
                        onClick={handleCancel}
                        fullWidth="xs"
                    >
                        {t('cancel')}
                    </Button>
                }
                renderNextButton={
                    <Button
                        id={'QA_test_navigation_next_button'}
                        variant={ButtonVariant.primary}
                        dataCy="qa-navigation-button-next"
                        dataTestId={'navigation-next-button'}
                        onClick={handleConfirm}
                        fullWidth="xs"
                    >
                        {t('next')}
                    </Button>
                }
            />
        </>
    );
};
