import React from 'react';
import { useTranslation } from 'react-i18next';

import { Typography, Variant, Article, Modal, DateText } from '../../styles';
import { getFormattedDate } from '../../utils';

export interface ISaveAndLeaveModalProps {
    isOpen?: boolean;
    signatureVariant?: boolean;
    isMartine?: boolean;
    dateLimiteApprobationMartine?: string;
    onNext?: () => void;
    onCancel?: () => void;
}

export const SaveAndLeaveModal: React.FC<ISaveAndLeaveModalProps> = ({
    isOpen,
    signatureVariant = false,
    isMartine,
    dateLimiteApprobationMartine,
    onNext,
    onCancel
}) => {
    const [t] = useTranslation(['SaveAndLeaveModal']);
    const formattedDateLimiteApprobationMartine = new Date(
        getFormattedDate(dateLimiteApprobationMartine || '', '/')
    );

    return (
        <>
            {isOpen && (
                <Modal
                    show={true}
                    title={t('modalContentTitle')}
                    buttonPrimaryLabel={t('modalContentLeaveButton')}
                    buttonSecondaryLabel={
                        signatureVariant
                            ? t('modalContentStaySignButton')
                            : t('modalContentStayButton')
                    }
                    onClickPrimaryButton={onNext}
                    onClickSecondaryButton={onCancel}
                >
                    {isMartine ? (
                        <Typography variant={Variant.p}>
                            <DateText
                                translationKey="SaveAndLeaveModal:modalContentTextMartine"
                                date={formattedDateLimiteApprobationMartine}
                            >
                                {{
                                    date: formattedDateLimiteApprobationMartine
                                }}
                            </DateText>
                        </Typography>
                    ) : (
                        <Typography variant={Variant.p} article={Article.a3}>
                            {t('modalContentText')}
                        </Typography>
                    )}
                </Modal>
            )}
        </>
    );
};
