import { AxiosResponse } from 'axios';
import { useMutation } from 'react-query';

import { SIGNATURE_CONFIRMATION_ENDPOINT } from '../../apiEndPoints';
import {
    axiosInstance,
    reactQueryOptions,
    IMutationResult,
    getDynamicHeaders
} from '../../../../api';

interface ISignatureConfirmation {
    idDemande: number;
    idContrat: string;
}

const putSignatureConfirmation = async (
    param: ISignatureConfirmation
): Promise<AxiosResponse<any>> => {
    const endPoint = SIGNATURE_CONFIRMATION_ENDPOINT.replace(
        /{idDemande}/g,
        String(param?.idDemande)
    );

    const { data: response } = await axiosInstance.put(
        endPoint,
        { idContrat: param?.idContrat },
        getDynamicHeaders()
    );

    return response;
};

export const useSignatureConfirmationApi = (): IMutationResult => {
    return useMutation(putSignatureConfirmation, {
        ...reactQueryOptions()
    });
};
