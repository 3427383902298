import {
    useSessionApiMock,
    useToggleApiMock,
    usePreselectionnerOffreApiMock,
    useStatutEmprunteurApiMock,
    useRenouvellementApiMock,
    usePretApiMock,
    useEnteteApiMock,
    useAssuranceApiMock,
    useObtenirDemandesApiMock,
    useOffresApiMock,
    useQuestionsApiMock,
    useListeConventionsApiMock,
    useSauvegarderModalitesOffreApiMock,
    useSelectionnerOffreCoemprunteurApiMock,
    useObtenirResumeApiMock,
    useRefuserOffreApiMock,
    useResetOffreChoisieApiMock,
    useConfirmerConventionApiMock,
    useSoldeFinTermeApiMock,
    useDatesProchainPaiementApiMock,
    useFrequenceAccelereeApiMock,
    usePaiementApiMock,
    useSignatureAdmissibiliteApiMock,
    useSignatureApiMock,
    useSignatureStatutApiMock,
    useSignatureAbandonneeApiMock,
    useSignatureConfirmationApiMock,
    useConfirmerOffreApiMock,
    useCodeAvisApiMock,
    useActualiserOffreGuyApiMock
} from '../hooks';

export function useMock(): any {
    useSessionApiMock();
    useToggleApiMock();
    useRenouvellementApiMock();
    useEnteteApiMock();
    useAssuranceApiMock();
    useObtenirDemandesApiMock();
    useOffresApiMock();
    usePreselectionnerOffreApiMock();
    useCodeAvisApiMock();
    useStatutEmprunteurApiMock();
    usePretApiMock();
    useDatesProchainPaiementApiMock();
    useQuestionsApiMock();
    useListeConventionsApiMock();
    useSauvegarderModalitesOffreApiMock();
    useSelectionnerOffreCoemprunteurApiMock();
    useObtenirResumeApiMock();
    useRefuserOffreApiMock();
    useResetOffreChoisieApiMock();
    useConfirmerConventionApiMock();
    useSoldeFinTermeApiMock();
    useFrequenceAccelereeApiMock();
    usePaiementApiMock();
    useSignatureAdmissibiliteApiMock();
    useSignatureApiMock();
    useSignatureStatutApiMock();
    useSignatureAbandonneeApiMock();
    useConfirmerOffreApiMock();
    useSignatureConfirmationApiMock();
    useActualiserOffreGuyApiMock();
}
