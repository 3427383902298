import { mockInstance, withDelay } from '../../../../api/axiosInstance.mock';

import { ISignature } from './useSignatureApi';
import { SIGNATURE_ENDPOINT } from '../../apiEndPoints';
import { dataCoEmprunteur1 as sessionMockData } from '../../contexte/useSessionApi/useSessionApi.mock';

export const data: ISignature = {
    url: 'https://www.badUrlDesjardinsMock.com',
    idContrat: '8759dfa9-9280-4aa2-afd7-82e4eabb740f'
};

const endPoint = SIGNATURE_ENDPOINT.replace(
    /{idDemande}/g,
    String(sessionMockData?.contextePret?.idDemande)
);

export const useSignatureApiMock = (): any => {
    mockInstance.onPost(endPoint).reply(withDelay([200, data], 200));
};
