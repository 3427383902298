import { useQuery, UseQueryResult } from 'react-query';

import {
    axiosInstance,
    getDynamicHeaders,
    reactQueryOptions
} from '../../../../api';
import { SIGNATURE_ADMISSIBILITE_ENDPOINT } from '../../apiEndPoints';

export interface ISignatureAdmissibilite {
    admissible: boolean;
}

const fetch = async (idDemande: number) => {
    const endPoint = SIGNATURE_ADMISSIBILITE_ENDPOINT.replace(
        /{idDemande}/g,
        String(idDemande)
    );

    const { data } = await axiosInstance.get(endPoint, getDynamicHeaders());

    return data;
};

export function useSignatureAdmissibiliteApi(
    idDemande: number = undefined,
    isEnabled = true
): UseQueryResult<ISignatureAdmissibilite, Error> {
    return useQuery<ISignatureAdmissibilite, Error>(
        'signatureAdmissibilite',
        () => fetch(idDemande),
        {
            ...reactQueryOptions(),
            enabled: !!idDemande && !!isEnabled
        }
    );
}
