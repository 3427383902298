export default {
    en: {
        // Enregistrer et Quitter
        modalSaveAndLeaveSigneeTitle: 'Your documents have been signed',
        modalSaveAndLeaveSigneeDescription:
            'Please wait. You’ll be able to continue your mortgage renewal in just a moment.',
        modalSaveAndLeaveSigneePrimaryButton: 'Stay on page',
        modalSaveAndLeaveSigneeSecondaryButton: 'Save and leave'
    },
    fr: {
        // Enregistrer et Quitter
        modalSaveAndLeaveSigneeTitle: 'Vos documents sont signés',
        modalSaveAndLeaveSigneeDescription:
            'Veuillez patienter un instant pour pouvoir continuer le renouvellement de votre prêt.',
        modalSaveAndLeaveSigneePrimaryButton: 'Rester sur la page',
        modalSaveAndLeaveSigneeSecondaryButton: 'Enregistrer et quitter'
    }
};
