import React from 'react';
import { Box } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

import {
    ColorHex,
    GridRow,
    Police,
    Typography,
    Variant,
    Headings
} from '../../../styles';
import { HeadingProvider } from '../../../context';
import { ColorfulContainer } from '../colorfulContainer/ColorfulContainer';

const headingMapping = {
    h2: Headings.h4
};

interface InteretNonPayeBoxProps {
    date: string;
    montantTotalInteretRetard: number;
}

export const InteretNonPayeBox: React.FC<InteretNonPayeBoxProps> = ({
    date,
    montantTotalInteretRetard
}) => {
    const [t] = useTranslation(['InteretRetard']);

    return (
        <HeadingProvider value={headingMapping}>
            <ColorfulContainer
                title={
                    t('interet_non_paye_box_titre') +
                    t('Date:full', {
                        value: date
                    })
                }
                color={ColorHex.yellow1}
                titleColor={ColorHex.darkYellow1}
            >
                <GridRow>
                    <Box marginTop="0.2rem" marginBottom="0.2rem">
                        <Typography
                            variant={Variant.p}
                            police={Police.bold}
                            className="dsd-t-4"
                        >
                            {t('Number:currency', {
                                value: montantTotalInteretRetard
                            })}
                        </Typography>
                    </Box>
                </GridRow>
                <GridRow>
                    <Typography variant={Variant.p} hideMarginBottom>
                        {t('interet_non_paye_box_description')}
                    </Typography>
                </GridRow>
            </ColorfulContainer>
        </HeadingProvider>
    );
};
