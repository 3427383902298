export default {
    en: {
        title: 'Rate selector',
        description:
            'Answer 2 simple questions to see the rates that meet your needs.',
        // Questions 1
        question1Title: '* What are you planning to do?',
        question1Choice1: 'Sell the property within 2 years',
        question1Choice2: 'Keep the property longer than 2 years',
        question1Choice3: 'You’re not sure',
        // Questions 2
        question2Title: '* What rate type are you looking for?',
        question2Choice1: 'A fixed rate to avoid interest rate fluctuations',
        question2Choice2:
            'A variable rate to take advantage of rate cuts, even if there’s a chance of an increase',
        question2Choice3: 'You’re not sure',
        boutonConfirmer: 'Continue',
        boutonFermer: 'Close',
        boutonFermerSr: 'Close rate selector'
    },
    fr: {
        title: 'Sélecteur de taux',
        description:
            'Répondez à 2 questions simples pour voir les taux selon vos besoins.',
        // Questions 1
        question1Title: '* Que prévoyez-vous?',
        question1Choice1: 'Vendre la propriété dans 2 ans',
        question1Choice2: 'Garder la propriété plus de 2 ans',
        question1Choice3: 'Vous ne savez pas',
        // Questions 2
        question2Title: '* Quel type de taux cherchez-vous?',
        question2Choice1:
            'Un taux fixe pour éviter les variations de taux hypothécaire',
        question2Choice2:
            'Un taux variable pour profiter des variations de taux à la baisse malgré la possibilité d’augmentation',
        question2Choice3: 'Vous ne savez pas',
        boutonConfirmer: 'Continuer',
        boutonFermer: 'Fermer',
        boutonFermerSr: 'Fermer le sélecteur de taux'
    }
};
