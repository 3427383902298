import React from 'react';

interface IProgressProps {
    title: string;
    percentage: number; // [2, 98]
    subtitle?: string;
    currentStep: number;
    steps?: number;
    hiddenTitle?: boolean;
    dark?: boolean;
}

export const Progress: React.FC<IProgressProps> = ({
    title,
    percentage,
    subtitle = ' ',
    currentStep = 0,
    steps = 0,
    hiddenTitle = false,
    dark = false
}) => (
    // @ts-ignore
    <dsd-progress
        percentage={percentage}
        current-step={currentStep}
        steps={steps}
        hidden-title={hiddenTitle}
        dark={dark}
    >
        <span slot="title">{title}</span>
        <span slot="subtitle">{subtitle}</span>
        {/* @ts-ignore */}
    </dsd-progress>
);
