import React from 'react';
import { createTheme } from '@material-ui/core/styles';
import themePalette from './themePalette.js';
import AvertaRegular from '../fonts/averta-regular.woff2';
import InfoIcon from '@material-ui/icons/Info';
import ErrorIcon from '@material-ui/icons/Error';
import WarningIcon from '@material-ui/icons/Warning';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';

const avertaRegular = {
    fontFamily: 'Averta',
    fontStyle: 'normal',
    fontDisplay: 'swap',
    fontWeight: 'normal',
    src: `
      local('Averta'),
      local('Averta-Regular'),
      url(${AvertaRegular}) format('woff2')
    `
};

let theme = createTheme({});

theme = {
    ...theme,
    palette: themePalette,
    typography: {
        /* eslint-disable-next-line */
        pxToRem: (size) => `${size / 16}rem`,
        fontFamily: ['Averta,Arial,Helvetica,sans-serif']
    },
    overrides: {
        MuiCssBaseline: {
            '@global': {
                '@font-face': [avertaRegular]
            }
        },
        MuiChip: {
            root: {
                margin: '.125rem 0.5rem 1rem 0',
                minHeight: theme.typography.pxToRem(24),
                fontSize: '.875rem',
                borderRadius: '.9375rem',
                whiteSpace: 'wrap'
            },
            label: {
                whiteSpace: 'wrap',
                lineHeight: 1
            },
            icon: {
                fontSize: '.9rem',
                color: 'inherit'
            },
            colorPrimary: {
                backgroundColor: themePalette.primary.main,
                color: themePalette.primary.contrastText
            },
            colorSecondary: {
                backgroundColor: themePalette.secondary.light,
                color: themePalette.secondary.dark
            }
        },
        MuiCard: {
            root: {
                overflow: 'visible',
                wordWrap: 'break-word',
                backgroundColor: themePalette.grey[100],
                marginBottom: '1.7rem',
                boxShadow: '0 5px 3rem 0 rgba(0,0,0,.2)',
                fontSize: '1rem',
                fontWeight: 400,
                lineHeight: 1.5,
                color: themePalette.grey[800],
                borderRadius: '16px',
                borderWidth: 0,
                borderStyle: 'solid',
                borderColor: 'transparent',
                flexFlow: 'column nowrap',
                display: 'flex',
                width: '100%',
                minHeight: theme.typography.pxToRem(350),
                [theme.breakpoints.up('sm')]: {
                    minHeight: theme.typography.pxToRem(300)
                },
                [theme.breakpoints.up('md')]: {
                    minHeight: theme.typography.pxToRem(250)
                }
            }
        },
        MuiCardHeader: {
            root: {
                borderTopLeftRadius: '.625rem',
                borderTopRightRadius: '.625rem',
                borderBottomLeftRadius: 0,
                borderBottomRightRadius: 0,
                overflow: 'hidden',
                backgroundColor: '#f0f0f0',
                borderColor: '#f0f0f0',
                padding: '.25rem 1.125rem .25rem',
                minHeight: '2.69rem',
                borderStyle: 'solid',
                borderLeftWidth: theme.typography.pxToRem(2),
                borderTopWidth: theme.typography.pxToRem(2),
                borderRightWidth: theme.typography.pxToRem(2),
                borderBottomWidth: 0,
                textAlign: 'center'
            },
            title: {
                fontSize: '.75rem',
                color: themePalette.primary.contrastText,
                marginBottom: 0
            },
            subheader: {
                fontSize: '.75rem',
                color: themePalette.primary.contrastText
            }
        },
        MuiCardContent: {
            root: {
                padding: '.69rem 1rem 0 1rem',
                '&:last-child': {
                    paddingBottom: '1rem'
                },
                '&+div': {
                    marginTop: 'auto',
                    padding: '0 1rem 1rem 1rem'
                }
            }
        },
        MuiAccordionSummary: {
            root: {
                fontFamily: ['Arial,Helvetica,sans-serif']
            }
        },
        MuiIconButton: {
            root: {
                borderRadius: '.5rem'
            }
        },
        MuiButton: {
            root: {
                minWidth: '4.375rem',
                borderRadius: '.5rem',
                textDecoration: 'none',
                textAlign: 'center',
                textTransform: 'none',
                border: `2px ${themePalette.primary.main}`,
                '&:hover': {},
                '&:not(.MuiButton-text)': {
                    boxShadow: '0 2px 3px 0 rgba(0,0,0,.1)',
                    fontWeight: 700
                }
            },
            sizeLarge: {
                fontSize: theme.typography.pxToRem(16),
                padding: '.75rem 1.5rem',
                lineHeight: 1.3335
            },
            outlinedPrimary: {
                '&:hover': {
                    backgroundColor: themePalette.primary.dark,
                    color: themePalette.primary.contrastText
                }
            },
            outlinedSecondary: {
                '&:hover': {
                    backgroundColor: themePalette.secondary.hover
                }
            },
            textPrimary: {
                fontWeight: 'normal',
                textDecoration: 'underline',
                '&:hover': {
                    backgroundColor: 'transparent'
                }
            },
            textSecondary: {
                fontWeight: 'normal',
                color: themePalette.secondary.main,
                textDecoration: 'underline',
                '&:hover': {
                    backgroundColor: 'transparent'
                }
            }
        },
        MuiAlert: {
            root: {
                borderRadius: '8px',
                marginBottom: '1rem',
                padding: '8px 16px',
                width: 'fit-content'
            },
            standardError: {
                backgroundColor: '#fdd8d8',
                border: '2px solid #FFA5A5',
                color: '#960e02',
                '& .MuiAlert-icon': {
                    color: '#960e02'
                }
            },
            standardWarning: {
                backgroundColor: '#ffe387',
                border: '2px solid #f6c93c',
                color: '#443507',
                '& .MuiAlert-icon': {
                    color: '#443507'
                }
            },
            standardInfo: {
                backgroundColor: '#dbeaf6',
                border: '2px solid #a7ceed',
                color: '#1c3a52',
                '& .MuiAlert-icon': {
                    color: '#1c3a52'
                }
            },
            standardSuccess: {
                backgroundColor: '#d9f0e3',
                border: '2px solid #9ed7b8',
                color: '#053e26',
                '& .MuiAlert-icon': {
                    color: '#053e26'
                }
            },
            message: {
                fontSize: '1rem',
                lineHeight: '1.5'
            }
        },
        MuiSvgIcon: {
            root: {
                color: '#2f2f2f'
            }
        },
        MuiTooltip: {
            tooltip: {
                fontSize: '0.75rem',
                backgroundColor: '#383838'
            },
            arrow: {
                '&::before': {
                    backgroundColor: '#383838'
                }
            }
        },
        MuiDialogTitle: {
            root: {
                padding: '1rem'
            }
        },
        MuiDialogContent: {
            root: {
                padding: '1rem'
            }
        },
        MuiDialogActions: {
            root: {
                padding: '1rem'
            }
        },
        MuiDialog: {
            paper: {
                borderRadius: '1rem'
            }
        },
        MuiFormHelperText: {
            root: {
                fontSize: '0.875rem' // 14px
            }
        },
        MuiListItem: {
            root: {
                '&$selected': { backgroundColor: '#dcf8e0' }
            }
        },
        MuiMenuItem: {
            root: {
                whiteSpace: 'normal'
            }
        },
        MuiPaper: {
            root: {
                transition: 'none !important'
            }
        }
    },
    props: {
        MuiAlert: {
            iconMapping: {
                /* eslint-disable */
                info: <InfoIcon fontSize="default" />,
                error: <ErrorIcon fontSize="default" />,
                warning: <WarningIcon fontSize="default" />,
                success: <CheckCircleIcon fontSize="default" />
                /* eslint-enable */
            }
        }
    }
};

export default theme;
