import i18n from '../../../../i18n';
import { FrequenceDureeTerme, SousTypeTaux, TypeTaux } from './useOffreApi';
import { TypeOffreParams } from './Offres.transformer';

export const is1AnsFixeFerme = (offre: TypeOffreParams): boolean => {
    return offre.valeurDureeTerme === 1 && isFixeFerme(offre);
};

export const is2AnsFixeFerme = (offre: TypeOffreParams): boolean => {
    return offre.valeurDureeTerme === 2 && isFixeFerme(offre);
};

export const is3AnsFixeFerme = (offre: TypeOffreParams): boolean => {
    return offre.valeurDureeTerme === 3 && isFixeFerme(offre);
};

export const is4AnsFixeFerme = (offre: TypeOffreParams): boolean => {
    return offre.valeurDureeTerme === 4 && isFixeFerme(offre);
};

export const is5AnsFixeFerme = (offre: TypeOffreParams): boolean => {
    return offre.valeurDureeTerme === 5 && isFixeFerme(offre);
};

export const is7AnsFixeFerme = (offre: TypeOffreParams): boolean => {
    return offre.valeurDureeTerme === 7 && isFixeFerme(offre);
};

export const is5AnsVariableReduit = (offre: TypeOffreParams): boolean => {
    return offre.valeurDureeTerme === 5 && isVariableReduit(offre);
};

export const is5AnsRevisableFerme = (offre: TypeOffreParams): boolean => {
    return offre.valeurDureeTerme === 5 && isRevisableFerme(offre);
};

export const is5AnsVariableProtege = (offre: TypeOffreParams): boolean => {
    return offre.valeurDureeTerme === 5 && isVariableProtege(offre);
};

const isTermeBrisee = (
    offre: TypeOffreParams,
    moisAVerifier: number
): boolean => {
    return (
        offre.valeurDureeTerme === moisAVerifier &&
        FrequenceDureeTerme.MOIS === offre?.frequenceDureeTerme &&
        isFixeFerme(offre)
    );
};

export const is30MoisFixeFerme = (offre: TypeOffreParams): boolean => {
    return isTermeBrisee(offre, 30);
};

export const is40MoisFixeFerme = (offre: TypeOffreParams): boolean => {
    return isTermeBrisee(offre, 40);
};

export const is39MoisFixeFerme = (offre: TypeOffreParams): boolean => {
    return isTermeBrisee(offre, 39);
};

export const is42MoisFixeFerme = (offre: TypeOffreParams): boolean => {
    return isTermeBrisee(offre, 42);
};

export const is55MoisFixeFerme = (offre: TypeOffreParams): boolean => {
    return isTermeBrisee(offre, 55);
};

export const isFixeFerme = (offre: TypeOffreParams): boolean => {
    return (
        offre.typeTaux === TypeTaux.FIXE &&
        offre.sousTypeTaux === SousTypeTaux.FERME
    );
};

export const isVariableReduit = (offre: TypeOffreParams): boolean => {
    return (
        offre.typeTaux === TypeTaux.VARIABLE &&
        offre.sousTypeTaux === SousTypeTaux.REDUIT
    );
};

export const isRevisableFerme = (offre: TypeOffreParams): boolean => {
    return (
        offre.typeTaux === TypeTaux.REVISABLE &&
        offre.sousTypeTaux === SousTypeTaux.FERME
    );
};

export const isVariableProtege = (offre: TypeOffreParams): boolean => {
    return (
        offre.typeTaux === TypeTaux.VARIABLE &&
        offre.sousTypeTaux === SousTypeTaux.PROTEGE
    );
};

export const getYearsOrMonths = (
    valeurDureeTerme: number,
    frequenceDureeTerme: FrequenceDureeTerme,
    isOfferTitle = true
): string => {
    const suffix = isOfferTitle ? 'Offer' : '';
    // Months format si plus que des annees
    if (FrequenceDureeTerme.MOIS === frequenceDureeTerme) {
        return i18n.t('Date:months' + suffix, {
            value: valeurDureeTerme
        });
    }

    return i18n.t('Date:years' + suffix, {
        value: valeurDureeTerme
    });
};

export const getOfferTitle = (
    typeTaux: TypeTaux,
    sousTypeTaux: SousTypeTaux,
    valeurDureeTerme: number,
    frequenceDureeTerme: FrequenceDureeTerme
): string => {
    if (i18n.language === 'en') {
        return (
            getYearsOrMonths(valeurDureeTerme, frequenceDureeTerme) +
            ' ' +
            i18n.t('Card:' + sousTypeTaux) +
            i18n.t('Card:' + typeTaux)
        );
    } else {
        return (
            getYearsOrMonths(valeurDureeTerme, frequenceDureeTerme) +
            ' ' +
            i18n.t('Card:' + typeTaux) +
            i18n.t('Card:' + sousTypeTaux)
        );
    }
};
