import React, { useState, useEffect } from 'react';
import { useTranslation, Trans } from 'react-i18next';
import moment from 'moment';
import { Box } from '@material-ui/core';

import { Typography, Variant } from '../../../../styles';

interface RenouvellementEtapesProps {
    isEmprunteurUnique: boolean;
    delais?: number;
}

export const RenouvellementEtapes: React.FC<RenouvellementEtapesProps> = ({
    isEmprunteurUnique,
    delais
}) => {
    const [t] = useTranslation(['RenouvellementEtapes']);
    const [delaisTexteCle, setDelaisTexteCle] = useState('');
    const [formattedDelais, setFormattedDelais] = useState(0);

    useEffect(() => {
        const formattedDelais = delais > 14 ? 14 : delais;
        setFormattedDelais(formattedDelais);
        if (formattedDelais === 0) {
            setDelaisTexteCle(
                'RenouvellementEtapes:nextstep_text_delais_today'
            );
        } else if (formattedDelais === 1) {
            setDelaisTexteCle(
                'RenouvellementEtapes:nextstep_text_delais_1_jours'
            );
        } else {
            setDelaisTexteCle(
                'RenouvellementEtapes:nextstep_text_delais_14_jours'
            );
        }
    }, [delais]);

    return (
        <React.Fragment>
            <Box paddingTop={'0.75rem'}>
                <Typography variant={Variant.h2}>{t('steps_title')}</Typography>
            </Box>
            <ul>
                <li>{t('steps_one')}</li>
                <li>{t('steps_two')}</li>
                <li>{t('steps_three')}</li>
                {!isEmprunteurUnique && (
                    <li>
                        <Trans i18nKey={delaisTexteCle}>
                            <strong>
                                {{
                                    timeRemaining: moment
                                        .duration(formattedDelais, 'days')
                                        .humanize()
                                }}
                            </strong>
                        </Trans>
                    </li>
                )}
            </ul>
        </React.Fragment>
    );
};
