import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { SaveAndLeaveModal } from '..';

import { Header } from '../../styles';

export interface IHeaderProps {
    langue: string;
    urlRetour: string;
    shouldConfirmationBeDisplayed: boolean;
    isModalConfirmOpened: boolean;
    handleClicBoutonRetour: () => void;
    handleClicModalClose: (value: boolean) => void;
}

export const CustomHeader: React.FC<IHeaderProps> = ({
    langue,
    urlRetour,
    shouldConfirmationBeDisplayed,
    isModalConfirmOpened,
    handleClicBoutonRetour,
    handleClicModalClose
}) => {
    const [t] = useTranslation(['Header']);

    const removeListenerClicBoutonRetour = () => {
        window.removeEventListener(
            'oel-header-transactionnel.boutonRetourCliquee',
            handleClicBoutonRetour
        );
    };

    const setListenerClicBoutonRetour = () => {
        window.addEventListener(
            'oel-header-transactionnel.boutonRetourCliquee',
            handleClicBoutonRetour
        );
    };

    // Add listener
    useEffect(() => {
        setListenerClicBoutonRetour();
        // When component is destroyed -> remove listener
        return () => {
            removeListenerClicBoutonRetour();
        };
    }, []);

    return (
        <>
            <Header
                langue={langue}
                urlRetour={urlRetour}
                tailleTexteNormale="100%"
                tailleTexteMoyenne="150%"
                tailleTexteLarge="200%"
                comportementBoutonRetour="retour-evenement"
                libelleBoutonRetour={t(
                    shouldConfirmationBeDisplayed
                        ? 'lienRetourAvecModale'
                        : 'lienRetourSansModale'
                )}
            />

            <SaveAndLeaveModal
                isOpen={isModalConfirmOpened}
                onNext={() => handleClicModalClose(false)}
                onCancel={() => handleClicModalClose(true)}
            />
        </>
    );
};
