import { QUERY_PARAM_USER_MODE, URLToURLSearchParams } from '../../../../utils';
import { mockInstance, withDelay } from '../../../../api/axiosInstance.mock';

import { ISignatureAdmissibilite } from './useSignatureAdmissibiliteApi';
import { SIGNATURE_ADMISSIBILITE_ENDPOINT } from '../../apiEndPoints';
import { dataCoEmprunteur1 as sessionMockData } from '../../contexte/useSessionApi/useSessionApi.mock';

const endPoint = SIGNATURE_ADMISSIBILITE_ENDPOINT.replace(
    /{idDemande}/g,
    String(sessionMockData?.contextePret?.idDemande)
);

export const dataSignatureInadmissibilite: ISignatureAdmissibilite = {
    admissible: false
};

export const dataSignatureAdmissibilite: ISignatureAdmissibilite = {
    admissible: true
};

const defineMockData = (useMockParam: string): any => {
    if (!useMockParam) return dataSignatureInadmissibilite;
    switch (useMockParam) {
        case 'emprunteuruniqueSignatureIntegree':
        case 'coemprunteur1OntarioSignatureIntegree':
        case 'coemprunteur2OntarioSignatureIntegree':
        case 'emprunteuruniqueontarioSignatureIntegree':
        case 'coemprunteur1SignatureIntegree':
        case 'coemprunteur2SignatureIntegree':
        case 'coemprunteur1codeAvisSignatureIntegreeEnCours':
            return dataSignatureAdmissibilite;
        default:
            return dataSignatureInadmissibilite;
    }
};

export const useSignatureAdmissibiliteApiMock = (): any => {
    // var
    const query = URLToURLSearchParams();
    const userMockParam = query.get(QUERY_PARAM_USER_MODE);
    mockInstance
        .onGet(endPoint)
        .reply(withDelay([200, defineMockData(userMockParam)], 200));
};
