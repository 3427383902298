import React from 'react';
import { useTranslation } from 'react-i18next';
import {
    BorderRadius,
    ColorDSD,
    ColorHex,
    Container,
    GridCol,
    GridRow,
    IconTooltip,
    Margin,
    Padding,
    Police,
    Spacer,
    Typography,
    Variant
} from '../../../../styles';
import { Box, useMediaQuery } from '@material-ui/core';

interface ExempleDeProtectionProps {
    admissibleAssuranceInvalidite: boolean;
}

export const ExempleDeProtection: React.FC<ExempleDeProtectionProps> = ({
    admissibleAssuranceInvalidite
}) => {
    const [t] = useTranslation(['EveilAssurance']);
    const isTablet = useMediaQuery('(max-width:990px)');
    const isMobile = useMediaQuery('(max-width:640px)');

    const renderAssuranceVie = () => {
        return (
            <>
                <GridRow>
                    <Typography
                        variant={Variant.h5}
                        police={Police.bold}
                        marginBottom="1rem"
                    >
                        {t('exempleProtectionAssuranceVieSousTitre')}
                    </Typography>
                </GridRow>
                <GridRow>
                    <Typography variant={Variant.p} marginBottom="0.2rem">
                        {t('exempleProtectionAssuranceVieSoldeSousTitre')}
                    </Typography>
                </GridRow>
                <GridRow>
                    <Typography variant={Variant.p} police={Police.bold}>
                        {t('exempleProtectionAssruanceVieSoldeMontant')}
                    </Typography>
                </GridRow>
                <GridRow>
                    <Typography variant={Variant.p} marginBottom="0.2rem">
                        {t(
                            'exempleProtectionAssuranceVieRemboursementSousTitre'
                        )}
                    </Typography>
                </GridRow>
                <GridRow>
                    <Typography
                        variant={Variant.p}
                        police={Police.bold}
                        hideMarginBottom={!admissibleAssuranceInvalidite}
                    >
                        {t('exempleProtectionAssuranceVieRemboursementMontant')}
                    </Typography>
                </GridRow>
            </>
        );
    };

    const renderAssuranceInvalidite = () => {
        return (
            <>
                <GridRow>
                    <Typography
                        variant={Variant.h5}
                        police={Police.bold}
                        marginBottom="1rem"
                    >
                        {t('exempleProtectionAssuranceInvaliditeSousTitre')}
                    </Typography>
                </GridRow>
                <GridRow>
                    <Typography variant={Variant.p} marginBottom="0.2rem">
                        {t(
                            'exempleProtectionAssuranceInvaliditePaiementSousTitre'
                        )}
                    </Typography>
                </GridRow>
                <GridRow>
                    <Typography variant={Variant.p} police={Police.bold}>
                        {t(
                            'exempleProtectionAssruanceInvaliditePaiementMontant'
                        )}
                    </Typography>
                </GridRow>
                <GridRow>
                    <Typography variant={Variant.p} marginBottom="0.2rem">
                        {t(
                            'exempleProtectionAssuranceInvaliditeMontantPayeSousTitre'
                        )}
                    </Typography>
                </GridRow>
                <GridRow>
                    <Typography
                        variant={Variant.p}
                        police={Police.bold}
                        hideMarginBottom
                    >
                        {t(
                            'exempleProtectionAssuranceInvaliditeMontantPayeMontant'
                        )}
                    </Typography>
                </GridRow>
            </>
        );
    };

    return (
        <>
            <Container
                backgroundColorCustom={ColorHex.lightGreen}
                borderRadius={BorderRadius.sm}
                padding={Padding.none}
                margin={Margin.none}
            >
                <GridCol col="12">
                    <Box
                        paddingX={isMobile ? '1.75rem' : '2.25rem'}
                        paddingY={isMobile ? '0rem' : '0.5rem'}
                    >
                        <GridRow>
                            <Typography
                                variant={Variant.h4}
                                police={Police.bold}
                                marginBottom="1rem"
                            >
                                {t('exempleProtectionTitre')}
                            </Typography>
                            <Box paddingLeft="0.5rem">
                                <IconTooltip
                                    iconName="alertes_rempli_information"
                                    iconSize="md"
                                    fill={ColorDSD.lightGraphite4}
                                    srOnlySuffix={t(
                                        'exempleProtectionTitreInfoBulleTexteHorsEcran'
                                    )}
                                    label={t('exempleProtectionTitreInfoBulle')}
                                    placement="top"
                                    isSmallerTooltip={isTablet}
                                />
                            </Box>
                        </GridRow>
                        {renderAssuranceVie()}
                        {admissibleAssuranceInvalidite &&
                            renderAssuranceInvalidite()}
                    </Box>
                </GridCol>
            </Container>
            <Spacer md={6} sm={5} xs={5} />
        </>
    );
};
