import React from 'react';

interface IFootnoteGroupProps {
    footnoteGroupTitle: string;
    titleLevel?: string;
    analytics?: string;
    dark?: boolean;
    open?: boolean;
    children: React.ReactNode;
}

export enum FootnoteGoupListType {
    LIST = 'list',
    ORDERED_LIST = 'ordered-list'
}

// eslint-disable-next-line
export const FootnoteGroup: React.FC<IFootnoteGroupProps> = ({
    footnoteGroupTitle,
    titleLevel,
    analytics,
    dark,
    open,
    children
}) => (
    <>
        {/*@ts-ignore*/}
        <dsd-footnote-group
            analytics={analytics}
            dark={dark}
            open={open}
            footnote-group-title={footnoteGroupTitle}
            title-level={titleLevel}
            data-testid={'footnote_group'}
        >
            {children}
            {/*@ts-ignore*/}
        </dsd-footnote-group>
    </>
);
