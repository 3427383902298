import { getLangue, isMobileAppFromCookies } from './index';

// Url de base du click2call
const CLICK2CALL_URL = 'https://rappel.desjardins.com/#/';
// Parametres fixe pour notre parcours
const CLICK2CALL_PREFIX_PARAM =
    '?struct=["VPA","DPCCCSC","Financement"]&dest=FIN_RenouvHyp&da=P&source=http://www.desjardins.com/?q=query&n=10&';
// identification de notre parcours pour click2call
const CLICK2CALL_RAISON = 'Renouvellement Hypothecaire Numerique - 50124';

export enum ContexteAppel {
    PAGE_ACCUEIL = "page d'accueil",
    PAGE_OFFRE = 'page des offres',
    PAGE_CHOIX_COEMPRUNTEUR = 'page choix du coemprunteur',
    PAGE_MODALITES = 'simulateur de paiement',
    PAGE_DATE_PAIEMENT = 'choix date premier paiement',
    PAGE_INFO_SIGNATURE = "page d'identification",
    PAGE_VALIDATION = 'page de validation',
    PAGE_CONFIRMATION = 'page de confirmation',
    PAGE_EVEIL_ASSURANCE = "page d'éveil au besoin d'assurance",
    PAGE_ERREUR = "page d'erreur",
    PAGE_SIGNATURE = 'page signature',
    VARIABLE_PROTEGE = 'variable protege',
    COTE_COMPORTEMENTAL_5_6 = 'autre offre cote comportementale 5-6',
    VENTE_DE_PROPRIETE = 'vente de maison',
    PRET_ACTUEL = 'voir mon prêt actuel',
    RAPPEL_DEPASSEE = 'date de rappel dépassée',
    PROTECTION_ACTUELLE = "voir ma protection d'assurance",
    PAGE_INTERMEDIAIRE_DEEPLINK = 'lien vers RHN'
}

//eslint-disable-next-line
export function getParlerConseillerUrl(
    contexte: ContexteAppel,
    idDemande: number
) {
    const langue = getLangue();
    const isMobile = isMobileAppFromCookies();

    // Calcul selon le type et la langue du domaine a appeler
    let click2callTypeUrl = '';
    if (langue === 'fr') {
        if (isMobile) {
            click2callTypeUrl = 'services-mobiles';
        } else {
            click2callTypeUrl = 'dcom-pleine-page';
        }
    } else {
        if (isMobile) {
            click2callTypeUrl = 'mobile-services';
        } else {
            click2callTypeUrl = 'dcom-full-page';
        }
    }

    // On construit l'url a ouvrir pour joindre un conseiller
    const urlToOpen = encodeURI(
        CLICK2CALL_URL +
            click2callTypeUrl +
            CLICK2CALL_PREFIX_PARAM +
            'MVT_REFERENCE_EXTERNE=' +
            idDemande +
            '&MVT_CONTEXTE=' +
            contexte +
            '&MVT_URL=' +
            window.location.href +
            '&MVT_RAISON_SORTIE=' +
            CLICK2CALL_RAISON
    );

    return urlToOpen;
}
