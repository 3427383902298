export default {
    en: {
        title: 'Have us call you',
        description:
            'We’re here to guide you through the mortgage renewal process.',
        parlerConseiller: 'Talk to our specialists',
        click2call_aria: 'This link will open in a new window.'
    },
    fr: {
        title: 'Demandez qu’on vous appelle',
        description:
            'Nous sommes là pour vous accompagner et vous aider à renouveler votre prêt hypothécaire.',
        parlerConseiller: 'Parlez à nos spécialistes',
        click2call_aria: 'Ce lien s’ouvrira dans une nouvelle fenêtre.'
    }
};
