import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    idDemande: null,
    montantSelectionne: null,
    // Permet de lancer un check de la signature integree
    checkSignature: null,
    // Permet de connaitre le statut de la signature integree
    statutSignature: null,
    infoBulleShown: false,
    // Etape actuelle dans le flow
    // Par defaut -> 0: page accueil
    etapeActuelle: 0
};

export const renouvellementSlice = createSlice({
    name: 'renouvellement',
    initialState: initialState,
    reducers: {
        setIdDemande: (state, action) => {
            state.idDemande = action.payload;
        },
        setMontantSelectionnee: (state, action) => {
            state.montantSelectionne = action.payload;
        },
        setCheckSignature: (state, action) => {
            state.checkSignature = action.payload;
        },
        setStatutSignature: (state, action) => {
            state.statutSignature = action.payload;
        },
        setEtapeActuelle: (state, action) => {
            state.etapeActuelle = action.payload;
        },
        setInfoBulleShown: (state, action) => {
            state.infoBulleShown = action.payload;
        },
        reset: () => {
            return initialState;
        }
    }
});

export const {
    setIdDemande,
    setMontantSelectionnee,
    setCheckSignature,
    setStatutSignature,
    setEtapeActuelle,
    setInfoBulleShown,
    reset
} = renouvellementSlice.actions;

export default renouvellementSlice.reducer;
