export default {
    en: {
        economie: 'Estimated interest saved',
        tooltipEconomie:
            'Depending on the term selected, your actual savings will depend on a number of factors, including any rate changes, prepayments or late payments.',
        economieInteret: 'd’économie d’intérêt'
    },
    fr: {
        economie: 'Économie d’intérêt estimé',
        tooltipEconomie:
            'Selon le terme choisi, cette économie pourrait varier en fonction de différents facteurs. Exemples : fluctuation du taux, remboursements anticipés, retards de paiement.',
        economieInteret: 'd’économie d’intérêt'
    }
};
