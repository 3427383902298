import { AxiosResponse } from 'axios';
import { useMutation } from 'react-query';

import { SELECTIONNER_OFFRE_COEMPRUNTEUR_END_POINT } from '../../apiEndPoints';
import {
    axiosInstance,
    reactQueryOptions,
    IMutationResult,
    getDynamicHeaders
} from '../../../../api';

interface ISelectionnerOffreCoemprunteur {
    idDemande: number;
    isMartine: boolean;
    idOffre?: number;
}

const putSelectionnerOffreCoemprunteur = async (
    data: ISelectionnerOffreCoemprunteur
): Promise<AxiosResponse<any>> => {
    const endPoint = SELECTIONNER_OFFRE_COEMPRUNTEUR_END_POINT.replace(
        /{idDemande}/g,
        String(data.idDemande)
    ).replace(/{idOffre}/g, String(data.idOffre));

    const content = {
        statutDemande: data?.isMartine === true ? 'EN_ATTENTE' : 'INITIALE'
    };

    const { data: response } = await axiosInstance.put(
        endPoint,
        content,
        getDynamicHeaders()
    );

    return response;
};

export const useSelectionnerOffreCoemprunteurApi = (): IMutationResult => {
    return useMutation(putSelectionnerOffreCoemprunteur, {
        ...reactQueryOptions()
    });
};
