import { Box } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
    ColorDSD,
    Modal,
    Radio,
    RadioGroup,
    Spacer,
    Typography,
    Variant
} from '../../../../styles';

interface AiguilleurProps {
    isOpen: boolean;
    onClose: () => void;
    onSelectFilter: (answer1: string, answer2: string) => void;
}

export const answersQuestion1 = ['sell', 'keep', 'notsure'];
export const answersQuestion2 = ['fixe', 'variable', 'notsure'];

export const Aiguilleur: React.FC<AiguilleurProps> = ({
    isOpen,
    onClose,
    onSelectFilter
}) => {
    const [t] = useTranslation('Aiguilleur');
    let question1Answer = answersQuestion1[2];
    let question2Answer = answersQuestion2[2];
    const [displaySecondQuestion, setDisplaySecondQuestion] = useState(false);

    const handleAnswer1Selected = (value: string) => {
        question1Answer = value;
    };

    const handleAnswer2Selected = (value: string) => {
        question2Answer = value;
    };

    const handleConfirm = () => {
        // Le membre a repondu aux deux questions
        if (question1Answer && question2Answer) {
            onSelectFilter(question1Answer, question2Answer);
            // Si le membre repond "sell" on ne pose pas la deuxieme question
        } else if (question1Answer && question1Answer === answersQuestion1[0]) {
            onSelectFilter(question1Answer, answersQuestion2[2]);
            // Sinon affichage deuxieme question
        } else {
            question2Answer = answersQuestion2[2];
            setDisplaySecondQuestion(true);
        }
    };

    useEffect(() => {
        // Reset
        if (isOpen) {
            question1Answer = answersQuestion1[2];
            question2Answer = null;
            setDisplaySecondQuestion(false);
        }
    }, [isOpen]);

    return (
        <>
            {isOpen && (
                <Modal
                    show={true}
                    title={t('title')}
                    dataTestId={'modal_aiguilleur'}
                    buttonPrimaryLabel={t('boutonConfirmer')}
                    buttonSecondaryLabel={t('boutonFermer')}
                    onClickPrimaryButton={() => {
                        handleConfirm();
                    }}
                    onClickSecondaryButton={onClose}
                >
                    <Typography variant={Variant.span}>
                        {t('description')}
                    </Typography>
                    <Spacer />
                    <Box className={displaySecondQuestion ? 'dsd-d-none' : ''}>
                        <Typography
                            variant={Variant.h6}
                            color={ColorDSD.primary}
                        >
                            {t('question1Title')}
                        </Typography>
                        <RadioGroup name="radio_group_question1" column={true}>
                            <Radio
                                dataTestId="aiguilleur_btn_vendre_2_ans"
                                name="aiguilleur_btn_vendre_2_ans"
                                label={t('question1Choice1')}
                                value={answersQuestion1[0]}
                                isChecked={
                                    question1Answer === answersQuestion1[0]
                                }
                                onChange={handleAnswer1Selected}
                            />
                            <Radio
                                dataTestId="aiguilleur_btn_garder_plus_2_ans"
                                name="aiguilleur_btn_garder_plus_2_ans"
                                label={t('question1Choice2')}
                                value={answersQuestion1[1]}
                                isChecked={
                                    question1Answer === answersQuestion1[1]
                                }
                                onChange={handleAnswer1Selected}
                            />
                            <Radio
                                dataTestId="aiguilleur_btn_ne_sais_pas_1"
                                name="aiguilleur_btn_ne_sais_pas_1"
                                label={t('question1Choice3')}
                                value={answersQuestion1[2]}
                                isChecked={
                                    question1Answer === answersQuestion1[2]
                                }
                                onChange={handleAnswer1Selected}
                            />
                        </RadioGroup>
                    </Box>
                    <Box className={displaySecondQuestion ? '' : 'dsd-d-none'}>
                        <Typography
                            variant={Variant.h6}
                            color={ColorDSD.primary}
                        >
                            {t('question2Title')}
                        </Typography>
                        <RadioGroup name="radio_group_question2" column={true}>
                            <Radio
                                dataTestId="aiguilleur_taux_fixe"
                                name="aiguilleur_taux_fixe"
                                label={t('question2Choice1')}
                                value={answersQuestion2[0]}
                                onChange={handleAnswer2Selected}
                                isChecked={
                                    question2Answer === answersQuestion2[0]
                                }
                            />
                            <Radio
                                dataTestId="aiguilleur_taux_variable"
                                name="aiguilleur_taux_variable"
                                label={t('question2Choice2')}
                                value={answersQuestion2[1]}
                                onChange={handleAnswer2Selected}
                                isChecked={
                                    question2Answer === answersQuestion2[1]
                                }
                            />
                            <Radio
                                dataTestId="aiguilleur_btn_ne_sais_pas_2"
                                name="aiguilleur_btn_ne_sais_pas_2"
                                label={t('question2Choice3')}
                                value={answersQuestion2[2]}
                                onChange={handleAnswer2Selected}
                                isChecked={
                                    question2Answer === answersQuestion2[2]
                                }
                            />
                        </RadioGroup>
                    </Box>
                </Modal>
            )}
        </>
    );
};
