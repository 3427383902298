import React, { useState } from 'react';
import { Box, useMediaQuery } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

import { Button, ButtonVariant, IconPosition } from '../../../../styles';

import { CoutAssuranceModalConnected as CoutAssuranceModal } from '../coutAssuranceModal/CoutAssuranceModal.connected';

interface IDetailsPaiementBarProps {
    isCoutAssuranceModalDisplayed: boolean;
    onReset: () => void;
}

export const DetailsPaiementBar: React.FC<IDetailsPaiementBarProps> = ({
    isCoutAssuranceModalDisplayed,
    onReset
}) => {
    // Translation
    const [t] = useTranslation('InfoBar');
    const [shouldShowCoutAssurance, setShouldShowCoutAssurance] =
        useState(false);

    const isMobile = useMediaQuery('(max-width:640px)');

    const showCoutAssuranceModal = () => {
        setShouldShowCoutAssurance(true);
    };

    const hideCoutAssuranceModal = () => {
        setShouldShowCoutAssurance(false);
    };

    return (
        <Box display="flex" justifyContent="space-between" flexWrap="wrap">
            <Box ml="0.25rem">
                <Button
                    dataTestId={'bouton_recommencer_calcul'}
                    variant={ButtonVariant.tertiary}
                    iconName="actions_contour_rafraichir"
                    iconPosition={IconPosition.start}
                    theme="somber"
                    onClick={onReset}
                >
                    {t('reinitialiser')}
                </Button>
            </Box>
            {isCoutAssuranceModalDisplayed && (
                <Box
                    mr={isMobile ? 0 : '0.75rem'}
                    ml={isMobile ? '0.25rem' : 0}
                >
                    <Button
                        variant={ButtonVariant.tertiary}
                        iconName="navigations_contour_modale"
                        iconPosition={IconPosition.start}
                        theme="somber"
                        dataTestId="comprendreButton"
                        onClick={showCoutAssuranceModal}
                    >
                        {t('comprendreButton')}
                    </Button>
                    <CoutAssuranceModal
                        isOpen={shouldShowCoutAssurance}
                        onClose={hideCoutAssuranceModal}
                    />
                </Box>
            )}
        </Box>
    );
};
