import { Body, Headings } from '../../styles';
import {
    EVENT_ACTIONS,
    EVENT_CATEGORIES,
    EVENT_LABELS,
    hideLoading,
    sendEvent,
    showLoading
} from './../../features';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useRenouvellementApi, useRoutes, useSessionApi } from '../../hooks';

import { CodeAvisEnumModal } from '../../components/codeAvis/CodeAvis.utils';
import { HeadingProvider } from '../../context';
import { Home } from './Home';
import { Navigation as NavigationHome } from './Home.navigation';
import { StatutEmprunteur } from '../../components';
import { getFormattedDate } from '../../utils';

const headingMapping = {
    h1: Headings.h3,
    h2: Headings.h6,
    h3: Body.b2
};

export const HomeConnected: React.FC<unknown> = () => {
    // Store
    const isLoading = useSelector((state: any) => state.loading.show);

    // State
    const [isNextClicked, setIsNextClicked] = useState(false);

    // Hooks
    const dispatch = useDispatch();
    const { getRoute, goToRoute } = useRoutes();
    const { data: session } = useSessionApi();
    const { data: renouvellement } = useRenouvellementApi(!!session);
    const idDemande = session?.contextePret?.idDemande;

    useEffect(() => {
        if (session) {
            const eventAction = session?.isMartine
                ? EVENT_ACTIONS.ETAPE_7
                : EVENT_ACTIONS.ENTREE;
            dispatch(
                sendEvent({
                    category: EVENT_CATEGORIES.FORMULAIRE,
                    action: eventAction,
                    label: EVENT_LABELS.FRM
                })
            );
        }
    }, [session]);

    useEffect(() => {
        if (session && (renouvellement || session?.isFastLoading === true)) {
            dispatch(hideLoading());
        }
    }, [session, renouvellement]);

    // Si le bouton next est click ne pas aller a la prochaine
    // page jusqu'à ce que renouvellement à finit de load
    useEffect(() => {
        if (!renouvellement && isNextClicked) {
            dispatch(showLoading());
        } else if (renouvellement && isNextClicked) {
            dispatch(hideLoading());

            let nextRoute = getRoute().nextRoute;

            if (renouvellement?.montantTotalInteretRetard > 0) {
                nextRoute = getRoute().nextRouteConditional1;
            }

            goToRoute(nextRoute);
        }
    }, [renouvellement, isNextClicked]);

    return (
        !isLoading && (
            <HeadingProvider value={headingMapping}>
                <StatutEmprunteur />
                <Home
                    parcoursEmprunteur={session?.typeParcoursEmprunteur}
                    guyFirstName={renouvellement?.emprunteurNonConnecte?.prenom}
                    dateLimite={
                        new Date(
                            getFormattedDate(
                                session?.approbationCoemprunteur.dateLimite,
                                '/'
                            )
                        )
                    }
                    dateRappel={
                        new Date(
                            getFormattedDate(
                                session?.approbationCoemprunteur.dateRappel,
                                '/'
                            )
                        )
                    }
                    dateAujourdhui={
                        new Date(getFormattedDate(session?.dateSysteme, '/'))
                    }
                    isRenewalExpired={
                        session &&
                        session?.codeAvis ===
                            CodeAvisEnumModal.AVIS_DATE_DEPASSEE
                    }
                    delaisRenouvellement={
                        session?.approbationCoemprunteur.delaiApprobation
                    }
                    idDemande={idDemande}
                />
                <NavigationHome
                    onNextClick={() => {
                        setIsNextClicked(true);
                    }}
                />
            </HeadingProvider>
        )
    );
};

export default HomeConnected;
