import { useState, useEffect } from 'react';
import { findIndex } from 'lodash';
import { useHistory, useLocation } from 'react-router-dom';

import {
    routeConfigUniqueOuGuy,
    routeConfigMartine,
    Routes,
    routeConfigUniqueOuGuySansModalitesNiAssurance,
    nomDePage,
    routesRequiringLeaveConfirmation,
    etapeEtStatutDesPages,
    routesRequiringStatutCheck
} from './routeConfig';
import { PageInitiale, useSessionApi } from '../..';
import { getRetourUrl } from '../../../utils';

export interface IRoutes {
    route?: string;
    pageInitiales?: Array<PageInitiale>;
    nextRoute?: string;
    prevRoute?: string;
    nextRouteConditional1?: string;
    nextRouteConditional2?: string;
    prevRouteConditional?: string;
}

function getActualPath(actualPath): string {
    const paths = actualPath.split('/');
    paths.shift();
    return paths[paths.length - 1];
}

export function getActualRoute(): string {
    // Retrieve actual location/route without configuration
    const location = useLocation();
    return getActualPath(location.pathname);
}

// Permet de savoir pour le header si la page actuelle a besoin d'afficher
// une confirmation avant de quitter
export function isRouteRequireLeaveConfirmation(): boolean {
    return routesRequiringLeaveConfirmation.includes(getActualRoute());
}

// Permet de savoir pour le header si la page actuelle a besoin d'appeler un endpoint
// pour verifier le statut avant de quitter
export function isRouteRequireStatutCheck(): boolean {
    return routesRequiringStatutCheck.includes(getActualRoute());
}

export function getPageStep(): any {
    // Retrieve actual path
    const path = getActualPath(window.location.href);
    // Search page name in dictionnary
    // If not found -> default to home
    if (!(path in etapeEtStatutDesPages)) {
        return null;
    }
    return etapeEtStatutDesPages[path];
}

export function getPageName(): string {
    // Retrieve actual path
    const path = getActualPath(window.location.href);
    // Search page name in dictionnary
    // If not found -> default to home
    if (!(path in nomDePage)) {
        return nomDePage[Routes.HOME];
    }
    return nomDePage[path];
}

// HACK HACK HACK ne pas bouger
export function goQuitterParcours(): any {
    window.location.href = getRetourUrl();
}

export function useRoutes(): any {
    // State
    const [config, setConfig] = useState(null);

    // hooks
    const history = useHistory();
    const location = useLocation();

    // Api
    const { data: session } = useSessionApi();

    const getInitialRoute = (
        pageInitiale,
        isInteretRetard,
        pretAdmissibleAssurance
    ) => {
        let route;

        // Si le membre a des interet retard il ne peut pas renouveller
        if (isInteretRetard && pageInitiale !== PageInitiale.CONFIRMATION) {
            return Routes.INTERET_RETARD;
        }

        // Si retour d'assurance (INITIALE) mais plus admissible -> page offre
        if (
            !pretAdmissibleAssurance &&
            pageInitiale === PageInitiale.EVEIL_ASSURANCE
        ) {
            return Routes.OFFRES;
        }

        if (config) {
            //@ts-ignore - reducer
            route = config.reduce((acc, current) => {
                if (current?.pageInitiales?.includes(pageInitiale)) {
                    return current.route;
                }
                return acc;
            }, []);
        }

        return route ? route : Routes.HOME;
    };

    const getRoute = (): IRoutes => {
        if (config) {
            const path = getActualPath(location.pathname);

            const currentRoute: IRoutes =
                config[findIndex(config, ['route', path])];

            // Return route configuration. If no route found, display 'quitter' button by default
            return {
                route: currentRoute?.route || null,
                nextRoute: currentRoute?.nextRoute || null,
                prevRoute: currentRoute?.prevRoute || null,
                nextRouteConditional1:
                    currentRoute?.nextRouteConditional1 || null,
                nextRouteConditional2:
                    currentRoute?.nextRouteConditional2 || null,
                prevRouteConditional: currentRoute?.prevRouteConditional || null
            };
        }
    };

    const goToRoute = (route) => {
        history.push(route);
    };

    const resetParcours = () => {
        // Retourne Accueil si ce n'est pas deja le cas
        if (history.location.pathname !== '/' + Routes.HOME) {
            history.push(Routes.HOME);
        }
    };

    // set la config des routes dependament si parcours martine ou guy/unique
    useEffect(() => {
        if (session) {
            let newConfig = [];

            // Es-ce qu'on a le droit aux modalites ?
            if (session?.contexteToggleFeature.calculScenarioPaiement) {
                // Es-ce qu'on a le droit a l'assurance ?
                newConfig = routeConfigUniqueOuGuy;
            } else {
                // Pas de modalites -> pas d'assurance alors non plus au niveau business: mini route
                newConfig = routeConfigUniqueOuGuySansModalitesNiAssurance;
            }

            //  Es-ce qu'on est Martine ?
            newConfig = session?.isMartine ? routeConfigMartine : newConfig;
            setConfig(newConfig);
        }
    }, [session]);

    return {
        getInitialRoute,
        getRoute,
        goToRoute,
        resetParcours,
        config
    };
}
