import { FrequenceRemboursement as Freq } from '../hooks/api/demande/useOffreApi/useOffreApi';

const isBothHebdomadaire = (freq1: Freq, freq2: Freq) => {
    return (
        (freq1 === Freq.HEBDOMADAIRE_ACCELEREE &&
            freq2 === Freq.HEBDOMADAIRE) ||
        (freq1 === Freq.HEBDOMADAIRE && freq2 === Freq.HEBDOMADAIRE_ACCELEREE)
    );
};

const isBothAuxDeuxSemaines = (freq1: Freq, freq2: Freq) => {
    return (
        (freq1 === Freq.AUX_DEUX_SEMAINES_ACCELEREE &&
            freq2 === Freq.AUX_DEUX_SEMAINES) ||
        (freq1 === Freq.AUX_DEUX_SEMAINES &&
            freq2 === Freq.AUX_DEUX_SEMAINES_ACCELEREE)
    );
};

export const isFrequenceEqual = (freq1: Freq, freq2: Freq): boolean => {
    switch (true) {
        case freq1 === freq2:
        case isBothHebdomadaire(freq1, freq2):
        case isBothAuxDeuxSemaines(freq1, freq2):
            return true;
        default:
            return false;
    }
};
