import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { ResumePret } from './ResumePret';
import { useRoutes, Routes, IResumeOffre, ISession } from '../../hooks';
import {
    sendEvent,
    EVENT_CATEGORIES,
    EVENT_ACTIONS,
    EVENT_LABELS
} from '../../features';
import { ResumePretSimplifie } from './ResumePretSimplifie';

export interface IResumePretConnectedProps {
    isEditButtonDisplayed?: boolean;
    isTauxAssuranceDisplayed: boolean;
    isSoldeSansAssuranceDisplayed: boolean;
    resumeOffre: IResumeOffre;
    session: ISession;
    showButtonPretActualModal?: boolean; // if not set to true, the button and modal wont show.
    isSimplifie: boolean;
}

export const ResumePretConnected: React.FC<IResumePretConnectedProps> = ({
    isEditButtonDisplayed,
    isTauxAssuranceDisplayed,
    isSoldeSansAssuranceDisplayed,
    resumeOffre,
    session,
    showButtonPretActualModal,
    isSimplifie
}) => {
    const dispatch = useDispatch();
    const { goToRoute } = useRoutes();

    // State
    const [isMartineFlow, setIsMartineFlow] = useState(null);

    const handleOnModifiedClick = () => {
        dispatch(
            sendEvent({
                category: EVENT_CATEGORIES.RHN,
                action: EVENT_ACTIONS.CLIC,
                label: EVENT_LABELS.MODIFIER + 'type de prêt'
            })
        );

        if (!isMartineFlow) {
            goToRoute(Routes.OFFRES);
        }
    };

    const handleTooltipActive = () => {
        const CYBERMETRIE_TOOLTIP_LABEL = 'taux d’intérêt';

        dispatch(
            sendEvent({
                category: EVENT_CATEGORIES.RHN,
                action: EVENT_ACTIONS.CLIC,
                label: EVENT_LABELS.INFOBULLE + CYBERMETRIE_TOOLTIP_LABEL
            })
        );
    };

    useEffect(() => {
        if (session?.typeParcoursEmprunteur) {
            setIsMartineFlow(session?.isMartine);
        }
    }, [session]);

    return (
        <>
            {isSimplifie && (
                <ResumePretSimplifie
                    resumeOffre={resumeOffre}
                    isEditButtonDisplayed={
                        isEditButtonDisplayed && !isMartineFlow
                    }
                    isTauxAssuranceDisplayed={isTauxAssuranceDisplayed}
                    isSoldeSansAssuranceDisplayed={
                        isSoldeSansAssuranceDisplayed
                    }
                    showButtonPretActualModal={showButtonPretActualModal}
                    onModifiedClick={handleOnModifiedClick}
                    onTooltipActive={handleTooltipActive}
                />
            )}
            {!isSimplifie && (
                <ResumePret
                    resumeOffre={resumeOffre}
                    isEditButtonDisplayed={
                        isEditButtonDisplayed && !isMartineFlow
                    }
                    isTauxAssuranceDisplayed={isTauxAssuranceDisplayed}
                    isSoldeSansAssuranceDisplayed={
                        isSoldeSansAssuranceDisplayed
                    }
                    showButtonPretActualModal={showButtonPretActualModal}
                    onModifiedClick={handleOnModifiedClick}
                    onTooltipActive={handleTooltipActive}
                />
            )}
        </>
    );
};
