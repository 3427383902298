export default {
    en: {
        titleRhn: 'Mortgage renewal',
        pageOffres: 'Rates',
        pageEveilAssurance: 'Loan insurance',
        pageModalites: 'Details',
        pageModalitesDatePaiement: 'Payment date',
        pageInfoSignature: 'Sign your contract',
        pageValidation: 'Verify'
    },
    fr: {
        titleRhn: 'Renouvellement hypothécaire',
        pageOffres: 'Taux',
        pageEveilAssurance: 'Assurance prêt',
        pageModalites: 'Modalités',
        pageModalitesDatePaiement: 'Date de paiement',
        pageInfoSignature: 'Signature du contrat',
        pageValidation: 'Validation'
    }
};
