import { AxiosResponse } from 'axios';
import { useMutation } from 'react-query';

import { BASCULER_VERS_ASSURANCE_END_POINT } from '../../apiEndPoints';
import {
    axiosInstance,
    reactQueryOptions,
    IMutationResult,
    getDynamicHeaders
} from '../../../../api';

const basculerVersAssurance = async (
    idDemande: number
): Promise<AxiosResponse<any>> => {
    const endPoint = BASCULER_VERS_ASSURANCE_END_POINT.replace(
        /{idDemande}/g,
        String(idDemande)
    );

    const { data } = await axiosInstance.get(endPoint, getDynamicHeaders());

    return data;
};

export const useAssuranceApi = (): IMutationResult => {
    return useMutation(basculerVersAssurance, {
        ...reactQueryOptions()
    });
};
