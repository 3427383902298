export default {
    en: {
        boutonFermerDetailsOffre: 'Close',
        tauxPesonnaliseTitre: 'Calculating your personalized rate',
        avantagesTitre: 'Benefits',
        pourQuiTitre: 'Who is this product for?',
        boutonOuvrirSRPrefix: 'See ',
        boutonOuvrirSRSuffix: ' mortgage details.',

        // 1 an fixe fermé
        titre1AFF: '1-year fixed rate',
        description1AFF_1: 'Closed fixed-rate mortgage',
        description1AFF_2: 'Term of 1 year',
        avantages1AFF_1:
            'The mortgage can be renewed in the short term to accommodate future plans',
        avantages1AFF_2: 'Payment and rate security for 1 year',
        pourQui1AFF_1:
            'Those with a moderate to high tolerance for rate fluctuations',
        pourQui1AFF_2: 'Those looking for budget stability during the term',

        // 2 ans fixe fermé
        titre2AFF: '2-year fixed rate',
        description2AFF_1: 'Closed fixed-rate mortgage',
        description2AFF_2: 'Term of 2 years',
        avantages2AFF_1:
            'The mortgage can be renewed in the short term to accommodate future plans',
        avantages2AFF_2: 'Payment and rate security for 2 years',
        pourQui2AFF_1:
            'Those with a moderate to high tolerance for rate fluctuations',
        pourQui2AFF_2: 'Those looking for budget stability during the term',

        // 3 ans fixe fermé ou X mois fixe
        titreFF: ' fixed rate',
        descriptionFF_1: 'Closed fixed-rate mortgage',
        descriptionFF_2: 'Term of ',
        avantagesFF_1:
            'The mortgage can be renewed in the medium term to accommodate future plans',
        avantagesFF_2: 'Payment and rate security for ',
        pourQuiFF_1: 'Those with a moderate tolerance for rate fluctuations',
        pourQuiFF_2: 'Those looking for budget stability',

        // 4 ans fixe fermé
        titre4AFF: '4-year fixed rate',
        description4AFF_1: 'Closed fixed-rate mortgage',
        description4AFF_2: 'Term of 4 years',
        avantages4AFF_1: 'Payment and rate security for 4 years',
        pourQui4AFF_1: 'Those with a low tolerance for rate fluctuations',
        pourQui4AFF_2: 'Those looking for budget stability',

        // 5 ans fixe fermé
        description5AFF_1: 'Closed fixed-rate mortgage',
        description5AFF_2: 'Term of ',
        avantages5AFF_1: 'Payment and rate security for ',
        pourQui5AFF_1: 'Those with a low tolerance for rate fluctuations',
        pourQui5AFF_2: 'Those looking for budget stability',

        // 7 ans fixe fermé
        titre7AFF: '7-year fixed rate',
        description7AFF_1: 'Closed fixed-rate mortgage',
        description7AFF_2: 'Term of 7 years',
        avantages7AFF_1: 'Payment and rate security for 7 years',
        pourQui7AFF_1: 'Those with a low tolerance for rate fluctuations',
        pourQui7AFF_2: 'Those looking for budget stability',
        pourQui7AFF_3:
            "Those who don't plan to sell their property in the next 7 years",
        pourQui7AFF_4_1: 'Those who understand the ',
        pourQui7AFF_4_2: 'financial consequences',
        pourQui7AFF_4_3: ' of paying their mortgage early',
        pourQui7AFF_5:
            'Occasionally getting lower interest rates is not a priority in the short or medium term',

        // 5 ans révisable annuellement
        titre5ARF: '5-year Yearly Rate Resetter',
        description5ARF_1: '1-year fixed rate',
        description5ARF_2: 'Rate and payment revised annually',
        description5ARF_3_1: '',
        description5ARF_3_2: '% rebate on revised rate beginning in year 2',
        description5ARF_4: '5-year term',
        avantages5ARF_1: 'Get a higher rebate on your interest rate in year 1',
        avantages5ARF_2:
            'Pay no penalties to convert to a fixed-rate mortgage with a pre-determined discount, at any time',
        avantages5ARF_3: 'Get more security than with a variable-rate mortgage',
        avantages5ARF_4:
            'Enjoy fixed payments for 12 months (adjusted every year at term renewal)',
        avantages5ARF_5:
            'Benefit from a historically more cost-effective solution than a 5-year fixed rate mortgage',
        pourQui5ARF_1: 'Those with a moderate tolerance for rate fluctuations',

        // 5 ans variable réduit
        titre5AVR: '5-year reduced variable rate',
        description5AVR_1:
            'Closed variable-rate mortgage based on the prime rate +/- a guaranteed spread on 5 years',
        avantages5AVR_1:
            'With certain conditions, can be converted to a closed fixed-rate mortgage at any time, without penalty',
        avantages5AVR_2: 'Historically lower interest than with fixed rates',
        pourQui5AVR_1: 'Those with a high tolerance for rate fluctuations',
        pourQui5AVR_2: 'Those looking to pay the least in interest',
        boutonLabelDetailsOffre: 'See mortgage details',
        boutonLabelDetailsChoixCoEmprunteur: 'See selected offer',

        // 5 ans variable protegé
        titre5AVP: '5-year protected variable rate',
        description5AVP_1:
            'We give you a guaranteed spread that is applied to the Desjardins prime rate for the duration of the term.',
        description5AVP_2:
            'The Desjardins prime rate may fluctuate and change your personalized rate. Your rate is protected and will never exceed the maximum rate set at the start, no matter how the prime rate fluctuates.',
        avantages5AVP_1: 'Maximum rate established from the start',
        avantages5AVP_2:
            'Rate based on the prime rate plus or minus a guaranteed spread',
        avantages5AVP_3:
            'Possible rate decrease or increase without exceeding the maximum rate',
        avantages5AVP_4:
            'Fixed payments based on the maximum rate (ceiling rate)',
        avantages5AVP_5:
            'Convertible to a fixed-rate mortgage at any time without penalty, under certain conditions',
        pourQui5AVP_1: 'You can moderately tolerate rate fluctuations.',
        pourQui5AVP_2:
            'You want to know the maximum rate from the start to avoid any surprises.',
        pourQui5AVP_3:
            'You’re able to make payments based on the maximum rate.',
        pourQui5AVP_4:
            'You can pay off your principal quicker while the personalized rate is below the maximum rate.',
        pourQui5AVP_5:
            'You want to pay the least interest possible, while still being protected by the maximum rate.',
        pourQui5AVP_6: 'You plan to keep the property for at least 5 years.'
    },
    fr: {
        boutonFermerDetailsOffre: 'Fermer',
        tauxPesonnaliseTitre: 'Calcul du taux personnalisé',
        avantagesTitre: 'Avantages',
        pourQuiTitre: 'Pour qui?',
        boutonOuvrirSRPrefix: 'Voir le détail du prêt ',
        boutonOuvrirSRSuffix: '.',

        // 1 an fixe fermé
        titre1AFF: '1 an fixe',
        description1AFF_1: 'Taux fixe fermé',
        description1AFF_2: 'Terme de 1 an',
        avantages1AFF_1:
            'Possibilité de renouveler à court terme pour projets à venir',
        avantages1AFF_2: 'Sécurité des paiements et du taux sur 1 an',
        pourQui1AFF_1: 'Tolérance modérée à élevée aux fluctuations de taux',
        pourQui1AFF_2: 'Recherche la stabilité budgétaire pendant le terme',

        // 2 ans fixe fermé
        titre2AFF: '2 ans fixe',
        description2AFF_1: 'Taux fixe fermé',
        description2AFF_2: 'Terme de 2 ans',
        avantages2AFF_1:
            'Possibilité de renouveler à court terme pour projets à venir',
        avantages2AFF_2: 'Sécurité des paiements et du taux sur 2 ans',
        pourQui2AFF_1: 'Tolérance modérée à élevée aux fluctuations de taux',
        pourQui2AFF_2: 'Recherche la stabilité budgétaire pendant le terme',

        // 3 ans fixe fermé ou X mois fixe
        titreFF: ' fixe',
        descriptionFF_1: 'Taux fixe fermé',
        descriptionFF_2: 'Terme de ',
        avantagesFF_1:
            'Possibilité de renouveler à moyen terme pour projets à venir',
        avantagesFF_2: 'Sécurité des paiements et du taux sur ',
        pourQuiFF_1: 'Tolérance modérée aux fluctuations de taux',
        pourQuiFF_2: 'Recherche la stabilité budgétaire',

        // 4 ans fixe fermé
        titre4AFF: '4 ans fixe',
        description4AFF_1: 'Taux fixe fermé',
        description4AFF_2: 'Terme de 4 ans',
        avantages4AFF_1: 'Sécurité des paiements et du taux sur 4 ans',
        pourQui4AFF_1: 'Tolérance faible aux fluctuations de taux',
        pourQui4AFF_2: 'Recherche la stabilité budgétaire',

        // 5 ans fixe fermé
        description5AFF_1: 'Taux fixe fermé',
        description5AFF_2: 'Terme de ',
        avantages5AFF_1: 'Sécurité des paiements et du taux sur ',
        pourQui5AFF_1: 'Tolérance faible aux fluctuations de taux',
        pourQui5AFF_2: 'Recherche la stabilité budgétaire',

        // 7 ans fixe fermé
        titre7AFF: '7 ans fixe',
        description7AFF_1: 'Taux fixe fermé',
        description7AFF_2: 'Terme de 7 ans',
        avantages7AFF_1: 'Sécurité des paiements et du taux sur 7 ans',
        pourQui7AFF_1: 'Tolérance faible aux fluctuations de taux',
        pourQui7AFF_2: 'Recherche la stabilité budgétaire',
        pourQui7AFF_3:
            'Intention de conserver sa propriété pour les 7 prochaines années',
        pourQui7AFF_4_1: 'Comprend bien ',
        pourQui7AFF_4_2: 'les risques et les conséquences',
        pourQui7AFF_4_3:
            ' financières associés à un remboursement avant l’échéance, en cas d’imprévus',
        pourQui7AFF_5:
            "Ne cherche pas la flexibilité des baisses potentielles des taux d'intérêt à court ou moyen terme",

        // 5 ans révisable annuellement
        titre5ARF: '5 ans révisable annuellement',
        description5ARF_1: 'Taux fixe d’un an',
        description5ARF_2: 'Taux et paiement révisés chaque année',
        description5ARF_3_1: 'Rabais de ',
        description5ARF_3_2: '% sur le taux révisé à partir de la 2e année',
        description5ARF_4: 'Terme de 5 ans',
        avantages5ARF_1:
            'Rabais plus élevé sur votre taux d’intérêt la première année',
        avantages5ARF_2:
            'Peut être converti en prêt à taux fixe avec un rabais préétabli en tout temps, sans indemnité',
        avantages5ARF_3: 'Plus sécuritaire qu’un prêt à taux variable',
        avantages5ARF_4:
            'Versements fixes pendant 12 mois, ajustés chaque année à la date anniversaire du terme',
        avantages5ARF_5:
            'Historiquement plus économique qu’un prêt à taux fixe 5 ans',
        pourQui5ARF_1: 'Tolérance modérée aux fluctuations de taux',

        // 5 ans variable réduit
        titre5AVR: '5 ans variable réduit',
        description5AVR_1:
            'Taux variable fermé basé sur le taux préférentiel plus ou moins un écart garanti sur 5 ans',
        avantages5AVR_1:
            'Possibilité de convertir en taux fixe fermé en tout temps, sans indemnité et à certaines conditions',
        avantages5AVR_2: 'Historiquement plus bas que les taux fixes',
        pourQui5AVR_1: 'Tolérance élevée aux fluctuations de taux',
        pourQui5AVR_2: 'Recherche d’un potentiel coût d’intérêt minimal',
        boutonLabelDetailsOffre: 'Voir le détail du prêt',
        boutonLabelDetailsChoixCoEmprunteur: "Voir l'offre choisie",

        // 5 ans variable protegé
        titre5AVP: '5 ans variable protégé',
        description5AVP_1:
            'Nous vous attribuons un écart garanti appliqué au taux préférentiel Desjardins pour la durée du terme.',
        description5AVP_2:
            'Le taux préférentiel Desjardins peut varier et modifier le taux personnalisé. Votre taux est protégé et il ne dépassera jamais le taux maximum établi dès le départ, peu importe les variations du taux préférentiel.',
        avantages5AVP_1: 'Taux maximum fixé dès le départ',
        avantages5AVP_2:
            'Taux basé sur le taux préférentiel plus ou moins un écart garanti',
        avantages5AVP_3:
            'Possible variation du taux à la baisse ou à la hausse sans dépasser le taux maximum',
        avantages5AVP_4:
            'Paiements fixes établis en fonction du taux maximum (taux plafond)',
        avantages5AVP_5:
            'Conversion en taux fixe disponible en tout temps, sans frais d’indemnité et à certaines conditions',
        pourQui5AVP_1:
            'Vous pouvez tolérer avec modération les variations de taux.',
        pourQui5AVP_2:
            'Vous voulez connaitre le taux maximum dès le départ pour éviter toute surprise.',
        pourQui5AVP_3:
            'Vous êtes en mesure de faire un paiement basé sur le taux maximum.',
        pourQui5AVP_4:
            'Vous pouvez rembourser votre capital plus vite tant que le taux personnalisé n’atteint pas le taux maximum.',
        pourQui5AVP_5:
            'Vous souhaitez payer le moins d’intérêt possible tout en restant protégé par le taux maximum.',
        pourQui5AVP_6: 'Vous prévoyez conserver la propriété au moins 5 ans.'
    }
};
