import React from 'react';

type BannerVariant = 'tip' | 'improvement' | 'suggestion';

type TitleLevel = 1 | 2 | 3 | 4 | 5 | 6;

interface IBannerProps {
    analytics?: string;
    dark?: boolean;
    show?: boolean;
    variant?: BannerVariant;
    hideCloseButton?: boolean;
    titleLevel?: TitleLevel;
    title: string;
    content: string;
    actions?: string;
    closeSrText?: string;
    dataTestId?: string;
}

export const Banner: React.FC<IBannerProps> = ({
    analytics,
    dark,
    show,
    variant,
    hideCloseButton,
    titleLevel,
    title,
    content,
    actions,
    closeSrText,
    dataTestId
}) => {
    return (
        // @ts-ignore
        <dsd-banner
            analytics={analytics}
            dark={dark}
            show={show}
            variant={variant}
            hideCloseButton={hideCloseButton}
            titleLevel={titleLevel}
            data-testId={dataTestId}
        >
            <span slot="title">{title}</span>
            <span slot="content">{content}</span>
            <span slot="actions">{actions}</span>
            <span slot="close-sr-text">{closeSrText}</span>
            {/* @ts-ignore */}
        </dsd-banner>
    );
};
