import { useMediaQuery } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
    Typography,
    Variant,
    Grid,
    GridRow,
    GridCol,
    ContainerMui,
    ColorHex,
    Police
} from '../../styles';
import { ISignature } from '../../hooks';

interface ISignatureProps {
    signature: ISignature;
}

export const Signature: React.FC<ISignatureProps> = ({ signature }) => {
    const [t] = useTranslation(['Signature']);
    const isMiniMobile = useMediaQuery('(max-width:380px)');
    const isMobile = useMediaQuery('(max-width:639px)');
    const isTablet = useMediaQuery('(max-width:991px)');
    const isDesktop = useMediaQuery('(min-width:992px)');

    // State
    const [paddingIframe, setPaddingIframe] = useState('30%');

    // Responsive part
    useEffect(() => {
        if (isMiniMobile) {
            setPaddingIframe('140%');
        } else if (isMobile) {
            setPaddingIframe('150%');
        } else if (isTablet) {
            setPaddingIframe('90%');
        } else if (isDesktop) {
            setPaddingIframe('45%');
        }
    }, [isMiniMobile, isMobile, isTablet, isDesktop]);

    const renderHeader = () => (
        <>
            <Typography
                variant={Variant.p}
                police={Police.bold}
                color={ColorHex.lightGraphite5}
            >
                {t('titleRhn')}
            </Typography>
            <Typography
                variant={Variant.h1}
                dataTestId="titre_signature_integree"
            >
                {t('title')}
            </Typography>
        </>
    );

    return (
        <>
            <Grid>
                {/* Header */}
                <GridRow>
                    <GridCol col="12" sm="12" md="12">
                        {renderHeader()}
                    </GridCol>
                </GridRow>
                {/* Legal */}
                <ContainerMui padding="0rem">
                    <GridRow>
                        {/* Legal sommaire */}
                        <GridCol col="12" sm="12" md="12">
                            <div
                                style={{
                                    position: 'relative',
                                    paddingBottom: paddingIframe,
                                    paddingTop: '30px',
                                    height: 0,
                                    overflow: 'auto',
                                    border: 'solid black 1px'
                                }}
                            >
                                <iframe
                                    src={signature?.url}
                                    style={{
                                        position: 'absolute',
                                        top: 0,
                                        left: 0,
                                        width: '100%',
                                        height: '100%'
                                    }}
                                />
                            </div>
                        </GridCol>
                    </GridRow>
                </ContainerMui>
            </Grid>
        </>
    );
};
