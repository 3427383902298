import React from 'react';

import {
    Box,
    CardMedia,
    createStyles,
    makeStyles,
    useMediaQuery
} from '@material-ui/core';
import {
    GridCol,
    GridRow,
    LinkAction,
    LinkActionTheme,
    LinkActionType,
    LinkActionVariant,
    Police,
    Spacer,
    Typography,
    Variant
} from '../../../../styles';
import { useTranslation } from 'react-i18next';
import { ContexteAppel, getParlerConseillerUrl } from '../../../../utils';

interface IDeeplinkSansPretProps {
    trackAnalytic: () => void;
}

const useStyles = makeStyles(() =>
    createStyles({
        imageMobile: {
            borderRadius: '1rem 1rem 0 0',
            height: '100%',
            paddingBottom: '55%',
            backgroundSize: '100%',
            backgroundPosition: 'center',
            position: 'relative',
            display: 'flex',
            justifyContent: 'center'
        },
        imageDesktop: {
            height: '100%',
            position: 'relative',
            backgroundSize: '100%',
            backgroundPosition: '50% 14%',
            display: 'flex',
            justifyContent: 'center',
            borderRadius: '0 1rem 1rem 0'
        }
    })
);

export const DeeplinkSansPret: React.FC<IDeeplinkSansPretProps> = ({
    trackAnalytic
}) => {
    const classes = useStyles();
    const [t] = useTranslation(['DeeplinkSansPret']);
    const isTablet = useMediaQuery('(max-width:990px)');

    return (
        <>
            <GridRow>
                {/* image pour mobile ou tablette */}
                <GridCol col="12" className="md:dsd-d-none">
                    <CardMedia
                        className={classes.imageMobile}
                        image={'/images/page_deeplink_mobile.jpg'}
                    />
                </GridCol>
            </GridRow>
            <GridRow>
                <GridCol lg="6" md="6" sm="12">
                    <Box padding={isTablet ? '0.75rem' : '1.5rem'}>
                        <Box paddingLeft="1rem">
                            <GridRow>
                                <Typography
                                    variant={Variant.h4}
                                    police={Police.bold}
                                    marginBottom={'1rem'}
                                >
                                    {t('title')}
                                </Typography>
                            </GridRow>
                            <GridRow>
                                <Typography
                                    variant={Variant.p}
                                    paddingRight="0.75rem"
                                >
                                    {t('titleDescription')}
                                </Typography>
                            </GridRow>
                            <GridRow>
                                <Typography
                                    variant={Variant.h6}
                                    police={Police.bold}
                                >
                                    {t('subtitle')}
                                </Typography>
                            </GridRow>
                            <GridRow>
                                <Typography
                                    variant={Variant.p}
                                    paddingRight="0.75rem"
                                >
                                    {t('subtitleDescription')}
                                </Typography>
                            </GridRow>
                        </Box>
                        <Spacer all={isTablet ? 3 : 2} />
                        <LinkAction
                            href={getParlerConseillerUrl(
                                ContexteAppel.PAGE_INTERMEDIAIRE_DEEPLINK,
                                null
                            )}
                            type={LinkActionType.external}
                            variant={LinkActionVariant.primary}
                            theme={LinkActionTheme.green}
                            dataTestId="parler-conseiller-deeplink-aucunpret"
                            ariaLabel={t('click2call_aria')}
                            onClick={trackAnalytic}
                        >
                            {t('parlerConseiller')}
                        </LinkAction>
                    </Box>
                </GridCol>
                {/* image pour desktop */}
                <GridCol col="6" className="dsd-d-none md:dsd-d-block">
                    <CardMedia
                        className={classes.imageDesktop}
                        image="/images/page_deeplink_desktop.jpg"
                    />
                </GridCol>
            </GridRow>
        </>
    );
};
