import { UseQueryResult, useQuery } from 'react-query';
import {
    axiosInstance,
    getDynamicHeaders,
    reactCachedQueryOptions
} from '../../../../api';

export enum PageInitiale {
    ANNULEE_SYSTEME = 'ANNULEE_SYSTEME',
    ANNULEE_CONSEILLER = 'ANNULEE_CONSEILLER',
    ERREUR = 'ERREUR',
    ACCUEIL = 'ACCUEIL',
    OFFRE = 'OFFRE',
    OFFRE_CHOISIE_PAR_GUY = 'OFFRE_CHOISIE_PAR_GUY',
    MODALITES = 'MODALITES',
    FREQUENCE = 'FREQUENCE',
    FINALISATION = 'FINALISATION',
    RESUME = 'RESUME',
    CONFIRMATION = 'CONFIRMATION',
    EVEIL_ASSURANCE = 'EVEIL_ASSURANCE'
}

export enum StatutDemande {
    INITIALE = 'INITIALE',
    EN_ATTENTE = 'EN_ATTENTE',
    ACCEPTEE = 'ACCEPTEE',
    ANNULEE_CONSEILLER = 'ANNULEE_CONSEILLER',
    ANNULEE_SYSTEME = 'ANNULEE_SYSTEME',
    TERMINEE = 'TERMINEE'
}

export enum PersonneConnectee {
    MEMBRE = 'MEMBRE',
    CONSEILLER = 'CONSEILLER_ACCESD'
}

export enum ParcoursEmprunteur {
    UNIQUE = 'UNIQUE',
    COEMPRUNTEUR_GUY = 'COEMPRUNTEUR_GUY',
    COEMPRUNTEUR_MARTINE = 'COEMPRUNTEUR_MARTINE'
}

type contextePret = {
    idDemande: number;
    descriptionPerso: string;
};

type contexteToggleFeature = {
    calculScenarioPaiement: boolean;
};

type approbationCoemprunteur = {
    delaiApprobation: number;
    dateLimite: string;
    dateRappel: string;
};

export interface ISession {
    pageInitiale: PageInitiale;
    statutDemande: StatutDemande;
    langue: string;
    typeParcoursEmprunteur: ParcoursEmprunteur;
    approbationCoemprunteur: approbationCoemprunteur;
    codeAvis: string | null;
    dateSysteme?: string;
    personneConnectee?: PersonneConnectee;
    contextePret?: contextePret;
    contexteToggleFeature?: contexteToggleFeature;
    isConseiller?: boolean;
    isFastLoading?: boolean;
    isMartine?: boolean;
}

export const END_POINT = '/contextes/session';

function postLoadingCheck(session: ISession): ISession {
    // verification si conseiller
    session.isConseiller =
        PersonneConnectee.CONSEILLER === session?.personneConnectee;
    // Verification si on est martine
    session.isMartine =
        session?.typeParcoursEmprunteur ===
        ParcoursEmprunteur.COEMPRUNTEUR_MARTINE;
    // Chargement rapide de la page acceuil ?
    session.isFastLoading =
        session?.pageInitiale === PageInitiale.ACCUEIL && !session.isMartine;
    return session;
}

const fetch = async () => {
    const { data } = await axiosInstance.get(END_POINT, getDynamicHeaders());

    return postLoadingCheck(data);
};

export function useSessionApi(
    isEnable = true,
    cache = 'cache'
): UseQueryResult<ISession, Error> {
    return useQuery<ISession, Error>(['session', cache], () => fetch(), {
        ...reactCachedQueryOptions(),
        enabled: isEnable
    });
}
