import React from 'react';

export enum AlertType {
    confirmation = 'confirmation',
    error = 'error',
    inactive = 'inactive',
    information = 'information',
    warning = 'warning'
}

type TitleLevel = 1 | 2 | 3 | 4 | 5;

interface IAlert {
    title?: string;
    titleLevel?: TitleLevel;
    type?: AlertType;
    isFullWidth?: boolean;
    dynamic?: boolean;
    isDark?: boolean;
    children: React.ReactNode;
    id?: string;
    dataTestId?: string;
    className?: string;
}

export const Alert: React.FC<IAlert> = ({
    title,
    titleLevel,
    type,
    isFullWidth,
    dynamic,
    isDark,
    children,
    id,
    dataTestId,
    className
}) => (
    // @ts-ignore
    <dsd-alert
        type={type}
        full-width={isFullWidth}
        dynamic={dynamic}
        dark={isDark}
        id={id}
        data-testid={dataTestId}
        class={className}
        title-level={titleLevel}
    >
        <h2 slot="title">{title}</h2>
        {children}
        {/* @ts-ignore */}
    </dsd-alert>
);
