// --------- Base endpoints ---------
export const CONTEXTE_END_POINT = '/contextes';
export const DEMANDE_END_POINT = '/demandes/{idDemande}';

// --------- Specific endpoints ---------
//Permet la mise a jour du statut lors de la marche arriere
export const STATUT_EMPRUNTEUR_END_POINT =
    DEMANDE_END_POINT + '/emprunteurs/actuel';

// Page eveil au besoin
// Remet l'offre choisie par default sans assurance
export const RESET_OFFRE_CHOISIE_END_POINT =
    DEMANDE_END_POINT + '/emprunteurs/actuel/offres/choisie/reset';

// Page Validation
// Guy ou Unique
// Verifier si codeAvis
export const CODE_AVIS_END_POINT =
    DEMANDE_END_POINT + '/emprunteurs/actuel/codeavis';

// Page offres
// Lie idOffre -> idOffreChoisie
// MET LE STATUT MODALITES ( / EVEIL_ASSURANCE )
// Ou a InformationSignature si Offre choisie VP
export const PRESELECTIONNER_OFFRE_END_POINT =
    DEMANDE_END_POINT + '/emprunteurs/actuel/offres/{idOffre}/preselectionner';

// Pages Modalites + Choix 1er coemprunteur
// Lie idOffre -> idOffreChoisie
// Cree une offre modifie + lie -> idOffreChoisie
// Met le statut a "OFFRE_SELECTIONNEE"
export const SELECTIONNER_OFFRE_END_POINT =
    DEMANDE_END_POINT + '/emprunteurs/actuel/offres/{idOffre}/selectionner';

// Page Choix 1er coemprunteur ( Martine )
// Lie idOffre -> idOffreChoisie
// Si jamais assurance  -> "ASSURANCE" + Cookies + redirect URI
// Sinon statut "OFFRE_SELECTIONNEE"

export const SELECTIONNER_OFFRE_COEMPRUNTEUR_END_POINT =
    DEMANDE_END_POINT +
    '/emprunteurs/coemprunteur/offres/{idOffre}/selectionner';
export const CONFIRMER_CONVENTION_END_POINT =
    DEMANDE_END_POINT + '/conventions';
export const CONFIRMER_OFFRE_END_POINT =
    DEMANDE_END_POINT + '/emprunteurs/actuel/offres/{idOffre}/confirmer';

// Endpoint pour basculer vers l'assurance (si dans les bonnes conditions)
export const BASCULER_VERS_ASSURANCE_END_POINT =
    DEMANDE_END_POINT + '/emprunteurs/actuel/assurance/redirect';

// Endpoint pour obtenir le solde de fin de terme si applicable
export const SOLDE_FIN_DE_TERME_END_POINT =
    DEMANDE_END_POINT + '/emprunteurs/actuel/offres/choisie/soldeFinTerme';

// Endpoint pour config server. n'utilise pas base url
export const AUHT0_CONFIG_SERVER_ENDPOINT =
    'https://{domain}/wapi/config-server/clients/{clientId}/auth0js';

// Deeplink - Obtenir les idDemandes si existantes et en cours
export const EMPRUNTEURS_ACTUEL_DEMANDES = '/emprunteurs/actuel/demandes';

// Signature integree
export const SIGNATURE_ADMISSIBILITE_ENDPOINT =
    DEMANDE_END_POINT + '/signature/admissibilite';
export const SIGNATURE_ENDPOINT = DEMANDE_END_POINT + '/signature';
export const SIGNATURE_STATUT_ENDPOINT =
    DEMANDE_END_POINT + '/signature/{idContrat}/statut';
export const SIGNATURE_ABANDONNE_ENDPOINT =
    DEMANDE_END_POINT + '/signature/{idCeremonie}/abandonner';
export const SIGNATURE_CONFIRMATION_ENDPOINT =
    DEMANDE_END_POINT + '/emprunteurs/actuel/confirmer';
export const SIGNATURE_ACTUALISER_OFFRE_GUY =
    DEMANDE_END_POINT + '/emprunteurs/actuel/offres/actualiser';
