import React, { useEffect, useRef } from 'react';

export enum AccordionSize {
    MD = 'md', // default
    XS = 'xs'
}

type TitleLevel = 1 | 2 | 3 | 4 | 5 | 6;

interface IAccordion {
    id?: string;
    dataTestId?: string;
    analytics?: { [key: string]: string };
    dark?: boolean;
    open?: boolean;
    size?: AccordionSize;
    titleLevel?: TitleLevel;
    onOpenOrClose?: (isOpen: boolean) => void;
}

export const Accordion: React.FC<IAccordion> = ({
    id,
    dataTestId,
    analytics,
    dark,
    open,
    size,
    titleLevel,
    children,
    onOpenOrClose
}) => {
    const ref = useRef(null);
    const handleOpenOrClose = (e) => {
        onOpenOrClose(e.target.open);
    };

    useEffect(() => {
        ref.current.addEventListener('dsdAccordionChange', handleOpenOrClose);
    }, []);

    return (
        // @ts-ignore
        <dsd-accordion
            ref={ref}
            id={id}
            data-testid={dataTestId}
            analytics={analytics}
            dark={dark}
            open={open}
            size={size}
            title-level={titleLevel}
        >
            {children}
            {/* @ts-ignore */}
        </dsd-accordion>
    );
};
