import React from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { RepartitionDonut } from './RepartitionDonut';
import {
    sendEvent,
    EVENT_CATEGORIES,
    EVENT_ACTIONS,
    EVENT_LABELS
} from '../../../../features';

export const RepartitionDonutConnected: React.FC<unknown> = () => {
    const dispatch = useDispatch();
    // Selectors
    const offreModifiee = useSelector(
        (state: any) => state.modalites.offreModifiee
    );

    const handleOnTooltipActive = (label) => {
        dispatch(
            sendEvent({
                category: EVENT_CATEGORIES.RHN,
                action: EVENT_ACTIONS.CLIC,
                label: EVENT_LABELS.INFOBULLE + label
            })
        );
    };

    return (
        <>
            {offreModifiee && (
                <RepartitionDonut
                    amortissementRestant={offreModifiee?.amortissement}
                    montantCapital={
                        offreModifiee?.paiementsEtPourcentagesOffreSelectionnee
                            ?.montantTotalCapital
                    }
                    montantInteret={
                        offreModifiee?.paiementsEtPourcentagesOffreSelectionnee
                            ?.montantTotalInteret
                    }
                    montantAssurance={
                        offreModifiee?.paiementsEtPourcentagesOffreSelectionnee
                            ?.montantTotalAssurancesVie +
                        offreModifiee?.paiementsEtPourcentagesOffreSelectionnee
                            ?.montantTotalAssurancesInvalidite
                    }
                    onTooltipActive={handleOnTooltipActive}
                />
            )}
        </>
    );
};
