import { mockInstance, withDelay } from '../../../../api/axiosInstance.mock';

import { SELECTIONNER_OFFRE_COEMPRUNTEUR_END_POINT } from '../../apiEndPoints';
import { dataCoEmprunteur1 as sessionMockData } from '../../contexte/useSessionApi/useSessionApi.mock';

const endPoint = SELECTIONNER_OFFRE_COEMPRUNTEUR_END_POINT.replace(
    /{idDemande}/g,
    String(sessionMockData?.contextePret?.idDemande)
).replace(/{idOffre}/g, String('[0-9]+'));

export const useSelectionnerOffreCoemprunteurApiMock = (): any => {
    const url = new RegExp(`${endPoint}`);

    mockInstance.onPut(url).reply(
        withDelay(
            [
                200,
                {
                    pageInitiale: 'OFFRE_SELECTIONNEE',
                    codeAvis: null
                    // Utile pour tester le redirect - uncomment ligne en dessous
                    // , redirectUrl: 'https://soumission-assurance-produit-financier-spa-dev.apps.cfzcea.dev.desjardins.com/'
                }
            ],
            200
        )
    );
};
