import { mockInstance, withDelay } from '../../../../api/axiosInstance.mock';

import { ISoldeFinTerme } from './useSoldeFinTermeApi';
import { SOLDE_FIN_DE_TERME_END_POINT } from '../../apiEndPoints';
import { dataCoEmprunteur1 as sessionMockData } from '../../contexte/useSessionApi/useSessionApi.mock';

export const data: ISoldeFinTerme = {
    soldeFinTerme: 12345.67,
    soldeFinTermeSansAssurance: 1234.56
};

const endPoint = SOLDE_FIN_DE_TERME_END_POINT.replace(
    /{idDemande}/g,
    String(sessionMockData?.contextePret?.idDemande)
);

export const useSoldeFinTermeApiMock = (): any =>
    mockInstance.onGet(endPoint).reply(withDelay([200, data], 200));
