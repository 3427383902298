import React, { useEffect } from 'react';

import { Navigation } from './AnnuleeSysteme.navigation';
import { AnnuleeSysteme } from './AnnuleeSysteme';
import { useDispatch } from 'react-redux';
import { hideLoading } from '../../features';

export const AnnuleeSystemeConnected: React.FC<unknown> = () => {
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(hideLoading());
    }, []);

    return (
        <>
            <AnnuleeSysteme />
            <Navigation />
        </>
    );
};
