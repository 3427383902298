import React from 'react';
import { NoteLegaleEveilAssurance } from './NoteLegaleEveilAssurance';
import { useRenouvellementApi, useSessionApi } from '../../hooks';

export const NoteLegaleEveilAssuranceConnected: React.FC<unknown> = () => {
    const { data: session } = useSessionApi();
    const { data: renouvellement } = useRenouvellementApi(!!session);

    return (
        <>
            {renouvellement?.emprunteurConnecte
                ?.admissibleAssuranceInvalidite && <NoteLegaleEveilAssurance />}
        </>
    );
};
