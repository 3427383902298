import { ParcoursAssurance } from '../../hooks';

export enum ParcoursAssuranceCybermetrie {
    Parcours1 = 'APMAT',
    Parcours2 = 'APMAT-A',
    Parcours3 = 'AP'
}

export const getCybermetrieAttribut2 = (
    parcoursAssurance?: ParcoursAssurance
): ParcoursAssuranceCybermetrie => {
    switch (parcoursAssurance) {
        case ParcoursAssurance.Parcours1:
            return ParcoursAssuranceCybermetrie.Parcours1;
        case ParcoursAssurance.Parcours3:
            return ParcoursAssuranceCybermetrie.Parcours3;
        case ParcoursAssurance.Parcours2:
        default:
            return ParcoursAssuranceCybermetrie.Parcours2;
    }
};
