import React from 'react';
import Radio from '@material-ui/core/Radio';
import FormControlLabel from '@material-ui/core/FormControlLabel';

type Placement = 'top' | 'end' | 'bottom' | 'start';

interface IRadioMui {
    label?: string;
    placement?: Placement;
    value: string;
    isChecked?: boolean;
    dataTestId?: string;
    onChange: () => void;
}

export const RadioMui: React.FC<IRadioMui> = ({
    label,
    placement,
    value,
    isChecked,
    dataTestId,
    onChange
}) => {
    return (
        <FormControlLabel
            data-testid={dataTestId}
            value={value}
            checked={isChecked}
            control={<Radio color="primary" />}
            label={label}
            labelPlacement={placement}
            onChange={onChange}
        />
    );
};
