import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Accordion, Police, Typography, Variant } from '../../../../styles';

interface AccordionMargeAtoutProps {
    isAccordionOpen: boolean;
    onAccordionOpenOrClose: (isOpen: boolean) => void;
}

export const AccordionMargeAtout: React.FC<AccordionMargeAtoutProps> = ({
    isAccordionOpen,
    onAccordionOpenOrClose
}) => {
    const [t] = useTranslation(['EveilAssurance']);

    return (
        <Accordion
            dataTestId="accordionMargeAtout"
            titleLevel={4}
            open={isAccordionOpen}
            onOpenOrClose={(isOpen: boolean) => {
                onAccordionOpenOrClose(isOpen);
            }}
        >
            <span slot="title">{t('accordionMargeAtoutTitre')}</span>
            <Typography variant={Variant.h5} police={Police.bold}>
                {t('accordionMargeAtoutSousTitre1')}
            </Typography>
            <Typography variant={Variant.p}>
                <Trans i18nKey="EveilAssurance:accordionMargeAtoutDescription1" />
            </Typography>
            <Typography variant={Variant.h5} police={Police.bold}>
                {t('accordionMargeAtoutSousTitre2')}
            </Typography>
            <Typography variant={Variant.p}>
                {t('accordionMargeAtoutDescription2')}
            </Typography>
            <Typography variant={Variant.h5} police={Police.bold}>
                {t('accordionMargeAtoutSousTitre3')}
            </Typography>
            <Typography variant={Variant.p}>
                {t('accordionMargeAtoutDescription3_1')}
            </Typography>
            <Typography variant={Variant.p}>
                {t('accordionMargeAtoutDescription3_2')}
            </Typography>
            <Typography variant={Variant.p}>
                {t('accordionMargeAtoutDescription3_3')}
            </Typography>
        </Accordion>
    );
};
