import React from 'react';
import { useMediaQuery } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

import {
    GridCol,
    Variant,
    Hr,
    Typography,
    Police,
    ColorHex,
    Checkbox,
    Spacer,
    ContainerMui
} from '../../../../styles';

import { ParcoursEmprunteur } from '../../../../hooks';

interface IConsentementInfoSignature {
    checkBoxRef: any;
    isAdmissibleSI: boolean;
    parcoursEmprunteur: string;
    isAssuranceAjoute: boolean;
    isOntario: boolean;
    isCheckBoxError: boolean;
    prenomNomMartine: string;
    onCheckBoxClick: (isChecked: boolean) => void;
}

export const ConsentementInfoSignature: React.FC<
    IConsentementInfoSignature
> = ({
    checkBoxRef,
    isAdmissibleSI,
    parcoursEmprunteur,
    isAssuranceAjoute,
    isOntario,
    isCheckBoxError,
    prenomNomMartine,
    onCheckBoxClick
}) => {
    const [t] = useTranslation('InformationsSignature');
    const isMobile = useMediaQuery('(max-width:820px)');

    const isGuy: boolean =
        parcoursEmprunteur === ParcoursEmprunteur.COEMPRUNTEUR_GUY;
    const isMartine: boolean =
        parcoursEmprunteur === ParcoursEmprunteur.COEMPRUNTEUR_MARTINE;
    const isUnique: boolean = parcoursEmprunteur === ParcoursEmprunteur.UNIQUE;

    return (
        <ContainerMui
            padding={
                isMobile
                    ? '0.33rem 1rem 0.33rem 1rem'
                    : '0.5rem 1.5rem 0.5rem 1.5rem'
            }
            spacerDistance={3}
            spacerDistanceMobile={1}
            minWidth="100%"
            minHeight="fit-content"
        >
            <GridCol col="12" sm="12" md="12">
                <Typography variant={Variant.h3} police={Police.bold}>
                    {t('titreConsentement')}
                </Typography>
                <Typography variant={Variant.p}>
                    {t('descriptionConsentement_1')}
                    <strong>{t('descriptionConsentement_2_strong')}</strong>
                    {t('descriptionConsentement_3')}
                </Typography>
                <ul
                    style={{
                        marginBottom: isAdmissibleSI ? '0' : '0.5rem'
                    }}
                >
                    {isAdmissibleSI && (
                        <>
                            <li id="consentement_accepte_mise_a_jour">
                                {t('puce1SI')}
                            </li>
                            <li id="consentement_je_suis_la_personne">
                                {t('puce2SI')}
                            </li>
                            <li id="consentement_chaque_signature">
                                {t('puce3SI')}
                            </li>
                        </>
                    )}
                    {isGuy && (
                        <>
                            <li id="consentement_transmis_coemprunteur">
                                {t('puce1Guy') + prenomNomMartine + '.'}
                            </li>
                            {!isAdmissibleSI && (
                                <li id="consentement_mise_a_jour_coemprunteur">
                                    {t('puce2GuyHP_1') +
                                        prenomNomMartine +
                                        t('puce2GuyHP_2')}
                                </li>
                            )}
                        </>
                    )}
                    {(isMartine || isUnique) && !isAdmissibleSI && (
                        <li id="consentement_mise_a_jour_avant">
                            {t('puce1UniqueEtMartineHP_1')}
                            <strong>{t('puce1UniqueEtMartineHP_2')}</strong>
                            {t('puce1UniqueEtMartineHP_3')}
                        </li>
                    )}
                    {isAssuranceAjoute && (
                        <li id="consentement_assurance">
                            {t('puceAssurance')}
                        </li>
                    )}
                </ul>
                {isOntario && (
                    <>
                        {isAdmissibleSI && <Spacer all={3} />}
                        <Typography
                            variant={Variant.p}
                            dataTestId="consentement_ontario"
                        >
                            {t('texteOntario')}
                        </Typography>
                    </>
                )}
                {!isAdmissibleSI && (
                    <div ref={checkBoxRef}>
                        <Checkbox
                            dataTestId={'checkbox_confirmation'}
                            onChange={(isChecked) => {
                                onCheckBoxClick(isChecked);
                            }}
                            hasError={isCheckBoxError}
                            label={
                                <>
                                    <Typography variant={Variant.p}>
                                        {t('checkBoxTexte_1')}
                                    </Typography>
                                    <Typography
                                        variant={Variant.p}
                                        hideMarginBottom
                                    >
                                        {t('checkBoxTexte_2')}
                                    </Typography>
                                </>
                            }
                        />
                    </div>
                )}
            </GridCol>
            <GridCol col="12" sm="12" md="12">
                <Hr height="0.1rem" backgroundColor={ColorHex.lightGrey3} />
            </GridCol>
            <GridCol col="12" sm="12" md="12">
                <Typography
                    variant={Variant.p}
                    dataTestId="consentement_accepte_frais_indemnite"
                    hideMarginBottom
                >
                    {t('texteIndemnite')}
                </Typography>
            </GridCol>
        </ContainerMui>
    );
};
