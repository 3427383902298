import React from 'react';
import { useTranslation } from 'react-i18next';
import { Box } from '@material-ui/core';

import {
    ContainerMui,
    Variant,
    Tag,
    Article,
    Typography,
    Spacer,
    ColorHex,
    BorderRadiusMui
} from '../../styles';

export interface IInfoAlertProps {
    message: string;
    color?: ColorHex;
    title?: string;
}

const DEFAULT_BACKGROUND_COLOR = ColorHex.lightGraphite1;

export const InfoAlert: React.FC<IInfoAlertProps> = ({
    message,
    color,
    title
}) => {
    const [t] = useTranslation('InfoAlert');

    return (
        <ContainerMui
            backgroundColor={color ? color : DEFAULT_BACKGROUND_COLOR}
            borderRadius={BorderRadiusMui.sm}
            noSpacer
        >
            <Box display="flex" flexDirection="column" flexWrap="nowrap">
                <Box display="flex">
                    <Tag label={title ? title : t('tagInformationTitle')} />
                </Box>
                <Spacer />
                <Box display="flex">
                    <Typography
                        variant={Variant.p}
                        article={Article.a3}
                        hideMarginBottom
                    >
                        {message}
                    </Typography>
                </Box>
            </Box>
        </ContainerMui>
    );
};
