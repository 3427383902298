import React, { ReactNode, useEffect, useRef } from 'react';

export enum ModalType {
    STANDARD = 'STANDARD',
    CRITICAL = 'CRITICAL',
    INACTIVE = 'INACTIVE',
    WARNING = 'WARNING'
}

export enum ModalPrimaryButtonTheme {
    BLUE = 'BLUE',
    GREEN = 'GREEN',
    PALE = 'PALE',
    RED = 'RED',
    SOMBER = 'SOMBER',
    YELLOW = 'YELLOW'
}

interface IModalProps {
    title: string;
    buttonPrimaryLabel: string;
    children: ReactNode;

    //optional
    dark?: boolean;
    show?: boolean;
    type?: ModalType;
    analyticsButtonPrimary?: string;
    analyticsButtonSecondary?: string;
    buttonPrimaryTheme?: ModalPrimaryButtonTheme;
    buttonSecondaryLabel?: string;
    returnFocus?: string;
    dataTestId?: string;
    onClickPrimaryButton?: () => void;
    onClickSecondaryButton?: () => void;
    onOpen?: () => void;
}

// -------------------------------------------------------------------------
//  NOTE IMPORTANTE
//  #1  Si plusieurs Modal/Lightbox sont rendered dans la même page ça peut causer
//      un crash de l'application. Voir pop-up aiguilleur et InfoTaux pour exemple
//      et fix.
//  #2  Pour une raison qui échappe même dsd les fonctions appelé ne fonctionne pas
//      avec le useState hook de react. Voir pup-up aiguilleur pour exemple et fix
// -------------------------------------------------------------------------
export const Modal: React.FC<IModalProps> = ({
    title,
    buttonPrimaryLabel,
    buttonSecondaryLabel,
    children,

    //optional
    dark,
    show,
    type,
    analyticsButtonPrimary,
    analyticsButtonSecondary,
    buttonPrimaryTheme,
    returnFocus,
    dataTestId,

    onClickPrimaryButton,
    onClickSecondaryButton,
    onOpen
}) => {
    const ref = useRef(null);

    useEffect(() => {
        ref.current.addEventListener('dsdModalShow', () => {
            if (onOpen) onOpen();
        });
        ref.current.addEventListener('dsdModalPrimaryClick', () => {
            if (onClickPrimaryButton) onClickPrimaryButton();
        });
        ref.current.addEventListener('dsdModalSecondaryClick', () => {
            if (onClickSecondaryButton) onClickSecondaryButton();
        });
    }, []);

    return (
        // @ts-ignore
        <dsd-modal
            ref={ref}
            dark={dark}
            show={show}
            type={type}
            analytics-button-primary={analyticsButtonPrimary}
            analytics-button-secondary={analyticsButtonSecondary}
            button-primary-label={buttonPrimaryLabel}
            button-primary-theme={buttonPrimaryTheme}
            button-secondary-label={buttonSecondaryLabel}
            data-testid={dataTestId}
            return-focus={returnFocus}
        >
            <span slot="title">{title}</span>
            {children}
            {/* @ts-ignore */}
        </dsd-modal>
    );
};
