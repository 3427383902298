import { mockInstance, withDelay } from '../../../../api/axiosInstance.mock';

import { SIGNATURE_ABANDONNE_ENDPOINT } from '../../apiEndPoints';
import { dataCoEmprunteur1 as sessionMockData } from '../../contexte/useSessionApi/useSessionApi.mock';
import { data as signatureMockData } from '../useSignatureApi/useSignatureApi.mock';

const endPoint = SIGNATURE_ABANDONNE_ENDPOINT.replace(
    /{idDemande}/g,
    String(sessionMockData?.contextePret?.idDemande)
).replace(/{idCeremonie}/g, signatureMockData?.idContrat);

export const useSignatureAbandonneeApiMock = (): any => {
    const url = new RegExp(`${endPoint}`);

    mockInstance.onPut(url).reply(withDelay([200, null], 200));
};
