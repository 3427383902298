export default {
    en: {
        titleParcours1:
            'Insure your mortgage and your Versatile Line of Credit',
        titleParcours2et3: 'Insure your mortgage',
        subtitle: 'Make sure you and your loved ones are covered',
        descriptionParcours2_1:
            "Your Versatile Line of Credit is already covered, but your tied mortgage isn't.",
        descriptionParcours2_2:
            'Your situation may have changed since you bought your home. Insure your mortgage balance and payments as well to get peace of mind. Guaranteed approval.',
        descriptionParcours1et3:
            'Your situation may have changed since you bought your home. Insure your mortgage to give you peace of mind.',
        description_assurance_invalidite_titre: 'The Desjardins difference',
        description_assurance_invalidite_1:
            "Our disability insurance covers the selected percentage of your mortgage payments for as long as you're disabled",
        description_assurance_invalidite_2:
            " or until you reach the age of\xa070. That's a lot longer than the 2-year coverage often available on the market.",
        exempleProtectionTitre: 'Coverage example ',
        exempleProtectionTitreInfoBulle:
            'This scenario is fictional and only used as an example.',
        exempleProtectionTitreInfoBulleTexteHorsEcran:
            'Information on coverage example',
        exempleProtectionAssuranceVieSousTitre:
            'Life insurance – 100% of balance',
        exempleProtectionAssuranceVieSoldeSousTitre: 'Balance at time of death',
        exempleProtectionAssruanceVieSoldeMontant: '$100,000',
        exempleProtectionAssuranceVieRemboursementSousTitre:
            'Amount insurance will pay off at death',
        exempleProtectionAssuranceVieRemboursementMontant: '$100,000',
        exempleProtectionAssuranceInvaliditeSousTitre:
            'Disability insurance – 50% of payments',
        exempleProtectionAssuranceInvaliditePaiementSousTitre:
            'Regular mortgage payment',
        exempleProtectionAssruanceInvaliditePaiementMontant: '$1,200 monthly',
        exempleProtectionAssuranceInvaliditeMontantPayeSousTitre:
            'Amount insurance will pay while you’re disabled',
        exempleProtectionAssuranceInvaliditeMontantPayeMontant: '$600 monthly',

        // Accordion Assurance Hypotheque Parcours 1-2-3
        accordionAssuranceHypothequeTitre:
            'Why should I insure my mortgage if I already have personal insurance?',
        accordionAssuranceHypothequeVotreEntourageSousTitre:
            'Your family may be vulnerable',
        accordionAssuranceHypothequeVotreEntourageDescription1_1:
            'According to the report ',
        accordionAssuranceHypothequeVotreEntourageDescription1_2:
            'State of the Nation: Life Insurance Trends 2021',
        accordionAssuranceHypothequeVotreEntourageDescription1_3:
            ', Canadians are underinsured. Over <strong>75% of those with dependants would be unable to provide 2\xa0years of financial support to their family</strong> if they were to die or get sick.',
        accordionAssuranceHypothequeVotreEntourageDescription2:
            'Experts generally recommend that you have a financial cushion of 7 to 10\xa0years in the event of death.',
        accordionAssuranceHypothequeAssuranceCollectiveSousTitre:
            'Group insurance may not be enough',
        accordionAssuranceHypothequeAssuranceCollectiveBullet1:
            "Your life insurance policy may not cover your mortgage and other financial obligations. It doesn't always allow you to leave a financial cushion for your loved ones.",
        accordionAssuranceHypothequeAssuranceCollectiveBullet2:
            'Your disability insurance may include a loss of earnings compared to your regular income. An accident or illness can also bring about new expenses such as costs for medical treatments, transportation or childcare.',
        accordionAssuranceHypothequeAssuranceCollectiveBullet3:
            'You could lose your coverage if you change jobs or retire.',

        // Accordion Marge Atout Parcours 1
        accordionMargeAtoutTitre: 'How does the Versatile Line of Credit work?',
        accordionMargeAtoutSousTitre1: 'The basics',
        accordionMargeAtoutDescription1:
            'The Versatile Line of Credit is a home equity line of credit (HELOC)—which means it’s a line of credit that’s backed by your home. The loan you’re renewing today is a <strong>loan tied to your Versatile Line of Credit</strong>. It functions like a typical mortgage.',
        accordionMargeAtoutSousTitre2:
            'Use your home equity to finance your projects',
        accordionMargeAtoutDescription2:
            'As you pay off your tied loan, the amount available on your HELOC goes up. Use your available funds to pay for your renovations, finance a second property, consolidate your debt or cover unexpected expenses.',
        accordionMargeAtoutSousTitre3: 'Insurance',
        accordionMargeAtoutDescription3_1:
            'When you take out insurance on your Versatile Line of Credit, we guarantee that any future tied loans will qualify for the same coverage.',
        accordionMargeAtoutDescription3_2:
            'Your insurance premium is calculated based on the amount of financing being used, both for your Versatile Line of Credit and your tied loans.',
        accordionMargeAtoutDescription3_3:
            "If you don't use your HELOC, you'll only pay the premium on your insured tied loans.",

        serviceAccompagnementTitre: 'Included assistance services',
        serviceAccompagnementTitreBullet1:
            '<strong>Psychological</strong> assistance to help you through difficult times confidentially',
        serviceAccompagnementTitreBullet2:
            '<strong>Legal</strong> assistance to inform you of your rights and legal recourse',
        serviceAccompagnementTitreBullet3:
            '<strong>Convalescence</strong> assistance –<strong> case management</strong> to help you find the information and healthcare providers you need',
        nextWithInsuranceButton: 'Continue',
        nextWithNoInsuranceButton: 'Continue without coverage',
        backButton: 'Back',
        cancelButton: 'Save and leave',
        footnoteToolTip: 'Go to note 1'
    },
    fr: {
        titleParcours1: 'Assurer votre prêt hypothécaire et votre marge Atout',
        titleParcours2et3: 'Assurer votre prêt hypothécaire',
        subtitle: 'Prenez soin de vous et de votre monde',
        descriptionParcours2_1:
            'La marge de crédit de votre marge Atout est déjà assurée, mais votre prêt hypothécaire lié ne l’est pas.',
        descriptionParcours2_2:
            'Votre situation a peut-être changé depuis l’achat de votre propriété. Ayez l’esprit tranquille en assurant aussi le solde de votre prêt et vos paiements hypothécaires. Approbation garantie!',
        descriptionParcours1et3:
            'Votre situation a peut-être changé depuis l’achat de votre propriété. Ayez l’esprit tranquille en assurant votre prêt.',
        description_assurance_invalidite_titre: 'La différence Desjardins',
        description_assurance_invalidite_1:
            'Notre assurance invalidité couvre le pourcentage choisi de votre paiement hypothécaire tant que vous êtes invalide',
        description_assurance_invalidite_2:
            " ou jusqu’à ce que vous ayez 70\xa0ans. C'est bien plus que la protection de 2\xa0ans souvent offerte sur le marché.",
        exempleProtectionTitre: 'Exemple de protection ',
        exempleProtectionTitreInfoBulle:
            'Scénario fictif à titre d’exemple seulement',
        exempleProtectionTitreInfoBulleTexteHorsEcran:
            'Information sur l’exemple de protection',
        exempleProtectionAssuranceVieSousTitre:
            'Assurance vie – 100\xa0% du solde',
        exempleProtectionAssuranceVieSoldeSousTitre: 'Solde au moment du décès',
        exempleProtectionAssruanceVieSoldeMontant: '100\xa0000\xa0$',
        exempleProtectionAssuranceVieRemboursementSousTitre:
            'Remboursement du prêt par l’assurance au décès',
        exempleProtectionAssuranceVieRemboursementMontant: '100\xa0000\xa0$',
        exempleProtectionAssuranceInvaliditeSousTitre:
            'Assurance invalidité – 50\xa0% du paiement',
        exempleProtectionAssuranceInvaliditePaiementSousTitre:
            'Paiement hypothécaire régulier',
        exempleProtectionAssruanceInvaliditePaiementMontant:
            '1\xa0200\xa0$ par mois',
        exempleProtectionAssuranceInvaliditeMontantPayeSousTitre:
            'Montant payé par l’assurance durant l’invalidité',
        exempleProtectionAssuranceInvaliditeMontantPayeMontant:
            '600\xa0$ par mois',

        // Accordion Assurance Hypotheque Parcours 1-2-3
        accordionAssuranceHypothequeTitre:
            'Pourquoi assurer mon prêt hypothécaire si j’ai déjà une assurance personnelle?',
        accordionAssuranceHypothequeVotreEntourageSousTitre:
            'Votre entourage est peut-être vulnérable',
        accordionAssuranceHypothequeVotreEntourageDescription1_1:
            'Selon le rapport ',
        accordionAssuranceHypothequeVotreEntourageDescription1_2:
            'State of the Nation: Life Insurance Trends 2021',
        accordionAssuranceHypothequeVotreEntourageDescription1_3:
            ', la population canadienne est sous-assurée S’ils décédaient ou tombaient malades, plus de <strong>75\xa0% des individus qui ont des personnes à charge seraient incapables de leur offrir un soutien financier pendant 2\xa0ans</strong>.',
        accordionAssuranceHypothequeVotreEntourageDescription2:
            'Les experts recommandent de prévoir un soutien financier de 7 à 10\xa0ans en cas de décès.',
        accordionAssuranceHypothequeAssuranceCollectiveSousTitre:
            'L’assurance collective peut ne pas suffire',
        accordionAssuranceHypothequeAssuranceCollectiveBullet1:
            'Votre assurance vie pourrait ne pas couvrir votre prêt hypothécaire et vos autres obligations financières. Elle ne permet pas toujours de laisser un coussin financier à vos proches.',
        accordionAssuranceHypothequeAssuranceCollectiveBullet2:
            'Votre assurance salaire implique souvent un manque à gagner par rapport à votre revenu habituel. Un accident ou une maladie peut aussi entraîner de nouvelles dépenses comme des traitements médicaux, des frais de transport ou de garde d’enfants.',
        accordionAssuranceHypothequeAssuranceCollectiveBullet3:
            'Si vous changez d’emploi ou prenez votre retraite, vous pourriez perdre votre assurance.',

        // Accordion Marge Atout Parcours 1
        accordionMargeAtoutTitre: 'Comment fonctionne la marge Atout?',
        accordionMargeAtoutSousTitre1: 'La base',
        accordionMargeAtoutDescription1:
            "La marge Atout est une marge de crédit hypothécaire, c'est-à-dire une marge de crédit garantie par votre propriété. Le prêt que vous renouvelez aujourd’hui est un <strong>prêt lié à votre marge Atout</strong>. Il fonctionne comme un prêt hypothécaire classique.",
        accordionMargeAtoutSousTitre2: 'Du crédit accessible pour vos projets',
        accordionMargeAtoutDescription2:
            "Au fur et à mesure que vous remboursez votre prêt lié, le capital que vous payez s'accumule sous forme de crédit sur votre marge. Vous pouvez alors utiliser le montant disponible pour faire des rénovations, financer une résidence secondaire, consolider vos dettes ou vous dépanner en cas d'imprévu.",
        accordionMargeAtoutSousTitre3: 'L’assurance',
        accordionMargeAtoutDescription3_1:
            'Quand vous prenez l’assurance sur votre marge Atout, vous obtenez la garantie que tout futur prêt lié pourra bénéficier des mêmes protections.',
        accordionMargeAtoutDescription3_2:
            'Votre prime d’assurance est calculée selon le montant de financement utilisé, sur la marge Atout comme sur les prêts liés.',
        accordionMargeAtoutDescription3_3:
            'Si vous n’utilisez pas votre marge de crédit hypothécaire, vous payez l’assurance seulement sur vos prêts liés assurés.',

        serviceAccompagnementTitre: 'Services d’accompagnement inclus',
        serviceAccompagnementTitreBullet1:
            'Assistance <strong>psychologique</strong> confidentielle pour vous aider à traverser les épreuves',
        serviceAccompagnementTitreBullet2:
            "Assistance <strong>juridique</strong> pour obtenir de l'information sur vos droits et vos recours",
        serviceAccompagnementTitreBullet3:
            'Assistance <strong>convalescence – coordination</strong> pour vous aider à trouver l’information et les fournisseurs dont vous avez besoin',
        nextWithInsuranceButton: 'Continuer',
        nextWithNoInsuranceButton: 'Continuer sans assurance',
        backButton: 'Précédent',
        cancelButton: 'Enregistrer et quitter',
        footnoteToolTip: 'Aller à la note 1'
    }
};
