export default {
    en: {
        resumeDateTitle: 'Payment date',
        resumeDateDisclaimerText:
            'Since you’re changing your payment frequency, your renewal will take effect after the next payment on {{date, MMMM\xa0D}}, {{date, YYYY}}',
        resumeDatePremierPaiementTitle: 'First payment date',
        resumeDatePremierPaiementValeur:
            '{{date, dddd}}, {{date, MMMM\xa0D}}, {{date, YYYY}}',
        resumeChangeButton: 'Edit'
    },
    fr: {
        resumeDateTitle: 'Date de paiement',
        resumeDateDisclaimerText:
            'En raison de votre changement de fréquence, votre renouvellement prendra effet après le dernier paiement prévu le {{date, D}}{{suffixe}}\xa0{{date, MMMM\xa0YYYY}}',
        resumeDatePremierPaiementTitle: 'Date de votre premier paiement',
        resumeDatePremierPaiementValeur:
            '{{date, D}}{{suffixe}}\xa0{{date, MMMM\xa0YYYY}}',
        resumeChangeButton: 'Modifier'
    }
};
