import React from 'react';

interface IFootnoteProps {
    id?: string;
    returnId?: string;
    dark?: boolean;
    open?: boolean;
    footnoteGroupTitle?: string;
    listType?: FootnoteListTypeEnum;
    children: React.ReactNode;
}

export enum FootnoteListTypeEnum {
    BULLET = 'bullet',
    DECIMAL = 'decimal'
}

export const Footnote: React.FC<IFootnoteProps> = ({
    id,
    returnId,
    children,
    dark,
    open,
    footnoteGroupTitle,
    listType
}) => (
    <>
        {/*@ts-ignore*/}
        <dsd-footnote
            id={id}
            return-id={returnId}
            dark={dark}
            open={open}
            footnote-group-title={footnoteGroupTitle}
            list-type={listType}
        >
            {children}
            {/*@ts-ignore*/}
        </dsd-footnote>
    </>
);
