export default {
    en: {
        linkHS: 'This link opens your phone app.',
        MTLTitre: 'Montreal area',
        MTLLink: '514-224-7737',
        MTLLinkAria: '5 1 4. 2 2 4. 7 7 3 7.',
        MTLLinkSuffix: ' (514-CAISSES)',
        autreTitre: 'Elsewhere in Quebec, Canada and the US',
        autreLink: '1-800-224-7737',
        autreLinkAria: '1 8 0 0. 2 2 4. 7 7 3 7.',
        autreLinkSuffix: ' (1-800-CAISSES)'
    },
    fr: {
        linkHS: 'Ce lien ouvre votre application de téléphonie.',
        MTLTitre: 'Région de Montréal',
        MTLLink: '514 224-7737',
        MTLLinkAria: '5 1 4. 2 2 4. 7 7 3 7.',
        MTLLinkSuffix: ' (514 CAISSES)',
        autreTitre: 'Ailleurs au Québec, Canada et États-Unis',
        autreLink: '1 800 224-7737',
        autreLinkAria: '1 8 0 0. 2 2 4. 7 7 3 7.',
        autreLinkSuffix: ' (1 800 CAISSES)'
    }
};
