export default {
    en: {
        reinitialiser: 'Recalculate',
        comprendreButton: 'Learn about insurance costs'
    },
    fr: {
        reinitialiser: 'Recommencer le calcul',
        comprendreButton: "Comprendre le coût de l'assurance"
    }
};
