import React, { useEffect } from 'react';
import { Box, useMediaQuery } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

import {
    GridCol,
    Typography,
    Variant,
    Police,
    Alert,
    AlertType,
    ColorHex,
    Article,
    Spacer,
    Banner,
    DateText
} from '../../../../styles';

import {
    ResumeMontant,
    ResumePret,
    ClavardageContexte,
    setContexteClavardage
} from '../../../../components';
import {
    ParcoursEmprunteur,
    emprunteur,
    EmprunteurStatut,
    IResumeOffre,
    ISession
} from '../../../../hooks';

interface IConfirmationHorsParcoursProps {
    isOntario: boolean;
    delaiApprobation: number;
    dateSignature: Date;
    typeParcours: ParcoursEmprunteur;
    emprunteurs: Array<emprunteur>;
    resumeOffre: IResumeOffre;
    session: ISession;
}

export const ConfirmationHorsParcours: React.FC<
    IConfirmationHorsParcoursProps
> = ({
    isOntario,
    delaiApprobation,
    dateSignature,
    typeParcours,
    emprunteurs,
    resumeOffre,
    session
}) => {
    const [t] = useTranslation(['Confirmation']);
    const isMobile = useMediaQuery('(max-width:820px)');

    // emprunteur connecte' en premier
    const sortedEmprunteurs = emprunteurs.sort((x, y) =>
        x?.connecte === y?.connecte ? 0 : x?.connecte ? -1 : 1
    );

    const renderNextStepSection = () => {
        const shouldDisplayTwoSteps =
            typeParcours === ParcoursEmprunteur.COEMPRUNTEUR_GUY &&
            sortedEmprunteurs.length > 1 &&
            sortedEmprunteurs[1].statut !== EmprunteurStatut.CONFIRMEE;
        return (
            <>
                <Typography variant={Variant.p} police={Police.bold}>
                    {shouldDisplayTwoSteps
                        ? t('choixTitre2Steps')
                        : t('choixTitre1Step')}
                </Typography>
                <ul>
                    {/* On affiche l'etape 1 si coemprunteur ET le second coemprunteur n'as pas fini */}
                    {shouldDisplayTwoSteps && <li>{t('choixEtape1')}</li>}
                    <li>{t('choixEtape2')}</li>
                </ul>
                {!isMobile && <Spacer />}
            </>
        );
    };

    const renderGuySection = () => (
        <>
            {/* Si les deux coemprunteurs on finit -> on affiche pas le temps restant */}
            {typeParcours === ParcoursEmprunteur.COEMPRUNTEUR_GUY &&
                sortedEmprunteurs[1].statut !== EmprunteurStatut.CONFIRMEE && (
                    <>
                        <Typography variant={Variant.h3} police={Police.bold}>
                            {delaiApprobation > 0 ? (
                                <DateText
                                    translationKey="Confirmation:sousTitreDate"
                                    date={dateSignature}
                                >
                                    {{ date: dateSignature }}
                                </DateText>
                            ) : (
                                t('sousTitreToday')
                            )}
                        </Typography>
                        <Typography
                            variant={Variant.p}
                            article={Article.a3}
                            marginBottom="1.5rem"
                        >
                            {t('warningText')}
                        </Typography>
                        <Alert
                            isFullWidth
                            type={AlertType.information}
                            dataTestId="attente_signature_martine"
                        >
                            {t('informationSignatureContrat')}
                        </Alert>
                    </>
                )}
        </>
    );

    const renderAlertCourrielFinParcours = () => (
        <>
            {(typeParcours === ParcoursEmprunteur.COEMPRUNTEUR_GUY &&
                sortedEmprunteurs[1].statut !== EmprunteurStatut.CONFIRMEE) ||
            isOntario ? (
                <></>
            ) : (
                <Alert
                    type={AlertType.information}
                    dataTestId="alerte_courriel_signature"
                >
                    {t('alertBasTerminee')}
                </Alert>
            )}
        </>
    );

    useEffect(() => {
        setContexteClavardage(ClavardageContexte.CONFIRMATION);

        return () => {
            // Réinitialisation du contexte de clavardage
            setContexteClavardage(ClavardageContexte.DEFAULT);
        };
    }, []);

    return (
        <GridCol col="12" sm="8" md="6">
            <Box
                margin={
                    isMobile ? '1rem 1rem 1rem 1rem' : '1.5rem 0 1rem 1.5rem'
                }
            >
                <Typography
                    variant={Variant.p}
                    police={Police.bold}
                    color={ColorHex.lightGraphite4}
                >
                    {t('titreRhn')}
                </Typography>
                <Typography
                    variant={Variant.h2}
                    dataTestId={'page_confirmation_titre'}
                >
                    {t('titre')}
                </Typography>
                <Alert
                    isFullWidth
                    type={AlertType.confirmation}
                    dataTestId={'alerte_demande_enregistré'}
                >
                    {t('saved')}
                </Alert>
                {renderAlertCourrielFinParcours()}
                {renderGuySection()}
                {renderNextStepSection()}
                <ResumePret
                    isEditButtonDisplayed={false}
                    isTauxAssuranceDisplayed={false}
                    isSoldeSansAssuranceDisplayed={false}
                    session={session}
                    resumeOffre={resumeOffre}
                    isSimplifie={true}
                />
                <ResumeMontant
                    resumeOffre={resumeOffre}
                    spacerDistance={6}
                    spacerDistanceMobile={6}
                />
                <Banner
                    variant="tip"
                    title={t('tipTitle')}
                    content={t('tip')}
                    dataTestId="banner_conseil"
                />
                {isOntario && (
                    <>
                        <Spacer all={6} />
                        <Banner
                            variant="suggestion"
                            title={t('alertOntarioTitle')}
                            content={t('alertOntario')}
                            dataTestId="banner_regle_ontario"
                        />
                    </>
                )}
                {!isMobile && <Spacer md={3} />}
            </Box>
        </GridCol>
    );
};
