import React from 'react';
import { useTranslation } from 'react-i18next';

import { Button, IconPosition } from '../../../../../styles';

interface IPaiementButton {
    paiement: { value: number; min: number; max: number };
    isDisable: boolean;
    onChange: (value: number) => void;
}

export const PaiementButton: React.FC<IPaiementButton> = ({
    paiement,
    isDisable,
    onChange
}) => {
    const [t] = useTranslation('PaiementSelect');

    const isInRange = (value) => {
        return Number(value) >= paiement.min && Number(value) <= paiement.max;
    };

    const handleCalculer = () => {
        const { value } = paiement;
        if (isInRange(value)) {
            onChange(value);
        }
    };

    return (
        <Button
            isDisabled={isDisable}
            iconPosition={IconPosition.start}
            iconName={'contenus_contour_calculateur'}
            onClick={() => handleCalculer()}
            dataTestId="bouton_calculer"
        >
            {t('calculer')}
        </Button>
    );
};
