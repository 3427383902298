export default {
    en: {
        title: 'No mortgage to renew online',
        titleDescription:
            'For more information on mortgage renewal, contact us.',
        subtitle: 'Have us call you',
        subtitleDescription:
            'We’re here to guide you through the renewal process and help you find the best options for you.',
        parlerConseiller: 'Talk to our specialists',
        retourAuSommaire: 'Back to AccèsD home',
        click2call_aria: 'This link will open in a new window.'
    },
    fr: {
        title: 'Aucun prêt à renouveler en ligne',
        titleDescription:
            'Pour obtenir des informations concernant le renouvellement hypothécaire, communiquez avec nous.',

        subtitle: 'Demandez qu’on vous appelle',
        subtitleDescription:
            'Nous sommes là pour vous accompagner et vous aider à trouver les options qui répondent à votre besoin.',
        parlerConseiller: 'Parlez à nos spécialistes',
        retourAuSommaire: 'Retour à l’accueil',
        click2call_aria: 'Ce lien s’ouvrira dans une nouvelle fenêtre.'
    }
};
