import React from 'react';
import { useTranslation } from 'react-i18next';

import { Box, FormControl } from '@material-ui/core';

import {
    ContainerMui,
    ColorHex,
    BorderRadiusMui,
    ISelectOptions,
    IconTooltip,
    Typography,
    Variant,
    Article,
    ColorDSD,
    SelectMui
} from '../../../../styles';

export interface IFrequenceSelectProps {
    options: ISelectOptions;
    value: string;
    onChange: (value: string) => void;
    onToolTipClick: () => void;
}

export const FrequenceSelect: React.FC<IFrequenceSelectProps> = ({
    options,
    value,
    onChange,
    onToolTipClick
}) => {
    // Translation
    const [t] = useTranslation('FrequenceSelect');

    return (
        <Box paddingBottom="0.5rem">
            <ContainerMui
                height="162px"
                borderRadius={BorderRadiusMui.sm}
                border={2}
                borderColor={ColorHex.lightSystem1}
            >
                <Box display="flex" flexWrap="nowrap">
                    <Typography
                        variant={Variant.p}
                        article={Article.a3}
                        color={ColorDSD.lightSystem3}
                        hideMarginBottom
                    >
                        {t('frequence')}
                    </Typography>
                    <Box
                        paddingTop="4px"
                        paddingLeft="0.5rem"
                        height="40px"
                        data-testid={'info_bulle_frequence'}
                    >
                        <IconTooltip
                            iconName="alertes_contour_information"
                            label={t('tooltip')}
                            onActive={onToolTipClick}
                        />
                    </Box>
                </Box>
                {/* TODO: Quand on fait la normalisation de la page modalites decommente le select dsd et delete le select MUI */}
                {/* {options && (
                    // TODO: quand toolTip dsd fonctionne utilise seulement le tool tip et titre dans select
                    <Select
                        fullWidth
                        identifier={'modalites_select_frequence'}
                        value={value}
                        options={options}
                        onChange={(e) => onChange(e)}
                    />
                )} */}

                {options && (
                    <FormControl
                        style={{ minWidth: '100%' }}
                        data-testid={'liste_frequence'}
                    >
                        <SelectMui
                            id={'modalites_select_frequence'}
                            value={value}
                            options={options}
                            onChange={(e) => onChange(e.target.value)}
                        />
                    </FormControl>
                )}
            </ContainerMui>
        </Box>
    );
};
