import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Close from '@material-ui/icons/Close';
import { IEntete } from '../../hooks';
import { Box, useMediaQuery } from '@material-ui/core';
import { isMockEnabled, RenderMockButton } from '../../api';
import { Tag, ThemeTag, Typography, Variant } from '../../styles';
import { EnvConfig } from '../../config';

interface IDebugBannerProps extends IEntete {
    emprunteur: string;
    pdo: string;
    numCompte: string;
    numeroInstitution: string;
    numeroTransit: string;
    folio: string;
    idDemande: number;
    isAuth0Enabled: boolean;
    isAuthenticated: boolean;
    isAdmissible: boolean;
    isAdmissibleInvalidite: boolean;
    envConfig: EnvConfig;
    onClose: () => void;
}

const useStyles = makeStyles({
    table: {
        minWidth: 650
    }
});

export const DebugBanner: React.FC<IDebugBannerProps> = ({
    folio,
    numCompte,
    numeroInstitution,
    numeroTransit,
    emprunteur,
    pdo,
    idDemande,
    isAuth0Enabled,
    isAuthenticated,
    isAdmissible,
    isAdmissibleInvalidite,
    envConfig,
    onClose
}) => {
    const classes = useStyles();
    const mockFeatureToggle = isMockEnabled();
    // State
    const [isDisplayed, setIsDisplayed] = useState(true);

    const isMobile = useMediaQuery('(max-width:800px)');

    // Responsive part
    useEffect(() => {
        setIsDisplayed(!isMobile);
    }, [isMobile]);

    const getTagType = (status: boolean): ThemeTag => {
        if (status === null) {
            return null;
        }
        if (status) {
            return ThemeTag.PRIMARY;
        }
        return ThemeTag.YELLOW;
    };

    const getStatusAuth0 = (): string => {
        if (isAuth0Enabled === null) {
            return 'OFF';
        }
        if (isAuthenticated) {
            return 'Ouverte';
        }
        return 'Fermée';
    };

    const RenderDebugDemandeInfos = (): any => {
        return (
            <Table className={classes.table} size="small">
                <TableHead>
                    <TableRow>
                        <TableCell align="left">Institution</TableCell>
                        <TableCell align="left">Transit</TableCell>
                        <TableCell align="left">Folio</TableCell>
                        <TableCell align="left">Compte</TableCell>
                        <TableCell align="left">Emprunteur</TableCell>
                        <TableCell align="left">idDemande</TableCell>
                        <TableCell align="left">pdo</TableCell>
                        <TableCell align="center" size="small">
                            Close
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    <TableRow key={folio}>
                        <TableCell align="left">{numeroInstitution}</TableCell>
                        <TableCell align="left">{numeroTransit}</TableCell>
                        <TableCell align="left">{folio}</TableCell>
                        <TableCell align="left">{numCompte}</TableCell>
                        <TableCell align="left">{emprunteur}</TableCell>
                        <TableCell align="left">{idDemande}</TableCell>
                        <TableCell align="left">{pdo}</TableCell>
                        <TableCell
                            id={'QA_test_close_debug_banner'}
                            align="center"
                            size="small"
                        >
                            <Close onClick={onClose}></Close>
                        </TableCell>
                    </TableRow>
                </TableBody>
            </Table>
        );
    };

    const RenderDebugEnvInfos = (): any => {
        return (
            <>
                <Box ml={3}>
                    <Typography variant={Variant.p} hideMarginBottom>
                        <b>{'Session AccesWeb '}</b>
                        <Tag
                            theme={getTagType(isAuth0Enabled)}
                            label={getStatusAuth0()}
                        />
                    </Typography>
                </Box>
                <Box ml={2}>
                    <Typography variant={Variant.p} hideMarginBottom>
                        <b>{'Admissibilite assurance'}</b>
                        {!isAdmissible && (
                            <Tag theme={ThemeTag.YELLOW} label="NON" />
                        )}
                        {isAdmissible && (
                            <>
                                <Tag theme={ThemeTag.PRIMARY} label="Vie" />
                                <Tag
                                    theme={getTagType(isAdmissibleInvalidite)}
                                    label="Invalidité"
                                />
                            </>
                        )}
                    </Typography>
                </Box>
                <Box ml={2}>
                    <Typography variant={Variant.p} hideMarginBottom>
                        <b>{'Environnement '}</b>
                        <Tag
                            theme={ThemeTag.NEW}
                            label={envConfig.type.toString()}
                        />
                    </Typography>
                </Box>
                <Box ml={2}>
                    <Typography variant={Variant.p} hideMarginBottom>
                        <b>{'Mode '}</b>
                        <Tag
                            theme={ThemeTag.NEW}
                            label={envConfig.usePise ? 'PISE' : 'JWT Simulé'}
                        />
                    </Typography>
                </Box>
            </>
        );
    };

    return (
        <>
            {isDisplayed && (
                <TableContainer
                    id={'QA_test_info_debug_banner'}
                    data-testid={'barre-info-pret'}
                    component={Paper}
                >
                    <Box ml={2} mt={1} display="flex">
                        {RenderMockButton()}
                        {!mockFeatureToggle && RenderDebugEnvInfos()}
                    </Box>
                    {!mockFeatureToggle && RenderDebugDemandeInfos()}
                </TableContainer>
            )}
        </>
    );
};
