export default {
    en: {
        descriptionErreur: 'Errors were detected in the following fields:',
        prefixHorsEcran: 'Error '
    },
    fr: {
        descriptionErreur:
            'Des erreurs ont été détectées dans les champs suivants :',
        prefixHorsEcran: 'Erreur '
    }
};
