import React, { ReactNode } from 'react';

export enum EnumLinkType {
    EXTERNAL = 'EXTERNAL',
    DOWNLOAD = 'DOWNLOAD'
}

interface ILinkProps {
    dataTestId?: string;
    analytics?: string;
    dark?: boolean;
    href?: string;
    ariaLabel?: string;
    type?: EnumLinkType;
    describedBy?: string;
    children: ReactNode;
    onClick?: () => void;
}

/* cross-origin destinations are unsafe, adding rel https://web.dev/external-anchors-use-rel-noopener/ ++lighthouse */
export const Link: React.FC<ILinkProps> = ({
    dataTestId,
    analytics,
    dark,
    href,
    ariaLabel,
    type,
    describedBy,
    children,
    onClick
}) => {
    return (
        // @ts-ignore
        <dsd-link
            onClick={onClick}
            data-testId={dataTestId}
            analytics={analytics}
            dark={dark}
            href={href}
            label={ariaLabel}
            type={type}
            described-by={describedBy}
        >
            {children}
            {/* @ts-ignore */}
        </dsd-link>
    );
};
