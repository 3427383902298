import React from 'react';
import { TextField, InputAdornment, IconButton } from '@material-ui/core';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';

interface IInputProps {
    dataTestId?: string;
    value?: string;
    defaultValue?: string;
    isDisable?: boolean;
    type?: string;
    isFullWidth?: boolean;
    inputRef?: any;
    min?: number;
    max?: number;
    inputKey?: string;
    isError?: boolean;
    adornment?: string;
    helperText?: string;
    hideAdornmentState?: boolean;
    displayHideAdornment?: boolean;
    onKeyPress?: (key: string, value: string) => void;
    onHideAdornmentClick?: () => void;
    onBlur?: (value: any) => void;
    onFocus?: (value: any) => void;
    onChange?: (value: any) => void;
}

export const Input: React.FC<IInputProps> = ({
    dataTestId = 'inputMui',
    value,
    defaultValue,
    type,
    isFullWidth,
    inputRef,
    min,
    isDisable,
    isError,
    max,
    inputKey,
    adornment,
    helperText,
    hideAdornmentState,
    displayHideAdornment,
    onKeyPress,
    onHideAdornmentClick,
    onBlur,
    onFocus,
    onChange
}) => {
    const handleKeyPress = (e) => {
        if (onKeyPress) {
            //@ts-ignore
            onKeyPress(e?.key, e?.target?.value);
        }
    };

    return (
        <TextField
            data-testid={dataTestId}
            onKeyPress={handleKeyPress}
            error={isError}
            size="small"
            helperText={helperText}
            key={inputKey}
            inputRef={inputRef}
            disabled={isDisable}
            fullWidth={isFullWidth}
            type={type}
            // @ts-ignore
            onWheel={(event) => event?.target?.blur()}
            InputProps={{
                inputProps: {
                    min: min,
                    max: max,
                    'data-testid': dataTestId + '_field'
                },
                startAdornment: (
                    <InputAdornment position="start">
                        {adornment}
                    </InputAdornment>
                ),
                endAdornment: displayHideAdornment && (
                    <>
                        <InputAdornment position="end">
                            <IconButton onClick={onHideAdornmentClick}>
                                {hideAdornmentState ? (
                                    <Visibility />
                                ) : (
                                    <VisibilityOff />
                                )}
                            </IconButton>
                        </InputAdornment>
                    </>
                )
            }}
            value={value}
            defaultValue={defaultValue}
            onBlur={onBlur}
            onFocus={onFocus}
            onChange={onChange}
            variant="outlined"
        />
    );
};
