import React, { useState, useRef, useEffect } from 'react';
import { Box } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

import {
    Grid,
    GridCol,
    GridRow,
    Variant,
    Typography,
    ISelectOptions,
    AlertType,
    Alert,
    Article,
    Spacer,
    DateText
} from '../../styles';

import {
    AlertInputError,
    ProgressBar,
    ClavardageContexte,
    setContexteClavardage
} from '../../components';
import { CourrielInfoSignature } from './components/courrielInfoSignature/CourrielInfoSignature';
import { AuthentificationInfoSignature } from './components/authentificationInfoSignature/AuthentificationInfoSignature';
import { ConsentementInfoSignature } from './components/consentementInfoSignature/ConsentementInfoSignature';
import { LangueInfoSignature } from './components/langueInfoSignature/LangueInfoSignature';
import { IToggle, emprunteur, ParcoursEmprunteur } from '../../hooks';
import { IConventionState } from './InformationsSignature.utils';

export interface IInformationsSignatureProps {
    convention: IConventionState;
    questions: ISelectOptions;
    emprunteurMartine: emprunteur;
    parcoursEmprunteur: string;
    isAssuranceAjoute: boolean;
    isOntario: boolean;
    dateCibleRenouvellement: Date;
    shouldEmailInputErrorDisplay: boolean;
    shouldCellPhoneInputErrorDisplay: boolean;
    shouldQuestion1ErrorDisplay: boolean;
    shouldQuestion2ErrorDisplay: boolean;
    shouldAnswer1ErrorDisplay: boolean;
    shouldAnswer2ErrorDisplay: boolean;
    shouldCheckBoxErrorDisplay: boolean;
    isAdmissibleSI: boolean;
    toggle: IToggle;
    onConventionChanged: (prop, value) => void;
    onCheckBoxClick: (isChecked: boolean) => void;
}

export const InformationsSignature: React.FC<IInformationsSignatureProps> = ({
    convention,
    questions,
    emprunteurMartine,
    parcoursEmprunteur,
    isAssuranceAjoute,
    isOntario,
    dateCibleRenouvellement,
    shouldEmailInputErrorDisplay,
    shouldCellPhoneInputErrorDisplay,
    shouldQuestion1ErrorDisplay,
    shouldQuestion2ErrorDisplay,
    shouldAnswer1ErrorDisplay,
    shouldAnswer2ErrorDisplay,
    shouldCheckBoxErrorDisplay,
    isAdmissibleSI,
    toggle,
    onConventionChanged,
    onCheckBoxClick
}) => {
    const [t] = useTranslation('InformationsSignature');

    // State
    const [erreursListe, setErreursListe] = useState([]);
    const [isErrorBlocDisplayed, setIsErrorBlocDisplayed] = useState(false);

    // Refs
    const emailRef = useRef(null);
    const cellRef = useRef(null);
    const question1Ref = useRef(null);
    const question2Ref = useRef(null);
    const checkBoxRef = useRef(null);

    const renderErrorDetails = () => {
        const erreurs = [];

        if (shouldEmailInputErrorDisplay) {
            erreurs.push({
                label: t('alertEmailInputError'),
                dataTestId: 'erreur_adresse_courriel',
                ref: emailRef
            });
        }
        if (shouldCellPhoneInputErrorDisplay) {
            erreurs.push({
                label: t('authentificationModeCellphoneInputErrorAlert'),
                dataTestId: 'erreur_cellulaire',
                ref: cellRef
            });
        }
        if (shouldQuestion1ErrorDisplay || shouldAnswer1ErrorDisplay) {
            erreurs.push({
                label: t('authentificationModeQuestionsInput') + 1,
                dataTestId: 'erreur_question_1',
                ref: question1Ref
            });
        }
        if (shouldQuestion2ErrorDisplay || shouldAnswer2ErrorDisplay) {
            erreurs.push({
                label: t('authentificationModeQuestionsInput') + 2,
                dataTestId: 'erreur_question_2',
                ref: question2Ref
            });
        }

        if (shouldCheckBoxErrorDisplay) {
            erreurs.push({
                label: t('checkBoxErrorTexte'),
                dataTestId: 'erreur_checkbox',
                ref: checkBoxRef
            });
        }

        setErreursListe(erreurs);
    };

    // Scan for when to display error bloc
    useEffect(() => {
        if (
            shouldEmailInputErrorDisplay ||
            shouldCellPhoneInputErrorDisplay ||
            shouldQuestion1ErrorDisplay ||
            shouldQuestion2ErrorDisplay ||
            shouldAnswer1ErrorDisplay ||
            shouldAnswer2ErrorDisplay ||
            shouldCheckBoxErrorDisplay
        ) {
            setIsErrorBlocDisplayed(true);
            renderErrorDetails();
        }
    }, [
        shouldEmailInputErrorDisplay,
        shouldCellPhoneInputErrorDisplay,
        shouldQuestion1ErrorDisplay,
        shouldQuestion2ErrorDisplay,
        shouldAnswer1ErrorDisplay,
        shouldAnswer2ErrorDisplay,
        shouldCheckBoxErrorDisplay
    ]);

    useEffect(() => {
        setContexteClavardage(ClavardageContexte.INFORMATION_SIGNATURE);

        return () => {
            // Réinitialisation du contexte de clavardage
            setContexteClavardage(ClavardageContexte.DEFAULT);
        };
    }, []);

    const renderTextAlertSI = () => {
        switch (parcoursEmprunteur) {
            case ParcoursEmprunteur.COEMPRUNTEUR_GUY:
                return 'infoRenouvellementActifSIGuy';
            case ParcoursEmprunteur.COEMPRUNTEUR_MARTINE:
                return 'infoRenouvellementActifSIMartine';
            default:
                return 'infoRenouvellementActifSIUnique';
        }
    };

    return (
        <Grid>
            <ProgressBar />
            <GridCol col="12">
                <Box mt="-1rem">
                    <Typography
                        dataTestId={'page_info_signature_titre'}
                        variant={Variant.h2}
                    >
                        {t('titre')}
                    </Typography>
                </Box>
                {(isAdmissibleSI ||
                    parcoursEmprunteur !==
                        ParcoursEmprunteur.COEMPRUNTEUR_GUY) && (
                    <Alert
                        title={t('titreAlerteInfo')}
                        titleLevel={3}
                        type={AlertType.information}
                        dataTestId="bloc_texte_date_renouvellement_actif"
                    >
                        {isAdmissibleSI ? (
                            <DateText
                                translationKey={
                                    'InformationsSignature:' +
                                    renderTextAlertSI()
                                }
                                date={dateCibleRenouvellement}
                            >
                                {{ date: dateCibleRenouvellement }}
                            </DateText>
                        ) : (
                            t('infoRenouvellementActifHP')
                        )}
                    </Alert>
                )}
                <Typography
                    variant={Variant.p}
                    article={Article.a3}
                    hideMarginBottom
                >
                    {t('descriptionInfoSignature')}
                </Typography>
                <Spacer lg={6} />
                {isErrorBlocDisplayed && (
                    <GridRow>
                        <GridCol col="12">
                            <AlertInputError errors={erreursListe} />
                        </GridCol>
                    </GridRow>
                )}
                {/* Langue contrat */}
                <LangueInfoSignature
                    convention={convention}
                    parcoursEmprunteur={parcoursEmprunteur}
                    loi14Actif={toggle?.texteLoi14 && !isOntario}
                    onLangueChange={(lang) => {
                        onConventionChanged('langue', lang);
                    }}
                />

                {/* Courriel */}
                <CourrielInfoSignature
                    parcoursEmprunteur={parcoursEmprunteur}
                    emailRef={emailRef}
                    isAdmissibleSI={isAdmissibleSI}
                    courrielPreRempli={convention?.courriel}
                    shouldEmailInputErrorDisplay={shouldEmailInputErrorDisplay}
                    handleEmailChange={(email: string) => {
                        onConventionChanged('courriel', email);
                    }}
                />

                {!isAdmissibleSI && (
                    <AuthentificationInfoSignature
                        convention={convention}
                        questions={questions}
                        shouldQuestion1ErrorDisplay={
                            shouldQuestion1ErrorDisplay
                        }
                        shouldQuestion2ErrorDisplay={
                            shouldQuestion2ErrorDisplay
                        }
                        shouldAnswer1ErrorDisplay={shouldAnswer1ErrorDisplay}
                        shouldAnswer2ErrorDisplay={shouldAnswer2ErrorDisplay}
                        shouldCellPhoneInputErrorDisplay={
                            shouldCellPhoneInputErrorDisplay
                        }
                        question1Ref={question1Ref}
                        question2Ref={question2Ref}
                        cellRef={cellRef}
                        onConventionChanged={onConventionChanged}
                    />
                )}

                <ConsentementInfoSignature
                    checkBoxRef={checkBoxRef}
                    isAdmissibleSI={isAdmissibleSI}
                    parcoursEmprunteur={parcoursEmprunteur}
                    isAssuranceAjoute={isAssuranceAjoute}
                    isOntario={isOntario}
                    isCheckBoxError={shouldCheckBoxErrorDisplay}
                    prenomNomMartine={
                        emprunteurMartine?.prenom + ' ' + emprunteurMartine?.nom
                    }
                    onCheckBoxClick={onCheckBoxClick}
                />
            </GridCol>
        </Grid>
    );
};
