import { useQuery, UseQueryResult } from 'react-query';

import {
    axiosInstance,
    getDynamicHeaders,
    reactCachedQueryOptions
} from '../../../../api';
import { FrequenceRemboursement } from '../../demande/useOffreApi/useOffreApi';

export type IDateProchainPaiement = {
    dateDernierPaiement: string;
    dateMinimale: string;
    dateMaximale: string;
    frequence: FrequenceRemboursement;
};

export const END_POINT =
    '/calculScenarioPaiement/demandes/{idDemande}/scenarios/datesProchainPaiement';

const fetch = async (idDemande: number) => {
    const endPoint = END_POINT.replace(/{idDemande}/g, String(idDemande));

    const { data } = await axiosInstance.get(endPoint, getDynamicHeaders());

    return data?.datesProchainPaiement;
};

export function useDatesProchainPaiementApi(
    idDemande: number = undefined,
    isEnabled = true
): UseQueryResult<Array<IDateProchainPaiement>, Error> {
    return useQuery<Array<IDateProchainPaiement>, Error>(
        [
            'calculScenarioPaiement/datesProchainPaiement',
            useDatesProchainPaiementApi
        ],
        () => fetch(idDemande),
        {
            ...reactCachedQueryOptions(),
            enabled: !!isEnabled
        }
    );
}
