import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

import { FrequenceRemboursement, offre as IOffre } from '../../hooks';

export interface IOffreModifier {
    amortissement: { annee: number; mois: number };
    borneMaximale: number;
    borneMinimale: number;
    frequenceRemboursementSauvegarder: FrequenceRemboursement; // Si la frequence a ete modifiee ET sauvegarder
    isCalculer: boolean; // Si le paiement entree a ete calculer
    shouldCalculAmortissement: boolean; // Si le paiement a ete entree avec un keypress
    frequenceRemboursement: FrequenceRemboursement;
    isAcceleree: boolean;
    label: string;
    paiementCalculerSauvegarder: number; // le dernier paiement valide calculer par le BE
    paiement: number; // le paiement en cours et non sauvegarder
    economies: {
        montantTotal: number;
        economieAssurance: any;
        economieInteret: any;
        economieCapital: any;
    };
    paiementsEtPourcentagesOffreActuelle: {
        montantTotalInteret: number;
        pourcentageInteretSurMontantTotal: number;
        montantTotalAssurancesVie: number;
        pourcentageAssurancesVieSurMontantTotal: number;
        montantTotalAssurancesInvalidite: number;
    };
    paiementsEtPourcentagesOffreSelectionnee: {
        montantTotalInteret: number;
        pourcentageInteretSurMontantTotal: number;
        montantTotalAssurancesVie: number;
        pourcentageAssurancesVieSurMontantTotal: number;
        montantTotalAssurancesInvalidite: number;
    };
}

interface IState {
    offreModifiee: IOffreModifier;
    offreChoisie: IOffre;
    offreBase: IOffre;
    offresList: Array<FrequenceRemboursement>;
    tauxInteret: number;
}

// Nous avons besoin de "sorter" les frequences par hebdomadaire, hebdomadaire acceleeree etc
const getFormattedOffreList = (list) => {
    const ACCELEREE = 'ACCELEREE';
    const formattedList = [];

    list.forEach((item) => {
        // regarder si il y a le mot ACCELLEREE
        const words = item?.value?.split('_');
        const acc = words[words.length - 1];
        if (acc === ACCELEREE) {
            words.pop(); // enlever le mot acceleree
            const normal = words.join('_');
            const i = formattedList.findIndex((x) => x.value === normal);
            formattedList.splice(i + 1, 0, item);
            // repositionner l'item acceleree sous l'item non acceleree
        } else {
            formattedList.push(item);
        }
    });
    return formattedList;
};

export const setFrequence = createAsyncThunk(
    'setFrequence',
    async (value, { getState, dispatch }) => {
        //@ts-ignore
        const offresList = getState().modalites.offresList;
        const offreModifiee = offresList.find(
            ({ frequenceRemboursement }) => frequenceRemboursement === value
        );

        dispatch(
            setOffreModifiee({
                ...offreModifiee,
                paiement: offreModifiee?.paiement.toFixed(2)
            })
        );
    }
);

const initialState: IState = {
    offreModifiee: null,
    offreChoisie: null,
    offreBase: null,
    offresList: null,
    tauxInteret: null
};

export const modalitesSlice = createSlice({
    name: 'modalites',
    initialState: initialState,
    reducers: {
        // l'offre choisie retourné dans /resume, ex: page modalites, date paiement et validation. Donc l'offre que
        // tu obtient en sauvegardant l'offre en backend dans le parcours de modalites ou simplement l'offre sauvegardé en backend comme choisie
        setOffreChoisie: (state, action) => {
            state.offreChoisie = action.payload;
        },
        // l'objet d'offre utilise pour sauvé le state de la page de modalites. Eventuellement lorsque le membre décide de
        // continuer ces informations sont transféré dans l'offre choisie en backend.
        setOffreModifiee: (state, action) => {
            state.offreModifiee = action.payload;
        },
        // l'offre avec laquel tu rentre dans la page de modalites pour la 1ere fois dans ta session. L'information provient
        // de l'offre choisie dans /resume. ex: offre selectionne dans la page offre ou l'offre simulee du parcours assurance
        setOffreBase: (state, action) => {
            state.offreBase = action.payload;
        },
        setOffresList: (state, action) => {
            state.offresList = getFormattedOffreList(action.payload);
        },
        setTauxInteret: (state, action) => {
            state.tauxInteret = action.payload;
        },
        reset: () => {
            return initialState;
        }
    }
});

export const {
    setOffreChoisie,
    setOffreModifiee,
    setOffreBase,
    setOffresList,
    setTauxInteret,
    reset
} = modalitesSlice.actions;

export default modalitesSlice.reducer;
