import React from 'react';
import { useSelector, useDispatch } from 'react-redux';

import {
    setFrequence,
    sendEvent,
    EVENT_CATEGORIES,
    EVENT_ACTIONS,
    EVENT_LABELS
} from '../../../../features';

import { FrequenceSelect } from './FrequenceSelect';
import { IOptions } from '../../../../styles';

const CYBERMETRIE_TOOLTIP_LABEL = 'frequence';

export const FrequenceSelectConnected: React.FC<unknown> = () => {
    const dispatch = useDispatch();

    const options = useSelector((state: any) => state.modalites.offresList);

    const offreModifiee = useSelector(
        (state: any) => state.modalites.offreModifiee
    );

    const handleFrequenceChange = (value) => {
        //@ts-ignore
        dispatch(setFrequence(value));

        dispatch(
            sendEvent({
                category: EVENT_CATEGORIES.RHN,
                action: EVENT_ACTIONS.CLIC,
                label: EVENT_LABELS.MODIFICATION_DE_FREQUENCE,
                extraAttribut2: options.find((o: IOptions) => o.value === value)
                    .label
            })
        );
    };

    const handleOnTooltipClick = () => {
        dispatch(
            sendEvent({
                category: EVENT_CATEGORIES.RHN,
                action: EVENT_ACTIONS.CLIC,
                label: EVENT_LABELS.INFOBULLE + CYBERMETRIE_TOOLTIP_LABEL
            })
        );
    };

    return (
        <FrequenceSelect
            value={offreModifiee?.frequenceRemboursement}
            options={options}
            onChange={(value) => handleFrequenceChange(value)}
            onToolTipClick={handleOnTooltipClick}
        />
    );
};
