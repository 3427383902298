import { IDemande } from './useObtenirDemandesApi';

const sortByDate = (a: any, b: any) => {
    if (a?.statut === 'ACCEPTEE' && b?.statut !== 'ACCEPTEE') {
        return 1;
    }
    if (b?.statut === 'ACCEPTEE' && a?.statut !== 'ACCEPTEE') {
        return -1;
    }
    return a?.dateDeRappel > b?.dateDeRappel
        ? 1
        : a?.dateDeRappel < b?.dateDeRappel
        ? -1
        : 0;
};

export const sortDemandesByDate = (demandes: Array<IDemande>): any => {
    return demandes.sort(sortByDate);
};
