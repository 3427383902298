// Date nous parviens du backend en string "20200204". Ajout de /
//eslint-disable-next-line
export function getFormattedDate(date: string, charToAdd: string) {
    if (!date) return '';
    return (
        date.substr(0, 4) +
        charToAdd +
        date.substr(4, 2) +
        charToAdd +
        date.substr(6, 2)
    );
}

export function getYear(date: string): string {
    if (!date) return '';
    return date.substr(0, 4);
}

export function getMonth(date: string): string {
    if (!date) return '';
    return date.substr(4, 2);
}

export function getDay(date: string): string {
    if (!date) return '';
    return date.substr(6, 2);
}

export function convertForBackend(date: string): string {
    if (!date) {
        return '';
    } else {
        return date.split('-').join('');
    }
}

export function getDateStr(date: Date): string {
    const day = date.getDate();
    const month = date.getMonth() + 1;
    const year = date.getFullYear();

    return `${year}${month < 10 ? `0${month}` : `${month}`}${
        day < 10 ? `0${day}` : `${day}`
    }`;
}
