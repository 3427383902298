export default {
    en: {
        title: 'Learn about the cost of loan insurance',
        coutProtectionActuelleTitle:
            'Cost of Versatile Line of Credit coverage applied to mortgage',
        coutProtectionActuelleTextPrefix:
            'The cost of insurance is an additional interest rate of ',
        coutProtectionActuelleTextSuffix:
            ' that’s applied to the current rate of your mortgage.',
        evolutionSoldePretTitle: 'Impact on mortgage balance',
        evolutionSoldePretTextPartie1:
            'Your regular payments will be higher if you take out loan insurance, but the life of the mortgage remains the same.',
        evolutionSoldePretTextPartie2:
            'The balance of an insured mortgage will be greater than that of an uninsured mortgage, except at the end of the mortgage.',
        evolutionSoldePretTextPartie3:
            'Your balance may vary as a result of different factors, including rate fluctuations (depending on the type of mortgage), prepayments and late payments.',
        // tables
        tableColoneTaux: 'Rate',
        tableColoneFrequencePaiement: 'Amount due ',
        tableLignePretSansAssurance: 'Mortgage without insurance',
        tableLignePretAvecAssurance: 'Mortgage with insurance',
        tableLigneSoldePretSansAssurance: 'Mortgage balance without insurance',
        tableLigneSoldePretAvecAssurance: 'Mortgage balance with insurance',
        tableColoneFinDuTermeRevisable: 'After 1 year',
        tableColoneFinDuTerme: 'End of term in {{ count }} year',
        tableColoneFinDuTerme_plural: 'End of term in {{ count }} years',
        tableColoneFinDuPretAnnee: 'End of mortgage in {{ count }} year',
        tableColoneFinDuPretAnnee_plural:
            'End of mortgage in {{ count }} years',
        tableColoneFinDuPretMois: ' and {{ count }} month',
        tableColoneFinDuPretMois_plural: ' and {{ count }} months',
        // frequences
        HEBDOMADAIRE: 'weekly',
        HEBDOMADAIRE_ACCELEREE: 'weekly',
        AUX_DEUX_SEMAINES: 'every 2 weeks',
        AUX_DEUX_SEMAINES_ACCELEREE: 'every 2 weeks',
        MENSUELLE: 'monthly',
        // buttons
        buttonText: 'Close'
    },
    fr: {
        title: 'Comprendre le coût de l’assurance prêt',
        coutProtectionActuelleTitle:
            'Coût pour la protection de votre marge Atout ajoutée à votre prêt',
        coutProtectionActuelleTextPrefix:
            'Le coût de l’assurance correspond à un taux d’intérêt additionnel de ',
        coutProtectionActuelleTextSuffix:
            ' ajouté au taux du prêt hypothécaire.',
        evolutionSoldePretTitle: 'Évolution du solde du prêt',
        evolutionSoldePretTextPartie1:
            'L’adhésion à l’assurance prêt augmente vos paiements réguliers. La durée totale de votre prêt hypothécaire reste la même.',
        evolutionSoldePretTextPartie2:
            'Le solde de votre prêt avec assurance sera plus élevé que celui sans assurance, sauf à la fin du prêt.',
        evolutionSoldePretTextPartie3:
            'Le solde pourrait varier en fonction de différents facteurs. Exemples : fluctuation du taux (selon le type de prêt), remboursements anticipés, retards de paiement.',
        // tables
        tableColoneTaux: 'Taux',
        tableColoneFrequencePaiement: 'Paiement ',
        tableLignePretSansAssurance: 'Prêt hypothécaire sans assurance',
        tableLignePretAvecAssurance: 'Prêt hypothécaire avec assurance',
        tableLigneSoldePretSansAssurance: 'Solde du prêt sans assurance',
        tableLigneSoldePretAvecAssurance: 'Solde du prêt avec assurance',
        tableColoneFinDuTermeRevisable: 'Après 1 an',
        tableColoneFinDuTerme: 'Fin du terme dans {{ count }} an',
        tableColoneFinDuTerme_plural: 'Fin du terme dans {{ count }} ans',
        tableColoneFinDuPretAnnee: 'Fin du prêt dans {{ count }} an',
        tableColoneFinDuPretAnnee_plural: 'Fin du prêt dans {{ count }} ans',
        tableColoneFinDuPretMois: ' et {{ count }} mois',
        tableColoneFinDuPretMois_plural: ' et {{ count }} mois',
        // frequences
        HEBDOMADAIRE: 'par semaine',
        HEBDOMADAIRE_ACCELEREE: 'par semaine',
        AUX_DEUX_SEMAINES: 'aux 2 semaines',
        AUX_DEUX_SEMAINES_ACCELEREE: 'aux 2 semaines',
        MENSUELLE: 'par mois',
        // buttons
        buttonText: 'Fermer'
    }
};
