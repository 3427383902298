const LOCAL_STORAGE_DEBUG_MODE_KEY = 'postachat.debug-mode';
const LOG_PREFIX = 'PostAchat -';

const shouldLog = (): boolean =>
    localStorage.getItem(LOCAL_STORAGE_DEBUG_MODE_KEY) == 'true';

const log = (msg: string): void => {
    if (shouldLog()) {
        // eslint-disable-next-line no-console
        console.log(`${LOG_PREFIX} ${msg}`);
    }
};

const error = (err: string): void => {
    if (shouldLog()) {
        // eslint-disable-next-line no-console
        console.error(`${LOG_PREFIX} ${err}`);
    }
};

const Logger = {
    log: log,
    error: error
};

export { Logger };
