import { Box } from '@material-ui/core';
import React from 'react';

import { ColorHex, Spacer, SpacingRange } from '../';

// helper to convert border radius
export enum BorderRadiusMui {
    none = '0px',
    sm = '0.5rem',
    md = '1rem',
    lg = '2rem'
}

type BoxShadow = 0 | 1 | 2 | 3;

interface IContainerMuiProps {
    alignItems?: string;
    backgroundColor?: string;
    children: React.ReactNode;
    borderRadius?: string;
    isDisabled?: boolean;
    margin?: string;
    height?: string;
    minHeight?: string;
    maxHeight?: string;
    minWidth?: string;
    maxWidth?: string;
    opacity?: string;
    padding?: string;
    className?: string;
    border?: number;
    borderColor?: ColorHex;
    boxShadow?: BoxShadow;
    hidePadding?: boolean;
    key?: number;
    containerRef?: any;
    spacerDistance?: SpacingRange;
    spacerDistanceMobile?: SpacingRange;
    noSpacer?: boolean;
    dataTestId?: string;
    isPointerOnHover?: boolean;
    style?: any;
    onClick?: () => void;
}

// TODO:: Remove when DSD will add border
export const ContainerMui: React.FC<IContainerMuiProps> = ({
    alignItems,
    children,
    margin,
    padding,
    hidePadding,
    height,
    minHeight,
    maxHeight,
    minWidth,
    maxWidth,
    borderRadius,
    border,
    boxShadow,
    key,
    containerRef,
    backgroundColor,
    spacerDistance = 5,
    spacerDistanceMobile = 5,
    borderColor = ColorHex.lightSystem3, // default
    noSpacer,
    dataTestId,
    isPointerOnHover,
    style,
    onClick
}) => {
    return (
        <>
            <Box
                //@ts-ignore - ref is missing in box def
                ref={containerRef}
                height={height}
                minHeight={minHeight}
                maxHeight={maxHeight}
                minWidth={minWidth}
                maxWidth={maxWidth}
                key={key}
                border={border}
                borderColor={borderColor}
                borderRadius={
                    borderRadius ? borderRadius : BorderRadiusMui.md // default DSD border radius
                }
                boxShadow={boxShadow}
                alignItems={alignItems}
                margin={margin}
                style={{
                    backgroundColor: backgroundColor
                        ? backgroundColor
                        : 'rgb(255, 255, 255)',
                    cursor: isPointerOnHover ? 'pointer' : '',
                    ...style
                }}
                padding={hidePadding ? 0 : padding ? padding : '1rem'}
                data-testid={dataTestId}
                onClick={onClick}
            >
                {children}
            </Box>
            {!noSpacer && (
                <Spacer
                    md={spacerDistance}
                    sm={spacerDistanceMobile}
                    xs={spacerDistanceMobile}
                />
            )}
        </>
    );
};
