export default {
    en: {
        back: 'Back',
        cancel: 'Save and leave',
        next: 'Continue',
        titre: 'Select your new payment date',
        description:
            'You selected a new payment frequency. Now you’ll need to decide when you want the new frequency to take effect.',
        titreDernierPaiement: 'Last payment with your old frequency',
        descriptionDernierPaiement:
            'One last automatic payment will be withdrawn from your account before your new payment frequency starts.',
        dateDernierAncienPaiement: '{{date, MMMM\xa0D}}, {{date, YYYY}}',
        HEBDOMADAIRE: 'Weekly',
        HEBDOMADAIRE_ACCELEREE: 'Weekly – accelerated',
        AUX_DEUX_SEMAINES: 'Every 2 weeks',
        AUX_DEUX_SEMAINES_ACCELEREE: 'Every 2 weeks – accelerated',
        MENSUELLE: 'Monthly',
        titrePremierPaiement: 'First payment with your new frequency',
        sousTitrePremierPaiement: 'Frequency',
        legende1: 'With current terms',
        legende2: 'With new terms',
        legende3: 'Today',
        titreInputDate: 'Start date',
        hintInputDate: 'DD-MM-YYYY',
        infoAlertMessage:
            'If you receive a regular wage, consider choosing a date that’s close to when you’re paid.',
        erreurCalendrierDateInvalide: 'Enter a valid date.',
        erreurCalendrierDateVide: 'Enter a date.',
        dateErreur: 'Date',
        titreAstuce: 'Think ahead',
        descriptionAstuce:
            'Consider picking a date that falls just after your payday, if you get paid on a regular schedule.'
    },
    fr: {
        back: 'Précédent',
        cancel: 'Enregistrer et quitter',
        next: 'Continuer',
        titre: 'Choisir votre date de paiement',
        description:
            'Vous avez choisi une nouvelle fréquence de paiement. Vous devez maintenant choisir à partir de quelle date cette fréquence s’appliquera.',
        titreDernierPaiement: 'Dernier paiement à la fréquence actuelle',
        descriptionDernierPaiement:
            'Un dernier paiement automatique sera prélevé de votre compte avant la nouvelle fréquence de paiement.',
        dateDernierAncienPaiement:
            '{{date, D}}{{suffixe}}\xa0{{date, MMMM\xa0YYYY}}',
        HEBDOMADAIRE: 'Toutes les semaines',
        HEBDOMADAIRE_ACCELEREE: 'Toutes les semaines – accéléré',
        AUX_DEUX_SEMAINES: 'Aux 2 semaines',
        AUX_DEUX_SEMAINES_ACCELEREE: 'Aux 2 semaines – accéléré',
        MENSUELLE: 'Tous les mois',
        titrePremierPaiement: 'Premier paiement à la nouvelle fréquence',
        sousTitrePremierPaiement: 'Fréquence',
        legende1: 'Avec les modalités actuelles',
        legende2: 'Avec les nouvelles modalités',
        legende3: "Aujourd'hui",
        titreInputDate: 'Date de début',
        hintInputDate: 'JJ-MM-AAAA',
        infoAlertMessage:
            'Si vous recevez un salaire régulier, pensez à choisir une date proche du versement.',
        erreurCalendrierDateInvalide: 'Entrer une date valide.',
        erreurCalendrierDateVide: 'Entrer une date.',
        dateErreur: 'Date',
        titreAstuce: 'Bien planifier',
        descriptionAstuce:
            'Pensez à choisir une date juste après votre jour de paie, si vous avez un salaire régulier.'
    }
};
