import { useQuery, UseQueryResult } from 'react-query';
import {
    axiosInstance,
    getDynamicHeaders,
    reactQueryOptions
} from '../../../../api';

export const END_POINT = `/calculScenarioPaiement/demandes/{idDemande}/offres/choisie/scenarios/paiement`;

const fetch = async (idDemande: number) => {
    const endPoint = END_POINT.replace(/{idDemande}/g, String(idDemande));

    const { data } = await axiosInstance.get(endPoint, getDynamicHeaders());

    return [data];
};

export function usePaiementApi(
    idDemande: number = undefined,
    isEnabled = true
): UseQueryResult<any[], Error> {
    return useQuery<any[], Error>(
        ['calculScenarioPaiement/paiement', usePaiementApi],
        () => fetch(idDemande),
        {
            ...reactQueryOptions(),
            enabled: !!isEnabled
        }
    );
}
