import React from 'react';
import { useTranslation } from 'react-i18next';

import {
    Button,
    ButtonVariant,
    LinkAction,
    LinkActionType,
    LinkActionTheme
} from '../../styles';
import { goQuitterParcours } from '../../hooks';
import { NavigationPlacement } from '../../components';
import { getRetourUrl } from '../../utils';
interface NavigationProps {
    onNextClick: () => void;
}

export const Navigation: React.FC<NavigationProps> = ({ onNextClick }) => {
    // hooks
    const [t] = useTranslation('Home');

    const handleCancel = () => {
        goQuitterParcours();
    };

    const retourUrl = getRetourUrl();

    return (
        <NavigationPlacement
            renderReturnButton={
                <LinkAction
                    href={retourUrl}
                    type={LinkActionType.return}
                    theme={LinkActionTheme.green}
                    dataTestId="navigation-quit-button"
                    onClick={handleCancel}
                >
                    {t('sommaire')}
                </LinkAction>
            }
            renderNextButton={
                <Button
                    id={'QA_test_navigation_next_button'}
                    variant={ButtonVariant.primary}
                    dataCy="qa-navigation-button-next"
                    dataTestId={'navigation-next-button'}
                    onClick={onNextClick}
                    fullWidth="xs"
                >
                    {t('start')}
                </Button>
            }
        ></NavigationPlacement>
    );
};
