import React from 'react';
import { useTranslation } from 'react-i18next';
import { Backdrop, makeStyles, Box } from '@material-ui/core';

import { ColorHex } from '..';

interface ILoadingScreenProps {
    show?: boolean;
}

const useStyles = makeStyles((theme) => ({
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#2f2f2f'
    }
}));

export const LoadingScreen: React.FC<ILoadingScreenProps> = ({
    show = true
}) => {
    const classes = useStyles();
    const [t] = useTranslation(['General']);

    return (
        <Backdrop
            className={classes.backdrop}
            open={show}
            data-testid="loading-backdrop"
        >
            <Box display="block" position="absolute">
                <Box
                    data-testid="loading-wheel"
                    display="flex"
                    flexDirection="row"
                    alignItems="center"
                    justifyContent="center"
                    margin="0 1.625rem"
                    borderRadius="1rem"
                    padding="1.625rem"
                    paddingRight="2.375rem" // compensation for dsd-loading box
                    bgcolor={ColorHex.lightWhite}
                    style={{
                        userSelect: 'none',
                        gap: '0.625rem'
                    }}
                >
                    {/* @ts-ignore */}
                    <dsd-loading size="xs" />
                    <b>{t('loadingText')}</b>
                </Box>
            </Box>
        </Backdrop>
    );
};
