import { mockInstance, withDelay } from '../../../../api/axiosInstance.mock';

import { SELECTIONNER_OFFRE_END_POINT } from '../../apiEndPoints';
import { dataCoEmprunteur1 as sessionMockData } from '../../contexte/useSessionApi/useSessionApi.mock';

const endPoint = SELECTIONNER_OFFRE_END_POINT.replace(
    /{idDemande}/g,
    String(sessionMockData?.contextePret?.idDemande)
)
    .replace(/{idOffre}/g, String('[0-9]+'))
    .replace(/{statutDemande}/g, String('[A-Z_]+'));

export const useSauvegarderModalitesOffreApiMock = (): any => {
    const url = new RegExp(`${endPoint}`);

    mockInstance.onPut(url).reply(
        withDelay(
            [
                200,
                {
                    pageInitiale: 'FINALISATION',
                    codeAvis: null
                }
            ],
            200
        )
    );
};
