import React from 'react';
import { Box } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import {
    Grid,
    GridRow,
    GridCol,
    Alert,
    AlertType,
    Typography,
    Variant,
    Police
} from '../../styles';

export interface IParlerConseillerProps {
    idDemande: number;
}

export const AnnuleeSysteme: React.FC<unknown> = () => {
    const [t] = useTranslation(['AnnuleeSysteme']);

    const renderAnnuleeSystemeHeader = () => (
        <>
            <Typography variant={Variant.h3} police={Police.bold}>
                {t('title')}
            </Typography>
        </>
    );
    const renderAnnuleeSystemeAlert = () => {
        return (
            <>
                <Box pb={1} data-testid="erreur_annulee_systeme">
                    <Alert type={AlertType.error}>
                        {t('annuleeSystemeText')}
                    </Alert>
                </Box>
            </>
        );
    };
    return (
        <>
            <Grid>
                <GridRow>
                    {/* Header title */}
                    <GridCol col="12" sm="12">
                        {renderAnnuleeSystemeHeader()}
                    </GridCol>
                    {/* Alert */}
                    <GridCol col="12" sm="12">
                        {renderAnnuleeSystemeAlert()}
                    </GridCol>
                </GridRow>
            </Grid>
        </>
    );
};
