export const stringToBoolean = (string: string): boolean => {
    if (!string) {
        return false;
    }
    switch (string.toLowerCase().trim()) {
        case 'true':
        case 'yes':
        case '1':
            return true;
        case 'false':
        case 'no':
        case '0':
        case null:
            return false;
        default:
            return Boolean(string);
    }
};

export const URLToURLSearchParams = (): URLSearchParams => {
    // On recupere les params de l'url
    let searchUrl = window.location.search;

    if (searchUrl?.length > 0) {
        searchUrl = decodeURIComponent(searchUrl);
        // On verifie qu'il n'y a pas deux "?" a cause d'un mauvais redirect de Auth0
        // Si il y en a plusieurs -> on remplace les autres par un "&" tout en gardant le premier intact
        if (searchUrl.split('?').length - 1 > 1) {
            let count = 0;
            searchUrl = searchUrl.replace(/\?/g, (match) =>
                ++count > 1 ? '&' : match
            );
        }
    }
    // On retourne la valeur
    return new URLSearchParams(searchUrl);
};
