export default {
    en: {
        protection: 'Your coverage',
        fumeur: 'Smoker',
        nonFumeur: 'Non-smoker',
        tooltipFumeur:
            'If you stopped smoking more than 12 months ago, contact us to get a better premium.',
        tooltipNonFumeur:
            'If your use of tobacco or nicotine products has changed, contact us',
        years: 'years',
        buttonText: 'Close',
        assuranceVie: 'Life insurance',
        assuranceInvalidite: 'Disability insurance',
        assuranceVieIneligible:
            'No longer eligible. Will be over 70 at renewal',
        assuranceInvaliditeIneligible:
            'No longer eligible. Will be over 65 at renewal',
        soldePret: 'of mortgage balance',
        paiementRegulier: 'of regular payments',
        titreSection: 'Your insurance is renewed automatically',
        admissible: "You're already eligible!",
        admissible_text:
            "Since your mortgage is already insured, you're automatically eligible for insurance when you renew your mortgage. You don't need to answer the medical questionnaire again.",
        insurance_documents_part1: 'View your insurance documents on ',
        insurance_documents_part2_bold: 'AccèsD ',
        insurance_documents_part3: 'under ',
        insurance_documents_part4_bold: 'Life and health insurance ',
        insurance_documents_part5: 'for more information.',
        modifierAssurance_part1: 'To change your coverage, ',
        modifierAssurance_part2: '.',
        modifierAssuranceLink: 'talk to our specialists',
        boutonsProtection: 'See your current coverage',
        click2call_aria: 'This link will open in a new window.'
    },
    fr: {
        protection: 'Votre protection',
        fumeur: 'Fumeur',
        nonFumeur: 'Non-fumeur',
        tooltipFumeur:
            'Si vous avez cessé de fumer depuis plus de 12 mois, communiquez avec nous. Nous vous offrirons une prime avantageuse.',
        tooltipNonFumeur:
            'Si votre usage du tabac ou des produits de nicotine a changé, communiquez avec nous.',
        years: 'ans',
        buttonText: 'Fermer',
        assuranceVie: 'Assurance vie',
        assuranceInvalidite: 'Assurance invalidité',
        assuranceVieIneligible:
            'N’est plus admissible car aura dépassé 70 ans au renouvellement',
        assuranceInvaliditeIneligible:
            'N’est plus admissible car aura dépassé 65 ans au renouvellement',
        soldePret: 'du solde du prêt',
        paiementRegulier: 'des paiements réguliers',
        titreSection: 'Renouvellement automatique de votre assurance',
        admissible: 'Vous êtes déjà admissible!',
        admissible_text:
            'Votre prêt hypothécaire est assuré. Vous êtes donc automatiquement admissible à l’assurance lors du renouvellement de votre prêt hypothécaire. Vous n’avez pas à répondre de nouveau au questionnaire sur votre état de santé.',
        insurance_documents_part1:
            'Pour en savoir plus, consultez vos documents d’assurance dans ',
        insurance_documents_part2_bold: 'AccèsD ',
        insurance_documents_part3: 'sous la section ',
        insurance_documents_part4_bold: 'Assurance vie et santé',
        insurance_documents_part5: '.',
        modifierAssurance_part1: 'Pour modifier votre assurance, ',
        modifierAssurance_part2: '.',
        modifierAssuranceLink: 'parlez à nos spécialistes',
        boutonsProtection: 'Voir votre protection actuelle',
        click2call_aria: 'Ce lien s’ouvrira dans une nouvelle fenêtre.'
    }
};
