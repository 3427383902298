import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { PaiementButton } from './PaiementButton';
import {
    setOffreModifiee,
    sendEvent,
    EVENT_CATEGORIES,
    EVENT_ACTIONS,
    EVENT_LABELS
} from '../../../../../features';
import { useAmortissementApi, useSessionApi } from '../../../../../hooks';
import { LoadingScreen } from '../../../../../styles';

export const PaiementButtonConnected: React.FC<unknown> = () => {
    const dispatch = useDispatch();

    // State
    const [isButtonDisabled, setIsButtonDisabled] = useState(true);

    // Store
    const offreChoisie = useSelector(
        (state: any) => state.modalites.offreChoisie
    );
    const offreModifiee = useSelector(
        (state: any) => state.modalites.offreModifiee
    );

    // Apis
    const { data: session } = useSessionApi();
    const amortissementApiMutation = useAmortissementApi();

    const setPaiementButtonState = () => {
        const paiementAmount = Number(offreModifiee?.paiement);
        const isDisabled =
            offreModifiee?.isCalculer ||
            offreModifiee?.isAcceleree ||
            paiementAmount ===
                Number(offreModifiee?.paiementCalculerSauvegarder) ||
            paiementAmount <= Number(offreModifiee?.borneMinimale) ||
            paiementAmount > Number(offreModifiee?.borneMaximale);

        setIsButtonDisabled(isDisabled);
    };

    const handlePaiementButtonChange = () => {
        if (!offreModifiee?.isCalculer) {
            amortissementApiMutation.mutateAsync({
                idDemande: session?.contextePret?.idDemande,
                idOffre: offreChoisie?.idOffre,
                montant: offreModifiee?.paiement,
                frequence: offreModifiee?.frequenceRemboursement
            });

            setPaiementButtonState();
        }
    };

    useEffect(() => {
        if (amortissementApiMutation.isSuccess) {
            dispatch(
                sendEvent({
                    category: EVENT_CATEGORIES.RHN,
                    action: EVENT_ACTIONS.CLIC,
                    label: EVENT_LABELS.MODIFICATION_DE_PAIEMENT
                })
            );

            const { data: amortissementData } = amortissementApiMutation;

            if (amortissementData) {
                if (amortissementData.codeAvis) {
                    // Si un code avis est présent on reload la page pour que le code avis ne soit pas affiché 2 fois
                    // ex: code avis dans modalites -> click -> redirect et reload sur la page offre -> 2e code avis affiché du reload de /renouvellement
                    window.location.reload();
                } else {
                    // @ts-ignore
                    const offre = amortissementData?.frequences[0];
                    const paiement = Number(offreModifiee.paiement).toFixed(2);
                    const newOffreModifiee = {
                        ...offreModifiee,
                        paiement: paiement,
                        paiementCalculerSauvegarder: paiement,
                        shouldCalculAmortissement: false,
                        isCalculer: true,
                        amortissement: offre?.amortissement,
                        economies: offre?.economies,
                        paiementsEtPourcentagesOffreActuelle:
                            offre?.paiementsEtPourcentagesOffreActuelle,
                        paiementsEtPourcentagesOffreSelectionnee:
                            offre?.paiementsEtPourcentagesOffreSelectionnee
                    };

                    if (offre) {
                        dispatch(setOffreModifiee(newOffreModifiee));
                    }

                    setPaiementButtonState();
                }
            }
        }
    }, [amortissementApiMutation.isSuccess]);

    useEffect(() => {
        setPaiementButtonState();
    }, [offreModifiee]);

    useEffect(() => {
        if (offreModifiee?.shouldCalculAmortissement) {
            handlePaiementButtonChange();
        }
    }, [offreModifiee?.shouldCalculAmortissement]);

    return (
        <>
            <LoadingScreen show={amortissementApiMutation.isLoading} />
            <PaiementButton
                isDisable={isButtonDisabled}
                paiement={{
                    value: offreModifiee?.paiement,
                    min: offreModifiee?.borneMinimale,
                    max: offreModifiee?.borneMaximale
                }}
                onChange={handlePaiementButtonChange}
            />
        </>
    );
};
