import React, { useEffect, useRef } from 'react';

interface ICalendarProps {
    isDark?: boolean;
    isDisabled?: boolean;
    identifier?: string;
    isError?: boolean;
    max?: string;
    min?: string;
    name?: string;
    range?: boolean;
    isRequired?: boolean;
    target?: string;
    value?: string;
    headingLabel?: string; // valeur par defaut 'Choisir une date' ou 'Choose a date'
    hideRequiredLabelling?: boolean;
    quietValidate?: boolean;
    errorLabel?: string;
    hintLabel?: string;
    label?: string;
    labelTooltip?: string;
    onDateChange?: (date: string) => void;
    onCalendarClose?: () => void;
    onCalendarOpen?: () => void;
}

export const Calendar: React.FC<ICalendarProps> = ({
    isDark = false,
    isDisabled = false,
    identifier,
    isError,
    max,
    min,
    name,
    range,
    isRequired = false,
    target,
    value,
    headingLabel,
    hideRequiredLabelling,
    quietValidate,
    errorLabel,
    hintLabel,
    label,
    labelTooltip,
    onDateChange,
    onCalendarClose,
    onCalendarOpen
}) => {
    const ref = useRef(null);
    const handleDateChange = (e) => {
        onDateChange(e?.detail?.value);
    };

    const handleCalendarClose = () => {
        onCalendarClose();
    };

    const handleCalendarOpen = () => {
        onCalendarOpen();
    };

    useEffect(() => {
        ref.current.addEventListener('dsdDatepickerChange', (e) => {
            if (onDateChange) handleDateChange(e);
        });

        ref.current.addEventListener('dsdDatepickerClose', () => {
            if (onCalendarClose) handleCalendarClose();
        });

        ref.current.addEventListener('dsdDatepickerOpen', () => {
            if (onCalendarOpen) handleCalendarOpen();
        });
    }, []);

    return (
        <>
            {/* @ts-ignore */}
            <dsd-datepicker
                ref={ref}
                dark={isDark}
                disabled={isDisabled}
                identifier={identifier}
                error={isError}
                max={max}
                min={min}
                name={name}
                range={range}
                required={isRequired}
                target={target}
                value={value}
                headingLabel={headingLabel}
                hideRequiredLabelling={hideRequiredLabelling}
                quietValidate={quietValidate}
            >
                <span slot="label">{label}</span>
                <span slot="error">{errorLabel}</span>
                <span slot="hint">{hintLabel}</span>
                <span slot="label-tooltip">{labelTooltip}</span>
                {/* @ts-ignore */}
            </dsd-datepicker>
        </>
    );
};
