import { mockInstance, withDelay } from '../../../../api/axiosInstance.mock';

import { STATUT_EMPRUNTEUR_END_POINT } from '../../apiEndPoints';
import { dataCoEmprunteur1 as sessionMockData } from '../../contexte/useSessionApi/useSessionApi.mock';

const endPoint = STATUT_EMPRUNTEUR_END_POINT.replace(
    /{idDemande}/g,
    String(sessionMockData?.contextePret?.idDemande)
);

export const useStatutEmprunteurApiMock = (): any => {
    mockInstance.onPut(endPoint).reply(
        withDelay(
            [
                200,
                {
                    pageInitiale: 'OFFRE_AFFICHEE',
                    codeAvis: null
                }
            ],
            200
        )
    );
};
