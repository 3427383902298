export default {
    en: {
        sommaire: 'Back to AccèsD home',
        title: 'Mortgage renewal',
        errorFonctionnelleText:
            'Your request cannot be processed.  Please speak to an advisor.',
        errorFonctionnelleTextConseiller:
            'The online mortgage renewal solution can no longer be viewed in read-only mode on AccèsD.',
        erreurTarifTitre: 'We’re unable to calculate your personalized offers',
        erreurTarifDescription:
            'Something went wrong. Contact your caisse to renew your mortgage with one of our experts.'
    },
    fr: {
        sommaire: 'Retour à l’accueil',
        title: 'Renouvellement hypothécaire',
        errorFonctionnelleText:
            'Il est impossible de traiter votre demande. Veuillez parler avec un conseiller.',
        errorFonctionnelleTextConseiller:
            'Le parcours de renouvellement hypothécaire numérique n’est plus présenté dans AccèsD en mode lecture.',
        erreurTarifTitre:
            'Nous n’arrivons pas à calculer vos offres sur mesure',
        erreurTarifDescription:
            "Une erreur s'est produite. Obtenez un accompagnement personnalisé en renouvelant votre prêt en caisse."
    }
};
