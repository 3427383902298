import { useMediaQuery } from '@material-ui/core';

export const getCardMinHeight = (): string => {
    const isMobile = useMediaQuery('(max-width:640px)');
    const isTablet = useMediaQuery('(max-width:991px)');
    const isEcranMedium = useMediaQuery('(max-width:1440px)');
    const isEcranSmall = useMediaQuery('(max-width:1200px)');

    // controle de la taille d'une tuile à differente grandeur pour que
    // toutes les tuiles aillent la meme grandeur quand plus grand que mobile
    let minHeight: string;
    if (isMobile) {
        minHeight = '28rem';
    } else if (isTablet) {
        minHeight = '26rem';
    } else if (isEcranSmall) {
        minHeight = '29.25rem';
    } else if (isEcranMedium) {
        minHeight = '29rem';
    } else {
        minHeight = '27rem';
    }

    return minHeight;
};

export const getCardTitleMarginBottomNoPromo = (
    isTitreSurDeuxLigne: boolean,
    isVoisinTitreSurDeuxLigne: boolean,
    isVoisinPromo: boolean
): string => {
    const isTablet = useMediaQuery('(max-width:991px)');

    // controle de l'espacement apres le titre pour que le taux soit aligne
    const offsetTitreDeuxLigne = 1;
    const offsetPromo = 1;
    const offsetPromoEtTitreDeuxLigne = 1;
    const startingMargin = isTitreSurDeuxLigne ? 1 : 2;
    let offsetMargin = 0;
    // si en mobile pas besoin d'aligner le taux car c'est une carte par range
    if (!isTablet) {
        // ajustement de base
        if (isVoisinPromo) {
            offsetMargin = offsetMargin + offsetPromo;
        }
        if (isVoisinTitreSurDeuxLigne) {
            offsetMargin = offsetMargin + offsetTitreDeuxLigne;
        }

        if (isVoisinTitreSurDeuxLigne && isVoisinPromo) {
            offsetMargin = offsetMargin + offsetPromoEtTitreDeuxLigne;
        }
        if (isTitreSurDeuxLigne) {
            offsetMargin = offsetMargin - offsetTitreDeuxLigne;
        }

        // ajustement mineur quand le titre est sur une ligne et que son voisin est sur deux ligne
        if (
            isVoisinTitreSurDeuxLigne &&
            !isTitreSurDeuxLigne &&
            !isVoisinPromo
        ) {
            offsetMargin = offsetMargin - 0.25;
        }
        if (
            isVoisinTitreSurDeuxLigne &&
            !isTitreSurDeuxLigne &&
            isVoisinPromo
        ) {
            offsetMargin = offsetMargin - 0.05;
        }
    }

    if (isTablet) {
        return '2rem';
    } else {
        return String(startingMargin + offsetMargin) + 'rem';
    }
};
