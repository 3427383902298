import React, { useEffect, useState } from 'react';
import { Box, useMediaQuery } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

import {
    GridCol,
    Variant,
    Article,
    Typography,
    Police,
    ColorDSD,
    InputType,
    ContainerMui,
    Input
} from '../../../../styles';
import { ParcoursEmprunteur } from '../../../../hooks';

export interface ICourrielInformationSignature {
    parcoursEmprunteur: string;
    courrielPreRempli: string;
    shouldEmailInputErrorDisplay: boolean;
    emailRef: any;
    isAdmissibleSI: boolean;
    handleEmailChange: (email: string) => void;
}

export const CourrielInfoSignature: React.FC<ICourrielInformationSignature> = ({
    parcoursEmprunteur,
    courrielPreRempli,
    shouldEmailInputErrorDisplay,
    emailRef,
    isAdmissibleSI,
    handleEmailChange
}) => {
    const [t] = useTranslation('InformationsSignature');
    const isMobile = useMediaQuery('(max-width:820px)');
    const [email, setEmail] = useState(courrielPreRempli);
    const [isErrorDisplayed, setIsErrorDisplayed] = useState(false);

    const onEmailChange = (value) => {
        setIsErrorDisplayed(false);
        setEmail(value);
        handleEmailChange(value);
    };

    useEffect(() => {
        if (shouldEmailInputErrorDisplay) {
            setIsErrorDisplayed(shouldEmailInputErrorDisplay);
        }
    }, [shouldEmailInputErrorDisplay]);

    return (
        <ContainerMui
            padding={
                isMobile
                    ? '0.33rem 1rem 0.33rem 1rem'
                    : '0.5rem 1.5rem 0.5rem 1.5rem'
            }
            spacerDistance={6}
            minWidth="100%"
            minHeight="fit-content"
        >
            {/* Courriel */}
            <GridCol col="12" md="8">
                <Typography variant={Variant.h3} police={Police.bold}>
                    {t('courrielTitre')}
                </Typography>
                <Typography
                    variant={Variant.p}
                    article={Article.a3}
                    hideMarginBottom
                >
                    {t(
                        isAdmissibleSI
                            ? 'courrielDescriptionSI'
                            : parcoursEmprunteur ===
                              ParcoursEmprunteur.COEMPRUNTEUR_GUY
                            ? 'courrielDescriptionGuyHP'
                            : 'courrielDescriptionMartineHP'
                    )}
                </Typography>
            </GridCol>
            {/* Courriel - input field - taille differente de grille */}
            <GridCol col="12" sm="12" md="4">
                <Box>
                    <Box display="flex" flexWrap="nowrap">
                        <Typography
                            variant={Variant.p}
                            article={Article.a3}
                            color={ColorDSD.lightSystem3}
                            hideMarginBottom
                        >
                            {t('courrielInput')}
                        </Typography>
                    </Box>
                    <Box maxWidth={isMobile ? '100%' : '300px'}>
                        <Input
                            inputRef={emailRef}
                            dataTestId={'input_courriel_field'}
                            isError={isErrorDisplayed}
                            errorLabel={t('courrielInputError')}
                            key="InputMui_InfoSignature_Email_01"
                            value={email}
                            type={InputType.EMAIL}
                            onFocusOut={onEmailChange}
                            onChange={onEmailChange}
                        />
                    </Box>
                </Box>
            </GridCol>
        </ContainerMui>
    );
};
