import { AxiosResponse } from 'axios';
import { useMutation } from 'react-query';

import { CONFIRMER_CONVENTION_END_POINT } from '../../apiEndPoints';
import {
    axiosInstance,
    reactQueryOptions,
    IMutationResult,
    getDynamicHeaders
} from '../../../../api';

interface IConfirmerConvention {
    idDemande: number;
    data: {
        statutDemande: string;
        langue: string;
        courriel: string;
        idOffre: number;
        // null en dessous de cette ligne si admissible SI
        modeAuthentification: string;
        cellulaire: string;
        question1Id: number;
        question2Id: number;
        reponse1: string;
        reponse2: string;
    };
}

const putConfirmerConvention = async (
    data: IConfirmerConvention
): Promise<AxiosResponse<any>> => {
    const endPoint = CONFIRMER_CONVENTION_END_POINT.replace(
        /{idDemande}/g,
        String(data?.idDemande)
    );

    const { data: response } = await axiosInstance.put(
        endPoint,
        data?.data,
        getDynamicHeaders()
    );

    return response;
};

export const useConfirmerConventionApi = (): IMutationResult => {
    return useMutation(putConfirmerConvention, {
        ...reactQueryOptions()
    });
};
