import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { ResumeModalites } from './ResumeModalites';
import {
    useRoutes,
    Routes,
    IResumeOffre,
    hasCurrentlyAssurance,
    isVariableProtege,
    ISession,
    hasSoumissionAssurance
} from '../../hooks';
import {
    sendEvent,
    EVENT_CATEGORIES,
    EVENT_ACTIONS,
    EVENT_LABELS
} from '../../features';

export interface IResumeModalitesConnectedProps {
    isEditButtonDisplayed: boolean;
    session: ISession;
    resumeOffre: IResumeOffre;
}

export const ResumeModalitesConnected: React.FC<
    IResumeModalitesConnectedProps
> = ({ isEditButtonDisplayed, session, resumeOffre }) => {
    const dispatch = useDispatch();
    const { goToRoute } = useRoutes();

    // State
    const [shouldEditButtonBeDisplayed, setShouldEditButtonBeDisplayed] =
        useState(null);

    // Store
    const isLoading = useSelector((state: any) => state.loading.show);

    const handleOnModifiedClick = () => {
        dispatch(
            sendEvent({
                category: EVENT_CATEGORIES.RHN,
                action: EVENT_ACTIONS.CLIC,
                label: EVENT_LABELS.MODIFIER + 'modalités de paiement'
            })
        );

        goToRoute(Routes.MODALITES);
    };

    const handleTooltipActive = () => {
        dispatch(
            sendEvent({
                category: EVENT_CATEGORIES.RHN,
                action: EVENT_ACTIONS.CLIC,
                label: EVENT_LABELS.INFOBULLE + 'Paiement avec assurance'
            })
        );
    };

    useEffect(() => {
        if (session && resumeOffre) {
            setShouldEditButtonBeDisplayed(
                isEditButtonDisplayed &&
                    session?.contexteToggleFeature.calculScenarioPaiement ===
                        true &&
                    !session?.isMartine &&
                    !isVariableProtege(resumeOffre?.offreChoisie) &&
                    !hasSoumissionAssurance(
                        resumeOffre?.assurance?.codeAssurance
                    )
            );
        }
    }, [session, resumeOffre]);

    return (
        !isLoading && (
            <ResumeModalites
                isEditButtonDisplayed={shouldEditButtonBeDisplayed}
                shouldDisplayVariableProtegeNote={isVariableProtege(
                    resumeOffre?.offreChoisie
                )}
                montant={resumeOffre?.offreChoisie.montantRemboursement}
                amortissement={resumeOffre?.offreChoisie.amortissement}
                frequence={resumeOffre?.offreChoisie.frequenceRemboursement}
                isPretAvecAssurance={hasCurrentlyAssurance(
                    resumeOffre?.assurance?.codeAssurance
                )}
                isPretAvecAjoutAssurance={hasSoumissionAssurance(
                    resumeOffre?.assurance?.codeAssurance
                )}
                // Resume Date n'apparait pas si changement frequence est null donc on enleve le spacer
                // pour que le spacing avec les boutons de navigation soit correct
                isLastInValidationPage={
                    resumeOffre?.offreChoisie
                        .dateProchainPaiementChangementFrequence == null
                }
                onModifiedClick={handleOnModifiedClick}
                onTooltipActive={handleTooltipActive}
            />
        )
    );
};
