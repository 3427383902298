import {
    CodeAssurance,
    END_POINT,
    FrequenceRemboursement,
    IPret,
    TypeAssurance,
    TypeFumeur,
    TypePret
} from './usePretApi';
import { mockInstance, withDelay } from '../../../../api/axiosInstance.mock';

import { dataCoEmprunteur1 as sessionMockData } from '../../contexte/useSessionApi/useSessionApi.mock';

export const data: IPret = {
    dureeTerme: 1,
    typePret: TypePret.VARIABLE_REDUIT,
    dateRappel: '20210201',
    solde: 31100.49,
    frequenceRemboursement: FrequenceRemboursement.AUX_DEUX_SEMAINES,
    amortissement: {
        annee: 15,
        mois: 3
    },
    typeAssurance: TypeAssurance.AP,
    indicateurReductionTauxNonFumeur: false,
    codeAssurance: CodeAssurance.VIE_INVALIDITE,
    montantRemboursement: 321.9,
    tauxInteret: 8.315,
    listeAssures: [
        {
            prenom: 'UNIQUE',
            nom: 'Mocked',
            age: 34,
            indicateurFumeur: TypeFumeur.OUI,
            pctCouvertureAssuranceVie: 100.0,
            pctCouvertureAssuranceInvalidite: 100.0,
            indicateurEligibiliteAssuranceVie: true,
            indicateurEligibiliteAssuranceInvalidite: true
        }
    ]
};

const endPoint = END_POINT.replace(
    /{idDemande}/g,
    String(sessionMockData?.contextePret?.idDemande)
);

export const usePretApiMock = (): any =>
    mockInstance.onGet(endPoint).reply(withDelay([200, data], 200));
