import React, { useEffect, useState } from 'react';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import { Box } from '@material-ui/core';

import { Chip } from '../../styles';

const useStyles = makeStyles(() =>
    createStyles({
        chip: {
            display: 'flex'
        },
        chipContainer: {
            display: 'flex',
            flexWrap: 'wrap',
            justifyContent: 'flex-start',
            alignItems: 'flex-start',
            flexDirection: 'row',
            paddingBottom: '1.5rem'
        }
    })
);

export interface IChipGroupProps {
    listeChips: Map<string, string>;
    defaultSelected: string;
    onSelectChip: (chipSelectionne: string) => void;
    altText: string;
}

export const ChipGroup: React.FC<IChipGroupProps> = ({
    defaultSelected,
    listeChips,
    onSelectChip,
    altText
}) => {
    const [chipSelectionne, setChipSelectionne] = useState('');
    const classes = useStyles();

    useEffect(() => {
        setChipSelectionne(defaultSelected);
    }, []);

    const handleChipValueChanged = (value) => {
        const chipName: string = value;
        if (chipName !== chipSelectionne && chipName !== undefined) {
            setChipSelectionne(chipName);
            onSelectChip(chipName);
        }
    };

    const renderedChipList = [];
    listeChips.forEach((chipName, chipKey) => {
        renderedChipList.push(
            <Box mt={1} mr={1} key={chipKey + '_box'} display="flex">
                <Chip
                    key={chipKey}
                    dataTestId={chipKey + '_filtre'}
                    value={chipKey}
                    name={chipKey}
                    label={chipName}
                    hasNoCross={true}
                    isChecked={chipKey === chipSelectionne}
                    onClick={handleChipValueChanged}
                    srOnlySuffix={altText + chipName}
                />
            </Box>
        );
    });

    return <div className={classes.chipContainer}>{renderedChipList}</div>;
};
