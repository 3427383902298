import { END_POINT, IQuestion } from './useQuestionsApi';
import { mockInstance, withDelay } from '../../../../api/axiosInstance.mock';

import { dataCoEmprunteur1 as sessionMockData } from '../../contexte/useSessionApi/useSessionApi.mock';

export const data: Array<IQuestion> = [
    {
        id: 7,
        libelle:
            "Dans quelle ville ou municipalité était l'école primaire où vous avez terminé votre 6e année?",
        idQuestionUnifiee: 7
    },
    {
        id: 8,
        libelle:
            "Quel est le nom de famille de votre meilleur ami d'enfance ? ",
        idQuestionUnifiee: 8
    },
    {
        id: 6,
        libelle: "Quel est le nom de l'école primaire de votre premier enfant?",
        idQuestionUnifiee: 6
    },
    {
        id: 2,
        libelle: 'Quel est le nom de votre premier employeur?',
        idQuestionUnifiee: 2
    },
    {
        id: 3,
        libelle:
            'Quel est le premier code postal que vous avez appris par coeur?',
        idQuestionUnifiee: 3
    },
    {
        id: 10,
        libelle: 'Quel est le prénom de votre grand-mère maternelle?',
        idQuestionUnifiee: 10
    },
    {
        id: 1,
        libelle: 'Quel est votre code secret pour cette transaction?',
        idQuestionUnifiee: 1
    },
    {
        id: 9,
        libelle: "Quel était votre surnom d'enfance ? ",
        idQuestionUnifiee: 9
    },
    {
        id: 4,
        libelle:
            "Quelle est l'année de l'achat de votre première maison / copropriété ? ",
        idQuestionUnifiee: 4
    },
    {
        id: 5,
        libelle: "Sur quelle rue avez - vous vécu à l'âge de 18 ans?",
        idQuestionUnifiee: 5
    }
];

const endPoint = END_POINT.replace(
    /{idDemande}/g,
    String(sessionMockData?.contextePret?.idDemande)
);

export const useQuestionsApiMock = (): any =>
    mockInstance.onGet(endPoint).reply(withDelay([200, data], 200));
