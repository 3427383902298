import React from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { PaiementInput } from './PaiementInput';
import { setOffreModifiee } from '../../../../../features';

export const PaiementInputConnected: React.FC<unknown> = () => {
    const dispatch = useDispatch();

    const offreModifiee = useSelector(
        (state: any) => state.modalites.offreModifiee
    );

    const handleInputChange = (
        value: string,
        shouldCalculAmortissement: boolean
    ) => {
        const newOffreModifiee = {
            ...offreModifiee,
            shouldCalculAmortissement: shouldCalculAmortissement,
            isCalculer: false,
            paiement: value
        };

        dispatch(setOffreModifiee(newOffreModifiee));
    };

    return (
        <PaiementInput
            isDisabled={offreModifiee?.isAcceleree}
            paiement={{
                value: offreModifiee?.paiement,
                min: offreModifiee?.borneMinimale,
                max: offreModifiee?.borneMaximale
            }}
            onChange={handleInputChange}
        />
    );
};
