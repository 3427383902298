import { axiosInstanceKeepAlive } from '../../../api';
import { mockFeatureToggle } from '../../../api/axiosInstance.mock';

const PATH_PROLONGATION_SESSION_AW =
    '/identifiantunique/api/v1/ping-aw-200.ajax';

const keepAliveCall = async () => {
    await axiosInstanceKeepAlive.get(PATH_PROLONGATION_SESSION_AW);
};

export function useKeepAliveApi(): void {
    // Mock enabled -> no call at all
    if (!mockFeatureToggle) {
        // Real call using cookies config to get URL
        keepAliveCall();
    }
}
