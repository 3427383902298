export default {
    en: {
        // Texte hors ecran
        offerChooseSRPrefix: 'Select ',
        offerChooseSRSuffix: ' mortgage and continue.',

        // Frequences
        HEBDOMADAIRE: 'weekly',
        AUX_DEUX_SEMAINES: 'every 2 weeks',
        MENSUELLE: 'monthly',
        HEBDOMADAIRE_ACCELEREE: 'weekly',
        AUX_DEUX_SEMAINES_ACCELEREE: 'every 2 weeks',

        // offer
        offerInterest: 'Personalized rate',
        offerInterestNote_part1: 'year 1 (rate revised each year and ',
        offerInterestNote_part2: '',
        offerInterestNote_part3: '',
        offerInterestNote_part4: ' rebate)',
        offerInterestNoteCeilingRate: 'maximum rate: ',
        offerInterestNotePrimeRate: 'prime rate: ',
        offerPrimeNotePrefix: 'prime rate: ',
        offerPayment: 'Payment',
        offerPaymentAp: 'Payment with insurance',
        offerPaymentApMat: 'Payment with insurance',
        offerPaymentCoemprunteur: 'Estimated payment',
        offerPaymentApCoemprunteur: 'Estimated payment with insurance',
        offerPaymentApMatCoemprunteur: 'Estimated payment with insurance',
        // Offres grisees
        offerDisabled40MF:
            'For details about this type of mortgage, contact your caisse.<br><br>* Some conditions apply',
        offerDisabled:
            'To select this type of mortgage, contact your caisse advisor.',

        offerYear: 'an',
        offerCurrent: 'Your current type of mortgage',
        offerPaymentNotePrefix: '(year 1)',
        offerPaymentNoteSup: '',
        offerPaymentNoteSuffix: '',
        offerPaymentNoteVariableProtege:
            '(calculated based on the maximum rate)',
        offerChooseLinkLabel: 'Select and continue',
        // sous type taux
        FERME: '',
        OUVERT: '',
        REDUIT: 'reduced ',
        PROTEGE: 'protected ',
        REGULIER: 'regular ',
        // type taux
        FIXE: 'fixed rate',
        VARIABLE: 'variable rate',
        REVISABLE: 'Yearly Rate Resetter',

        tagPromotion: 'Available for a limited time'
    },
    fr: {
        // Texte hors ecran
        offerChooseSRPrefix: 'Choisir le prêt ',
        offerChooseSRSuffix: ' et continuer.',

        // Frequences
        HEBDOMADAIRE: 'par semaine',
        AUX_DEUX_SEMAINES: 'aux 2 semaines',
        MENSUELLE: 'par mois',
        HEBDOMADAIRE_ACCELEREE: 'par semaine',
        AUX_DEUX_SEMAINES_ACCELEREE: 'aux 2 semaines',

        // offer
        offerInterest: 'Taux personnalisé',
        offerInterestNote_part1: '1',
        offerInterestNote_part2: 're',
        offerInterestNote_part3:
            ' année (taux révisé chaque année et rabais de ',
        offerInterestNote_part4: ')',
        offerInterestNoteCeilingRate: 'taux maximum : ',
        offerInterestNotePrimeRate: 'taux préférentiel : ',
        offerPrimeNotePrefix: 'taux préférentiel : ',
        offerPayment: 'Paiement',
        offerPaymentAp: 'Paiement avec assurance',
        offerPaymentApMat: 'Paiement avec assurance',
        offerPaymentCoemprunteur: 'Paiement estimé',
        offerPaymentApCoemprunteur: 'Paiement avec assurance estimé',
        offerPaymentApMatCoemprunteur: 'Paiement avec assurance estimé',
        // Offres grisees
        offerDisabled40MF:
            'Pour obtenir des précisions concernant ce type de prêt, communiquez avec votre caisse.<br><br>* Certaines conditions s’appliquent.',
        offerDisabled:
            'Pour choisir ce type de prêt, contactez votre spécialiste en caisse.',

        offerYear: 'an',
        offerCurrent: 'Votre type de prêt actuel',
        offerPaymentNotePrefix: '(1',
        offerPaymentNoteSup: 're',
        offerPaymentNoteSuffix: ' année)',
        offerPaymentNoteVariableProtege:
            '(calculé en fonction du taux maximum)',
        offerChooseLinkLabel: 'Choisir et continuer',

        // sous type taux
        FERME: '',
        OUVERT: '',
        REDUIT: ' réduit',
        PROTEGE: ' protégé',
        REGULIER: ' régulier',
        // type taux
        FIXE: 'fixe',
        VARIABLE: 'variable',
        REVISABLE: 'révisable annuellement',

        tagPromotion: 'Offert pour une durée limitée'
    }
};
