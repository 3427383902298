import React from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import {
    sendEvent,
    EVENT_CATEGORIES,
    EVENT_ACTIONS,
    EVENT_LABELS
} from '../../features';
import { IResumeOffre } from '../../hooks';
import { DetailsOffreModal } from '..';

export interface IDetailsOffreModalChoixCoEmprunteurConnectedProps {
    resumeOffre: IResumeOffre;
}

export const DetailsOffreModalChoixCoEmprunteurConnected: React.FC<
    IDetailsOffreModalChoixCoEmprunteurConnectedProps
> = ({ resumeOffre }) => {
    const dispatch = useDispatch();
    const [t] = useTranslation(['DetailsOffreModal']);

    const handleOpenModalAnalytic = () => {
        dispatch(
            sendEvent({
                category: EVENT_CATEGORIES.RHN,
                action: EVENT_ACTIONS.CLIC,
                label: EVENT_LABELS.VOIR_DETAIL_PRET,
                extraAttribut2: resumeOffre?.titreOffre
            })
        );
    };

    return (
        <DetailsOffreModal
            valeurDureeTerme={resumeOffre?.offreChoisie?.valeurDureeTerme}
            frequenceDureeTerme={resumeOffre?.offreChoisie?.frequenceDureeTerme}
            buttonLabel={t('boutonLabelDetailsChoixCoEmprunteur')}
            buttonDataTestId="bouton_offre_choisie"
            tauxRabaisSubsequent={
                resumeOffre?.offreChoisie?.tauxRabaisSubsequent
            }
            typeOffre={resumeOffre?.typeOffre}
            titreOffre={resumeOffre?.titreOffre}
            onOpenModalAnalytic={handleOpenModalAnalytic}
        ></DetailsOffreModal>
    );
};
