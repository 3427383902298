import { useQuery, UseQueryResult } from 'react-query';

import {
    axiosInstance,
    getDynamicHeaders,
    reactQueryOptions
} from '../../../../api';
import { EMPRUNTEURS_ACTUEL_DEMANDES } from '../../apiEndPoints';
import { Logger } from '../../../../utils';
import { sortDemandesByDate } from './useObtenirDemandesApi.transformer';
import { StatutDemande } from '../../contexte/useSessionApi/useSessionApi';
import { EmprunteurStatut } from '../../contexte/useRenouvellementApi/useRenouvellementApi';

export interface IDemande {
    idDemande: number;
    statut: StatutDemande;
    emprunteurStatut: EmprunteurStatut;
    dateDeRappel: string;
    solde: number;
    numeroEngagement: string;
}

export interface IDemandes {
    demandes: Array<IDemande>;
    mobileApp: boolean;
}

const obtenirDemandes = async () => {
    Logger.log('Appel pour obtenir les demandes');
    const { data: response } = await axiosInstance.get(
        EMPRUNTEURS_ACTUEL_DEMANDES,
        getDynamicHeaders()
    );

    // Sort by dateDeRappel
    if (response?.demandes?.length > 1) {
        response.demandes = sortDemandesByDate(response?.demandes);
    }
    return response;
};

export function useObtenirDemandesApi(): UseQueryResult<IDemandes, Error> {
    return useQuery<IDemandes, Error>(
        'obtenirDemandes',
        () => obtenirDemandes(),
        reactQueryOptions()
    );
}
