import React from 'react';

export type ToolTipPlacement =
    | 'none'
    | 'top'
    | 'top-end'
    | 'top-start'
    | 'right'
    | 'bottom'
    | 'bottom-end'
    | 'bottom-start'
    | 'left';

interface ITooltipProps {
    label: string;
    description: string;
    placement?: ToolTipPlacement;
    dark?: boolean;
    dataTestId?: string;
    onClick?: () => void;
}

export const Tooltip: React.FC<ITooltipProps> = ({
    label,
    description,
    placement = 'bottom',
    dark = false,
    dataTestId,
    onClick
}) => {
    return (
        <>
            <div onClick={onClick}>
                {/* @ts-ignore */}
                <dsd-tooltip
                    data-testid={dataTestId}
                    placement={placement}
                    dark={dark}
                >
                    <span slot="description">{description}</span>
                    <span slot="label">{label}</span>
                    {/* @ts-ignore */}
                </dsd-tooltip>
            </div>
        </>
    );
};
