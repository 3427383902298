import React, { useEffect, useRef } from 'react';
import { Box, useMediaQuery } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

import {
    ContainerMui,
    Grid,
    GridCol,
    GridRow,
    Variant,
    Typography,
    Calendar,
    Police,
    TipBanner,
    Spacer,
    DateText
} from '../../styles';
import {
    AlertInputError,
    ClavardageContexte,
    ProgressBar,
    hideClavardage,
    setContexteClavardage,
    showClavardage
} from '../../components';
import { IDateProchainPaiement } from '../../hooks/api/calculScenarioPaiement';
import { FrequenceRemboursement } from '../../hooks';

interface IModalitesDatePaiementProps {
    borneDatesPaiement: IDateProchainPaiement;
    ancienPaiement: number;
    nouvelleFrequencePaiement: FrequenceRemboursement;
    dateChoisie: string;
    isErreurVisible: boolean;
    isCalendarInputEmpty: boolean;
    isCalendarClicked: boolean;
    onDateChange: (date: string) => void;
    onCalendarFocus: () => void;
}

export const ModalitesDatePaiement: React.FC<IModalitesDatePaiementProps> = ({
    borneDatesPaiement,
    ancienPaiement,
    nouvelleFrequencePaiement,
    dateChoisie,
    isErreurVisible,
    isCalendarInputEmpty,
    isCalendarClicked,
    onDateChange,
    onCalendarFocus
}) => {
    // hooks
    const [t] = useTranslation('ModalitesDatePaiement');
    const isMobile = useMediaQuery('(max-width:640px)');
    const isTablet = useMediaQuery('(max-width:990px)');
    const dateRef = useRef(null);
    const dateDernierPaiement = new Date(
        borneDatesPaiement?.dateDernierPaiement
    );

    useEffect(() => {
        setContexteClavardage(ClavardageContexte.MODALITES);

        return () => {
            // Réinitialisation du contexte de clavardage
            setContexteClavardage(ClavardageContexte.DEFAULT);
        };
    }, []);

    return (
        <Grid>
            <ProgressBar subtitle={t('titre')} />
            <GridRow>
                <GridCol col="12">
                    <Typography
                        variant={Variant.p}
                        marginBottom={isTablet ? '1rem' : '1.5rem'}
                    >
                        {t('description')}
                    </Typography>
                </GridCol>
            </GridRow>
            {isErreurVisible && (
                <GridRow>
                    <GridCol col="12">
                        <AlertInputError
                            errors={[
                                {
                                    label: t('titreInputDate'),
                                    dataTestId: t('dateErreur'),
                                    ref: dateRef
                                }
                            ]}
                        />
                    </GridCol>
                </GridRow>
            )}
            <GridRow>
                <GridCol col="12">
                    <ContainerMui
                        padding={isMobile ? '1rem' : '1.5rem'}
                        spacerDistance={6}
                    >
                        <Typography variant={Variant.h3} police={Police.bold}>
                            {t('titreDernierPaiement')}
                        </Typography>
                        <Typography variant={Variant.p}>
                            {t('descriptionDernierPaiement')}
                        </Typography>
                        <Typography
                            variant={Variant.p}
                            police={Police.bold}
                            hideMarginBottom
                        >
                            {t('Number:currency', {
                                value: ancienPaiement
                            })}
                        </Typography>
                        <Typography
                            variant={Variant.p}
                            police={Police.bold}
                            hideMarginBottom
                        >
                            <DateText
                                translationKey="ModalitesDatePaiement:dateDernierAncienPaiement"
                                date={dateDernierPaiement}
                            >
                                {{ date: dateDernierPaiement }}
                            </DateText>
                        </Typography>
                    </ContainerMui>
                </GridCol>
            </GridRow>
            <GridRow>
                <GridCol col="12">
                    <ContainerMui
                        padding={isMobile ? '1rem' : '1.5rem'}
                        spacerDistance={6}
                    >
                        <Typography variant={Variant.h3} police={Police.bold}>
                            {t('titrePremierPaiement')}
                        </Typography>
                        <Typography variant={Variant.p} hideMarginBottom>
                            {t('sousTitrePremierPaiement')}
                        </Typography>
                        <Typography variant={Variant.p} police={Police.bold}>
                            {t(nouvelleFrequencePaiement)}
                        </Typography>
                        <Box
                            data-testid={'input_date_prochain_paiement'}
                            // @ts-ignore
                            ref={dateRef}
                            onFocus={onCalendarFocus}
                        >
                            <Calendar
                                identifier="date-frequence-paiment"
                                min={borneDatesPaiement?.dateMinimale}
                                max={borneDatesPaiement?.dateMaximale}
                                label={t('titreInputDate')}
                                value={dateChoisie}
                                hintLabel={t('hintInputDate')}
                                isRequired={true}
                                isError={
                                    isErreurVisible &&
                                    !isCalendarClicked &&
                                    (!dateChoisie || isCalendarInputEmpty)
                                }
                                errorLabel={
                                    !dateChoisie || isCalendarInputEmpty
                                        ? t('erreurCalendrierDateVide')
                                        : t('erreurCalendrierDateInvalide')
                                }
                                onDateChange={onDateChange}
                                onCalendarOpen={hideClavardage}
                                onCalendarClose={showClavardage}
                            />
                        </Box>
                        <Spacer md={6} />
                        <TipBanner
                            titleLevel={4}
                            title={t('titreAstuce')}
                            content={t('descriptionAstuce')}
                        />
                    </ContainerMui>
                </GridCol>
            </GridRow>
        </Grid>
    );
};
