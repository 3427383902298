export default {
    en: {
        steps_title: 'Renewing your mortgage online is easy',
        steps_one: 'Choose from the offers that are calculated just for you.',
        steps_two: 'Set your payment.',
        steps_three: 'Sign the required documents.',
        nextstep_text_delais_14_jours:
            'Have your co-borrower confirm in <strong>{{timeRemaining}}</strong> or less.',
        nextstep_text_delais_1_jours:
            'Have your co-borrower confirm in <strong>{{timeRemaining}}</strong> or less.',
        nextstep_text_delais_today:
            'Have your co-borrower confirm <strong>today</strong>.'
    },
    fr: {
        steps_title: 'Renouvelez votre prêt en toute simplicité',
        steps_one: 'Choisissez parmi les offres calculées juste pour vous.',
        steps_two: 'Déterminez vos paiements.',
        steps_three: 'Signez les documents requis.',
        nextstep_text_delais_today: `Obtenez la confirmation de votre coemprunteur <strong>aujourd'hui</strong>.`,
        nextstep_text_delais_1_jours:
            'Obtenez la confirmation de votre coemprunteur dans <strong>{{timeRemaining}}</strong> ou moins.',
        nextstep_text_delais_14_jours:
            'Obtenez la confirmation de votre coemprunteur dans les <strong>{{timeRemaining}}</strong> ou moins.'
    }
};
