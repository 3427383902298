import React, { useEffect, useRef, useState } from 'react';

import { Navigation } from './Signature.navigation';
import { HeadingProvider } from '../../context';
import { Headings } from '../../styles';
import { useDispatch, useSelector } from 'react-redux';
import {
    hideLoading,
    setStatutSignature,
    showLoading,
    sendEvent,
    EVENT_CATEGORIES,
    EVENT_ACTIONS,
    EVENT_LABELS
} from '../../features';
import { Signature } from './Signature';
import {
    SignatureStatutEnum,
    useSessionApi,
    useSignatureApi,
    useSignatureStatutApi,
    useSignatureAbandonneeApi,
    useEnteteApi,
    useSignatureConfirmationApi,
    useObtenirResumeApi,
    getPoolingSignatureStatut,
    useRenouvellementApi,
    getYearsOrMonths
} from '../../hooks';
import { isSignataireSignee } from './Signature.utils';

const headingMapping = {
    h1: Headings.h3
};

export const SignatureConnected: React.FC<unknown> = () => {
    const dispatch = useDispatch();

    const [signatureStatut, setSignatureStatut] =
        useState<SignatureStatutEnum>(null);

    // Apis
    const { data: session } = useSessionApi();
    const idDemande = session?.contextePret?.idDemande;
    const { data: renouvellement } = useRenouvellementApi(!!session);
    const { data: resume } = useObtenirResumeApi(
        idDemande,
        'Signature',
        !!renouvellement
    );
    const { data: entete } = useEnteteApi(idDemande);
    let poolingCount = 1;

    // Mutation
    //      Call pour lancer la creation de la ceremonie et obtenir le lien
    const signatureCreateMutation = useSignatureApi();
    //      Call pour le check avec le statut cote API
    const signatureRealStatutMutation = useSignatureStatutApi();
    //      Call pour abandonner la ceremonie lors du precedent
    const signatureAbandonneeMutation = useSignatureAbandonneeApi();
    //      Call pour confirmer la ceremonie
    const signatureConfirmationMutation = useSignatureConfirmationApi();

    // Refs
    const signatureStatutRef = useRef(signatureStatut);
    const signatureRealStatutMutationRef = useRef(
        signatureRealStatutMutation.isLoading
    );

    // Redux
    const checkSignature = useSelector(
        (state: any) => state.renouvellement.checkSignature
    );

    // Pooling pour le statut
    const signatureStatutPooling = async () => {
        let statut = signatureStatutRef.current;
        const isLoading = signatureRealStatutMutationRef.current;
        const isMartine = session?.isMartine;

        // Si l'autre appel est en cours ou le statut est ok, on ne pool plus la BD
        if (isLoading !== true && !isSignataireSignee(isMartine, statut)) {
            // Appel asynchrone vers le pooling
            // Toutes les 6 fois -> on appelle avec le fetchAPI = true
            const resp = await getPoolingSignatureStatut({
                idDemande: idDemande,
                idContrat: signatureCreateMutation?.data?.idContrat,
                isFetchingAPI: poolingCount % 6 == 0 ? true : false
            });
            poolingCount++;

            // Mise a jour du statut par le pooling si besoin
            statut = signatureStatutRef.current;
            const statutReceived = resp?.data?.statut;
            if (statutReceived && !isSignataireSignee(isMartine, statut)) {
                // Mise a jour du statut cette page
                setSignatureStatut(statutReceived);
                // Mise a jour du statut pour le header
                dispatch(setStatutSignature(statutReceived));
            }
        }
    };

    // Lancement du check API par le header
    useEffect(() => {
        if (checkSignature === true) {
            onClickBackOrQuit();
        }
    }, [checkSignature]);

    // Lancement de la creation
    useEffect(() => {
        if (renouvellement && entete && resume) {
            signatureCreateMutation.mutateAsync({
                idDemande: idDemande
            });
        }
    }, [renouvellement, entete, resume]);

    // On attend la fin du chargement
    useEffect(() => {
        if (signatureCreateMutation?.isSuccess) {
            dispatch(hideLoading());
            // tous les 5 seconde appeler statut signature
            const statutInterval = setInterval(() => {
                signatureStatutPooling();
            }, 5000);

            return () => {
                clearInterval(statutInterval);
            };
        }
    }, [signatureCreateMutation?.isSuccess]);

    // Mise a jour du current 'statut' pour l'interval
    useEffect(() => {
        signatureStatutRef.current = signatureStatut;
    }, [signatureStatut]);

    // Mise a jour du current 'isLoading' pour l'interval
    useEffect(() => {
        signatureRealStatutMutationRef.current =
            signatureRealStatutMutation.isLoading;
    }, [signatureRealStatutMutation.isLoading]);

    // Mise a jour du statut par le check API
    const updateStatutSignature = (statutReceived: SignatureStatutEnum) => {
        setSignatureStatut(statutReceived);
    };

    // get le statut quand bouton precedent ou quitter est clicker
    const onClickBackOrQuit = () => {
        dispatch(showLoading());

        setSignatureStatut(null);

        signatureRealStatutMutation.mutateAsync({
            idDemande: idDemande,
            idContrat: signatureCreateMutation?.data?.idContrat,
            isFetchingAPI: true
        });
    };

    // abandonne la signature quand click modifier apres avoir click precedent
    const onClickModifierVosChoixGuy = () => {
        dispatch(showLoading());
        signatureAbandonneeMutation.mutateAsync({
            idDemande: idDemande,
            idContrat: signatureCreateMutation?.data?.idContrat
        });
    };

    // confirmer quand membre click suivant
    const onClickNext = () => {
        dispatch(
            sendEvent({
                category: EVENT_CATEGORIES.FORMULAIRE,
                action: EVENT_ACTIONS.ETAPE_5_2,
                label: EVENT_LABELS.FRM,
                eventValue: resume?.offreChoisie?.solde,
                choixTerme: getYearsOrMonths(
                    resume?.offreChoisie?.valeurDureeTerme,
                    resume?.offreChoisie?.frequenceDureeTerme
                ),
                extraAttribut2: String(
                    resume?.offreChoisie?.typeTaux +
                        ' ' +
                        resume?.offreChoisie?.sousTypeTaux
                ).toLowerCase()
            })
        );

        signatureConfirmationMutation.mutateAsync({
            idDemande: idDemande,
            idContrat: signatureCreateMutation?.data?.idContrat
        });
    };

    useEffect(() => {
        dispatch(
            sendEvent({
                category: EVENT_CATEGORIES.FORMULAIRE,
                action: EVENT_ACTIONS.ETAPE_5_1,
                label: EVENT_LABELS.FRM
            })
        );
    }, []);

    return (
        <HeadingProvider value={headingMapping}>
            {signatureCreateMutation?.isSuccess && (
                <>
                    <Signature signature={signatureCreateMutation?.data} />
                    <Navigation
                        signatureStatut={signatureStatut}
                        signatureRealStatutMutation={
                            signatureRealStatutMutation
                        }
                        signatureAbandonneeMutation={
                            signatureAbandonneeMutation
                        }
                        signatureConfirmationMutation={
                            signatureConfirmationMutation
                        }
                        isMartine={session?.isMartine}
                        updateStatutSignature={updateStatutSignature}
                        onClickBackOrQuit={onClickBackOrQuit}
                        onClickModifierVosChoixGuy={onClickModifierVosChoixGuy}
                        onClickNext={onClickNext}
                    />
                </>
            )}
        </HeadingProvider>
    );
};
