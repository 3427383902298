import { useQuery, UseQueryResult } from 'react-query';

import {
    axiosInstance,
    getDynamicHeaders,
    reactCachedQueryOptions
} from '../../../../api';
import { getFormattedDate } from '../../../../utils';
import { PageInitiale } from '../useSessionApi/useSessionApi';
import { CodeAvisEnumModal } from '../../../../components';

export enum EmprunteurStatut {
    ACCUEIL = 'ACCUEIL',
    OFFRE_AFFICHEE = 'OFFRE_AFFICHEE',
    EVEIL_ASSURANCE = 'EVEIL_ASSURANCE',
    ASSURANCE = 'ASSURANCE',
    MODALITES = 'MODALITES',
    FREQUENCE = 'FREQUENCE',
    OFFRE_SELECTIONNEE = 'OFFRE_SELECTIONNEE',
    CONFIRMEE = 'CONFIRMEE',
    OFFRE_VALIDEE = 'OFFRE_VALIDEE'
}

export enum Sexe {
    INCONNU = 'INCONNU',
    MASCULIN = 'MASCULIN',
    FEMININ = 'FEMININ',
    NON_APPLICABLE = 'NON_APPLICABLE'
}

export enum IndicateurFumeur {
    SANS_INFORMATION = 'SANS_INFORMATION',
    OUI = 'OUI',
    NON = 'NON'
}

export enum ParcoursAssurance {
    Parcours1 = 'APMAT',
    Parcours2 = 'CONFIRMATIONAP',
    Parcours3 = 'AP'
}

export type emprunteur = {
    id: number;
    prenom: string;
    nom: string;
    courriel: string;
    sexe: string;
    telephone: string;
    poste: string;
    cellulaire: string;
    statut: EmprunteurStatut;
    dateAcceptation: string;
    connecte: boolean;
    admissibleAssuranceInvalidite: boolean;
    admissibleAssuranceVie: boolean;
    isAjoutAssurance?: boolean;
};

export type IAssureResume = {
    prenom: string;
    nom: string;
    codeSexe: Sexe;
    age: number;
    indicateurFumeur?: IndicateurFumeur;
    pctCouvertureAssuranceVie: number;
    pctCouvertureAssuranceInvalidite: number;
    indicateurEligibiliteAssuranceVie: boolean;
    indicateurEligibiliteAssuranceInvalidite: boolean;
};

export interface IRenouvellement {
    pageInitiale: PageInitiale;
    codeAvis: CodeAvisEnumModal | null;
    pretAdmissibleAssurance: boolean;
    parcoursAssurance?: ParcoursAssurance;
    emprunteurs: Array<emprunteur>;
    emprunteurConnecte?: emprunteur;
    emprunteurNonConnecte?: emprunteur | null;
    redirectUrl?: string;
    // Utilise lorsqu'un pret vient d'ajouter de l'assurance
    assures?: Array<IAssureResume>;
    montantTotalInteretRetard?: number;
    isSignatureIntegre?: boolean;
}

export const END_POINT = '/v2/contextes/renouvellement';

export const getEmprunteurTypeConnection = (
    isConnecte: boolean,
    emprunteurs: Array<emprunteur>
): any => {
    return emprunteurs.filter((emprunteur) => {
        return emprunteur.connecte === isConnecte;
    })[0];
};

const getUniqueTransformedData = (data) => {
    const connected: emprunteur = getEmprunteurTypeConnection(
        true,
        data?.emprunteurs
    );

    const transformedData: IRenouvellement = {
        ...data,
        emprunteurConnecte: {
            ...connected,
            dateAcceptation: getFormattedDate(connected.dateAcceptation, '/')
        }
    };

    return transformedData;
};

const getCoEmprunteursTransformedData = (data: IRenouvellement) => {
    const connected: emprunteur = getEmprunteurTypeConnection(
        true,
        data?.emprunteurs
    );
    const notConnected: emprunteur = getEmprunteurTypeConnection(
        false,
        data?.emprunteurs
    );

    const transformedData: IRenouvellement = {
        ...data,
        emprunteurConnecte: {
            ...connected,
            dateAcceptation: getFormattedDate(connected.dateAcceptation, '/')
        },
        emprunteurNonConnecte: {
            ...notConnected,
            dateAcceptation: getFormattedDate(notConnected.dateAcceptation, '/')
        }
    };
    return transformedData;
};

/*
    Ajout de propriete au payload Renouvellement
    - parcoursEmprunteur
    - emprunteurConnecte
    - emprunteurNonConnecte
*/
const getTransformedData = (data: IRenouvellement): any => {
    // Redirect url mode -> return data only. No mapping required
    if (data?.redirectUrl) {
        return data;
    }
    // Emprunteur Unique
    if (data?.emprunteurs?.length === 1) {
        return getUniqueTransformedData(data);
    }

    // Co-emprunteur
    /* Mise en place de l'utilisateur connectee ou non */
    return getCoEmprunteursTransformedData(data);
};

const fetch = async () => {
    const { data } = await axiosInstance.get(END_POINT, getDynamicHeaders());

    const transformedData = getTransformedData(data);

    return transformedData;
};

export function useRenouvellementApi(
    isEnable = true
): UseQueryResult<IRenouvellement, Error> {
    return useQuery<IRenouvellement, Error>(
        ['renouvellement', isEnable],
        () => fetch(),
        { ...reactCachedQueryOptions(), enabled: !!isEnable }
    );
}
