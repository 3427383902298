export default {
    en: {
        titleRhn: 'Mortgage renewal',
        title: 'Sign the required documents',
        back: 'Edit choices',
        backMartine: 'Back',
        cancel: 'Save and leave',
        next: 'Continue',
        // Back button
        lightBoxTropTardTitre: 'Your documents have been signed',
        lightBoxTropTardDescription_1:
            'You’ve signed the required documents. If you want to change your choices, contact our specialists.',
        lightBoxTropTardDescription_2: 'Call us',
        lightBoxTropTardDescription_3: '1-844-626-2476',
        lightBoxTropTardPrimaryButton: 'Got it',

        modalModifierVosChoixTitle: 'Edit your choices?',
        modalModifierVosChoixDescription:
            'If you edit your choices, you’ll need to redo certain steps of the renewal.',
        modalModifierVosChoixSecondaryButton: 'Continue signing',
        modalModifierVosChoixPrimaryButton: 'Edit choices'
    },
    fr: {
        titleRhn: 'Renouvellement hypothécaire',
        title: 'Signer les documents requis',
        back: 'Modifier vos choix',
        backMartine: 'Précédent',
        cancel: 'Enregistrer et quitter',
        next: 'Continuer',
        // Back button
        lightBoxTropTardTitre: 'Vos documents sont signés',
        lightBoxTropTardDescription_1:
            'Vous avez signé les documents requis. Pour modifier vos choix, contactez nos spécialistes.',
        lightBoxTropTardDescription_2: 'Appelez-nous',
        lightBoxTropTardDescription_3: '1 844 626-2476',
        lightBoxTropTardPrimaryButton: 'J’ai compris',

        modalModifierVosChoixTitle: 'Modifier vos choix?',
        modalModifierVosChoixDescription:
            'Si vous modifiez vos choix, vous devrez recommencer certaines étapes du renouvellement.',
        modalModifierVosChoixSecondaryButton: 'Continuer la signature',
        modalModifierVosChoixPrimaryButton: 'Modifier vos choix'
    }
};
