import React from 'react';
import { useTranslation } from 'react-i18next';
import {
    CardMedia,
    createStyles,
    makeStyles,
    Box,
    useMediaQuery
} from '@material-ui/core';

import { TypePret } from '../../hooks';
import {
    ContainerMui,
    Grid,
    GridCol,
    GridRow,
    Typography,
    Variant,
    Police,
    Spacer
} from '../../styles';
import { InteretNonPayeBox } from './interetNonPayeBox/InteretNonPayeBox';
import { AppelerCaisse } from '../../components';

const useStyles = makeStyles(() =>
    createStyles({
        imageMobile: {
            borderRadius: '1rem 1rem 0 0',
            height: '100%',
            paddingBottom: '75%',
            backgroundPosition: 'center',
            position: 'relative',
            display: 'flex',
            justifyContent: 'center'
        },
        imageTablet: {
            borderRadius: '0 1rem 1rem 0',
            height: '100%',
            position: 'relative',
            backgroundPosition: '70%',
            display: 'flex',
            justifyContent: 'center'
        },
        imageDesktop: {
            borderRadius: '0 1rem 1rem 0',
            height: '100%',
            position: 'relative',
            backgroundPosition: 'center',
            display: 'flex',
            justifyContent: 'center'
        }
    })
);

interface InteretRetardProps {
    montantTotalInteretRetard: number;
    date: string;
    typePret: TypePret;
}

export const InteretRetard: React.FC<InteretRetardProps> = ({
    montantTotalInteretRetard,
    date,
    typePret
}) => {
    const classes = useStyles();
    const [t] = useTranslation(['InteretRetard']);
    const isTablet = useMediaQuery('(max-width:990px)');
    const isMobile = useMediaQuery('(max-width:640px)');

    const isVariable =
        typePret === TypePret.VARIABLE_REDUIT ||
        typePret === TypePret.VARIABLE_REGULIER ||
        typePret === TypePret.VARIABLE_PROTEGE;

    return (
        <Grid>
            <ContainerMui hidePadding noSpacer margin="lg">
                <GridRow>
                    {/* image pour mobile */}
                    <GridCol col="12" className="sm:dsd-d-none">
                        <CardMedia
                            className={classes.imageMobile}
                            image={'/images/page_interet_retard_mobile.jpg'}
                        />
                    </GridCol>
                </GridRow>
                <GridRow>
                    <GridCol col="12" sm="8" md="6">
                        <Box
                            padding={
                                isMobile
                                    ? '1rem 1rem 0rem 1rem'
                                    : '1.5rem 0.5rem 0rem 1.5rem'
                            }
                        >
                            <Typography
                                variant={Variant.h1}
                                police={Police.bold}
                            >
                                {t('titre')}
                            </Typography>
                            {isVariable && (
                                <Typography variant={Variant.p}>
                                    {t('description_taux_variable')}
                                </Typography>
                            )}
                            <Typography variant={Variant.p}>
                                {t('description')}
                            </Typography>
                            <InteretNonPayeBox
                                date={date}
                                montantTotalInteretRetard={
                                    montantTotalInteretRetard
                                }
                            />
                            <Typography variant={Variant.h2}>
                                {t('option_titre')}
                            </Typography>
                            <ul>
                                <li>{t('option_1')}</li>
                                <li>{t('option_2')}</li>
                            </ul>
                            <Typography variant={Variant.h2}>
                                {t('appeler_nous_titre')}
                            </Typography>
                            <Typography variant={Variant.p}>
                                {t('appeler_nous_description')}
                            </Typography>
                            <AppelerCaisse />
                            {!isMobile && <Spacer all={3} />}
                        </Box>
                    </GridCol>
                    {/* image pour desktop */}
                    <GridCol
                        sm="4"
                        md="6"
                        className="dsd-d-none sm:dsd-d-block"
                    >
                        <CardMedia
                            className={
                                isTablet
                                    ? classes.imageTablet
                                    : classes.imageDesktop
                            }
                            image="/images/page_interet_retard_desktop.jpg"
                        />
                    </GridCol>
                </GridRow>
            </ContainerMui>
        </Grid>
    );
};
