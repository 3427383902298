export default {
    en: {
        amortissementRestant: 'of remaining amortization',
        and: 'and',
        chartTitle: 'Payment breakdown',
        tooltipTitle:
            'These results are estimates based on current data, including the term selected. They depend on a number of factors.',
        tooltipAmortissementRestant:
            'Time left to fully repay your mortgage based on new terms.',
        seriesTitle: 'Payment breakdown',
        seriesCapital: 'Principal',
        seriesInteret: 'Interest',
        seriesAssurance: 'Insurance'
    },
    fr: {
        amortissementRestant: 'd’amortissement restant',
        and: 'et',
        chartTitle: 'Répartition des paiements',
        tooltipTitle:
            'Ces résultats sont des estimations basées sur les données actuelles y compris le terme choisi. Ils pourraient varier en fonction de différents facteurs.',
        tooltipAmortissementRestant:
            'Temps requis pour rembourser la totalité de votre prêt hypothécaire en se basant sur les nouvelles modalités.',
        seriesTitle: 'Répartitions des paiements',
        seriesCapital: 'Capital',
        seriesInteret: 'Intérêt',
        seriesAssurance: 'Assurance'
    }
};
