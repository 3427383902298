import {
    CodeAssurance,
    TypeAssurance,
    IResumeOffre,
    IPret,
    TypeOffreTarification
} from '../../../../hooks';

export const hasAlreadyAssurance = (codeAssurance: string): boolean => {
    return (
        codeAssurance === CodeAssurance.VIE ||
        codeAssurance === CodeAssurance.VIE_INVALIDITE ||
        codeAssurance === CodeAssurance.RETRAIT
    );
};

export const hasOffreSimulee = (typeOffreTarification: string): boolean => {
    return typeOffreTarification === TypeOffreTarification.SIMULEE;
};

export const hasSoumissionAssurance = (codeAssurance: string): boolean => {
    return codeAssurance === CodeAssurance.SOUMISSION_ASSURANCE;
};

export const mustFetchSoldeFinTerme = (resume: IResumeOffre): boolean => {
    return (
        !resume?.offreChoisie?.indicateurFinDeTerme &&
        hasSoumissionAssurance(resume?.assurance?.codeAssurance)
    );
};

export const hasCurrentlyAssurance = (codeAssurance: string): boolean => {
    return (
        codeAssurance === CodeAssurance.VIE ||
        codeAssurance === CodeAssurance.VIE_INVALIDITE ||
        codeAssurance === CodeAssurance.SOUMISSION_ASSURANCE
    );
};

export const getTypeAssurance = (pret: IPret): TypeAssurance => {
    return pret?.codeAssurance !== CodeAssurance.RETRAIT
        ? pret?.typeAssurance
        : TypeAssurance.NONE;
};
