export { getLangue } from './langueUtil';
export { getFormattedDate, getDateStr } from './dateParser';
export { ScrollToTop } from './scrollToTop';
export {
    setCookieConfig,
    getCookieConfigAttributes,
    getLangueFromCookies,
    getDesjLangueFromCookie,
    getRetourUrl,
    getPretIdDemande,
    setPostAchatJWT,
    getPostAchatJWT,
    getConfigCookie,
    isMobileAppFromCookies,
    isNextReturnFromAssurance,
    isSimulateurOriginalSource,
    deleteCookie,
    DOMAIN_DESJARDINS_COOKIE,
    JWT_COOKIE_CONFIG_NAME,
    TNR_COOKIE,
    Langues,
    TNR_MOMENTVERITE_VALUES
} from './cookieParser';
export { isFrequenceEqual } from './frequenceUtils';
export { getParlerConseillerUrl, ContexteAppel } from './parlerConseiller';
export { stringToBoolean, URLToURLSearchParams } from './typeParser';
export { Logger } from './logger';

export const REGEX_ALPHANUMERIC = /^[a-zA-Z0-9]+$/i;
export const QUERY_PARAM_USE_AUTH0 = 'useAuth0';
export const QUERY_PARAM_DEEP_LINK = 'dl';
export const QUERY_PARAM_LANGUE = 'lg';
export const QUERY_PARAM_USER_MODE = 'userMocked';
export const QUERY_PARAM_USE_MOCK = 'useMock';
export const MOBILE_WIDTH = 640;
