import React from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles, createStyles } from '@material-ui/core/styles';

import { Typography, Variant, Article, ColorHex, Headings } from '../../styles';
import { HeadingProvider } from '../../context';

const headingMapping = {
    h2: Headings.h6
};

const useStyles = makeStyles(() =>
    createStyles({
        numberLink: {
            textDecoration: 'underline',
            color: ColorHex.primary,
            fontWeight: 'bold'
        },
        numberLinkMB: {
            marginBottom: '16px'
        },
        noUnderline: {
            textDecoration: 'none'
        }
    })
);

export const AppelerCaisse: React.FC<unknown> = () => {
    const classes = useStyles();
    const [t] = useTranslation(['AppelerCaisse']);

    return (
        <HeadingProvider value={headingMapping}>
            <Typography variant={Variant.h2} marginBottom="0.5rem">
                {t('MTLTitre')}
            </Typography>
            <a
                href={'tel:+' + t('MTLLink')}
                aria-label={t('MTLLinkAria') + ' ' + t('linkHS')}
                className={classes.noUnderline}
            >
                <Typography
                    variant={Variant.p}
                    article={Article.a1}
                    dataTestId="appeler_caisse_mtl_link"
                >
                    <span className={classes.numberLink}>{t('MTLLink')}</span>
                    <span>{t('MTLLinkSuffix')}</span>
                </Typography>
            </a>
            <Typography variant={Variant.h2} marginBottom="0.5rem">
                {t('autreTitre')}
            </Typography>
            <a
                href={'tel:+' + t('autreLink')}
                aria-label={t('autreLinkAria') + ' ' + t('linkHS')}
                className={classes.noUnderline}
            >
                <Typography
                    variant={Variant.p}
                    article={Article.a1}
                    dataTestId="appeler_caisse_autre_link"
                    className={classes.numberLinkMB}
                >
                    <span className={classes.numberLink}>{t('autreLink')}</span>
                    <span>{t('autreLinkSuffix')}</span>
                </Typography>
            </a>
        </HeadingProvider>
    );
};
