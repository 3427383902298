import React from 'react';
import { AccordionSize } from '../accordion/Accordion';

interface IAccordionGroup {
    dark?: boolean;
    size?: AccordionSize;
    buttonSrOnlyPrefix?: string; //Prefix à ajouter aux boutons pour les lecteurs d'écrans
    className?: string;
}

export const AccordionGroup: React.FC<IAccordionGroup> = ({
    dark,
    size,
    buttonSrOnlyPrefix,
    className,
    children
}) => (
    // @ts-ignore
    <dsd-accordion-group
        dark={dark}
        size={size}
        buttonSrOnlySuffix={buttonSrOnlyPrefix}
        className={className}
    >
        {children}
        {/* @ts-ignore */}
    </dsd-accordion-group>
);
