import {
    IOffres,
    offre,
    FrequenceDureeTerme,
    TypeOffreTarification,
    TypeTauxFilter,
    TypeTaux
} from './useOffreApi';

import {
    is1AnsFixeFerme,
    is2AnsFixeFerme,
    is30MoisFixeFerme,
    is3AnsFixeFerme,
    is4AnsFixeFerme,
    is55MoisFixeFerme,
    is5AnsFixeFerme,
    is5AnsRevisableFerme,
    is5AnsVariableReduit,
    is5AnsVariableProtege,
    is7AnsFixeFerme,
    isVariableReduit,
    is40MoisFixeFerme,
    getOfferTitle,
    isVariableProtege,
    is39MoisFixeFerme,
    is42MoisFixeFerme
} from './Offres.utils';

export enum TypeOffre {
    UN_ANS_FIXE_FERME = '1AFF',
    DEUX_ANS_FIXE_FERME = '2AFF',
    TROIS_ANS_FIXE_FERME = '3AFF',
    QUATRE_ANS_FIXE_FERME = '4AFF',
    CINQ_ANS_FIXE_FERME = '5AFF',
    SEPT_ANS_FIXE_FERME = '7AFF',
    CINQ_ANS_REVISABLE_FERME = '5ARF',
    CINQ_ANS_VARIABLE_REDUIT = '5AVR',
    CINQ_ANS_VARIABLE_PROTEGE = '5AVP',
    TRENTE_MOIS_FIXE_FERME = '30MFF',
    QUARANTE_MOIS_FIXE_FERME = '40MFF',
    QUARANTE_DEUX_MOIS_FIXE_FERME = '42MFF',
    CINQUANTE_CINQ_MOIS_FIXE_FERME = '55MFF',
    TRENTENEUF_MOIS_FIXE_FERME = '39MFF'
}

export const TEXTE_DYNAMIQUE = [
    TypeOffre.TROIS_ANS_FIXE_FERME,
    TypeOffre.CINQ_ANS_FIXE_FERME,
    TypeOffre.QUARANTE_MOIS_FIXE_FERME,
    TypeOffre.CINQUANTE_CINQ_MOIS_FIXE_FERME,
    TypeOffre.TRENTENEUF_MOIS_FIXE_FERME,
    TypeOffre.QUARANTE_DEUX_MOIS_FIXE_FERME
];

export type TypeOffreParams = {
    valeurDureeTerme: number;
    typeTaux: string;
    sousTypeTaux: string;
    frequenceDureeTerme?: FrequenceDureeTerme;
};

export const getTypeOffre = (offre: TypeOffreParams): TypeOffre => {
    switch (true) {
        case is1AnsFixeFerme(offre):
            return TypeOffre.UN_ANS_FIXE_FERME;
        case is2AnsFixeFerme(offre):
            return TypeOffre.DEUX_ANS_FIXE_FERME;
        case is3AnsFixeFerme(offre):
            return TypeOffre.TROIS_ANS_FIXE_FERME;
        case is4AnsFixeFerme(offre):
            return TypeOffre.QUATRE_ANS_FIXE_FERME;
        case is5AnsFixeFerme(offre):
            return TypeOffre.CINQ_ANS_FIXE_FERME;
        case is7AnsFixeFerme(offre):
            return TypeOffre.SEPT_ANS_FIXE_FERME;
        case is5AnsVariableReduit(offre):
            return TypeOffre.CINQ_ANS_VARIABLE_REDUIT;
        case is5AnsRevisableFerme(offre):
            return TypeOffre.CINQ_ANS_REVISABLE_FERME;
        case is5AnsVariableProtege(offre):
            return TypeOffre.CINQ_ANS_VARIABLE_PROTEGE;
        case is40MoisFixeFerme(offre):
            return TypeOffre.QUARANTE_MOIS_FIXE_FERME;
        case is30MoisFixeFerme(offre):
            return TypeOffre.TRENTE_MOIS_FIXE_FERME;
        case is55MoisFixeFerme(offre):
            return TypeOffre.CINQUANTE_CINQ_MOIS_FIXE_FERME;
        case is39MoisFixeFerme(offre):
            return TypeOffre.TRENTENEUF_MOIS_FIXE_FERME;
        case is42MoisFixeFerme(offre):
            return TypeOffre.QUARANTE_DEUX_MOIS_FIXE_FERME;
        default:
            return null;
    }
};

const offerDetailLink = (offer: offre) => {
    return offer.idOffre; //lien/fonction pour détail de l'offre
};

const offerSelectLink = (offer: offre) => {
    return offer.idOffre; //lien/fonction pour choisir et continuer
};

const primeRate = (offer: offre) => {
    return Number(offer.valeurTauxPreferentiel);
};

const getEffectiveRate = (offer: offre) => {
    return Number(offer?.valeurTauxEffectif) || 0;
};

const getCeilingRate = (offer: offre) => {
    return Number(offer?.valeurTauxPlafond) || 0;
};

const interestRate = (offer: offre) => {
    return offer?.valeurTauxPropose ? Number(offer.valeurTauxPropose) : null;
};

const hasPrimeNote = (offer: offre) => {
    return isVariableReduit(offer);
};

const hasInterestNote = (offer: offre) => {
    // https://confluence.desjardins.com/display/PHRH/RA-Page-00005+Affichage+page+d%27offre#RAPage00005
    // Voir rêgle d'affaire: RA-Page-00005-00011
    return offer.typeTaux === TypeTaux.REVISABLE;
};

const isOfferDisabled = (offer: offre) => {
    // https://confluence.desjardins.com/display/PHRH/RA-Page-00005+Affichage+page+d%27offre#RAPage00005
    // Voir rêgle d'affaire: RA-Page-00005-00012 / RA-Page-00005-Affichage-Page-Des-Offres-Refonte - Section 3- Offres
    return (
        offer?.typeOffreTarification ===
            TypeOffreTarification.NON_SELECTIONABLE || !offer?.idOffre
    );
};

const typeTaux = (offre: offre) => {
    return offre.typeTaux;
};

const typeTauxFilter = (offre: offre) => {
    if (offre.typeTaux === TypeTaux.REVISABLE) {
        return TypeTauxFilter.VARIABLE;
    }
    return offre.typeTaux;
};

const hasVariableProtegeInterestNote = (
    isDisabled: boolean,
    isVariableProtege,
    effectiveRate,
    ceilingRate
) => {
    return !isDisabled && isVariableProtege && effectiveRate && ceilingRate;
};

const sousTypeTaux = (offre: offre) => {
    return offre.sousTypeTaux;
};

const getTauxRabaisSubsequent = (offre: offre) => {
    //https://confluence.desjardins.com/display/PHRH/RA-Page-00005+Affichage+page+d%27offre+-+Refonte
    //Voir rêgle d'affaire: RA-Page-00005-00011
    return Math.abs(offre.tauxRabaisSubsequent);
};

export const getOffreChoisie = (offres: Array<offre>): any => {
    return offres.find(({ offreSelectionnee }) => offreSelectionnee);
};

export const getTransformedOffers = (
    offres: IOffres,
    idDemande: number
): any => {
    return offres.listeOffre
        .filter(
            (offre: offre) =>
                offre?.typeOffreTarification !==
                    TypeOffreTarification.SIMULEE &&
                offre?.typeOffreTarification !==
                    TypeOffreTarification.ACTUALISEE
        )
        .map((offre: offre) => {
            const ceilingRate = getCeilingRate(offre);
            const isDisabled = isOfferDisabled(offre);
            const effectiveRate = getEffectiveRate(offre);
            const variableProtege = isVariableProtege(offre);

            return {
                idOffre: offre.idOffre,
                idDemande: idDemande,
                offerTitle: getOfferTitle(
                    offre.typeTaux,
                    offre.sousTypeTaux,
                    offre.valeurDureeTerme,
                    offre?.frequenceDureeTerme
                ),
                valeurDureeTerme: offre.valeurDureeTerme,
                frequenceDureeTerme: offre?.frequenceDureeTerme,
                isCurrent: offre.offreInitiale,
                offerDetailLink: offerDetailLink(offre),
                offerSelectLink: offerSelectLink(offre),
                paiement: offre.montantRemboursement,
                frequence: offre.frequenceRemboursement,
                isDisabled: isDisabled,
                typeOffreTarification: offre?.typeOffreTarification,
                primeRate: primeRate(offre),
                effectiveRate: effectiveRate,
                ceilingRate: ceilingRate,
                interestRate: interestRate(offre),
                hasPrimeNote: hasPrimeNote(offre),
                hasInterestNote: hasInterestNote(offre),
                typeTaux: typeTaux(offre),
                typeTauxFilter: typeTauxFilter(offre),
                sousTypeTaux: sousTypeTaux(offre),
                typeOffre: getTypeOffre(offre),
                tauxRabaisSubsequent: getTauxRabaisSubsequent(offre),
                showVariableProtegeInterestNote: hasVariableProtegeInterestNote(
                    isDisabled,
                    variableProtege,
                    effectiveRate,
                    ceilingRate
                )
            };
        });
};
