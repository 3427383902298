import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import { Box } from '@material-ui/core';

import {
    Typography,
    FootnoteGroup,
    FootnoteRef,
    FootnoteGoupListType,
    Footnote,
    Police,
    Variant,
    Article,
    Hr,
    ColorDSD,
    Lightbox
} from '../../../../styles';

const useStyles = makeStyles(() =>
    createStyles({
        note: {
            paddingLeft: '1rem',
            paddingTop: '1rem'
        },
        lienNote: {
            color: '#0000FF !important',
            cursor: 'pointer',
            display: 'inline'
        }
    })
);

interface ProtectionProps {
    isOpen: boolean;
    onClose: () => void;
}

export const InfoTaux: React.FC<ProtectionProps> = ({ isOpen, onClose }) => {
    const [t] = useTranslation('InfoTaux');
    const classes = useStyles();
    const descriptionTauxVariableRef = useRef(null);

    const renderHr1 = () => {
        return <Hr color={ColorDSD.hr1} />;
    };

    const scrollToDescriptionTauxVariable = () =>
        descriptionTauxVariableRef.current.scrollIntoView({
            behavior: 'smooth',
            block: 'center'
        });

    const PaddingLeft = ({ children }) => {
        return <Box paddingLeft={'1.5rem'}>{children}</Box>;
    };

    const Titre = ({ children, isFirst = false }) => {
        return (
            <PaddingLeft>
                <Box
                    paddingBottom={'0.25rem'}
                    paddingTop={isFirst ? '0.25rem' : '1rem'}
                >
                    <Typography
                        variant={Variant.p}
                        color={ColorDSD.primary}
                        article={Article.a3}
                        police={Police.bold}
                    >
                        {children}
                    </Typography>
                </Box>
            </PaddingLeft>
        );
    };

    const SousTitre = ({ children, isNote = false }) => {
        return (
            <PaddingLeft>
                <Box
                    paddingTop={isNote ? '2.75rem' : '0.75rem'}
                    paddingBottom={'0.5rem'}
                >
                    <Typography
                        variant={Variant.p}
                        article={Article.a3}
                        police={Police.bold}
                        hideMarginBottom
                    >
                        {children}
                    </Typography>
                </Box>
            </PaddingLeft>
        );
    };

    const Text = ({ children }) => {
        return (
            <PaddingLeft>
                <Typography variant={Variant.p}>{children}</Typography>
            </PaddingLeft>
        );
    };

    const handleKeyPress = (key: string) => {
        if (key === 'Enter') {
            scrollToDescriptionTauxVariable();
        }
    };

    return (
        <>
            {isOpen && (
                <Lightbox
                    show={true}
                    title={t('titre')}
                    buttonPrimaryLabel={t('boutonFermerModal')}
                    onClose={onClose}
                    onClickPrimaryButton={onClose}
                >
                    {/* TAUX FIXE */}
                    <Titre isFirst>{t('titreTauxFixe')}</Titre>
                    <Hr />

                    <SousTitre>{t('sousTitrePaiement')}</SousTitre>
                    <Text>{t('descriptionPaiementTauxFixe')}</Text>
                    {renderHr1()}

                    <SousTitre>{t('sousTitreTaux')}</SousTitre>
                    <Text>{t('descriptionTauxTauxFixe')}</Text>
                    {renderHr1()}

                    <SousTitre>{t('sousTitreHistoriqueTaux')}</SousTitre>
                    <Text>{t('descriptionHistoriqueTauxFixe')}</Text>
                    {renderHr1()}

                    <SousTitre>{t('sousTitrePourquoi')}</SousTitre>
                    <PaddingLeft>
                        <ul>
                            <li> {t('descriptionPourquoiTauxFixe_1')}</li>
                            <li> {t('descriptionPourquoiTauxFixe_2')}</li>
                        </ul>
                    </PaddingLeft>
                    {renderHr1()}

                    <SousTitre>{t('sousTitreConversion')}</SousTitre>
                    <Text>{t('conversionTauxFixe')}</Text>
                    {renderHr1()}

                    {/* TAUX VARIABLE */}
                    <Titre>{t('titreTauxVariable')}</Titre>
                    <Hr />

                    <SousTitre>{t('sousTitrePaiement')}</SousTitre>
                    <Text>
                        <div ref={descriptionTauxVariableRef}>
                            {t('descriptionPaiementTauxVariable')}
                            <FootnoteRef
                                target={'footnote_typedetaux_note_1'}
                                id={'footnote_return_typedetaux_note_1'}
                            >
                                1
                            </FootnoteRef>
                        </div>
                    </Text>
                    {renderHr1()}

                    <SousTitre>{t('sousTitreTaux')}</SousTitre>
                    <Text>{t('descriptionTauxTauxVariable')}</Text>
                    {renderHr1()}

                    <SousTitre>{t('sousTitreHistoriqueTaux')}</SousTitre>
                    <Text>{t('descriptionHistoriqueTauxVariable')}</Text>
                    {renderHr1()}

                    <SousTitre>{t('sousTitrePourquoi')}</SousTitre>
                    <PaddingLeft>
                        <ul>
                            <li> {t('descriptionPourquoiTauxVariable_1')}</li>
                            <li> {t('descriptionPourquoiTauxVariable_2')}</li>
                            <li> {t('descriptionPourquoiTauxVariable_3')}</li>
                        </ul>
                    </PaddingLeft>
                    {renderHr1()}

                    <SousTitre>{t('sousTitreConversion')}</SousTitre>
                    <Text>{t('conversionTauxVariable')}</Text>
                    {renderHr1()}

                    {/* TAUX RÉVISABLE */}
                    <Titre>{t('titreTauxRevisable')}</Titre>
                    <Hr />

                    <SousTitre>{t('sousTitrePaiement')}</SousTitre>
                    <Text>{t('descriptionPaiementTauxRevisable')}</Text>
                    {renderHr1()}

                    <SousTitre>{t('sousTitreTaux')}</SousTitre>
                    <Text>{t('descriptionTauxTauxRevisable')}</Text>
                    {renderHr1()}

                    <SousTitre>{t('sousTitreHistoriqueTaux')}</SousTitre>
                    <Text>{t('descriptionHistoriqueTauxRevisable')}</Text>
                    {renderHr1()}

                    <SousTitre>{t('sousTitrePourquoi')}</SousTitre>
                    <PaddingLeft>
                        <ul>
                            <li> {t('descriptionPourquoiTauxRevisable_1')}</li>
                            <li> {t('descriptionPourquoiTauxRevisable_2')}</li>
                        </ul>
                    </PaddingLeft>
                    {renderHr1()}

                    <SousTitre>{t('sousTitreConversion')}</SousTitre>
                    <Text>{t('conversionTauxRevisable')}</Text>
                    {renderHr1()}

                    <FootnoteGroup
                        footnoteGroupTitle={t('sousTitreNotes')}
                        open={true}
                        titleLevel="2"
                    >
                        <div slot={FootnoteGoupListType.ORDERED_LIST}>
                            <Footnote id={'footnote_typedetaux_note_1'}>
                                <Typography
                                    variant={Variant.p}
                                    article={Article.a4}
                                >
                                    {t('note_1')}
                                    <Typography
                                        tabIndex={0}
                                        onKeyPress={handleKeyPress}
                                        variant={Variant.a}
                                        article={Article.a4}
                                        className={classes.lienNote}
                                        onClick={
                                            scrollToDescriptionTauxVariable
                                        }
                                    >
                                        [{t('retour')}]
                                    </Typography>
                                </Typography>
                            </Footnote>
                        </div>
                    </FootnoteGroup>
                </Lightbox>
            )}
        </>
    );
};
