import React, { useEffect, useState } from 'react';
import { HeadingProvider } from '../../context';
import { ContainerMui, Grid, Headings } from '../../styles';
import { Navigation } from './Deeplink.navigation';
import { useDispatch } from 'react-redux';
import {
    hideLoading,
    sendEvent,
    EVENT_CATEGORIES,
    EVENT_ACTIONS,
    EVENT_LABELS
} from '../../features';
import { Box, useMediaQuery } from '@material-ui/core';
import { useObtenirDemandesApi } from '../../hooks';
import { DeeplinkSansPret } from './component/deeplinkSansPret/DeeplinkSansPret';
import { DeeplinkAvecPret } from './component/deeplinkAvecPret/DeeplinkAvecPret';
import { DeeplinkParlerConseiller } from './component/deeplinkParlerConseiller/DeeplinkParlerConseiller';
import { ContexteAppel, Logger } from '../../utils';
import { isEnvNonProd } from '../../config';
import { injecterClavardage } from '../../components';

const headingMapping = {
    h2: Headings.h3
};

export const DeeplinkConnected: React.FC<unknown> = () => {
    const isMobile = useMediaQuery('(max-width:640px)');
    const dispatch = useDispatch();
    const [isLoadingDone, setIsLoadingDone] = useState<boolean>(false);

    // Hooks
    const { data: infoDemandes } = useObtenirDemandesApi();

    const trackAnalytic = () => {
        dispatch(
            sendEvent({
                category: EVENT_CATEGORIES.RHN,
                action: EVENT_ACTIONS.CLIC,
                label:
                    EVENT_LABELS.CLICK2CALL_SPECIALISTE +
                    ContexteAppel.PAGE_INTERMEDIAIRE_DEEPLINK
            })
        );
    };

    // Deep link Response
    useEffect(() => {
        if (infoDemandes) {
            setIsLoadingDone(true);
            dispatch(hideLoading());
            // On active le clavardage pour le "deepLink" si on est pas sur l'app mobile
            if (infoDemandes?.mobileApp === false) {
                Logger.log('Activation du clavardage - DeepLink');
                // Injecter le clavardage ainsi que le token
                injecterClavardage(!isEnvNonProd());
            }
        }
    }, [infoDemandes]);

    return (
        <>
            {isLoadingDone && (
                <HeadingProvider value={headingMapping}>
                    <Box
                        marginTop="1.5rem"
                        marginBottom={isMobile ? '0rem' : '0.75rem'}
                    >
                        <Grid>
                            <ContainerMui hidePadding>
                                {infoDemandes.demandes.length > 0 ? (
                                    <DeeplinkAvecPret
                                        infoDemandes={infoDemandes}
                                    />
                                ) : (
                                    <DeeplinkSansPret
                                        trackAnalytic={trackAnalytic}
                                    />
                                )}
                            </ContainerMui>
                        </Grid>
                    </Box>
                    <Navigation />
                    {infoDemandes.demandes.length > 0 && (
                        <DeeplinkParlerConseiller
                            trackAnalytic={trackAnalytic}
                        />
                    )}
                </HeadingProvider>
            )}
        </>
    );
};
