import { Box } from '@material-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';

import {
    FootnoteGroup,
    Footnote,
    FootnoteGoupListType,
    Grid,
    GridCol
} from '../../styles';

export const NoteLegaleEveilAssurance: React.FC<unknown> = () => {
    const [t] = useTranslation(['NoteLegaleEveilAssurance']);

    return (
        <Box mt={1} marginBottom="-0.5rem" marginTop="-2rem">
            <Grid>
                <GridCol col="12">
                    <FootnoteGroup
                        footnoteGroupTitle={t('footnoteGroupTitle')}
                        titleLevel="2"
                        open={true}
                    >
                        <div slot={FootnoteGoupListType.LIST}>
                            <Footnote
                                id="footnote_admissible_invalidite"
                                returnId="footnote_return_footnote_admissible_invalidite"
                            >
                                {/* le 1. est un hack en attendant qu'on puisse update dsd */}
                                {'1. ' +
                                    t('noteLegaleTexteAdmissibleInvalidite')}
                            </Footnote>
                        </div>
                    </FootnoteGroup>
                </GridCol>
            </Grid>
        </Box>
    );
};
