import React, { useState, useEffect } from 'react';
import { ProgressBar } from './ProgressBar';

import { useRenouvellementApi, useRoutes, useSessionApi } from '../../hooks';

interface ProgressBarProps {
    subtitle?: string;
}

export const ProgressBarConnected: React.FC<ProgressBarProps> = ({
    subtitle = null
}) => {
    const { getRoute } = useRoutes();
    const routeCourante = getRoute()?.route;

    // Hooks
    const { data: session } = useSessionApi();
    const { data: renouvellement } = useRenouvellementApi(!!session);

    // States
    const [isMartineParcours, setMartineParcours] = useState(false);
    const [isAdmissibleAssurance, setIsAdmissibleAssurance] = useState(false);
    const [isAjoutAssurance, setIsAjoutAssurance] = useState(false);

    useEffect(() => {
        if (session && renouvellement) {
            const { pretAdmissibleAssurance, emprunteurConnecte } =
                renouvellement;

            setMartineParcours(session?.isMartine);
            setIsAdmissibleAssurance(pretAdmissibleAssurance);
            setIsAjoutAssurance(emprunteurConnecte?.isAjoutAssurance);
        }
    }, [session, renouvellement]);

    return (
        <ProgressBar
            subtitle={subtitle}
            isMartineParcours={isMartineParcours}
            isAdmissibleAssurance={isAdmissibleAssurance}
            routeCourante={routeCourante}
            isAjoutAssurance={isAjoutAssurance}
        />
    );
};
