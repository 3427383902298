import React from 'react';
import { NoteLegaleOffre } from './NoteLegaleOffre';
import { Routes } from '../../hooks';

export const NoteLegaleOffreConnected: React.FC<unknown> = () => {
    const handleShowNoteLegale = () => {
        window.location.href = `#/${Routes.LEGAL}`;
    };

    return <NoteLegaleOffre onShowNoteLegale={handleShowNoteLegale} />;
};
