import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { createStyles, makeStyles, useMediaQuery } from '@material-ui/core';
import {
    ProgressBar,
    ResumeMontant,
    ResumePret,
    ResumeAssurance,
    ResumeModalites,
    ResumeDate,
    ClavardageContexte,
    setContexteClavardage
} from '../../components';
import {
    Grid,
    GridRow,
    GridCol,
    Typography,
    Variant,
    Police,
    Spacer,
    Link,
    EnumLinkType,
    DateText
} from '../../styles';
import {
    IRenouvellement,
    IResumeOffre,
    ISession,
    ParcoursEmprunteur,
    hasSoumissionAssurance
} from '../../hooks';
import {
    ContexteAppel,
    getParlerConseillerUrl,
    getFormattedDate
} from '../../utils';
import { hasOffreSimulee } from '../../hooks/api/demande/useObtenirResumeApi/Resume.utils';

const useStyles = makeStyles(() =>
    createStyles({
        link: {
            fontSize: '20px'
        }
    })
);

export interface IValidationProps {
    idDemande: number;
    resumeOffre: IResumeOffre;
    session: ISession;
    renouvellement: IRenouvellement;
    trackClick2CallAnalytic: () => void;
}

export const Validation: React.FC<IValidationProps> = ({
    idDemande,
    resumeOffre,
    session,
    renouvellement,
    trackClick2CallAnalytic
}) => {
    const [t] = useTranslation(['Validation']);
    const isWide = useMediaQuery('(min-width:900px)');
    const classes = useStyles();
    const isMartine: boolean =
        session?.typeParcoursEmprunteur ===
        ParcoursEmprunteur.COEMPRUNTEUR_MARTINE;
    const isSIMode = resumeOffre?.isAdmissibleSignatureIntegree;

    const formattedDateLimiteApprobation = new Date(
        getFormattedDate(
            resumeOffre?.offreChoisie?.dateLimiteApprobation || '',
            '/'
        )
    );

    useEffect(() => {
        setContexteClavardage(ClavardageContexte.VALIDATION);

        return () => {
            // Réinitialisation du contexte de clavardage
            setContexteClavardage(ClavardageContexte.DEFAULT);
        };
    }, []);

    const renderDescriptionMartine = () =>
        session?.approbationCoemprunteur?.delaiApprobation > 0 ? (
            <DateText
                translationKey="Validation:instructionsDescMartineDate"
                date={formattedDateLimiteApprobation}
            >
                {{
                    date: formattedDateLimiteApprobation
                }}
            </DateText>
        ) : (
            t('instructionDescMartineAujourdhui')
        );

    const renderDescriptionGuy = () => (
        <>
            {t('instructionsDesc1')}
            {t('instructionsDesc2_1')}
            <Link
                type={EnumLinkType.EXTERNAL}
                href={getParlerConseillerUrl(
                    ContexteAppel.PAGE_VALIDATION,
                    idDemande
                )}
                dataTestId="parler-conseiller-validation"
                ariaLabel={t('click2call_aria')}
                onClick={trackClick2CallAnalytic}
            >
                <span className={classes.link}>{t('click2call')}</span>
            </Link>
            {t('instructionsDesc2_2')}
        </>
    );

    return (
        <Grid>
            <ProgressBar />
            {/* Header */}
            <GridRow>
                <GridCol col="12">
                    <Typography
                        variant={Variant.h2}
                        dataTestId={'page_validation_titre'}
                    >
                        {t('titre')}
                    </Typography>
                    <Typography
                        variant={Variant.h5}
                        police={Police.normal}
                        dataTestId={'page_validation_instructions'}
                    >
                        {isMartine
                            ? renderDescriptionMartine()
                            : renderDescriptionGuy()}
                    </Typography>
                    <Spacer all={3} />
                    <ResumeMontant
                        resumeOffre={resumeOffre}
                        isWide={isWide}
                        spacerDistance={3}
                        isSIMode={isSIMode}
                        spacerDistanceMobile={1}
                    />
                </GridCol>
            </GridRow>

            {/* Validation */}
            <GridCol col="12">
                {/* Validation - type de pret */}
                <ResumePret
                    isEditButtonDisplayed={!isMartine}
                    isTauxAssuranceDisplayed={true}
                    isSoldeSansAssuranceDisplayed={false}
                    session={session}
                    resumeOffre={resumeOffre}
                    isSimplifie={false}
                />

                {/* Validation - assurance */}
                <ResumeAssurance
                    idDemande={idDemande}
                    resumeOffre={resumeOffre}
                    isNoAssuranceDisplayed={true}
                    isModalProtectionVisible={false}
                    isEditButtonDisplayed={
                        !isMartine &&
                        (!hasOffreSimulee(
                            resumeOffre?.offreChoisie?.typeOffreTarification
                        ) ||
                            hasSoumissionAssurance(
                                resumeOffre?.assurance?.codeAssurance
                            ))
                    }
                    isAssuranceAdmissible={
                        renouvellement?.pretAdmissibleAssurance
                    }
                    assures={renouvellement?.assures}
                />

                {/* Validation - modalites */}
                <ResumeModalites
                    isEditButtonDisplayed={!isMartine}
                    session={session}
                    resumeOffre={resumeOffre}
                />

                {/* Validation - date premier versement (optionel) */}
                <ResumeDate
                    isEditButtonDisplayed={!isMartine}
                    session={session}
                    resumeOffre={resumeOffre}
                />
            </GridCol>
        </Grid>
    );
};
