import { IDemande, IDemandes } from '../../..';
import { QUERY_PARAM_USER_MODE, URLToURLSearchParams } from '../../../../utils';
import { mockInstance, withDelay } from '../../../../api/axiosInstance.mock';

import { EMPRUNTEURS_ACTUEL_DEMANDES } from '../../apiEndPoints';
import { EmprunteurStatut } from '../../contexte/useRenouvellementApi/useRenouvellementApi';
import { StatutDemande } from '../../contexte/useSessionApi/useSessionApi';

export const demandesMockedData: Array<IDemande> = [
    {
        idDemande: 20777,
        statut: StatutDemande.ACCEPTEE,
        emprunteurStatut: EmprunteurStatut.CONFIRMEE,
        dateDeRappel: '20210129',
        solde: 345678.31,
        numeroEngagement: '815-70016-0177982-05-03'
    },
    {
        idDemande: 20778,
        statut: StatutDemande.ACCEPTEE,
        emprunteurStatut: EmprunteurStatut.CONFIRMEE,
        dateDeRappel: '20210128',
        solde: 501102.31,
        numeroEngagement: '815-70016-0177445-05-03'
    },
    {
        idDemande: 12345,
        statut: StatutDemande.INITIALE,
        emprunteurStatut: EmprunteurStatut.ACCUEIL,
        dateDeRappel: '20210128',
        solde: 112300.0,
        numeroEngagement: '815-00123-0058859-05-02'
    },
    {
        idDemande: 33579,
        statut: StatutDemande.EN_ATTENTE,
        emprunteurStatut: EmprunteurStatut.OFFRE_SELECTIONNEE,
        dateDeRappel: '20210205',
        solde: 230512.0,
        numeroEngagement: '815-20274-0661911-05-01'
    }
];

export const mocked2PretsData: IDemandes = {
    demandes: demandesMockedData,
    mobileApp: true
};

export const mocked0PretsData: IDemandes = {
    demandes: [],
    mobileApp: true
};

const defineMockData = (useMockParam: string): any => {
    if (!useMockParam) return mocked2PretsData;
    switch (useMockParam) {
        case 'deeplink0pret':
            return mocked0PretsData;
        default:
            return mocked2PretsData;
    }
};

export const useObtenirDemandesApiMock = (): any => {
    // var
    const query = URLToURLSearchParams();
    const useMockParam = query.get(QUERY_PARAM_USER_MODE);

    mockInstance
        .onGet(EMPRUNTEURS_ACTUEL_DEMANDES)
        .reply(withDelay([200, defineMockData(useMockParam)], 200));
};
