import { useMutation } from 'react-query';

import {
    axiosInstance,
    getDynamicHeaders,
    IMutationResult,
    reactQueryOptions
} from '../../../../api';
import { CODE_AVIS_END_POINT } from '../../apiEndPoints';
import { AxiosResponse } from 'axios';

export interface ICodeAvisParams {
    idDemande: number;
}

const getCodeAvis = async (
    data: ICodeAvisParams
): Promise<AxiosResponse<any>> => {
    const endPoint = CODE_AVIS_END_POINT.replace(
        /{idDemande}/g,
        String(data.idDemande)
    );

    const { data: response } = await axiosInstance.get(
        endPoint,
        getDynamicHeaders()
    );

    return response;
};

export const useCodeAvisApi = (): IMutationResult => {
    return useMutation(getCodeAvis, {
        ...reactQueryOptions()
    });
};
