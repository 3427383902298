import {
    Alert,
    AlertType,
    Article,
    ColorHex,
    DateText,
    GridCol,
    Police,
    Typography,
    Variant
} from '../../../../styles';
import {
    Box,
    createStyles,
    makeStyles,
    useMediaQuery
} from '@material-ui/core';
import {
    ClavardageContexte,
    ResumeMontant,
    setContexteClavardage
} from '../../../../components';
import {
    IResumeOffre,
    ParcoursEmprunteur,
    StatutDemande,
    emprunteur
} from '../../../../hooks';
import React, { useEffect } from 'react';

import { useTranslation } from 'react-i18next';

interface IConfirmationSignatureIntegreeProps {
    resumeOffre: IResumeOffre;
    typeParcours: ParcoursEmprunteur;
    isOntario: boolean;
    dateRappel: Date;
    dateAujourdhui: Date;
    dateLimite: Date;
    dateCibleRenouvellement: Date;
    emprunteurMartine: emprunteur;
    statutDemande: StatutDemande;
}

const useStyles = makeStyles(() =>
    createStyles({
        titreRhn: {
            fontSize: '16px !important',
            marginBottom: '0.5rem'
        },
        sousTitre: {
            fontSize: '18px !important'
        }
    })
);

export const ConfirmationSignatureIntegree: React.FC<
    IConfirmationSignatureIntegreeProps
> = ({
    resumeOffre,
    typeParcours,
    isOntario,
    dateRappel,
    dateAujourdhui,
    dateLimite,
    dateCibleRenouvellement,
    emprunteurMartine,
    statutDemande
}) => {
    const [t] = useTranslation(['Confirmation']);
    const isMobile = useMediaQuery('(max-width:640px)');
    const isEcranMedium = useMediaQuery('(max-width:1200px)');
    const classes = useStyles();

    useEffect(() => {
        setContexteClavardage(ClavardageContexte.CONFIRMATION);

        return () => {
            // Réinitialisation du contexte de clavardage
            setContexteClavardage(ClavardageContexte.DEFAULT);
        };
    }, []);

    const getTextDateLimite = () => {
        // Date rappel moins un jour parce que dans le backend si on met la date cible renouvellement = date rappel et ensuite date limite = date cible renouvellement - 1.
        // Donc, ça donne la date limite = date rappel - 1 donc pour voir si il sont égal il faut faire moins 1 jour sur la date de rappel sinon ils peuveunt jamais être égal
        // sauf si c'est la à la date d'aujourd'hui.
        const dateRappelMoins1Jour = new Date(dateRappel);
        dateRappelMoins1Jour.setDate(dateRappelMoins1Jour.getDate() - 1);
        if (dateLimite.getTime() < dateRappelMoins1Jour.getTime()) {
            if (dateLimite.getTime() === dateAujourdhui.getTime()) {
                return 'dateRappelPlusGrandDateLimiteEgaleDateAujourdhui';
            } else {
                return 'dateRappelPlusGrandDateLimite';
            }
        } else {
            if (dateRappel.getTime() === dateAujourdhui.getTime()) {
                return 'dateLimiteEgaleDateRappelDateAujourdhui';
            } else {
                return 'dateLimiteEgaleDateRappel';
            }
        }
    };

    const getDescriptionMargin = () => {
        if (isMobile) {
            return '1rem 1rem 1rem 1rem';
        } else if (isEcranMedium) {
            return '1rem 0.5rem 1rem 1.5rem';
        } else {
            return '1rem 0 1rem 1.5rem';
        }
    };

    const getAlerteDescription = () => {
        switch (typeParcours) {
            case ParcoursEmprunteur.UNIQUE:
                return t('alerteSIUnique');
            case ParcoursEmprunteur.COEMPRUNTEUR_GUY:
                return t('alerteSIGuy');
            case ParcoursEmprunteur.COEMPRUNTEUR_MARTINE:
                return (
                    <DateText
                        translationKey="Confirmation:alerteSIMartine"
                        date={dateCibleRenouvellement}
                    >
                        {{
                            date: dateCibleRenouvellement
                        }}
                    </DateText>
                );
        }
    };

    const isGuyAndNonAcceptee = (): boolean => {
        return (
            typeParcours === ParcoursEmprunteur.COEMPRUNTEUR_GUY &&
            statutDemande !== StatutDemande.ACCEPTEE
        );
    };

    return (
        <GridCol col="12" sm="8" md="6">
            <Box margin={getDescriptionMargin()}>
                <Typography
                    variant={Variant.h1}
                    police={Police.bold}
                    color={ColorHex.lightGraphite4}
                    className={classes.titreRhn}
                >
                    {t('titreRhn')}
                </Typography>
                <Typography
                    variant={Variant.h2}
                    dataTestId={'page_confirmation_titre'}
                >
                    {isGuyAndNonAcceptee() ? t('titreSIGuy') : t('titreSI')}
                </Typography>
                <Alert
                    isFullWidth
                    type={AlertType.confirmation}
                    dataTestId={'alerte_demande_enregistré'}
                    titleLevel={3}
                >
                    {getAlerteDescription()}
                </Alert>
                {isGuyAndNonAcceptee() && (
                    <Typography variant={Variant.p}>
                        {t('descriptionProchaineEtapeGuy')}
                    </Typography>
                )}
                {isGuyAndNonAcceptee() ? (
                    <>
                        <Typography
                            variant={Variant.h3}
                            police={Police.bold}
                            className={classes.sousTitre}
                            marginBottom="0.25rem"
                        >
                            {t('DescriptionTitreGuy')}
                        </Typography>
                        <Typography
                            variant={Variant.p}
                            article={Article.a3}
                            marginBottom="1.5rem"
                        >
                            <DateText
                                date={dateLimite}
                                translationKey={
                                    'Confirmation:' + getTextDateLimite()
                                }
                            >
                                {{
                                    martineName:
                                        emprunteurMartine?.prenom +
                                        ' ' +
                                        emprunteurMartine?.nom,
                                    date: dateLimite
                                }}
                            </DateText>
                        </Typography>
                    </>
                ) : (
                    <>
                        <Typography
                            variant={Variant.h3}
                            police={Police.bold}
                            className={classes.sousTitre}
                            marginBottom="0.25rem"
                        >
                            {t('rappelTitreSI')}
                        </Typography>
                        <Typography
                            variant={Variant.p}
                            article={Article.a3}
                            marginBottom="1.5rem"
                        >
                            {t('rappelTexteSI')}
                        </Typography>
                        <ResumeMontant
                            resumeOffre={resumeOffre}
                            isSIMode={true}
                            spacerDistance={isOntario ? 6 : 3}
                            spacerDistanceMobile={isOntario ? 6 : 2}
                        />
                    </>
                )}
                {isOntario && (
                    <>
                        <Typography
                            variant={Variant.h3}
                            police={Police.bold}
                            className={classes.sousTitre}
                            marginBottom="0.25rem"
                        >
                            {t('DescriptionTitreOntario')}
                        </Typography>
                        <Typography
                            variant={Variant.p}
                            article={Article.a3}
                            marginBottom="1.5rem"
                        >
                            {t('DescriptionOntario')}
                        </Typography>
                    </>
                )}
            </Box>
        </GridCol>
    );
};
