import React, { useEffect } from 'react';

import { Navigation } from './Legal.navigation';
import { Legal } from './Legal';
import { useSessionApi, useEnteteApi } from '../../hooks';
import { HeadingProvider } from '../../context';
import { Headings } from '../../styles';
import { useDispatch } from 'react-redux';
import { hideLoading } from '../../features';

const headingMapping = {
    h1: Headings.h3
};

export const LegalConnected: React.FC<unknown> = () => {
    const { data: session } = useSessionApi();
    const idDemande = session?.contextePret?.idDemande;
    const { data: entete } = useEnteteApi(idDemande);
    const isQuebec = entete?.numeroInstitution === '815';
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(hideLoading());
    }, []);

    return (
        <HeadingProvider value={headingMapping}>
            <Legal isQuebec={isQuebec} />
            <Navigation></Navigation>
        </HeadingProvider>
    );
};
