import React, { useRef, useEffect } from 'react';
import { ToolTipPlacement, Tooltip } from '../tooltip/Tooltip';

export interface IOptions {
    value: string;
    label: string;
    isDisabled?: boolean;
    isSelected?: boolean;
}

export type ISelectOptions = IOptions[];

interface ISelectProps {
    dataTestId?: string;
    isDark?: boolean;
    isDisabled?: boolean;
    identifier?: string;
    isRequired?: boolean;
    value: string;
    defaultOptionLabel?: string;
    describedBy?: string;
    hideRequiredLabelling?: boolean;
    hasNoDefaultOption?: boolean;
    quietValidate?: boolean;
    error?: string;
    hint?: string;
    label?: string;
    toolTipLabel?: string;
    toolTipDescription?: string;
    toolTipPlacement?: ToolTipPlacement;
    isError?: boolean;
    fullWidth?: boolean;
    onChange?: (value: string) => void;
    validate?: () => void;
    onToolTipClick?: () => void;
    options: ISelectOptions;
}

export const Select: React.FC<ISelectProps> = ({
    dataTestId,
    isDark,
    isDisabled = false,
    identifier,
    isRequired = false,
    value,
    defaultOptionLabel,
    describedBy,
    hideRequiredLabelling = false,
    hasNoDefaultOption = false,
    quietValidate,
    error,
    hint,
    label,
    toolTipLabel,
    toolTipDescription,
    toolTipPlacement,
    fullWidth = false,
    onChange,
    validate,
    onToolTipClick,
    options
}) => {
    const ref = useRef(null);

    const handleChange = (e: any) => {
        onChange(e?.detail?.value);
    };

    useEffect(() => {
        ref.current.addEventListener('dsdSelectChange', (e: any) => {
            if (onChange) handleChange(e);
        });

        ref.current.addEventListener('validate', () => {
            if (validate) validate();
        });
    }, []);

    return (
        <div data-testid={dataTestId}>
            {/* @ts-ignore */}
            <dsd-select
                ref={ref}
                dark={isDark}
                identifier={identifier}
                disabled={isDisabled}
                name={identifier}
                required={isRequired}
                value={value}
                default-option-label={defaultOptionLabel}
                describe-by={describedBy}
                hide-required-labelling={hideRequiredLabelling}
                no-default-option={hasNoDefaultOption}
                quiet-validate={quietValidate}
                style={fullWidth ? { minWidth: '100%' } : {}}
                label={label}
            >
                <span slot="label">{label}</span>
                {toolTipLabel && (
                    <Tooltip
                        label={toolTipLabel}
                        description={toolTipDescription}
                        placement={toolTipPlacement}
                        onClick={onToolTipClick}
                    />
                )}
                {hint && <span slot="hint">{hint}</span>}
                {error && <span slot="error">{error}</span>}
                {options && (
                    <span slot="options" data-testid={identifier + '_options'}>
                        {options.map((option, index) => (
                            <option
                                key={index}
                                value={option.value}
                                disabled={option.isDisabled}
                                data-testid={
                                    identifier + '_option_' + option.value
                                }
                            >
                                {option.label}
                            </option>
                        ))}
                    </span>
                )}
                {/* @ts-ignore */}
            </dsd-select>
        </div>
    );
};
