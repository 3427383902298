import React from 'react';
import { Box, CardMedia, createStyles, makeStyles } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import {
    Grid,
    GridRow,
    GridCol,
    Typography,
    Variant,
    ContainerMui,
    Police
} from '../../../styles';
import { AppelerCaisse } from '../../../components';

const useStyles = makeStyles(() =>
    createStyles({
        imageMobile: {
            borderRadius: '1rem 1rem 0 0',
            height: '100%',
            paddingBottom: '60%',
            backgroundPosition: 'center',
            position: 'relative',
            display: 'flex',
            justifyContent: 'center',
            marginTop: '-1rem'
        },
        imageDesktop: {
            borderRadius: '0 1rem 1rem 0',
            height: '100%',
            position: 'relative',
            backgroundPosition: '50% 12%',
            display: 'flex',
            justifyContent: 'center'
        }
    })
);

export const ErreurFonctionnelleTarif: React.FC<unknown> = () => {
    const [t] = useTranslation(['ErreurFonctionnelle']);
    const classes = useStyles();

    return (
        <Grid>
            <ContainerMui hidePadding noSpacer>
                {/* image pour mobile */}
                <GridCol col="12" className="sm:dsd-d-none">
                    <CardMedia
                        className={classes.imageMobile}
                        image={'/images/page_deeplink_mobile.jpg'}
                    />
                </GridCol>
                <GridRow>
                    {/* content */}
                    <GridCol col="12" md="7" sm="7">
                        <Box padding="1rem 1rem 0rem 1.5rem">
                            <Typography
                                variant={Variant.h1}
                                police={Police.bold}
                                marginBottom={'1rem'}
                            >
                                {t('erreurTarifTitre')}
                            </Typography>
                            <Typography
                                variant={Variant.p}
                                marginBottom={'1rem'}
                            >
                                {t('erreurTarifDescription')}
                            </Typography>
                            <AppelerCaisse />
                        </Box>
                    </GridCol>

                    {/* image desktop */}
                    <GridCol
                        className="dsd-d-none sm:dsd-d-block"
                        md="5"
                        sm="5"
                    >
                        <CardMedia
                            className={classes.imageDesktop}
                            image={'/images/page_deeplink_desktop.jpg'}
                        />
                    </GridCol>
                </GridRow>
            </ContainerMui>
        </Grid>
    );
};
