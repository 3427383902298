import {
    AnnuleeConseiller,
    AnnuleeSysteme,
    Confirmation,
    Deeplink,
    Erreur,
    ErreurFonctionnelle,
    EveilAssurance,
    Home,
    InformationsSignature,
    InteretRetard,
    Legal,
    Modalites,
    ModalitesDatePaiement,
    Offres,
    Signature,
    Validation
} from './views';
import {
    Box,
    Theme,
    ThemeProvider,
    createStyles,
    makeStyles,
    useTheme
} from '@material-ui/core';
import {
    CodeAvis,
    DebugBanner,
    ErrorBanner,
    Header,
    NoteLegaleEveilAssurance,
    NoteLegaleOffre,
    SupportBar,
    injecterClavardage,
    setJetonClavardage
} from './components';
import {
    ContexteAppel,
    DOMAIN_DESJARDINS_COOKIE,
    JWT_COOKIE_CONFIG_NAME,
    Logger,
    MOBILE_WIDTH,
    QUERY_PARAM_DEEP_LINK,
    QUERY_PARAM_USE_AUTH0,
    ScrollToTop,
    TNR_COOKIE,
    URLToURLSearchParams,
    deleteCookie,
    getLangue,
    getPretIdDemande,
    isMobileAppFromCookies,
    setPostAchatJWT,
    stringToBoolean
} from './utils';
import { EnvConfig, EnvType, getEnvConfig } from './config';
import { ErrorBoundary, FallbackProps } from 'react-error-boundary';
import { Footer, GridHelper, LoadingScreen } from './styles';
import {
    PersonneConnectee,
    Routes,
    getActualRoute,
    isRouteRequireLeaveConfirmation,
    useAuth0,
    useKeepAliveApi,
    useMock,
    useRenouvellementApi,
    useRoutes,
    useSessionApi,
    useToggleApi
} from './hooks';
import { Provider, useDispatch, useSelector } from 'react-redux';
import React, { useEffect, useState } from 'react';
import {
    Route,
    HashRouter as Router,
    Switch,
    useHistory
} from 'react-router-dom';
import {
    hideLoading,
    initCybermetrie,
    initGAServices,
    setIdDemande,
    setIsAppCybermetrie
} from './features';
import { isMockEnabled, queryClient, shouldUseMock } from './api';

import { AxiosError } from 'axios';
import { CODE_ERREUR_FONCTIONNELLE_RHN } from './views/erreurFonctionnelle/ErreurFonctionnelle.connected';
import { CODE_ERREUR_TECHNIQUE_RHN } from './views/erreur/Erreur.connected';
import { QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import i18n from 'i18next';
import { isRouteRequireStatutCheck } from './hooks/navigation/useRoutes/useRoutes';
import moment from 'moment';
import store from './store';
import theme from './theme/theme.jsx';
import { useHotkeys } from 'react-hotkeys-hook';
import { useTranslation } from 'react-i18next';

const removeFocusForButton = () => {
    document.addEventListener('click', function () {
        if (
            document.activeElement instanceof HTMLElement &&
            document.activeElement.toString() == '[object HTMLButtonElement]'
        ) {
            document.activeElement.blur();
        }
    });
};

const getContainerStyles = (
    theme: Theme,
    mobileDownMarginTop = '0',
    mobileUpMarginTop = '0',
    mobileDownMarginBottom = 'auto',
    mobileUpMarginBottom = 'auto'
) => ({
    [theme.breakpoints.down(MOBILE_WIDTH)]: {
        margin: `${mobileDownMarginTop} 0 ${mobileDownMarginBottom} 0`
    },
    [theme.breakpoints.up(MOBILE_WIDTH)]: {
        margin: `${mobileUpMarginTop} 0 ${mobileUpMarginBottom} 0`
    }
});

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        autoContainer: getContainerStyles(theme),
        viewWithLegalContainer: getContainerStyles(
            theme,
            '1rem',
            '2rem',
            '0',
            '0'
        ),
        viewContainer: getContainerStyles(theme, '1rem', '2rem', '1rem', '1rem')
    })
);

const RenderFooter = () => {
    // Oblige d'utilise une variable plutot que le i18n direct
    // Sinon la lib oel ne prend pas en compte
    const langue = i18n.language;
    return <>{langue && <Footer langue={langue}></Footer>}</>;
};

const RenderHeader = () => {
    return (
        <>
            {i18n.language && (
                <Header
                    langue={i18n.language}
                    isConfirmationRequired={isRouteRequireLeaveConfirmation()}
                    isStatutCheckRequired={isRouteRequireStatutCheck()}
                />
            )}
        </>
    );
};

const RenderErreur = (info: FallbackProps) => {
    let axiosError: AxiosError<any>;
    let isTechnicalError = false;
    let codeErreur: number;
    let codeErreurRHN: string;
    let messageErreur: string;
    const history = useHistory();
    const classes = useStyles();
    const theme = useTheme();
    const langue = getLangue();
    // Recuperation depuis le store pour savoir si les appels ont reussis
    const idDemande = useSelector(
        (state: any) => state.renouvellement.idDemande
    );

    // Try to retrieve error and cast it to Axios type
    try {
        if (info?.error) {
            axiosError = info?.error as AxiosError<any>;
            codeErreur = axiosError?.response?.status;

            // retrieve error & try to find error type
            const errors = axiosError?.response?.data?.errors;
            codeErreurRHN =
                errors && errors.length > 0 && errors[0]?.code
                    ? errors[0]?.code
                    : null;
            messageErreur =
                errors && errors.length > 0 && errors[0]?.message
                    ? errors[0]?.message
                    : null;
            isTechnicalError =
                codeErreurRHN === CODE_ERREUR_TECHNIQUE_RHN.ERREUR_TECHNIQUE;
        }
    } catch (error) {
        axiosError = null;
    }

    history.push(`./${Routes.ERREUR}`);

    useEffect(() => {
        i18n.changeLanguage(langue);
        moment.locale(langue);
    }, []);

    return (
        <Provider store={store}>
            <Box
                display="flex"
                flexDirection="column"
                bgcolor={theme.palette.grey[200]}
                minHeight="100vh"
            >
                {idDemande && <RenderHeader />}
                {/* If error is one of axios -> display error banner */}
                {axiosError && <ErrorBanner axiosError={axiosError} />}
                <div className={classes.autoContainer}>
                    <div className={classes.viewContainer}>
                        {/* Si on a pu charger la session alors erreur fonctionnelle. Sinon technique */}
                        {isTechnicalError ? (
                            <Erreur codeErreur={codeErreur} />
                        ) : (
                            <ErreurFonctionnelle
                                status={codeErreur}
                                codeErreurRHN={codeErreurRHN}
                                messageErreur={messageErreur}
                            />
                        )}
                    </div>
                </div>
                {/* Afficher le bandeau de support pour page fonctionnelle sauf si l'erreur est dans le calcul offre */}
                {idDemande &&
                    codeErreurRHN !==
                        CODE_ERREUR_FONCTIONNELLE_RHN.ERREUR_CALCUL_OFFRE && (
                        <SupportBar
                            contexte={ContexteAppel.PAGE_ERREUR}
                            idDemande={idDemande}
                        />
                    )}
                <RenderFooter />
            </Box>
        </Provider>
    );
};

const RenderViewSelected = (route: string, isConseiller: boolean) => {
    switch (route) {
        case Routes.HOME:
            return <Home />;
        case Routes.OFFRES:
            return <Offres />;
        case Routes.MODALITES:
            return <Modalites />;
        case Routes.MODALITES_DATE_PAIEMENT:
            return <ModalitesDatePaiement />;
        case Routes.INFORMATIONS_SIGNATURE:
            return <InformationsSignature />;
        case Routes.VALIDATION:
            return <Validation />;
        case Routes.CONFIRMATION:
            return <Confirmation />;
        case Routes.ERREUR:
            return <ErreurFonctionnelle isConseiller={isConseiller} />;
        case Routes.ANNULEE_SYSTEME:
            return <AnnuleeSysteme />;
        case Routes.ANNULEE_CONSEILLER:
            return <AnnuleeConseiller />;
        case Routes.LEGAL:
            return <Legal />;
        case Routes.EVEIL_ASSURANCE:
            return <EveilAssurance />;
        case Routes.INTERET_RETARD:
            return <InteretRetard />;
        case Routes.SIGNATURE:
            return <Signature />;
        default:
            return <></>;
    }
};

const RenderComponent = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const { getInitialRoute, config, getRoute } = useRoutes();

    // Apis
    const { data: session } = useSessionApi();
    const { data: renouvellement } = useRenouvellementApi(
        !!session && !session?.isFastLoading
    );

    const [isRouteLoaded, setIsRouteLoaded] = useState(false);

    const idDemandeStored = useSelector(
        (state: any) => state.renouvellement.idDemande
    );

    useEffect(() => {
        if (isRouteLoaded == true) {
            dispatch(setIdDemande(session?.contextePret?.idDemande));
        }
    }, [isRouteLoaded]);

    // Check if idDemandeStored exists. IF not -> reloaded page protection -> nav to PageInitiale
    useEffect(() => {
        if (session && config && (session?.isFastLoading || renouvellement)) {
            if (idDemandeStored === null) {
                // Si on est en fast loading -> On prend la page initiale de session
                // Sinon on attend les regles d'affaire et on prend la page initiale de renouvellement
                const route = getInitialRoute(
                    session?.isFastLoading
                        ? session?.pageInitiale
                        : renouvellement?.pageInitiale,
                    renouvellement &&
                        renouvellement?.montantTotalInteretRetard > 0,
                    renouvellement?.pretAdmissibleAssurance === true
                );

                history.push(`./${route}`);
                setIsRouteLoaded(true);
            }
        }
    }, [session, config, renouvellement]);

    // Render view after idDemandeStored is defined
    return RenderViewSelected(
        idDemandeStored ? getRoute()?.route : null,
        session?.isConseiller
    );
};

const RenderSupportBar = () => {
    const { getRoute } = useRoutes();

    const { data: session } = useSessionApi();
    const idDemande = session?.contextePret?.idDemande;

    let contexte = null;

    if (session?.personneConnectee !== PersonneConnectee.MEMBRE) {
        return null;
    }

    switch (getRoute()?.route) {
        case Routes.HOME:
            contexte = ContexteAppel.PAGE_ACCUEIL;
            break;
        case Routes.OFFRES:
            contexte = ContexteAppel.PAGE_OFFRE;
            break;
        case Routes.MODALITES:
            contexte = ContexteAppel.PAGE_MODALITES;
            break;
        case Routes.MODALITES_DATE_PAIEMENT:
            contexte = ContexteAppel.PAGE_DATE_PAIEMENT;
            break;
        case Routes.INFORMATIONS_SIGNATURE:
            contexte = ContexteAppel.PAGE_INFO_SIGNATURE;
            break;
        case Routes.VALIDATION:
            contexte = ContexteAppel.PAGE_VALIDATION;
            break;
        case Routes.CONFIRMATION:
            contexte = ContexteAppel.PAGE_CONFIRMATION;
            break;
        case Routes.SIGNATURE:
            contexte = ContexteAppel.PAGE_SIGNATURE;
            break;
        case Routes.EVEIL_ASSURANCE:
            contexte = ContexteAppel.PAGE_EVEIL_ASSURANCE;
            break;
        case Routes.ANNULEE_SYSTEME:
            contexte = ContexteAppel.PAGE_ERREUR;
            break;
        case Routes.ANNULEE_CONSEILLER:
            contexte = ContexteAppel.PAGE_ERREUR;
            break;
        case Routes.ERREUR:
            contexte = ContexteAppel.PAGE_ERREUR;
            break;
    }

    return contexte && <SupportBar contexte={contexte} idDemande={idDemande} />;
};

const RenderNoteLegal = () => {
    const { getRoute } = useRoutes();

    switch (getRoute()?.route) {
        case Routes.OFFRES:
            return <NoteLegaleOffre />;
        case Routes.EVEIL_ASSURANCE:
            return <NoteLegaleEveilAssurance />;
        default:
            return <></>;
    }
};

const RenderRoutes: React.FC<{
    isConseiller: boolean;
    isDeepLink: boolean;
}> = ({ isConseiller, isDeepLink }) => {
    // Vue conseiller lecture seule
    if (isConseiller) {
        return <ErreurFonctionnelle isConseiller={true} />;
    }
    // Vue deep link
    if (isDeepLink) {
        return <Deeplink />;
    }
    // Routage classique une fois dans le parcours
    return (
        <Switch>
            <Route path={'/:path'}>
                <RenderComponent />
            </Route>
            <Route path={'/'}>
                <RenderComponent />
            </Route>
            <Route path="*">
                <div>no match</div>
            </Route>
        </Switch>
    );
};

// Dynamic Content: Views etc
const View: React.FC<{
    envConfig: EnvConfig;
    langue: string;
    isAuth0Enabled: boolean;
    isAuthenticated: boolean;
    isNonProdEnv: boolean;
    isDeepLink: boolean;
    isMobileApp: boolean;
}> = ({
    envConfig,
    langue,
    isAuth0Enabled,
    isAuthenticated,
    isNonProdEnv,
    isDeepLink,
    isMobileApp
}) => {
    // APIs
    const { data: session } = useSessionApi(isDeepLink === false);
    const { data: renouvellement } = useRenouvellementApi(
        session?.isConseiller === false
    );

    // Prerequisite data loading (session & renouvellement)
    const [isPrerequisiteDataLoading, setPrerequisiteDataLoading] =
        useState(true);

    // Store
    const isCybermetrieEnabled = useSelector(
        (state: any) => state.cybermetrie.isEnabled
    );

    removeFocusForButton();

    // hooks
    const [t] = useTranslation(['General']);
    const dispatch = useDispatch();
    const classes = useStyles();

    // Const
    const platform = isMobileApp ? 'Mobile' : 'Web';
    const orientation =
        window.innerHeight > window.innerWidth ? 'Portrait' : 'Landscape';
    const browser = navigator.userAgent;
    // Set title
    document.title = t('titrePagePrincipal');

    const initCyberAndFinishAppLoading = () => {
        const {
            typeParcoursEmprunteur,
            personneConnectee,
            contextePret: { idDemande }
        } = session;

        // Dispatch init cyber metrie
        dispatch(
            initCybermetrie({
                personneConnectee,
                idDemande,
                langue,
                platform,
                orientation,
                browser,
                parcoursEmprunteur: typeParcoursEmprunteur
            })
        );

        // Prerequisite data loaded
        setPrerequisiteDataLoading(false);
    };

    useEffect(() => {
        // Bascule rapide -> Si Guy ou Unique ET page accueil
        // Sinon chargement et verifs normales
        if (session && session?.isFastLoading === true) {
            // Chargement rapide
            initCyberAndFinishAppLoading();
        } else if (session && renouvellement) {
            // Si on recoit un redirectUrl et qu'on est un membre -> Alors on suit l'url ( Assurance )
            if (
                renouvellement?.redirectUrl &&
                session?.personneConnectee === PersonneConnectee.MEMBRE
            ) {
                window.location.href = renouvellement?.redirectUrl;
                return;
            }
            // Sinon chargement normal
            initCyberAndFinishAppLoading();
        }
    }, [session, renouvellement]);

    useEffect(() => {
        if (isCybermetrieEnabled && session) {
            dispatch(initGAServices());
            // cyber is mobile app
            setIsAppCybermetrie(isMobileApp);
        }
    }, [session, isCybermetrieEnabled]);

    useEffect(() => {
        // arreter le chargement et le spinner si c'est un conseiller
        if (session?.isConseiller) {
            setPrerequisiteDataLoading(false);
            dispatch(hideLoading());
        }
    }, [session]);

    useEffect(() => {
        // Charger la vue deepLink avec le spinner qui partira dans la vue une fois l'appel fini
        if (isDeepLink) {
            setPrerequisiteDataLoading(false);
        }
    }, [isDeepLink]);

    useEffect(() => {
        Logger.log(t('appStarted'));
    }, []);

    const withLegalRoutes = [Routes.OFFRES.toString()];
    const viewContainerClass = withLegalRoutes.includes(getActualRoute())
        ? classes.viewWithLegalContainer
        : classes.viewContainer;

    return (
        <>
            {/* Global loading is managed by views through redux store */}
            {!isPrerequisiteDataLoading && (
                <>
                    {isNonProdEnv && (
                        <DebugBanner
                            skipBasculeInfos={
                                isDeepLink || session?.isConseiller
                            }
                            envConfig={envConfig}
                            isAuth0Enabled={isAuth0Enabled}
                            isAuthenticated={isAuthenticated}
                            session={session}
                            renouvellement={renouvellement}
                        />
                    )}
                    {!session?.isConseiller &&
                        !isDeepLink &&
                        renouvellement &&
                        renouvellement?.montantTotalInteretRetard === 0 && (
                            <CodeAvis />
                        )}
                    <div className={viewContainerClass}>
                        <RenderRoutes
                            isConseiller={session?.isConseiller}
                            isDeepLink={isDeepLink}
                        />
                    </div>
                </>
            )}
        </>
    );
};

// Auth0 configuration
// 1. Verifier si on a besoin d'utiliser Auth0.
// 2. Initialiser auth0 avec le tokenIssuer et l'environement
const Auth0 = ({ langue, deepLinkMode, mockEnabled }) => {
    const classes = useStyles();

    // const
    const query = URLToURLSearchParams();
    const isMobileApp = isMobileAppFromCookies();

    // Store
    const globalLoading = useSelector((state: any) => state.loading.show);

    // state
    const [token, setToken] = useState(null);
    const [isReadyForAPI, setIsReadyForAPI] = useState<boolean>(false);
    const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false);
    const [shouldUseAuth0, setShouldUseAuth0] = useState<boolean | null>(null);

    // hooks
    const auth0 = useAuth0();
    const envConfig = getEnvConfig();
    const { data: toggle } = useToggleApi(isAuthenticated === true);

    useEffect(() => {
        if (shouldUseAuth0 === true) {
            // Si besoin, nous allons initialiser auth0
            auth0.initialiser(envConfig.type);
        } else if (mockEnabled === true && shouldUseAuth0 === false) {
            // Si on a les mock et pas de Auth0 -> on met un fakeToken pour le clavardage
            setToken('fakeToken');
        }
    }, [shouldUseAuth0]);

    useEffect(() => {
        if (envConfig.type === EnvType.PROD) {
            // PROD: enable
            return setShouldUseAuth0(true);
        }

        // NonProd Logic
        if (envConfig.type === EnvType.LOCAL) {
            // Si Env est Local, no auth SAUF si on enabler le https
            return setShouldUseAuth0(false);
        }

        // Finally, we check for query params from Simulateur (useAuth0)
        const param = query.get(QUERY_PARAM_USE_AUTH0);

        if (param) {
            return setShouldUseAuth0(
                stringToBoolean(query.get(QUERY_PARAM_USE_AUTH0))
            );
        } else {
            // no query params. We return true by default
            return setShouldUseAuth0(true);
        }
    }, []);

    // Get token when everything is ready with Auth0
    useEffect(() => {
        if (auth0.isAuthenticated && shouldUseAuth0 && !token) {
            const getToken = async () => {
                const token = await auth0.getToken();
                if (token) {
                    Logger.log(token);
                    // Save Token to cookies if we need PISE
                    if (envConfig.usePise) {
                        setPostAchatJWT(token, envConfig.isProd);
                    }
                    setToken(token);
                }
            };

            getToken();
        }
    }, [auth0.isAuthenticated]);

    // Render Content (views) visibility
    useEffect(() => {
        if (shouldUseAuth0 !== null) {
            if (!shouldUseAuth0 || (shouldUseAuth0 && token)) {
                setIsAuthenticated(true);
                setInterval(useKeepAliveApi, 60000);
            }
        }
    }, [token, shouldUseAuth0]);

    //Attendre le retour de l'appel pour obtenir le statut des toggles
    useEffect(() => {
        if (toggle) {
            if (toggle.clavardageActif === true && token !== null) {
                //Injecter le token au clavardage
                setJetonClavardage(token);
                // On active le clavardage si on est dans le parcours "normal" et pas sur l'app mobile
                if (deepLinkMode === false && isMobileApp === false) {
                    Logger.log('Activation du clavardage');
                    // Injecter le clavardage ainsi que le token
                    injecterClavardage(envConfig.isProd);
                }
            }
            // Activer la suite du chargement de l'application
            setIsReadyForAPI(true);
        }
    }, [toggle]);

    return (
        <>
            <div className={classes.autoContainer}>
                <LoadingScreen show={globalLoading} />
                {/* Classic views */}
                {isReadyForAPI && (
                    <View
                        envConfig={envConfig}
                        langue={langue}
                        isAuth0Enabled={shouldUseAuth0}
                        isAuthenticated={auth0.isAuthenticated}
                        isNonProdEnv={envConfig.type !== EnvType.PROD}
                        isDeepLink={deepLinkMode}
                        isMobileApp={isMobileApp}
                    />
                )}
            </div>

            {isReadyForAPI && !deepLinkMode && !globalLoading && (
                <>
                    <RenderSupportBar />
                    <RenderNoteLegal />
                </>
            )}
        </>
    );
};

// Static Content: Header and footer
const App = () => {
    const [isGridVisible, setIsGridVisible] = useState(false);
    const theme = useTheme();

    // const
    const query = URLToURLSearchParams();
    const dlParam = query.get(QUERY_PARAM_DEEP_LINK);
    const mockEnabled = isMockEnabled();
    let deepLinkMode = false;

    // deepLink (Before env config)
    if ('true' === dlParam || (getPretIdDemande() === null && !mockEnabled)) {
        // Mode DeepLink: Ouvrir la vue pour obtenir les demandes
        deepLinkMode = true;
        // Si deepLink -> On enleve le cookie config pour ne pas avoir de conflit
        deleteCookie(JWT_COOKIE_CONFIG_NAME, DOMAIN_DESJARDINS_COOKIE, '/');
    }

    // Setup langue
    const langue = getLangue();
    i18n.changeLanguage(langue);
    moment.locale(langue);
    document.documentElement.lang = langue;

    // Cleanup TNR cookie
    deleteCookie(TNR_COOKIE, DOMAIN_DESJARDINS_COOKIE, '/');

    // Init mock if required
    if (shouldUseMock()) {
        useMock();
    }

    // Localhost only -> grid
    if (process.env.NODE_ENV === 'development') {
        useHotkeys('g', () => setIsGridVisible((prevState) => !prevState));
    }

    // On desactive le bouton retour sur l'application. Il faut utiliser la navigation
    useEffect(() => {
        window.history.pushState(null, '', window.location.href);
        window.onpopstate = function () {
            window.history.pushState(null, '', window.location.href);
        };
    }, []);

    return (
        <>
            {isGridVisible && <GridHelper></GridHelper>}
            <Box
                display="flex"
                flexDirection="column"
                minHeight="100vh"
                bgcolor={theme.palette.grey[200]}
            >
                <RenderHeader />
                {/* VIEW CONTENT */}
                <Auth0
                    langue={langue}
                    deepLinkMode={deepLinkMode}
                    mockEnabled={mockEnabled}
                />
                <RenderFooter />
            </Box>
        </>
    );
};

// Init Flow : Root -> App -> Auth0 -> View -> RenderRoutes -> RenderComponent
export const Root: React.FC<unknown> = () => (
    <React.StrictMode>
        <QueryClientProvider client={queryClient}>
            <ReactQueryDevtools initialIsOpen={false} />
            <ThemeProvider theme={theme}>
                <Router basename={'/'}>
                    <ScrollToTop />
                    <Provider store={store}>
                        <ErrorBoundary FallbackComponent={RenderErreur}>
                            <App />
                        </ErrorBoundary>
                    </Provider>
                </Router>
            </ThemeProvider>
        </QueryClientProvider>
    </React.StrictMode>
);
