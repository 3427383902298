import React from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { Typography, Variant, Article, Modal } from '../../styles';
import { CodeAvisEnumModal } from './CodeAvis.utils';

export interface ICodeAvisProps {
    isOpen: boolean;
    guyName: string;
    onClose: () => void;
    codeAvis: CodeAvisEnumModal;
}

export const CodeAvis: React.FC<ICodeAvisProps> = ({
    isOpen,
    guyName,
    codeAvis,
    onClose
}) => {
    const [t] = useTranslation('CodeAvis');

    return (
        <>
            {isOpen && (
                <Modal
                    show={true}
                    title={t('titre_' + codeAvis)}
                    buttonPrimaryLabel={t('buttonClose_' + codeAvis)}
                    onClickPrimaryButton={onClose}
                    dataTestId={'popup_' + codeAvis}
                >
                    <Typography variant={Variant.p} article={Article.a4}>
                        <Trans i18nKey={'CodeAvis:' + codeAvis}>
                            {{ guyName: guyName }}
                        </Trans>
                    </Typography>
                </Modal>
            )}
        </>
    );
};
