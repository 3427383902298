import React from 'react';
import { Box, useMediaQuery } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { IDemande, IDemandes } from '../../../../hooks';
import {
    getLangue,
    QUERY_PARAM_DEEP_LINK,
    QUERY_PARAM_USER_MODE,
    setCookieConfig
} from '../../../../utils';
import {
    ColorHex,
    GridCol,
    GridRow,
    Police,
    Typography,
    Variant,
    TuileNavigation,
    TuileNavigationVariantEnum,
    TuileNavigationTagThemeEnum
} from '../../../../styles';
import { isEnvNonProd } from '../../../../config';
import { EmprunteurStatut } from '../../../../hooks/api/contexte/useRenouvellementApi/useRenouvellementApi';
import { StatutDemande } from '../../../../hooks/api/contexte/useSessionApi/useSessionApi';

interface DeeplinkAvecPretProps {
    infoDemandes: IDemandes;
}

export const DeeplinkAvecPret: React.FC<DeeplinkAvecPretProps> = ({
    infoDemandes
}) => {
    const [t] = useTranslation(['DeeplinkAvecPret']);
    const isTablet = useMediaQuery('(max-width:990px)');

    const renderTuile = (key: number, demande: IDemande) => {
        const pret = demande.numeroEngagement.split('-');
        const noTag =
            demande.emprunteurStatut === EmprunteurStatut.ACCUEIL &&
            demande.statut === StatutDemande.INITIALE;
        const completedTag = demande.statut === StatutDemande.ACCEPTEE;
        const pretName =
            pret[2] + t('tuilePretSurtitreSuffix') + parseInt(pret[4]);
        const pretDateMax = t('Date:full', {
            value: demande.dateDeRappel
        });

        // Texte hors ecran pour la tuile
        let tuileTexteHorsEcran =
            t('tuilePretSRPrefix') +
            pretName +
            t('tuilePretSRSuffix') +
            pretDateMax;

        // Ajuster le texte hors ecran avec le tag si present
        if (completedTag) {
            tuileTexteHorsEcran =
                t('tuilePretTagDone') + ' - ' + tuileTexteHorsEcran;
        } else if (!completedTag && !noTag) {
            tuileTexteHorsEcran =
                t('tuilePretTagInProgress') + ' - ' + tuileTexteHorsEcran;
        }

        const handleTuileClick = () => {
            // Preparer le cookie config avec les infos necessaires comme l'idDemande et recharger le parcours en mode normal
            setCookieConfig(
                demande.idDemande.toString(),
                getLangue(),
                infoDemandes.mobileApp,
                isEnvNonProd()
            );
            // Supprimer le param deepLink si present.
            let urlChanged = false;
            const url = new URL(window.location.href);
            const searchParams = url.searchParams;
            // Remove deepLink param
            if (searchParams.has(QUERY_PARAM_DEEP_LINK)) {
                searchParams.delete(QUERY_PARAM_DEEP_LINK);
                urlChanged = true;
            }
            // // Remove userMocked param (NonProd Only)
            if (searchParams.has(QUERY_PARAM_USER_MODE)) {
                searchParams.delete(QUERY_PARAM_USER_MODE);
                urlChanged = true;
            }

            url.search = searchParams.toString();
            // Mise a jour de l'url pour reload
            if (urlChanged) {
                window.location.href = url.toString();
            } else {
                window.location.reload();
            }
        };

        return (
            <GridCol className="dsd-d-flex" key={key} col="12" sm="6" md="4">
                <TuileNavigation
                    fullWidth={true}
                    variant={TuileNavigationVariantEnum.COMPLEX}
                    onClick={handleTuileClick}
                    dataTestId={'deeplink-tuile-pret-' + key}
                    srOnlySuffix={tuileTexteHorsEcran}
                >
                    <span slot="suptitle">
                        {t('tuilePretSurtitrePrefix') + pretName}
                    </span>
                    <span slot="label">
                        {t('tuilePretSurtitreLabel')}
                        <br />
                        {pretDateMax}
                    </span>
                    <span slot="description">
                        {t('tuilePretSurtitreDescription') +
                            t('Number:currency', {
                                value: demande.solde
                            })}
                    </span>

                    {completedTag && (
                        // @ts-ignore
                        <dsd-tag
                            theme={TuileNavigationTagThemeEnum.CONFIRMATION}
                            slot="tag"
                        >
                            {t('tuilePretTagDone')}
                            {/* @ts-ignore */}
                        </dsd-tag>
                    )}

                    {!completedTag && !noTag && (
                        // @ts-ignore
                        <dsd-tag
                            theme={TuileNavigationTagThemeEnum.INFORMATION}
                            slot="tag"
                        >
                            {t('tuilePretTagInProgress')}
                            {/* @ts-ignore */}
                        </dsd-tag>
                    )}
                </TuileNavigation>
            </GridCol>
        );
    };

    return (
        <Box
            padding={
                isTablet ? '1rem 1rem 0rem 1rem' : '1.5rem 1.5rem 0.5rem 1.5rem'
            }
        >
            <Box paddingLeft={isTablet ? '0.5rem' : '0.75rem'}>
                <GridRow>
                    <Typography
                        variant={Variant.h6}
                        police={Police.bold}
                        color={ColorHex.lightGraphite4}
                        marginBottom={'0.5rem'}
                    >
                        {t('applicationTitle')}
                    </Typography>
                </GridRow>
                <GridRow>
                    <Typography
                        variant={Variant.h3}
                        police={Police.bold}
                        marginBottom={'1rem'}
                    >
                        {t('title')}
                    </Typography>
                </GridRow>
            </Box>
            <GridRow>
                {infoDemandes.demandes.map((demande, key) => {
                    return renderTuile(key, demande);
                })}
            </GridRow>
        </Box>
    );
};
