import { ClavardageContexte, setContexteClavardage } from '../../components';
import { ContainerMui, Grid, GridCol, GridRow } from '../../styles';
import {
    IResumeOffre,
    ISession,
    ParcoursEmprunteur,
    emprunteur,
    getEmprunteurTypeConnection
} from '../../hooks';
import React, { useEffect } from 'react';
import { createStyles, makeStyles } from '@material-ui/core/styles';

import { CardMedia } from '@material-ui/core';
import { ConfirmationHorsParcours } from './components/confirmationHorsParcours/ConfirmationHorsParcours';
import { ConfirmationSignatureIntegree } from './components/confirmationSignatureIntegree/ConfirmationSignatureIntegree';
import { getFormattedDate } from '../../utils';

interface IConfirmationProps {
    isOntario: boolean;
    delaiApprobation: number;
    dateLimite: Date;
    dateRappel: Date;
    dateAujourdhui: Date;
    typeParcours: ParcoursEmprunteur;
    emprunteurs: Array<emprunteur>;
    resumeOffre: IResumeOffre;
    session: ISession;
}

const useStyles = makeStyles(() =>
    createStyles({
        imageMobile: {
            borderRadius: '1rem 1rem 0 0',
            height: '100%',
            paddingBottom: '75%',
            backgroundPosition: 'bottom right',
            position: 'relative',
            display: 'flex',
            justifyContent: 'center'
        },
        imageDesktop: {
            borderRadius: '0 1rem 1rem 0',
            height: '100%',
            position: 'relative',
            backgroundPosition: 'bottom right',
            display: 'flex',
            justifyContent: 'center'
        },
        chevron: {
            top: '1.875rem',
            position: 'absolute',
            width: '42.5%'
        }
    })
);

export const Confirmation: React.FC<IConfirmationProps> = ({
    isOntario,
    delaiApprobation,
    dateLimite,
    dateRappel,
    dateAujourdhui,
    typeParcours,
    emprunteurs,
    resumeOffre,
    session
}) => {
    const classes = useStyles();
    const Chevron = () => {
        return (
            <img
                alt=""
                src="/images/chevronup.svg"
                className={classes.chevron}
            />
        );
    };

    useEffect(() => {
        setContexteClavardage(ClavardageContexte.CONFIRMATION);

        return () => {
            // Réinitialisation du contexte de clavardage
            setContexteClavardage(ClavardageContexte.DEFAULT);
        };
    }, []);

    return (
        <>
            <Grid>
                <ContainerMui hidePadding>
                    {/* Confirmation */}
                    <GridRow>
                        {/* image pour mobile */}
                        <GridCol col="12" className="sm:dsd-d-none">
                            <CardMedia
                                className={classes.imageMobile}
                                image={'/images/home.jpg'}
                            ></CardMedia>
                        </GridCol>

                        {resumeOffre?.isAdmissibleSignatureIntegree ? (
                            <ConfirmationSignatureIntegree
                                resumeOffre={resumeOffre}
                                typeParcours={typeParcours}
                                isOntario={isOntario}
                                dateLimite={dateLimite}
                                dateRappel={dateRappel}
                                dateAujourdhui={dateAujourdhui}
                                dateCibleRenouvellement={
                                    new Date(
                                        getFormattedDate(
                                            resumeOffre?.offreChoisie
                                                ?.dateCibleRenouvellement || '',
                                            '/'
                                        )
                                    )
                                }
                                emprunteurMartine={getEmprunteurTypeConnection(
                                    false,
                                    emprunteurs
                                )}
                                statutDemande={session?.statutDemande}
                            />
                        ) : (
                            <ConfirmationHorsParcours
                                isOntario={isOntario}
                                delaiApprobation={delaiApprobation}
                                dateSignature={dateLimite}
                                typeParcours={typeParcours}
                                emprunteurs={emprunteurs}
                                resumeOffre={resumeOffre}
                                session={session}
                            />
                        )}

                        {/* image desktop */}

                        <GridCol
                            className="dsd-d-none sm:dsd-d-block"
                            sm="4"
                            md="6"
                        >
                            <CardMedia
                                className={classes.imageDesktop}
                                image="/images/home.jpg"
                            >
                                <Chevron />
                            </CardMedia>
                        </GridCol>
                    </GridRow>
                </ContainerMui>
            </Grid>
        </>
    );
};
