// Code avis que nous devons afficher avec la modale
export enum CodeAvisEnumModal {
    AVIS_DATE_DEPASSEE = 'AVIS_DATE_DEPASSEE', // Renouvellement en retard
    AVIS_ASSURANCE_NON_DISPONIBLE = 'AVIS_ASSURANCE_NON_DISPONIBLE', // Retour en arriere de l'ajout d'assurance. (reset app)
    AVIS_OFFRE_MODIFIEE = 'AVIS_OFFRE_MODIFIEE', // Les offres ont changé.  Retourne acceuil. (reset app)
    AVIS_OFFRE_ACCEPTEE = 'AVIS_OFFRE_ACCEPTEE', // Écran Devenir second emprunteur. Retourne acceuil. Pageinitial (reset app)
    AVIS_PRESTATION_INVALIDITE = 'AVIS_PRESTATION_INVALIDITE', // On redirige a la page des offres
    AVIS_CONFLIT_CONVENTION = 'AVIS_CONFLIT_CONVENTION', // Martine a pris le meme courriel que Guy.
    AVIS_COURRIEL_INVALIDE = 'AVIS_COURRIEL_INVALIDE', // Mauvais domaine ou format du courriel
    AVIS_SIGNATURE_EN_COURS = 'AVIS_SIGNATURE_EN_COURS' // il y a une signature en cours pour les coemprunteurs
}
