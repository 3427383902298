import { AxiosResponse } from 'axios';
import { useMutation } from 'react-query';

import { SELECTIONNER_OFFRE_END_POINT } from '../../apiEndPoints';
import { FrequenceRemboursement } from '../useOffreApi/useOffreApi';
import {
    axiosInstance,
    reactQueryOptions,
    IMutationResult,
    getDynamicHeaders
} from '../../../../api';

export enum TypeOperationModalites {
    AMORTISSEMENT = 'AMORTISSEMENT',
    PAIEMENT = 'PAIEMENT'
}

export type infoOffreSimulee = {
    frequence: FrequenceRemboursement;
    amortissement: { annee: number; mois: number };
    remboursement: number;
    typeOperation: TypeOperationModalites;
    tauxInitial: number;
    dateProchainPaiement: string;
};

export type infoOffreSelectionne = {
    statutDemande: string;
    offreSimulee?: infoOffreSimulee;
};

interface ISauvegarderModalitesOffre {
    idDemande: number;
    idOffre: number;
    infoOffreSauvegarde?: infoOffreSelectionne;
}

const putSauvegarderModalitesOffreApi = async (
    data: ISauvegarderModalitesOffre
): Promise<AxiosResponse<any>> => {
    const endPoint = SELECTIONNER_OFFRE_END_POINT.replace(
        /{idDemande}/g,
        String(data.idDemande)
    ).replace(/{idOffre}/g, String(data.idOffre));

    const { data: response } = await axiosInstance.put(
        endPoint,
        data.infoOffreSauvegarde,
        getDynamicHeaders()
    );

    return response;
};

// Le nom de l'api est : selectionneOffreApi (pour eviter confusion avec autre api selectionnerApi)
export const useSauvegarderModalitesOffreApi = (): IMutationResult => {
    return useMutation(putSauvegarderModalitesOffreApi, {
        ...reactQueryOptions()
    });
};
