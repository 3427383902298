import { END_POINT, IEntete, TypeProduit } from './useEnteteApi';
import { QUERY_PARAM_USER_MODE, URLToURLSearchParams } from '../../../../utils';
import { mockInstance, withDelay } from '../../../../api/axiosInstance.mock';

import { dataCoEmprunteur1 as sessionMockData } from '../../contexte/useSessionApi/useSessionApi.mock';

const data: IEntete = {
    folio: '0058859',
    numCompte: '05',
    description: 'Description Pret Demande 12345 - pdo 00012345678',
    numeroTransit: '00123',
    numeroInstitution: '815',
    typeProduit: TypeProduit.PR
};

export const defineMockData = (useMockParam: string): IEntete => {
    if (!useMockParam) return data;
    switch (useMockParam) {
        case 'emprunteuruniqueontario':
        case 'emprunteuruniqueontarioSignatureIntegree':
        case 'coemprunteur1OntarioSignatureIntegree':
        case 'coemprunteur2OntarioSignatureIntegree':
            data.numeroInstitution = '829';
            return data;
        default:
            return data;
    }
};

const endPoint = END_POINT.replace(
    /{idDemande}/g,
    String(sessionMockData?.contextePret?.idDemande)
);

export const useEnteteApiMock = (): any => {
    const query = URLToURLSearchParams();
    const userMockParam = query.get(QUERY_PARAM_USER_MODE);

    mockInstance
        .onGet(endPoint)
        .reply(withDelay([200, defineMockData(userMockParam)], 200));
};
