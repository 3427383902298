import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Accordion, Police, Typography, Variant } from '../../../../styles';
interface AccordionAssuranceHypothequeProps {
    isAccordionOpen: boolean;
    admissibleAssuranceInvalidite: boolean;
    onAccordionOpenOrClose: (isOpen: boolean) => void;
}

export const AccordionAssuranceHypotheque: React.FC<
    AccordionAssuranceHypothequeProps
> = ({
    isAccordionOpen,
    admissibleAssuranceInvalidite,
    onAccordionOpenOrClose
}) => {
    const [t] = useTranslation(['EveilAssurance']);

    return (
        <Accordion
            dataTestId="accordionAssuranceHypotheque"
            titleLevel={4}
            open={isAccordionOpen}
            onOpenOrClose={(isOpen: boolean) => {
                onAccordionOpenOrClose(isOpen);
            }}
        >
            <span slot="title">{t('accordionAssuranceHypothequeTitre')}</span>
            <Typography variant={Variant.h5} police={Police.bold}>
                {t('accordionAssuranceHypothequeVotreEntourageSousTitre')}
            </Typography>
            <Typography variant={Variant.p}>
                {t('accordionAssuranceHypothequeVotreEntourageDescription1_1')}
                <span lang="en">
                    {/* utiliser pour enlever l'espace bizarre qui s'insert apres italic */}
                    <style
                        dangerouslySetInnerHTML={{
                            __html: [
                                '.accordionItalic:after {',
                                '  content: "";',
                                '  position: absolute',
                                '}'
                            ].join('\n')
                        }}
                    />
                    <i className="accordionItalic">
                        {t(
                            'accordionAssuranceHypothequeVotreEntourageDescription1_2'
                        )}
                    </i>
                </span>
                <Trans i18nKey="EveilAssurance:accordionAssuranceHypothequeVotreEntourageDescription1_3" />
            </Typography>
            <Typography variant={Variant.p}>
                {t('accordionAssuranceHypothequeVotreEntourageDescription2')}
            </Typography>
            <Typography variant={Variant.h5} police={Police.bold}>
                {t('accordionAssuranceHypothequeAssuranceCollectiveSousTitre')}
            </Typography>
            <ul>
                <li>
                    <Typography variant={Variant.p}>
                        {t(
                            'accordionAssuranceHypothequeAssuranceCollectiveBullet1'
                        )}
                    </Typography>
                </li>
                {admissibleAssuranceInvalidite && (
                    <li>
                        <Typography variant={Variant.p}>
                            {t(
                                'accordionAssuranceHypothequeAssuranceCollectiveBullet2'
                            )}
                        </Typography>
                    </li>
                )}
                <li>
                    <Typography variant={Variant.p}>
                        {t(
                            'accordionAssuranceHypothequeAssuranceCollectiveBullet3'
                        )}
                    </Typography>
                </li>
            </ul>
        </Accordion>
    );
};
