import { useMutation } from 'react-query';

import {
    axiosInstance,
    getDynamicHeaders,
    IMutationResult,
    reactQueryOptions
} from '../../../../api';
import { SIGNATURE_ENDPOINT } from '../../apiEndPoints';
import { AxiosResponse } from 'axios';

export type ISignature = {
    idContrat: string;
    url: string;
};

interface ISignatureParams {
    idDemande: number;
}

const postSignature = async (
    param: ISignatureParams
): Promise<AxiosResponse<any>> => {
    const endPoint = SIGNATURE_ENDPOINT.replace(
        /{idDemande}/g,
        String(param?.idDemande)
    );

    const { data: response } = await axiosInstance.post(
        endPoint,
        null,
        getDynamicHeaders()
    );

    return response;
};

export const useSignatureApi = (): IMutationResult => {
    return useMutation(postSignature, {
        ...reactQueryOptions()
    });
};
