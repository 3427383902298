export const getMessageErreur = (
    emailInputError: boolean,
    cellPhoneInputError: boolean,
    question1Error: boolean,
    question2Error: boolean,
    answer1Error: boolean,
    answer2Error: boolean,
    checkBoxError: boolean
): string => {
    let messageErreur = 'Erreur utilisateur page Informations Signature: ';

    if (emailInputError) {
        messageErreur = messageErreur + 'Adresse courriel | ';
    }

    if (cellPhoneInputError) {
        messageErreur = messageErreur + 'Numéro de téléphone cellulaire | ';
    }

    if (question1Error || answer1Error) {
        messageErreur = messageErreur + 'Question 1 | ';
    }

    if (question2Error || answer2Error) {
        messageErreur = messageErreur + 'Question 2 | ';
    }

    if (checkBoxError) {
        messageErreur = messageErreur + 'Accord au consentement | ';
    }

    messageErreur = messageErreur.substring(0, messageErreur.length - 3);

    return messageErreur;
};

export interface IConventionState {
    cellulaire: string;
    question1Id: string;
    question2Id: string;
    reponse1: string;
    reponse2: string;
    langue: string;
    modeAuthentification: string;
    courriel: string;
}
