import createPalette from '@material-ui/core/styles/createPalette';

const themePalette = createPalette({
    common: {
        black: '#000000',
        white: '#ffffff'
    },
    primary: {
        desjardinsVert: '#00874e',
        light: '#6f9384',
        main: '#00884e',
        dark: '#007944',
        contrastText: '#ffffff'
    },
    secondary: {
        main: '#00884e',
        light: '#d7e6fa',
        dark: '#007944',
        contrastText: '#ffffff',
        oliveGreen: '#443507',
        hover: 'rgba(0, 135, 78, 0.08)'
    },
    grey: {
        100: '#ffffff',
        200: '#f4f4f4', //f2f2f2
        300: /*'#eaeaec',*/ '#cbcccc',
        400: '#dcdedf',
        500: '#bbbdbf',
        600: '#80868b', //595959
        700: /*'#636a6e',*/ '#383838',
        800: /*'#23282b', */ '#2f2f2f',
        900: '#000000'
    },
    dsd: {
        tooltip: '#383838'
    },
    contrastThreshold: 3,
    tonalOffset: 0
});

export default themePalette;
