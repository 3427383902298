import { useQuery, UseQueryResult } from 'react-query';

import {
    axiosInstance,
    getDynamicHeaders,
    reactCachedQueryOptions
} from '../../../../api';

export enum TypeProduit {
    PR = 'PR'
}

export interface IEntete {
    folio: string;
    numCompte: string;
    description: string;
    numeroTransit: string;
    numeroInstitution: string;
    typeProduit: TypeProduit;
}

export const END_POINT = `/demandes/{idDemande}/entete`;

const fetch = async (idDemande: number) => {
    const endPoint = END_POINT.replace(/{idDemande}/g, String(idDemande));

    const { data } = await axiosInstance.get(endPoint, getDynamicHeaders());

    return data;
};

export function useEnteteApi(
    idDemande: number = undefined
): UseQueryResult<IEntete, Error> {
    return useQuery<IEntete, Error>(
        ['entete', idDemande],
        () => fetch(idDemande),
        {
            ...reactCachedQueryOptions(),
            enabled: !!idDemande
        }
    );
}
