import { useQuery, UseQueryResult } from 'react-query';

import {
    axiosInstance,
    getDynamicHeaders,
    reactQueryOptions
} from '../../../../api';
import { DEMANDE_END_POINT } from '../../apiEndPoints';

export interface IInfosSignatureElement {
    cellulaire: string;
    courriel: string;
    idEmprunteur: number;
    modeAuthentification: string;
    question1Id: null | number;
    question1Libelle: null | string;
    question2Id: null | number;
    question2Libelle: null | string;
    reponse1: null | string;
    reponse2: null | string;
    langue: string;
}

export interface IInfosSignature {
    listeConventions?: Array<IInfosSignatureElement>;
    userConventions?: IInfosSignatureElement;
    userNotConnectedConventions?: IInfosSignatureElement;
}

export const END_POINT = DEMANDE_END_POINT + '/conventions';

const getUserConventionsData = (
    conventions: IInfosSignature,
    idEmprunteur: number,
    isSameEmprunteur: boolean
): any => {
    return conventions?.listeConventions?.filter((convention) => {
        if (isSameEmprunteur) {
            return convention?.idEmprunteur === idEmprunteur;
        } else {
            return convention?.idEmprunteur !== idEmprunteur;
        }
    })[0];
};

const getListConventionsData = (data, idEmprunteur) => {
    const userConventions: IInfosSignatureElement = getUserConventionsData(
        data,
        idEmprunteur,
        true
    );
    const userNotConnectedConventions: IInfosSignatureElement =
        getUserConventionsData(data, idEmprunteur, false);

    const transformedData: IInfosSignature = {
        ...data,
        userConventions: userConventions,
        userNotConnectedConventions: userNotConnectedConventions
    };

    return transformedData;
};

const fetch = async (idDemande: number, idEmprunteur: number) => {
    const endPoint = END_POINT.replace(/{idDemande}/g, String(idDemande));

    const { data } = await axiosInstance.get(endPoint, getDynamicHeaders());

    const transformedData = getListConventionsData(data, idEmprunteur);

    return transformedData;
};

export function useListeConventionsApi(
    idDemande: number = undefined,
    idEmprunteur: number = undefined,
    isEnabled = true
): UseQueryResult<IInfosSignature, Error> {
    return useQuery<IInfosSignature, Error>(
        ['listeConventions', idDemande],
        () => fetch(idDemande, idEmprunteur),
        {
            ...reactQueryOptions(),
            enabled: !!isEnabled
        }
    );
}
