import React from 'react';
import { useTranslation } from 'react-i18next';
import { TypeOffre } from '../../hooks';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import themePalette from '../../theme/themePalette';

const useStyles = makeStyles(() =>
    createStyles({
        text: {
            marginBottom: '8px',
            marginLeft: '16px'
        },
        sousTitre: {
            color: themePalette.primary['desjardinsVert'],
            marginLeft: '-12px',
            marginTop: '12px',
            marginBottom: '20px'
        },
        paragraph: {
            marginLeft: '-1.4rem',
            '@media (min-width: 1200px)': {
                marginLeft: '-0.7rem'
            }
        }
    })
);

export const getKeyTitre = (typeOffre: TypeOffre): string => {
    switch (typeOffre) {
        case TypeOffre.UN_ANS_FIXE_FERME:
            return 'titre1AFF';
        case TypeOffre.DEUX_ANS_FIXE_FERME:
            return 'titre2AFF';
        case TypeOffre.TROIS_ANS_FIXE_FERME:
        case TypeOffre.QUARANTE_MOIS_FIXE_FERME:
        case TypeOffre.TRENTENEUF_MOIS_FIXE_FERME:
        case TypeOffre.QUARANTE_DEUX_MOIS_FIXE_FERME:
        case TypeOffre.CINQUANTE_CINQ_MOIS_FIXE_FERME:
        case TypeOffre.CINQ_ANS_FIXE_FERME:
            return 'titreFF';
        case TypeOffre.QUATRE_ANS_FIXE_FERME:
            return 'titre4AFF';
        case TypeOffre.SEPT_ANS_FIXE_FERME:
            return 'titre7AFF';
        case TypeOffre.CINQ_ANS_REVISABLE_FERME:
            return 'titre5ARF';
        case TypeOffre.CINQ_ANS_VARIABLE_REDUIT:
            return 'titre5AVR';
        case TypeOffre.CINQ_ANS_VARIABLE_PROTEGE:
            return 'titre5AVP';
        default:
            return 'titre non trouvé';
    }
};

export const getDescription = (
    typeOffre: TypeOffre,
    tauxRabaisSubsequent: number,
    dureeSuffix?: string
): JSX.Element => {
    const [t] = useTranslation(['DetailsOffreModal']);
    const classes = useStyles();

    switch (typeOffre) {
        case TypeOffre.UN_ANS_FIXE_FERME:
            return (
                <>
                    <li className={classes.text}>{t('description1AFF_1')}</li>
                    <li className={classes.text}>{t('description1AFF_2')}</li>
                </>
            );
        case TypeOffre.DEUX_ANS_FIXE_FERME:
            return (
                <>
                    <li className={classes.text}>{t('description2AFF_1')}</li>
                    <li className={classes.text}>{t('description2AFF_2')}</li>
                </>
            );
        case TypeOffre.TROIS_ANS_FIXE_FERME:
        case TypeOffre.QUARANTE_MOIS_FIXE_FERME:
        case TypeOffre.TRENTENEUF_MOIS_FIXE_FERME:
        case TypeOffre.QUARANTE_DEUX_MOIS_FIXE_FERME:
            return (
                <>
                    <li className={classes.text}>{t('descriptionFF_1')}</li>
                    <li className={classes.text}>
                        {t('descriptionFF_2') + dureeSuffix}
                    </li>
                </>
            );
        case TypeOffre.QUATRE_ANS_FIXE_FERME:
            return (
                <>
                    <li className={classes.text}>{t('description4AFF_1')}</li>
                    <li className={classes.text}>{t('description4AFF_2')}</li>
                </>
            );
        case TypeOffre.CINQ_ANS_FIXE_FERME:
        case TypeOffre.CINQUANTE_CINQ_MOIS_FIXE_FERME:
            return (
                <>
                    <li className={classes.text}>{t('description5AFF_1')}</li>
                    <li className={classes.text}>
                        {t('description5AFF_2') + dureeSuffix}
                    </li>
                </>
            );
        case TypeOffre.SEPT_ANS_FIXE_FERME:
            return (
                <>
                    <li className={classes.text}>{t('description7AFF_1')}</li>
                    <li className={classes.text}>{t('description7AFF_2')}</li>
                </>
            );
        case TypeOffre.CINQ_ANS_REVISABLE_FERME:
            return (
                <>
                    <li className={classes.text}>{t('description5ARF_1')}</li>
                    <li className={classes.text}>{t('description5ARF_2')}</li>
                    <li className={classes.text}>
                        {t('description5ARF_3_1') +
                            tauxRabaisSubsequent +
                            t('description5ARF_3_2')}
                    </li>
                    <li className={classes.text}>{t('description5ARF_4')}</li>
                </>
            );
        case TypeOffre.CINQ_ANS_VARIABLE_REDUIT:
            return (
                <>
                    <li className={classes.text}>{t('description5AVR_1')}</li>
                </>
            );
        case TypeOffre.CINQ_ANS_VARIABLE_PROTEGE:
            return (
                <>
                    <h6 className={classes.sousTitre}>
                        {t('tauxPesonnaliseTitre')}
                    </h6>
                    <p className={classes.paragraph}>
                        {t('description5AVP_1')}
                    </p>
                    <p className={classes.paragraph}>
                        {t('description5AVP_2')}
                    </p>
                </>
            );
        default:
            return <></>;
    }
};

export const getAvantages = (
    typeOffre: TypeOffre,
    dureeSuffix: string
): JSX.Element => {
    const [t] = useTranslation(['DetailsOffreModal']);
    const classes = useStyles();

    switch (typeOffre) {
        case TypeOffre.UN_ANS_FIXE_FERME:
            return (
                <>
                    <li className={classes.text}>{t('avantages1AFF_1')}</li>
                    <li className={classes.text}>{t('avantages1AFF_2')}</li>
                </>
            );
        case TypeOffre.DEUX_ANS_FIXE_FERME:
            return (
                <>
                    <li className={classes.text}>{t('avantages2AFF_1')}</li>
                    <li className={classes.text}>{t('avantages2AFF_2')}</li>
                </>
            );
        case TypeOffre.TROIS_ANS_FIXE_FERME:
        case TypeOffre.QUARANTE_MOIS_FIXE_FERME:
        case TypeOffre.TRENTENEUF_MOIS_FIXE_FERME:
        case TypeOffre.QUARANTE_DEUX_MOIS_FIXE_FERME:
            return (
                <>
                    <li className={classes.text}>{t('avantagesFF_1')}</li>
                    <li className={classes.text}>
                        {t('avantagesFF_2') + dureeSuffix}
                    </li>
                </>
            );
        case TypeOffre.QUATRE_ANS_FIXE_FERME:
            return (
                <>
                    <li className={classes.text}>{t('avantages4AFF_1')}</li>
                </>
            );
        case TypeOffre.CINQ_ANS_FIXE_FERME:
        case TypeOffre.CINQUANTE_CINQ_MOIS_FIXE_FERME:
            return (
                <>
                    <li className={classes.text}>
                        {t('avantages5AFF_1') + dureeSuffix}
                    </li>
                </>
            );
        case TypeOffre.SEPT_ANS_FIXE_FERME:
            return (
                <>
                    <li className={classes.text}>{t('avantages7AFF_1')}</li>
                </>
            );
        case TypeOffre.CINQ_ANS_REVISABLE_FERME:
            return (
                <>
                    <li className={classes.text}>{t('avantages5ARF_1')}</li>
                    <li className={classes.text}>{t('avantages5ARF_2')}</li>
                    <li className={classes.text}>{t('avantages5ARF_3')}</li>
                    <li className={classes.text}>{t('avantages5ARF_4')}</li>
                    <li className={classes.text}>{t('avantages5ARF_5')}</li>
                </>
            );
        case TypeOffre.CINQ_ANS_VARIABLE_REDUIT:
            return (
                <>
                    <li className={classes.text}>{t('avantages5AVR_1')}</li>
                    <li className={classes.text}>{t('avantages5AVR_2')}</li>
                </>
            );
        case TypeOffre.CINQ_ANS_VARIABLE_PROTEGE:
            return (
                <>
                    <li className={classes.text}>{t('avantages5AVP_1')}</li>
                    <li className={classes.text}>{t('avantages5AVP_2')}</li>
                    <li className={classes.text}>{t('avantages5AVP_3')}</li>
                    <li className={classes.text}>{t('avantages5AVP_4')}</li>
                    <li className={classes.text}>{t('avantages5AVP_5')}</li>
                </>
            );
        default:
            return <></>;
    }
};

export const getPourQui = (typeOffre: TypeOffre): JSX.Element => {
    const [t, i18n] = useTranslation(['DetailsOffreModal']);
    const classes = useStyles();

    switch (typeOffre) {
        case TypeOffre.UN_ANS_FIXE_FERME:
            return (
                <>
                    <li className={classes.text}>{t('pourQui1AFF_1')}</li>
                    <li className={classes.text}>{t('pourQui1AFF_2')}</li>
                </>
            );
        case TypeOffre.DEUX_ANS_FIXE_FERME:
            return (
                <>
                    <li className={classes.text}>{t('pourQui2AFF_1')}</li>
                    <li className={classes.text}>{t('pourQui2AFF_2')}</li>
                </>
            );
        case TypeOffre.TROIS_ANS_FIXE_FERME:
        case TypeOffre.QUARANTE_MOIS_FIXE_FERME:
        case TypeOffre.TRENTENEUF_MOIS_FIXE_FERME:
        case TypeOffre.QUARANTE_DEUX_MOIS_FIXE_FERME:
            return (
                <>
                    <li className={classes.text}>{t('pourQuiFF_1')}</li>
                    <li className={classes.text}>{t('pourQuiFF_2')}</li>
                </>
            );
        case TypeOffre.QUATRE_ANS_FIXE_FERME:
            return (
                <>
                    <li className={classes.text}>{t('pourQui4AFF_1')}</li>
                    <li className={classes.text}>{t('pourQui4AFF_2')}</li>
                </>
            );
        case TypeOffre.CINQ_ANS_FIXE_FERME:
        case TypeOffre.CINQUANTE_CINQ_MOIS_FIXE_FERME:
            return (
                <>
                    <li className={classes.text}>{t('pourQui5AFF_1')}</li>
                    <li className={classes.text}>{t('pourQui5AFF_2')}</li>
                </>
            );
        case TypeOffre.SEPT_ANS_FIXE_FERME:
            return (
                <>
                    <li className={classes.text}>{t('pourQui7AFF_1')}</li>
                    <li className={classes.text}>{t('pourQui7AFF_2')}</li>
                    <li className={classes.text}>{t('pourQui7AFF_3')}</li>
                    <li className={classes.text}>
                        {t('pourQui7AFF_4_1')}
                        <a
                            href={
                                i18n.language === 'fr'
                                    ? 'https://www.desjardins.com/particuliers/projets-evenements/financer-habitation/gerer-pret-hypothecaire/rembourser-totalite-pret/index.jsp'
                                    : 'https://www.desjardins.com/ca/personal/goals-life-events/owning-home/managing-mortgage/pay-off-mortgage/index.jsp'
                            }
                            target={'_blank'}
                            rel={'noopener noreferrer'}
                            tabIndex={0}
                        >
                            {t('pourQui7AFF_4_2')}
                        </a>
                        {t('pourQui7AFF_4_3')}
                    </li>
                    <li className={classes.text}>{t('pourQui7AFF_5')}</li>
                </>
            );
        case TypeOffre.CINQ_ANS_REVISABLE_FERME:
            return <li className={classes.text}>{t('pourQui5ARF_1')}</li>;
        case TypeOffre.CINQ_ANS_VARIABLE_REDUIT:
            return (
                <>
                    <li className={classes.text}>{t('pourQui5AVR_1')}</li>
                    <li className={classes.text}>{t('pourQui5AVR_2')}</li>
                </>
            );
        case TypeOffre.CINQ_ANS_VARIABLE_PROTEGE:
            return (
                <>
                    <li className={classes.text}>{t('pourQui5AVP_1')}</li>
                    <li className={classes.text}>{t('pourQui5AVP_2')}</li>
                    <li className={classes.text}>{t('pourQui5AVP_3')}</li>
                    <li className={classes.text}>{t('pourQui5AVP_4')}</li>
                    <li className={classes.text}>{t('pourQui5AVP_5')}</li>
                    <li className={classes.text}>{t('pourQui5AVP_6')}</li>
                </>
            );
        default:
            return <></>;
    }
};
