import { AxiosResponse } from 'axios';
import { useMutation } from 'react-query';

import { DEMANDE_END_POINT } from '../../apiEndPoints';
import {
    axiosInstance,
    reactQueryOptions,
    IMutationResult,
    getDynamicHeaders
} from '../../../../api';

export const END_POINT =
    DEMANDE_END_POINT + '/emprunteurs/actuel/offres/choisie/refuser';

const putRefuserOffre = async (
    idDemande: number
): Promise<AxiosResponse<any>> => {
    const endPoint = END_POINT.replace(/{idDemande}/g, String(idDemande));

    const { data: response } = await axiosInstance.put(
        endPoint,
        {},
        getDynamicHeaders()
    );

    return response;
};

export const useRefuserOffreApi = (): IMutationResult => {
    return useMutation(putRefuserOffre, {
        ...reactQueryOptions()
    });
};
