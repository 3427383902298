import React from 'react';
import { useTranslation } from 'react-i18next';
import {
    Box,
    makeStyles,
    createStyles,
    useMediaQuery
} from '@material-ui/core';

import {
    GridRow,
    GridCol,
    Typography,
    Variant,
    Police,
    Button,
    ButtonVariant,
    IconTooltip,
    ContainerMui,
    Spacer
} from '../../styles';
import {
    IResumeOffre,
    TypeAssurance,
    hasAlreadyAssurance,
    hasSoumissionAssurance,
    isVariableProtege,
    isVariableReduit
} from '../../hooks';
import { PretActuelModal, DetailsOffreModalChoixCoEmprunteur } from '..';

const useStyles = makeStyles(() =>
    createStyles({
        note: {
            fontSize: '0.875rem',
            fontWeight: 'normal'
        },
        interest: {
            fontSize: '18px',
            fontWeight: 'bold'
        }
    })
);

export interface IResumePretProps {
    resumeOffre: IResumeOffre;
    isEditButtonDisplayed?: boolean;
    isTauxAssuranceDisplayed: boolean;
    isSoldeSansAssuranceDisplayed: boolean;
    showButtonPretActualModal?: boolean;
    onModifiedClick: () => void;
    onTooltipActive: () => void;
}

export const ResumePret: React.FC<IResumePretProps> = ({
    resumeOffre,
    isEditButtonDisplayed,
    isTauxAssuranceDisplayed,
    showButtonPretActualModal,
    isSoldeSansAssuranceDisplayed,
    onModifiedClick,
    onTooltipActive
}) => {
    const [t] = useTranslation(['ResumePret']);
    const classes = useStyles();
    const isMobile = useMediaQuery('(max-width:640px)');

    const { titreOffre, assurance, offreChoisie } = resumeOffre;
    const { codeAssurance, typeAssurance } = assurance;
    const {
        valeurTauxPropose,
        valeurTauxEffectif,
        valeurTauxPlafond,
        valeurTauxPreferentiel,
        tauxRabaisSubsequent,
        tauxAdditionnelAssurance,
        montantRemboursementSansAssurance,
        has5Dans1Note
    } = offreChoisie;

    const hasAssurance = hasAlreadyAssurance(codeAssurance);
    const isSoumissionAssurance = hasSoumissionAssurance(codeAssurance);
    const isOfferVariableProtege = isVariableProtege(resumeOffre?.offreChoisie);
    const isOfferVariableReduit = isVariableReduit(resumeOffre?.offreChoisie);
    const shoudTooltipBeDisplayed =
        isOfferVariableReduit || isOfferVariableProtege;

    const renderInterestRate = () => {
        const interestRateValue =
            isOfferVariableProtege && valeurTauxEffectif
                ? valeurTauxEffectif
                : valeurTauxPropose;

        return t('Number:percent', {
            value: interestRateValue
        });
    };

    const renderInterestTooltip = () => {
        const interetPretTooltipKey = isOfferVariableProtege
            ? 'resumePretTypeVPTooltip'
            : 'resumePretTypeVRTooltip';

        return (
            <Box paddingTop="4px" paddingLeft="0.5rem">
                <IconTooltip
                    iconName="alertes_contour_information"
                    srOnlySuffix={t('resumePretTypeTooltipAltText')}
                    label={t(interetPretTooltipKey)}
                    onActive={onTooltipActive}
                ></IconTooltip>
            </Box>
        );
    };

    return (
        <>
            <ContainerMui dataTestId="bloc_taux_validation">
                <Box
                    ml={isMobile ? 1 : 3}
                    mb={
                        isEditButtonDisplayed
                            ? isMobile
                                ? -2
                                : -1
                            : isMobile
                            ? -4
                            : -3
                    }
                    mt={isMobile ? -2 : -1}
                >
                    <GridCol col="12">
                        <GridRow>
                            <Typography
                                variant={Variant.h4}
                                police={Police.bold}
                                marginBottom="1rem"
                            >
                                {t('resumePretTitle')}
                            </Typography>
                        </GridRow>
                        <GridRow>
                            <Typography
                                variant={Variant.p}
                                marginBottom="0.25rem"
                            >
                                {t('resumePretTypeTitle')}
                            </Typography>
                        </GridRow>
                        <GridRow>
                            <Typography
                                variant={Variant.h6}
                                police={Police.bold}
                                marginBottom="1rem"
                            >
                                {titreOffre}
                            </Typography>
                        </GridRow>
                        <GridRow>
                            <Typography
                                variant={Variant.p}
                                marginBottom="0.25rem"
                            >
                                {t('resumePretInterestTitle')}
                            </Typography>
                            {shoudTooltipBeDisplayed && renderInterestTooltip()}
                        </GridRow>
                        <GridRow>
                            <Typography variant={Variant.p}>
                                <span className={classes.interest}>
                                    {renderInterestRate()}
                                </span>
                                {has5Dans1Note && (
                                    <span
                                        className={classes.note}
                                        data-testid="text_taux_5dans1"
                                    >
                                        {/* Ajout du space pour espacement entre interet et tooltip*/}{' '}
                                        {t('resumePretInterestSubTextPart1')}
                                        <sup>
                                            {t(
                                                'resumePretInterestSubTextPart2'
                                            )}
                                        </sup>
                                        {t('resumePretInterestSubTextPart3')}
                                        {t('Number:percent', {
                                            value: Math.abs(
                                                tauxRabaisSubsequent
                                            )
                                        })}
                                        {t('resumePretInterestSubTextPart4')}
                                    </span>
                                )}
                                {isOfferVariableProtege &&
                                    valeurTauxPlafond && (
                                        <>
                                            <span
                                                className={classes.note}
                                                data-testid="text_taux_vp"
                                            >
                                                {t('resumePretTypeVPTextPart1')}
                                                {t('Number:percent', {
                                                    value: valeurTauxPlafond
                                                })}
                                                {t('resumePretTypeVPTextPart2')}
                                            </span>
                                        </>
                                    )}
                                {isOfferVariableReduit &&
                                    valeurTauxPreferentiel && (
                                        <>
                                            <span
                                                className={classes.note}
                                                data-testid="text_taux_reduit"
                                            >
                                                {t('resumePretTypeVRTextPart1')}
                                                {t('Number:percent', {
                                                    value: valeurTauxPreferentiel
                                                })}
                                                {t('resumePretTypeVRTextPart2')}
                                            </span>
                                        </>
                                    )}
                            </Typography>
                        </GridRow>
                        {hasAssurance && isTauxAssuranceDisplayed && (
                            <>
                                <GridRow>
                                    <Typography
                                        variant={Variant.p}
                                        marginBottom="0.25rem"
                                    >
                                        {t(
                                            typeAssurance === TypeAssurance.AP
                                                ? 'resumePretInsuranceTitleAssurance'
                                                : 'resumePretInsuranceTitleAssuranceMargeAtout'
                                        )}
                                    </Typography>
                                </GridRow>
                                <GridRow>
                                    <Typography
                                        variant={Variant.p}
                                        police={Police.bold}
                                        marginBottom="1rem"
                                    >
                                        {t('Number:percent3Decimal', {
                                            value: tauxAdditionnelAssurance
                                        })}
                                    </Typography>
                                </GridRow>
                            </>
                        )}
                        {isSoumissionAssurance &&
                            isSoldeSansAssuranceDisplayed && (
                                <>
                                    <GridRow>
                                        <Typography
                                            variant={Variant.p}
                                            marginBottom="0.25rem"
                                        >
                                            {t(
                                                'resumePretInsuranceTitlePaiementSansAssurance'
                                            )}
                                        </Typography>
                                    </GridRow>
                                    <GridRow>
                                        <Typography
                                            variant={Variant.p}
                                            police={Police.bold}
                                            marginBottom="1rem"
                                        >
                                            {t('Number:currency', {
                                                value: montantRemboursementSansAssurance
                                            })}
                                        </Typography>
                                    </GridRow>
                                </>
                            )}
                        {isEditButtonDisplayed && (
                            <GridRow>
                                <Button
                                    id={'QA_test_validation_date_modifier'}
                                    variant={ButtonVariant.compact}
                                    dataCy="qa-button-date-modifier"
                                    dataTestId={'bouton_modifier_offre'}
                                    onClick={onModifiedClick}
                                    srOnlySuffix={
                                        t('resumeChangeButton') +
                                        ' ' +
                                        t('resumePretTypeTitle')
                                    }
                                >
                                    {t('resumeChangeButton')}
                                </Button>
                            </GridRow>
                        )}
                    </GridCol>
                </Box>
                {showButtonPretActualModal && (
                    <Box
                        mb={isMobile ? -2 : -1}
                        mt={-2.5}
                        ml={isMobile ? -2 : 0}
                    >
                        <GridCol col="12">
                            <GridRow>
                                <Box mb={2}>
                                    <DetailsOffreModalChoixCoEmprunteur
                                        resumeOffre={resumeOffre}
                                    />
                                </Box>
                            </GridRow>
                            <GridRow>
                                <PretActuelModal />
                            </GridRow>
                        </GridCol>
                    </Box>
                )}
            </ContainerMui>
            <Spacer md={3} sm={1} xs={1} />
        </>
    );
};
