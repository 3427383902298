import React from 'react';
import { useDispatch } from 'react-redux';

import { ResumeDate } from './ResumeDate';
import { Routes, useRoutes, IResumeOffre, ISession } from '../../hooks';
import {
    sendEvent,
    EVENT_CATEGORIES,
    EVENT_ACTIONS,
    EVENT_LABELS
} from '../../features';
import { getFormattedDate } from '../../utils';

export interface IResumeDateConnectedProps {
    isEditButtonDisplayed: boolean;
    resumeOffre: IResumeOffre;
    session: ISession;
}

export const ResumeDateConnected: React.FC<IResumeDateConnectedProps> = ({
    isEditButtonDisplayed,
    resumeOffre,
    session
}) => {
    const { goToRoute } = useRoutes();
    const dispatch = useDispatch();

    const handleOnModifiedClick = () => {
        dispatch(
            sendEvent({
                category: EVENT_CATEGORIES.RHN,
                action: EVENT_ACTIONS.CLIC,
                label: EVENT_LABELS.MODIFIER + 'date de paiement'
            })
        );

        goToRoute(Routes.MODALITES_DATE_PAIEMENT);
    };

    return (
        session &&
        resumeOffre && (
            <ResumeDate
                shouldBeDisplayed={
                    resumeOffre?.offreChoisie
                        .dateProchainPaiementChangementFrequence !== null
                }
                dateProchainPaiement={getFormattedDate(
                    resumeOffre?.offreChoisie
                        .dateProchainPaiementChangementFrequence,
                    '/'
                )}
                dateDernierPaiement={getFormattedDate(
                    resumeOffre?.offreChoisie.dateDebutEffetChangementFrequence,
                    '/'
                )}
                isEditButtonDisplayed={
                    isEditButtonDisplayed && !session?.isMartine
                }
                onModifiedClick={handleOnModifiedClick}
            />
        )
    );
};
