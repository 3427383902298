export default {
    en: {
        footnoteGroupTitle: 'Notes',
        noteLegaleTexte: 'Read legal terms and conditions',
        noteTauxPageOffre:
            'These rates are being offered to you as the result of an automated assessment of your personal information. There was no human involvement. For more information, contact us. '
    },
    fr: {
        footnoteGroupTitle: 'Notes',
        noteLegaleTexte: 'Lire les conditions légales',
        noteTauxPageOffre:
            'Ces taux vous sont offerts à la suite d’une analyse automatique de vos renseignements personnels. Aucune intervention humaine n’a eu lieu. Pour en savoir plus, communiquez avec nous. '
    }
};
