import {
    END_POINT,
    ISession,
    PageInitiale,
    ParcoursEmprunteur,
    PersonneConnectee,
    StatutDemande
} from './useSessionApi';
import {
    QUERY_PARAM_USER_MODE,
    URLToURLSearchParams,
    getDateStr
} from '../../../../utils';
import { mockInstance, withDelay } from '../../../../api/axiosInstance.mock';

const nextWeekDateStr = getDateStr(
    new Date(new Date().setDate(new Date().getDate() + 7))
);

const inFourDays = getDateStr(
    new Date(new Date().setDate(new Date().getDate() + 4))
);

export const dataCoEmprunteur1: ISession = {
    pageInitiale: PageInitiale.ACCUEIL,
    statutDemande: StatutDemande.INITIALE,
    langue: 'fr',
    approbationCoemprunteur: {
        delaiApprobation: 14,
        dateLimite: nextWeekDateStr,
        dateRappel: inFourDays
    },
    codeAvis: null,
    typeParcoursEmprunteur: ParcoursEmprunteur.COEMPRUNTEUR_GUY,
    dateSysteme: '20210128',
    contextePret: {
        idDemande: 12345,
        descriptionPerso: 'Description Pret Demande 12345 - pdo 00012345678'
    },
    contexteToggleFeature: {
        calculScenarioPaiement: true
    },
    personneConnectee: PersonneConnectee.MEMBRE
};

export const dataConseiller: ISession = {
    ...dataCoEmprunteur1,
    personneConnectee: PersonneConnectee.CONSEILLER
};

export const dataCoEmprunteur2: ISession = {
    ...dataCoEmprunteur1,
    typeParcoursEmprunteur: ParcoursEmprunteur.COEMPRUNTEUR_MARTINE,
    isMartine: true
};

export const dataCoEmprunteur2Retard: ISession = {
    ...dataCoEmprunteur2,
    codeAvis: 'AVIS_DATE_DEPASSEE'
};

export const dataEmprunteurUnique: ISession = {
    ...dataCoEmprunteur1,
    typeParcoursEmprunteur: ParcoursEmprunteur.UNIQUE
};

export const defineMockData = (useMockParam: string): ISession => {
    if (!useMockParam) return dataCoEmprunteur1;
    switch (useMockParam) {
        case 'conseiller':
            return dataConseiller;
        case 'coemprunteur2':
        case 'coemprunteur2InteretRetard':
        case 'coemprunteur2SignatureIntegree':
        case 'coemprunteur2OntarioSignatureIntegree':
            return dataCoEmprunteur2;
        case 'coemprunteur2Retard':
            return dataCoEmprunteur2Retard;
        case 'emprunteurunique':
        case 'emprunteuruniqueontario':
        case 'emprunteuruniqueSignatureIntegree':
        case 'emprunteuruniqueInteretRetard':
        case 'emprunteurUniqueAdmissibleAssuranceParcours1':
        case 'emprunteurUniqueAjoutAssuranceSignatureIntegreeParcours2':
        case 'emprunteurUniqueAdmissibleAssuranceParcours2':
        case 'emprunteurUniqueAdmissibleAssuranceParcours3':
        case 'emprunteuruniqueontarioSignatureIntegree':
            return dataEmprunteurUnique;
        default:
            return dataCoEmprunteur1;
    }
};

export const useSessionApiMock = (): any => {
    // var
    const query = URLToURLSearchParams();
    const useMockParam = query.get(QUERY_PARAM_USER_MODE);

    mockInstance
        .onGet(END_POINT)
        .reply(withDelay([200, defineMockData(useMockParam)], 200));
};
