import React, { useEffect } from 'react';

import {
    EVENT_CATEGORIES,
    EVENT_ACTIONS,
    EVENT_LABELS,
    sendEvent
} from '../../../../features';
import { useDispatch } from 'react-redux';
import { CoutAssuranceModal } from './CoutAssuranceModal';

interface ICoutAssuranceModalConnectedProps {
    isOpen: boolean;
    onClose: () => void;
}

export const CoutAssuranceModalConnected: React.FC<
    ICoutAssuranceModalConnectedProps
> = ({ isOpen, onClose }) => {
    const dispatch = useDispatch();

    useEffect(() => {
        if (isOpen) {
            dispatch(
                sendEvent({
                    category: EVENT_CATEGORIES.RHN,
                    action: EVENT_ACTIONS.CLIC,
                    label: EVENT_LABELS.COMPRENDRE_COUT_ASSURANCE
                })
            );
        }
    }, [isOpen]);

    return <CoutAssuranceModal isOpen={isOpen} onClose={onClose} />;
};
