import React from 'react';
import { Box } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import {
    Grid,
    GridRow,
    GridCol,
    Alert,
    AlertType,
    Typography,
    Variant
} from '../../styles';

export const Erreur: React.FC<unknown> = () => {
    const [t] = useTranslation(['Erreur']);

    const renderErreurTechniqueHeader = () => (
        <>
            <Typography variant={Variant.h1}>{t('title')}</Typography>
        </>
    );
    const renderErreurAlert = () => {
        return (
            <>
                <Box pb={1} data-testid={'description_erreur_technique'}>
                    <Alert type={AlertType.error}>
                        {t('errorTechniqueText')}
                    </Alert>
                </Box>
            </>
        );
    };
    return (
        <>
            <Grid>
                <GridRow>
                    {/* Header title */}
                    <GridCol col="12" sm="12">
                        {renderErreurTechniqueHeader()}
                    </GridCol>
                    {/* Alert */}
                    <GridCol col="12" sm="12">
                        {renderErreurAlert()}
                    </GridCol>
                </GridRow>
            </Grid>
        </>
    );
};
