import React from 'react';
import { useTranslation } from 'react-i18next';

import { Box, useMediaQuery } from '@material-ui/core';
import {
    FootnoteRef,
    GridRow,
    Police,
    Typography,
    Variant
} from '../../../../styles';
import { ParcoursAssurance } from '../../../../hooks';

interface EveilAssuranceDescriptionProps {
    admissibleAssuranceInvalidite: boolean;
    parcoursAssurance: ParcoursAssurance;
}

export const EveilAssuranceDescription: React.FC<
    EveilAssuranceDescriptionProps
> = ({ admissibleAssuranceInvalidite, parcoursAssurance }) => {
    const [t] = useTranslation(['EveilAssurance']);
    const isTablet = useMediaQuery('(max-width:990px)');
    const isMobile = useMediaQuery('(max-width:640px)');

    const renderDescriptionParcours2 = () => {
        return (
            <>
                <Typography variant={Variant.p} paddingRight="0.75rem">
                    {t('descriptionParcours2_1')}
                </Typography>
                <Typography variant={Variant.p} paddingRight="0.75rem">
                    {t('descriptionParcours2_2')}
                </Typography>
            </>
        );
    };

    const renderDescriptionParcours1et3 = () => {
        return (
            <Typography variant={Variant.p} paddingRight="0.75rem">
                {t('descriptionParcours1et3')}
            </Typography>
        );
    };

    return (
        <Box
            paddingLeft={isTablet ? '0.5rem' : '0.75rem'}
            mb={isMobile ? '1rem' : '1.5rem'}
        >
            <GridRow>
                <Typography
                    variant={Variant.h3}
                    police={Police.bold}
                    marginBottom={'1rem'}
                >
                    {t('subtitle')}
                </Typography>
            </GridRow>
            <GridRow>
                {ParcoursAssurance.Parcours1 === parcoursAssurance ||
                ParcoursAssurance.Parcours3 === parcoursAssurance
                    ? renderDescriptionParcours1et3()
                    : renderDescriptionParcours2()}
            </GridRow>
            {admissibleAssuranceInvalidite && (
                <>
                    <GridRow>
                        <Typography variant={Variant.h4} police={Police.bold}>
                            {t('description_assurance_invalidite_titre')}
                        </Typography>
                    </GridRow>
                    <GridRow>
                        <Typography
                            variant={Variant.p}
                            paddingRight="0.75rem"
                            hideMarginBottom
                        >
                            {t('description_assurance_invalidite_1')}
                            <Box display="inline" paddingLeft="0.1rem">
                                <FootnoteRef
                                    target={'footnote_admissible_invalidite'}
                                    id={
                                        'footnote_return_footnote_admissible_invalidite'
                                    }
                                >
                                    <span aria-label={t('footnoteToolTip')}>
                                        <span aria-hidden="true">1</span>
                                    </span>
                                </FootnoteRef>
                            </Box>
                            {t('description_assurance_invalidite_2')}
                        </Typography>
                    </GridRow>
                </>
            )}
        </Box>
    );
};
