import { END_POINT, IToggle } from './useToggleApi';
import { mockInstance, withDelay } from '../../../../api/axiosInstance.mock';

export const dataToggle: IToggle = {
    clavardageActif: true,
    texteLoi14: true
};

export const useToggleApiMock = (): any => {
    mockInstance.onGet(END_POINT).reply(withDelay([200, dataToggle], 200));
};
