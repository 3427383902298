import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    show: true
};

export const loadingSlice = createSlice({
    name: 'loading',
    initialState: initialState,
    reducers: {
        showLoading: (state) => {
            if (!state.show) state.show = true;
        },
        hideLoading: (state) => {
            if (state.show) state.show = false;
        },
        setLoading: (state, action) => {
            state.show = action.payload;
        }
    }
});

export const { showLoading, hideLoading, setLoading } = loadingSlice.actions;

export default loadingSlice.reducer;
