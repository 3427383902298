export default {
    en: {
        back: 'Back',
        cancel: 'Save and leave',
        next: 'Renew mortgage',
        titre: 'Options for signing your contract',
        titreAlerteInfo: 'Notice - rate and payment',
        descriptionInfoSignature:
            'To finalize your mortgage renewal, you’ll need to sign your contract using our secure e-signature platform.',
        titreLangue: 'Document language',
        descriptionLangue:
            '* What language would you like to sign the documents in?',
        descriptionLangueGuy:
            'Please note that you and your co-borrower will be required to sign them in the same language.',
        descriptionLangueMartine: 'Your co-borrower selected:',
        optionLangueFrancais: 'French',
        optionLangueAnglais: 'English',
        fr: 'French',
        en: 'English',
        // courriel
        courrielTitre: 'Email address',
        courrielDescriptionGuyHP:
            'After your co-borrower confirms your selections, you’ll get an email within 3 business days with a link to sign the documents.',
        courrielDescriptionMartineHP:
            'You’ll get an email within 3 business days with a link to sign the documents.',
        courrielDescriptionSI:
            'Your e-signature will be linked to this address.',
        courrielInput: '* Email address',
        courrielInputError: 'Enter a valid email address.',
        alertEmailInputError: 'Email address',
        // authentification
        authentificationTitre: 'Identity verification',
        authentificationModeLabel:
            '* What verification method would you like to use?',
        authentificationModeRadioSMS: 'Receive a security code by text message',
        authentificationModeRadioQuestions: 'Answer security questions',
        authentificationModeCellphoneInput: '* Cellphone number',
        authentificationModeCellphoneInputErrorAlert: 'Cellphone number',
        authentificationModeCellphoneInputHint: 'For example: 555-123-4567',
        authentificationModeCellphoneInputError:
            'Enter a valid 10-digit phone number.',
        authentificationModeQuestionsInput: ' Question ',
        authentificationModeQuestionsAnswerInput: ' Answer ',
        authentificationModeQuestionsErrorHint:
            'Select a question in the drop-down menu.',
        authentificationModeQuestionsAnswerHintEmpty: 'Enter an answer.',
        authentificationModeQuestionsErrorMinMaxCharHint:
            'Answers need to be between 4 and 100 characters (letters and numbers only).',
        titreConsentement: 'Consent',
        descriptionConsentement_1: 'By choosing to ',
        descriptionConsentement_2_strong: 'renew my mortgage',
        descriptionConsentement_3: ', I acknowledge that: ',
        puce1Guy: 'The renewal offer will be sent to my co-borrower, ',
        puce2GuyHP_1: 'My mortgage will be updated once ',
        puce2GuyHP_2: ' confirms my selections.',
        puce1UniqueEtMartineHP_1: 'My mortgage will be ',
        puce1UniqueEtMartineHP_2: 'updated before',
        puce1UniqueEtMartineHP_3: ' I receive the email to sign the documents.',
        puce1SI: 'My mortgage will be updated.',
        puce2SI:
            'I must sign each document that is submitted for electronic signature.',
        puce3SI:
            'Each electronic signature that I affix represents my consent and I am bound by that signature.',
        puceAssurance:
            'I’ve reviewed the insurance details, cost and description. ',
        texteOntario:
            'By choosing to renew this mortgage, I also agree to receive, by electronic means, any cost of borrowing disclosure statements required by law regarding my credit agreements. This includes current and future agreements, as well as any amendments or renewals of those agreements.',
        checkBoxErrorTexte: 'Consent agreement',
        checkBoxTexte_1:
            'I’ve read and accepted all the terms of the mortgage renewal.',
        checkBoxTexte_2:
            'I understand that the terms of the electronic contract may differ from those of the offer I selected in this mortgage renewal process. In this case, the electronic contract will prevail over the offer selected in this process.',
        texteIndemnite:
            'Note that you will not be required to pay a penalty, even if you renew your mortgage earlier than expected.',
        loi14AvisPart1:
            'We’re required by law to provide the French version of your documents first.',
        loi14AvisPart2:
            'When you get to the signature platform, you’ll need to scroll to the bottom of the French document and confirm that you received it. You will then be directed to the English version.',
        loi14TitreHorsEcran: 'Notice - documents',
        titreModalSI: 'Confirm your email address',
        infoRenouvellementActifSIUnique:
            'The new rate and payment will replace your current ones after you sign the documents.',
        infoRenouvellementActifSIGuy:
            'The new rate and payment will replace your current ones on <strong>{{date, MMMM\xa0D}}, {{date, YYYY}}</strong>, if your co-borrower confirms your choices.',
        infoRenouvellementActifSIMartine:
            'The new rate and payment will replace your current ones on <strong>{{date, MMMM\xa0D}}, {{date, YYYY}}</strong>.',
        infoRenouvellementActifHP:
            'The new rate and payment will replace your current ones after you renew your mortgage.',
        infoSignatureContratEnLigne:
            'To finalize your mortgage renewal, you’ll need to sign your contract using our secure e-signature platform.',
        descriptionModalSI:
            'Before you sign, confirm that your email address is correct.',
        titreModal: 'Confirm your details',
        descriptionModal:
            'Before you sign, confirm that your details are correct.',
        sousTitreModalAdresseCourriel: 'Email address',
        sousTitreModalCellulaire: 'Cellphone number',
        sousTitreModalQuestion: 'Question',
        sousTitreModalReponse: 'Answer',
        modalSecondaryButtonLabel: 'Edit',
        modalPrimaryButtonLabel: 'Confirm'
    },
    fr: {
        back: 'Précédent',
        cancel: 'Enregistrer et quitter',
        next: 'Renouveler le prêt',
        titre: 'Choisir les options de signature',
        titreAlerteInfo: 'Avis – taux et paiement',
        descriptionInfoSignature:
            'Pour finaliser votre renouvellement hypothécaire, vous devez signer votre contrat. La signature électronique se fait en ligne, en toute sécurité.',
        titreLangue: 'Langue des documents',
        descriptionLangue:
            '* Dans quelle langue voulez-vous signer les documents?',
        descriptionLangueGuy:
            'Notez que votre coemprunteur devra les signer dans la même langue.',
        descriptionLangueMartine: 'Votre coemprunteur a choisi :',
        optionLangueFrancais: 'Français',
        optionLangueAnglais: 'Anglais',
        fr: 'Français',
        en: 'Anglais',
        // courriel
        courrielTitre: 'Adresse courriel',
        courrielDescriptionGuyHP:
            'Vous recevrez un courriel d’invitation à la signature dans les 3 jours ouvrables après que votre coemprunteur aura confirmé vos choix.',
        courrielDescriptionMartineHP:
            'Vous recevrez un courriel d’invitation à la signature dans les 3 jours ouvrables.',
        courrielDescriptionSI:
            'Votre signature électronique sera liée à cette adresse.',
        courrielInput: '* Adresse courriel',
        alertEmailInputError: 'Adresse courriel',
        courrielInputError: 'Entrez une adresse courriel valide.',
        // authentification
        authentificationTitre: 'Vérification de votre identité',
        authentificationModeLabel:
            '* Quelle méthode de vérification voulez-vous utiliser?',
        authentificationModeRadioSMS: 'Recevoir un code de sécurité par texto',
        authentificationModeRadioQuestions:
            'Répondre à des questions de sécurité',
        authentificationModeCellphoneInput: '* Numéro de cellulaire',
        authentificationModeCellphoneInputErrorAlert: 'Numéro de cellulaire',
        authentificationModeCellphoneInputError:
            'Entrez un numéro de téléphone valide. Le numéro doit comporter 10 chiffres.',
        authentificationModeCellphoneInputHint: 'Exemple : 555 123-4567',
        authentificationModeQuestionsInput: ' Question ',
        authentificationModeQuestionsAnswerInput: ' Réponse ',
        authentificationModeQuestionsErrorHint:
            'Choisissez une question dans la liste déroulante.',
        authentificationModeQuestionsAnswerHintEmpty: 'Entrez une réponse.',
        authentificationModeQuestionsErrorMinMaxCharHint:
            'Vos réponses doivent contenir 4 à 100 caractères alphanumériques.',
        // Texte anglais / francais des questions ( dont la langue est definie par le contrat )
        questionId_1_fr: 'Quel est votre code secret pour cette transaction?',
        questionId_2_fr: 'Quel est le nom de votre premier employeur?',
        questionId_3_fr:
            'Quel est le premier code postal que vous avez appris par cœur?',
        questionId_4_fr:
            'Quelle est l’année de l’achat de votre première maison / copropriété?',
        questionId_5_fr: 'Sur quelle rue avez-vous vécu à l’âge de 18 ans?',
        questionId_6_fr:
            'Quel est le nom de l’école primaire de votre premier enfant?',
        questionId_7_fr:
            'Dans quelle ville ou municipalité était l’école primaire où vous avez terminé votre 6e année?',
        questionId_8_fr:
            "Quel est le nom de famille de votre meilleur ami d'enfance?",
        questionId_9_fr: "Quel était votre surnom d'enfance?",
        questionId_10_fr: 'Quel est le prénom de votre grand-mère maternelle?',
        questionId_1_en: "What's the secret transaction code?",
        questionId_2_en: 'What was the name of your first employer?',
        questionId_3_en: 'What is the first postal code you learned by heart?',
        questionId_4_en: 'In what year did you buy your first home?',
        questionId_5_en: 'What street did you live on when you were 18?',
        questionId_6_en:
            "What's the name of your eldest child's elementary school?",
        questionId_7_en:
            'In which city was the school where you finished 6th grade?',
        questionId_8_en: 'What is the last name of your childhood best friend?',
        questionId_9_en: 'What was your nickname as a kid?',
        questionId_10_en: "What is your maternal grandmother's first name?",
        titreConsentement: 'Consentement',
        descriptionConsentement_1: 'En choisissant de ',
        descriptionConsentement_2_strong: 'renouveler le prêt',
        descriptionConsentement_3: ', je reconnais que\xa0: ',
        puce1Guy:
            'L’offre de renouvellement sera transmise à mon coemprunteur, ',
        puce2GuyHP_1: 'Mon prêt hypothécaire sera mis à jour dès que ',
        puce2GuyHP_2: ' aura confirmé mes choix.',
        puce1UniqueEtMartineHP_1: 'Mon prêt hypothécaire sera ',
        puce1UniqueEtMartineHP_2: 'mis à jour avant',
        puce1UniqueEtMartineHP_3:
            ' que je reçoive le courriel d’invitation à la signature.',
        puce1SI: 'Mon prêt hypothécaire sera mis à jour.',
        puce2SI:
            'Je suis la personne qui doit signer chaque document soumis pour signature électronique.',
        puce3SI:
            'Chaque signature électronique que j’appose constitue mon consentement et je suis lié ou liée par cette signature.',
        puceAssurance:
            'J’ai consulté les détails, le coût et la description de l’assurance.',
        texteOntario:
            'De plus, en choisissant de renouveler le prêt, j’accepte de recevoir par voie électronique toute déclaration du coût d’emprunt requise par la loi concernant mes conventions et contrats de crédit. Cela inclut les conventions et contrats actuels, ceux à venir ainsi que leurs modifications ou leurs renouvellements.',
        checkBoxErrorTexte: 'Accord au consentement',
        checkBoxTexte_1:
            'J’ai lu et j’accepte toutes les modalités du renouvellement hypothécaire.',
        checkBoxTexte_2:
            'Je reconnais que les modalités du contrat électronique peuvent différer de ceux de l’offre que j’ai choisie dans le présent parcours. Dans ce cas, le contrat électronique prévaudra sur l’offre que j’ai choisie dans le parcours.',
        texteIndemnite:
            'Sachez que vous n’aurez pas à payer de frais d’indemnité, même si vous renouvelez votre prêt hypothécaire plus tôt que prévu.',
        loi14AvisPart1:
            'La loi nous oblige à vous remettre la version française des documents en premier.',
        loi14AvisPart2:
            'Lors de la signature, vous devrez défiler le document français, puis confirmer que vous l’avez bien reçu. La version anglaise s’affichera par la suite.',
        loi14TitreHorsEcran: 'Avis - documents',
        titreModalSI: 'Confirmer votre adresse courriel',
        infoRenouvellementActifSIUnique:
            'Les nouveaux taux et paiements remplacent ceux de votre prêt actuel dès que vous signez les documents.',
        infoRenouvellementActifSIGuy:
            'Les nouveaux taux et paiements remplaceront ceux de votre prêt actuel le <strong>{{date, D}}{{suffixe}}\xa0{{date, MMMM\xa0YYYY}}</strong> si votre coemprunteur approuve vos choix.',
        infoRenouvellementActifSIMartine:
            'Les nouveaux taux et paiements remplaceront ceux de votre prêt actuel le <strong>{{date, D}}{{suffixe}}\xa0{{date, MMMM\xa0YYYY}}</strong>.',
        infoRenouvellementActifHP:
            'Les nouveaux taux et paiements remplacent ceux de votre prêt actuel dès que vous renouvelez le prêt.',
        descriptionModalSI:
            'Avant de signer, confirmez que votre adresse courriel est exacte.',
        titreModal: 'Confirmer vos renseignements',
        descriptionModal:
            'Avant de signer, confirmez que vos renseignements sont exacts.',
        sousTitreModalAdresseCourriel: 'Adresse courriel',
        sousTitreModalCellulaire: 'Numéro de cellulaire',
        sousTitreModalQuestion: 'Question',
        sousTitreModalReponse: 'Réponse',
        modalSecondaryButtonLabel: 'Modifier',
        modalPrimaryButtonLabel: 'Confirmer'
    }
};
