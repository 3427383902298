import React from 'react';
import { useTranslation } from 'react-i18next';
import { ModeAuthentification } from '../../../../hooks';

import {
    Typography,
    Variant,
    Police,
    Modal,
    ISelectOptions
} from '../../../../styles';
import { IConventionState } from '../../InformationsSignature.utils';
import { createStyles, makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() =>
    createStyles({
        email: {
            wordWrap: 'break-word'
        }
    })
);

export interface IModalInfoSignature {
    isOpen: boolean;
    isAdmissibleSI: boolean;
    convention: IConventionState;
    questions: ISelectOptions;
    onClose: () => void;
    handleNext: () => void;
}

export const ModalInfoSignature: React.FC<IModalInfoSignature> = ({
    isOpen,
    isAdmissibleSI,
    convention,
    questions,
    onClose,
    handleNext
}) => {
    const [t] = useTranslation('InformationsSignature');
    const classes = useStyles();

    const findQuestion = (questionId: string): string => {
        return questions.find(
            (question) => Number(question.value) === Number(questionId)
        )?.label;
    };

    return (
        <>
            {isOpen && (
                <Modal
                    show={true}
                    title={t(isAdmissibleSI ? 'titreModalSI' : 'titreModal')}
                    buttonPrimaryLabel={t('modalPrimaryButtonLabel')}
                    buttonSecondaryLabel={t('modalSecondaryButtonLabel')}
                    onClickPrimaryButton={handleNext}
                    onClickSecondaryButton={onClose}
                    dataTestId={'modal_confirmer_vos_renseignements'}
                >
                    <Typography variant={Variant.p} marginBottom="1.5rem">
                        {t(
                            isAdmissibleSI
                                ? 'descriptionModalSI'
                                : 'descriptionModal'
                        )}
                    </Typography>
                    <Typography variant={Variant.p} hideMarginBottom>
                        {t('sousTitreModalAdresseCourriel')}
                    </Typography>
                    <Typography
                        className={classes.email}
                        variant={Variant.p}
                        police={Police.bold}
                        marginBottom="1rem"
                        dataTestId={'courriel_membre'}
                    >
                        {convention?.courriel}
                    </Typography>
                    {!isAdmissibleSI && (
                        <>
                            {convention?.modeAuthentification ===
                                ModeAuthentification.CODE_SMS &&
                                convention?.cellulaire && (
                                    <>
                                        <Typography
                                            variant={Variant.p}
                                            hideMarginBottom
                                        >
                                            {t('sousTitreModalCellulaire')}
                                        </Typography>
                                        <Typography
                                            variant={Variant.p}
                                            police={Police.bold}
                                            marginBottom="1rem"
                                            dataTestId={'cellulaire_membre'}
                                        >
                                            {convention?.cellulaire}
                                        </Typography>
                                    </>
                                )}
                            {convention?.modeAuthentification ===
                                ModeAuthentification.QUESTIONS &&
                                convention?.question1Id &&
                                convention?.question2Id &&
                                convention?.reponse1 &&
                                convention?.reponse2 && (
                                    <>
                                        <Typography
                                            variant={Variant.p}
                                            hideMarginBottom
                                        >
                                            {t('sousTitreModalQuestion') + ' 1'}
                                        </Typography>
                                        <Typography
                                            variant={Variant.p}
                                            police={Police.bold}
                                            marginBottom="1rem"
                                            dataTestId={'question1_membre'}
                                        >
                                            {findQuestion(
                                                convention?.question1Id
                                            )}
                                        </Typography>
                                        <Typography
                                            variant={Variant.p}
                                            hideMarginBottom
                                        >
                                            {t('sousTitreModalReponse') + ' 1'}
                                        </Typography>
                                        <Typography
                                            variant={Variant.p}
                                            police={Police.bold}
                                            marginBottom="1rem"
                                            dataTestId={'reponse1_membre'}
                                        >
                                            {convention?.reponse1}
                                        </Typography>
                                        <Typography
                                            variant={Variant.p}
                                            hideMarginBottom
                                        >
                                            {t('sousTitreModalQuestion') + ' 2'}
                                        </Typography>
                                        <Typography
                                            variant={Variant.p}
                                            police={Police.bold}
                                            marginBottom="1rem"
                                            dataTestId={'question2_membre'}
                                        >
                                            {findQuestion(
                                                convention?.question2Id
                                            )}
                                        </Typography>
                                        <Typography
                                            variant={Variant.p}
                                            hideMarginBottom
                                        >
                                            {t('sousTitreModalReponse') + ' 2'}
                                        </Typography>
                                        <Typography
                                            variant={Variant.p}
                                            police={Police.bold}
                                            marginBottom="1rem"
                                            dataTestId={'reponse2_membre'}
                                        >
                                            {convention?.reponse2}
                                        </Typography>
                                    </>
                                )}
                        </>
                    )}
                </Modal>
            )}
        </>
    );
};
