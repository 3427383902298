import React from 'react';
import { useDispatch } from 'react-redux';

import { SupportBar } from './SupportBar';
import { ContexteAppel, getParlerConseillerUrl } from '../../utils';
import {
    sendEvent,
    EVENT_CATEGORIES,
    EVENT_ACTIONS,
    EVENT_LABELS
} from './../../features';
import { Routes, useRoutes } from '../../hooks';
import { Headings } from '../../styles';
import { HeadingProvider } from '../../context';

export interface IParlerConseillerProps {
    contexte: ContexteAppel;
    idDemande: number;
}

const headingMapping = {
    h2: Headings.h5,
    h3: Headings.h6,
    h4: Headings.h5
};

export const SupportBarConnected: React.FC<IParlerConseillerProps> = ({
    contexte,
    idDemande
}) => {
    const dispatch = useDispatch();
    const { getRoute } = useRoutes();

    const trackPlanifierUnAppel = () => {
        dispatch(
            sendEvent({
                category: EVENT_CATEGORIES.RHN,
                action: EVENT_ACTIONS.CLIC,
                label: EVENT_LABELS.CLICK2CALL_CONSEILLER + contexte
            })
        );
    };

    let isLastComponentOnPage: boolean;
    switch (getRoute()?.route) {
        case Routes.OFFRES:
            isLastComponentOnPage = false;
            break;
        case Routes.EVEIL_ASSURANCE:
            isLastComponentOnPage = false;
            break;
        default:
            isLastComponentOnPage = true;
    }

    return (
        <HeadingProvider value={headingMapping}>
            <SupportBar
                supportUrl={getParlerConseillerUrl(contexte, idDemande)}
                isLastComponentOnPage={isLastComponentOnPage}
                onSupportLinkClick={trackPlanifierUnAppel}
            />
        </HeadingProvider>
    );
};
