import React from 'react';
import { useTranslation } from 'react-i18next';

import { ContexteAppel, getParlerConseillerUrl } from '../../../../utils';
import {
    Typography,
    Variant,
    Link,
    Police,
    Body,
    EnumLinkType
} from '../../../../styles';

export interface IMoreOptionsProps {
    idDemande: number;
    onNavigationParlerConseiller: () => void;
}

export const MoreOptions: React.FC<IMoreOptionsProps> = ({
    idDemande,
    onNavigationParlerConseiller
}) => {
    const [t] = useTranslation(['MoreOptions']);

    return (
        <React.Fragment>
            <Typography variant={Variant.h3} police={Police.bold}>
                {t('moreoptions_title')}
            </Typography>
            <Typography variant={Variant.p} body={Body.b2}>
                {t('moreoptions_text_part1')}
                <Link
                    type={EnumLinkType.EXTERNAL}
                    href={getParlerConseillerUrl(
                        ContexteAppel.VENTE_DE_PROPRIETE,
                        idDemande
                    )}
                    dataTestId="parler-conseiller-accueil-vente-propriete"
                    onClick={onNavigationParlerConseiller}
                    ariaLabel={t('click2call_aria')}
                >
                    {t('moreoptions_text_link')}
                </Link>
                {t('moreoptions_text_part2')}
            </Typography>
        </React.Fragment>
    );
};
