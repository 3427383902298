import React, { useEffect, useState } from 'react';
import { Box, FormControl, useMediaQuery } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

import {
    InputType,
    ISelectOptions,
    Typography,
    Variant,
    Article,
    ColorDSD,
    SelectMui,
    InputMui
} from '../../../../styles';

export interface IQestionInfoSignatureProps {
    id: number;
    paddingBottom: string;
    conventionId: string;
    conventionQuestionReponse: string;
    questions: ISelectOptions;
    shouldQuestionErrorDisplay: boolean;
    shouldAnswerErrorDisplay: boolean;
    selectedQuestionInUse: number;
    questionRef: any;
    onQuestionChange: (questionId: string) => void;
    onQuestionAnswerChange: (questionAnswer: string) => void;
}

export const QuestionInfoSignature: React.FC<IQestionInfoSignatureProps> = ({
    id,
    paddingBottom,
    conventionId,
    conventionQuestionReponse,
    questions,
    shouldQuestionErrorDisplay,
    shouldAnswerErrorDisplay,
    selectedQuestionInUse,
    questionRef,
    onQuestionChange,
    onQuestionAnswerChange
}) => {
    const [t] = useTranslation('InformationsSignature');
    const isMobile = useMediaQuery('(max-width:820px)');
    const [questionsOptions, setQuestionsOptions] = useState(questions);
    const [isErrorQuestionDisplayed, setIsErrorQuestionDisplayed] = useState(
        shouldQuestionErrorDisplay
    );
    const [isErrorAnswerDisplayed, setIsErrorAnswerDisplayed] = useState(
        shouldAnswerErrorDisplay
    );

    const questionId = 'question' + id;

    const handleQuestionChange = (e) => {
        setIsErrorQuestionDisplayed(false);
        const value = e?.target?.value;
        onQuestionChange(value);
    };

    const handleQuestionAnswerChange = (e) => {
        setIsErrorAnswerDisplayed(false);
        const value = e?.target?.value;
        onQuestionAnswerChange(value);
    };

    useEffect(() => {
        setIsErrorQuestionDisplayed(shouldQuestionErrorDisplay);
        setIsErrorAnswerDisplayed(shouldAnswerErrorDisplay);
    }, [shouldQuestionErrorDisplay, shouldAnswerErrorDisplay]);

    const getErrorDisplayMessage = () => {
        // si vide, on met le message d'erreur normal, sinon c'est que le min/max lenght n'est pas le bon
        const isEmpty = !conventionQuestionReponse;

        return isEmpty
            ? t('authentificationModeQuestionsAnswerHintEmpty')
            : t('authentificationModeQuestionsErrorMinMaxCharHint');
    };

    useEffect(() => {
        if (selectedQuestionInUse && questions) {
            const result = questions.map((o) => ({
                ...o
            }));
            result.find((o) => Number(o.value) === selectedQuestionInUse);
            setQuestionsOptions(result);
        } else if (questions) {
            setQuestionsOptions(questions);
        }
    }, [selectedQuestionInUse, questions]);

    return (
        <Box
            display="flex"
            paddingBottom={paddingBottom}
            flexDirection={isMobile ? 'column' : 'row'}
            id={questionId}
        >
            <Box
                display="flex"
                id={questionId + '_text'}
                flexDirection="column"
                paddingBottom={isMobile ? '1rem' : 0}
                width={isMobile ? '100%' : '420px'}
                data-testid="listbox"
            >
                <Typography
                    variant={Variant.p}
                    article={Article.a3}
                    color={ColorDSD.lightSystem3}
                    marginBottom="0.5rem"
                >
                    {'*' + t('authentificationModeQuestionsInput') + id}
                </Typography>

                <FormControl>
                    <SelectMui
                        id={'infos_signature_select_' + id}
                        dataTestId={'select-option-' + id}
                        value={conventionId}
                        options={questionsOptions}
                        onChange={handleQuestionChange}
                        error={isErrorQuestionDisplayed}
                        helperText={
                            isErrorQuestionDisplayed &&
                            t('authentificationModeQuestionsErrorHint')
                        }
                    />
                </FormControl>
                {/* TODO: reactiver le select dsd quand les problèmes de render sont réglé */}
                {/* <Select
                    isRequired
                    hasNoDefaultOption
                    dataTestId={'select-option-' + id}
                    identifier={'liste_question' + id}
                    value={Number(conventionId)}
                    options={questionsOptions}
                    onChange={handleQuestionChange}
                    label={t('authentificationModeQuestionsInput' + id)}
                    error={
                        isErrorQuestionDisplayed &&
                        t('authentificationModeQuestionsErrorHint')
                    }
                /> */}
            </Box>

            <Box
                display="flex"
                id={questionId + '_reponse'}
                flexDirection="column"
                paddingLeft={isMobile ? 0 : '1.5rem'}
                width={isMobile ? '100%' : '420px'}
            >
                <Box maxWidth={isMobile ? '100%' : '261px'}>
                    <Typography
                        variant={Variant.p}
                        article={Article.a3}
                        color={ColorDSD.lightSystem3}
                        marginBottom="0.5rem"
                    >
                        {'*' +
                            t('authentificationModeQuestionsAnswerInput') +
                            id}
                    </Typography>

                    <InputMui
                        dataTestId={'input_reponse' + id + '_field'}
                        inputRef={questionRef}
                        isError={isErrorAnswerDisplayed}
                        helperText={
                            isErrorAnswerDisplayed && getErrorDisplayMessage()
                        }
                        key={'InputMui_InfoSignature_Answer_0' + id}
                        isFullWidth={true}
                        value={conventionQuestionReponse}
                        type={InputType.TEXT}
                        onChange={handleQuestionAnswerChange}
                    />
                    {/* TODO: réactiver quand le select dsd est fix*/}
                    {/* <Input
                        isRequired
                        dataTestId={'input_reponse' + id + '_field'}
                        inputRef={questionRef}
                        isError={isErrorAnswerDisplayed}
                        label={
                            t('authentificationModeQuestionsAnswerInput') + id
                        }
                        errorLabel={getErrorDisplayMessage()}
                        key={'InputMui_InfoSignature_Answer_0' + id}
                        value={conventionQuestionReponse}
                        type={InputType.TEXT}
                        inputMode={InputMode.TEXT}
                        onFocusOut={handleQuestionAnswerChange}
                        onChange={handleQuestionAnswerChange}
                    /> */}
                </Box>
            </Box>
        </Box>
    );
};
