export default {
    en: {
        // LEGAL
        legal_general_title:
            'TERMS AND CONDITIONS OF THE HYPOTHECARY LOAN RENEWAL OFFER',
        legal_general_partie_1:
            'The terms and conditions of your loan will be amended as described in the Renewal Notice and Hypothecary (Mortgage) Loan Renewal Agreement that will be provided for your signature ',
        legal_general_partie_bold: '(the “renewal notice and loan agreement”)',
        legal_general_partie_2:
            '. With the exception of these amendments, the initial agreement and any collateral agreements will continue to apply, without novation, and the caisse will retain its hypothec on your property.',
        // LEGAL - INTERET
        legal_interet_title: 'INTEREST',
        // LEGAL - INTERET - PARTIE TAUX FIXE
        legal_interet_tauxfixe_title: 'If you selected a fixed-rate loan:',
        legal_interet_tauxfixe_desc_partie_1:
            'Commencing on the date indicated in the renewal notice and loan agreement, the loan shall bear interest, both before and after the due date, at the annual rate indicated in the renewal terms and conditions of the offer you selected ',
        legal_interet_tauxfixe_desc_partie_bold: '(the “loan rate”)',
        legal_interet_tauxfixe_desc_partie_2:
            '. This rate will be calculated semi-annually and not in advance.',
        legal_interet_tauxfixe_taux_interet_partie_bold:
            'Interest rate reduction: ',
        legal_interet_tauxfixe_taux_interet_partie_texte:
            'You acknowledge that the loan rate includes a rate reduction for the duration of the term. The annual rate equivalent to this rate reduction will be indicated in the renewal notice and loan agreement. If the loan is prepaid in part or in full, the interest saved as a result of this reduction must be repaid in proportion to the time remaining in the term. (See Prepayment section below.)',
        // LEGAL - INTERET - PARTIE TAUX VARIABLE
        legal_interet_tauxvariable_title:
            'If you selected a reduced variable-rate loan:',
        legal_interet_tauxvariable_paragraph_1_partie_1:
            'Commencing on the date indicated in the renewal notice and loan agreement, the variable interest rate on the loan, both before and after the due date, will be the Desjardins prime rate plus or less the annual rate indicated in the renewal terms and conditions ',
        legal_interet_tauxvariable_paragraph_1_partie_bold: '(the “loan rate”)',
        legal_interet_tauxvariable_paragraph_1_partie_2:
            '. The loan rate shall vary according to each change in the Desjardins prime rate and shall be calculated monthly and not in advance.',
        legal_interet_tauxvariable_paragraph_2:
            'The “Desjardins prime rate” is the interest rate that the Fédération des caisses Desjardins du Québec sets from time to time as its prime rate. It is available on the Desjardins website and at any caisse. The Desjardins prime rate in effect on the renewal date will be indicated in the renewal notice and loan agreement.',
        legal_interet_tauxvariable_paragraph_3:
            'If, due to a variation in the loan rate, the amount of a payment is less than the accrued interest, the unpaid interest shall be capitalized and shall itself bear interest at the loan rate.',
        legal_interet_tauxvariable_paragraph_4_bold:
            'CONVERSION TO CLOSED FIXED RATE: ',
        legal_interet_tauxvariable_paragraph_4_texte:
            'At any time, you may ask the caisse to convert your loan rate to a closed fixed rate for the remainder of the term without paying an indemnity. You must then sign an amendment agreement with the new loan repayment terms.',
        // LEGAL - REMBOURSEMENT
        legal_remboursement_title: 'REPAYMENT',
        legal_remboursement_desc:
            'You agree to repay the balance of the loan, without the possibility of set-off or compensation, by way of regular payments in principal and interest in accordance with the payment frequency and term set out in the renewal notice and loan agreement. The full amount of the loan shall become payable on the term end date. Based on these terms of repayment, the loan amortization period is the period indicated in the renewal terms and conditions of the offer you selected.',
        // LEGAL - PRET AUTRE PREMIER RANG
        legal_pretnonpremierrang_remboursement_title:
            'FOR A HYPOTHECARY LOAN OTHER THAN FIRST RANK TO A “CONSUMER”',
        legal_pretnonpremierrang_remboursement_desc:
            'If you are renewing a hypothecary loan other than a first-ranking hypothec, the credit rate and credit charges for the duration of the contract (term) will be indicated in the renewal notice and loan agreement.',
        // LEGAL - AUGMENTATION PAIEMENTS PERIODIQUES
        legal_augmentation_paiements_periodiques_title:
            'INCREASED SCHEDULED PAYMENTS',
        legal_augmentation_paiements_periodiques_desc_partie_1:
            'Once per calendar year, you may increase your scheduled payments up to twice the payment amount without paying an indemnity. You may subsequently notify the caisse that you wish to reduce your payments to no lower than the amount stated in the renewal notice and loan agreement. (For examples, go to ',
        legal_lien_gerer_pret: 'https://www.desjardins.com/managemortgage',
        legal_augmentation_paiements_periodiques_desc_partie_2: '.)',
        // LEGAL - REMBOURSEMENT PAR ANTICIPATION
        legal_remboursement_anticipation_title: 'PREPAYMENT',
        legal_remboursement_anticipation_subtitle:
            'If you selected a closed fixed-rate loan:',
        legal_remboursement_anticipation_desc_partie_1:
            '(For examples of how the penalties in the paragraphs below are calculated, go to ',
        legal_remboursement_anticipation_desc_partie_2: '.)',
        // LEGAL - REMBOURSEMENTS PARTIELS
        legal_remboursements_partiels_title: 'Partial loan prepayments',
        legal_remboursements_partiels_desc_partie_1:
            'Each calendar year, you may prepay, in payments of at least $100, a maximum of 15% of the ',
        legal_remboursements_partiels_desc_partie_bold: 'initial loan amount ',
        legal_remboursements_partiels_desc_partie_2_firstbloc:
            'without paying an indemnity. This option cannot be carried forward from one year of the term to the next and does not apply when the loan is repaid in full.',
        legal_remboursements_partiels_desc_partie_2_secondbloc:
            'without penalty. This option cannot be carried forward from one year of the term to the next and does not apply when the loan is repaid in full.',
        legal_remboursements_partiels_desc_paragraphe_2:
            'You may prepay more than the 15%, subject to the payment of an indemnity on the amount exceeding the 15%, calculated as explained in the following paragraph. You must then continue to make the scheduled payments as stated in the renewal notice and loan agreement.',
        // LEGAL - REMBOURSEMENT TOTAL
        legal_remboursement_total_title: 'Full loan prepayment',
        legal_remboursement_total_desc_partie_1:
            'You may prepay your loan in full at any time subject to the payment of an indemnity equal to the ',
        legal_remboursement_total_desc_partie_bold:
            'higher of the following two amounts:',
        legal_remboursement_total_desc_partie_2: '',
        legal_remboursement_total_desc_partie_alternative_paragraphe_1:
            'You may prepay your loan in full at any time subject to the payment of an indemnity equal to three months’ interest on the prepayment amount, at the loan rate.',
        legal_remboursement_total_desc_partie_alternative_paragraphe_2:
            'If the prepayment is made less than three months before the end of the term, the indemnity will be equal to the interest calculated on the prepayment amount for the remainder of the term, at the loan rate.',
        legal_remboursement_total_desc_section_a_partie_1: 'a) ',
        legal_remboursement_total_desc_section_a_partie_bold_1:
            'An amount equal to three months’ interest ',
        legal_remboursement_total_desc_section_a_partie_2:
            'on the prepayment amount, at the loan’s interest rate; ',
        legal_remboursement_total_desc_section_a_partie_bold_2: 'or',
        legal_remboursement_total_desc_section_b_partie_1: 'b) ',
        legal_remboursement_total_desc_section_b_partie_bold:
            'An amount equal to ',
        legal_remboursement_total_desc_section_b_partie_2:
            'the interest calculated on the prepayment amount, to the end of the term, at an interest rate equal to the difference between the following two rates:',
        legal_remboursement_total_desc_section_b_partie_2_section_1:
            'i) The loan rate; and',
        legal_remboursement_total_desc_section_b_partie_2_section_2_partie_1:
            'ii) The interest rate recommended at that time by the Fédération des caisses Desjardins du Québec to its affiliated caisses for a closed fixed-rate residential mortgage loan with the term closest to the actual remaining term as published on the Desjardins website and available at any caisse, less a percentage equal to the loan rate reduction if a rate reduction is included in the renewal notice and loan agreement.',
        legal_remboursement_total_desc_section_b_partie_2_section_2_partie_1_bold:
            '',
        legal_remboursement_total_desc_section_b_partie_2_section_2_partie_2:
            '',
        legal_remboursement_total_taux_variable_title:
            'If you selected a reduced variable-rate loan:',
        legal_remboursement_total_taux_variable_desc_partie_1:
            '(For examples of how the penalties in the paragraphs below are calculated, go to ',
        legal_remboursement_total_taux_variable_desc_partie_2: '.)',
        // LEGAL - LOI SUR INTERET
        legal_loi_interet_title: 'INTEREST ACT',
        legal_loi_interet_desc:
            'Under section 10 of the Interest Act, you agree that the date of the hypothec shall be deemed the date of the renewal notice and loan agreement.',
        legal_imputation_paiements_title: 'IMPUTATION OF PAYMENT',
        legal_imputation_paiements_desc:
            'Any loan payments received by the caisse with respect of the loan, whether a scheduled payment, prepayment, realization of security or from any other source, shall first be applied to interest, starting with the oldest, as well as to the cost of any life or disability insurance, and finally to the principal.',
        legal_avis_divulgation_title: 'DISCLOSURE NOTICE OF INCENTIVE PAYMENT',
        legal_avis_divulgation_desc:
            'Your advisor or representative may receive an incentive payment by recommending or selling you products manufactured and/or distributed by Desjardins Group. This is common practice in the industry. Incentive payment programs are based on multiple criteria and could provide a financial advantage to your advisor or representative. The caisse and your advisor or representative have an obligation to ensure that the recommendations made to you and any transactions you engage in are appropriate for you.',
        legal_consentement_title:
            'YOUR CONSENT TO CERTAIN TRANSACTIONS AND SHARING OF YOUR PERSONAL INFORMATION',
        legal_consentement_element_1_paragraphe_1:
            '1. You consent to the Fédération des caisses Desjardins du Québec or the caisse selling, transferring, syndicating, securitizing, hypothecating, insuring or carrying out any necessary transaction with respect to your loan. The caisse continues in all cases to be responsible for the management of your loan, unless we advise you otherwise.',
        legal_consentement_element_1_paragraphe_2:
            'These transactions allow us, for instance, to :',
        legal_consentement_element_1_element_liste_1:
            'Obtain the cash flow needed to grant other loans',
        legal_consentement_element_1_element_liste_2:
            'Diversify our sources of financing',
        legal_consentement_element_1_element_liste_3:
            'Better manage our financial risk',
        legal_consentement_element_1_element_liste_4:
            'Respect our regulatory obligations.',
        legal_consentement_element_1_paragraphe_3:
            'These transactions have no impact on your mortgage, interest rate or repayment conditions.',
        legal_consentement_element_1_paragraphe_4:
            'We continue to be responsible for managing your loan and you must continue to deal with us for any request related to your loan, unless we advise you otherwise. We can also name a custodian, a representative or an administrator for your loan. None of these actors would have any liability toward you.',
        legal_consentement_element_2_paragraphe_1:
            '2. When a member’s loan is insured with mortgage loan insurance, the caisse must respect certain conditions.',
        legal_consentement_element_2_conditions_autres_financements_title:
            'Obligations when there is additional financing other than the insured loan (credit increase, new loans, etc.)',
        legal_consentement_element_2_conditions_autres_prets_desc:
            'As long as the insured loan and additional financing are secured by the same hypothecary security deed, the additional financing must be considered to be a distinct loan from the insured loan. If you default, the insured loan will have priority over any additional financing with respect to payment, collection, execution and realization.',
        legal_consentement_element_2_pret_assure_titrise_title:
            'Obligations when an insured loan is securitized',
        legal_consentement_element_2_pret_assure_titrise_paragraphe_1:
            'As long as the insured loan and additional financing are secured by the same hypothecary security deed, and as long as the insured loan is securitized in a CMHC program, the caisse must make sure that neither the mortgage loan insurer nor the CMHC do not lose their security on the insured loan. The caisse must therefore, for the benefit of the mortgage insurer and the CMHC, refrain from exercising its mortgage security with respect to any uninsured loan and must refrain from exercising any right of consolidation, cross-collateralization or cross default with respect to any uninsured loan as long as the insured loan is covered by a CMHC securitization program.',
        legal_consentement_element_2_pret_assure_titrise_paragraphe_2:
            'However, the caisse reserves all its rights and recourses with respect to the loans and you are not in any way released from your obligations with respect to the insured and uninsured loans.',
        legal_consentement_element_3_paragraphe_1:
            '3. You consent to the collection, use and sharing of your personal information, and any information on your credit rating or financial situation by the following people, within the limits allowed by law :',
        legal_consentement_element_3_element_liste_1:
            'The Fédération des caisses Desjardins du Québec',
        legal_consentement_element_3_element_liste_2: 'Your caisse',
        legal_consentement_element_3_element_liste_3:
            'Any administrator of a securitization program',
        legal_consentement_element_3_element_liste_4:
            'Any entity or person involved in transactions with respect to your loan, or who is considering being involved in such a transaction',
        legal_consentement_element_3_element_liste_5:
            'The mortgage loan insurer (the Canada Mortgage and Housing Corporation (CMHC) or any other insurer)',
        legal_consentement_element_3_element_liste_6:
            'Personal information agents (such as Equifax), even if   your file with that agent is closed or inactive',
        legal_consentement_element_3_element_liste_7:
            'Any person who handles the management or collection of the loan',
        legal_consentement_element_3_element_liste_8:
            'Any government authority with jurisdiction over one of the authorized persons or entities or its activities',
        legal_frais_title: 'FEES',
        legal_frais_desc:
            'This renewal may be subject to administration fees. Any applicable administration fees will be indicated in the renewal notice and loan agreement. By signing the agreement, you authorize the caisse to debit these fees from your account.',
        legal_preseance_title: 'PRECEDENCE',
        legal_preseance_desc:
            'In the event of any discrepancy or conflict between this text and the text of the renewal notice and loan agreement that will be provided for your signature, the provisions of the renewal notice and loan agreement shall prevail.',
        // -------------------------------------------------------------------- HERE
        // VERSION ONTARIO
        legal_ontario_general:
            'The terms and conditions of your loan will be amended as described in the Mortgage Renewal or Amendment Agreement that will be provided for your signature. With the exception of these amendments, the initial agreement and any collateral agreements will continue to apply, without novation, and your financial institution (the “caisse”) will retain its mortgage on your property.',
        // LEGAL - INTERET - PARTIE TAUX FIXE
        legal_ontario_interet_tauxfixe_desc_partie_1:
            'Commencing on the date indicated in the Mortgage Renewal or Amendment agreement, the loan shall bear interest, both before and after the due date, at the annual rate indicated in the renewal terms and conditions of the offer you selected ',
        legal_ontario_interet_tauxfixe_desc_partie_bold: '(the “loan rate”).',
        legal_ontario_interet_tauxfixe_desc_partie_2:
            ' This rate will be calculated semi-annually and not in advance.',
        legal_ontario_interet_tauxfixe_taux_interet_partie_texte:
            'You acknowledge that the loan rate includes a rate reduction for the duration of the term. The annual rate equivalent to this rate reduction will be indicated in the Mortgage Renewal or Amendment agreement. If the loan is prepaid in part or in full, the interest saved as a result of this reduction must be repaid in proportion to the time remaining in the term. (See Prepayment section below.)',
        // LEGAL - INTERET - PARTIE TAUX VARIABLE
        legal_ontario_interet_tauxvariable_title: 'Interest rate reduction:',
        legal_ontario_interet_tauxvariable_paragraph_1_partie_1:
            'Commencing on the date indicated in the Mortgage Renewal or Amendment agreement, the variable interest rate on the loan, both before and after the due date, will be the Desjardins prime rate plus or less the annual rate indicated in the renewal terms and conditions ',
        legal_ontario_interet_tauxvariable_paragraph_1_partie_bold:
            '(the “loan rate”). ',
        legal_ontario_interet_tauxvariable_paragraph_1_partie_2:
            ' The loan rate shall vary according to each change in the Desjardins prime rate and shall be calculated monthly and not in advance.',
        legal_ontario_interet_tauxvariable_paragraph_2:
            'The “Desjardins prime rate” is the interest rate that the Fédération des caisses Desjardins du Québec sets from time to time as its prime rate. It is available on the Desjardins website and at any caisse. The Desjardins prime rate in effect on the renewal date will be indicated in the Mortgage Renewal or Amendment agreement.',
        // LEGAL - REMBOURSEMENT
        legal_ontario_remboursement_desc:
            'You agree to repay the balance of the loan, without the possibility of set-off or compensation, by way of regular payments in principal and interest in accordance with the payment frequency and term set out in the Mortgage Renewal or Amendment agreement. The full amount of the loan shall become payable on the term end date. Based on these terms of repayment, the loan amortization period is the period indicated in the renewal terms and conditions of the offer you selected.',
        // LEGAL - REMBOURSEMENT PAR ANTICIPATION
        legal_ontario_remboursement_total_desc_section_b_partie_2_section_2_partie_1:
            'ii) The interest rate recommended at that time by the Fédération des caisses Desjardins du Québec to its affiliated caisses for a closed fixed-rate residential mortgage loan with the term closest to the actual remaining term as published on the Desjardins website and available at any caisse, less a percentage equal to the loan rate reduction if a rate reduction is included in the Mortgage Renewal or Amendment agreement.',
        legal_ontario_remboursement_total_desc_section_b_partie_2_section_2_partie_2:
            '',
        // LEGAL - PRET AUTRE PREMIER RANG
        legal_ontario_pretnonpremierrang_remboursement_title:
            'POUR UN PRÊT HYPOTHÉCAIRE AUTRE QUE DE PREMIER RANG À UN « CONSOMMATEUR » :',
        legal_ontario_pretnonpremierrang_remboursement_desc:
            'Si vous procédez au renouvellement d’un prêt hypothécaire autre que de premier rang, le taux de crédit et les frais de crédit pour toute la durée du contrat (terme) apparaîtront sur le préavis et contrat de renouvellement.',
        // LEGAL - AUGMENTATION PAIEMENTS PERIODIQUES
        legal_ontario_augmentation_paiements_periodiques_desc_partie_1:
            'Once per calendar year, you may increase your scheduled payments up to twice the payment amount without paying an indemnity. You may subsequently notify the caisse that you wish to reduce your payments to no lower than the amount stated in the Mortgage Renewal or Amendmentagreement. (For examples, go to ',
        // LEGAL - REMBOURSEMENTS PARTIELS
        legal_ontario_remboursements_partiels_desc_paragraphe_2:
            'You may prepay more than the 15%, subject to the payment of an indemnity on the amount exceeding the 15%, calculated as explained in the following paragraph. You must then continue to make the scheduled payments as stated in the Mortgage Renewal or Amendment agreement.',
        // LEGAL - LOI SUR INTERET
        legal_ontario_loi_interet_desc:
            'Under section 10 of the Interest Act, you agree that the date of the hypothec shall be deemed the date of the Mortgage Renewal or Amendment agreement.',
        // LEGAL - CONSENT
        legal_ontario_consentement_element_1_paragraphe_1:
            '1.	You consent to the caisse or the Fédération des caisses Desjardins du Québec selling, transferring, syndicating, securitizing, hypothecating, insuring or carrying out any necessary transaction with respect to your loan. The caisse continues in all cases to be responsible for the management of your loan, unless we advise you otherwise.',
        legal_ontario_consentement_element_3_element_liste_1: 'Your caisse',
        legal_ontario_consentement_element_3_element_liste_2:
            'The Fédération des caisses Desjardins du Québec',
        // LEGAL - FRAIS
        legal_ontario_frais_desc:
            'This renewal may be subject to administration fees. Any applicable administration fees will be indicated in the Mortgage Renewal or Amendment agreement. By signing the agreement, you authorize the caisse to debit these fees from your account.',
        // LEGAL - PRESENCE
        legal_ontario_preseance_desc:
            'In the event of any discrepancy or conflict between this text and the text of the Mortgage Renewal or Amendment agreement that will be provided for your signature, the provisions of the Mortgage Renewal or Amendment agreement shall prevail.',
        // LEGAL - BUTTONS
        quitter: 'Exit',
        click2call_aria: 'This link will open in a new window.'
    },
    fr: {
        // LEGAL
        legal_general_title:
            'CONDITIONS DE L’OFFRE DE RENOUVELLEMENT HYPOTHÉCAIRE',
        legal_general_partie_1:
            'Les modalités de remboursement de votre prêt seront modifiées de la façon décrite au préavis et contrat de renouvellement qui vous sera transmis pour signature (le « ',
        legal_general_partie_bold:
            'préavis et contrat de renouvellement hypothécaire',
        legal_general_partie_2:
            ' »). Sauf ces modifications, le contrat initial et toute convention accessoire continueront de s’appliquer, sans novation, et la caisse conservera son hypothèque sur votre immeuble.',
        // LEGAL - INTERET
        legal_interet_title: 'INTÉRÊT',
        // LEGAL - INTERET - PARTIE TAUX FIXE
        legal_interet_tauxfixe_title:
            'Si vous avez sélectionné le prêt à taux fixe :',
        legal_interet_tauxfixe_desc_partie_1:
            'À compter de la date qui vous sera indiquée sur le préavis et contrat de renouvellement, le prêt portera intérêt, avant comme après échéance, au taux indiqué dans les modalités de renouvellement de l’offre que vous avez sélectionnée, ce taux étant un taux annuel calculé semestriellement et non à l’avance (le « ',
        legal_interet_tauxfixe_desc_partie_bold: 'taux du prêt',
        legal_interet_tauxfixe_desc_partie_2: ' »).',
        legal_interet_tauxfixe_taux_interet_partie_bold:
            'Réduction de taux d’intérêt : ',
        legal_interet_tauxfixe_taux_interet_partie_texte:
            'Vous reconnaissez que le taux du prêt comporte une réduction de taux pour la durée du terme. Le taux annuel équivalent à cette réduction de taux sera indiqué sur le préavis et contrat de renouvellement. L’économie d’intérêt réalisée en raison de cette réduction sera remboursable proportionnellement à la durée restante du terme si le prêt est remboursé par anticipation, en partie ou en totalité. (Voir le sujet Remboursement par anticipation ci-dessous.)',
        // LEGAL - INTERET - PARTIE TAUX VARIABLE
        legal_interet_tauxvariable_title:
            'Si vous avez sélectionné le prêt à taux variable réduit :',
        legal_interet_tauxvariable_paragraph_1_partie_1:
            'À compter de la date qui vous sera indiquée sur le préavis et contrat de renouvellement, le prêt portera intérêt, avant comme après échéance, à un taux variable correspondant au taux préférentiel Desjardins, soit en y ajoutant ou en y soustrayant le taux annuel indiqué dans les modalités de renouvellement. Le taux du prêt variera à chaque modification du taux préférentiel Desjardins et sera calculé mensuellement et non à l’avance (le « ',
        legal_interet_tauxvariable_paragraph_1_partie_bold: 'taux du prêt',
        legal_interet_tauxvariable_paragraph_1_partie_2: ' »).',
        legal_interet_tauxvariable_paragraph_2:
            'Le « taux préférentiel Desjardins » est le taux d’intérêt que la Fédération des caisses Desjardins du Québec établit de temps à autre comme étant son taux préférentiel et rend disponible sur le site Internet de Desjardins et auprès de toute caisse. Le taux préférentiel Desjardins en vigueur à la date du renouvellement sera indiqué sur le préavis et contrat de renouvellement.',
        legal_interet_tauxvariable_paragraph_3:
            'Si, en raison d’une variation du taux du prêt, le montant d’un paiement en capital et intérêts est inférieur aux intérêts courus, les intérêts impayés seront capitalisés et porteront eux-mêmes intérêt au taux du prêt.',
        legal_interet_tauxvariable_paragraph_4_bold:
            'Conversion en taux fixe « fermé »',
        legal_interet_tauxvariable_paragraph_4_texte:
            ' : Vous pouvez en tout temps prendre entente avec la caisse afin de convertir le taux du prêt en un taux fixe « fermé » pour le reste du terme, et ce, sans indemnité. Une convention comportant les nouvelles modalités de remboursement devra alors être signée.',
        // LEGAL - REMBOURSEMENT
        legal_remboursement_title: 'REMBOURSEMENT',
        legal_remboursement_desc:
            'Le solde du prêt est remboursable au moyen de paiements réguliers, en capital et intérêts, que vous vous engagez à verser, sans possibilité de compensation, selon la fréquence et pendant la durée prévue au préavis et contrat de renouvellement. À la date de fin du terme prévue, toute somme due à l’égard du prêt deviendra exigible. En fonction de ces modalités de remboursement, la période d’amortissement du prêt est celle indiquée aux modalités de renouvellement de l’offre que vous avez sélectionnée.',
        // LEGAL - PRET AUTRE PREMIER RANG
        legal_pretnonpremierrang_remboursement_title:
            'POUR UN PRÊT HYPOTHÉCAIRE AUTRE QUE DE PREMIER RANG À UN « CONSOMMATEUR » :',
        legal_pretnonpremierrang_remboursement_desc:
            'Si vous procédez au renouvellement d’un prêt hypothécaire autre que de premier rang, le taux de crédit et les frais de crédit pour toute la durée du contrat (terme) apparaîtront sur le préavis et contrat de renouvellement.',
        // LEGAL - AUGMENTATION PAIEMENTS PERIODIQUES
        legal_augmentation_paiements_periodiques_title:
            'AUGMENTATION DES PAIEMENTS PÉRIODIQUES',
        legal_augmentation_paiements_periodiques_desc_partie_1:
            'Une fois par année civile et sans payer d’indemnité, vous pourrez augmenter, jusqu’à concurrence du double, le montant des paiements. Sur avis subséquent de votre part, la caisse réduira les paiements jusqu’à concurrence du montant convenu dans le préavis et contrat de renouvellement. (Vous trouverez des exemples d’application sur le site Internet de Desjardins à la section ',
        legal_lien_gerer_pret: 'https://www.desjardins.com/gererpret',
        legal_augmentation_paiements_periodiques_desc_partie_2: '.)',
        // LEGAL - REMBOURSEMENT PAR ANTICIPATION
        legal_remboursement_anticipation_title:
            'REMBOURSEMENT PAR ANTICIPATION',
        legal_remboursement_anticipation_subtitle:
            'Si vous avez sélectionné le prêt à taux fixe fermé :',
        legal_remboursement_anticipation_desc_partie_1:
            '(Vous trouverez des exemples de calcul relatifs aux indemnités prévues aux paragraphes qui suivent sur le site Internet de Desjardins à la section ',
        legal_remboursement_anticipation_desc_partie_2: '.)',
        // LEGAL - REMBOURSEMENTS PARTIELS
        legal_remboursements_partiels_title: 'Remboursements partiels du prêt',
        legal_remboursements_partiels_desc_partie_1:
            'Au cours de chaque année civile et sans payer d’indemnité, vous pourrez, au moyen de versements d’au moins 100 $, rembourser par anticipation un maximum de 15% ',
        legal_remboursements_partiels_desc_partie_bold:
            'du montant initial du prêt',
        legal_remboursements_partiels_desc_partie_2_firstbloc:
            '. Ce privilège ne s’accumule pas d’une année à l’autre durant le terme et n’est pas applicable au remboursement total du prêt.',
        legal_remboursements_partiels_desc_partie_2_secondbloc:
            '. Ce privilège ne s’accumule pas d’une année à l’autre durant le terme et n’est pas applicable au remboursement total du prêt.',
        legal_remboursements_partiels_desc_paragraphe_2:
            'Vous pouvez rembourser plus que ce 15 %, mais devrez alors payer une indemnité calculée de la façon prévue au paragraphe qui suit sur le montant excédentaire versé. Vous effectuerez par la suite les paiements prévus dans le préavis et contrat de renouvellement.',
        // LEGAL - REMBOURSEMENT TOTAL
        legal_remboursement_total_title: 'Remboursement total du prêt',
        legal_remboursement_total_desc_partie_1:
            'Vous pourrez en tout temps rembourser par anticipation le prêt au complet, étant entendu que vous devrez alors payer une indemnité égale ',
        legal_remboursement_total_desc_partie_bold:
            'au plus élevé des deux montants ',
        legal_remboursement_total_desc_partie_2: 'suivants:',
        legal_remboursement_total_desc_partie_alternative_paragraphe_1:
            'Vous pourrez en tout temps rembourser par anticipation le prêt au complet, étant entendu que vous devrez alors payer une indemnité égale à trois mois d’intérêt calculé sur le montant remboursé, au taux du prêt.',
        legal_remboursement_total_desc_partie_alternative_paragraphe_2:
            'Si le remboursement par anticipation est effectué moins de trois mois avant la fin du terme du prêt, l’indemnité à payer est calculée sur le montant remboursé par anticipation pour la durée restante du terme, au taux du prêt.',
        legal_remboursement_total_desc_section_a_partie_1:
            'a) Un montant égal à ',
        legal_remboursement_total_desc_section_a_partie_bold_1:
            'trois mois d’intérêt ',
        legal_remboursement_total_desc_section_a_partie_2:
            'calculé sur le montant remboursé, au taux d’intérêt du prêt; ',
        legal_remboursement_total_desc_section_a_partie_bold_2: 'ou',
        legal_remboursement_total_desc_section_b_partie_1:
            'b) Un montant égal à l’intérêt calculé sur le montant remboursé, jusqu’à la fin du terme, au taux d’intérêt égal à la ',
        legal_remboursement_total_desc_section_b_partie_bold:
            'différence entre les deux taux ',
        legal_remboursement_total_desc_section_b_partie_2: 'suivants:',
        legal_remboursement_total_desc_section_b_partie_2_section_1:
            'i) Le taux du prêt; et ',
        legal_remboursement_total_desc_section_b_partie_2_section_2_partie_1:
            'ii) Le taux d’intérêt recommandé à ce moment par la Fédération des caisses Desjardins du Québec à ses caisses affiliées pour un prêt hypothécaire résidentiel à taux fixe fermé, dont le terme se rapproche le plus de la durée restante du terme actuel (le « ',
        legal_remboursement_total_desc_section_b_partie_2_section_2_partie_1_bold:
            'taux de comparaison ',
        legal_remboursement_total_desc_section_b_partie_2_section_2_partie_2:
            '») et publié sur le site Internet de Desjardins et auprès de toute caisse, réduit d’un pourcentage égal à la réduction du taux du prêt si une telle réduction est prévue au préavis et contrat de renouvellement.',
        legal_remboursement_total_taux_variable_title:
            'Si vous avez sélectionné le prêt à taux variable réduit :',
        legal_remboursement_total_taux_variable_desc_partie_1:
            '(Vous trouverez des exemples de calcul relatifs aux indemnités prévues aux paragraphes qui suivent sur le site Internet de Desjardins à la section ',
        legal_remboursement_total_taux_variable_desc_partie_2: '.)',
        // LEGAL - LOI SUR INTERET
        legal_loi_interet_title: 'LOI SUR L’INTÉRÊT',
        legal_loi_interet_desc:
            'Aux fins de l’article 10 de la Loi sur l’intérêt, vous convenez que la date de l’hypothèque sera réputée être la date du préavis et contrat de renouvellement.',
        legal_imputation_paiements_title: 'IMPUTATION DES PAIEMENTS',
        legal_imputation_paiements_desc:
            'Toute somme perçue par la caisse à titre de remboursement du prêt, qu’elle provienne d’un paiement périodique, d’un remboursement par anticipation, de la réalisation d’une sûreté ou de toute autre source, sera d’abord imputée aux intérêts, en commençant par les plus anciens, ainsi qu’au coût de l’assurance vie et de l’assurance invalidité, le cas échéant, et ensuite au remboursement du capital.',
        legal_avis_divulgation_title:
            'AVIS DE DIVULGATION CONCERNANT LA RÉMUNÉRATION INCITATIVE',
        legal_avis_divulgation_desc:
            'Votre conseiller ou représentant pourrait recevoir une rémunération incitative en vous offrant ou en vous recommandant des produits manufacturés ou distribués par le Mouvement Desjardins, une pratique courante dans l’industrie. Les programmes de rémunération incitative sont basés sur plusieurs critères et pourraient procurer un intérêt financier à votre conseiller ou représentant en lien avec l’opération proposée. La caisse, votre conseiller ou votre représentant doivent veiller à ce que les recommandations reçues ou les opérations effectuées conviennent à vos besoins.',
        legal_consentement_title:
            'VOTRE CONSENTEMENT À CERTAINES OPÉRATIONS ET AU PARTAGE DE VOS RENSEIGNEMENTS PERSONNELS',
        legal_consentement_element_1_paragraphe_1:
            '1. Vous consentez à ce que la Fédération des caisses Desjardins du Québec ou la caisse vende, cède, syndique, titrise, hypothèque, assure ou effectue toute opération nécessaire à l’égard de votre prêt. La caisse continue malgré tout à être responsable de la gestion de votre prêt, sauf avis contraire de notre part.',
        legal_consentement_element_1_paragraphe_2:
            'Ces opérations nous permettent, entre autres:',
        legal_consentement_element_1_element_liste_1:
            'D’obtenir des liquidités pour octroyer d’autres prêts',
        legal_consentement_element_1_element_liste_2:
            'De diversifier nos sources de financement',
        legal_consentement_element_1_element_liste_3:
            'De mieux gérer nos risques financiers',
        legal_consentement_element_1_element_liste_4:
            'De respecter nos obligations règlementaires.',
        legal_consentement_element_1_paragraphe_3:
            'Ces opérations n’ont aucun impact sur votre hypothèque, votre taux d’intérêt et vos conditions de remboursement. Nous continuons à être responsables de la gestion de votre prêt et vous devez continuer à traiter avec nous pour toute demande liée à votre prêt, sauf avis contraire de notre part.',
        legal_consentement_element_1_paragraphe_4:
            'Nous pouvons aussi désigner un dépositaire, un mandataire ou un administrateur à l’égard de votre prêt. Ces derniers n’auront aucune responsabilité envers vous.',
        legal_consentement_element_2_paragraphe_1:
            '2. Lorsque votre prêt est assuré par une assurance prêt hypothécaire, la caisse doit respecter certaines conditions.',
        legal_consentement_element_2_conditions_autres_financements_title:
            'Conditions à respecter lorsqu’il y a un financement supplémentaire autre que le prêt assuré (augmentation de crédit, nouveaux prêts, etc.)',
        legal_consentement_element_2_conditions_autres_prets_desc:
            'Tant que le prêt assuré et le financement supplémentaire sont garantis par le même acte de garantie hypothécaire, le financement supplémentaire doit être considéré comme un prêt distinct du prêt assuré et, en cas de défaut de votre part, le prêt assuré aura priorité sur tout financement supplémentaire en ce qui a trait au paiement, au recouvrement, à l’exécution et à la réalisation.',
        legal_consentement_element_2_pret_assure_titrise_title:
            'Lorsqu’un prêt assuré est titrisé',
        legal_consentement_element_2_pret_assure_titrise_paragraphe_1:
            'Tant que le prêt assuré et le financement supplémentaire sont garantis par le même acte de garantie hypothécaire et tant que le prêt assuré est titrisé dans un programme de la Société canadienne d’hypothèques et de logement (SCHL), la caisse doit faire en sorte que l’assureur prêt hypothécaire ou la SCHL ne perdent pas leur garantie sur le prêt assuré. La caisse doit donc, au profit de l’assureur hypothécaire et de la SCHL, s’abstenir d’exercer sa garantie hypothécaire à l’égard de tout prêt non assuré et doit s’abstenir d’exercer tout droit de consolidation, de garantie croisée ou de défaut croisé à l’égard de tout prêt non assuré tant que le prêt assuré est compris dans un programme de titrisation de la SCHL.',
        legal_consentement_element_2_pret_assure_titrise_paragraphe_2:
            'Par contre, la caisse conserve tous ses droits et recours à l’égard des prêts et vous n’êtes pas libéré pour autant de vos obligations à l’égard des prêts assurés ou des prêts non assurés.',
        legal_consentement_element_3_paragraphe_1:
            '3. Vous consentez à la cueillette, à l’utilisation et au partage de vos renseignements personnels, et de toute information sur votre solvabilité ou votre situation financière par les personnes suivantes, dans les limites permises par la loi :',
        legal_consentement_element_3_element_liste_1:
            'La Fédération des caisses Desjardins du Québec',
        legal_consentement_element_3_element_liste_2: 'Votre caisse',
        legal_consentement_element_3_element_liste_3:
            'Tout administrateur d’un programme de titrisation',
        legal_consentement_element_3_element_liste_4:
            'Toute entité ou personne impliquée dans des opérations à l’égard de votre prêt, ou qui envisage de s’impliquer dans une telle opération',
        legal_consentement_element_3_element_liste_5:
            'L’assureur prêt hypothécaire (la SCHL, ou tout autre assureur)',
        legal_consentement_element_3_element_liste_6:
            'L’agent de renseignements personnels (ex. Equifax), même si votre dossier est fermé ou inactif auprès de cet agent',
        legal_consentement_element_3_element_liste_7:
            'Toute personne qui s’occupe de la gestion ou du recouvrement du prêt',
        legal_consentement_element_3_element_liste_8:
            'Toute autorité gouvernementale ayant compétence sur l’une des personnes autorisées ou ses activités',
        legal_frais_title: 'FRAIS',
        legal_frais_desc:
            'Ce renouvellement peut être assujetti à des frais d’administration. Le cas échéant, les frais d’administration applicables seront indiqués au préavis et contrat de renouvellement. Par votre signature dudit préavis et contrat, vous autoriserez la caisse à les débiter de votre compte.',
        legal_preseance_title: 'PRÉSÉANCE',
        legal_preseance_desc:
            'En cas de divergence entre le présent texte et le texte du préavis et contrat de renouvellement qui vous sera transmis pour signature, celui du préavis et contrat de renouvellement prévaut.',
        // VERSION ONTARIO
        legal_ontario_general:
            'Les modalités de remboursement de votre prêt seront modifiées de la façon décrite à la Convention de modification ou de renouvellement qui vous sera transmise pour signature. Sauf ces modifications, le contrat initial et toute convention accessoire continueront de s’appliquer, sans novation, et votre institution financière (la « caisse ») conservera son hypothèque sur votre immeuble.',
        // LEGAL - INTERET - PARTIE TAUX FIXE
        legal_ontario_interet_tauxfixe_desc_partie_1:
            'À compter de la date qui vous sera indiquée sur la Convention de modification ou de renouvellement, le prêt portera intérêt, avant comme après échéance, au taux indiqué dans les modalités de renouvellement de l’offre que vous avez sélectionnée, ce taux étant un taux annuel calculé semestriellement et non à l’avance (le « ',
        legal_ontario_interet_tauxfixe_desc_partie_bold: 'taux du prêt',
        legal_ontario_interet_tauxfixe_desc_partie_2: ' »).',
        legal_ontario_interet_tauxfixe_taux_interet_partie_texte:
            'Vous reconnaissez que le taux du prêt comporte une réduction de taux pour la durée du terme. Le taux annuel équivalent à cette réduction de taux sera indiqué sur la Convention de modification ou de renouvellement. L’économie d’intérêt réalisée en raison de cette réduction sera remboursable proportionnellement à la durée restante du terme si le prêt est remboursé par anticipation, en partie ou en totalité. (Voir le sujet Remboursement par anticipation ci-dessous.)',
        // LEGAL - INTERET - PARTIE TAUX VARIABLE
        legal_ontario_interet_tauxvariable_paragraph_1_partie_1:
            'À compter de la date qui vous sera indiquée sur la Convention de modification ou de renouvellement, le prêt portera intérêt, avant comme après échéance, à un taux variable correspondant au taux préférentiel Desjardins, soit en y ajoutant ou en y soustrayant le taux annuel indiqué dans les modalités de renouvellement. Le taux du prêt variera à chaque modification du taux préférentiel Desjardins et sera calculé mensuellement et non à l’avance (le « ',
        legal_ontario_interet_tauxvariable_paragraph_1_partie_bold:
            'taux du prêt',
        legal_ontario_interet_tauxvariable_paragraph_1_partie_2: ' »).',
        legal_ontario_interet_tauxvariable_paragraph_2:
            'Le « taux préférentiel Desjardins » est le taux d’intérêt que la Fédération des caisses Desjardins du Québec établit de temps à autre comme étant son taux préférentiel et rend disponible sur le site Internet de Desjardins et auprès de toute caisse. Le taux préférentiel Desjardins en vigueur à la date du renouvellement sera indiqué sur la Convention de modification ou de renouvellement.',
        // LEGAL - REMBOURSEMENT
        legal_ontario_remboursement_desc:
            'Le solde du prêt est remboursable au moyen de paiements réguliers, en capital et intérêts, que vous vous engagez à verser, sans possibilité de compensation, selon la fréquence et pendant la durée prévue à la Convention de modification ou de renouvellement. À la date de fin du terme prévue, toute somme due à l’égard du prêt deviendra exigible. En fonction de ces modalités de remboursement, la période d’amortissement du prêt est celle indiquée aux modalités de renouvellement de l’offre que vous avez sélectionnée.',
        // LEGAL - REMBOURSEMENT PAR ANTICIPATION
        legal_ontario_remboursement_total_desc_section_b_partie_2_section_2_partie_1:
            'ii) Le taux d’intérêt recommandé à ce moment par la Fédération des caisses Desjardins du Québec à ses caisses affiliées pour un prêt hypothécaire résidentiel à taux fixe fermé, dont le terme se rapproche le plus de la durée restante du terme actuel (le « ',
        legal_ontario_remboursement_total_desc_section_b_partie_2_section_2_partie_2:
            ' ») et publié sur le site Internet de Desjardins et auprès de toute caisse, réduit d’un pourcentage égal à la réduction du taux du prêt si une telle réduction est prévue à la Convention de modification ou de renouvellement.',
        // LEGAL - PRET AUTRE PREMIER RANG
        legal_ontario_pretnonpremierrang_remboursement_title:
            'POUR UN PRÊT HYPOTHÉCAIRE AUTRE QUE DE PREMIER RANG À UN « CONSOMMATEUR » :',
        legal_ontario_pretnonpremierrang_remboursement_desc:
            'Si vous procédez au renouvellement d’un prêt hypothécaire autre que de premier rang, le taux de crédit et les frais de crédit pour toute la durée du contrat (terme) apparaîtront sur le préavis et contrat de renouvellement.',
        // LEGAL - AUGMENTATION PAIEMENTS PERIODIQUES
        legal_ontario_augmentation_paiements_periodiques_desc_partie_1:
            'Une fois par année civile et sans payer d’indemnité, vous pourrez augmenter, jusqu’à concurrence du double, le montant des paiements. Sur avis subséquent de votre part, la caisse réduira les paiements jusqu’à concurrence du montant convenu dans la Convention de modification ou de renouvellement. (Vous trouverez des exemples d’application sur le site Internet de Desjardins à la section ',
        // LEGAL - REMBOURSEMENTS PARTIELS
        legal_ontario_remboursements_partiels_desc_paragraphe_2:
            'Vous pouvez rembourser plus que ce 15 %, mais devrez alors payer une indemnité calculée de la façon prévue au paragraphe qui suit sur le montant excédentaire versé. Vous effectuerez par la suite les paiements prévus dans la Convention de modification ou de renouvellement.',
        // LEGAL - LOI SUR INTERET
        legal_ontario_loi_interet_desc:
            'Aux fins de l’article 10 de la Loi sur l’intérêt, vous convenez que la date de l’hypothèque sera réputée être la date de la Convention de modification ou de renouvellement.',
        // LEGAL - CONSENT
        legal_ontario_consentement_element_1_paragraphe_1:
            '1. Vous consentez à ce que la Fédération des caisses Desjardins du Québec ou la caisse vende, cède, syndique, titrise, hypothèque, assure ou effectue toute opération nécessaire à l’égard de votre prêt. La caisse continue malgré tout à être responsable de la gestion de votre prêt, sauf avis contraire de notre part.',
        legal_ontario_consentement_element_3_element_liste_1:
            'La Fédération des caisses Desjardins du Québec',
        legal_ontario_consentement_element_3_element_liste_2: 'Votre caisse',
        // LEGAL - FRAIS
        legal_ontario_frais_desc:
            'Ce renouvellement peut être assujetti à des frais d’administration. Le cas échéant, les frais d’administration applicables seront indiqués à la Convention de modification ou de renouvellement. Par votre signature de ladite Convention de modification ou de renouvellement, vous autoriserez la caisse à les débiter de votre compte.',
        // LEGAL - PRESENCE
        legal_ontario_preseance_desc:
            'En cas de divergence entre le présent texte et le texte de la Convention de modification ou de renouvellement qui vous sera transmis pour signature, celui de la Convention de modification ou de renouvellement prévaut.',
        // LEGAL - BUTTONS
        quitter: 'Quitter',
        click2call_aria: 'Ce lien s’ouvrira dans une nouvelle fenêtre.'
    }
};
