import i18n from '../../i18n';

const getUniqueListBy = (arr, key) => {
    // @ts-ignore
    return [...new Map(arr.map((item) => [item[key], item])).values()];
};

export const getFormattedFrequencesOptions = (paiementArr: Array<any>): any => {
    const frequencesArr = paiementArr.reduce((acc, current) => {
        const frequences = current.frequences.map((f) => ({
            idOffre: current.idOffre,
            value: f.frequenceRemboursement,
            label: i18n.t('Modalites:' + f.frequenceRemboursement),
            isAcceleree: f.frequenceRemboursement.includes('ACCELEREE'),
            isCalculer: true,
            paiement: f.frequenceRemboursement.includes('ACCELEREE')
                ? f.paiement
                : f.borneMinimale,
            ...f
        }));
        return acc.concat(frequences);
    }, []);

    return getUniqueListBy(frequencesArr, 'value');
};
