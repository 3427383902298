import React from 'react';
import { Box } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import {
    Grid,
    GridRow,
    GridCol,
    Alert,
    AlertType,
    Typography,
    Variant
} from '../../../styles';

interface IErreurFonctionnelleGenerique {
    isConseiller: boolean;
}

export const ErreurFonctionnelleGenerique: React.FC<
    IErreurFonctionnelleGenerique
> = ({ isConseiller }) => {
    const [t] = useTranslation(['ErreurFonctionnelle']);

    return (
        <Grid>
            <GridRow>
                {/* Header title */}
                <GridCol col="12" sm="12">
                    <Typography variant={Variant.h1}>{t('title')}</Typography>
                </GridCol>
                {/* Alert */}
                <GridCol col="12" sm="12">
                    <Box
                        pb={1}
                        data-testid={'description_erreur_fonctionnelle'}
                    >
                        <Alert type={AlertType.error}>
                            {isConseiller
                                ? t('errorFonctionnelleTextConseiller')
                                : t('errorFonctionnelleText')}
                        </Alert>
                    </Box>
                </GridCol>
            </GridRow>
        </Grid>
    );
};
