import { useState, useEffect } from 'react';
import { AuthentificationService } from '@oel/js-authentification';

import {
    getConfig,
    IMetaTag,
    MetaTagsArray,
    getAccesWebUrl
} from './auth0Config';
import { Logger } from './../../utils';
import { EnvType } from '../../config';

const PATH_PROLONGATION_SESSION_LEGACY =
    '/identifiantunique/api/v1/ping-200.ajax'; // (AW+ADP/ADA)

const CHARGEMENT_SESSION_TERMINE =
    '@authentification/v1/chargement-session-complete';

export function useAuth0(): any {
    const [metaConfig, setMetaConfig] = useState<MetaTagsArray>(null);
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [awStatus, setAwStatus] = useState(null);

    const changeMetaTag = (metaTag: IMetaTag) => {
        document
            .querySelector('meta[name="' + metaTag.name + '"]')
            .setAttribute('content', metaTag.content);
    };

    // Change le "content" des meta-tags (index.html)
    const changeMetaTags = (metaTags: MetaTagsArray) => {
        metaTags.map((meta) => {
            changeMetaTag(meta);
        });
    };

    const onAuth0Loaded = () => {
        Logger.log('CHARGEMENT_SESSION_TERMINE');
        setIsAuthenticated(true);
    };

    const getToken = async () => {
        return await AuthentificationService.getToken().then((token) => token);
    };

    // Initialiser
    const initialiser = (env: EnvType) => {
        const config = getConfig(env);
        // eslint-disable-next-line
        setMetaConfig(config);
    };

    const fermerSession = () => {
        AuthentificationService.fermerSession();
    };

    const pingAw = async (env: EnvType) => {
        const xhr = new XMLHttpRequest();
        const accesWebUrl = getAccesWebUrl(env);
        const urlPing = `${accesWebUrl}${PATH_PROLONGATION_SESSION_LEGACY}`;
        xhr.open('GET', urlPing, true);
        xhr.withCredentials = true;
        xhr.send(null);

        xhr.onload = () => {
            setAwStatus(xhr.status);
        };
    };

    useEffect(() => {
        if (metaConfig) {
            changeMetaTags(metaConfig);
            AuthentificationService.initialiser();
        }
    }, [metaConfig]);

    useEffect(() => {
        window.addEventListener(CHARGEMENT_SESSION_TERMINE, onAuth0Loaded);
    }, []);

    return {
        initialiser,
        fermerSession,
        isAuthenticated,
        getToken,
        awStatus,
        pingAw
    };
}
