export default {
    en: {
        footnoteGroupTitle: 'Note',
        noteLegaleTexteAdmissibleInvalidite:
            'To continue to be considered disabled after the first 24\xa0months, you must be unable to do any paid work and not just your previous position. '
    },
    fr: {
        footnoteGroupTitle: 'Note',
        noteLegaleTexteAdmissibleInvalidite:
            'Après les 24\xa0premiers mois, vous devez être complètement incapable d’occuper un emploi payé, et non pas seulement votre poste précédent. Sinon, nous ne vous considérerons plus comme étant invalide. '
    }
};
