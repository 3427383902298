import { QUERY_PARAM_USER_MODE, URLToURLSearchParams } from '../../../../utils';
import { mockInstance, withDelay } from '../../../../api/axiosInstance.mock';

import { CONFIRMER_OFFRE_END_POINT } from '../../apiEndPoints';
import { CodeAvisEnumModal } from '../../../../components/codeAvis/CodeAvis.utils';
import { IConfirmerOffreResponse } from './useConfirmerOffreApi';
import { defineMockData as defineMockDataSession } from '../../contexte/useSessionApi/useSessionApi.mock';

const data: IConfirmerOffreResponse = {
    codeAvis: null,
    redirectUrl: ''
};

const defineMockData = (useMockParam: string): any => {
    if (!useMockParam) return data;
    switch (useMockParam) {
        case 'coemprunteur1codeAvisSignatureIntegreeEnCours':
            return {
                ...data,
                codeAvis: CodeAvisEnumModal.AVIS_SIGNATURE_EN_COURS
            };
        default:
            return data;
    }
};

export const useConfirmerOffreApiMock = (): any => {
    // var
    const query = URLToURLSearchParams();
    const userMockParam = query.get(QUERY_PARAM_USER_MODE);
    const sessionMockData = defineMockDataSession(userMockParam);

    const endPoint = CONFIRMER_OFFRE_END_POINT.replace(
        /{idDemande}/g,
        String(sessionMockData?.contextePret?.idDemande)
    ).replace(/{idOffre}/g, String('[0-9]+'));

    const url = new RegExp(`${endPoint}`);

    mockInstance
        .onPut(url)
        .reply(withDelay([200, defineMockData(userMockParam)], 200));
};
