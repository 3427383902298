import React from 'react';
import { useTranslation } from 'react-i18next';

import { Progress } from '../../styles';
import { Routes } from '../../hooks';

interface IProgressBarProps {
    subtitle?: string;
    isMartineParcours: boolean;
    isAdmissibleAssurance: boolean;
    isAjoutAssurance: boolean;
    routeCourante: string;
}

const ETAPES_GUY_OU_UNIQUE = {
    [Routes.OFFRES]: {
        currentStep: 1,
        currentStepSansParcoursAssurance: 1,
        i18nKeyTitle: 'pageOffres',
        percentage: 10,
        percentageSansParcoursAssurance: 17
    },
    [Routes.EVEIL_ASSURANCE]: {
        currentStep: 2,
        i18nKeyTitle: 'pageEveilAssurance',
        percentage: 20,
        percentageSansParcoursAssurance: 0
    },
    [Routes.MODALITES]: {
        currentStep: 3,
        currentStepSansParcoursAssurance: 2,
        i18nKeyTitle: 'pageModalites',
        percentage: 60,
        percentageSansParcoursAssurance: 34
    },
    [Routes.MODALITES_DATE_PAIEMENT]: {
        currentStep: 4,
        currentStepSansParcoursAssurance: 3,
        i18nKeyTitle: 'pageModalitesDatePaiement',
        percentage: 70,
        percentageSansParcoursAssurance: 51
    },
    [Routes.VALIDATION]: {
        currentStep: 5,
        currentStepSansParcoursAssurance: 4,
        i18nKeyTitle: 'pageValidation',
        percentage: 80,
        percentageSansParcoursAssurance: 68
    },
    [Routes.INFORMATIONS_SIGNATURE]: {
        currentStep: 6,
        currentStepSansParcoursAssurance: 5,
        i18nKeyTitle: 'pageInfoSignature',
        percentage: 90,
        percentageSansParcoursAssurance: 85
    }
};

const ETAPES_MARTINE = {
    [Routes.VALIDATION]: {
        currentStep: 2,
        currentStepSansParcoursAssurance: 2,
        i18nKeyTitle: 'pageValidation',
        percentage: 20,
        percentageSansParcoursAssurance: 33
    },
    [Routes.INFORMATIONS_SIGNATURE]: {
        currentStep: 3,
        currentStepSansParcoursAssurance: 3,
        i18nKeyTitle: 'pageInfoSignature',
        percentage: 80,
        percentageSansParcoursAssurance: 66
    }
};

export const ProgressBar: React.FC<IProgressBarProps> = ({
    subtitle,
    isMartineParcours,
    isAdmissibleAssurance,
    isAjoutAssurance,
    routeCourante
}) => {
    const [t] = useTranslation(['ProgressBar']);

    const etapeParcours = isMartineParcours
        ? ETAPES_MARTINE[routeCourante]
        : ETAPES_GUY_OU_UNIQUE[routeCourante];

    // Pourcentage.
    // Si Unique / Guy: On regarde si le parcours est admissible
    // Si Martine: On regarde s'il y a eut ajout d'assurance
    let progressPercentage;
    let currentStep;
    if (isMartineParcours) {
        progressPercentage = isAjoutAssurance
            ? etapeParcours?.percentage
            : etapeParcours?.percentageSansParcoursAssurance;
        currentStep = isAjoutAssurance
            ? etapeParcours?.currentStep
            : etapeParcours?.currentStepSansParcoursAssurance;
    } else {
        progressPercentage = isAdmissibleAssurance
            ? etapeParcours?.percentage
            : etapeParcours?.percentageSansParcoursAssurance;
        currentStep = isAdmissibleAssurance
            ? etapeParcours?.currentStep
            : etapeParcours?.currentStepSansParcoursAssurance;
    }

    const progressTitle =
        t('titleRhn') + ' – ' + t(etapeParcours?.i18nKeyTitle || '');

    return (
        <Progress
            title={progressTitle}
            subtitle={subtitle}
            percentage={progressPercentage}
            currentStep={currentStep}
        />
    );
};
