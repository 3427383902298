import {
    END_POINT,
    EmprunteurStatut,
    IAssureResume,
    IRenouvellement,
    IndicateurFumeur,
    ParcoursAssurance,
    Sexe,
    emprunteur
} from './useRenouvellementApi';
import { QUERY_PARAM_USER_MODE, URLToURLSearchParams } from '../../../../utils';
import { mockInstance, withDelay } from '../../../../api';

import { PageInitiale } from '../useSessionApi/useSessionApi';

const dataEmprunteurUniqueAjoutAssurance: Array<emprunteur> = [
    {
        id: 28371,
        prenom: 'UNIQUE',
        nom: 'Mocked',
        courriel: 'testmock@mock.com',
        sexe: '2',
        telephone: '5140001234',
        poste: '',
        cellulaire: '',
        statut: EmprunteurStatut.ACCUEIL,
        dateAcceptation: '20210129',
        connecte: true,
        admissibleAssuranceInvalidite: true,
        admissibleAssuranceVie: true,
        isAjoutAssurance: true
    }
];

const listeAssureEmprunteurUnique: Array<IAssureResume> = [
    {
        prenom: 'UNIQUE',
        nom: 'Mocked',
        codeSexe: Sexe.FEMININ,
        age: 26,
        indicateurFumeur: IndicateurFumeur.NON,
        pctCouvertureAssuranceVie: 100.0,
        pctCouvertureAssuranceInvalidite: 100.0,
        indicateurEligibiliteAssuranceVie: true,
        indicateurEligibiliteAssuranceInvalidite: true
    }
];

const listeAssureCoemprunteurs: Array<IAssureResume> = [
    {
        prenom: 'MARTINE',
        nom: 'Mocked',
        codeSexe: Sexe.FEMININ,
        age: 34,
        indicateurFumeur: IndicateurFumeur.NON,
        pctCouvertureAssuranceVie: 100.0,
        pctCouvertureAssuranceInvalidite: 100.0,
        indicateurEligibiliteAssuranceVie: true,
        indicateurEligibiliteAssuranceInvalidite: true
    },
    {
        prenom: 'GUY',
        nom: 'Mocked',
        codeSexe: Sexe.MASCULIN,
        age: 32,
        indicateurFumeur: IndicateurFumeur.NON,
        pctCouvertureAssuranceVie: 10.0,
        pctCouvertureAssuranceInvalidite: 0.0,
        indicateurEligibiliteAssuranceVie: true,
        indicateurEligibiliteAssuranceInvalidite: true
    }
];

// Export this object for BOTH : mock and testing
export const dataCoEmprunteur1: IRenouvellement = {
    pageInitiale: PageInitiale.ACCUEIL,
    codeAvis: null,
    pretAdmissibleAssurance: false,
    montantTotalInteretRetard: 0,
    emprunteurs: [
        {
            id: 28371,
            prenom: 'MARTINE',
            nom: 'Mocked',
            courriel: 'testmock2@mock.com',
            sexe: '2',
            telephone: '5147774444',
            poste: '',
            cellulaire: '',
            statut: EmprunteurStatut.ACCUEIL,
            dateAcceptation: '20210129',
            connecte: false,
            admissibleAssuranceInvalidite: true,
            admissibleAssuranceVie: true,
            isAjoutAssurance: true
        },
        {
            id: 28093,
            prenom: 'GUY',
            nom: 'Mocked',
            courriel: 'testmock@mock.com',
            sexe: '1',
            telephone: '5147774444',
            poste: '',
            cellulaire: '5140001234',
            statut: EmprunteurStatut.CONFIRMEE,
            dateAcceptation: null,
            connecte: true,
            admissibleAssuranceInvalidite: true,
            admissibleAssuranceVie: true,
            isAjoutAssurance: true
        }
    ],
    assures: listeAssureCoemprunteurs
};

// Export for testing
export const dataCoEmprunteur2: IRenouvellement = {
    pageInitiale: PageInitiale.ACCUEIL,
    codeAvis: null,
    pretAdmissibleAssurance: false,
    parcoursAssurance: null,
    montantTotalInteretRetard: 0,
    emprunteurs: [
        {
            id: 28371,
            prenom: 'MARTINE',
            nom: 'Mocked',
            courriel: 'testmock2@mock.com',
            sexe: '2',
            telephone: '5147774444',
            poste: '',
            cellulaire: '',
            statut: EmprunteurStatut.CONFIRMEE,
            dateAcceptation: null,
            connecte: true,
            admissibleAssuranceInvalidite: true,
            admissibleAssuranceVie: true,
            isAjoutAssurance: false
        },
        {
            id: 28093,
            prenom: 'GUY',
            nom: 'Mocked',
            courriel: 'testmock@mock.com',
            sexe: '1',
            telephone: '5147774444',
            poste: '',
            cellulaire: '5140001234',
            statut: EmprunteurStatut.CONFIRMEE,
            dateAcceptation: '20210129',
            connecte: false,
            admissibleAssuranceInvalidite: true,
            admissibleAssuranceVie: true,
            isAjoutAssurance: false
        }
    ],
    assures: listeAssureCoemprunteurs
};

// Export for testing
export const dataEmprunteurUnique: IRenouvellement = {
    pageInitiale: PageInitiale.ACCUEIL,
    codeAvis: null,
    pretAdmissibleAssurance: false,
    parcoursAssurance: null,
    montantTotalInteretRetard: 0,
    emprunteurs: [
        {
            id: 28371,
            prenom: 'UNIQUE',
            nom: 'Mocked',
            courriel: 'testmock@mock.com',
            sexe: '2',
            telephone: '5140001234',
            poste: '',
            cellulaire: '',
            statut: EmprunteurStatut.ACCUEIL,
            dateAcceptation: '20210129',
            connecte: true,
            admissibleAssuranceInvalidite: true,
            admissibleAssuranceVie: true,
            isAjoutAssurance: false
        }
    ]
};

// Export for testing
export const dataemprunteurUniqueAdmissibleAssuranceParcours1: IRenouvellement =
    {
        ...dataEmprunteurUnique,
        pretAdmissibleAssurance: true,
        parcoursAssurance: ParcoursAssurance.Parcours1
    };

export const dataemprunteurUniqueAdmissibleAssuranceParcours2: IRenouvellement =
    {
        ...dataEmprunteurUnique,
        pretAdmissibleAssurance: true,
        parcoursAssurance: ParcoursAssurance.Parcours2,
        assures: listeAssureEmprunteurUnique
    };

export const dataemprunteurUniqueAjoutAssuranceSignatureIntegreeParcours2: IRenouvellement =
    {
        ...dataEmprunteurUnique,
        pretAdmissibleAssurance: true,
        parcoursAssurance: ParcoursAssurance.Parcours2,
        assures: listeAssureEmprunteurUnique,
        emprunteurs: dataEmprunteurUniqueAjoutAssurance
    };

export const dataemprunteurUniqueAdmissibleAssuranceParcours3: IRenouvellement =
    {
        ...dataEmprunteurUnique,
        pretAdmissibleAssurance: true,
        parcoursAssurance: ParcoursAssurance.Parcours3
    };

export const dataEmprunteurUniqueInteretRetard: IRenouvellement = {
    pageInitiale: PageInitiale.ACCUEIL,
    codeAvis: null,
    pretAdmissibleAssurance: false,
    parcoursAssurance: null,
    montantTotalInteretRetard: 1000.83,
    emprunteurs: [
        {
            id: 28371,
            prenom: 'UNIQUE',
            nom: 'Mocked',
            courriel: 'testmock@mock.com',
            sexe: '2',
            telephone: '5140001234',
            poste: '',
            cellulaire: '',
            statut: EmprunteurStatut.ACCUEIL,
            dateAcceptation: '20210129',
            connecte: true,
            admissibleAssuranceInvalidite: true,
            admissibleAssuranceVie: true,
            isAjoutAssurance: false
        }
    ]
};

export const dataCoEmprunteur2InteretRetard: IRenouvellement = {
    pageInitiale: PageInitiale.OFFRE,
    codeAvis: null,
    pretAdmissibleAssurance: false,
    parcoursAssurance: null,
    montantTotalInteretRetard: 1000,
    emprunteurs: [
        {
            id: 28371,
            prenom: 'MARTINE',
            nom: 'Mocked',
            courriel: 'testmock2@mock.com',
            sexe: '2',
            telephone: '5147774444',
            poste: '',
            cellulaire: '',
            statut: EmprunteurStatut.CONFIRMEE,
            dateAcceptation: null,
            connecte: true,
            admissibleAssuranceInvalidite: true,
            admissibleAssuranceVie: true,
            isAjoutAssurance: false
        },
        {
            id: 28093,
            prenom: 'GUY',
            nom: 'Mocked',
            courriel: 'testmock@mock.com',
            sexe: '1',
            telephone: '5147774444',
            poste: '',
            cellulaire: '5140001234',
            statut: EmprunteurStatut.CONFIRMEE,
            dateAcceptation: '20210129',
            connecte: false,
            admissibleAssuranceInvalidite: true,
            admissibleAssuranceVie: true,
            isAjoutAssurance: false
        }
    ],
    assures: listeAssureCoemprunteurs
};

export const dataErreurTarif = {
    errors: [
        {
            type: 'B',
            code: 'CALCUL_OFFRE',
            message:
                "API OffreTaux: Le numeroEngagment n'existe pas ou n'est pas un engagement de type prÃªt hypothÃ©caire.",
            meta: {
                traceId: '3392f81cbd7c695c'
            }
        }
    ]
};

export const dataErreurTarifAucunDetails = {
    errors: null
};

const defineMockData = (userMockParam: string): any => {
    if (!userMockParam) return dataCoEmprunteur1;
    switch (userMockParam) {
        case 'coemprunteur2':
        case 'coemprunteur2Retard':
        case 'coemprunteur2SignatureIntegree':
        case 'coemprunteur2OntarioSignatureIntegree':
            return dataCoEmprunteur2;
        case 'emprunteurunique':
        case 'emprunteuruniqueontario':
        case 'emprunteuruniqueontarioSignatureIntegree':
            return dataEmprunteurUnique;
        case 'emprunteurUniqueAdmissibleAssuranceParcours1':
            return dataemprunteurUniqueAdmissibleAssuranceParcours1;
        case 'emprunteurUniqueAdmissibleAssuranceParcours2':
            return dataemprunteurUniqueAdmissibleAssuranceParcours2;
        case 'emprunteurUniqueAjoutAssuranceSignatureIntegreeParcours2':
            return dataemprunteurUniqueAjoutAssuranceSignatureIntegreeParcours2;
        case 'emprunteurUniqueAdmissibleAssuranceParcours3':
            return dataemprunteurUniqueAdmissibleAssuranceParcours3;
        case 'emprunteuruniqueInteretRetard':
            return dataEmprunteurUniqueInteretRetard;
        case 'coemprunteur2InteretRetard':
            return dataCoEmprunteur2InteretRetard;
        case 'erreurTarif':
            return dataErreurTarif;
        case 'erreurTarifAucunDetails':
            return dataErreurTarifAucunDetails;
        default:
            return dataCoEmprunteur1;
    }
};

const defineMockStatus = (userMockParam: string): number => {
    if (!userMockParam) return 200;
    switch (userMockParam) {
        case 'erreurTarif':
        case 'erreurTarifAucunDetails':
            return 500;
        default:
            return 200;
    }
};

export const useRenouvellementApiMock = (): any => {
    // var
    const query = URLToURLSearchParams();
    const userMockParam = query.get(QUERY_PARAM_USER_MODE);

    mockInstance
        .onGet(END_POINT)
        .reply(
            withDelay(
                [
                    defineMockStatus(userMockParam),
                    defineMockData(userMockParam)
                ],
                200
            )
        );
};
