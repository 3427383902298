export default {
    en: {
        resumePretTitle: 'Personalized rate',
        resumePretTypeTitle: 'Type of mortgage',
        // Pret variable protege
        resumePretTypeVPTooltip:
            'The Desjardins prime rate may fluctuate and change your personalized rate. Your rate is protected and will never exceed the maximum rate set at the start, no matter how the prime rate fluctuates.',
        resumePretTypeVPTextPart1: ' (maximum rate: ',
        resumePretTypeVPTextPart2: ')',
        // Pret variable réduit
        resumePretTypeVRTooltip:
            'The mortgage rate will vary with each change in the Desjardins prime rate, and the spread will remain fixed for the rest of the term.',
        resumePretTypeTooltipAltText: 'Information',
        resumePretInterestTitle: 'Interest rate',
        resumePretInterestSubTextPart1: ' year 1 (rate revised each year and ',
        resumePretInterestSubTextPart2: '',
        resumePretInterestSubTextPart3: '',
        resumePretInterestSubTextPart4: ' rebate)',
        resumePretTypeVRTextPart1: ' (prime rate: ',
        resumePretTypeVRTextPart2: ')',
        // Taux interet additionnel assurance
        resumePretInsuranceTitleAssurance:
            'Additional interest rate for Loan Insurance',
        resumePretInsuranceTitlePaiementSansAssurance:
            'Payment without insurance ',
        resumePretInsuranceTitleAssuranceMargeAtout:
            'Additional interest rate for Loan Insurance – Versatile Line of Credit',
        resumeChangeButton: 'Edit'
    },
    fr: {
        resumePretTitle: 'Taux personnalisé',
        resumePretTypeTitle: 'Type de prêt',
        // Pret variable protege
        resumePretTypeVPTooltip:
            'Le taux préférentiel Desjardins peut varier et modifier le taux personnalisé. Votre taux est protégé et il ne dépassera jamais le taux maximum établi dès le départ, peu importe les variations du taux préférentiel.',
        resumePretTypeVPTextPart1: ' (taux maximum : ',
        resumePretTypeVPTextPart2: ')',
        // Pret variable réduit
        resumePretTypeVRTooltip:
            "Le taux applicable au prêt variera à chaque modification du taux préférentiel de Desjardins et l'écart de taux restera fixe pour la durée du terme.",
        resumePretTypeTooltipAltText: 'Information',
        resumePretInterestTitle: 'Taux d’intérêt',
        resumePretInterestSubTextPart1: ' 1',
        resumePretInterestSubTextPart2: 're',
        resumePretInterestSubTextPart3:
            ' année (taux révisé chaque année et rabais de ',
        resumePretInterestSubTextPart4: ')',
        resumePretTypeVRTextPart1: ' (taux préférentiel : ',
        resumePretTypeVRTextPart2: ')',
        // Taux interet additionnel assurance
        resumePretInsuranceTitleAssurance:
            'Taux d’intérêt additionnel Assurance prêt',
        resumePretInsuranceTitlePaiementSansAssurance:
            'Paiement sans assurance',
        resumePretInsuranceTitleAssuranceMargeAtout:
            'Taux d’intérêt additionnel Assurance prêt – marge Atout',
        resumeChangeButton: 'Modifier'
    }
};
