import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import { Button, ButtonVariant } from '../../styles';
import { NavigationPlacement, SaveAndLeaveModal } from '../../components';
import { useRoutes, getPageName, goQuitterParcours } from '../../hooks';
import {
    sendEvent,
    EVENT_CATEGORIES,
    EVENT_ACTIONS,
    EVENT_LABELS
} from '../../features';

export const Navigation: React.FC<any> = () => {
    const { getRoute, goToRoute } = useRoutes();
    const dispatch = useDispatch();
    const [t] = useTranslation(['Offres']);

    // Confirmation modal
    const [isModalOpened, setIsModalOpened] = useState(false);

    // Send cyber & leave
    const leaveApp = () => {
        const pageCourante = getPageName();

        if (pageCourante) {
            dispatch(
                sendEvent({
                    category: EVENT_CATEGORIES.RHN,
                    action: EVENT_ACTIONS.CLIC,
                    label: EVENT_LABELS.ENREGISTRER_ET_QUITTER + pageCourante
                })
            );
        }

        goQuitterParcours();
    };

    const handleCancel = () => {
        // Open confirmation modal
        setIsModalOpened(true);
    };

    const handleClicModalClose = (shouldStayHere: boolean) => {
        // Close confirmation modal
        setIsModalOpened(false);
        if (!shouldStayHere) {
            // Send cyber & leave
            leaveApp();
        }
    };

    const handleBack = () => {
        dispatch(
            sendEvent({
                category: EVENT_CATEGORIES.RHN,
                action: EVENT_ACTIONS.CLIC,
                label: EVENT_LABELS.PRECEDENT + getPageName()
            })
        );
        goToRoute(getRoute().prevRoute);
    };

    return (
        <>
            <SaveAndLeaveModal
                isOpen={isModalOpened}
                onNext={() => handleClicModalClose(false)}
                onCancel={() => handleClicModalClose(true)}
            />
            <NavigationPlacement
                renderBackButton={
                    <Button
                        id={'QA_test_navigation_back_button'}
                        variant={ButtonVariant.secondary}
                        dataCy="qa-navigation-button-back"
                        dataTestId={'navigation-back-button'}
                        onClick={handleBack}
                        fullWidth="xs"
                    >
                        {t('back')}
                    </Button>
                }
                renderSaveButton={
                    <Button
                        id={'QA_test_navigation_quit_button'}
                        variant={ButtonVariant.secondary}
                        dataCy="qa-navigation-button-quit"
                        dataTestId={'navigation-quit-button'}
                        onClick={handleCancel}
                        fullWidth="xs"
                    >
                        {t('cancel')}
                    </Button>
                }
            ></NavigationPlacement>
        </>
    );
};
