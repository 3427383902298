import { mockInstance, withDelay } from '../../../../api/axiosInstance.mock';

import { END_POINT } from './useDatesProchainPaiementApi';
import { getDateStr } from '../../../../utils';
import { dataCoEmprunteur1 as sessionMockData } from '../../contexte/useSessionApi/useSessionApi.mock';

const actualDateStr = getDateStr(new Date());
const tomorrowDateStr = getDateStr(
    new Date(new Date().setDate(new Date().getDate() - 16))
);
const nextWeekDateStr = getDateStr(
    new Date(new Date().setDate(new Date().getDate() + 2))
);

/* eslint-disable */
export const data = {
    datesProchainPaiement: [
        {
            dateDernierPaiement: tomorrowDateStr,
            dateMinimale: actualDateStr,
            dateMaximale: nextWeekDateStr,
            frequence: 'HEBDOMADAIRE'
        },
        {
            dateDernierPaiement: tomorrowDateStr,
            dateMinimale: actualDateStr,
            dateMaximale: nextWeekDateStr,
            frequence: 'HEBDOMADAIRE_ACCELEREE'
        },
        {
            dateDernierPaiement: tomorrowDateStr,
            dateMinimale: actualDateStr,
            dateMaximale: nextWeekDateStr,
            frequence: 'AUX_DEUX_SEMAINES'
        },
        {
            dateDernierPaiement: tomorrowDateStr,
            dateMinimale: actualDateStr,
            dateMaximale: nextWeekDateStr,
            frequence: 'AUX_DEUX_SEMAINES_ACCELEREE'
        },
        {
            dateDernierPaiement: null, // Son null parce que aux des semaines est la valeur par défaut dans nos mock
            dateMinimale: null,
            dateMaximale: null,
            frequence: 'MENSUELLE'
        }
    ]
};

const endPoint = END_POINT.replace(
    /{idDemande}/g,
    String(sessionMockData?.contextePret?.idDemande)
);

export const useDatesProchainPaiementApiMock = (): any => {
    mockInstance.onGet(endPoint).reply(withDelay([200, data], 200));
};
