import React from 'react';
import { useDispatch } from 'react-redux';

import { PaiementSelect } from './PaiementSelect';
import {
    sendEvent,
    EVENT_CATEGORIES,
    EVENT_ACTIONS,
    EVENT_LABELS
} from '../../../../features';

const CYBERMETRIE_TOOLTIP_LABEL = 'paiement';

export const PaiementSelectConnected: React.FC<unknown> = () => {
    const dispatch = useDispatch();

    const handleOnTooltipActive = () => {
        dispatch(
            sendEvent({
                category: EVENT_CATEGORIES.RHN,
                action: EVENT_ACTIONS.CLIC,
                label: EVENT_LABELS.INFOBULLE + CYBERMETRIE_TOOLTIP_LABEL
            })
        );
    };

    return <PaiementSelect onTooltipActive={handleOnTooltipActive} />;
};
