export default {
    en: {
        start: 'Get started',
        sommaire: 'Back to AccèsD home',
        title: 'Renew your mortgage',
        today: 'today',
        estimated_time: '10 minutes',
        estimated_time_alt: 'Time needed to fill out the application.',
        warning_text:
            'For a limited time, you can still renew your mortgage. If we don’t hear from you, your mortgage will be renewed as specified in the Mortgage renewal notice you received.',
        warning_text_click2call_prefix: 'For more details, ',
        warning_text_click2call_link: 'contact us',
        warning_text_click2call_aria:
            'This link redirects to another Desjardins site. Opens in a new tab.',
        warning_text_click2call_suffix: '.',
        warning_text_click2call_hs: 'Important',
        consentement_title: 'Consent agreement',
        consentement_notice_1:
            'To offer you a personalized mortgage rate, we consider  ',
        consentement_notice_1_bis_unique:
            'your financial habits as well as the number and type of products you have with Desjardins Group.',
        consentement_notice_1_bis_coemprunteur:
            ' you and your co-borrower’s financial habits as well as the number and type of products you have with Desjardins Group. ',
        consentement_notice_2: 'By clicking ',
        consentement_notice_2_strong: 'Get started',
        consentement_notice_2_bis_unique:
            ', you consent to the sharing and use of this information within Desjardins Group.',
        consentement_notice_2_bis_guy:
            ', you confirm that you and your co-borrower consent to the sharing and use of this information within Desjardins Group.',
        consentement_notice_2_bis_martine:
            ', you understand that this information is used and shared within Desjardins Group.',
        precision_title: 'What to know before you start',
        precision_text_1: 'Fields marked with the * symbol are required.',
        precision_text_2:
            'Your application is automatically saved when you change pages. You can leave and come back to it at any time.',
        renouvellement_actif_unique:
            'Your new choices will come into effect after a few days.',
        renouvellement_actif_guy:
            'Your new choices will come into effect once your co-borrower confirms.'
    },
    fr: {
        start: 'Commencer',
        sommaire: 'Retour à l’accueil',
        title: 'Renouveler votre prêt hypothécaire',
        today: `aujourd'hui`,
        estimated_time: '10 minutes',
        estimated_time_alt: 'Temps pour remplir la demande.',
        warning_text:
            'Pour une durée limitée, vous pouvez encore renouveler votre prêt. Si nous n’avons aucune nouvelle de votre part, votre prêt sera renouvelé conformément à la lettre d’avis de renouvellement que vous avez reçue.',
        warning_text_click2call_prefix: 'Pour obtenir des précisions, ',
        warning_text_click2call_link: 'communiquez avec nous',
        warning_text_click2call_aria:
            'Lien vers un autre site de Desjardins. S’ouvre dans un nouvel onglet.',
        warning_text_click2call_suffix: '.',
        warning_text_click2call_hs: 'Avertissement',
        consentement_title: 'Consentement',
        consentement_notice_1:
            'Pour vous proposer un taux personnalisé, nous tenons compte du comportement financier, du nombre et du type de produits que ',
        consentement_notice_1_bis_unique:
            ' vous détenez au Mouvement Desjardins. ',
        consentement_notice_1_bis_coemprunteur:
            ' votre coemprunteur et vous détenez au Mouvement Desjardins. ',
        consentement_notice_2: 'En cliquant sur ',
        consentement_notice_2_strong: 'Commencer',
        consentement_notice_2_bis_unique:
            ', vous acceptez que ces renseignements soient utilisés et communiqués au sein de celui-ci.',
        consentement_notice_2_bis_guy:
            ', vous confirmez que vous et votre coemprunteur acceptez que ces renseignements soient utilisés et communiqués au sein de celui-ci.',
        consentement_notice_2_bis_martine:
            ', vous comprenez que ces renseignements sont utilisés et communiqués au sein de celui-ci.',
        precision_title: 'Précisions',
        precision_text_1: 'Les champs précédés du symbole * sont obligatoires.',
        precision_text_2:
            'Votre demande s’enregistre quand vous changez de page. Vous pouvez quitter et revenir à tout moment.',
        renouvellement_actif_unique:
            "Vos nouveaux choix s'appliquent dans les jours suivants.",
        renouvellement_actif_guy:
            "Vos nouveaux choix s'appliquent dès la confirmation de votre coemprunteur."
    }
};
