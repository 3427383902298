import { useQuery, UseQueryResult } from 'react-query';

import {
    axiosInstance,
    getDynamicHeaders,
    reactQueryOptions
} from '../../../../api';
import { SOLDE_FIN_DE_TERME_END_POINT } from '../../apiEndPoints';

export type ISoldeFinTerme = {
    soldeFinTerme: number;
    soldeFinTermeSansAssurance: number;
};

const fetch = async (idDemande: number) => {
    const endPoint = SOLDE_FIN_DE_TERME_END_POINT.replace(
        /{idDemande}/g,
        String(idDemande)
    );

    const { data } = await axiosInstance.get(endPoint, getDynamicHeaders());

    return data;
};

export function useSoldeFinTermeApi(
    idDemande: number = undefined,
    enabled: boolean
): UseQueryResult<ISoldeFinTerme, Error> {
    return useQuery<ISoldeFinTerme, Error>(
        ['soldeFinTerme', idDemande],
        () => fetch(idDemande),
        {
            ...reactQueryOptions(),
            enabled: !!idDemande && enabled
        }
    );
}
