import React from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { InfoEconomie } from './InfoEconomie';
import {
    sendEvent,
    EVENT_CATEGORIES,
    EVENT_ACTIONS,
    EVENT_LABELS
} from '../../../../features';

const CYBERMETRIE_TOOLTIP_LABEL = 'économie d’intérêt estimé';

export const InfoEconomieConnected: React.FC<unknown> = () => {
    const dispatch = useDispatch();
    const offreModifiee = useSelector(
        (state: any) => state.modalites.offreModifiee
    );

    const handleOnTooltipActive = () => {
        dispatch(
            sendEvent({
                category: EVENT_CATEGORIES.RHN,
                action: EVENT_ACTIONS.CLIC,
                label: EVENT_LABELS.INFOBULLE + CYBERMETRIE_TOOLTIP_LABEL
            })
        );
    };

    return (
        <InfoEconomie
            value={offreModifiee?.economies?.economieInteret?.montant}
            onTooltipActive={handleOnTooltipActive}
        ></InfoEconomie>
    );
};
