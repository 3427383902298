import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { Box } from '@material-ui/core';

import {
    ContexteAppel,
    getParlerConseillerUrl,
    getFormattedDate
} from '../../utils';
import {
    Lightbox,
    Typography,
    Variant,
    ColorDSD,
    Article,
    Hr,
    Link,
    Spacer,
    Button,
    IconPosition,
    ButtonVariant,
    EnumLinkType
} from '../../styles';

import {
    FrequenceRemboursement,
    getTypePret,
    IAmortissement,
    TypePret
} from '../../hooks';

interface IPretActuelProps {
    dateRappel: string;
    solde: number;
    amortissementRestant: IAmortissement;
    tauxInteret: number;
    dureeTerme: number;
    typePret: TypePret;
    paiement: number;
    idDemande: number;
    frequenceRemboursement: FrequenceRemboursement;
    onOpenModalAnalytic: () => void;
    onParlerConseillerAnalytic: () => void;
}
export const PretActuelModal: React.FC<IPretActuelProps> = ({
    dateRappel,
    solde,
    amortissementRestant,
    tauxInteret,
    dureeTerme,
    typePret,
    paiement,
    idDemande,
    frequenceRemboursement,
    onOpenModalAnalytic,
    onParlerConseillerAnalytic
}) => {
    const [t] = useTranslation(['PretActuelModal']);
    const [isModalOpened, setIsModalOpened] = useState<boolean>(false);

    const getFormattedAmortissement = () => {
        const annee = amortissementRestant?.annee;
        const mois = amortissementRestant?.mois;

        const formattedAnnee = t('Date:years', {
            value: annee
        });
        const formnattedMois = t('Date:months', {
            value: mois
        });

        return mois > 0
            ? formattedAnnee + ' ' + t('and') + ' ' + formnattedMois
            : formattedAnnee;
    };

    const getFormattedDateRappel = () => {
        const date = getFormattedDate(dateRappel, '/');

        return moment(new Date(date)).format('LL');
    };

    // Quand la modale ouvre, on envoie des analytiques
    const handleOpenModal = () => {
        onOpenModalAnalytic();
        setIsModalOpened((prevState) => !prevState);
    };

    return (
        <>
            {/* Bouton de la modale pour ouvrir/fermer */}
            <Box ml="1rem">
                <Button
                    variant={ButtonVariant.tertiary}
                    iconName="navigations_contour_modale"
                    iconPosition={IconPosition.start}
                    onClick={handleOpenModal}
                    dataTestId="bouton_pret_actuel"
                >
                    <Box textAlign="left">{t('boutonsPretActuel')}</Box>
                </Button>
            </Box>

            {/* Modal */}
            {isModalOpened && (
                <Lightbox
                    show={true}
                    title={t('title')}
                    buttonPrimaryLabel={t('buttonText')}
                    onClickPrimaryButton={() => setIsModalOpened(false)}
                    onClose={() => setIsModalOpened(false)}
                >
                    {/* date Renouvellement */}
                    <Typography variant={Variant.p} article={Article.a3}>
                        {t('dateLimiteTitle')}
                        <span style={{ float: 'right' }}>
                            {getFormattedDateRappel()}
                        </span>
                    </Typography>
                    <Typography
                        variant={Variant.p}
                        article={Article.a4}
                        color={ColorDSD.lightSystem3}
                    >
                        {t('dateLimiteText')}
                    </Typography>
                    <Hr></Hr>
                    <Spacer></Spacer>
                    {/* solde */}
                    <Typography variant={Variant.p} article={Article.a3}>
                        {t('soldeTitle')}
                        <span style={{ float: 'right' }}>
                            {t('Number:currency', {
                                value: solde
                            })}
                        </span>
                    </Typography>
                    <Typography
                        variant={Variant.p}
                        article={Article.a4}
                        color={ColorDSD.lightSystem3}
                    >
                        {t('soldeText')}
                    </Typography>
                    <Hr></Hr>
                    <Spacer></Spacer>
                    {/* amortissement Restant */}
                    <Typography variant={Variant.p} article={Article.a3}>
                        {t('amortissementTitle')}
                        <span style={{ float: 'right' }}>
                            {getFormattedAmortissement()}
                        </span>
                    </Typography>
                    <Typography
                        variant={Variant.p}
                        article={Article.a4}
                        color={ColorDSD.lightSystem3}
                    >
                        {t('amortissementText')}
                    </Typography>
                    <Hr></Hr>
                    <Spacer></Spacer>
                    {/* type Pret */}
                    <Typography variant={Variant.p} article={Article.a3}>
                        {t('typePretTitle')}
                        <span style={{ float: 'right' }}>
                            {getTypePret(dureeTerme, typePret, t)}
                        </span>
                    </Typography>
                    <ol>
                        <li className={Article.a4}>
                            <Typography
                                variant={Variant.p}
                                article={Article.a4}
                                color={ColorDSD.lightSystem3}
                            >
                                {t('typePretText1')}
                            </Typography>
                        </li>
                        <li className={Article.a4}>
                            <Typography
                                variant={Variant.p}
                                article={Article.a4}
                                color={ColorDSD.lightSystem3}
                            >
                                {t('typePretText2')}
                            </Typography>
                        </li>
                    </ol>
                    <Hr></Hr>
                    <Spacer></Spacer>
                    {/* taux Interet */}
                    <Typography variant={Variant.p} article={Article.a3}>
                        {t('tauxInteretTitle')}
                        <span style={{ float: 'right' }}>
                            {t('Number:percent', {
                                value: tauxInteret
                            })}
                        </span>
                    </Typography>
                    <Typography
                        variant={Variant.p}
                        article={Article.a4}
                        color={ColorDSD.lightSystem3}
                    >
                        {t('tauxInteretText')}
                    </Typography>
                    <Hr></Hr>
                    <Spacer></Spacer>
                    {/* paiement */}
                    <Typography variant={Variant.p} article={Article.a3}>
                        {t('paiementTitle')}
                        <span style={{ float: 'right' }}>
                            {t('Number:currency', {
                                value: paiement
                            })}{' '}
                            {t(frequenceRemboursement)}
                        </span>
                    </Typography>
                    <Typography
                        variant={Variant.p}
                        article={Article.a4}
                        color={ColorDSD.lightSystem3}
                    >
                        {t('paiementText')}
                    </Typography>
                    <Hr></Hr>
                    <Spacer></Spacer>
                    {/* parler conseiller si erreur dans le pret affiche'*/}
                    <Typography
                        variant={Variant.p}
                        article={Article.a3}
                        color={ColorDSD.lightWarning}
                    >
                        {t('parlerConseiller')}
                        <Link
                            href={getParlerConseillerUrl(
                                ContexteAppel.PRET_ACTUEL,
                                idDemande
                            )}
                            dataTestId="parler_conseiller_link"
                            type={EnumLinkType.EXTERNAL}
                            onClick={onParlerConseillerAnalytic}
                            ariaLabel={t('click2call_aria')}
                        >
                            {t('parlerConseillerLink')}
                        </Link>
                    </Typography>
                </Lightbox>
            )}
        </>
    );
};
