import React, { ReactNode } from 'react';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControl from '@material-ui/core/FormControl';

interface IRadioGroupMui {
    id?: string;
    children: ReactNode;
    value: string;
    onChange?: () => void;
}

export const RadioGroupMui: React.FC<IRadioGroupMui> = ({
    id,
    children,
    value,
    onChange
}) => {
    return (
        <FormControl component="fieldset">
            <RadioGroup id={id} value={value} onChange={onChange}>
                {children}
            </RadioGroup>
        </FormControl>
    );
};
