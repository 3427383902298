export default {
    en: {
        paiement: 'Payment',
        calculer: 'Calculate',
        minimum: 'Minimum: ',
        maximum: 'Maximum: ',
        tooltip1:
            'Consider the minimum or maximum amount payable when determining your mortgage payment.',
        tooltip2:
            'Regular payment amount based on frequency selected. Includes principal, interest and insurance, if applicable.',
        tooltip3:
            'You can choose to increase your regular payment amount up to 2 times its original amount. If you want to increase it even more, talk to our specialists.',
        tooltip4:
            'You can choose to go back to your original payment amount if necessary.',
        erreurPaiementInput:
            'Enter an amount that falls between your minimum and maximum limit.'
    },
    fr: {
        paiement: 'Paiement',
        calculer: 'Calculer',
        minimum: 'Min. :',
        maximum: 'Max. :',
        tooltip1:
            'Tenez compte du montant minimum ou maximum payable pour déterminer votre versement hypothécaire.',
        tooltip2:
            'Montant de vos paiements réguliers selon la fréquence que vous déterminez. Il inclut le capital, les intérêts ainsi que les assurances, s’il y a lieu.',
        tooltip3:
            'Vous pouvez augmenter le montant de vos paiements réguliers. La limite de l’augmentation est fixée à 2 fois leur montant. Si vous souhaitez dépasser cette limite, parlez à nos spécialistes.',
        tooltip4:
            'Vous pourrez revenir au montant initial de vos paiements réguliers si nécessaire.',
        erreurPaiementInput:
            'Entrez un montant compris entre la limite minimale et la limite maximale permise.'
    }
};
