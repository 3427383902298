export default {
    en: {
        title: 'Your current mortgage',
        dateLimiteTitle: 'Renewal deadline',
        dateLimiteText:
            'Renewal deadline specified on your mortgage agreement or last renewal agreement. It’s the last day you can renew your mortgage.',
        soldeTitle: 'mortgage balance',
        soldeText: 'Amount left to fully repay your mortgage.',
        amortissementTitle: 'Remaining amortization',
        amortissementText:
            'Time left to fully repay your mortgage based on current terms.',
        typePretTitle: 'Type of mortgage',
        typePretText1:
            'Type of mortgage (fixed rate or variable rate, open or closed)',
        typePretText2: 'Term (duration of your renewal agreement)',
        tauxInteretTitle: 'Interest rate',
        tauxInteretText:
            'We personalize your interest rate. We take into account your loyalty, your financial habits and the number and type of Desjardins products you have.',
        paiementTitle: 'Payments',
        paiementText:
            'Regular payment amount based on your actual frequency. Includes principal, interest and insurance, if applicable.',
        click2call_aria: 'This link will open in a new window.',

        buttonText: 'Close',
        month: 'month',
        and: 'and',
        FIXE_FERME: 'fixed closed',
        FIXE_OUVERT: 'fixed open',
        TAUX_REVISABLE_ANNUELLEMENT: 'yearly resetter',
        VARIABLE_PROTEGE: 'protected variable',
        VARIABLE_REDUIT: 'reduced variable',
        VARIABLE_REGULIER: 'regular variable',

        parlerConseiller:
            'If the information about your current mortgage isn’t accurate, ',
        parlerConseillerLink: 'talk to our specialists.',
        // Frequences
        HEBDOMADAIRE: 'weekly',
        AUX_DEUX_SEMAINES: 'every 2 weeks',
        MENSUELLE: 'monthly',
        HEBDOMADAIRE_ACCELEREE: 'weekly',
        AUX_DEUX_SEMAINES_ACCELEREE: 'every 2 weeks',
        boutonsPretActuel: 'See your current mortgage'
    },
    fr: {
        title: 'Votre prêt actuel',
        dateLimiteTitle: 'Date limite de renouvellement',
        dateLimiteText:
            'Date d’échéance de votre prêt hypothécaire prévue à votre dernier contrat de renouvellement. Il s’agit de la date limite à laquelle vous pouvez renouveler votre prêt.',
        soldeTitle: 'Solde du prêt',
        soldeText:
            'Somme qu’il vous reste à payer pour rembourser entièrement votre prêt hypothécaire.',
        amortissementTitle: 'Amortissement restant',
        amortissementText:
            'Temps requis pour rembourser la totalité de votre prêt hypothécaire, en se basant sur les modalités actuelles.',
        typePretTitle: 'Type de prêt',
        typePretText1:
            'le type de prêt (prêt à taux fixe ou à taux variable, prêt ouvert ou fermé) et',
        typePretText2:
            'le terme (durée de votre contrat de renouvellement hypothécaire)',
        tauxInteretTitle: 'Taux d’intérêt',
        tauxInteretText:
            'Votre taux d’intérêt est personnalisé. Nous prenons en compte votre fidélité, votre comportement financier, le nombre et le type de produits que vous détenez.',
        paiementTitle: 'Paiement',
        paiementText:
            'Montant de vos paiements réguliers selon votre fréquence actuelle. Il inclut le capital, les intérêts ainsi que les assurances, s’il y a lieu.',
        click2call_aria: 'Ce lien s’ouvrira dans une nouvelle fenêtre.',

        buttonText: 'Fermer',
        month: 'mois',
        and: 'et',

        FIXE_FERME: 'fixe fermé',
        FIXE_OUVERT: 'fixe ouvert',
        TAUX_REVISABLE_ANNUELLEMENT: 'taux révisable annuellement',
        VARIABLE_PROTEGE: 'variable protégé',
        VARIABLE_REDUIT: 'variable réduit',
        VARIABLE_REGULIER: 'variable régulier',

        FIXE: 'fixe',
        VARIABLE: 'variable',
        REVISABLE: 'révisable',
        OUVERT: 'ouvert',
        FERME: 'fermé',
        REDUIT: 'réduit',
        PROTEGE: 'protégé',
        REGULIER: 'régultier',

        parlerConseiller:
            'Si les informations concernant votre prêt actuel sont incorrectes, ',
        parlerConseillerLink: 'parlez à nos spécialistes.',
        // Frequences
        HEBDOMADAIRE: 'par semaine',
        AUX_DEUX_SEMAINES: 'aux 2 semaines',
        MENSUELLE: 'par mois',
        HEBDOMADAIRE_ACCELEREE: 'par semaine',
        AUX_DEUX_SEMAINES_ACCELEREE: 'aux 2 semaines',
        boutonsPretActuel: 'Voir votre prêt actuel'
    }
};
