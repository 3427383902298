import { ACCESWEB_NONPROD, ACCESWEB_PROD, EnvType } from '../../config';

interface IAuthConfig {
    domain: string;
    clientId: string;
    issuer: string;
    redirectUri: string;
    acceswebUrl: string;
    audience: string;
    scope: string;
    creerSessionLegacy?: string;
    connection: string;
    moniteurActiviteActif?: string;
}

export interface IMetaTag {
    name: string;
    content: string;
}

export interface IEnvTypes {
    LOCAL: string;
    DEV: string;
    QA: string;
    HA: string;
    NONPROD: string;
    PROD: string;
}

export type MetaTagsArray = Array<IMetaTag>;

export const MetagTagNames: IAuthConfig = {
    domain: 'oel-authentification-domain',
    clientId: 'oel-authentification-clientId',
    issuer: 'oel-authentification-issuer',
    acceswebUrl: 'oel-acces-web-url',
    redirectUri: 'oel-authentification-redirect-uri',
    audience: 'oel-authentification-audience',
    scope: 'oel-authentification-scope',
    creerSessionLegacy: 'oel-authentification-creer-session-legacy',
    connection: 'oel-authentification-connection',
    moniteurActiviteActif: 'oel-authentification-moniteur-activite-actif'
};

export interface IContent {
    domain: IEnvTypes;
    clientId: IEnvTypes;
    scope: string;
    audience: IEnvTypes;
    issuer: IEnvTypes;
    creerSessionLegacy: string;
    moniteurActiviteActif: string;
    connection: IEnvTypes;
    redirectUri: IEnvTypes;
    acceswebUrl: IEnvTypes;
}

const Content: IContent = {
    domain: {
        LOCAL: 'id.dev.desjardins.com',
        DEV: 'id.dev.desjardins.com',
        QA: 'id.dev.desjardins.com',
        HA: 'id.dev.desjardins.com',
        NONPROD: 'id.dev.desjardins.com',
        PROD: 'id.desjardins.com'
    },
    clientId: {
        LOCAL: 'KEOftQ8II5MJFSTWzfH2zOeuelevSGwT',
        DEV: 'KEOftQ8II5MJFSTWzfH2zOeuelevSGwT',
        QA: 'KEOftQ8II5MJFSTWzfH2zOeuelevSGwT',
        HA: 'KEOftQ8II5MJFSTWzfH2zOeuelevSGwT',
        NONPROD: 'KEOftQ8II5MJFSTWzfH2zOeuelevSGwT',
        PROD: 'sNiBz3pfrxdMPv4cUgvXJebj9pel1XdJ'
    },
    issuer: {
        LOCAL: 'id.dev.desjardins.com',
        DEV: 'id.dev.desjardins.com',
        QA: 'id.dev.desjardins.com',
        HA: 'id.dev.desjardins.com',
        NONPROD: 'id.dev.desjardins.com',
        PROD: 'id.desjardins.com'
    },
    scope: 'particulier.renouvellement.hypothecaire',
    audience: {
        LOCAL: 'pise-mc-accesd',
        DEV: 'pise-mc-accesd',
        QA: 'pise-mc-accesd',
        HA: 'pise-mc-accesd',
        NONPROD: 'pise-mc-accesd',
        PROD: 'pise-mc-accesd'
    },
    creerSessionLegacy: 'false',
    moniteurActiviteActif: 'true',
    connection: {
        LOCAL: 'AcceswebMultiDev',
        DEV: 'AcceswebMultiDev',
        QA: 'AcceswebMultiDev',
        HA: 'AcceswebMultiDev',
        NONPROD: 'AcceswebMultiDev',
        PROD: 'AcceswebDirect'
    },
    redirectUri: {
        LOCAL: 'https://localhost.dev.desjardins.com:3000/',
        DEV: 'https://renouvellement-web-dev-parcours-habitation.apps.cfzcea.dev.desjardins.com/',
        QA: 'https://renouvellement-web-qa-parcours-habitation.apps.cfzcea.dev.desjardins.com/',
        HA: 'https://renouvellement-web-ha-parcours-habitation.apps.cfzcea.dev.desjardins.com/',
        NONPROD:
            'https://renouvellement-hypothecaire.accesd.mouvdev.desjardins.com/',
        PROD: 'https://renouvellement-hypothecaire.accesd.mouv.desjardins.com/'
    },
    acceswebUrl: {
        LOCAL: ACCESWEB_NONPROD,
        DEV: ACCESWEB_NONPROD,
        QA: ACCESWEB_NONPROD,
        HA: ACCESWEB_NONPROD,
        NONPROD: ACCESWEB_NONPROD,
        PROD: ACCESWEB_PROD
    }
};

export const getAccesWebUrl = (env: EnvType): string => {
    return Content.acceswebUrl[env];
};

export const getDomain = (env: EnvType): string => {
    return Content.domain[env];
};

export const getClientId = (env: EnvType): string => {
    return Content.clientId[env];
};

export const getConfig = (env: EnvType): MetaTagsArray => [
    {
        name: MetagTagNames.domain,
        content: Content.domain[env]
    },
    {
        name: MetagTagNames.clientId,
        content: Content.clientId[env]
    },
    {
        name: MetagTagNames.issuer,
        content: Content.issuer[env]
    },
    {
        name: MetagTagNames.redirectUri,
        content: Content.redirectUri[env] + window.location.search
    },
    {
        name: MetagTagNames.acceswebUrl,
        content: Content.acceswebUrl[env]
    },
    {
        name: MetagTagNames.audience,
        content: Content.audience[env]
    },
    {
        name: MetagTagNames.scope,
        content: Content.scope
    },
    {
        name: MetagTagNames.creerSessionLegacy,
        content: Content.creerSessionLegacy
    },
    {
        name: MetagTagNames.connection,
        content: Content.connection[env]
    },
    {
        name: MetagTagNames.moniteurActiviteActif,
        content: Content.moniteurActiviteActif
    }
];
