import { Box, Button } from '@material-ui/core';
import {
    QUERY_PARAM_DEEP_LINK,
    QUERY_PARAM_LANGUE,
    QUERY_PARAM_USER_MODE,
    QUERY_PARAM_USE_MOCK,
    URLToURLSearchParams,
    getLangue
} from '../utils';
import { Select, Tag, ThemeTag, Typography, Variant } from '../styles';
import { axiosInstance, axiosInstanceNoError } from './axiosInstance';

import MockAdapter from 'axios-mock-adapter';
import React from 'react';
import { isEnvNonProd } from '../config';

// export
export let mockInstance;
export let mockInstanceNoError;
export let mockFeatureToggle = false;
export const MOCK_USER_BASIC_LIST = [
    'coemprunteur1',
    'coemprunteur2',
    'emprunteurunique',
    'conseiller',
    'deeplink2prets',
    'deeplink0pret',
    'emprunteuruniqueontario',
    'emprunteuruniqueInteretRetard',
    'coemprunteur2InteretRetard',
    'emprunteuruniqueSignatureIntegree',
    'erreurTarif',
    'coemprunteur1SignatureIntegree',
    'coemprunteur2SignatureIntegree'
];

//static
const MOCK_WARNING_TEXT_DISABLE =
    'Voulez vous désactiver le mode "Démonstration" ?\nSi oui, vous devrez basculer depuis le simulateur sur un pret.';
const MOCK_WARNING_TEXT_ENABLE =
    ' Voulez vous activer le mode "Démonstration" ?\n\nAttention: ce mode ne sert qu\'a tester le visuel !\nCela ne prend pas en compte les choix réalisés et les donnees peuvent etre incohérentes.';
const MOCK_USER_OPTIONS = [
    { value: MOCK_USER_BASIC_LIST[2], label: 'Emprunteur Unique' },
    {
        value: MOCK_USER_BASIC_LIST[9],
        label: 'Emprunteur Unique - Signature Integree'
    },
    {
        value: 'emprunteurUniqueAjoutAssuranceSignatureIntegreeParcours2',
        label: 'Emprunteur Unique - SI + Ajout Assurance - Parcours 2'
    },
    { value: MOCK_USER_BASIC_LIST[6], label: 'Emprunteur Unique - Ontario' },
    {
        value: MOCK_USER_BASIC_LIST[7],
        label: 'Emprunteur Unique - Interet Retard'
    },
    { value: MOCK_USER_BASIC_LIST[0], label: 'Co-emprunteur #1' },
    {
        value: MOCK_USER_BASIC_LIST[11],
        label: 'Co-emprunteur #1 - Signature Integree'
    },
    { value: MOCK_USER_BASIC_LIST[1], label: 'Co-emprunteur #2' },
    {
        value: MOCK_USER_BASIC_LIST[12],
        label: 'Co-emprunteur #2 - Signature Integree'
    },
    {
        value: MOCK_USER_BASIC_LIST[8],
        label: 'Co-emprunteur #2 - Interet Retard'
    },
    { value: MOCK_USER_BASIC_LIST[3], label: 'Conseiller' },
    { value: MOCK_USER_BASIC_LIST[4], label: 'DeepLink - Plusieurs Prêts' },
    { value: MOCK_USER_BASIC_LIST[5], label: 'DeepLink - Aucun Prêt' },
    {
        value: MOCK_USER_BASIC_LIST[10],
        label: 'Erreur - Tarif'
    }
];

const MOCK_USER_OPTIONS_ADVANCED = [
    { value: '', label: '----------------', isDisabled: true },
    {
        value: 'emprunteurUniqueAdmissibleAssuranceParcours1',
        label: 'Emprunteur Unique - Admissible Assurance - Parcours 1'
    },
    {
        value: 'emprunteurUniqueAdmissibleAssuranceParcours2',
        label: 'Emprunteur Unique - Admissible Assurance - Parcours 2'
    },
    {
        value: 'emprunteurUniqueAdmissibleAssuranceParcours3',
        label: 'Emprunteur Unique - Admissible Assurance - Parcours 3'
    },
    {
        value: 'emprunteuruniqueontarioSignatureIntegree',
        label: 'Emprunteur Unique - Signature Integree Ontario'
    },
    {
        value: 'coemprunteur1OntarioSignatureIntegree',
        label: 'Co-emprunteur #1 - Signature Integree Ontario'
    },
    {
        value: 'coemprunteur1codeAvisSignatureIntegreeEnCours',
        label: 'Co-emprunteur #1 - Code Avis Signature En Cours '
    },
    { value: 'coemprunteur2Retard', label: 'Co-emprunteur #2 - Retard' },
    {
        value: 'coemprunteur2OntarioSignatureIntegree',
        label: 'Co-emprunteur #2 - Signature Integree Ontario'
    },
    {
        value: 'erreurTarifAucunDetails',
        label: 'Erreur - Tarif page blanche ou page fallback si fix est présent'
    }
];

function updateQueryStringParameter(
    paramName: string,
    paramValue: any,
    oldUrl: string = window.location.href
): string {
    const url = new URL(oldUrl);
    const searchParams = url.searchParams;

    searchParams.set(paramName, paramValue);

    url.search = searchParams.toString();

    return url.toString();
}

const getMockStatus = (): string => {
    if (mockFeatureToggle) {
        return 'Actif';
    }
    return 'Inactif';
};

const getMockButtonText = (): string => {
    if (mockFeatureToggle) {
        return 'Désactiver le mode "Démonstration"';
    }
    return 'Activer le mode "Démonstration"';
};

// eslint-disable-next-line
export const withDelay = (response, delay) => (config) => {
    const mockResponseTime = delay + Math.random() * 150;
    // eslint-disable-next-line
    return new Promise(function (resolve, reject) {
        setTimeout(function () {
            resolve(response);
        }, mockResponseTime);
    });
};

export function isMockSelectedSimple(): boolean {
    // var
    const query = URLToURLSearchParams();
    const userMockParam = query.get(QUERY_PARAM_USER_MODE);
    return !userMockParam || MOCK_USER_BASIC_LIST.includes(userMockParam)
        ? true
        : false;
}

export function isMockEnabled(): boolean {
    // var
    const query = URLToURLSearchParams();
    // const
    const useMocks = 'true' === process.env.REACT_APP_USE_MOCKS;
    const useMockParam = query.get(QUERY_PARAM_USE_MOCK);

    // Mock: Only for Non-Prod
    // Change here if you want to use mocks or not
    // Query param true or env param whitout query param
    if (
        isEnvNonProd() &&
        ((useMockParam && 'true' === useMockParam) ||
            (useMocks && !useMockParam))
    ) {
        return true;
    }

    return false;
}

export function shouldUseMock(): boolean {
    mockFeatureToggle = isMockEnabled();

    if (mockFeatureToggle) {
        mockInstance = new MockAdapter(axiosInstance);
        mockInstanceNoError = new MockAdapter(axiosInstanceNoError);
    }

    return mockFeatureToggle;
}

export const handleMockStateButton = (): void => {
    if (
        window.confirm(
            mockFeatureToggle
                ? MOCK_WARNING_TEXT_DISABLE
                : MOCK_WARNING_TEXT_ENABLE
        )
    ) {
        if ('URLSearchParams' in window) {
            window.location.href = updateQueryStringParameter(
                QUERY_PARAM_USE_MOCK,
                !mockFeatureToggle
            );
        }
    }
};

const handleDemoUserTypeChange = (value) => {
    const updatedUrl = updateQueryStringParameter(QUERY_PARAM_USER_MODE, value);
    window.location.href = updateQueryStringParameter(
        QUERY_PARAM_DEEP_LINK,
        value.includes('deeplink') ? 'true' : 'false',
        updatedUrl
    );
};

const handleLanguageChange = (value) => {
    window.location.href = updateQueryStringParameter(
        QUERY_PARAM_LANGUE,
        value
    );
};

export const RenderMockButton = (): any => {
    // var
    const query = URLToURLSearchParams();
    const useMockParam = query.get(QUERY_PARAM_USER_MODE);
    const useLangueParam = getLangue();
    const url = window.location.href;
    // Test d'acceptation env
    const isTAEnv =
        url.includes('-ha-') ||
        url.includes('-preprod-') ||
        url.includes('-demo-')
            ? true
            : false;

    // en TA -> Options simples
    const mockOptions = isTAEnv
        ? MOCK_USER_OPTIONS
        : MOCK_USER_OPTIONS.concat(MOCK_USER_OPTIONS_ADVANCED);
    const topMarging = mockFeatureToggle ? 1 : 0;

    return (
        <>
            <Box mt={topMarging}>
                <Typography variant={Variant.p} hideMarginBottom>
                    <b>{'Mode "Démonstration" '}</b>
                    <Tag theme={ThemeTag.NEW} label={getMockStatus()} />
                </Typography>
            </Box>
            {mockFeatureToggle && (
                <>
                    <Box ml={2} mt={1}>
                        <Typography variant={Variant.p} hideMarginBottom>
                            <b>{'Utilisateur type: '}</b>
                        </Typography>
                    </Box>
                    <Box ml={1}>
                        <Select
                            identifier="demo_utilisateur_type"
                            value={
                                useMockParam ? useMockParam : 'coemprunteur1'
                            }
                            options={mockOptions}
                            onChange={(value) =>
                                handleDemoUserTypeChange(value)
                            }
                        />
                    </Box>
                </>
            )}
            {mockFeatureToggle && (
                <>
                    <Box ml={2} mt={1}>
                        <Typography variant={Variant.p} hideMarginBottom>
                            <b>{'Langue: '}</b>
                        </Typography>
                    </Box>
                    <Box ml={1}>
                        <Select
                            identifier="demo_langue"
                            value={useLangueParam ? useLangueParam : 'fr'}
                            options={[
                                {
                                    value: 'fr',
                                    label: 'fr'
                                },
                                {
                                    value: 'en',
                                    label: 'en'
                                }
                            ]}
                            onChange={(value) => handleLanguageChange(value)}
                        />
                    </Box>
                </>
            )}
            {!mockFeatureToggle && (
                <>
                    <Box ml={2} mt={topMarging}>
                        <Button
                            size="small"
                            style={{
                                color: 'white',
                                backgroundColor: 'green'
                            }}
                            onClick={handleMockStateButton}
                        >
                            {getMockButtonText()}
                        </Button>
                    </Box>
                </>
            )}
        </>
    );
};
