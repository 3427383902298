import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import {
    GridRow,
    Button,
    ButtonVariant,
    IconPosition,
    Lightbox
} from '../../styles';
import {
    FrequenceDureeTerme,
    getYearsOrMonths,
    TEXTE_DYNAMIQUE,
    TypeOffre
} from '../../hooks';
import {
    getAvantages,
    getDescription,
    getPourQui,
    getKeyTitre
} from './DetailsOffreModal.utils';
import themePalette from '../../theme/themePalette';
import { Box } from '@material-ui/core';

interface DetailsOffreModalProps {
    valeurDureeTerme: number;
    frequenceDureeTerme: FrequenceDureeTerme;
    typeOffre: TypeOffre;
    tauxRabaisSubsequent: number;
    buttonLabel: string;
    buttonDataTestId?: string;
    titreOffre: string;
    onOpenModalAnalytic: () => void;
}

const useStyles = makeStyles(() =>
    createStyles({
        titre: {
            textAlign: 'left'
        },
        sousTitre: {
            color: themePalette.primary['desjardinsVert'],
            marginLeft: '16px',
            marginTop: '12px',
            marginBottom: '20px'
        },
        text: {
            marginBottom: '8px',
            marginLeft: '16px'
        },
        firstText: {
            marginTop: '10px'
        },
        buttonDetailsOffre: {
            textAlign: 'center'
        }
    })
);

export const DetailsOffreModal: React.FC<DetailsOffreModalProps> = ({
    valeurDureeTerme,
    frequenceDureeTerme,
    typeOffre,
    tauxRabaisSubsequent,
    buttonLabel,
    buttonDataTestId,
    titreOffre,
    onOpenModalAnalytic
}) => {
    const classes = useStyles();
    const [titrePrefix, setTitrePrefix] = useState<string>('');
    const [dureeSuffix, setDureeSuffix] = useState<string>('');
    const [isModalOpened, setIsModalOpened] = useState<boolean>(false);
    const [t] = useTranslation(['DetailsOffreModal']);

    const renderDescription = (dureeSuffix: string) => {
        return (
            <ul className={classes.firstText}>
                {getDescription(typeOffre, tauxRabaisSubsequent, dureeSuffix)}
            </ul>
        );
    };

    const renderAvantages = (dureeSuffix: string) => {
        return (
            <>
                <GridRow>
                    <h6 className={classes.sousTitre}>{t('avantagesTitre')}</h6>
                </GridRow>
                <ul>{getAvantages(typeOffre, dureeSuffix)}</ul>
            </>
        );
    };

    const renderPourQui = () => {
        return (
            <>
                <GridRow>
                    <h6 className={classes.sousTitre}>{t('pourQuiTitre')}</h6>
                </GridRow>
                <ul>{getPourQui(typeOffre)}</ul>
            </>
        );
    };

    // Quand la modale ouvre, on envoie des analytiques
    const handleOpenModal = () => {
        setIsModalOpened(true);
        onOpenModalAnalytic();
    };

    useEffect(() => {
        if (isModalOpened) {
            if (TEXTE_DYNAMIQUE.includes(typeOffre)) {
                setTitrePrefix(
                    getYearsOrMonths(valeurDureeTerme, frequenceDureeTerme)
                );
                setDureeSuffix(
                    getYearsOrMonths(
                        valeurDureeTerme,
                        frequenceDureeTerme,
                        false
                    )
                );
            } else {
                setTitrePrefix('');
            }
        }
    }, [isModalOpened]);

    return (
        <>
            <Box ml="1rem">
                <Button
                    dataTestId={buttonDataTestId}
                    variant={ButtonVariant.tertiary}
                    iconName="navigations_contour_modale"
                    iconPosition={IconPosition.start}
                    onClick={handleOpenModal}
                    srOnlySuffix={
                        t('boutonOuvrirSRPrefix') +
                        titreOffre +
                        t('boutonOuvrirSRSuffix')
                    }
                >
                    {buttonLabel}
                </Button>
            </Box>
            <Lightbox
                show={isModalOpened}
                title={titrePrefix + t(getKeyTitre(typeOffre))}
                buttonPrimaryLabel={t('boutonFermerDetailsOffre')}
                onClose={() => setIsModalOpened(false)}
                onClickPrimaryButton={() => setIsModalOpened(false)}
            >
                {renderDescription(dureeSuffix)}
                {renderAvantages(dureeSuffix)}
                {renderPourQui()}
            </Lightbox>
        </>
    );
};
