import { Box } from '@material-ui/core';
import React from 'react';

import { ColorDSD, ColorHex } from '../';

export enum BorderRadius {
    none = 'none',
    sm = 'sm',
    md = 'md'
}

export enum Padding {
    none = 'none',
    sm = 'sm',
    md = 'md',
    lg = 'lg'
}

export enum Margin {
    none = 'none',
    sm = 'sm',
    md = 'md',
    lg = 'lg'
}

type alignItems =
    | null
    | 'stretch'
    | 'center'
    | 'flex-start'
    | 'flex-end'
    | 'baseline';

interface IContainerProps {
    isDisabled?: boolean;
    flex?: boolean;
    margin?: Margin;
    opacity?: string;
    padding?: Padding;
    alignItems?: alignItems;
    backgroundColor?: ColorDSD | ColorHex;
    backgroundColorCustom?: string;
    children: React.ReactNode;
    borderRadius?: BorderRadius;
    fullHeight?: boolean;
    horizontalScrollItems?: boolean;
    className?: string;
    dataTestId?: string;
}

export const Container: React.FC<IContainerProps> = ({
    isDisabled,
    flex,
    margin,
    opacity,
    padding,
    alignItems,
    backgroundColor,
    backgroundColorCustom,
    children,
    borderRadius,
    fullHeight,
    horizontalScrollItems,
    className,
    dataTestId
}) => {
    return (
        <Box className={className} data-test-id={dataTestId}>
            {/* TODO:: Remove this wrapper classname from CARD its a HACK */}
            {/*@ts-ignore*/}
            <dsd-container
                flex={flex}
                full-height={fullHeight}
                align-items={alignItems}
                background-color={backgroundColor}
                background-color-custom={backgroundColorCustom}
                horizontal-scroll-items={horizontalScrollItems}
                border-radius={borderRadius}
                disabled={isDisabled}
                margin={margin}
                opacity={opacity}
                padding={padding}
                class={className}
            >
                {children}
                {/*@ts-ignore*/}
            </dsd-container>
        </Box>
    );
};
