import React from 'react';
import { useTranslation } from 'react-i18next';

import {
    Typography,
    Variant,
    Article,
    Lightbox,
    Police
} from '../../../../styles';

export interface ILightBoxTropTardProps {
    isOpen?: boolean;
    onClose?: () => void;
}

export const LightBoxTropTard: React.FC<ILightBoxTropTardProps> = ({
    isOpen,
    onClose
}) => {
    const [t] = useTranslation(['Signature']);

    return (
        <>
            {isOpen && (
                <Lightbox
                    show={true}
                    title={t('lightBoxTropTardTitre')}
                    buttonPrimaryLabel={t('lightBoxTropTardPrimaryButton')}
                    onClickPrimaryButton={onClose}
                    onClose={onClose}
                >
                    <Typography variant={Variant.p} article={Article.a3}>
                        {t('lightBoxTropTardDescription_1')}
                    </Typography>
                    <Typography
                        variant={Variant.p}
                        police={Police.bold}
                        article={Article.a3}
                        hideMarginBottom
                    >
                        {t('lightBoxTropTardDescription_2')}
                    </Typography>
                    <Typography variant={Variant.p} article={Article.a3}>
                        {t('lightBoxTropTardDescription_3')}
                    </Typography>
                </Lightbox>
            )}
        </>
    );
};
