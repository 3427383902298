import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import {
    hideLoading,
    setCheckSignature,
    setStatutSignature
} from '../../features';

import { Button, ButtonVariant } from '../../styles';
import {
    NavigationPlacement,
    SaveAndLeaveModal,
    ModalSaveAndLeaveSignee
} from '../../components';
import {
    SignatureStatutEnum,
    getPageName,
    goQuitterParcours,
    useRoutes
} from '../../hooks';
import {
    EVENT_ACTIONS,
    EVENT_CATEGORIES,
    EVENT_LABELS,
    sendEvent
} from '../../features';
import { LightBoxTropTard } from './components/lightBoxTropTard/LightBoxTropTard';
import { ModalModifierVosChoix } from './components/modalModifierVosChoix/ModalModifierVosChoix';
import { IMutationResult } from '../../api';
import { isSignataireSignee } from './Signature.utils';

interface INavigationProps {
    signatureStatut: SignatureStatutEnum;
    signatureRealStatutMutation: IMutationResult;
    signatureAbandonneeMutation: IMutationResult;
    signatureConfirmationMutation: IMutationResult;
    isMartine: boolean;
    updateStatutSignature: (statutReceived: SignatureStatutEnum) => void;
    onClickBackOrQuit: () => void;
    onClickModifierVosChoixGuy: () => void;
    onClickNext: () => void;
}

export const Navigation: React.FC<INavigationProps> = ({
    signatureStatut,
    signatureRealStatutMutation,
    signatureAbandonneeMutation,
    signatureConfirmationMutation,
    isMartine,
    updateStatutSignature,
    onClickBackOrQuit,
    onClickModifierVosChoixGuy,
    onClickNext
}) => {
    const dispatch = useDispatch();
    const [t] = useTranslation(['Signature']);

    const { getRoute, goToRoute } = useRoutes();

    // State
    // modal back
    const [isLightBoxTropTardOpened, setIsLightBoxTropTardOpened] =
        useState(false);
    const [isModalModifierVosChoixOpened, setIsModalModifierVosChoixOpened] =
        useState(false);
    // modal quitter
    const [isModalSaveAndLeaveOpened, setIsModalSaveAndLeaveOpened] =
        useState(false);
    const [
        isModalSaveAndLeaveSigneeOpened,
        setIsModalSaveAndLeaveSigneeOpened
    ] = useState(false);
    const [isBackClicked, setIsBackClicked] = useState(false);

    // Logique de update statut quand le use click sur des boutons
    useEffect(() => {
        if (signatureRealStatutMutation.isSuccess) {
            const statutReceived = signatureRealStatutMutation?.data?.statut;
            // Mise a jour du statut pour le header
            dispatch(setStatutSignature(statutReceived));
            // Mise a jour du statut cette page
            updateStatutSignature(statutReceived);
            // Disable it for next use in Header
            dispatch(setCheckSignature(false));
            dispatch(hideLoading());

            if (isBackClicked) {
                if (isSignataireSignee(isMartine, statutReceived)) {
                    setIsLightBoxTropTardOpened(true);
                } else {
                    // Si martine fait back ne pas abandonner la signature et aller directement à la prochaine page sans modale
                    if (isMartine) {
                        dispatch(
                            sendEvent({
                                category: EVENT_CATEGORIES.RHN,
                                action: EVENT_ACTIONS.CLIC,
                                label: EVENT_LABELS.PRECEDENT + getPageName()
                            })
                        );
                        goToRoute(getRoute().prevRoute);
                    } else {
                        setIsModalModifierVosChoixOpened(true);
                    }
                }
                setIsBackClicked(false);
            } else {
                if (isSignataireSignee(isMartine, statutReceived)) {
                    setIsModalSaveAndLeaveSigneeOpened(true);
                } else {
                    setIsModalSaveAndLeaveOpened(true);
                }
            }
        }
    }, [signatureRealStatutMutation.isSuccess]);

    // quand signature abandonne success faire precedent
    useEffect(() => {
        if (signatureAbandonneeMutation.isSuccess) {
            dispatch(
                sendEvent({
                    category: EVENT_CATEGORIES.RHN,
                    action: EVENT_ACTIONS.CLIC,
                    label: EVENT_LABELS.PRECEDENT + getPageName()
                })
            );
            goToRoute(getRoute().prevRoute);
        }
    }, [signatureAbandonneeMutation.isSuccess]);

    // quand confirmation success aller à la prochane page
    useEffect(() => {
        if (signatureConfirmationMutation.isSuccess) {
            goToRoute(getRoute().nextRoute);
        }
    }, [signatureConfirmationMutation.isSuccess]);

    // Send cyber & leave
    const leaveApp = () => {
        const pageCourante = getPageName();

        if (pageCourante) {
            dispatch(
                sendEvent({
                    category: EVENT_CATEGORIES.RHN,
                    action: EVENT_ACTIONS.CLIC,
                    label: EVENT_LABELS.ENREGISTRER_ET_QUITTER + pageCourante
                })
            );
        }

        goQuitterParcours();
    };

    const handleQuitter = () => {
        // Si statut est deja signee pas besoin d'ouvrir enregistrer signature
        if (!isSignataireSignee(isMartine, signatureStatut)) {
            // Open confirmation modal
            onClickBackOrQuit();
        } else {
            leaveApp();
        }
    };

    const handleBack = () => {
        setIsBackClicked(true);
        onClickBackOrQuit(); // api calls
    };

    const handleNext = () => {
        onClickNext();
    };

    const handleClickPrimaryModalModifierVosChoix = () => {
        onClickModifierVosChoixGuy();
    };

    const handleClickModalClose = (shouldStayHere: boolean) => {
        // Close confirmation modal
        setIsModalSaveAndLeaveOpened(false);
        setIsModalSaveAndLeaveSigneeOpened(false);

        if (!shouldStayHere) {
            // Send cyber & leave
            leaveApp();
        }
    };

    const renderPopUpPourBackButton = () => {
        return (
            <>
                {/* Modal et lightbox pour back button */}
                <LightBoxTropTard
                    isOpen={isLightBoxTropTardOpened}
                    onClose={() => {
                        setIsLightBoxTropTardOpened(false);
                    }}
                />
                <ModalModifierVosChoix
                    isOpen={isModalModifierVosChoixOpened}
                    onPrimaryButtonClick={
                        handleClickPrimaryModalModifierVosChoix
                    }
                    onSecondaryButtonClick={() => {
                        setIsModalModifierVosChoixOpened(false);
                    }}
                />
            </>
        );
    };

    const renderPopUpPourQuitter = () => {
        return (
            <>
                {/* Modal pour save and leave */}
                <SaveAndLeaveModal
                    isOpen={isModalSaveAndLeaveOpened}
                    onNext={() => handleClickModalClose(false)}
                    onCancel={() => handleClickModalClose(true)}
                    signatureVariant={true}
                />
                <ModalSaveAndLeaveSignee
                    isOpen={isModalSaveAndLeaveSigneeOpened}
                    onPrimaryButtonClick={() => handleClickModalClose(true)}
                    onSecondaryButtonClick={() => handleClickModalClose(false)}
                />
            </>
        );
    };

    return (
        <>
            {renderPopUpPourQuitter()}
            {renderPopUpPourBackButton()}
            <NavigationPlacement
                renderBackButton={
                    <>
                        {!isSignataireSignee(isMartine, signatureStatut) && (
                            <Button
                                id={'QA_test_navigation_back_button'}
                                variant={ButtonVariant.secondary}
                                dataCy="qa-navigation-button-back"
                                dataTestId={'navigation-back-button'}
                                onClick={handleBack}
                                fullWidth="xs"
                            >
                                {t(isMartine ? 'backMartine' : 'back')}
                            </Button>
                        )}
                    </>
                }
                renderSaveButton={
                    <Button
                        id={'QA_test_navigation_quit_button'}
                        variant={ButtonVariant.secondary}
                        dataCy="qa-navigation-button-quit"
                        dataTestId={'navigation-quit-button'}
                        onClick={handleQuitter}
                        fullWidth="xs"
                    >
                        {t('cancel')}
                    </Button>
                }
                renderNextButton={
                    <>
                        {isSignataireSignee(isMartine, signatureStatut) ? (
                            <Button
                                id={'QA_test_navigation_next_button'}
                                variant={ButtonVariant.primary}
                                dataCy="qa-navigation-button-next"
                                dataTestId={'navigation-next-button'}
                                onClick={handleNext}
                                fullWidth="xs"
                            >
                                {t('next')}
                            </Button>
                        ) : null}
                    </>
                }
            />
        </>
    );
};
