import React from 'react';
import { useDispatch } from 'react-redux';

import {
    useSessionApi,
    usePretApi,
    CodeAssurance,
    useRenouvellementApi
} from '../../hooks';
import {
    sendEvent,
    EVENT_CATEGORIES,
    EVENT_ACTIONS,
    EVENT_LABELS
} from '../../features';

import { ProtectionModal } from './ProtectionModal';

const CYBERMETRIE_TOOLTIP_LABEL = 'protection fumeur';

export const ProtectionModalConnected: React.FC<unknown> = () => {
    const dispatch = useDispatch();
    const { data: session } = useSessionApi();
    const { data: renouvellement } = useRenouvellementApi(!!session);

    const idDemande = session?.contextePret?.idDemande;
    const { data: pretActuel } = usePretApi(idDemande, !!renouvellement);

    const handleOnTooltipActiveAnalitic = () => {
        dispatch(
            sendEvent({
                category: EVENT_CATEGORIES.RHN,
                action: EVENT_ACTIONS.CLIC,
                label: EVENT_LABELS.INFOBULLE + CYBERMETRIE_TOOLTIP_LABEL
            })
        );
    };

    const handleOpenModalAnalytic = () => {
        dispatch(
            sendEvent({
                category: EVENT_CATEGORIES.RHN,
                action: EVENT_ACTIONS.CLIC,
                label: EVENT_LABELS.VOIR_MA_PROTECTION
            })
        );
    };

    const handleParlerConseillerAnalytic = () => {
        dispatch(
            sendEvent({
                category: EVENT_CATEGORIES.RHN,
                action: EVENT_ACTIONS.CLIC,
                label:
                    EVENT_LABELS.CLICK2CALL_SPECIALISTE +
                    EVENT_LABELS.MODALE_VOIR_PROTECTION
            })
        );
    };

    return (
        <>
            <ProtectionModal
                listeAssures={pretActuel?.listeAssures}
                idDemande={idDemande}
                isRenewalSectionDisplayed={
                    pretActuel?.codeAssurance !== CodeAssurance.RETRAIT
                }
                onTooltipActive={handleOnTooltipActiveAnalitic}
                onOpenModal={handleOpenModalAnalytic}
                onParlerConseiller={handleParlerConseillerAnalytic}
            ></ProtectionModal>
        </>
    );
};
