import React, { useEffect } from 'react';

import { Navigation } from './AnnuleeConseiller.navigation';
import { AnnuleeConseiller } from './AnnuleeConseiller';
import { HeadingProvider } from '../../context';
import { Headings } from '../../styles';
import { useDispatch } from 'react-redux';
import { hideLoading } from '../../features';

const headingMapping = {
    h1: Headings.h3
};

export const AnnuleeConseillerConnected: React.FC<unknown> = () => {
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(hideLoading());
    }, []);

    return (
        <HeadingProvider value={headingMapping}>
            <AnnuleeConseiller />
            <Navigation />
        </HeadingProvider>
    );
};
