// Wrapper buisness logic pour page des offres (Card)
import React from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import {
    sendEvent,
    EVENT_CATEGORIES,
    EVENT_ACTIONS,
    EVENT_LABELS
} from '../../features';
import { DetailsOffreModal } from '..';
import { FrequenceDureeTerme, TypeOffre } from '../../hooks';

// Props viennent de offre => card.tsx
interface IDetailsOffreModalOffreConnected {
    valeurDureeTerme: number;
    frequenceDureeTerme: FrequenceDureeTerme;
    typeOffre: TypeOffre;
    tauxRabaisSubsequent: number;
    titreOffre: string;
    idOffre: string;
}

export const DetailsOffreModalOffreConnected: React.FC<
    IDetailsOffreModalOffreConnected
> = ({
    valeurDureeTerme,
    frequenceDureeTerme,
    typeOffre,
    tauxRabaisSubsequent,
    titreOffre,
    idOffre
}) => {
    const dispatch = useDispatch();
    const [t] = useTranslation(['DetailsOffreModal']);

    const handleOpenModalAnalytic = () => {
        dispatch(
            sendEvent({
                category: EVENT_CATEGORIES.RHN,
                action: EVENT_ACTIONS.CLIC,
                label: EVENT_LABELS.VOIR_DETAIL_PRET,
                extraAttribut2: titreOffre
            })
        );
    };

    return (
        <DetailsOffreModal
            valeurDureeTerme={valeurDureeTerme}
            frequenceDureeTerme={frequenceDureeTerme}
            buttonLabel={t('boutonLabelDetailsOffre')}
            buttonDataTestId={'bouton_details_offre_' + idOffre}
            typeOffre={typeOffre}
            tauxRabaisSubsequent={tauxRabaisSubsequent}
            titreOffre={titreOffre}
            onOpenModalAnalytic={handleOpenModalAnalytic}
        ></DetailsOffreModal>
    );
};
