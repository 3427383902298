import { useQuery, UseQueryResult } from 'react-query';

import {
    axiosInstance,
    getDynamicHeaders,
    reactCachedQueryOptions
} from '../../../../api';
import { DEMANDE_END_POINT } from '../../apiEndPoints';
import { getOfferTitle } from '../useOffreApi/Offres.utils';
import {
    FrequenceDureeTerme,
    SousTypeTaux,
    TypeTaux
} from '../useOffreApi/useOffreApi';

export enum FrequenceRemboursement {
    HEBDOMADAIRE = 'HEBDOMADAIRE',
    AUX_DEUX_SEMAINES = 'AUX_DEUX_SEMAINES',
    MENSUELLE = 'MENSUELLE',
    HEBDOMADAIRE_ACCELEREE = 'HEBDOMADAIRE_ACCELEREE',
    AUX_DEUX_SEMAINES_ACCELEREE = 'AUX_DEUX_SEMAINES_ACCELEREE'
}

export enum TypeFumeur {
    OUI = 'OUI',
    NON = 'NON'
}

export enum CodeAssurance {
    VIE = 'V',
    VIE_INVALIDITE = 'I',
    SOUMISSION_ASSURANCE = 'S',
    AUCUNE = 'A',
    RETRAIT = 'R'
}

export enum TypeAssurance {
    AP_MAT = 'AP_MAT',
    AP = 'AP',
    NONE = 'NONE'
}

export enum TypePret {
    FIXE_FERME = 'FIXE_FERME',
    FIXE_OUVERT = 'FIXE_OUVERT',
    TAUX_REVISABLE_ANNUELLEMENT = 'TAUX_REVISABLE_ANNUELLEMENT',
    VARIABLE_REGULIER = 'VARIABLE_REGULIER',
    VARIABLE_REDUIT = 'VARIABLE_REDUIT',
    VARIABLE_PROTEGE = 'VARIABLE_PROTEGE'
}

export type IAmortissement = {
    annee: number;
    mois: number;
};

export interface IAssuree {
    prenom: string;
    nom: string;
    age: number;
    indicateurFumeur?: TypeFumeur;
    pctCouvertureAssuranceVie: number;
    pctCouvertureAssuranceInvalidite: number;
    indicateurEligibiliteAssuranceVie: boolean;
    indicateurEligibiliteAssuranceInvalidite: boolean;
}

export interface IPret {
    dureeTerme: number;
    typePret: TypePret;
    dateRappel: string;
    solde: number;
    frequenceRemboursement: FrequenceRemboursement;
    amortissement: IAmortissement;
    montantRemboursement: number;
    tauxInteret: number;
    listeAssures: Array<IAssuree>;
    indicateurReductionTauxNonFumeur: boolean;
    codeAssurance: CodeAssurance;
    typeAssurance: TypeAssurance;
    offerTitle?: string;
}

const offreDureeAnnee = [1, 2, 3, 4, 5, 7];
const correspondanceTypePret = {
    [TypePret.FIXE_FERME]: {
        typeTaux: TypeTaux.FIXE,
        sousTypeTaux: SousTypeTaux.FERME
    },
    [TypePret.FIXE_OUVERT]: {
        typeTaux: TypeTaux.FIXE,
        sousTypeTaux: SousTypeTaux.OUVERT
    },
    [TypePret.TAUX_REVISABLE_ANNUELLEMENT]: {
        typeTaux: TypeTaux.REVISABLE,
        sousTypeTaux: SousTypeTaux.FERME
    },
    [TypePret.VARIABLE_PROTEGE]: {
        typeTaux: TypeTaux.VARIABLE,
        sousTypeTaux: SousTypeTaux.PROTEGE
    },
    [TypePret.VARIABLE_REDUIT]: {
        typeTaux: TypeTaux.VARIABLE,
        sousTypeTaux: SousTypeTaux.REDUIT
    },
    [TypePret.VARIABLE_REGULIER]: {
        typeTaux: TypeTaux.VARIABLE,
        sousTypeTaux: SousTypeTaux.REGULIER
    }
};

// eslint-disable-next-line
export const getTypePret = (dureeTerme: number, typePret: TypePret, t: any) => {
    const dureeArray = String(dureeTerme).split('.');
    let formattedDuree = '';

    // Months format si plus que des annees
    if (dureeArray.length > 1 && parseInt(dureeArray[1]) != 0) {
        const monthTotal = String(Math.floor(dureeTerme * 12));
        formattedDuree = t('Date:months', {
            value: monthTotal
        });
    } else {
        formattedDuree = t('Date:years', {
            value: dureeArray[0]
        });
    }

    return formattedDuree + ' ' + t(typePret);
};

export const END_POINT = DEMANDE_END_POINT + '/pret';

function deduireTitreDuPret(pretActuel: IPret) {
    const duree = pretActuel.dureeTerme;
    // Deduire si annee ou mois
    const frequence = offreDureeAnnee.includes(duree)
        ? FrequenceDureeTerme.ANNEE
        : FrequenceDureeTerme.MOIS;
    // Obtenir le type et sous type de taux pour avoir le titre
    if (pretActuel.typePret in correspondanceTypePret) {
        const correspondance = correspondanceTypePret[pretActuel.typePret];
        pretActuel.offerTitle = getOfferTitle(
            correspondance.typeTaux,
            correspondance.sousTypeTaux,
            duree,
            frequence
        );
    }
    // Retourner le tout
    return pretActuel;
}

const fetch = async (idDemande: number) => {
    const endPoint = END_POINT.replace(/{idDemande}/g, String(idDemande));

    const { data } = await axiosInstance.get(endPoint, getDynamicHeaders());

    return deduireTitreDuPret(data);
};

export function usePretApi(
    idDemande: number = undefined,
    isEnabled = true
): UseQueryResult<IPret, Error> {
    return useQuery<IPret, Error>(['pret', idDemande], () => fetch(idDemande), {
        ...reactCachedQueryOptions(),
        enabled: !!isEnabled
    });
}
