import { useQuery, UseQueryResult } from 'react-query';

import {
    axiosInstance,
    getDynamicHeaders,
    reactQueryOptions
} from '../../../../api';
import { DEMANDE_END_POINT } from '../../apiEndPoints';
import { getTransformedOffers, getOffreChoisie } from './Offres.transformer';

export enum Sexe {
    INCONNU = 'INCONNU',
    MASCULIN = 'MASCULIN',
    FEMININ = 'FEMININ',
    NON_APPLICABLE = 'NON_APPLICABLE'
}

export enum TypeTaux {
    FIXE = 'FIXE',
    VARIABLE = 'VARIABLE',
    REVISABLE = 'REVISABLE'
}

export enum TypeTauxFilter {
    FIXE = 'FIXE',
    VARIABLE = 'VARIABLE'
}

export enum SousTypeTaux {
    OUVERT = 'OUVERT',
    FERME = 'FERME',
    REDUIT = 'REDUIT',
    PROTEGE = 'PROTEGE',
    REGULIER = 'REGULIER'
}

export enum FrequenceRemboursement {
    HEBDOMADAIRE = 'HEBDOMADAIRE',
    AUX_DEUX_SEMAINES = 'AUX_DEUX_SEMAINES',
    MENSUELLE = 'MENSUELLE',
    HEBDOMADAIRE_ACCELEREE = 'HEBDOMADAIRE_ACCELEREE',
    AUX_DEUX_SEMAINES_ACCELEREE = 'AUX_DEUX_SEMAINES_ACCELEREE'
}

export enum FrequenceDureeTerme {
    ANNEE = 'A',
    MOIS = 'M'
}

export enum TypeOffreTarification {
    PROMOTION = 'PROMOTION',
    SIMULEE = 'SIMULEE',
    ACTUALISEE = 'ACTUALISEE',
    NON_SELECTIONABLE = 'NON_SELECTIONABLE'
}

type approbation = {
    delaiApprobation: number;
    dateLimite: string;
};

export type offre = {
    idOffre: number;
    dateAcceptationOffre: string;
    dateDebutEffetChangementFrequence: string;
    dateLimiteApprobation: string;
    dateProchainPaiementChangementFrequence: string;
    delaiApprobation: number;
    frequenceRemboursement: FrequenceRemboursement;
    montantRemboursement: number;
    offreAcceptee: boolean;
    offreInitiale: boolean;
    offreSelectionnee: boolean;
    sousTypeTaux: SousTypeTaux;
    tauxAdditionnelAssurance: number;
    tauxRabaisSubsequent: number;
    typeOffreTarification: TypeOffreTarification;
    typeTaux: TypeTaux;
    valeurDureeTerme: number;
    valeurTauxAfficher: number;
    valeurTauxPreferentiel: number;
    valeurTauxPropose: number;
    // optional properties
    frequenceDureeTerme?: FrequenceDureeTerme;
    valeurTauxEffectif?: number;
    valeurTauxPlafond?: number;
    typeTauxFilter?: TypeTauxFilter;
    showVariableProtegeInterestNote?: boolean;
};

export interface IOffres {
    dateCalcul: string;
    dateLimite: string;
    listeOffre: Array<offre>;
    indicateurOffreAcceptee: boolean;
    approbation: approbation;
    formattedCardList?: Array<any>;
    offreChoisie?: offre;
}

export const END_POINT = DEMANDE_END_POINT + '/emprunteurs/actuel/offres';

function formatData(offres: IOffres, idDemande: number) {
    offres.offreChoisie = getOffreChoisie(offres.listeOffre);

    return {
        ...offres,
        formattedCardList: getTransformedOffers(offres, idDemande)
    };
}

const fetch = async (idDemande: number) => {
    const endPoint = END_POINT.replace(/{idDemande}/g, String(idDemande));

    const { data } = await axiosInstance.get(endPoint, getDynamicHeaders());

    return formatData(data, idDemande);
};

export function useOffresApi(
    idDemande: number = undefined,
    isEnabled = true
): UseQueryResult<IOffres, Error> {
    return useQuery<IOffres, Error>(
        ['offres', idDemande],
        () => fetch(idDemande),
        {
            ...reactQueryOptions(),
            enabled: !!isEnabled && !!idDemande
        }
    );
}
