export default {
    en: {
        sommaire: 'Back to AccèsD home',
        title: 'Mortgage renewal',
        errorTechniqueText:
            'Something went wrong. Try again later. If the issue persists, contact us.'
    },
    fr: {
        sommaire: 'Retour à l’accueil',
        title: 'Renouvellement hypothécaire',
        errorTechniqueText:
            'Une erreur s’est produite. Réessayez plus tard. Si le problème persiste, communiquez avec nous.'
    }
};
