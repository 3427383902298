import React from 'react';
import { Box, useMediaQuery } from '@material-ui/core';

import { Grid, GridCol, GridRow, Spacer } from '../../styles';

export interface INavigationPlacementProps {
    renderBackButton?: React.ReactElement; // Back
    renderSaveButton?: React.ReactElement; // Enregistrer et quitter
    renderNextButton?: React.ReactElement; // Continuer
    renderNextButtonAlt?: React.ReactElement; // Continuer 2
    renderReturnButton?: React.ReactElement; // Retour
}

export const NavigationPlacement: React.FC<INavigationPlacementProps> = ({
    renderBackButton,
    renderSaveButton,
    renderNextButton,
    renderNextButtonAlt,
    renderReturnButton
}) => {
    const isMobile = useMediaQuery('(max-width:640px)');
    const shouldRenderNextButton: boolean =
        renderNextButton && renderNextButton.props.children;
    const shouldRenderNextButtonAlt: boolean =
        renderNextButtonAlt && renderNextButtonAlt.props.children;
    const shouldRenderBackButton: boolean =
        renderBackButton && renderBackButton.props.children;
    const shouldRenderSaveButton: boolean =
        renderSaveButton && renderSaveButton.props.children;
    const shouldRenderReturnButton: boolean =
        renderReturnButton && renderReturnButton.props.children;

    return (
        <>
            {isMobile ? (
                <Grid>
                    {shouldRenderNextButton && (
                        <>
                            <GridRow>
                                <GridCol col="12">{renderNextButton}</GridCol>
                            </GridRow>
                        </>
                    )}
                    {shouldRenderNextButtonAlt && (
                        <>
                            <Spacer />
                            <GridRow>
                                <GridCol col="12">
                                    {renderNextButtonAlt}
                                </GridCol>
                            </GridRow>
                        </>
                    )}
                    {shouldRenderBackButton && (
                        <>
                            {(shouldRenderNextButton ||
                                shouldRenderNextButtonAlt) && <Spacer />}
                            <GridRow>
                                <GridCol col="12">{renderBackButton}</GridCol>
                            </GridRow>
                        </>
                    )}
                    {shouldRenderSaveButton && (
                        <>
                            <Spacer />
                            <GridRow>
                                <GridCol col="12">{renderSaveButton}</GridCol>
                            </GridRow>
                        </>
                    )}
                    {shouldRenderReturnButton && (
                        <>
                            {(shouldRenderNextButton ||
                                shouldRenderBackButton ||
                                shouldRenderSaveButton) && <Spacer />}
                            <GridRow>
                                <Box display="flex" padding="16px 0">
                                    <GridCol col="12">
                                        {renderReturnButton}
                                    </GridCol>
                                </Box>
                            </GridRow>
                        </>
                    )}
                </Grid>
            ) : (
                <Grid>
                    {(shouldRenderNextButton ||
                        shouldRenderNextButtonAlt ||
                        shouldRenderBackButton ||
                        shouldRenderSaveButton) && (
                        <GridRow>
                            <GridCol col="12" sm="12" md="12">
                                <Box
                                    display="flex"
                                    flexDirection="row"
                                    flexWrap="nowrap"
                                    marginBottom="16px"
                                >
                                    {/* Next button */}
                                    <Box
                                        marginRight={
                                            shouldRenderNextButton ? '1rem' : 0
                                        }
                                    >
                                        {renderNextButton}
                                    </Box>
                                    {/* Next button alt */}
                                    <Box
                                        marginRight={
                                            shouldRenderNextButtonAlt
                                                ? '1rem'
                                                : 0
                                        }
                                    >
                                        {renderNextButtonAlt}
                                    </Box>
                                    {/* Back button */}
                                    <Box
                                        marginRight={
                                            shouldRenderBackButton ? '1rem' : 0
                                        }
                                    >
                                        {renderBackButton}
                                    </Box>
                                    {/* Save button */}
                                    <Box>{renderSaveButton}</Box>
                                </Box>
                            </GridCol>
                        </GridRow>
                    )}
                    <GridRow>
                        {/* Return button */}
                        <GridCol col="12" sm="5" md="3">
                            <Box display="flex" padding="16px 0 0 0">
                                {renderReturnButton}
                            </Box>
                        </GridCol>
                    </GridRow>
                </Grid>
            )}
        </>
    );
};
