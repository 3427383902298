import React from 'react';
import { useTranslation } from 'react-i18next';
import {
    Box,
    createStyles,
    makeStyles,
    useMediaQuery
} from '@material-ui/core';
import {
    GridRow,
    GridCol,
    Typography,
    Variant,
    Police,
    Spacer,
    Button,
    ButtonVariant,
    ContainerMui,
    DateText
} from '../../styles';

const useStyles = makeStyles(() =>
    createStyles({
        description: {
            maxWidth: '400px'
        }
    })
);

export interface IResumeDateProps {
    dateProchainPaiement: string;
    dateDernierPaiement: string;
    isEditButtonDisplayed: boolean;
    shouldBeDisplayed: boolean;
    onModifiedClick: () => void;
}

export const ResumeDate: React.FC<IResumeDateProps> = ({
    dateProchainPaiement,
    dateDernierPaiement,
    isEditButtonDisplayed,
    shouldBeDisplayed,
    onModifiedClick
}) => {
    const [t] = useTranslation(['ResumeDate']);
    const isMobile = useMediaQuery('(max-width:640px)');
    const classes = useStyles();
    const newDate = new Date(dateProchainPaiement);
    const oldDate = new Date(dateDernierPaiement);

    return (
        <>
            {shouldBeDisplayed && (
                <>
                    <ContainerMui noSpacer>
                        <Box
                            ml={isMobile ? 1 : 3}
                            mb={
                                isEditButtonDisplayed
                                    ? isMobile
                                        ? -2
                                        : -1
                                    : isMobile
                                    ? -4
                                    : -3
                            }
                            mt={isMobile ? -2 : -1}
                        >
                            <GridCol col="12">
                                <GridRow>
                                    <Typography
                                        variant={Variant.h4}
                                        police={Police.bold}
                                        marginBottom="1rem"
                                    >
                                        {t('resumeDateTitle')}
                                    </Typography>
                                </GridRow>
                                <GridRow>
                                    <Typography
                                        variant={Variant.p}
                                        marginBottom="1rem"
                                        className={classes.description}
                                    >
                                        <DateText
                                            translationKey="ResumeDate:resumeDateDisclaimerText"
                                            date={oldDate}
                                        >
                                            {{ date: oldDate }}
                                        </DateText>
                                    </Typography>
                                </GridRow>
                                <GridRow>
                                    <Typography
                                        variant={Variant.p}
                                        marginBottom="0.25rem"
                                    >
                                        {t('resumeDatePremierPaiementTitle')}
                                    </Typography>
                                </GridRow>
                                <GridRow>
                                    <Typography
                                        variant={Variant.p}
                                        police={Police.bold}
                                    >
                                        <DateText
                                            translationKey="ResumeDate:resumeDatePremierPaiementValeur"
                                            date={newDate}
                                        >
                                            {{ date: newDate }}
                                        </DateText>
                                    </Typography>
                                </GridRow>
                                {/* Ne pas afficher si MARTINE ( page validation ou choix 1er coemprunteur ) */}
                                {isEditButtonDisplayed && (
                                    <GridRow>
                                        <Button
                                            id={
                                                'QA_test_validation_date_modifier'
                                            }
                                            variant={ButtonVariant.compact}
                                            dataCy="qa-button-date-modifier"
                                            dataTestId={
                                                'bouton_modifier_date_paiement'
                                            }
                                            onClick={onModifiedClick}
                                            srOnlySuffix={
                                                t('resumeChangeButton') +
                                                ' ' +
                                                t('resumeDateTitle')
                                            }
                                        >
                                            {t('resumeChangeButton')}
                                        </Button>
                                    </GridRow>
                                )}
                            </GridCol>
                        </Box>
                    </ContainerMui>
                    <Spacer md={3} sm={1} xs={1} />
                </>
            )}
        </>
    );
};
