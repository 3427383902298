import { Box } from '@material-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Alert, AlertType, Article, Typography, Variant } from '../../styles';
import { makeStyles, createStyles } from '@material-ui/core/styles';

type Errors = {
    label: string;
    dataTestId: string;
    ref?: React.RefObject<HTMLInputElement>;
};

export interface IAlertInputErrorProps {
    errors: Array<Errors>;
}

const useStyles = makeStyles(() =>
    createStyles({
        error: {
            marginBottom: '0.4rem !important',
            textDecoration: 'underline',
            cursor: 'pointer'
        }
    })
);

export const AlertInputError: React.FC<IAlertInputErrorProps> = ({
    errors
}) => {
    const [t] = useTranslation('AlertInputError');
    const classes = useStyles();

    const handleClick = (ref) => {
        if (ref?.current) {
            ref.current.scrollIntoView({
                block: 'center',
                inline: 'center'
            });
            ref.current.focus();
        }
    };

    return (
        <Alert type={AlertType.error}>
            <Box marginBottom="1rem">
                <Typography
                    variant={Variant.p}
                    article={Article.a3}
                    hideMarginBottom
                >
                    {t('descriptionErreur')}
                </Typography>
            </Box>
            <ul>
                {errors.map((error) => (
                    <li
                        data-testid={error.dataTestId}
                        key={error.label}
                        className={classes.error}
                        onClick={() => handleClick(error.ref)}
                    >
                        <Typography
                            dataTestId="error-link"
                            tabIndex={0}
                            onKeyPress={() => handleClick(error.ref)}
                            variant={Variant.a}
                            article={Article.a3}
                            accessibilityText={
                                t('prefixHorsEcran') + error.label
                            }
                            hideMarginBottom
                        >
                            {error.label}
                        </Typography>
                    </li>
                ))}
            </ul>
        </Alert>
    );
};
