export default {
    en: {
        back: 'Back',
        cancel: 'Save and leave',
        title: 'Choose your personalized rate',
        subtitle: 'Mortgages available',
        description_1:
            'Select the right interest rate for your financial situation and needs. You can count on us to provide you with a personalized offer.',
        description_2:
            'Open mortgages and other term lengths are also available on request. Contact us for more information.',
        // boutons modals
        boutonAiguilleur: 'Rate selector',
        boutonAiguilleurSr: 'See rate types according to your needs.',
        boutonInfoTaux: 'Learn about the types of rates',
        //bulle info
        bulleTitre: 'Your favourite rates in one click',
        bulleDescription: 'Get help selecting the rate that suits you best.',
        // filtres
        titreFiltre: 'Filter by',
        TOUT: 'All rates',
        FIXE: 'Fixed rates',
        VARIABLE: 'Variable rates',
        VENTE: 'Sale within 2 years',
        // sort
        titreSort: 'Sort by',
        TAUX: 'Rate',
        TERME: 'Mortgage term',
        PAIEMENT: 'Payment',
        // PretActuel
        titreSituationActuelle: 'What you currently have',
        offreActuelleTaux: 'Personalized rate',
        offreActuellePaiementTitre: 'Payment',
        offreActuellePaiementAssuTitre: 'Payment with insurance',
        // footnote
        footnoteToolTip: 'Go to note 1'
    },
    fr: {
        back: 'Précédent',
        cancel: 'Enregistrer et quitter',
        title: 'Choisir votre taux personnalisé',
        subtitle: 'Prêts hypothécaires offerts',
        description_1:
            'Optez pour le taux d’intérêt correspondant à votre situation financière et à vos besoins. Comptez sur nous pour vous proposer une offre personnalisée dès maintenant.',
        description_2:
            'D’autres durées de prêt et des prêts ouverts sont aussi disponibles sur demande. Contactez-nous pour en savoir plus.',
        // boutons modals
        boutonAiguilleur: 'Sélecteur de taux',
        boutonAiguilleurSr: 'Voir les types de taux selon vos besoins.',
        boutonInfoTaux: 'Comprendre les types de taux',
        //bulle info
        bulleTitre: 'Vos taux préférés en un clic',
        bulleDescription:
            'Obtenez de l’aide pour sélectionner le taux qui fait votre affaire.',
        // filtres
        titreFiltre: 'Filtrer par',
        TOUT: 'Tous les taux',
        FIXE: 'Taux fixes',
        VARIABLE: 'Taux variables',
        VENTE: 'Vente avant 2 ans',
        // sort
        titreSort: 'Trier par',
        TAUX: 'Taux',
        TERME: 'Terme du prêt',
        PAIEMENT: 'Paiement',
        // PretActuel
        titreSituationActuelle: 'Ce que vous détenez',
        offreActuelleTaux: 'Taux personnalisé',
        offreActuellePaiementTitre: 'Paiement',
        offreActuellePaiementAssuTitre: 'Paiement avec assurance',
        // footnote
        footnoteToolTip: 'Aller à la note 1'
    }
};
