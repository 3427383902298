export default {
    en: {
        lienRetourAvecModale: 'Save and leave',
        lienRetourSansModale: 'Back to AccèsD home'
    },
    fr: {
        lienRetourAvecModale: 'Enregistrer et quitter',
        lienRetourSansModale: 'Retour à l’accueil'
    }
};
