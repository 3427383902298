import React, { useEffect, useRef } from 'react';

interface ICheckboxProps {
    dataTestId?: string;
    label: any;
    identifier?: string;
    value?: string;
    name?: string;
    isDark?: boolean;
    isChecked?: boolean;
    isDisabled?: boolean;
    isRequired?: boolean;
    isToggled?: boolean;
    hasError?: boolean;
    onChange: (isChecked: boolean) => void;
    validate?: () => void;
}

export const Checkbox: React.FC<ICheckboxProps> = ({
    dataTestId,
    label,
    identifier,
    value,
    name,
    isDark,
    isChecked,
    isDisabled,
    isRequired,
    isToggled,
    hasError,
    onChange,
    validate
}) => {
    const ref = useRef(null);

    const handleCheckboxChange = (e) => {
        onChange(e?.target?.checked);
    };

    useEffect(() => {
        ref.current.addEventListener('dsdCheckboxChange', handleCheckboxChange);

        ref.current.addEventListener('validate', validate);
    }, []);

    return (
        // @ts-ignore
        <dsd-checkbox
            ref={ref}
            data-testid={dataTestId}
            dark={isDark}
            identifier={identifier}
            value={value}
            name={name}
            checked={isChecked}
            disabled={isDisabled}
            required={isRequired}
            toggle={isToggled}
            error={hasError}
            hide-required-labelling
        >
            {label}
            {/* @ts-ignore */}
        </dsd-checkbox>
    );
};
