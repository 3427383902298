import { mockInstance, withDelay } from '../../../../api/axiosInstance.mock';
import { QUERY_PARAM_USER_MODE, URLToURLSearchParams } from '../../../../utils';
import { SIGNATURE_ACTUALISER_OFFRE_GUY } from '../../apiEndPoints';
import { defineMockData as defineMockDataSession } from '../../contexte/useSessionApi/useSessionApi.mock';

export const useActualiserOffreGuyApiMock = (): any => {
    // var
    const query = URLToURLSearchParams();
    const userMockParam = query.get(QUERY_PARAM_USER_MODE);
    const sessionMockData = defineMockDataSession(userMockParam);

    const endPoint = SIGNATURE_ACTUALISER_OFFRE_GUY.replace(
        /{idDemande}/g,
        String(sessionMockData?.contextePret?.idDemande)
    );

    const url = new RegExp(`${endPoint}`);

    mockInstance.onPut(url).reply(withDelay([200, null], 200));
};
