import { mockInstance, withDelay } from '../../../../api/axiosInstance.mock';

import { END_POINT } from './useFrequenceAccelereeApi';
import { dataCoEmprunteur1 as sessionMockData } from '../../contexte/useSessionApi/useSessionApi.mock';
import { useSelector } from 'react-redux';

/* eslint-disable */
export const data = {
    idOffre: 130068,
    frequences: [
        {
            frequenceRemboursement: 'HEBDOMADAIRE_ACCELEREE',
            paiement: 419.07,
            amortissement: {
                annee: 17,
                mois: 11
            },
            economies: {
                montantTotal: -8828.3,
                economieAssurance: {
                    montant: 0,
                    duree: {
                        annee: 5,
                        mois: 0
                    }
                },
                economieInteret: {
                    montant: 504.84,
                    duree: {
                        annee: 5,
                        mois: 0
                    }
                },
                economieCapital: {
                    montant: -9333.14,
                    duree: {
                        annee: 5,
                        mois: 0
                    }
                }
            },
            paiementsEtPourcentagesOffreSelectionnee: {
                montantTotalInteret: 30542.81,
                pourcentageInteretSurMontantTotal: 0.28,
                montantTotalAssurancesVie: 0,
                pourcentageAssurancesVieSurMontantTotal: 0.0,
                montantTotalAssurancesInvalidite: 0,
                pourcentageAssurancesInvaliditeSurMontantTotal: 0.0,
                montantTotalCapital: 78415.39,
                pourcentageCapitalSurMontantTotal: 0.72
            },
            paiementsEtPourcentagesOffreActuelle: {
                montantTotalInteret: 31047.65,
                pourcentageInteretSurMontantTotal: 0.31,
                montantTotalAssurancesVie: 0,
                pourcentageAssurancesVieSurMontantTotal: 0.0,
                montantTotalAssurancesInvalidite: 0,
                pourcentageAssurancesInvaliditeSurMontantTotal: 0.0,
                montantTotalCapital: 69082.25,
                pourcentageCapitalSurMontantTotal: 0.69
            }
        },
        {
            frequenceRemboursement: 'AUX_DEUX_SEMAINES_ACCELEREE',
            paiement: 837.57,
            amortissement: {
                annee: 17,
                mois: 11
            },
            economies: {
                montantTotal: -8754.2,
                economieAssurance: {
                    montant: 0,
                    duree: {
                        annee: 5,
                        mois: 0
                    }
                },
                economieInteret: {
                    montant: 477.4,
                    duree: {
                        annee: 5,
                        mois: 0
                    }
                },
                economieCapital: {
                    montant: -9231.6,
                    duree: {
                        annee: 5,
                        mois: 0
                    }
                }
            },
            paiementsEtPourcentagesOffreSelectionnee: {
                montantTotalInteret: 30570.25,
                pourcentageInteretSurMontantTotal: 0.28,
                montantTotalAssurancesVie: 0,
                pourcentageAssurancesVieSurMontantTotal: 0.0,
                montantTotalAssurancesInvalidite: 0,
                pourcentageAssurancesInvaliditeSurMontantTotal: 0.0,
                montantTotalCapital: 78313.85,
                pourcentageCapitalSurMontantTotal: 0.72
            },
            paiementsEtPourcentagesOffreActuelle: {
                montantTotalInteret: 31047.65,
                pourcentageInteretSurMontantTotal: 0.31,
                montantTotalAssurancesVie: 0,
                pourcentageAssurancesVieSurMontantTotal: 0.0,
                montantTotalAssurancesInvalidite: 0,
                pourcentageAssurancesInvaliditeSurMontantTotal: 0.0,
                montantTotalCapital: 69082.25,
                pourcentageCapitalSurMontantTotal: 0.69
            }
        }
    ]
};

const endPoint = END_POINT.replace(
    /{idDemande}/g,
    String(sessionMockData?.contextePret?.idDemande)
);

export const useFrequenceAccelereeApiMock = (): void => {
    // Store: obtenir l'offre choisie pour rendre les mock dynamique
    const offreChoisie = useSelector(
        (state: any) => state.modalites.offreChoisie
    );

    let dataToReturn;

    if (offreChoisie?.idOffre) {
        dataToReturn = {
            ...data,
            idOffre: offreChoisie?.idOffre
        };
    } else {
        dataToReturn = data;
    }

    mockInstance.onGet(endPoint).reply(withDelay([200, dataToReturn], 1000));
};
