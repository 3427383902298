import React from 'react';

interface IGrid {
    hideXs?: boolean;
    hideMd?: boolean;
    hideSm?: boolean;
    hideDesktop?: boolean;
    hideMobile?: boolean;
    col?: string;
    md?: string;
    sm?: string;
    lg?: string;
    xl?: string;
    className?: string;
    children: React.ReactNode;
    displayType?: DisplayType;
    dataTestId?: string;
}

/*  Display type for hideMobile and hideXs
    sometimes wwe need to specify if we use flex or block
*/
export enum DisplayType {
    flex = 'flex',
    block = 'block'
}

enum GridClasses {
    hideDesktop = 'sm:dsd-d-none',
    hideMobile = 'dsd-d-none sm:dsd-d-', // block or flex
    hideSm = 'sm:dsd-d-none',
    hideMd = 'md:dsd-d-none'
}

const DEFAULT_DISPLAY_TYPE = 'flex';

export const GridCol: React.FC<IGrid> = ({
    hideXs,
    hideMd,
    hideSm,
    hideDesktop,
    displayType,
    hideMobile,
    col,
    md,
    sm,
    lg,
    xl,
    className, // Deprecated
    children,
    dataTestId
}) => {
    const hideMobileClasses =
        (hideMobile || hideXs) &&
        GridClasses.hideMobile +
            (displayType ? displayType : DEFAULT_DISPLAY_TYPE);

    const classNames = [
        hideMobileClasses || '',
        hideDesktop || '',
        hideMd || '',
        hideSm || '',
        className || ''
    ]
        .filter((e) => !!e)
        .join(' ');

    return (
        <>
            {/*@ts-ignore*/}
            <dsd-grid-col
                col={col}
                md-col={md}
                sm-col={sm}
                lg-col={lg}
                xl-col={xl}
                class={classNames}
                data-testid={dataTestId}
            >
                {children}
                {/*@ts-ignore*/}
            </dsd-grid-col>
        </>
    );
};
